unit Pickups;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Grids,
  WEBLib.DBCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFPickups = class(TForm)
    PickupsPnl: TPanel;
    DSBackhauls: TXDataWebDataSet;
    WDSBackhauls: TDataSource;
    WebDBGrid1: TDBGrid;
    WebButton4: TButton;
    WebButton1: TButton;
    WebButton2: TButton;
    WebDBLabel5: TDBLabel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel7: TDBLabel;
    WebDBLabel8: TDBLabel;
    DSBackhaulsOrdrNumTrunc: TIntegerField;
    DSBackhaulsVenNum: TIntegerField;
    DSBackhaulsReleaseNum: TStringField;
    DSBackhaulsAdditionalTemPinfo: TStringField;
    DSBackhaulsNotes: TStringField;
    DSBackhaulsVenLoc: TStringField;
    DSBackhaulsAdd1: TStringField;
    DSBackhaulsAdd2: TStringField;
    DSBackhaulsCity: TStringField;
    DSBackhaulsST: TStringField;
    DSBackhaulsZip: TStringField;
    DSBackhaulsPhone: TStringField;
    DSBackhaulsTtlCs: TIntegerField;
    DSBackhaulsTtlCubes: TFloatField;
    DSBackhaulsTtlPndsGross: TFloatField;
    DSBackhaulsOrdrShipDt: TDateTimeField;
    DSBackhaulsPickupTruck: TIntegerField;
    DSBackhaulsLatitude: TFloatField;
    DSBackhaulsLongitude: TFloatField;
    DSBackhaulsPickupCompleted: TStringField;
    DSPOPerm: TXDataWebDataSet;
    DSPOPermAutoInt: TIntegerField;
    DSPOPermOrdrNumTrunc: TIntegerField;
    DSPOPermPickupCompleted: TStringField;
    DSPOPermPickupDateTime: TDateTimeField;
    WDSPOPerm: TDataSource;
    WebDBLabel9: TDBLabel;
    DSBackhaulsAddtionalTempInfo: TStringField;
    DSBackhaulsPickupDateTime: TDateTimeField;
    DSBackhaulsVenAltNm: TStringField;
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure DSPOPermAfterOpen(DataSet: TDataSet);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FPickups: TFPickups;

implementation

Uses Data, Options, PickupsMap;

{$R *.dfm}

procedure TFPickups.DSPOPermAfterOpen(DataSet: TDataSet);
begin
  DSPOPerm.Edit;
  DSPOPermPickupCompleted.AsString := 'Y';
  DSPOPermPickupDateTime.AsDateTime := Now();
  DSPOPerm.Post;
  DSPOPerm.ApplyUpdates;
  ShowMessage('Backhaul Marked Picked Up');
end;

procedure TFPickups.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFPickupMap, FPickupMap);
end;

procedure TFPickups.WebButton2Click(Sender: TObject);
begin
  DSPOPerm.QueryString := '$filter=(OrdrNumTrunc eq ' + DSBackhaulsOrdrNumTrunc.AsString+')';
  DSPOPerm.Load;
end;

procedure TFPickups.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFPickups.WebFormShow(Sender: TObject);
begin
  DSBackhauls.QueryString := '$filter=(PickupTruck eq ' + Dm.DSLoginLastRoute.AsString+')';
  DSBackhauls.Load;
  PickupsPnl.Left := Round((FPickups.Width / 2) -
    (PickupsPnl.Width / 2));
  PickupsPnl.Top := Round((FPickups.Height / 2) -
    (PickupsPnl.Height / 2));
end;

procedure TFPickups.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PickupsPnl := TPanel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebDBLabel7 := TDBLabel.Create(Self);
  WebDBLabel8 := TDBLabel.Create(Self);
  WebDBLabel9 := TDBLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  DSBackhauls := TXDataWebDataSet.Create(Self);
  DSBackhaulsOrdrNumTrunc := TIntegerField.Create(Self);
  DSBackhaulsVenNum := TIntegerField.Create(Self);
  DSBackhaulsReleaseNum := TStringField.Create(Self);
  DSBackhaulsAdditionalTemPinfo := TStringField.Create(Self);
  DSBackhaulsNotes := TStringField.Create(Self);
  DSBackhaulsVenLoc := TStringField.Create(Self);
  DSBackhaulsAdd1 := TStringField.Create(Self);
  DSBackhaulsAdd2 := TStringField.Create(Self);
  DSBackhaulsCity := TStringField.Create(Self);
  DSBackhaulsST := TStringField.Create(Self);
  DSBackhaulsZip := TStringField.Create(Self);
  DSBackhaulsPhone := TStringField.Create(Self);
  DSBackhaulsTtlCs := TIntegerField.Create(Self);
  DSBackhaulsTtlCubes := TFloatField.Create(Self);
  DSBackhaulsTtlPndsGross := TFloatField.Create(Self);
  DSBackhaulsOrdrShipDt := TDateTimeField.Create(Self);
  DSBackhaulsPickupTruck := TIntegerField.Create(Self);
  DSBackhaulsLatitude := TFloatField.Create(Self);
  DSBackhaulsLongitude := TFloatField.Create(Self);
  DSBackhaulsPickupCompleted := TStringField.Create(Self);
  DSBackhaulsAddtionalTempInfo := TStringField.Create(Self);
  DSBackhaulsPickupDateTime := TDateTimeField.Create(Self);
  DSBackhaulsVenAltNm := TStringField.Create(Self);
  WDSBackhauls := TDataSource.Create(Self);
  DSPOPerm := TXDataWebDataSet.Create(Self);
  DSPOPermAutoInt := TIntegerField.Create(Self);
  DSPOPermOrdrNumTrunc := TIntegerField.Create(Self);
  DSPOPermPickupCompleted := TStringField.Create(Self);
  DSPOPermPickupDateTime := TDateTimeField.Create(Self);
  WDSPOPerm := TDataSource.Create(Self);

  PickupsPnl.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel7.BeforeLoadDFMValues;
  WebDBLabel8.BeforeLoadDFMValues;
  WebDBLabel9.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  DSBackhauls.BeforeLoadDFMValues;
  DSBackhaulsOrdrNumTrunc.BeforeLoadDFMValues;
  DSBackhaulsVenNum.BeforeLoadDFMValues;
  DSBackhaulsReleaseNum.BeforeLoadDFMValues;
  DSBackhaulsAdditionalTemPinfo.BeforeLoadDFMValues;
  DSBackhaulsNotes.BeforeLoadDFMValues;
  DSBackhaulsVenLoc.BeforeLoadDFMValues;
  DSBackhaulsAdd1.BeforeLoadDFMValues;
  DSBackhaulsAdd2.BeforeLoadDFMValues;
  DSBackhaulsCity.BeforeLoadDFMValues;
  DSBackhaulsST.BeforeLoadDFMValues;
  DSBackhaulsZip.BeforeLoadDFMValues;
  DSBackhaulsPhone.BeforeLoadDFMValues;
  DSBackhaulsTtlCs.BeforeLoadDFMValues;
  DSBackhaulsTtlCubes.BeforeLoadDFMValues;
  DSBackhaulsTtlPndsGross.BeforeLoadDFMValues;
  DSBackhaulsOrdrShipDt.BeforeLoadDFMValues;
  DSBackhaulsPickupTruck.BeforeLoadDFMValues;
  DSBackhaulsLatitude.BeforeLoadDFMValues;
  DSBackhaulsLongitude.BeforeLoadDFMValues;
  DSBackhaulsPickupCompleted.BeforeLoadDFMValues;
  DSBackhaulsAddtionalTempInfo.BeforeLoadDFMValues;
  DSBackhaulsPickupDateTime.BeforeLoadDFMValues;
  DSBackhaulsVenAltNm.BeforeLoadDFMValues;
  WDSBackhauls.BeforeLoadDFMValues;
  DSPOPerm.BeforeLoadDFMValues;
  DSPOPermAutoInt.BeforeLoadDFMValues;
  DSPOPermOrdrNumTrunc.BeforeLoadDFMValues;
  DSPOPermPickupCompleted.BeforeLoadDFMValues;
  DSPOPermPickupDateTime.BeforeLoadDFMValues;
  WDSPOPerm.BeforeLoadDFMValues;
  try
    Name := 'FPickups';
    Width := 400;
    Height := 800;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    PickupsPnl.SetParentComponent(Self);
    PickupsPnl.Name := 'PickupsPnl';
    PickupsPnl.Left := 2;
    PickupsPnl.Top := 9;
    PickupsPnl.Width := 388;
    PickupsPnl.Height := 769;
    PickupsPnl.ElementClassName := 'card';
    PickupsPnl.BorderColor := clSilver;
    PickupsPnl.BorderStyle := bsSingle;
    PickupsPnl.ElementBodyClassName := 'card-body';
    WebDBLabel5.SetParentComponent(PickupsPnl);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 15;
    WebDBLabel5.Top := 482;
    WebDBLabel5.Width := 72;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'Address 1';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Arial';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.DataField := 'Add1';
    WebDBLabel5.DataSource := WDSBackhauls;
    WebDBLabel1.SetParentComponent(PickupsPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 15;
    WebDBLabel1.Top := 498;
    WebDBLabel1.Width := 72;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'Address 2';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -16;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.DataField := 'Add2';
    WebDBLabel1.DataSource := WDSBackhauls;
    WebDBLabel2.SetParentComponent(PickupsPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 15;
    WebDBLabel2.Top := 514;
    WebDBLabel2.Width := 27;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'City';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -16;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.DataField := 'City';
    WebDBLabel2.DataSource := WDSBackhauls;
    WebDBLabel3.SetParentComponent(PickupsPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 159;
    WebDBLabel3.Top := 514;
    WebDBLabel3.Width := 37;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'State';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.DataField := 'ST';
    WebDBLabel3.DataSource := WDSBackhauls;
    WebDBLabel4.SetParentComponent(PickupsPnl);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 202;
    WebDBLabel4.Top := 514;
    WebDBLabel4.Width := 22;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := 'Zip';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -16;
    WebDBLabel4.Font.Name := 'Arial';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.DataField := 'Zip';
    WebDBLabel4.DataSource := WDSBackhauls;
    WebDBLabel6.SetParentComponent(PickupsPnl);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 15;
    WebDBLabel6.Top := 534;
    WebDBLabel6.Width := 24;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := 'Info';
    WebDBLabel6.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel6.Font.Color := clWindowText;
    WebDBLabel6.Font.Height := -16;
    WebDBLabel6.Font.Name := 'Arial';
    WebDBLabel6.Font.Style := [];
    WebDBLabel6.ParentFont := False;
    WebDBLabel6.DataField := 'AdditionalTemPinfo';
    WebDBLabel6.DataSource := WDSBackhauls;
    WebDBLabel7.SetParentComponent(PickupsPnl);
    WebDBLabel7.Name := 'WebDBLabel7';
    WebDBLabel7.Left := 15;
    WebDBLabel7.Top := 554;
    WebDBLabel7.Width := 67;
    WebDBLabel7.Height := 18;
    WebDBLabel7.Caption := 'Release#';
    WebDBLabel7.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel7.Font.Color := clWindowText;
    WebDBLabel7.Font.Height := -16;
    WebDBLabel7.Font.Name := 'Arial';
    WebDBLabel7.Font.Style := [];
    WebDBLabel7.ParentFont := False;
    WebDBLabel7.DataField := 'ReleaseNum';
    WebDBLabel7.DataSource := WDSBackhauls;
    WebDBLabel8.SetParentComponent(PickupsPnl);
    WebDBLabel8.Name := 'WebDBLabel8';
    WebDBLabel8.Left := 15;
    WebDBLabel8.Top := 594;
    WebDBLabel8.Width := 45;
    WebDBLabel8.Height := 18;
    WebDBLabel8.Caption := 'Phone';
    WebDBLabel8.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel8.Font.Color := clWindowText;
    WebDBLabel8.Font.Height := -16;
    WebDBLabel8.Font.Name := 'Arial';
    WebDBLabel8.Font.Style := [];
    WebDBLabel8.ParentFont := False;
    WebDBLabel8.DataField := 'Phone';
    WebDBLabel8.DataSource := WDSBackhauls;
    WebDBLabel9.SetParentComponent(PickupsPnl);
    WebDBLabel9.Name := 'WebDBLabel9';
    WebDBLabel9.Left := 15;
    WebDBLabel9.Top := 574;
    WebDBLabel9.Width := 41;
    WebDBLabel9.Height := 18;
    WebDBLabel9.Caption := 'Notes';
    WebDBLabel9.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel9.Font.Color := clWindowText;
    WebDBLabel9.Font.Height := -16;
    WebDBLabel9.Font.Name := 'Arial';
    WebDBLabel9.Font.Style := [];
    WebDBLabel9.ParentFont := False;
    WebDBLabel9.DataField := 'Notes';
    WebDBLabel9.DataSource := WDSBackhauls;
    WebDBGrid1.SetParentComponent(PickupsPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 4;
    WebDBGrid1.Top := 3;
    WebDBGrid1.Width := 381;
    WebDBGrid1.Height := 473;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'OrdrNumTrunc';
      Title := 'PO#';
      Width := 72;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'VenAltNm';
      Title := 'Vendor';
      Width := 187;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'AdditionalTemPinfo';
      Title := 'Info';
      Width := 101;
    end;
    WebDBGrid1.DataSource := WDSBackhauls;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -13;
    WebDBGrid1.Font.Name := 'Tahoma';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.ColWidths[0] := 72;
    WebDBGrid1.ColWidths[1] := 187;
    WebDBGrid1.ColWidths[2] := 101;
    WebButton4.SetParentComponent(PickupsPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 15;
    WebButton4.Top := 697;
    WebButton4.Width := 137;
    WebButton4.Height := 57;
    WebButton4.Caption := 'Close';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-danger';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.ParentFont := False;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton1.SetParentComponent(PickupsPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 239;
    WebButton1.Top := 696;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Map Pickup';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn btn-info';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(PickupsPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 127;
    WebButton2.Top := 629;
    WebButton2.Width := 137;
    WebButton2.Height := 57;
    WebButton2.Caption := 'Mark Finished';
    WebButton2.ChildOrder := 3;
    WebButton2.ElementClassName := 'btn btn-info';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Tahoma';
    WebButton2.Font.Style := [];
    WebButton2.ParentFont := False;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    DSBackhauls.SetParentComponent(Self);
    DSBackhauls.Name := 'DSBackhauls';
    DSBackhauls.EntitySetName := 'Backhauls';
    DSBackhauls.Connection := Dm.ApiConnection;
    DSBackhauls.Left := 48;
    DSBackhauls.Top := 440;
    DSBackhaulsOrdrNumTrunc.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrNumTrunc.Name := 'DSBackhaulsOrdrNumTrunc';
    DSBackhaulsOrdrNumTrunc.FieldName := 'OrdrNumTrunc';
    DSBackhaulsVenNum.SetParentComponent(DSBackhauls);
    DSBackhaulsVenNum.Name := 'DSBackhaulsVenNum';
    DSBackhaulsVenNum.FieldName := 'VenNum';
    DSBackhaulsReleaseNum.SetParentComponent(DSBackhauls);
    DSBackhaulsReleaseNum.Name := 'DSBackhaulsReleaseNum';
    DSBackhaulsReleaseNum.FieldName := 'ReleaseNum';
    DSBackhaulsReleaseNum.Size := 24;
    DSBackhaulsAdditionalTemPinfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAdditionalTemPinfo.Name := 'DSBackhaulsAdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.FieldName := 'AdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.Size := 120;
    DSBackhaulsNotes.SetParentComponent(DSBackhauls);
    DSBackhaulsNotes.Name := 'DSBackhaulsNotes';
    DSBackhaulsNotes.FieldName := 'Notes';
    DSBackhaulsNotes.Size := 120;
    DSBackhaulsVenLoc.SetParentComponent(DSBackhauls);
    DSBackhaulsVenLoc.Name := 'DSBackhaulsVenLoc';
    DSBackhaulsVenLoc.FieldName := 'VenLoc';
    DSBackhaulsVenLoc.Size := 24;
    DSBackhaulsAdd1.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd1.Name := 'DSBackhaulsAdd1';
    DSBackhaulsAdd1.FieldName := 'Add1';
    DSBackhaulsAdd2.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd2.Name := 'DSBackhaulsAdd2';
    DSBackhaulsAdd2.FieldName := 'Add2';
    DSBackhaulsCity.SetParentComponent(DSBackhauls);
    DSBackhaulsCity.Name := 'DSBackhaulsCity';
    DSBackhaulsCity.FieldName := 'City';
    DSBackhaulsCity.Size := 16;
    DSBackhaulsST.SetParentComponent(DSBackhauls);
    DSBackhaulsST.Name := 'DSBackhaulsST';
    DSBackhaulsST.FieldName := 'ST';
    DSBackhaulsST.Size := 2;
    DSBackhaulsZip.SetParentComponent(DSBackhauls);
    DSBackhaulsZip.Name := 'DSBackhaulsZip';
    DSBackhaulsZip.FieldName := 'Zip';
    DSBackhaulsZip.Size := 10;
    DSBackhaulsPhone.SetParentComponent(DSBackhauls);
    DSBackhaulsPhone.Name := 'DSBackhaulsPhone';
    DSBackhaulsPhone.FieldName := 'Phone';
    DSBackhaulsPhone.Size := 14;
    DSBackhaulsTtlCs.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCs.Name := 'DSBackhaulsTtlCs';
    DSBackhaulsTtlCs.FieldName := 'TtlCs';
    DSBackhaulsTtlCubes.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCubes.Name := 'DSBackhaulsTtlCubes';
    DSBackhaulsTtlCubes.FieldName := 'TtlCubes';
    DSBackhaulsTtlPndsGross.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlPndsGross.Name := 'DSBackhaulsTtlPndsGross';
    DSBackhaulsTtlPndsGross.FieldName := 'TtlPndsGross';
    DSBackhaulsOrdrShipDt.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrShipDt.Name := 'DSBackhaulsOrdrShipDt';
    DSBackhaulsOrdrShipDt.FieldName := 'OrdrShipDt';
    DSBackhaulsPickupTruck.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupTruck.Name := 'DSBackhaulsPickupTruck';
    DSBackhaulsPickupTruck.FieldName := 'PickupTruck';
    DSBackhaulsLatitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLatitude.Name := 'DSBackhaulsLatitude';
    DSBackhaulsLatitude.FieldName := 'Latitude';
    DSBackhaulsLongitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLongitude.Name := 'DSBackhaulsLongitude';
    DSBackhaulsLongitude.FieldName := 'Longitude';
    DSBackhaulsPickupCompleted.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupCompleted.Name := 'DSBackhaulsPickupCompleted';
    DSBackhaulsPickupCompleted.FieldName := 'PickupCompleted';
    DSBackhaulsPickupCompleted.Size := 1;
    DSBackhaulsAddtionalTempInfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAddtionalTempInfo.Name := 'DSBackhaulsAddtionalTempInfo';
    DSBackhaulsAddtionalTempInfo.FieldName := 'AddtionalTempInfo';
    DSBackhaulsAddtionalTempInfo.Size := 120;
    DSBackhaulsPickupDateTime.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupDateTime.Name := 'DSBackhaulsPickupDateTime';
    DSBackhaulsPickupDateTime.FieldName := 'PickupDateTime';
    DSBackhaulsVenAltNm.SetParentComponent(DSBackhauls);
    DSBackhaulsVenAltNm.Name := 'DSBackhaulsVenAltNm';
    DSBackhaulsVenAltNm.FieldName := 'VenAltNm';
    DSBackhaulsVenAltNm.Size := 30;
    WDSBackhauls.SetParentComponent(Self);
    WDSBackhauls.Name := 'WDSBackhauls';
    WDSBackhauls.DataSet := DSBackhauls;
    WDSBackhauls.Left := 112;
    WDSBackhauls.Top := 440;
    DSPOPerm.SetParentComponent(Self);
    DSPOPerm.Name := 'DSPOPerm';
    DSPOPerm.AfterOpen := DSPOPermAfterOpen;
    DSPOPerm.EntitySetName := 'POPerm';
    DSPOPerm.Connection := Dm.ApiConnection;
    DSPOPerm.Left := 306;
    DSPOPerm.Top := 513;
    DSPOPermAutoInt.SetParentComponent(DSPOPerm);
    DSPOPermAutoInt.Name := 'DSPOPermAutoInt';
    DSPOPermAutoInt.FieldName := 'AutoInt';
    DSPOPermAutoInt.Required := True;
    DSPOPermOrdrNumTrunc.SetParentComponent(DSPOPerm);
    DSPOPermOrdrNumTrunc.Name := 'DSPOPermOrdrNumTrunc';
    DSPOPermOrdrNumTrunc.FieldName := 'OrdrNumTrunc';
    DSPOPermPickupCompleted.SetParentComponent(DSPOPerm);
    DSPOPermPickupCompleted.Name := 'DSPOPermPickupCompleted';
    DSPOPermPickupCompleted.FieldName := 'PickupCompleted';
    DSPOPermPickupCompleted.Size := 1;
    DSPOPermPickupDateTime.SetParentComponent(DSPOPerm);
    DSPOPermPickupDateTime.Name := 'DSPOPermPickupDateTime';
    DSPOPermPickupDateTime.FieldName := 'PickupDateTime';
    WDSPOPerm.SetParentComponent(Self);
    WDSPOPerm.Name := 'WDSPOPerm';
    WDSPOPerm.DataSet := DSPOPerm;
    WDSPOPerm.Left := 306;
    WDSPOPerm.Top := 561;
  finally
    PickupsPnl.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel7.AfterLoadDFMValues;
    WebDBLabel8.AfterLoadDFMValues;
    WebDBLabel9.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    DSBackhauls.AfterLoadDFMValues;
    DSBackhaulsOrdrNumTrunc.AfterLoadDFMValues;
    DSBackhaulsVenNum.AfterLoadDFMValues;
    DSBackhaulsReleaseNum.AfterLoadDFMValues;
    DSBackhaulsAdditionalTemPinfo.AfterLoadDFMValues;
    DSBackhaulsNotes.AfterLoadDFMValues;
    DSBackhaulsVenLoc.AfterLoadDFMValues;
    DSBackhaulsAdd1.AfterLoadDFMValues;
    DSBackhaulsAdd2.AfterLoadDFMValues;
    DSBackhaulsCity.AfterLoadDFMValues;
    DSBackhaulsST.AfterLoadDFMValues;
    DSBackhaulsZip.AfterLoadDFMValues;
    DSBackhaulsPhone.AfterLoadDFMValues;
    DSBackhaulsTtlCs.AfterLoadDFMValues;
    DSBackhaulsTtlCubes.AfterLoadDFMValues;
    DSBackhaulsTtlPndsGross.AfterLoadDFMValues;
    DSBackhaulsOrdrShipDt.AfterLoadDFMValues;
    DSBackhaulsPickupTruck.AfterLoadDFMValues;
    DSBackhaulsLatitude.AfterLoadDFMValues;
    DSBackhaulsLongitude.AfterLoadDFMValues;
    DSBackhaulsPickupCompleted.AfterLoadDFMValues;
    DSBackhaulsAddtionalTempInfo.AfterLoadDFMValues;
    DSBackhaulsPickupDateTime.AfterLoadDFMValues;
    DSBackhaulsVenAltNm.AfterLoadDFMValues;
    WDSBackhauls.AfterLoadDFMValues;
    DSPOPerm.AfterLoadDFMValues;
    DSPOPermAutoInt.AfterLoadDFMValues;
    DSPOPermOrdrNumTrunc.AfterLoadDFMValues;
    DSPOPermPickupCompleted.AfterLoadDFMValues;
    DSPOPermPickupDateTime.AfterLoadDFMValues;
    WDSPOPerm.AfterLoadDFMValues;
  end;
end;

end.