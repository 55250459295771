unit Expense;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB;

type
  TFExpense = class(TForm)
    ExpensePnl: TPanel;
    WebLabel1: TLabel;
    ExpenseAmtTxt: TEdit;
    WebLabel2: TLabel;
    InfoTxt: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    WDSPayments: TDataSource;
    DSPayments: TXDataWebDataSet;
    DSPaymentsAutoInc: TIntegerField;
    DSPaymentsInvoice: TIntegerField;
    DSPaymentsCust: TIntegerField;
    DSPaymentsPaymentType: TStringField;
    DSPaymentsCheckNumber: TIntegerField;
    DSPaymentsPaymentAmount: TFloatField;
    DSPaymentsDate: TDateTimeField;
    DSPaymentsRoute: TIntegerField;
    DSPaymentsDriverNum: TIntegerField;
    DSPaymentsMoreInfo: TStringField;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FExpense: TFExpense;

implementation

Uses Data,CurrentStop,Options;

{$R *.dfm}

procedure TFExpense.WebButton1Click(Sender: TObject);
begin
  if (ExpenseAmtTxt.Text = '') then
  begin
    ShowMessage('Enter a Dollar Amount');
    Exit;
  end;
  if InfoTxt.Text = '' then
  begin
    ShowMessage('Enter Details');
    Exit;
  end;
  DSPayments.Append;
  DSPaymentsInvoice.AsInteger := 999999;
  DSPaymentsCust.AsInteger := 999999;
  DSPaymentsPaymentType.AsString := 'Expense';
  DSPaymentsCheckNumber.AsString := '0';
  DSPaymentsPaymentAmount.AsString := ExpenseAmtTxt.Text;
  DSPaymentsDate.AsDateTime := Now;
  DSPaymentsRoute.AsInteger := Dm.DSLoginLastRoute.AsInteger;
  DSPaymentsDriverNum.AsInteger := Dm.DSLoginNumber.AsInteger;
  DSPaymentsMoreInfo.AsString := InfoTxt.Text;
  DSPayments.Post;
  DSPayments.ApplyUpdates;
  ExpenseAmtTxt.Clear;
  InfoTxt.Clear;
  //FExpense.Close;
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFExpense.WebButton2Click(Sender: TObject);
begin
  ExpenseAmtTxt.Clear;
  InfoTxt.Clear;
  //FExpense.Close;
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFExpense.WebFormResize(Sender: TObject);
begin
  ExpensePnl.Left := Round((FExpense.Width / 2) - (ExpensePnl.Width / 2));
  if ExpensePnl.Top < 0 then
    ExpensePnl.Top := 0;
end;

procedure TFExpense.WebFormShow(Sender: TObject);
begin
  DSPayments.QueryString := '$filter=(Invoice eq ' +
    Dm.DSCurrStopOrdersInvoiceNum.AsString + ' AND Cust eq ' +
    Dm.DSCurrStopOrdersCust.AsString + ')';
  DSPayments.Load;
  ExpensePnl.Left := Round((FExpense.Width / 2) - (ExpensePnl.Width / 2));
  ExpensePnl.Top := Round((FExpense.Height / 2) - (ExpensePnl.Height / 2));
end;

procedure TFExpense.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ExpensePnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  ExpenseAmtTxt := TEdit.Create(Self);
  InfoTxt := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WDSPayments := TDataSource.Create(Self);
  DSPayments := TXDataWebDataSet.Create(Self);
  DSPaymentsAutoInc := TIntegerField.Create(Self);
  DSPaymentsInvoice := TIntegerField.Create(Self);
  DSPaymentsCust := TIntegerField.Create(Self);
  DSPaymentsPaymentType := TStringField.Create(Self);
  DSPaymentsCheckNumber := TIntegerField.Create(Self);
  DSPaymentsPaymentAmount := TFloatField.Create(Self);
  DSPaymentsDate := TDateTimeField.Create(Self);
  DSPaymentsRoute := TIntegerField.Create(Self);
  DSPaymentsDriverNum := TIntegerField.Create(Self);
  DSPaymentsMoreInfo := TStringField.Create(Self);

  ExpensePnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  ExpenseAmtTxt.BeforeLoadDFMValues;
  InfoTxt.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WDSPayments.BeforeLoadDFMValues;
  DSPayments.BeforeLoadDFMValues;
  DSPaymentsAutoInc.BeforeLoadDFMValues;
  DSPaymentsInvoice.BeforeLoadDFMValues;
  DSPaymentsCust.BeforeLoadDFMValues;
  DSPaymentsPaymentType.BeforeLoadDFMValues;
  DSPaymentsCheckNumber.BeforeLoadDFMValues;
  DSPaymentsPaymentAmount.BeforeLoadDFMValues;
  DSPaymentsDate.BeforeLoadDFMValues;
  DSPaymentsRoute.BeforeLoadDFMValues;
  DSPaymentsDriverNum.BeforeLoadDFMValues;
  DSPaymentsMoreInfo.BeforeLoadDFMValues;
  try
    Name := 'FExpense';
    Width := 412;
    Height := 643;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ExpensePnl.SetParentComponent(Self);
    ExpensePnl.Name := 'ExpensePnl';
    ExpensePnl.Left := 8;
    ExpensePnl.Top := 8;
    ExpensePnl.Width := 396;
    ExpensePnl.Height := 625;
    ExpensePnl.ElementClassName := 'card';
    ExpensePnl.ElementBodyClassName := 'card-body';
    ExpensePnl.Font.Charset := DEFAULT_CHARSET;
    ExpensePnl.Font.Color := clWindowText;
    ExpensePnl.Font.Height := -16;
    ExpensePnl.Font.Name := 'Tahoma';
    ExpensePnl.Font.Style := [];
    ExpensePnl.ParentFont := False;
    WebLabel1.SetParentComponent(ExpensePnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 32;
    WebLabel1.Width := 141;
    WebLabel1.Height := 23;
    WebLabel1.Caption := 'Expense Amount';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ExpensePnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 152;
    WebLabel2.Width := 131;
    WebLabel2.Height := 23;
    WebLabel2.Caption := 'Expense Details';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    ExpenseAmtTxt.SetParentComponent(ExpensePnl);
    ExpenseAmtTxt.Name := 'ExpenseAmtTxt';
    ExpenseAmtTxt.Left := 104;
    ExpenseAmtTxt.Top := 61;
    ExpenseAmtTxt.Width := 153;
    ExpenseAmtTxt.Height := 36;
    ExpenseAmtTxt.ChildOrder := 1;
    ExpenseAmtTxt.EditType := weFloat;
    ExpenseAmtTxt.Font.Charset := DEFAULT_CHARSET;
    ExpenseAmtTxt.Font.Color := clWindowText;
    ExpenseAmtTxt.Font.Height := -21;
    ExpenseAmtTxt.Font.Name := 'Tahoma';
    ExpenseAmtTxt.Font.Style := [];
    ExpenseAmtTxt.HeightPercent := 100.000000000000000000;
    ExpenseAmtTxt.ParentFont := False;
    ExpenseAmtTxt.WidthPercent := 100.000000000000000000;
    InfoTxt.SetParentComponent(ExpensePnl);
    InfoTxt.Name := 'InfoTxt';
    InfoTxt.Left := 104;
    InfoTxt.Top := 181;
    InfoTxt.Width := 257;
    InfoTxt.Height := 36;
    InfoTxt.ChildOrder := 3;
    InfoTxt.Font.Charset := DEFAULT_CHARSET;
    InfoTxt.Font.Color := clWindowText;
    InfoTxt.Font.Height := -21;
    InfoTxt.Font.Name := 'Tahoma';
    InfoTxt.Font.Style := [];
    InfoTxt.HeightPercent := 100.000000000000000000;
    InfoTxt.ParentFont := False;
    InfoTxt.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(ExpensePnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 216;
    WebButton1.Top := 287;
    WebButton1.Width := 169;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Save';
    WebButton1.ChildOrder := 4;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -27;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(ExpensePnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 16;
    WebButton2.Top := 287;
    WebButton2.Width := 161;
    WebButton2.Height := 57;
    WebButton2.Caption := 'Cancel';
    WebButton2.ChildOrder := 4;
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -27;
    WebButton2.Font.Name := 'Tahoma';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WDSPayments.SetParentComponent(Self);
    WDSPayments.Name := 'WDSPayments';
    WDSPayments.DataSet := DSPayments;
    WDSPayments.Left := 184;
    WDSPayments.Top := 616;
    DSPayments.SetParentComponent(Self);
    DSPayments.Name := 'DSPayments';
    DSPayments.EntitySetName := 'payments';
    DSPayments.Connection := Dm.ApiConnection;
    DSPayments.Left := 144;
    DSPayments.Top := 568;
    DSPaymentsAutoInc.SetParentComponent(DSPayments);
    DSPaymentsAutoInc.Name := 'DSPaymentsAutoInc';
    DSPaymentsAutoInc.FieldName := 'AutoInc';
    DSPaymentsAutoInc.Required := True;
    DSPaymentsInvoice.SetParentComponent(DSPayments);
    DSPaymentsInvoice.Name := 'DSPaymentsInvoice';
    DSPaymentsInvoice.FieldName := 'Invoice';
    DSPaymentsCust.SetParentComponent(DSPayments);
    DSPaymentsCust.Name := 'DSPaymentsCust';
    DSPaymentsCust.FieldName := 'Cust';
    DSPaymentsPaymentType.SetParentComponent(DSPayments);
    DSPaymentsPaymentType.Name := 'DSPaymentsPaymentType';
    DSPaymentsPaymentType.FieldName := 'PaymentType';
    DSPaymentsPaymentType.Size := 10;
    DSPaymentsCheckNumber.SetParentComponent(DSPayments);
    DSPaymentsCheckNumber.Name := 'DSPaymentsCheckNumber';
    DSPaymentsCheckNumber.FieldName := 'CheckNumber';
    DSPaymentsPaymentAmount.SetParentComponent(DSPayments);
    DSPaymentsPaymentAmount.Name := 'DSPaymentsPaymentAmount';
    DSPaymentsPaymentAmount.FieldName := 'PaymentAmount';
    DSPaymentsPaymentAmount.DisplayFormat := '0.00';
    DSPaymentsDate.SetParentComponent(DSPayments);
    DSPaymentsDate.Name := 'DSPaymentsDate';
    DSPaymentsDate.FieldName := 'Date';
    DSPaymentsRoute.SetParentComponent(DSPayments);
    DSPaymentsRoute.Name := 'DSPaymentsRoute';
    DSPaymentsRoute.FieldName := 'Route';
    DSPaymentsDriverNum.SetParentComponent(DSPayments);
    DSPaymentsDriverNum.Name := 'DSPaymentsDriverNum';
    DSPaymentsDriverNum.FieldName := 'DriverNum';
    DSPaymentsMoreInfo.SetParentComponent(DSPayments);
    DSPaymentsMoreInfo.Name := 'DSPaymentsMoreInfo';
    DSPaymentsMoreInfo.FieldName := 'MoreInfo';
    DSPaymentsMoreInfo.Size := 25;
  finally
    ExpensePnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    ExpenseAmtTxt.AfterLoadDFMValues;
    InfoTxt.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WDSPayments.AfterLoadDFMValues;
    DSPayments.AfterLoadDFMValues;
    DSPaymentsAutoInc.AfterLoadDFMValues;
    DSPaymentsInvoice.AfterLoadDFMValues;
    DSPaymentsCust.AfterLoadDFMValues;
    DSPaymentsPaymentType.AfterLoadDFMValues;
    DSPaymentsCheckNumber.AfterLoadDFMValues;
    DSPaymentsPaymentAmount.AfterLoadDFMValues;
    DSPaymentsDate.AfterLoadDFMValues;
    DSPaymentsRoute.AfterLoadDFMValues;
    DSPaymentsDriverNum.AfterLoadDFMValues;
    DSPaymentsMoreInfo.AfterLoadDFMValues;
  end;
end;

end.