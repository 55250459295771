{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2022 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}
unit WEBLib.DropDown;

interface

// overflow hidden of parent elements????
// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_js_dropdown_hover

uses
  Classes, WEBLib.Controls, WEBLib.Forms, Web, JS, WEBLib.Menus, WEBLib.Graphics,
  WEBLib.Grids;

type
  TCustomDropDownControl = class(TWebCustomControl)
  private
    FDoClickLayer: pointer;
    FDoBtnClick: pointer;
    FLayer: TJSHTMLElement;
    FControl: TCustomControl;
    FDropDown: TJSHTMLElement;
    FTextElement: TJSHTMLElement;
    FButton: TJSHTMLElement;
    FOnCloseUp: TNotifyEvent;
    FOnDropDown: TNotifyEvent;
    FText: string;
    FAutoDropDown: boolean;
    FElementInputClassName: TElementClassName;
    FButtonImageURL: string;
    FDropDownHeight: integer;
    FDropDownWidth: integer;
    FDropDownColor: TColor;
    FDropDownShadow: boolean;
    FReadOnly: Boolean;
    FElementButtonClassName: TElementClassName;
    procedure SetText(const Value: string);
    function GetText: string;
    function GetDroppedDown: boolean;
    procedure SetButtonImageURL(const Value: string);
    procedure SetReadOnly(const Value: Boolean);
    procedure SetControl(const Value: TCustomControl);
  protected
    procedure UpdateElementVisual; override;
    procedure CreateInitialize; override;
    function HandleDoClickLayer(Event: TJSMouseEvent): Boolean; virtual;
    function HandleDoBtnClick(Event: TJSMouseEvent): Boolean; virtual;
    procedure Click; override;
    function HasEdit: boolean; virtual;
    procedure CreateChildElements(AContainer: TJSElement); override;
    function CreateElement: TJSElement; override;
    procedure ClearMethodPointers; override;
    procedure GetMethodPointers; override;
    procedure DoDropDown; virtual;
    procedure DoCloseUp; virtual;
    procedure BindEvents; override;
    procedure UnBindEvents; override;
  public
    procedure ShowDropDown; virtual;
    procedure HideDropDown; virtual;
    procedure ToggleDropDown; virtual;
    property DroppedDown: boolean read GetDroppedDown;
    property Text: string read GetText write SetText;
    property BorderStyle;
    property Control: TCustomControl read FControl write SetControl;
    property ElementInputClassName: TElementClassName read FElementInputClassName write FElementInputClassName;
    property ElementButtonClassName: TElementClassName read FElementButtonClassName write FElementButtonClassName;
    property ButtonImageURL: string read FButtonImageURL write SetButtonImageURL;
  published
    property AutoDropDown: boolean read FAutoDropDown write FAutoDropDown default false;
    property DropDownColor: TColor read FDropDownColor write FDropDownColor default clWhite;
    property DropDownWidth: integer read FDropDownWidth write FDropDownWidth default 0;
    property DropDownHeight: integer read FDropDownHeight write FDropDownHeight default 0;
    property DropDownShadow: boolean read FDropDownShadow write FDropDownShadow default true;
    property ReadOnly: Boolean read FReadOnly write SetReadOnly default false;
    property OnDropDown: TNotifyEvent read FOnDropDown write FOnDropDown;
    property OnCloseUp: TNotifyEvent read FOnCloseUp write FOnCloseUp;
  end;

  TDropDownControl = class(TCustomDropDownControl)
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ButtonImageURL;
    property ChildOrder;
    property Color;
    property Control;
    property DragMode;
    property Font;
    property ElementButtonClassName;
    property ElementClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property ReadOnly;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnCloseUp;
    property OnDblClick;
    property OnDropDown;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebDropDownControl = class(TDropDownControl);

  TEditDropDownControl = class(TCustomDropDownControl)
  private
    function GetEditElement: TJSHTMLElement;
  protected
    procedure KeyDown(var Key: Word; Shift: TShiftState); override;
    function GetElementBindHandle: TJSEventTarget; override;
    function HasEdit: boolean; override;
  public
    property EditElement: TJSHTMLElement read GetEditElement;
  published
    property Align;
    property AlignWithMargins;
    property Anchors;
    property BiDiMode;
    property BorderStyle;
    property ButtonImageURL;
    property ChildOrder;
    property Color;
    property Control;
    property DragMode;
    property Font;
    property ElementButtonClassName;
    property ElementClassName;
    property ElementInputClassName;
    property ElementID;
    property ElementFont;
    property ElementPosition;
    property Enabled;
    property Height;
    property HeightPercent;
    property HeightStyle;
    property Hint;
    property Left;
    property ParentColor;
    property ParentFont;
    property PopupMenu;
    property Role;
    property ShowFocus;
    property ShowHint;
    property TabOrder;
    property TabStop;
    property Text;
    property TextDirection;
    property Top;
    property Visible;
    property Width;
    property WidthPercent;
    property WidthStyle;
    property OnClick;
    property OnCloseUp;
    property OnDblClick;
    property OnDropDown;
    property OnKeyDown;
    property OnKeyPress;
    property OnKeyUp;
    property OnMouseDown;
    property OnMouseUp;
    property OnMouseMove;
    property OnMouseLeave;
    property OnMouseEnter;
    property OnMouseWheel;
    property OnEnter;
    property OnExit;
    property OnDragDrop;
    property OnDragOver;
    property OnEndDrag;
    property OnStartDrag;
  end;

  TWebEditDropDownControl = class(TEditDropDownControl);

  TTableOptions = class(TPersistent)
  private
    FOwner: TComponent;
    FElementSelectionClassName: TElementClassName;
    FElementFont: TELementFont;
    FElementHeaderClassName: TElementClassName;
    FElementRowSelectClassName: TElementClassName;
    FElementClassName: TELementClassName;
    FElementTableClassName: TElementClassName;
    FHeader: TTableControlHeader;
    FFooter: TTableControlHeader;
    FPaging: TTableControlPaging;
    FRowHeader: boolean;
    FSelectionTextColor: TColor;
    FSelectionColor: TColor;
    FColHeader: boolean;
    FScrollVertical: boolean;
    FResizeColumns: boolean;
    FAutoCellURL: boolean;
    FAutoCellEmail: boolean;
    FAutoCellImage: boolean;
    FCellBorderColor: TColor;
    FCellBorders: boolean;
    FImageAlign: TAlignment;
    FImageWidth: integer;
    procedure SetFooter(const Value: TTableControlHeader);
    procedure SetHeader(const Value: TTableControlHeader);
    procedure SetPaging(const Value: TTableControlPaging);
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(AOwner: TComponent);
    destructor Destroy; override;
    property Owner: TComponent read FOwner;
  published
    property AutoCellEmail: boolean read FAutoCellEmail write FAutoCellEmail default false;
    property AutoCellURL: boolean read FAutoCellURL write FAutoCellURL default false;
    property AutoCellImage: boolean read FAutoCellImage write FAutoCellImage default false;
    property CellBorders: boolean read FCellBorders write FCellBorders default false;
    property CellBorderColor: TColor read FCellBorderColor write FCellBorderColor default clSilver;
    property ColHeader: boolean read FColHeader write FColHeader default true;
    property ElementClassName: TELementClassName read FElementClassName write FElementClassName;
    property ElementFont: TELementFont read FElementFont write FElementFont;
    property ElementHeaderClassName: TElementClassName read FElementHeaderClassName write FElementHeaderClassName;
    property ElementRowSelectClassName: TElementClassName read FElementRowSelectClassName write FElementRowSelectClassName;
    property ElementSelectionClassName: TElementClassName read FElementSelectionClassName write FElementSelectionClassName;
    property ElementTableClassName: TElementClassName read FElementTableClassName write FElementTableClassName;
    property ImageAlign: TAlignment read FImageAlign write FImageAlign default taLeftJustify;
    property ImageWidth: integer read FImageWidth write FImageWidth default 0;
    property Header: TTableControlHeader read FHeader write SetHeader;
    property Footer: TTableControlHeader read FFooter write SetFooter;
    property Paging: TTableControlPaging read FPaging write SetPaging;
    property ResizeColumns: boolean read FResizeColumns write FResizeColumns default false;
    property RowHeader: boolean read FRowHeader write FRowHeader default true;
    property ScrollVertical: boolean read FScrollVertical write FScrollVertical default false;
    property SelectionColor: TColor read FSelectionColor write FSelectionColor default $00FF8F5B;
    property SelectionTextColor: TColor read FSelectionTextColor write FSelectionTextColor default clWhite;
  end;

  TEditDropDownTableControl = class(TEditDropDownControl)
  private
    FTableControl: TCustomTableControl;
    FEditColumn: integer;
    FTableOptions: TTableOptions;
    procedure SetTableOptions(const Value: TTableOptions);
  protected
    function CreateTable(AOwner: TComponent): TCustomTableControl; virtual;
    function GetTableControl: TWebTableControl;
    procedure CreateInitialize; override;
    procedure SetParent(AValue: TControl); override;
    procedure TableClickCell(Sender: TObject; ACol, ARow: integer);
    procedure TableKeyPress(Sender: TObject; var AChar: Char);
    procedure TableKeyDown(Sender: TObject; var AKey: Word; Shift: TShiftState);
    property TableControl: TCustomTableControl read FTableControl;
  public
    constructor Create(AOwner: TComponent); override;
    procedure ShowDropDown; override;
    destructor Destroy; override;
    property Table: TWebTableControl read GetTableControl;

  published
    property EditColumn: integer read FEditColumn write FEditColumn default 0;
    property TableOptions: TTableOptions read FTableOptions write SetTableOptions;
  end;

  TWebEditDropDownTableControl = class(TEditDropDownTableControl);


implementation

uses
  SysUtils, Math;

{ TWebDropDownControl }

procedure TCustomDropDownControl.BindEvents;
begin
  inherited;

  if HasEdit and Assigned(FButton) then
    FButton.addEventListener('click',FDoBtnClick);
end;

procedure TCustomDropDownControl.ClearMethodPointers;
begin
  inherited;
  FDoClickLayer := nil;
  FDoBtnClick := nil;
end;

procedure TCustomDropDownControl.Click;
begin
  inherited;

  if (ElementEvent.target = FButton) or (TJSElement(ElementEvent.target).parentElement = FButton) then
  begin
    ToggleDropDown;
  end
  else
    if AutoDropDown then
      ToggleDropDown;
end;

procedure TCustomDropDownControl.CreateChildElements(AContainer: TJSElement);
var
  r: TJSDOMRect;
begin
  if HasEdit then
  begin
    FTextElement := TJSHTMLElement(document.createElement('INPUT'));
    FTextElement['type'] := 'TEXT';
    if ElementFont = efProperty then
      SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);
  end
  else
  begin
    FTextElement := TJSHTMLElement(document.createElement('DIV'));
    FTextElement['type'] := 'TEXT';
    FTextElement.style.setProperty('padding-left','2px');
    FTextElement.style.setProperty('padding-right','2px');
    FTextElement.style.setProperty('text-overflow','ellipsis');
    if ElementFont = efProperty then
      SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);
  end;

  FButton := TJSHTMLElement(document.createElement('DIV'));

//  TJSHTMLElement(FTextElement).style.setProperty('background-color','white');
  TJSHTMLElement(FTextElement).style.setProperty('display','inline-block');
  TJSHTMLElement(FTextElement).style.setProperty('width','calc(100% - 26px)');

  r := AContainer.getBoundingClientRect;
  if r.height = 0 then
    r.height := 26;

  TJSHTMLElement(FTextElement).style.setProperty('height',r.height.ToString+'px');
  TJSHTMLElement(FTextElement).style.setProperty('box-sizing','border-box');
  TJSHTMLElement(FTextElement).style.setProperty('line-height', r.height.ToString + 'px');
  TJSHTMLElement(FTextElement).style.setProperty('vertical-align','middle');

  TJSHTMLElement(FButton).style.setProperty('background-color','silver');
  TJSHTMLElement(FButton).style.setProperty('display','inline-block');
  TJSHTMLElement(FButton).style.setProperty('width','25px');
  TJSHTMLElement(FButton).style.setProperty('height', r.height.ToString +'px');
  TJSHTMLElement(FButton).style.setProperty('line-height', r.height.ToString + 'px');
  TJSHTMLElement(FButton).style.setProperty('text-align','center');
  TJSHTMLElement(FButton).style.setProperty('vertical-align','middle');
  TJSHTMLElement(FButton).style.setProperty('padding','0px!important');

  FDropDown := TJSHTMLElement(document.createElement('DIV'));
  TJSHTMLElement(FDropDown).style.setProperty('position','absolute');
  TJSHTMLElement(FDropDown).style.setProperty('display','none');
  TJSHTMLElement(FDropDown).style.setProperty('background-color','#f1f1f1');
//  TJSHTMLElement(FDropDown).style.setProperty('min-width','160px');
  TJSHTMLElement(FDropDown).style.setProperty('box-shadow','0px 8px 8px 0px rgba(0,0,0,0.2)');
  TJSHTMLElement(FDropDown).style.setProperty('z-index','1');
//  TJSHTMLElement(FDropDown).style.setProperty('min-height','200px');
  //FDropDOwn.innerHTML := 'hello world<br>more content<br>is here<br>in the dropdown';

  FTextElement.innerHTML := '&nbsp;';
//  FButton.innerHTML := '&#x25BC;';

  AContainer.appendChild(FTextElement);
  AContainer.appendChild(FButton);

  FLayer := TJSHTMLElement(document.createElement('SPAN'));
  FLayer.style.setProperty('top', '0');
  FLayer.style.setProperty('left', '0');
  FLayer.style.setProperty('right', '0');
  FLayer.style.setProperty('bottom', '0');

  FLayer.style.setProperty('webkit-user-select', 'none');
  FLayer.style.setProperty('moz-user-select', 'none');
  FLayer.style.setProperty('khtml-user-select', 'none');
  FLayer.style.setProperty('ms-user-select', 'none');
  FLayer.style.setProperty('user-select', 'none');
  FLayer.style.setProperty('position', 'absolute');

  AddInstanceStyle('.arrow { border: solid black; border-width: 0 2px 2px 0;  display: inline-block;  padding: 2px;}'+#13#10+
     '.down { transform: rotate(45deg); -webkit-transform: rotate(45deg);};');

  FButton.innerHTML := '<i class="arrow down"></i>';
end;

function TCustomDropDownControl.CreateElement: TJSElement;
begin
  Result := document.createElement('DIV');
end;

procedure TCustomDropDownControl.CreateInitialize;
begin
  inherited;
  FAutoDropDown := false;
  FDropDownColor := clWhite;
  FDropDownWidth := 0;
  FDropDownHeight := 0;
  FDropDownShadow := true;
end;

procedure TCustomDropDownControl.DoCloseUp;
begin
  if Assigned(OnCloseUp) then
    OnCloseUp(Self);
end;

procedure TCustomDropDownControl.DoDropDown;
begin
  if Assigned(OnDropDown) then
    OnDropDown(Self);
end;

function TCustomDropDownControl.GetDroppedDown: boolean;
begin
  Result := FDropDown.style.getPropertyValue('display') <> 'none';
end;

procedure TCustomDropDownControl.GetMethodPointers;
begin
  inherited;
  if FDoClickLayer = nil then
  begin
    FDoClickLayer := @HandleDoClickLayer;
    FDoBtnClick := @HandleDoBtnClick;
  end;
end;

function TCustomDropDownControl.GetText: string;
begin
  Result := FText;

  if HasEdit then
  begin
    asm
      Result = this.FTextElement.value;
    end;
  end;
end;

procedure TCustomDropDownControl.SetButtonImageURL(const Value: string);
begin
  if (FButtonImageURL <> Value) then
  begin
    FButtonImageURL := Value;
    UpdateELement;
  end;
end;

procedure TCustomDropDownControl.SetControl(const Value: TCustomControl);
begin
  FControl := Value;
  if Assigned(FControl) and not (csDesigning in ComponentState) then
    FControl.Visible := false;
end;

procedure TCustomDropDownControl.SetReadOnly(const Value: Boolean);
begin
  if (FReadOnly <> Value) then
  begin
    FReadOnly := Value;
    UpdateElement;
  end;
end;

procedure TCustomDropDownControl.SetText(const Value: string);
begin
  FText := Value;

  if HasEdit then
  begin
    asm
      this.FTextElement.value = Value;
    end;
  end
  else
  begin
    if Value <> '' then
      FTextElement.innerHTML := Value
    else
      FTextElement.innerHTML := '&nbsp;';
  end;
end;

procedure TCustomDropDownControl.ShowDropDown;
var
  dr: TJSDOMRect;
  w,h: single;
begin
  if not Assigned(ElementHandle) then
    Exit;

  FLayer.style.setProperty('z-index', Application.MaxZIndexStr);
  document.body.appendChild(FLayer);

  FLayer.addEventListener('click',FDoClickLayer);

  FLayer.appendChild(FDropDown);

  FDropDown.style.setProperty('display','table');

  if Assigned(Control) then
  begin
    Control.Left := 0;
    Control.Top := 0;
    Control.ElementPosition := epRelative;
    Control.ElementFont := ElementFont;
    Control.Visible := true;

    dr := ElementHandle.getBoundingClientRect;

    FDropDown.style.setProperty('left', dr.Left.ToString + 'px');
    FDropDown.style.setProperty('top', dr.Bottom.ToString + 'px');

    dr := Control.ElementHandle.getBoundingClientRect;

    if DropDownWidth > 0 then
      w := DropDownWidth
    else
    begin
      if Assigned(Control) then
      begin
        w := Max(dr.Width, Control.Width);
      end
      else
        w := dr.width;
    end;

    if DropDownWidth > 0 then
      FDropDown.style.setProperty('width', w.ToString + 'px');

    if DropDownHeight > 0 then
      h := DropDownHeight
    else
      h := Max(dr.Height, Control.Height);

    FDropDown.style.setProperty('height', h.ToString + 'px');

    if DropDownColor <> clNone then
      FDropDown.style.setProperty('background-color', ColorToHTML(FDropDownColor));

    FDropDown.appendChild(Control.ElementHandle);
  end;

  if DropDownShadow then
    TJSHTMLElement(FDropDown).style.setProperty('box-shadow','0px 8px 8px 0px rgba(0,0,0,0.2)');

  DoDropDown;
end;

procedure TCustomDropDownControl.ToggleDropDown;
begin
  if FDropDown.style.getPropertyValue('display') = 'none' then
    ShowDropDown
  else
    HideDropDown;
end;

procedure TCustomDropDownControl.UnBindEvents;
begin
  inherited;

  if HasEdit and Assigned(FButton) then
    FButton.removeEventListener('click',FDoBtnClick);
end;

{$HINTS OFF}
procedure TCustomDropDownControl.UpdateElementVisual;
var
  h: integer;
  ro: boolean;
begin
  inherited;

  if not HasEdit and (BorderStyle = bsSingle) then
    ElementHandle.style.setProperty('border','1px solid silver');

  if HasEdit then
  begin
    ro := ReadOnly;
    asm
      this.FTextElement.readOnly = ro;
    end;
  end;

  ElementHandle.style.removeProperty('overflow');

  h := Height - 1;

 // ElementHandle.style.setProperty('padding','2px');
  SetHTMLElementFont(FTextElement, Font, ElementFont = efCSS);

  TJSHTMLElement(FTextElement).style.setProperty('line-height', h.ToString +'px');
  TJSHTMLElement(FTextElement).style.setProperty('height', h.ToString +'px');

  TJSHTMLElement(FButton).style.setProperty('height', h.ToString +'px');
  TJSHTMLElement(FButton).style.setProperty('line-height', h.ToString +'px');
  TJSHTMLElement(FButton).setAttribute('class', FElementButtonClassName);
  TJSHTMLElement(FButton).style.setProperty('padding', '0px');

  if FElementButtonClassName <> '' then
    TJSHTMLElement(FButton).style.removeProperty('background-color');

  if ButtonImageURL <> '' then
    FButton.innerHTML := '<img src="'+ ButtonImageURL+'">'
  else
    FButton.innerHTML := '<i class="arrow down"></i>';

  if FElementInputClassName <> '' then
    FTextElement.classList.add(FElementInputClassName)
  else
    if FTextElement.classList.contains(FElementInputClassName) then
      FTextElement.classList.remove(FElementInputClassName);
end;
{$HINTS ON}

function TCustomDropDownControl.HandleDoBtnClick(Event: TJSMouseEvent): Boolean;
begin
  ToggleDropDown;
  Result := true;
end;

function TCustomDropDownControl.HandleDoClickLayer(Event: TJSMouseEvent): Boolean;
begin
  HideDropDown;
  Result := true;
end;

function TCustomDropDownControl.HasEdit: boolean;
begin
  Result := false;
end;

procedure TCustomDropDownControl.HideDropDown;
begin
  DoCloseUp;
  FLayer.removeEventListener('click',FDoClickLayer);
  FLayer.parentElement.removeChild(FLayer);
  FDropDown.style.setProperty('display','none');
  FDropDown.parentElement.removeChild(FDropDown);
end;

{ TEditDropDownControl }

function TEditDropDownControl.GetEditElement: TJSHTMLElement;
begin
  Result := TJSHTMLElement(FTextElement);
end;

function TEditDropDownControl.GetElementBindHandle: TJSEventTarget;
begin
  Result := TJSHTMLElement(FTextElement);
end;

function TEditDropDownControl.HasEdit: boolean;
begin
  Result := true;
end;

procedure TEditDropDownControl.KeyDown(var Key: Word; Shift: TShiftState);
begin
  inherited;
  if Key = VK_F4 then
    ToggleDropDown;
end;

{ TEditDropDownTableControl }

constructor TEditDropDownTableControl.Create(AOwner: TComponent);
begin
  inherited;
end;

procedure TEditDropDownTableControl.CreateInitialize;
begin
  inherited;
  FTableControl := CreateTable(Owner);
  FTableControl.Visible := false;
  FTableControl.Options.ScrollVertical := true;
  FTableControl.OnClickCell := TableClickCell;
  FTableControl.OnKeyPress := TableKeyPress;
  FTableControl.OnKeyDown := TableKeyDown;
  FTableControl.WidthStyle := ssAuto;
  Control := FTableControl;

  FTableOptions := TTableOptions.Create(FTableControl);
end;

function TEditDropDownTableControl.CreateTable(Aowner: TComponent): TCustomTableControl;
begin
  Result := TWebTableControl.Create(Owner);
end;

destructor TEditDropDownTableControl.Destroy;
begin
  FTableControl.Free;
  FTableOptions.Free;
  inherited;
end;

function TEditDropDownTableControl.GetTableControl: TWebTableControl;
begin
  Result := TWebTableControl(FTableControl);
end;

procedure TEditDropDownTableControl.SetParent(AValue: TControl);
begin
  inherited;
  FTableControl.Parent := AValue;
  FTableControl.InitSample(imLinear);
end;

procedure TEditDropDownTableControl.SetTableOptions(const Value: TTableOptions);
begin
  FTableOptions.Assign(Value);
end;

procedure TEditDropDownTableControl.ShowDropDown;
var
  gc: TGridCoord;
begin
  inherited;

  if DropDownWidth = 0 then
    FTableControl.WidthStyle := ssAuto
  else
    FTableControl.Width := DropDownWidth;

  if DropDownHeight = 0 then
    FTableControl.HeightStyle := ssAuto
  else
    FTableControl.Height := DropDownHeight;

  FTableControl.Options.AutoCellEMail := TableOptions.AutoCellEmail;
  FTableControl.Options.AutoCellURL := TableOptions.AutoCellURL;
  FTableControl.Options.AutoCellImage := TableOptions.AutoCellImage;
  FTableControl.Options.ScrollVertical := TableOptions.ScrollVertical;
  FTableControl.Options.ResizeColumns := TableOptions.ResizeColumns;
  FTableControl.Options.CellBorders := TableOptions.CellBorders;
  FTableControl.Options.CellBorderColor := TableOptions.CellBorderColor;
  FTableControl.Options.ImageAlign := TableOptions.ImageAlign;
  FTableControl.Options.ImageWidth := TableOptions.ImageWidth;

  FTableControl.ElementClassName := TableOptions.ElementClassName;
  FTableControl.ElementFont := TableOptions.ElementFont;
  FTableControl.ElementTableClassName := TableOptions.ElementTableClassName;
  FTableControl.ElementRowSelectClassName := TableOptions.ElementRowSelectClassName;
  FTableControl.ELementHeaderClassName := TableOptions.ElementHeaderClassName;
  FTableControl.ElementSelectionClassName := TableOptions.ElementSelectionClassName;

  FTableControl.Paging.Assign(TableOptions.Paging);
  FTableControl.Header.Assign(TableOptions.Header);
  FTableControl.Footer.Assign(TableOptions.Footer);

  FTableControl.Invalidate;

  gc := FTableControl.FindCell(Text, false, true);

  if (gc.X <> -1)and (gc.Y <> -1) then
  begin
    FTableControl.RowIndex := gc.Y;
  end;

  FTableControl.SetFocus;
end;

procedure TEditDropDownTableControl.TableClickCell(Sender: TObject; ACol,
  ARow: integer);
begin
  Text := FTableControl.Cells[EditColumn, ARow];
  HideDropDown;
  EditElement.focus;
end;

procedure TEditDropDownTableControl.TableKeyDown(Sender: TObject;
  var AKey: Word; Shift: TShiftState);
begin
  if AKey = VK_F4 then
  begin
    HideDropDown;
    EditElement.focus;
  end;
end;

procedure TEditDropDownTableControl.TableKeyPress(Sender: TObject;
  var AChar: Char);
begin
  if AChar = #13 then
  begin
    TableClickCell(FTableControl, EditColumn, FTableControl.RowIndex);
  end;
end;

{ TTableOptions }

procedure TTableOptions.Assign(Source: TPersistent);
begin
  if (Source is TTableOptions) then
  begin
    FAutoCellURL := (Source as TTableOptions).AutoCellURL;
    FAutoCellImage := (Source as TTableOptions).AutoCellImage;
    FAutoCellEmail := (Source as TTableOptions).AutoCellEmail;
    FElementClassName := (Source as TTableOptions).ElementClassName;
    FElementFont := (Source as TTableOptions).ElementFont;
    FElementHeaderClassName := (Source as TTableOptions).ElementHeaderClassName;
    FElementRowSelectClassName := (Source as TTableOptions).ElementRowSelectClassName;
    FElementSelectionClassName := (Source as TTableOptions).ElementSelectionClassName;
    FElementTableClassName := (Source as TTableOptions).ElementTableClassName;
    FHeader.Assign((Source as TTableOptions).Header);
    FFooter.Assign((Source as TTableOptions).Footer);
    FPaging.Assign((Source as TTableOptions).Paging);
    FResizeColumns := (Source as TTableOptions).ResizeColumns;
    FColHeader := (Source as TTableOptions).ColHeader;
    FRowHeader := (Source as TTableOptions).RowHeader;
    FScrollVertical := (Source as TTableOptions).ScrollVertical;
    FSelectionColor := (Source as TTableOptions).SelectionColor;
    FSelectionTextColor := (Source as TTableOptions).SelectionTextColor;
    FCellBorders := (Source as TTableOptions).CellBorders;
    FCellBorderColor := (Source as TTableOptions).CellBorderColor;
    FImageWidth := (Source as TTableOptions).ImageWidth;
    FImageAlign := (Source as TTableOptions).ImageAlign;
  end;
end;

constructor TTableOptions.Create(AOwner: TComponent);
begin
  FOwner := AOwner;
  FHeader := TTableControlHeader.Create(AOwner as TCustomTableControl);
  FFooter := TTableControlHeader.Create(AOwner as TCustomTableControl);
  FPaging := TTableControlPaging.Create(AOwner as TCustomTableControl);
  FRowHeader := true;
  FColHeader := true;
  FSelectionTextColor := clWhite;
  FSelectionColor := $00FF8F5B;
  FCellBorderColor := clSilver;
end;

destructor TTableOptions.Destroy;
begin
  FPaging.Free;
  FHeader.Free;
  FFooter.Free;
  inherited;
end;

procedure TTableOptions.SetFooter(const Value: TTableControlHeader);
begin
  FFooter.Assign(Value);
end;

procedure TTableOptions.SetHeader(const Value: TTableControlHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTableOptions.SetPaging(const Value: TTableControlPaging);
begin
  FPaging.Assign(Value);
end;

end.
