unit SettleUp;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFSettleUp = class(TForm)
    SettleUpPnl: TPanel;
    DSPayments: TXDataWebDataSet;
    DSPaymentsAutoInc: TIntegerField;
    DSPaymentsInvoice: TIntegerField;
    DSPaymentsCust: TIntegerField;
    DSPaymentsPaymentType: TStringField;
    DSPaymentsCheckNumber: TIntegerField;
    DSPaymentsPaymentAmount: TFloatField;
    DSPaymentsDate: TDateTimeField;
    DSPaymentsRoute: TIntegerField;
    DSPaymentsDriverNum: TIntegerField;
    WDSPayments: TDataSource;
    WebDBGrid1: TDBGrid;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebButton2: TButton;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure DSPaymentsAfterOpen(Dataset: TDataSet);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FSettleUp: TFSettleUp;
  CashAmt, CheckAmt, PCheckAmt, TotalAmt: Double;

implementation

Uses Data, Options;

{$R *.dfm}

procedure TFSettleUp.DSPaymentsAfterOpen(Dataset: TDataSet);
begin
  CashAmt := 0;
  CheckAmt := 0;
  TotalAmt := 0;
  PCheckAmt := 0;
  while not DSPayments.Eof do
  begin
    if DSPaymentsPaymentType.AsString = 'Cash' then
      CashAmt := CashAmt + DSPaymentsPaymentAmount.AsFloat;
    if DSPaymentsPaymentType.AsString = 'Expense' then
      CashAmt := CashAmt - DSPaymentsPaymentAmount.AsFloat;
    if DSPaymentsPaymentType.AsString = 'Check' then
      CheckAmt := CheckAmt + DSPaymentsPaymentAmount.AsFloat;
    if DSPaymentsPaymentType.AsString = 'Postdated Check' then
      PCheckAmt := PCheckAmt + DSPaymentsPaymentAmount.AsFloat;
    TotalAmt := TotalAmt + DSPaymentsPaymentAmount.AsFloat;
    DSPayments.Next;
  end;
  WebLabel4.Caption := FloatToStrF(CashAmt, ffFixed, 8, 2);
  WebLabel5.Caption := FloatToStrF(CheckAmt, ffFixed, 8, 2);
  WebLabel8.Caption := FloatToStrF(PCheckAmt, ffFixed, 8, 2);
  WebLabel6.Caption := FloatToStrF(TotalAmt, ffFixed, 8, 2);
end;

procedure TFSettleUp.WebButton2Click(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFSettleUp.WebFormResize(Sender: TObject);
begin
  SettleUpPnl.Left := Round((FSettleUp.Width / 2) - (SettleUpPnl.Width / 2));
  if SettleUpPnl.Top < 0 then
    SettleUpPnl.Top := 0;
end;

procedure TFSettleUp.WebFormShow(Sender: TObject);
begin
  SettleUpPnl.Left := Round((FSettleUp.Width / 2) - (SettleUpPnl.Width / 2));
  SettleUpPnl.Top := Round((FSettleUp.Height / 2) - (SettleUpPnl.Height / 2));
  FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
  DSPayments.QueryString := '$filter=(Date eq ''' + DateToStr(Now) +
    ''' AND DriverNum eq ' + Dm.DSLoginNumber.AsString + ' AND Route eq ' +
    Dm.DSLoginLastRoute.AsString + ')';
  DSPayments.Load;
  FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
end;

procedure TFSettleUp.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SettleUpPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton2 := TButton.Create(Self);
  DSPayments := TXDataWebDataSet.Create(Self);
  DSPaymentsAutoInc := TIntegerField.Create(Self);
  DSPaymentsInvoice := TIntegerField.Create(Self);
  DSPaymentsCust := TIntegerField.Create(Self);
  DSPaymentsPaymentType := TStringField.Create(Self);
  DSPaymentsCheckNumber := TIntegerField.Create(Self);
  DSPaymentsPaymentAmount := TFloatField.Create(Self);
  DSPaymentsDate := TDateTimeField.Create(Self);
  DSPaymentsRoute := TIntegerField.Create(Self);
  DSPaymentsDriverNum := TIntegerField.Create(Self);
  WDSPayments := TDataSource.Create(Self);

  SettleUpPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  DSPayments.BeforeLoadDFMValues;
  DSPaymentsAutoInc.BeforeLoadDFMValues;
  DSPaymentsInvoice.BeforeLoadDFMValues;
  DSPaymentsCust.BeforeLoadDFMValues;
  DSPaymentsPaymentType.BeforeLoadDFMValues;
  DSPaymentsCheckNumber.BeforeLoadDFMValues;
  DSPaymentsPaymentAmount.BeforeLoadDFMValues;
  DSPaymentsDate.BeforeLoadDFMValues;
  DSPaymentsRoute.BeforeLoadDFMValues;
  DSPaymentsDriverNum.BeforeLoadDFMValues;
  WDSPayments.BeforeLoadDFMValues;
  try
    Name := 'FSettleUp';
    Width := 412;
    Height := 770;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    SettleUpPnl.SetParentComponent(Self);
    SettleUpPnl.Name := 'SettleUpPnl';
    SettleUpPnl.Left := 16;
    SettleUpPnl.Top := 16;
    SettleUpPnl.Width := 377;
    SettleUpPnl.Height := 745;
    SettleUpPnl.ElementClassName := 'card';
    SettleUpPnl.ElementBodyClassName := 'card-body';
    SettleUpPnl.Font.Charset := DEFAULT_CHARSET;
    SettleUpPnl.Font.Color := clWindowText;
    SettleUpPnl.Font.Height := -16;
    SettleUpPnl.Font.Name := 'Arial';
    SettleUpPnl.Font.Style := [];
    SettleUpPnl.ParentFont := False;
    WebLabel1.SetParentComponent(SettleUpPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 176;
    WebLabel1.Top := 544;
    WebLabel1.Width := 77;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Total Cash:';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(SettleUpPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 160;
    WebLabel2.Top := 568;
    WebLabel2.Width := 93;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Total Checks:';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(SettleUpPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 144;
    WebLabel3.Top := 632;
    WebLabel3.Width := 109;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Total Payments:';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(SettleUpPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 288;
    WebLabel4.Top := 544;
    WebLabel4.Width := 58;
    WebLabel4.Height := 18;
    WebLabel4.Alignment := taRightJustify;
    WebLabel4.Caption := '9999.99';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(SettleUpPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 288;
    WebLabel5.Top := 568;
    WebLabel5.Width := 58;
    WebLabel5.Height := 18;
    WebLabel5.Alignment := taRightJustify;
    WebLabel5.Caption := '9999.99';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(SettleUpPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 288;
    WebLabel6.Top := 632;
    WebLabel6.Width := 58;
    WebLabel6.Height := 18;
    WebLabel6.Alignment := taRightJustify;
    WebLabel6.Caption := '9999.99';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(SettleUpPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 120;
    WebLabel7.Top := 592;
    WebLabel7.Width := 133;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Postdated Checks:';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(SettleUpPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 288;
    WebLabel8.Top := 592;
    WebLabel8.Width := 58;
    WebLabel8.Height := 18;
    WebLabel8.Alignment := taRightJustify;
    WebLabel8.Caption := '9999.99';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebDBGrid1.SetParentComponent(SettleUpPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 16;
    WebDBGrid1.Top := 16;
    WebDBGrid1.Width := 345;
    WebDBGrid1.Height := 521;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Invoice';
      Title := 'Invoice';
      Width := 68;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Cust';
      Title := 'Cust';
      Width := 52;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'PaymentType';
      Title := 'Type';
      Width := 60;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'CheckNumber';
      Title := 'C#';
      Width := 69;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      ElementClassName := 'text-right';
      DataField := 'PaymentAmount';
      Title := 'Amount';
      Width := 76;
    end;
    WebDBGrid1.DataSource := WDSPayments;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 68;
    WebDBGrid1.ColWidths[1] := 52;
    WebDBGrid1.ColWidths[2] := 60;
    WebDBGrid1.ColWidths[3] := 69;
    WebDBGrid1.ColWidths[4] := 76;
    WebButton2.SetParentComponent(SettleUpPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 216;
    WebButton2.Top := 672;
    WebButton2.Width := 145;
    WebButton2.Height := 65;
    WebButton2.Caption := 'Close';
    WebButton2.ChildOrder := 16;
    WebButton2.ElementClassName := 'btn btn-danger';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    DSPayments.SetParentComponent(Self);
    DSPayments.Name := 'DSPayments';
    DSPayments.AfterOpen := DSPaymentsAfterOpen;
    DSPayments.EntitySetName := 'payments';
    DSPayments.Connection := Dm.ApiConnection;
    DSPayments.Left := 24;
    DSPayments.Top := 608;
    DSPaymentsAutoInc.SetParentComponent(DSPayments);
    DSPaymentsAutoInc.Name := 'DSPaymentsAutoInc';
    DSPaymentsAutoInc.FieldName := 'AutoInc';
    DSPaymentsAutoInc.Required := True;
    DSPaymentsInvoice.SetParentComponent(DSPayments);
    DSPaymentsInvoice.Name := 'DSPaymentsInvoice';
    DSPaymentsInvoice.FieldName := 'Invoice';
    DSPaymentsCust.SetParentComponent(DSPayments);
    DSPaymentsCust.Name := 'DSPaymentsCust';
    DSPaymentsCust.FieldName := 'Cust';
    DSPaymentsPaymentType.SetParentComponent(DSPayments);
    DSPaymentsPaymentType.Name := 'DSPaymentsPaymentType';
    DSPaymentsPaymentType.FieldName := 'PaymentType';
    DSPaymentsPaymentType.Size := 10;
    DSPaymentsCheckNumber.SetParentComponent(DSPayments);
    DSPaymentsCheckNumber.Name := 'DSPaymentsCheckNumber';
    DSPaymentsCheckNumber.FieldName := 'CheckNumber';
    DSPaymentsPaymentAmount.SetParentComponent(DSPayments);
    DSPaymentsPaymentAmount.Name := 'DSPaymentsPaymentAmount';
    DSPaymentsPaymentAmount.FieldName := 'PaymentAmount';
    DSPaymentsPaymentAmount.DisplayFormat := '0.00';
    DSPaymentsDate.SetParentComponent(DSPayments);
    DSPaymentsDate.Name := 'DSPaymentsDate';
    DSPaymentsDate.FieldName := 'Date';
    DSPaymentsRoute.SetParentComponent(DSPayments);
    DSPaymentsRoute.Name := 'DSPaymentsRoute';
    DSPaymentsRoute.FieldName := 'Route';
    DSPaymentsDriverNum.SetParentComponent(DSPayments);
    DSPaymentsDriverNum.Name := 'DSPaymentsDriverNum';
    DSPaymentsDriverNum.FieldName := 'DriverNum';
    WDSPayments.SetParentComponent(Self);
    WDSPayments.Name := 'WDSPayments';
    WDSPayments.DataSet := DSPayments;
    WDSPayments.Left := 40;
    WDSPayments.Top := 648;
  finally
    SettleUpPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    DSPayments.AfterLoadDFMValues;
    DSPaymentsAutoInc.AfterLoadDFMValues;
    DSPaymentsInvoice.AfterLoadDFMValues;
    DSPaymentsCust.AfterLoadDFMValues;
    DSPaymentsPaymentType.AfterLoadDFMValues;
    DSPaymentsCheckNumber.AfterLoadDFMValues;
    DSPaymentsPaymentAmount.AfterLoadDFMValues;
    DSPaymentsDate.AfterLoadDFMValues;
    DSPaymentsRoute.AfterLoadDFMValues;
    DSPaymentsDriverNum.AfterLoadDFMValues;
    WDSPayments.AfterLoadDFMValues;
  end;
end;

end.
