unit Statement;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFStatement = class(TForm)
    StatementPnl: TPanel;
    DSState: TXDataWebDataSet;
    DSStateTot: TXDataWebDataSet;
    WDSState: TDataSource;
    WebDBGrid1: TDBGrid;
    DSStateAuto: TIntegerField;
    DSStateCustNum: TIntegerField;
    DSStateInvDate: TDateTimeField;
    DSStateInvNum: TIntegerField;
    DSStateInvTyp: TStringField;
    DSStateInvRef: TStringField;
    DSStateInvAmt: TFloatField;
    DSStateInvBal: TFloatField;
    DSStateAgeing: TIntegerField;
    DSStateApplyTo: TIntegerField;
    DSStateTotAuto: TIntegerField;
    DSStateTotCusNum: TIntegerField;
    DSStateTotTotal: TFloatField;
    DSStateTotAge1: TFloatField;
    DSStateTotAge2: TFloatField;
    DSStateTotAge3: TFloatField;
    DSStateTotAge4: TFloatField;
    DSStateTotAge5: TFloatField;
    DSStateTotCreditHoldFlag: TStringField;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebDBLabel3: TDBLabel;
    WDSStateTot: TDataSource;
    WebDBLabel4: TDBLabel;
    WebDBLabel5: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel7: TDBLabel;
    WebDBLabel8: TDBLabel;
    WebButton4: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure DSStateAfterOpen(Dataset: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FStatement: TFStatement;
  FormCreatedBy: String;

implementation

Uses Data, Payment, OrderDetail;

{$R *.dfm}

procedure TFStatement.DSStateAfterOpen(Dataset: TDataSet);
begin
  DSState.Last;
end;

procedure TFStatement.WebButton4Click(Sender: TObject);
begin
  if FormCreatedBy = 'Payment' then
  begin
    Application.CreateForm(TFPayment, FPayment);
  end
  else if FormCreatedBy = 'OrderDetail' then
  begin
    Application.CreateForm(TFOrderDtl, FOrderDtl);
  end
  else
  begin
    Application.CreateForm(TFOrderDtl, FOrderDtl);
  end;
end;

procedure TFStatement.WebFormResize(Sender: TObject);
begin
  StatementPnl.Left := Round((FStatement.Width / 2) - (StatementPnl.Width / 2));
  StatementPnl.Top := Round((FStatement.Height / 2) -
    (StatementPnl.Height / 2));
  if StatementPnl.Left < 0 then
    StatementPnl.Left := 0;
  if StatementPnl.Top < 0 then
    StatementPnl.Top := 0;
end;

procedure TFStatement.WebFormShow(Sender: TObject);
begin
  StatementPnl.Left := Round((FStatement.Width / 2) - (StatementPnl.Width / 2));
  StatementPnl.Top := Round((FStatement.Height / 2) -
    (StatementPnl.Height / 2));
  if StatementPnl.Left < 0 then
    StatementPnl.Left := 0;
  if StatementPnl.Top < 0 then
    StatementPnl.Top := 0;

  DSStateTot.QueryString := '$filter=(CusNum eq ' +
    Dm.DSCurrStopOrdersCust.AsString + ')';
  DSStateTot.Load;
  DSState.QueryString := '$filter=(CustNum eq ' +
    Dm.DSCurrStopOrdersCust.AsString + ')&$orderby=Auto';
  DSState.Load;
end;

procedure TFStatement.LoadDFMValues;
begin
  inherited LoadDFMValues;

  StatementPnl := TPanel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebDBLabel7 := TDBLabel.Create(Self);
  WebDBLabel8 := TDBLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton4 := TButton.Create(Self);
  DSState := TXDataWebDataSet.Create(Self);
  DSStateAuto := TIntegerField.Create(Self);
  DSStateCustNum := TIntegerField.Create(Self);
  DSStateInvDate := TDateTimeField.Create(Self);
  DSStateInvNum := TIntegerField.Create(Self);
  DSStateInvTyp := TStringField.Create(Self);
  DSStateInvRef := TStringField.Create(Self);
  DSStateInvAmt := TFloatField.Create(Self);
  DSStateInvBal := TFloatField.Create(Self);
  DSStateAgeing := TIntegerField.Create(Self);
  DSStateApplyTo := TIntegerField.Create(Self);
  DSStateTot := TXDataWebDataSet.Create(Self);
  DSStateTotAuto := TIntegerField.Create(Self);
  DSStateTotCusNum := TIntegerField.Create(Self);
  DSStateTotTotal := TFloatField.Create(Self);
  DSStateTotAge1 := TFloatField.Create(Self);
  DSStateTotAge2 := TFloatField.Create(Self);
  DSStateTotAge3 := TFloatField.Create(Self);
  DSStateTotAge4 := TFloatField.Create(Self);
  DSStateTotAge5 := TFloatField.Create(Self);
  DSStateTotCreditHoldFlag := TStringField.Create(Self);
  WDSState := TDataSource.Create(Self);
  WDSStateTot := TDataSource.Create(Self);

  StatementPnl.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel7.BeforeLoadDFMValues;
  WebDBLabel8.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  DSState.BeforeLoadDFMValues;
  DSStateAuto.BeforeLoadDFMValues;
  DSStateCustNum.BeforeLoadDFMValues;
  DSStateInvDate.BeforeLoadDFMValues;
  DSStateInvNum.BeforeLoadDFMValues;
  DSStateInvTyp.BeforeLoadDFMValues;
  DSStateInvRef.BeforeLoadDFMValues;
  DSStateInvAmt.BeforeLoadDFMValues;
  DSStateInvBal.BeforeLoadDFMValues;
  DSStateAgeing.BeforeLoadDFMValues;
  DSStateApplyTo.BeforeLoadDFMValues;
  DSStateTot.BeforeLoadDFMValues;
  DSStateTotAuto.BeforeLoadDFMValues;
  DSStateTotCusNum.BeforeLoadDFMValues;
  DSStateTotTotal.BeforeLoadDFMValues;
  DSStateTotAge1.BeforeLoadDFMValues;
  DSStateTotAge2.BeforeLoadDFMValues;
  DSStateTotAge3.BeforeLoadDFMValues;
  DSStateTotAge4.BeforeLoadDFMValues;
  DSStateTotAge5.BeforeLoadDFMValues;
  DSStateTotCreditHoldFlag.BeforeLoadDFMValues;
  WDSState.BeforeLoadDFMValues;
  WDSStateTot.BeforeLoadDFMValues;
  try
    Name := 'FStatement';
    Width := 412;
    Height := 734;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    StatementPnl.SetParentComponent(Self);
    StatementPnl.Name := 'StatementPnl';
    StatementPnl.Left := 8;
    StatementPnl.Top := 8;
    StatementPnl.Width := 396;
    StatementPnl.Height := 721;
    StatementPnl.ElementClassName := 'card';
    StatementPnl.ElementBodyClassName := 'card-body';
    StatementPnl.Font.Charset := DEFAULT_CHARSET;
    StatementPnl.Font.Color := clWindowText;
    StatementPnl.Font.Height := -13;
    StatementPnl.Font.Name := 'Arial';
    StatementPnl.Font.Style := [];
    StatementPnl.ParentFont := False;
    WebDBLabel1.SetParentComponent(StatementPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 11;
    WebDBLabel1.Top := 16;
    WebDBLabel1.Width := 50;
    WebDBLabel1.Height := 22;
    WebDBLabel1.Caption := 'Cust#';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -19;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Cust';
    WebDBLabel1.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel2.SetParentComponent(StatementPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 80;
    WebDBLabel2.Top := 16;
    WebDBLabel2.Width := 50;
    WebDBLabel2.Height := 22;
    WebDBLabel2.Caption := 'Name';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -19;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'ShipName';
    WebDBLabel2.DataSource := Dm.WDSCurrStopOrders;
    WebLabel1.SetParentComponent(StatementPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 11;
    WebLabel1.Top := 56;
    WebLabel1.Width := 51;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Current';
    WebLabel1.ElementClassName := 'h6';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(StatementPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 87;
    WebLabel2.Top := 56;
    WebLabel2.Width := 38;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Age2';
    WebLabel2.ElementClassName := 'h6';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(StatementPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 159;
    WebLabel3.Top := 56;
    WebLabel3.Width := 38;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Age3';
    WebLabel3.ElementClassName := 'h6';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(StatementPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 231;
    WebLabel4.Top := 56;
    WebLabel4.Width := 38;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Age4';
    WebLabel4.ElementClassName := 'h6';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(StatementPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 304;
    WebLabel5.Top := 56;
    WebLabel5.Width := 38;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'Age5';
    WebLabel5.ElementClassName := 'h6';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(StatementPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 85;
    WebLabel6.Top := 104;
    WebLabel6.Width := 32;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Total';
    WebLabel6.ElementClassName := 'h6';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel3.SetParentComponent(StatementPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 11;
    WebDBLabel3.Top := 80;
    WebDBLabel3.Width := 67;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := '99999.99';
    WebDBLabel3.ElementClassName := 'h6';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Age1';
    WebDBLabel3.DataSource := WDSStateTot;
    WebDBLabel4.SetParentComponent(StatementPnl);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 87;
    WebDBLabel4.Top := 80;
    WebDBLabel4.Width := 67;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := '99999.99';
    WebDBLabel4.ElementClassName := 'h6';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -16;
    WebDBLabel4.Font.Name := 'Arial';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'Age2';
    WebDBLabel4.DataSource := WDSStateTot;
    WebDBLabel5.SetParentComponent(StatementPnl);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 159;
    WebDBLabel5.Top := 80;
    WebDBLabel5.Width := 67;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := '99999.99';
    WebDBLabel5.ElementClassName := 'h6';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Arial';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'Age3';
    WebDBLabel5.DataSource := WDSStateTot;
    WebDBLabel6.SetParentComponent(StatementPnl);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 231;
    WebDBLabel6.Top := 80;
    WebDBLabel6.Width := 67;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := '99999.99';
    WebDBLabel6.ElementClassName := 'h6';
    WebDBLabel6.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel6.Font.Color := clWindowText;
    WebDBLabel6.Font.Height := -16;
    WebDBLabel6.Font.Name := 'Arial';
    WebDBLabel6.Font.Style := [];
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.ParentFont := False;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'Age4';
    WebDBLabel6.DataSource := WDSStateTot;
    WebDBLabel7.SetParentComponent(StatementPnl);
    WebDBLabel7.Name := 'WebDBLabel7';
    WebDBLabel7.Left := 304;
    WebDBLabel7.Top := 80;
    WebDBLabel7.Width := 67;
    WebDBLabel7.Height := 18;
    WebDBLabel7.Caption := '99999.99';
    WebDBLabel7.ElementClassName := 'h6';
    WebDBLabel7.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel7.Font.Color := clWindowText;
    WebDBLabel7.Font.Height := -16;
    WebDBLabel7.Font.Name := 'Arial';
    WebDBLabel7.Font.Style := [];
    WebDBLabel7.HeightPercent := 100.000000000000000000;
    WebDBLabel7.ParentFont := False;
    WebDBLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel7.DataField := 'Age5';
    WebDBLabel7.DataSource := WDSStateTot;
    WebDBLabel8.SetParentComponent(StatementPnl);
    WebDBLabel8.Name := 'WebDBLabel8';
    WebDBLabel8.Left := 135;
    WebDBLabel8.Top := 104;
    WebDBLabel8.Width := 67;
    WebDBLabel8.Height := 18;
    WebDBLabel8.Caption := '99999.99';
    WebDBLabel8.ElementClassName := 'h6';
    WebDBLabel8.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel8.Font.Color := clWindowText;
    WebDBLabel8.Font.Height := -16;
    WebDBLabel8.Font.Name := 'Arial';
    WebDBLabel8.Font.Style := [];
    WebDBLabel8.HeightPercent := 100.000000000000000000;
    WebDBLabel8.ParentFont := False;
    WebDBLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel8.DataField := 'Total';
    WebDBLabel8.DataSource := WDSStateTot;
    WebDBGrid1.SetParentComponent(StatementPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 3;
    WebDBGrid1.Top := 128;
    WebDBGrid1.Width := 390;
    WebDBGrid1.Height := 521;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'InvNum';
      Title := 'Doc#';
      Width := 75;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'InvTyp';
      Title := 'Type';
      Width := 40;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'InvDate';
      Title := 'Date';
      Width := 82;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Ageing';
      Title := 'Age';
      Width := 31;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      ElementClassName := 'text-right';
      DataField := 'InvAmt';
      Title := 'Amount';
      Width := 67;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      ElementClassName := 'text-right';
      DataField := 'InvBal';
      Title := 'Balance';
      Width := 72;
    end;
    WebDBGrid1.DataSource := WDSState;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 75;
    WebDBGrid1.ColWidths[1] := 40;
    WebDBGrid1.ColWidths[2] := 82;
    WebDBGrid1.ColWidths[3] := 31;
    WebDBGrid1.ColWidths[4] := 67;
    WebDBGrid1.ColWidths[5] := 72;
    WebButton4.SetParentComponent(StatementPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 247;
    WebButton4.Top := 655;
    WebButton4.Width := 137;
    WebButton4.Height := 57;
    WebButton4.Caption := 'Close';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-danger';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.ParentFont := False;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    DSState.SetParentComponent(Self);
    DSState.Name := 'DSState';
    DSState.AfterOpen := DSStateAfterOpen;
    DSState.EntitySetName := 'ARSalesState';
    DSState.Connection := Dm.ApiConnection;
    DSState.Left := 312;
    DSState.Top := 624;
    DSStateAuto.SetParentComponent(DSState);
    DSStateAuto.Name := 'DSStateAuto';
    DSStateAuto.FieldName := 'Auto';
    DSStateCustNum.SetParentComponent(DSState);
    DSStateCustNum.Name := 'DSStateCustNum';
    DSStateCustNum.FieldName := 'CustNum';
    DSStateInvDate.SetParentComponent(DSState);
    DSStateInvDate.Name := 'DSStateInvDate';
    DSStateInvDate.FieldName := 'InvDate';
    DSStateInvNum.SetParentComponent(DSState);
    DSStateInvNum.Name := 'DSStateInvNum';
    DSStateInvNum.FieldName := 'InvNum';
    DSStateInvTyp.SetParentComponent(DSState);
    DSStateInvTyp.Name := 'DSStateInvTyp';
    DSStateInvTyp.FieldName := 'InvTyp';
    DSStateInvTyp.Size := 3;
    DSStateInvRef.SetParentComponent(DSState);
    DSStateInvRef.Name := 'DSStateInvRef';
    DSStateInvRef.FieldName := 'InvRef';
    DSStateInvRef.Size := 16;
    DSStateInvAmt.SetParentComponent(DSState);
    DSStateInvAmt.Name := 'DSStateInvAmt';
    DSStateInvAmt.FieldName := 'InvAmt';
    DSStateInvAmt.DisplayFormat := '0.00';
    DSStateInvBal.SetParentComponent(DSState);
    DSStateInvBal.Name := 'DSStateInvBal';
    DSStateInvBal.FieldName := 'InvBal';
    DSStateInvBal.DisplayFormat := '0.00';
    DSStateAgeing.SetParentComponent(DSState);
    DSStateAgeing.Name := 'DSStateAgeing';
    DSStateAgeing.FieldName := 'Ageing';
    DSStateApplyTo.SetParentComponent(DSState);
    DSStateApplyTo.Name := 'DSStateApplyTo';
    DSStateApplyTo.FieldName := 'ApplyTo';
    DSStateTot.SetParentComponent(Self);
    DSStateTot.Name := 'DSStateTot';
    DSStateTot.EntitySetName := 'ARSalesStateTot';
    DSStateTot.Connection := Dm.ApiConnection;
    DSStateTot.Left := 256;
    DSStateTot.Top := 632;
    DSStateTotAuto.SetParentComponent(DSStateTot);
    DSStateTotAuto.Name := 'DSStateTotAuto';
    DSStateTotAuto.FieldName := 'Auto';
    DSStateTotCusNum.SetParentComponent(DSStateTot);
    DSStateTotCusNum.Name := 'DSStateTotCusNum';
    DSStateTotCusNum.FieldName := 'CusNum';
    DSStateTotTotal.SetParentComponent(DSStateTot);
    DSStateTotTotal.Name := 'DSStateTotTotal';
    DSStateTotTotal.FieldName := 'Total';
    DSStateTotTotal.DisplayFormat := '0.00';
    DSStateTotAge1.SetParentComponent(DSStateTot);
    DSStateTotAge1.Name := 'DSStateTotAge1';
    DSStateTotAge1.FieldName := 'Age1';
    DSStateTotAge1.DisplayFormat := '0.00';
    DSStateTotAge2.SetParentComponent(DSStateTot);
    DSStateTotAge2.Name := 'DSStateTotAge2';
    DSStateTotAge2.FieldName := 'Age2';
    DSStateTotAge2.DisplayFormat := '0.00';
    DSStateTotAge3.SetParentComponent(DSStateTot);
    DSStateTotAge3.Name := 'DSStateTotAge3';
    DSStateTotAge3.FieldName := 'Age3';
    DSStateTotAge3.DisplayFormat := '0.00';
    DSStateTotAge4.SetParentComponent(DSStateTot);
    DSStateTotAge4.Name := 'DSStateTotAge4';
    DSStateTotAge4.FieldName := 'Age4';
    DSStateTotAge4.DisplayFormat := '0.00';
    DSStateTotAge5.SetParentComponent(DSStateTot);
    DSStateTotAge5.Name := 'DSStateTotAge5';
    DSStateTotAge5.FieldName := 'Age5';
    DSStateTotAge5.DisplayFormat := '0.00';
    DSStateTotCreditHoldFlag.SetParentComponent(DSStateTot);
    DSStateTotCreditHoldFlag.Name := 'DSStateTotCreditHoldFlag';
    DSStateTotCreditHoldFlag.FieldName := 'CreditHoldFlag';
    DSStateTotCreditHoldFlag.Size := 1;
    WDSState.SetParentComponent(Self);
    WDSState.Name := 'WDSState';
    WDSState.DataSet := DSState;
    WDSState.Left := 320;
    WDSState.Top := 600;
    WDSStateTot.SetParentComponent(Self);
    WDSStateTot.Name := 'WDSStateTot';
    WDSStateTot.DataSet := DSStateTot;
    WDSStateTot.Left := 256;
    WDSStateTot.Top := 612;
  finally
    StatementPnl.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel7.AfterLoadDFMValues;
    WebDBLabel8.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    DSState.AfterLoadDFMValues;
    DSStateAuto.AfterLoadDFMValues;
    DSStateCustNum.AfterLoadDFMValues;
    DSStateInvDate.AfterLoadDFMValues;
    DSStateInvNum.AfterLoadDFMValues;
    DSStateInvTyp.AfterLoadDFMValues;
    DSStateInvRef.AfterLoadDFMValues;
    DSStateInvAmt.AfterLoadDFMValues;
    DSStateInvBal.AfterLoadDFMValues;
    DSStateAgeing.AfterLoadDFMValues;
    DSStateApplyTo.AfterLoadDFMValues;
    DSStateTot.AfterLoadDFMValues;
    DSStateTotAuto.AfterLoadDFMValues;
    DSStateTotCusNum.AfterLoadDFMValues;
    DSStateTotTotal.AfterLoadDFMValues;
    DSStateTotAge1.AfterLoadDFMValues;
    DSStateTotAge2.AfterLoadDFMValues;
    DSStateTotAge3.AfterLoadDFMValues;
    DSStateTotAge4.AfterLoadDFMValues;
    DSStateTotAge5.AfterLoadDFMValues;
    DSStateTotCreditHoldFlag.AfterLoadDFMValues;
    WDSState.AfterLoadDFMValues;
    WDSStateTot.AfterLoadDFMValues;
  end;
end;

end.
