unit InspectionEod;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Grids,
  WEBLib.DBCtrls, Vcl.Controls, WEBLib.ExtCtrls, StrUtils, DateUtils,
  WEBLib.Grids;

type
  TFInspectionEod = class(TForm)
    InspectionPnl: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    EditNY: TEdit;
    EditPA: TEdit;
    EditOdometer: TEdit;
    WCBItems: TComboBox;
    EditOH: TEdit;
    WebMemo1: TMemo;
    WebDBGrid1: TDBGrid;
    WebButton1: TButton;
    WebButton2: TButton;
    WDSDefects: TDataSource;
    DSDefects: TXDataWebDataSet;
    DSDefectsAutoInc: TIntegerField;
    DSDefectsDriverNum: TIntegerField;
    DSDefectsDate: TDateTimeField;
    DSDefectsInspectionItem: TIntegerField;
    DSDefectsTruck: TIntegerField;
    DSDefectsTrailer: TIntegerField;
    DSDefectsNotes: TStringField;
    DSInspection: TXDataWebDataSet;
    DSInspectionAutoInc: TIntegerField;
    DSInspectionDriverNum: TIntegerField;
    DSInspectionDate: TDateTimeField;
    DSInspectionTruck: TIntegerField;
    DSInspectionTrailer1: TIntegerField;
    DSInspectionTrailer2: TIntegerField;
    DSInspectionOdometer: TIntegerField;
    DSInspectionInspectionType: TStringField;
    DSInspectionItems: TXDataWebDataSet;
    DSInspectionItemsAutoInc: TIntegerField;
    DSInspectionItemsDescription: TStringField;
    DSInspectionItemsCategory: TStringField;
    WDSInspection: TDataSource;
    WDSInspecitonItems: TDataSource;
    DSInspectionNYMiles: TIntegerField;
    DSInspectionPAMiles: TIntegerField;
    DSInspectionOHMiles: TIntegerField;
    WebLabel6: TLabel;
    EditNYThruway: TEdit;
    DSInspectionNYThruwayMiles: TIntegerField;
    DSInspectionRouteNum: TIntegerField;
    DSInspectionDoublesFinish: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure DSInspectionItemsAfterOpen(Dataset: TDataSet);
    procedure DSInspectionAfterOpen(Dataset: TDataSet);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FInspectionEod: TFInspectionEod;
  VarTop: Integer;

implementation

Uses Data, Feedback, Login, SelectRoute;

{$R *.dfm}

procedure TFInspectionEod.DSInspectionAfterOpen(Dataset: TDataSet);
begin
  DSInspectionItems.Load;
  FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
  DSDefects.QueryString := '$filter=(DriverNum eq ' +
    FLogin.DsLoginNumber.AsString + ' AND Date eq ''' + DateToStr(Now) + ''')';
  DSDefects.Load;
  FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
end;

procedure TFInspectionEod.DSInspectionItemsAfterOpen(Dataset: TDataSet);
Var
  TempCat: String;
begin
  TempCat := '';
  WCBItems.Items.Clear;
  WCBItems.Items.Add('Select Inspection Item..');
  while not DSInspectionItems.eof do
  begin
    if TempCat <> DSInspectionItemsCategory.AsString then
      WCBItems.Items.Add('-- ' + DSInspectionItemsCategory.AsString + ' - 00');
    WCBItems.Items.Add(DSInspectionItemsDescription.AsString + ' - ' +
      DSInspectionItemsAutoInc.AsString);
    TempCat := DSInspectionItemsCategory.AsString;
    DSInspectionItems.Next;
  end;
  DSInspectionItems.Close;
  WCBItems.ItemIndex := 0;
end;

procedure TFInspectionEod.WebButton1Click(Sender: TObject);
Var
  Truck, Trailer1, Trailer2: Integer;
begin
  if EditOdometer.Text = '' then
  begin
    ShowMessage('Must Enter an Odometer Reading');
    Exit;
  end;
  if DSInspectionOdometer.AsInteger > StrToInt(EditOdometer.Text) then
  begin
    ShowMessage('Must Enter More Miles than Start - ' +
      DSInspectionOdometer.AsString);
    Exit;
  end;
  if EditNY.Text = '' then EditNY.Text := '0';
  if EditPA.Text = '' then EditPA.Text := '0';
  if EditOH.Text = '' then EditOH.Text := '0';
  if StrToInt(EditNY.Text) > 1000 then
  begin
    ShowMessage('You entered more than 1000 miles in NY - please correct');
    Exit;
  end;
  if StrToInt(EditPA.Text) > 1000 then
  begin
    ShowMessage('You entered more than 1000 miles in PA - please correct');
    Exit;
  end;
  if StrToInt(EditOH.Text) > 1000 then
  begin
    ShowMessage('You entered more than 1000 miles in OH - please correct');
    Exit;
  end;
  Truck := DSInspectionTruck.AsInteger;
  Trailer1 := DSInspectionTrailer1.AsInteger;
  Trailer2 := DSInspectionTrailer2.AsInteger;
  DSInspection.Append;
  DSInspectionDriverNum.AsInteger := Dm.DsLoginNumber.AsInteger;
  DSInspectionNYMiles.AsString := EditNY.Text;
  DSInspectionNYThruwayMiles.AsString := EditNYThruway.Text;
  DSInspectionPAMiles.AsString := EditPA.Text;
  DSInspectionOHMiles.AsString := EditOH.Text;
  DSInspectionOdometer.AsString := EditOdometer.Text;
  DSInspectionTruck.AsInteger := Truck;
  DSInspectionTrailer1.AsInteger := Trailer1;
  DSInspectionTrailer2.AsInteger := Trailer2;
  if Dm.DSLoginDoublesFlag.AsString = 'Y' then
    DSInspectionDoublesFinish.AsString := 'Y';
  if HourOf(Now) > 19 then DSInspectionDate.AsDateTime := IncDay(Now,1)
    else DSInspectionDate.AsDateTime := Now;
  DSInspectionInspectionType.AsString := 'EndOfDay';
  DSInspectionRouteNum.AsInteger := Dm.DSLoginLastRoute.AsInteger;
  DSInspection.Post;
  DSInspection.ApplyUpdates;
  Dm.DSLogin.Edit;
  Dm.DSLoginLastRouteDate.Clear;
  Dm.DSLoginLastRoute.Clear;
  Dm.DSLogin.Post;
  Dm.DSLogin.ApplyUpdates;
  Application.CreateForm(TFFeedback, FFeedback);
end;

procedure TFInspectionEod.WebButton2Click(Sender: TObject);
begin
  if WCBItems.ItemIndex <= 0 then
  begin
    ShowMessage('Please Select an Inspection Item');
    Exit;
  end;
  DSDefects.Append;
  DSDefectsDriverNum.AsInteger := Dm.DsLoginNumber.AsInteger;
  DSDefectsDate.AsDateTime := Now;
  DSDefectsInspectionItem.AsString :=
    Trim(RightStr(WCBItems.Items[WCBItems.ItemIndex], 2));
  DSDefectsNotes.AsString := WebMemo1.Lines.Text;
  DSDefectsTruck.AsInteger := DSInspectionTruck.AsInteger;
  DSDefectsTrailer.AsInteger := DSInspectionTrailer1.AsInteger;
  DSDefects.Post;
  DSDefects.ApplyUpdates;
  WebMemo1.Clear;
end;

procedure TFInspectionEod.WebFormResize(Sender: TObject);
begin
  InspectionPnl.Left := Round((FInspectionEod.Width / 2) -
    (InspectionPnl.Width / 2));
  // InspectionPnl.Top := Round((FInspectionEod.Height/2)-(InspectionPnl.Height/2));
  if InspectionPnl.Top < 0 then
    InspectionPnl.Top := 0;
end;

procedure TFInspectionEod.WebFormShow(Sender: TObject);
begin
  InspectionPnl.Left := Round((FInspectionEod.Width / 2) -
    (InspectionPnl.Width / 2));
  InspectionPnl.Top := Round((FInspectionEod.Height / 2) -
    (InspectionPnl.Height / 2));
  EditNY.Text := '';
  EditPA.Text := '';
  EditOH.Text := '';
  EditOdometer.Text := '';
  CurrentForm := 'Inspection';
  FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
  DSInspection.QueryString := '$filter=(DriverNum eq ' +
    FLogin.DsLoginNumber.AsString + ' AND Date eq ''' + DateToStr(Now) + ''')';
  DSInspection.Load;
  FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
  VarTop := InspectionPnl.Top;
end;

procedure TFInspectionEod.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InspectionPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  EditNY := TEdit.Create(Self);
  EditPA := TEdit.Create(Self);
  EditOdometer := TEdit.Create(Self);
  WCBItems := TComboBox.Create(Self);
  EditOH := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  EditNYThruway := TEdit.Create(Self);
  WDSDefects := TDataSource.Create(Self);
  DSDefects := TXDataWebDataSet.Create(Self);
  DSDefectsAutoInc := TIntegerField.Create(Self);
  DSDefectsDriverNum := TIntegerField.Create(Self);
  DSDefectsDate := TDateTimeField.Create(Self);
  DSDefectsInspectionItem := TIntegerField.Create(Self);
  DSDefectsTruck := TIntegerField.Create(Self);
  DSDefectsTrailer := TIntegerField.Create(Self);
  DSDefectsNotes := TStringField.Create(Self);
  DSInspection := TXDataWebDataSet.Create(Self);
  DSInspectionAutoInc := TIntegerField.Create(Self);
  DSInspectionDriverNum := TIntegerField.Create(Self);
  DSInspectionDate := TDateTimeField.Create(Self);
  DSInspectionTruck := TIntegerField.Create(Self);
  DSInspectionTrailer1 := TIntegerField.Create(Self);
  DSInspectionTrailer2 := TIntegerField.Create(Self);
  DSInspectionOdometer := TIntegerField.Create(Self);
  DSInspectionInspectionType := TStringField.Create(Self);
  DSInspectionNYMiles := TIntegerField.Create(Self);
  DSInspectionPAMiles := TIntegerField.Create(Self);
  DSInspectionOHMiles := TIntegerField.Create(Self);
  DSInspectionNYThruwayMiles := TIntegerField.Create(Self);
  DSInspectionRouteNum := TIntegerField.Create(Self);
  DSInspectionDoublesFinish := TStringField.Create(Self);
  DSInspectionItems := TXDataWebDataSet.Create(Self);
  DSInspectionItemsAutoInc := TIntegerField.Create(Self);
  DSInspectionItemsDescription := TStringField.Create(Self);
  DSInspectionItemsCategory := TStringField.Create(Self);
  WDSInspection := TDataSource.Create(Self);
  WDSInspecitonItems := TDataSource.Create(Self);

  InspectionPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  EditNY.BeforeLoadDFMValues;
  EditPA.BeforeLoadDFMValues;
  EditOdometer.BeforeLoadDFMValues;
  WCBItems.BeforeLoadDFMValues;
  EditOH.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  EditNYThruway.BeforeLoadDFMValues;
  WDSDefects.BeforeLoadDFMValues;
  DSDefects.BeforeLoadDFMValues;
  DSDefectsAutoInc.BeforeLoadDFMValues;
  DSDefectsDriverNum.BeforeLoadDFMValues;
  DSDefectsDate.BeforeLoadDFMValues;
  DSDefectsInspectionItem.BeforeLoadDFMValues;
  DSDefectsTruck.BeforeLoadDFMValues;
  DSDefectsTrailer.BeforeLoadDFMValues;
  DSDefectsNotes.BeforeLoadDFMValues;
  DSInspection.BeforeLoadDFMValues;
  DSInspectionAutoInc.BeforeLoadDFMValues;
  DSInspectionDriverNum.BeforeLoadDFMValues;
  DSInspectionDate.BeforeLoadDFMValues;
  DSInspectionTruck.BeforeLoadDFMValues;
  DSInspectionTrailer1.BeforeLoadDFMValues;
  DSInspectionTrailer2.BeforeLoadDFMValues;
  DSInspectionOdometer.BeforeLoadDFMValues;
  DSInspectionInspectionType.BeforeLoadDFMValues;
  DSInspectionNYMiles.BeforeLoadDFMValues;
  DSInspectionPAMiles.BeforeLoadDFMValues;
  DSInspectionOHMiles.BeforeLoadDFMValues;
  DSInspectionNYThruwayMiles.BeforeLoadDFMValues;
  DSInspectionRouteNum.BeforeLoadDFMValues;
  DSInspectionDoublesFinish.BeforeLoadDFMValues;
  DSInspectionItems.BeforeLoadDFMValues;
  DSInspectionItemsAutoInc.BeforeLoadDFMValues;
  DSInspectionItemsDescription.BeforeLoadDFMValues;
  DSInspectionItemsCategory.BeforeLoadDFMValues;
  WDSInspection.BeforeLoadDFMValues;
  WDSInspecitonItems.BeforeLoadDFMValues;
  try
    Name := 'FInspectionEod';
    Width := 412;
    Height := 745;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    InspectionPnl.SetParentComponent(Self);
    InspectionPnl.Name := 'InspectionPnl';
    InspectionPnl.Left := 13;
    InspectionPnl.Top := 8;
    InspectionPnl.Width := 385;
    InspectionPnl.Height := 729;
    InspectionPnl.ElementClassName := 'container-fluid';
    InspectionPnl.Font.Charset := DEFAULT_CHARSET;
    InspectionPnl.Font.Color := clWindowText;
    InspectionPnl.Font.Height := -16;
    InspectionPnl.Font.Name := 'Arial';
    InspectionPnl.Font.Style := [];
    InspectionPnl.ParentFont := False;
    WebLabel1.SetParentComponent(InspectionPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 17;
    WebLabel1.Top := 22;
    WebLabel1.Width := 79;
    WebLabel1.Height := 22;
    WebLabel1.Caption := 'NY Miles:';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(InspectionPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 17;
    WebLabel2.Top := 100;
    WebLabel2.Width := 78;
    WebLabel2.Height := 22;
    WebLabel2.Caption := 'PA Miles:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(InspectionPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 17;
    WebLabel3.Top := 187;
    WebLabel3.Width := 206;
    WebLabel3.Height := 22;
    WebLabel3.Caption := 'Ending Truck Odometer:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(InspectionPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 17;
    WebLabel4.Top := 262;
    WebLabel4.Width := 196;
    WebLabel4.Height := 22;
    WebLabel4.Caption := 'Truck/Trailer Inspection';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -19;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(InspectionPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 17;
    WebLabel5.Top := 139;
    WebLabel5.Width := 82;
    WebLabel5.Height := 22;
    WebLabel5.Caption := 'OH Miles:';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -19;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(InspectionPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 17;
    WebLabel6.Top := 61;
    WebLabel6.Width := 154;
    WebLabel6.Height := 22;
    WebLabel6.Caption := 'NY Thruway Miles:';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -19;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    EditNY.SetParentComponent(InspectionPnl);
    EditNY.Name := 'EditNY';
    EditNY.Left := 254;
    EditNY.Top := 19;
    EditNY.Width := 100;
    EditNY.Height := 33;
    EditNY.ChildOrder := 5;
    EditNY.EditType := weNumeric;
    EditNY.ElementClassName := 'form-control';
    EditNY.Font.Charset := DEFAULT_CHARSET;
    EditNY.Font.Color := clWindowText;
    EditNY.Font.Height := -19;
    EditNY.Font.Name := 'Arial';
    EditNY.Font.Style := [];
    EditNY.HeightPercent := 100.000000000000000000;
    EditNY.ParentFont := False;
    EditNY.WidthPercent := 100.000000000000000000;
    EditPA.SetParentComponent(InspectionPnl);
    EditPA.Name := 'EditPA';
    EditPA.Left := 254;
    EditPA.Top := 97;
    EditPA.Width := 100;
    EditPA.Height := 33;
    EditPA.ChildOrder := 1;
    EditPA.EditType := weNumeric;
    EditPA.ElementClassName := 'form-control';
    EditPA.Font.Charset := DEFAULT_CHARSET;
    EditPA.Font.Color := clWindowText;
    EditPA.Font.Height := -19;
    EditPA.Font.Name := 'Arial';
    EditPA.Font.Style := [];
    EditPA.HeightPercent := 100.000000000000000000;
    EditPA.ParentFont := False;
    EditPA.WidthPercent := 100.000000000000000000;
    EditOdometer.SetParentComponent(InspectionPnl);
    EditOdometer.Name := 'EditOdometer';
    EditOdometer.Left := 241;
    EditOdometer.Top := 181;
    EditOdometer.Width := 113;
    EditOdometer.Height := 33;
    EditOdometer.ChildOrder := 1;
    EditOdometer.EditType := weNumeric;
    EditOdometer.ElementClassName := 'form-control';
    EditOdometer.Font.Charset := DEFAULT_CHARSET;
    EditOdometer.Font.Color := clWindowText;
    EditOdometer.Font.Height := -19;
    EditOdometer.Font.Name := 'Arial';
    EditOdometer.Font.Style := [];
    EditOdometer.HeightPercent := 100.000000000000000000;
    EditOdometer.ParentFont := False;
    EditOdometer.WidthPercent := 100.000000000000000000;
    WCBItems.SetParentComponent(InspectionPnl);
    WCBItems.Name := 'WCBItems';
    WCBItems.Left := 17;
    WCBItems.Top := 290;
    WCBItems.Width := 353;
    WCBItems.Height := 32;
    WCBItems.Font.Charset := DEFAULT_CHARSET;
    WCBItems.Font.Color := clWindowText;
    WCBItems.Font.Height := -21;
    WCBItems.Font.Name := 'Arial';
    WCBItems.Font.Style := [];
    WCBItems.HeightPercent := 100.000000000000000000;
    WCBItems.ParentFont := False;
    WCBItems.Text := 'Select Inspection Item..';
    WCBItems.WidthPercent := 100.000000000000000000;
    WCBItems.ItemIndex := 0;
    WCBItems.Items.BeginUpdate;
    try
      WCBItems.Items.Clear;
      WCBItems.Items.Add('Select Inspection Item..');
    finally
      WCBItems.Items.EndUpdate;
    end;
    EditOH.SetParentComponent(InspectionPnl);
    EditOH.Name := 'EditOH';
    EditOH.Left := 254;
    EditOH.Top := 136;
    EditOH.Width := 100;
    EditOH.Height := 33;
    EditOH.ChildOrder := 1;
    EditOH.EditType := weNumeric;
    EditOH.ElementClassName := 'form-control';
    EditOH.Font.Charset := DEFAULT_CHARSET;
    EditOH.Font.Color := clWindowText;
    EditOH.Font.Height := -19;
    EditOH.Font.Name := 'Arial';
    EditOH.Font.Style := [];
    EditOH.HeightPercent := 100.000000000000000000;
    EditOH.ParentFont := False;
    EditOH.WidthPercent := 100.000000000000000000;
    WebMemo1.SetParentComponent(InspectionPnl);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 17;
    WebMemo1.Top := 338;
    WebMemo1.Width := 256;
    WebMemo1.Height := 105;
    WebMemo1.AutoSize := False;
    WebMemo1.ElementClassName := 'form-control';
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.SetParentComponent(InspectionPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 17;
    WebDBGrid1.Top := 465;
    WebDBGrid1.Width := 353;
    WebDBGrid1.Height := 184;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'InspectionItem';
      Title := 'Item';
      Width := 39;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Notes';
      Title := 'Notes';
      Width := 286;
    end;
    WebDBGrid1.DataSource := WDSDefects;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebDBGrid1.TabOrder := 6;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 39;
    WebDBGrid1.ColWidths[1] := 286;
    WebButton1.SetParentComponent(InspectionPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 217;
    WebButton1.Top := 663;
    WebButton1.Width := 153;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Save && Continue';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(InspectionPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 288;
    WebButton2.Top := 338;
    WebButton2.Width := 82;
    WebButton2.Height := 49;
    WebButton2.Caption := 'Add';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn btn-warning';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    EditNYThruway.SetParentComponent(InspectionPnl);
    EditNYThruway.Name := 'EditNYThruway';
    EditNYThruway.Left := 254;
    EditNYThruway.Top := 58;
    EditNYThruway.Width := 100;
    EditNYThruway.Height := 33;
    EditNYThruway.ChildOrder := 5;
    EditNYThruway.EditType := weNumeric;
    EditNYThruway.ElementClassName := 'form-control';
    EditNYThruway.Font.Charset := DEFAULT_CHARSET;
    EditNYThruway.Font.Color := clWindowText;
    EditNYThruway.Font.Height := -19;
    EditNYThruway.Font.Name := 'Arial';
    EditNYThruway.Font.Style := [];
    EditNYThruway.HeightPercent := 100.000000000000000000;
    EditNYThruway.ParentFont := False;
    EditNYThruway.WidthPercent := 100.000000000000000000;
    WDSDefects.SetParentComponent(Self);
    WDSDefects.Name := 'WDSDefects';
    WDSDefects.DataSet := DSDefects;
    WDSDefects.Left := 296;
    WDSDefects.Top := 600;
    DSDefects.SetParentComponent(Self);
    DSDefects.Name := 'DSDefects';
    DSDefects.EntitySetName := 'inspectiondefects';
    DSDefects.Connection := Dm.ApiConnection;
    DSDefects.Left := 280;
    DSDefects.Top := 576;
    DSDefectsAutoInc.SetParentComponent(DSDefects);
    DSDefectsAutoInc.Name := 'DSDefectsAutoInc';
    DSDefectsAutoInc.FieldName := 'AutoInc';
    DSDefectsAutoInc.Required := True;
    DSDefectsDriverNum.SetParentComponent(DSDefects);
    DSDefectsDriverNum.Name := 'DSDefectsDriverNum';
    DSDefectsDriverNum.FieldName := 'DriverNum';
    DSDefectsDate.SetParentComponent(DSDefects);
    DSDefectsDate.Name := 'DSDefectsDate';
    DSDefectsDate.FieldName := 'Date';
    DSDefectsInspectionItem.SetParentComponent(DSDefects);
    DSDefectsInspectionItem.Name := 'DSDefectsInspectionItem';
    DSDefectsInspectionItem.FieldName := 'InspectionItem';
    DSDefectsTruck.SetParentComponent(DSDefects);
    DSDefectsTruck.Name := 'DSDefectsTruck';
    DSDefectsTruck.FieldName := 'Truck';
    DSDefectsTrailer.SetParentComponent(DSDefects);
    DSDefectsTrailer.Name := 'DSDefectsTrailer';
    DSDefectsTrailer.FieldName := 'Trailer';
    DSDefectsNotes.SetParentComponent(DSDefects);
    DSDefectsNotes.Name := 'DSDefectsNotes';
    DSDefectsNotes.FieldName := 'Notes';
    DSDefectsNotes.Size := 250;
    DSInspection.SetParentComponent(Self);
    DSInspection.Name := 'DSInspection';
    DSInspection.AfterOpen := DSInspectionAfterOpen;
    DSInspection.EntitySetName := 'inspectioninfo';
    DSInspection.Connection := Dm.ApiConnection;
    DSInspection.QueryString := 'orderby=Category,Description';
    DSInspection.Left := 288;
    DSInspection.Top := 504;
    DSInspectionAutoInc.SetParentComponent(DSInspection);
    DSInspectionAutoInc.Name := 'DSInspectionAutoInc';
    DSInspectionAutoInc.FieldName := 'AutoInc';
    DSInspectionAutoInc.Required := True;
    DSInspectionDriverNum.SetParentComponent(DSInspection);
    DSInspectionDriverNum.Name := 'DSInspectionDriverNum';
    DSInspectionDriverNum.FieldName := 'DriverNum';
    DSInspectionDate.SetParentComponent(DSInspection);
    DSInspectionDate.Name := 'DSInspectionDate';
    DSInspectionDate.FieldName := 'Date';
    DSInspectionTruck.SetParentComponent(DSInspection);
    DSInspectionTruck.Name := 'DSInspectionTruck';
    DSInspectionTruck.FieldName := 'Truck';
    DSInspectionTrailer1.SetParentComponent(DSInspection);
    DSInspectionTrailer1.Name := 'DSInspectionTrailer1';
    DSInspectionTrailer1.FieldName := 'Trailer1';
    DSInspectionTrailer2.SetParentComponent(DSInspection);
    DSInspectionTrailer2.Name := 'DSInspectionTrailer2';
    DSInspectionTrailer2.FieldName := 'Trailer2';
    DSInspectionOdometer.SetParentComponent(DSInspection);
    DSInspectionOdometer.Name := 'DSInspectionOdometer';
    DSInspectionOdometer.FieldName := 'Odometer';
    DSInspectionInspectionType.SetParentComponent(DSInspection);
    DSInspectionInspectionType.Name := 'DSInspectionInspectionType';
    DSInspectionInspectionType.FieldName := 'InspectionType';
    DSInspectionInspectionType.Size := 50;
    DSInspectionNYMiles.SetParentComponent(DSInspection);
    DSInspectionNYMiles.Name := 'DSInspectionNYMiles';
    DSInspectionNYMiles.FieldName := 'NYMiles';
    DSInspectionPAMiles.SetParentComponent(DSInspection);
    DSInspectionPAMiles.Name := 'DSInspectionPAMiles';
    DSInspectionPAMiles.FieldName := 'PAMiles';
    DSInspectionOHMiles.SetParentComponent(DSInspection);
    DSInspectionOHMiles.Name := 'DSInspectionOHMiles';
    DSInspectionOHMiles.FieldName := 'OHMiles';
    DSInspectionNYThruwayMiles.SetParentComponent(DSInspection);
    DSInspectionNYThruwayMiles.Name := 'DSInspectionNYThruwayMiles';
    DSInspectionNYThruwayMiles.FieldName := 'NYThruwayMiles';
    DSInspectionRouteNum.SetParentComponent(DSInspection);
    DSInspectionRouteNum.Name := 'DSInspectionRouteNum';
    DSInspectionRouteNum.FieldName := 'RouteNum';
    DSInspectionDoublesFinish.SetParentComponent(DSInspection);
    DSInspectionDoublesFinish.Name := 'DSInspectionDoublesFinish';
    DSInspectionDoublesFinish.FieldName := 'DoublesFinish';
    DSInspectionDoublesFinish.Size := 1;
    DSInspectionItems.SetParentComponent(Self);
    DSInspectionItems.Name := 'DSInspectionItems';
    DSInspectionItems.AfterOpen := DSInspectionItemsAfterOpen;
    DSInspectionItems.EntitySetName := 'inspectionitems';
    DSInspectionItems.Connection := Dm.ApiConnection;
    DSInspectionItems.QueryString := 'orderby=Category,Description';
    DSInspectionItems.Left := 296;
    DSInspectionItems.Top := 640;
    DSInspectionItemsAutoInc.SetParentComponent(DSInspectionItems);
    DSInspectionItemsAutoInc.Name := 'DSInspectionItemsAutoInc';
    DSInspectionItemsAutoInc.FieldName := 'AutoInc';
    DSInspectionItemsAutoInc.Required := True;
    DSInspectionItemsDescription.SetParentComponent(DSInspectionItems);
    DSInspectionItemsDescription.Name := 'DSInspectionItemsDescription';
    DSInspectionItemsDescription.FieldName := 'Description';
    DSInspectionItemsDescription.Size := 50;
    DSInspectionItemsCategory.SetParentComponent(DSInspectionItems);
    DSInspectionItemsCategory.Name := 'DSInspectionItemsCategory';
    DSInspectionItemsCategory.FieldName := 'Category';
    DSInspectionItemsCategory.Size := 50;
    WDSInspection.SetParentComponent(Self);
    WDSInspection.Name := 'WDSInspection';
    WDSInspection.DataSet := DSInspection;
    WDSInspection.Left := 328;
    WDSInspection.Top := 520;
    WDSInspecitonItems.SetParentComponent(Self);
    WDSInspecitonItems.Name := 'WDSInspecitonItems';
    WDSInspecitonItems.DataSet := DSInspectionItems;
    WDSInspecitonItems.Left := 328;
    WDSInspecitonItems.Top := 648;
  finally
    InspectionPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    EditNY.AfterLoadDFMValues;
    EditPA.AfterLoadDFMValues;
    EditOdometer.AfterLoadDFMValues;
    WCBItems.AfterLoadDFMValues;
    EditOH.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    EditNYThruway.AfterLoadDFMValues;
    WDSDefects.AfterLoadDFMValues;
    DSDefects.AfterLoadDFMValues;
    DSDefectsAutoInc.AfterLoadDFMValues;
    DSDefectsDriverNum.AfterLoadDFMValues;
    DSDefectsDate.AfterLoadDFMValues;
    DSDefectsInspectionItem.AfterLoadDFMValues;
    DSDefectsTruck.AfterLoadDFMValues;
    DSDefectsTrailer.AfterLoadDFMValues;
    DSDefectsNotes.AfterLoadDFMValues;
    DSInspection.AfterLoadDFMValues;
    DSInspectionAutoInc.AfterLoadDFMValues;
    DSInspectionDriverNum.AfterLoadDFMValues;
    DSInspectionDate.AfterLoadDFMValues;
    DSInspectionTruck.AfterLoadDFMValues;
    DSInspectionTrailer1.AfterLoadDFMValues;
    DSInspectionTrailer2.AfterLoadDFMValues;
    DSInspectionOdometer.AfterLoadDFMValues;
    DSInspectionInspectionType.AfterLoadDFMValues;
    DSInspectionNYMiles.AfterLoadDFMValues;
    DSInspectionPAMiles.AfterLoadDFMValues;
    DSInspectionOHMiles.AfterLoadDFMValues;
    DSInspectionNYThruwayMiles.AfterLoadDFMValues;
    DSInspectionRouteNum.AfterLoadDFMValues;
    DSInspectionDoublesFinish.AfterLoadDFMValues;
    DSInspectionItems.AfterLoadDFMValues;
    DSInspectionItemsAutoInc.AfterLoadDFMValues;
    DSInspectionItemsDescription.AfterLoadDFMValues;
    DSInspectionItemsCategory.AfterLoadDFMValues;
    WDSInspection.AfterLoadDFMValues;
    WDSInspecitonItems.AfterLoadDFMValues;
  end;
end;

end.
