unit Map;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TFMap = class(TForm)
    MapPnl: TPanel;
    WGM: TGoogleMaps;
    GeoLoc: TGeoLocation;
    DSCust: TXDataWebDataSet;
    WDSCust: TDataSource;
    WebButton1: TButton;
    DSCustCust: TIntegerField;
    DSCustCustName: TStringField;
    DSCustSRNum: TIntegerField;
    DSCustAddress1: TStringField;
    DSCustAddress2: TStringField;
    DSCustCity: TStringField;
    DSCustState: TStringField;
    DSCustTermsExplained: TStringField;
    DSCustLatitude: TFloatField;
    DSCustLongitude: TFloatField;
    procedure WebFormShow(Sender: TObject);
    procedure GeoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure DSCustAfterOpen(Dataset: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FMap: TFMap;
  vLat, vLong: Double;

implementation

Uses Data, Options;

{$R *.dfm}

procedure TFMap.DSCustAfterOpen(Dataset: TDataSet);
begin
  GeoLoc.GetGeolocation;
end;

procedure TFMap.GeoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
begin
  vLat := Lat;
  vLong := Lon;
  WGM.SetCenter(vLat, vLong);
  WGM.AddMarker(vLat, vLong);
  WGM.AddMarker(StrToFloat(DSCustLatitude.AsString),
    StrToFloat(DSCustLongitude.AsString), DSCustCustName.AsString);
end;

procedure TFMap.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFMap.WebFormResize(Sender: TObject);
begin
  MapPnl.Left := Round((FMap.Width / 2) - (MapPnl.Width / 2));
end;

procedure TFMap.WebFormShow(Sender: TObject);
begin
  MapPnl.Left := Round((FMap.Width / 2) - (MapPnl.Width / 2));
  MapPnl.Top := Round((FMap.Height / 2) - (MapPnl.Height / 2));
  DSCust.QueryString := '$filter=(Cust eq ' +
    Dm.DSCurrStopOrdersCust.AsString + ')';
  DSCust.Load;
end;

procedure TFMap.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MapPnl := TPanel.Create(Self);
  WGM := TGoogleMaps.Create(Self);
  WebButton1 := TButton.Create(Self);
  GeoLoc := TGeoLocation.Create(Self);
  DSCust := TXDataWebDataSet.Create(Self);
  DSCustCust := TIntegerField.Create(Self);
  DSCustCustName := TStringField.Create(Self);
  DSCustSRNum := TIntegerField.Create(Self);
  DSCustAddress1 := TStringField.Create(Self);
  DSCustAddress2 := TStringField.Create(Self);
  DSCustCity := TStringField.Create(Self);
  DSCustState := TStringField.Create(Self);
  DSCustTermsExplained := TStringField.Create(Self);
  DSCustLatitude := TFloatField.Create(Self);
  DSCustLongitude := TFloatField.Create(Self);
  WDSCust := TDataSource.Create(Self);

  MapPnl.BeforeLoadDFMValues;
  WGM.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  GeoLoc.BeforeLoadDFMValues;
  DSCust.BeforeLoadDFMValues;
  DSCustCust.BeforeLoadDFMValues;
  DSCustCustName.BeforeLoadDFMValues;
  DSCustSRNum.BeforeLoadDFMValues;
  DSCustAddress1.BeforeLoadDFMValues;
  DSCustAddress2.BeforeLoadDFMValues;
  DSCustCity.BeforeLoadDFMValues;
  DSCustState.BeforeLoadDFMValues;
  DSCustTermsExplained.BeforeLoadDFMValues;
  DSCustLatitude.BeforeLoadDFMValues;
  DSCustLongitude.BeforeLoadDFMValues;
  WDSCust.BeforeLoadDFMValues;
  try
    Name := 'FMap';
    Width := 400;
    Height := 721;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    MapPnl.SetParentComponent(Self);
    MapPnl.Name := 'MapPnl';
    MapPnl.Left := 2;
    MapPnl.Top := 8;
    MapPnl.Width := 391;
    MapPnl.Height := 705;
    MapPnl.ElementClassName := 'card';
    MapPnl.ElementBodyClassName := 'card-body';
    WGM.SetParentComponent(MapPnl);
    WGM.Name := 'WGM';
    WGM.Left := 3;
    WGM.Top := 3;
    WGM.Width := 385;
    WGM.Height := 638;
    WGM.APIKey := 'AIzaSyA5XnK3yR5OksMDSDSQmm33LA8gKehL3Rg';
    WGM.Options.DefaultLatitude := -34.397000000000000000;
    WGM.Options.DefaultLongitude := 150.644000000000000000;
    WebButton1.SetParentComponent(MapPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 238;
    WebButton1.Top := 647;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Close';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-danger';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    GeoLoc.SetParentComponent(Self);
    GeoLoc.Name := 'GeoLoc';
    SetEvent(GeoLoc, Self, 'OnGeolocation', 'GeoLocGeolocation');
    GeoLoc.Left := 80;
    GeoLoc.Top := 551;
    DSCust.SetParentComponent(Self);
    DSCust.Name := 'DSCust';
    DSCust.AfterOpen := DSCustAfterOpen;
    DSCust.EntitySetName := 'dlcust';
    DSCust.Connection := Dm.ApiConnection;
    DSCust.Left := 24;
    DSCust.Top := 520;
    DSCustCust.SetParentComponent(DSCust);
    DSCustCust.Name := 'DSCustCust';
    DSCustCust.FieldName := 'Cust';
    DSCustCustName.SetParentComponent(DSCust);
    DSCustCustName.Name := 'DSCustCustName';
    DSCustCustName.FieldName := 'CustName';
    DSCustCustName.Size := 255;
    DSCustSRNum.SetParentComponent(DSCust);
    DSCustSRNum.Name := 'DSCustSRNum';
    DSCustSRNum.FieldName := 'SRNum';
    DSCustAddress1.SetParentComponent(DSCust);
    DSCustAddress1.Name := 'DSCustAddress1';
    DSCustAddress1.FieldName := 'Address1';
    DSCustAddress1.Size := 255;
    DSCustAddress2.SetParentComponent(DSCust);
    DSCustAddress2.Name := 'DSCustAddress2';
    DSCustAddress2.FieldName := 'Address2';
    DSCustAddress2.Size := 255;
    DSCustCity.SetParentComponent(DSCust);
    DSCustCity.Name := 'DSCustCity';
    DSCustCity.FieldName := 'City';
    DSCustCity.Size := 255;
    DSCustState.SetParentComponent(DSCust);
    DSCustState.Name := 'DSCustState';
    DSCustState.FieldName := 'State';
    DSCustState.Size := 255;
    DSCustTermsExplained.SetParentComponent(DSCust);
    DSCustTermsExplained.Name := 'DSCustTermsExplained';
    DSCustTermsExplained.FieldName := 'TermsExplained';
    DSCustTermsExplained.Size := 255;
    DSCustLatitude.SetParentComponent(DSCust);
    DSCustLatitude.Name := 'DSCustLatitude';
    DSCustLatitude.FieldName := 'Latitude';
    DSCustLongitude.SetParentComponent(DSCust);
    DSCustLongitude.Name := 'DSCustLongitude';
    DSCustLongitude.FieldName := 'Longitude';
    WDSCust.SetParentComponent(Self);
    WDSCust.Name := 'WDSCust';
    WDSCust.DataSet := DSCust;
    WDSCust.Left := 104;
    WDSCust.Top := 520;
  finally
    MapPnl.AfterLoadDFMValues;
    WGM.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    GeoLoc.AfterLoadDFMValues;
    DSCust.AfterLoadDFMValues;
    DSCustCust.AfterLoadDFMValues;
    DSCustCustName.AfterLoadDFMValues;
    DSCustSRNum.AfterLoadDFMValues;
    DSCustAddress1.AfterLoadDFMValues;
    DSCustAddress2.AfterLoadDFMValues;
    DSCustCity.AfterLoadDFMValues;
    DSCustState.AfterLoadDFMValues;
    DSCustTermsExplained.AfterLoadDFMValues;
    DSCustLatitude.AfterLoadDFMValues;
    DSCustLongitude.AfterLoadDFMValues;
    WDSCust.AfterLoadDFMValues;
  end;
end;

end.
