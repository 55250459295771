unit SelectRoute;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TFSelectRoute = class(TForm)
    DSRouteList: TXDataWebDataSet;
    DSRouteListTrip: TIntegerField;
    RouteSelectPnl: TPanel;
    WebLabel1: TLabel;
    CBRouteList: TComboBox;
    WebButton1: TButton;
    CloseRouteSelBtn: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure DSRouteListAfterOpen(Dataset: TDataSet);
    procedure WebButton1Click(Sender: TObject);
    procedure CloseRouteSelBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FSelectRoute: TFSelectRoute;
  RouteNum: Integer;

implementation

Uses Data, CurrentStop, Login;

{$R *.dfm}

procedure TFSelectRoute.CloseRouteSelBtnClick(Sender: TObject);
begin
  //FSelectRoute.Close;
  //FSelectRoute.Free;
  Application.CreateForm(TFLogin, FLogin);
end;

procedure TFSelectRoute.DSRouteListAfterOpen(Dataset: TDataSet);
begin
  DSRouteList.First;
  CBRouteList.Items.Add('--');
  while not DSRouteList.Eof do
  begin
    CBRouteList.Items.Add(DSRouteListTrip.AsString);
    DSRouteList.Next;
  end;
  DSRouteList.Close;
end;

procedure TFSelectRoute.WebButton1Click(Sender: TObject);
begin
  if CBRouteList.ItemIndex > 0 then
  begin
    RouteNum := StrToInt(CBRouteList.Items[CBRouteList.ItemIndex]);
    Dm.DSCurrStopOrders.QueryString := '$filter=(Trip eq ' + IntToStr(RouteNum)
      + ')&$orderby=Stop';
    Dm.DSCurrStopOrders.Load;
    Dm.DSLogin.Edit;
    Dm.DSLoginLastRouteDate.AsDateTime := Now;
    Dm.DSLoginLastRoute.AsInteger := RouteNum;
    Dm.DSLogin.Post;
    Dm.DSLogin.ApplyUpdates;
    Application.CreateForm(TFCurrentStop, FCurrentStop);
  end
  else
  begin
    ShowMessage('Select Route');
  end;
end;

procedure TFSelectRoute.WebButton2Click(Sender: TObject);
begin
  ShowMessage(IntToStr(FSelectRoute.Width));
  ShowMessage(IntToStr(FSelectRoute.Height));
end;

procedure TFSelectRoute.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'SelectRoute' then
  begin
    FSelectRoute.Height := 699;
    FSelectRoute.Width := 393;
    //FSelectRoute.Close;
    Application.CreateForm(TFSelectRoute, FSelectRoute);
  end;
end;

procedure TFSelectRoute.WebFormResize(Sender: TObject);
begin
  RouteSelectPnl.Left := Round((FSelectRoute.Width / 2) -
    (RouteSelectPnl.Width / 2));
  // RouteSelectPnl.Top := Round((FSelectRoute.Height/2)-(RouteSelectPnl.Height/2));
  if RouteSelectPnl.Top < 0 then
    RouteSelectPnl.Top := 0;

end;

procedure TFSelectRoute.WebFormShow(Sender: TObject);
begin
  FSelectRoute.Height := 699;
  FSelectRoute.Width := 393;
  CBRouteList.Clear;
  DSRouteList.Load;
  RouteSelectPnl.Left := Round((FSelectRoute.Width / 2) -
    (RouteSelectPnl.Width / 2));
  RouteSelectPnl.Top := Round((FSelectRoute.Height / 2) -
    (RouteSelectPnl.Height / 2));
  CurrentForm := 'SelectRoute';
  // ShowMessage(FLogin.DSLoginLastRouteDate.AsString);
  if (FLogin.DSLoginLastRouteDate.AsString = DateToStr(Now)) and
    (FLogin.DSLoginLastRoute.AsString <> '') then
  begin
    RouteNum := FLogin.DSLoginLastRoute.AsInteger;
    Dm.DSCurrStopOrders.QueryString := '$filter=(Trip eq ' + IntToStr(RouteNum)
      + ')&$orderby=Stop';
    Dm.DSCurrStopOrders.Load;
    Application.CreateForm(TFCurrentStop, FCurrentStop);
  end;
end;

procedure TFSelectRoute.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RouteSelectPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  CBRouteList := TComboBox.Create(Self);
  WebButton1 := TButton.Create(Self);
  CloseRouteSelBtn := TButton.Create(Self);
  DSRouteList := TXDataWebDataSet.Create(Self);
  DSRouteListTrip := TIntegerField.Create(Self);

  RouteSelectPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  CBRouteList.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  CloseRouteSelBtn.BeforeLoadDFMValues;
  DSRouteList.BeforeLoadDFMValues;
  DSRouteListTrip.BeforeLoadDFMValues;
  try
    Name := 'FSelectRoute';
    Width := 412;
    Height := 657;
    Align := alClient;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    RouteSelectPnl.SetParentComponent(Self);
    RouteSelectPnl.Name := 'RouteSelectPnl';
    RouteSelectPnl.Left := 50;
    RouteSelectPnl.Top := 69;
    RouteSelectPnl.Width := 301;
    RouteSelectPnl.Height := 464;
    RouteSelectPnl.ElementClassName := 'card';
    RouteSelectPnl.Anchors := [];
    RouteSelectPnl.ElementBodyClassName := 'card-body';
    WebLabel1.SetParentComponent(RouteSelectPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 40;
    WebLabel1.Top := 34;
    WebLabel1.Width := 209;
    WebLabel1.Height := 22;
    WebLabel1.Anchors := [];
    WebLabel1.Caption := 'Select Your Route Below';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    CBRouteList.SetParentComponent(RouteSelectPnl);
    CBRouteList.Name := 'CBRouteList';
    CBRouteList.Left := 102;
    CBRouteList.Top := 121;
    CBRouteList.Width := 81;
    CBRouteList.Height := 35;
    CBRouteList.Anchors := [];
    CBRouteList.Font.Charset := DEFAULT_CHARSET;
    CBRouteList.Font.Color := clWindowText;
    CBRouteList.Font.Height := -24;
    CBRouteList.Font.Name := 'Arial';
    CBRouteList.Font.Style := [];
    CBRouteList.HeightPercent := 100.000000000000000000;
    CBRouteList.ParentFont := False;
    CBRouteList.Text := '0';
    CBRouteList.WidthPercent := 100.000000000000000000;
    CBRouteList.ItemIndex := 0;
    CBRouteList.Items.BeginUpdate;
    try
      CBRouteList.Items.Clear;
      CBRouteList.Items.Add('0');
      CBRouteList.Items.Add('1');
      CBRouteList.Items.Add('2');
      CBRouteList.Items.Add('3');
      CBRouteList.Items.Add('4');
    finally
      CBRouteList.Items.EndUpdate;
    end;
    WebButton1.SetParentComponent(RouteSelectPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 64;
    WebButton1.Top := 272;
    WebButton1.Width := 169;
    WebButton1.Height := 65;
    WebButton1.Caption := 'Load Route';
    WebButton1.ChildOrder := 2;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    CloseRouteSelBtn.SetParentComponent(RouteSelectPnl);
    CloseRouteSelBtn.Name := 'CloseRouteSelBtn';
    CloseRouteSelBtn.Left := 80;
    CloseRouteSelBtn.Top := 368;
    CloseRouteSelBtn.Width := 129;
    CloseRouteSelBtn.Height := 41;
    CloseRouteSelBtn.ButtonType := 'Close';
    CloseRouteSelBtn.Caption := 'Close';
    CloseRouteSelBtn.ChildOrder := 3;
    CloseRouteSelBtn.ElementClassName := 'btn btn-danger';
    CloseRouteSelBtn.HeightPercent := 100.000000000000000000;
    CloseRouteSelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CloseRouteSelBtn, Self, 'OnClick', 'CloseRouteSelBtnClick');
    DSRouteList.SetParentComponent(Self);
    DSRouteList.Name := 'DSRouteList';
    DSRouteList.AfterOpen := DSRouteListAfterOpen;
    DSRouteList.EntitySetName := 'routelist';
    DSRouteList.Connection := Dm.ApiConnection;
    DSRouteList.Left := 23;
    DSRouteList.Top := 32;
    DSRouteListTrip.SetParentComponent(DSRouteList);
    DSRouteListTrip.Name := 'DSRouteListTrip';
    DSRouteListTrip.FieldName := 'Trip';
    DSRouteListTrip.Required := True;
  finally
    RouteSelectPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    CBRouteList.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    CloseRouteSelBtn.AfterLoadDFMValues;
    DSRouteList.AfterLoadDFMValues;
    DSRouteListTrip.AfterLoadDFMValues;
  end;
end;

end.
