unit RFCRecap;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.ExtCtrls, Vcl.Grids, WEBLib.DBCtrls, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, StrUtils;

type
  TFRFCRecap = class(TForm)
    RFCRecapPnl: TPanel;
    WebButton6: TButton;
    WDSReturns: TDataSource;
    DSReturns: TXDataWebDataSet;
    DSReturnsAutoInc: TIntegerField;
    DSReturnsOrderNum: TIntegerField;
    DSReturnsProd: TIntegerField;
    DSReturnsLineNum: TIntegerField;
    DSReturnsQty: TIntegerField;
    DSReturnsReasonCode: TIntegerField;
    DSReturnsReturningToMV: TStringField;
    DSReturnsNotes: TStringField;
    DSOrders: TXDataWebDataSet;
    DSOrdersOrderNum: TIntegerField;
    DSOrdersCust: TIntegerField;
    DSOrdersTrip: TIntegerField;
    DSOrdersStop: TIntegerField;
    DSOrdersInvoiceNum: TIntegerField;
    DSOrdersShipCust: TIntegerField;
    DSOrdersShipName: TStringField;
    DSOrdersShipContact: TStringField;
    DSOrdersShipCity: TStringField;
    DSOrdersShipState: TStringField;
    DSOrdersShipZip: TIntegerField;
    DSOrdersShipPhone: TStringField;
    DSOrdersShipAdd1: TStringField;
    DSOrdersShipAdd2: TStringField;
    DSOrdersPcsDry: TIntegerField;
    DSOrdersPcsCooler: TIntegerField;
    DSOrdersPcsFreezer: TIntegerField;
    DSOrdersPcsTotal: TIntegerField;
    DSOrdersPcsWeight: TFloatField;
    DSOrdersPcsCubes: TFloatField;
    DSOrdersPickupPcs: TIntegerField;
    DSOrdersWindowArriveTime: TDateTimeField;
    DSOrdersDeliveryWindow: TStringField;
    DSOrdersDeliveredFlag: TStringField;
    DSOrdersMess1: TStringField;
    DSOrdersMess2: TStringField;
    DSOrdersMess3: TStringField;
    DSOrdersMess4: TStringField;
    DSOrdersMess5: TStringField;
    DSOrdersStandingMessage: TStringField;
    WDSOrders: TDataSource;
    CurrentRouteGrid: TDBGrid;
    DSLabels: TXDataWebDataSet;
    DSLabelsAssignNum: TIntegerField;
    DSLabelsRPNum: TIntegerField;
    DSLabelsOrderNum: TIntegerField;
    DSLabelsLineNum: TIntegerField;
    DSLabelsDate: TDateTimeField;
    DSLabelsUnit_: TStringField;
    DSLabelsSize: TStringField;
    DSLabelsBrand: TStringField;
    DSLabelsDescription: TStringField;
    DSLabelsQtyOrd: TIntegerField;
    DSLabelsQtyShip: TIntegerField;
    DSLabelsCWFlag: TStringField;
    DSLabelsPalletNum: TIntegerField;
    DSLabelsQtyOnHand: TIntegerField;
    DSLabelsUnitsPerCase: TIntegerField;
    DSLabelsWeight: TFloatField;
    DSLabelsSlot: TStringField;
    DSLabelsCustNum: TIntegerField;
    DSLabelsTruck: TIntegerField;
    DSLabelsStop: TIntegerField;
    DSLabelsStickerNum: TIntegerField;
    DSLabelsServingsPerCase: TFloatField;
    DSLabelsCubes: TFloatField;
    DSLabelsUPC: TStringField;
    DSLabelsManufNum: TStringField;
    DSLabelsCubesPerLCD: TFloatField;
    DSLabelsCustName: TStringField;
    DSLabelsProd: TIntegerField;
    DSLabelsNoSubFlag: TStringField;
    DSLabelsPalletFullFlag: TStringField;
    DSLabelsWeightPerLCD: TFloatField;
    DSLabelsLabelType: TStringField;
    DSLabelsSkipFlag: TStringField;
    DSLabelsEnteredWeight: TFloatField;
    DSLabelsPickStatus: TStringField;
    DSLabelsIDNum: TIntegerField;
    DSLabelsPickBatchNum: TIntegerField;
    DSLabelsProdScan: TStringField;
    DSLabelsErrorStatus: TStringField;
    DSLabelsErrorStatusPerm: TStringField;
    DSLabelsUser: TStringField;
    DSLabelsLargeQty: TStringField;
    DSLabelsPrintBatchNum: TIntegerField;
    DSLabelsShellfishLot: TStringField;
    DSLabelsHACCPFlag: TStringField;
    DSLabelsSubItem: TIntegerField;
    DSLabelsReturnFlag: TStringField;
    DSLabelsSubOutPanelScanFlag: TStringField;
    DSLabelsPickerSubFlag: TStringField;
    DSLabelsPickTime: TDateTimeField;
    DSLabelsWeighTime: TDateTimeField;
    DSLabelsItemSubbed: TStringField;
    DSLabelsEarlyPickPallet: TStringField;
    DSLabelsSkipPrint: TStringField;
    DSLabelsLeftInSlot: TStringField;
    DSLabelsLeftInSlotPerm: TStringField;
    DSLabelsDriverScan: TStringField;
    DSReturnsPickTagID: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure DSOrdersAfterOpen(DataSet: TDataSet);
    procedure WebButton6Click(Sender: TObject);
    procedure DSLabelsAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FRFCRecap: TFRFCRecap;
  BCAssign,BCRPNum:String;
  ReturnString:String;

implementation

Uses Data, SelectRoute, Options;

{$R *.dfm}

procedure TFRFCRecap.DSLabelsAfterOpen(DataSet: TDataSet);
begin
  {if DSLabels.RecordCount > 0 then
  begin
    ReturnString := DSReturns.QueryString;
    DSReturns.QueryString := '$filter=(OrderNum eq '+DSLabelsOrderNum.AsString+' AND Prod eq '+DSLabelsProd.AsString+')';
    DSReturns.Load;
  end; }
end;

procedure TFRFCRecap.DSOrdersAfterOpen(DataSet: TDataSet);
begin
  DSReturns.QueryString := '$filter=(OrderNum eq '+DSOrdersOrderNum.AsString;
  while not DSOrders.Eof do
  begin
    DSReturns.QueryString := DSReturns.QueryString + ' or OrderNum eq '+DSOrdersOrderNum.AsString;
    DSOrders.Next;
  end;
  DSReturns.QueryString := DSReturns.QueryString + ')';
  DSReturns.Load;
end;

procedure TFRFCRecap.WebButton6Click(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFRFCRecap.WebFormShow(Sender: TObject);
begin
  RFCRecapPnl.Left := Round((FRFCRecap.Width / 2) -
    (RFCRecapPnl.Width / 2));
  RFCRecapPnl.Top := Round((FRFCRecap.Height / 2) -
    (RFCRecapPnl.Height / 2));
  if RFCRecapPnl.Top < 0 then
    RFCRecapPnl.Top := 0;
  DSOrders.QueryString := '$filter=(Trip eq ' + IntToStr(RouteNum) +
    ')&$orderby=Stop';
  DSOrders.Load;
end;

procedure TFRFCRecap.LoadDFMValues;
begin
  inherited LoadDFMValues;

  RFCRecapPnl := TPanel.Create(Self);
  WebButton6 := TButton.Create(Self);
  CurrentRouteGrid := TDBGrid.Create(Self);
  WDSReturns := TDataSource.Create(Self);
  DSReturns := TXDataWebDataSet.Create(Self);
  DSReturnsAutoInc := TIntegerField.Create(Self);
  DSReturnsOrderNum := TIntegerField.Create(Self);
  DSReturnsProd := TIntegerField.Create(Self);
  DSReturnsLineNum := TIntegerField.Create(Self);
  DSReturnsQty := TIntegerField.Create(Self);
  DSReturnsReasonCode := TIntegerField.Create(Self);
  DSReturnsReturningToMV := TStringField.Create(Self);
  DSReturnsNotes := TStringField.Create(Self);
  DSReturnsPickTagID := TStringField.Create(Self);
  DSOrders := TXDataWebDataSet.Create(Self);
  DSOrdersOrderNum := TIntegerField.Create(Self);
  DSOrdersCust := TIntegerField.Create(Self);
  DSOrdersTrip := TIntegerField.Create(Self);
  DSOrdersStop := TIntegerField.Create(Self);
  DSOrdersInvoiceNum := TIntegerField.Create(Self);
  DSOrdersShipCust := TIntegerField.Create(Self);
  DSOrdersShipName := TStringField.Create(Self);
  DSOrdersShipContact := TStringField.Create(Self);
  DSOrdersShipCity := TStringField.Create(Self);
  DSOrdersShipState := TStringField.Create(Self);
  DSOrdersShipZip := TIntegerField.Create(Self);
  DSOrdersShipPhone := TStringField.Create(Self);
  DSOrdersShipAdd1 := TStringField.Create(Self);
  DSOrdersShipAdd2 := TStringField.Create(Self);
  DSOrdersPcsDry := TIntegerField.Create(Self);
  DSOrdersPcsCooler := TIntegerField.Create(Self);
  DSOrdersPcsFreezer := TIntegerField.Create(Self);
  DSOrdersPcsTotal := TIntegerField.Create(Self);
  DSOrdersPcsWeight := TFloatField.Create(Self);
  DSOrdersPcsCubes := TFloatField.Create(Self);
  DSOrdersPickupPcs := TIntegerField.Create(Self);
  DSOrdersWindowArriveTime := TDateTimeField.Create(Self);
  DSOrdersDeliveryWindow := TStringField.Create(Self);
  DSOrdersDeliveredFlag := TStringField.Create(Self);
  DSOrdersMess1 := TStringField.Create(Self);
  DSOrdersMess2 := TStringField.Create(Self);
  DSOrdersMess3 := TStringField.Create(Self);
  DSOrdersMess4 := TStringField.Create(Self);
  DSOrdersMess5 := TStringField.Create(Self);
  DSOrdersStandingMessage := TStringField.Create(Self);
  WDSOrders := TDataSource.Create(Self);
  DSLabels := TXDataWebDataSet.Create(Self);
  DSLabelsAssignNum := TIntegerField.Create(Self);
  DSLabelsRPNum := TIntegerField.Create(Self);
  DSLabelsOrderNum := TIntegerField.Create(Self);
  DSLabelsLineNum := TIntegerField.Create(Self);
  DSLabelsDate := TDateTimeField.Create(Self);
  DSLabelsUnit_ := TStringField.Create(Self);
  DSLabelsSize := TStringField.Create(Self);
  DSLabelsBrand := TStringField.Create(Self);
  DSLabelsDescription := TStringField.Create(Self);
  DSLabelsQtyOrd := TIntegerField.Create(Self);
  DSLabelsQtyShip := TIntegerField.Create(Self);
  DSLabelsCWFlag := TStringField.Create(Self);
  DSLabelsPalletNum := TIntegerField.Create(Self);
  DSLabelsQtyOnHand := TIntegerField.Create(Self);
  DSLabelsUnitsPerCase := TIntegerField.Create(Self);
  DSLabelsWeight := TFloatField.Create(Self);
  DSLabelsSlot := TStringField.Create(Self);
  DSLabelsCustNum := TIntegerField.Create(Self);
  DSLabelsTruck := TIntegerField.Create(Self);
  DSLabelsStop := TIntegerField.Create(Self);
  DSLabelsStickerNum := TIntegerField.Create(Self);
  DSLabelsServingsPerCase := TFloatField.Create(Self);
  DSLabelsCubes := TFloatField.Create(Self);
  DSLabelsUPC := TStringField.Create(Self);
  DSLabelsManufNum := TStringField.Create(Self);
  DSLabelsCubesPerLCD := TFloatField.Create(Self);
  DSLabelsCustName := TStringField.Create(Self);
  DSLabelsProd := TIntegerField.Create(Self);
  DSLabelsNoSubFlag := TStringField.Create(Self);
  DSLabelsPalletFullFlag := TStringField.Create(Self);
  DSLabelsWeightPerLCD := TFloatField.Create(Self);
  DSLabelsLabelType := TStringField.Create(Self);
  DSLabelsSkipFlag := TStringField.Create(Self);
  DSLabelsEnteredWeight := TFloatField.Create(Self);
  DSLabelsPickStatus := TStringField.Create(Self);
  DSLabelsIDNum := TIntegerField.Create(Self);
  DSLabelsPickBatchNum := TIntegerField.Create(Self);
  DSLabelsProdScan := TStringField.Create(Self);
  DSLabelsErrorStatus := TStringField.Create(Self);
  DSLabelsErrorStatusPerm := TStringField.Create(Self);
  DSLabelsUser := TStringField.Create(Self);
  DSLabelsLargeQty := TStringField.Create(Self);
  DSLabelsPrintBatchNum := TIntegerField.Create(Self);
  DSLabelsShellfishLot := TStringField.Create(Self);
  DSLabelsHACCPFlag := TStringField.Create(Self);
  DSLabelsSubItem := TIntegerField.Create(Self);
  DSLabelsReturnFlag := TStringField.Create(Self);
  DSLabelsSubOutPanelScanFlag := TStringField.Create(Self);
  DSLabelsPickerSubFlag := TStringField.Create(Self);
  DSLabelsPickTime := TDateTimeField.Create(Self);
  DSLabelsWeighTime := TDateTimeField.Create(Self);
  DSLabelsItemSubbed := TStringField.Create(Self);
  DSLabelsEarlyPickPallet := TStringField.Create(Self);
  DSLabelsSkipPrint := TStringField.Create(Self);
  DSLabelsLeftInSlot := TStringField.Create(Self);
  DSLabelsLeftInSlotPerm := TStringField.Create(Self);
  DSLabelsDriverScan := TStringField.Create(Self);

  RFCRecapPnl.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  CurrentRouteGrid.BeforeLoadDFMValues;
  WDSReturns.BeforeLoadDFMValues;
  DSReturns.BeforeLoadDFMValues;
  DSReturnsAutoInc.BeforeLoadDFMValues;
  DSReturnsOrderNum.BeforeLoadDFMValues;
  DSReturnsProd.BeforeLoadDFMValues;
  DSReturnsLineNum.BeforeLoadDFMValues;
  DSReturnsQty.BeforeLoadDFMValues;
  DSReturnsReasonCode.BeforeLoadDFMValues;
  DSReturnsReturningToMV.BeforeLoadDFMValues;
  DSReturnsNotes.BeforeLoadDFMValues;
  DSReturnsPickTagID.BeforeLoadDFMValues;
  DSOrders.BeforeLoadDFMValues;
  DSOrdersOrderNum.BeforeLoadDFMValues;
  DSOrdersCust.BeforeLoadDFMValues;
  DSOrdersTrip.BeforeLoadDFMValues;
  DSOrdersStop.BeforeLoadDFMValues;
  DSOrdersInvoiceNum.BeforeLoadDFMValues;
  DSOrdersShipCust.BeforeLoadDFMValues;
  DSOrdersShipName.BeforeLoadDFMValues;
  DSOrdersShipContact.BeforeLoadDFMValues;
  DSOrdersShipCity.BeforeLoadDFMValues;
  DSOrdersShipState.BeforeLoadDFMValues;
  DSOrdersShipZip.BeforeLoadDFMValues;
  DSOrdersShipPhone.BeforeLoadDFMValues;
  DSOrdersShipAdd1.BeforeLoadDFMValues;
  DSOrdersShipAdd2.BeforeLoadDFMValues;
  DSOrdersPcsDry.BeforeLoadDFMValues;
  DSOrdersPcsCooler.BeforeLoadDFMValues;
  DSOrdersPcsFreezer.BeforeLoadDFMValues;
  DSOrdersPcsTotal.BeforeLoadDFMValues;
  DSOrdersPcsWeight.BeforeLoadDFMValues;
  DSOrdersPcsCubes.BeforeLoadDFMValues;
  DSOrdersPickupPcs.BeforeLoadDFMValues;
  DSOrdersWindowArriveTime.BeforeLoadDFMValues;
  DSOrdersDeliveryWindow.BeforeLoadDFMValues;
  DSOrdersDeliveredFlag.BeforeLoadDFMValues;
  DSOrdersMess1.BeforeLoadDFMValues;
  DSOrdersMess2.BeforeLoadDFMValues;
  DSOrdersMess3.BeforeLoadDFMValues;
  DSOrdersMess4.BeforeLoadDFMValues;
  DSOrdersMess5.BeforeLoadDFMValues;
  DSOrdersStandingMessage.BeforeLoadDFMValues;
  WDSOrders.BeforeLoadDFMValues;
  DSLabels.BeforeLoadDFMValues;
  DSLabelsAssignNum.BeforeLoadDFMValues;
  DSLabelsRPNum.BeforeLoadDFMValues;
  DSLabelsOrderNum.BeforeLoadDFMValues;
  DSLabelsLineNum.BeforeLoadDFMValues;
  DSLabelsDate.BeforeLoadDFMValues;
  DSLabelsUnit_.BeforeLoadDFMValues;
  DSLabelsSize.BeforeLoadDFMValues;
  DSLabelsBrand.BeforeLoadDFMValues;
  DSLabelsDescription.BeforeLoadDFMValues;
  DSLabelsQtyOrd.BeforeLoadDFMValues;
  DSLabelsQtyShip.BeforeLoadDFMValues;
  DSLabelsCWFlag.BeforeLoadDFMValues;
  DSLabelsPalletNum.BeforeLoadDFMValues;
  DSLabelsQtyOnHand.BeforeLoadDFMValues;
  DSLabelsUnitsPerCase.BeforeLoadDFMValues;
  DSLabelsWeight.BeforeLoadDFMValues;
  DSLabelsSlot.BeforeLoadDFMValues;
  DSLabelsCustNum.BeforeLoadDFMValues;
  DSLabelsTruck.BeforeLoadDFMValues;
  DSLabelsStop.BeforeLoadDFMValues;
  DSLabelsStickerNum.BeforeLoadDFMValues;
  DSLabelsServingsPerCase.BeforeLoadDFMValues;
  DSLabelsCubes.BeforeLoadDFMValues;
  DSLabelsUPC.BeforeLoadDFMValues;
  DSLabelsManufNum.BeforeLoadDFMValues;
  DSLabelsCubesPerLCD.BeforeLoadDFMValues;
  DSLabelsCustName.BeforeLoadDFMValues;
  DSLabelsProd.BeforeLoadDFMValues;
  DSLabelsNoSubFlag.BeforeLoadDFMValues;
  DSLabelsPalletFullFlag.BeforeLoadDFMValues;
  DSLabelsWeightPerLCD.BeforeLoadDFMValues;
  DSLabelsLabelType.BeforeLoadDFMValues;
  DSLabelsSkipFlag.BeforeLoadDFMValues;
  DSLabelsEnteredWeight.BeforeLoadDFMValues;
  DSLabelsPickStatus.BeforeLoadDFMValues;
  DSLabelsIDNum.BeforeLoadDFMValues;
  DSLabelsPickBatchNum.BeforeLoadDFMValues;
  DSLabelsProdScan.BeforeLoadDFMValues;
  DSLabelsErrorStatus.BeforeLoadDFMValues;
  DSLabelsErrorStatusPerm.BeforeLoadDFMValues;
  DSLabelsUser.BeforeLoadDFMValues;
  DSLabelsLargeQty.BeforeLoadDFMValues;
  DSLabelsPrintBatchNum.BeforeLoadDFMValues;
  DSLabelsShellfishLot.BeforeLoadDFMValues;
  DSLabelsHACCPFlag.BeforeLoadDFMValues;
  DSLabelsSubItem.BeforeLoadDFMValues;
  DSLabelsReturnFlag.BeforeLoadDFMValues;
  DSLabelsSubOutPanelScanFlag.BeforeLoadDFMValues;
  DSLabelsPickerSubFlag.BeforeLoadDFMValues;
  DSLabelsPickTime.BeforeLoadDFMValues;
  DSLabelsWeighTime.BeforeLoadDFMValues;
  DSLabelsItemSubbed.BeforeLoadDFMValues;
  DSLabelsEarlyPickPallet.BeforeLoadDFMValues;
  DSLabelsSkipPrint.BeforeLoadDFMValues;
  DSLabelsLeftInSlot.BeforeLoadDFMValues;
  DSLabelsLeftInSlotPerm.BeforeLoadDFMValues;
  DSLabelsDriverScan.BeforeLoadDFMValues;
  try
    Name := 'FRFCRecap';
    Width := 581;
    Height := 737;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    RFCRecapPnl.SetParentComponent(Self);
    RFCRecapPnl.Name := 'RFCRecapPnl';
    RFCRecapPnl.Left := 6;
    RFCRecapPnl.Top := 6;
    RFCRecapPnl.Width := 398;
    RFCRecapPnl.Height := 723;
    RFCRecapPnl.ElementClassName := 'card';
    RFCRecapPnl.ElementBodyClassName := 'card-body';
    RFCRecapPnl.Font.Charset := DEFAULT_CHARSET;
    RFCRecapPnl.Font.Color := clWindowText;
    RFCRecapPnl.Font.Height := -19;
    RFCRecapPnl.Font.Name := 'Arial';
    RFCRecapPnl.Font.Style := [];
    RFCRecapPnl.ParentFont := False;
    WebButton6.SetParentComponent(RFCRecapPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 232;
    WebButton6.Top := 647;
    WebButton6.Width := 161;
    WebButton6.Height := 73;
    WebButton6.Caption := 'Close';
    WebButton6.ChildOrder := 2;
    WebButton6.ElementClassName := 'btn btn-danger';
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    CurrentRouteGrid.SetParentComponent(RFCRecapPnl);
    CurrentRouteGrid.Name := 'CurrentRouteGrid';
    CurrentRouteGrid.Left := 4;
    CurrentRouteGrid.Top := 5;
    CurrentRouteGrid.Width := 389;
    CurrentRouteGrid.Height := 540;
    CurrentRouteGrid.Columns.Clear;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'OrderNum';
      Title := 'OrderNum';
      Width := 70;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'Prod';
      Title := 'Prod';
      Width := 50;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'Qty';
      Title := 'Qty';
      Width := 40;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'ReasonCode';
      Title := 'Code';
      Width := 40;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'ReturningToMV';
      Title := 'Return';
      Width := 50;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'Notes';
      Title := 'Notes';
      Width := 120;
    end;
    CurrentRouteGrid.DataSource := WDSReturns;
    CurrentRouteGrid.FixedCols := 0;
    CurrentRouteGrid.Font.Charset := DEFAULT_CHARSET;
    CurrentRouteGrid.Font.Color := clWindowText;
    CurrentRouteGrid.Font.Height := -13;
    CurrentRouteGrid.Font.Name := 'Arial';
    CurrentRouteGrid.Font.Style := [];
    CurrentRouteGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    CurrentRouteGrid.ParentFont := False;
    CurrentRouteGrid.TabOrder := 1;
    CurrentRouteGrid.HeightPercent := 100.000000000000000000;
    CurrentRouteGrid.WidthPercent := 100.000000000000000000;
    CurrentRouteGrid.ColWidths[0] := 70;
    CurrentRouteGrid.ColWidths[1] := 50;
    CurrentRouteGrid.ColWidths[2] := 40;
    CurrentRouteGrid.ColWidths[3] := 40;
    CurrentRouteGrid.ColWidths[4] := 50;
    CurrentRouteGrid.ColWidths[5] := 120;
    WDSReturns.SetParentComponent(Self);
    WDSReturns.Name := 'WDSReturns';
    WDSReturns.DataSet := DSReturns;
    WDSReturns.Left := 504;
    WDSReturns.Top := 128;
    DSReturns.SetParentComponent(Self);
    DSReturns.Name := 'DSReturns';
    DSReturns.EntitySetName := 'returns';
    DSReturns.Connection := Dm.ApiConnection;
    DSReturns.Left := 456;
    DSReturns.Top := 96;
    DSReturnsAutoInc.SetParentComponent(DSReturns);
    DSReturnsAutoInc.Name := 'DSReturnsAutoInc';
    DSReturnsAutoInc.FieldName := 'AutoInc';
    DSReturnsAutoInc.Required := True;
    DSReturnsOrderNum.SetParentComponent(DSReturns);
    DSReturnsOrderNum.Name := 'DSReturnsOrderNum';
    DSReturnsOrderNum.FieldName := 'OrderNum';
    DSReturnsProd.SetParentComponent(DSReturns);
    DSReturnsProd.Name := 'DSReturnsProd';
    DSReturnsProd.FieldName := 'Prod';
    DSReturnsLineNum.SetParentComponent(DSReturns);
    DSReturnsLineNum.Name := 'DSReturnsLineNum';
    DSReturnsLineNum.FieldName := 'LineNum';
    DSReturnsQty.SetParentComponent(DSReturns);
    DSReturnsQty.Name := 'DSReturnsQty';
    DSReturnsQty.FieldName := 'Qty';
    DSReturnsReasonCode.SetParentComponent(DSReturns);
    DSReturnsReasonCode.Name := 'DSReturnsReasonCode';
    DSReturnsReasonCode.FieldName := 'ReasonCode';
    DSReturnsReturningToMV.SetParentComponent(DSReturns);
    DSReturnsReturningToMV.Name := 'DSReturnsReturningToMV';
    DSReturnsReturningToMV.FieldName := 'ReturningToMV';
    DSReturnsReturningToMV.Size := 1;
    DSReturnsNotes.SetParentComponent(DSReturns);
    DSReturnsNotes.Name := 'DSReturnsNotes';
    DSReturnsNotes.FieldName := 'Notes';
    DSReturnsNotes.Size := 100;
    DSReturnsPickTagID.SetParentComponent(DSReturns);
    DSReturnsPickTagID.Name := 'DSReturnsPickTagID';
    DSReturnsPickTagID.FieldName := 'PickTagID';
    DSReturnsPickTagID.Size := 25;
    DSOrders.SetParentComponent(Self);
    DSOrders.Name := 'DSOrders';
    DSOrders.AfterOpen := DSOrdersAfterOpen;
    DSOrders.EntitySetName := 'delorders';
    DSOrders.Connection := Dm.ApiConnection;
    DSOrders.Left := 455;
    DSOrders.Top := 168;
    DSOrdersOrderNum.SetParentComponent(DSOrders);
    DSOrdersOrderNum.Name := 'DSOrdersOrderNum';
    DSOrdersOrderNum.FieldName := 'OrderNum';
    DSOrdersCust.SetParentComponent(DSOrders);
    DSOrdersCust.Name := 'DSOrdersCust';
    DSOrdersCust.FieldName := 'Cust';
    DSOrdersTrip.SetParentComponent(DSOrders);
    DSOrdersTrip.Name := 'DSOrdersTrip';
    DSOrdersTrip.FieldName := 'Trip';
    DSOrdersStop.SetParentComponent(DSOrders);
    DSOrdersStop.Name := 'DSOrdersStop';
    DSOrdersStop.FieldName := 'Stop';
    DSOrdersInvoiceNum.SetParentComponent(DSOrders);
    DSOrdersInvoiceNum.Name := 'DSOrdersInvoiceNum';
    DSOrdersInvoiceNum.FieldName := 'InvoiceNum';
    DSOrdersShipCust.SetParentComponent(DSOrders);
    DSOrdersShipCust.Name := 'DSOrdersShipCust';
    DSOrdersShipCust.FieldName := 'ShipCust';
    DSOrdersShipName.SetParentComponent(DSOrders);
    DSOrdersShipName.Name := 'DSOrdersShipName';
    DSOrdersShipName.FieldName := 'ShipName';
    DSOrdersShipName.Size := 25;
    DSOrdersShipContact.SetParentComponent(DSOrders);
    DSOrdersShipContact.Name := 'DSOrdersShipContact';
    DSOrdersShipContact.FieldName := 'ShipContact';
    DSOrdersShipContact.Size := 25;
    DSOrdersShipCity.SetParentComponent(DSOrders);
    DSOrdersShipCity.Name := 'DSOrdersShipCity';
    DSOrdersShipCity.FieldName := 'ShipCity';
    DSOrdersShipCity.Size := 25;
    DSOrdersShipState.SetParentComponent(DSOrders);
    DSOrdersShipState.Name := 'DSOrdersShipState';
    DSOrdersShipState.FieldName := 'ShipState';
    DSOrdersShipState.Size := 2;
    DSOrdersShipZip.SetParentComponent(DSOrders);
    DSOrdersShipZip.Name := 'DSOrdersShipZip';
    DSOrdersShipZip.FieldName := 'ShipZip';
    DSOrdersShipPhone.SetParentComponent(DSOrders);
    DSOrdersShipPhone.Name := 'DSOrdersShipPhone';
    DSOrdersShipPhone.FieldName := 'ShipPhone';
    DSOrdersShipPhone.Size := 15;
    DSOrdersShipAdd1.SetParentComponent(DSOrders);
    DSOrdersShipAdd1.Name := 'DSOrdersShipAdd1';
    DSOrdersShipAdd1.FieldName := 'ShipAdd1';
    DSOrdersShipAdd1.Size := 25;
    DSOrdersShipAdd2.SetParentComponent(DSOrders);
    DSOrdersShipAdd2.Name := 'DSOrdersShipAdd2';
    DSOrdersShipAdd2.FieldName := 'ShipAdd2';
    DSOrdersShipAdd2.Size := 25;
    DSOrdersPcsDry.SetParentComponent(DSOrders);
    DSOrdersPcsDry.Name := 'DSOrdersPcsDry';
    DSOrdersPcsDry.FieldName := 'PcsDry';
    DSOrdersPcsCooler.SetParentComponent(DSOrders);
    DSOrdersPcsCooler.Name := 'DSOrdersPcsCooler';
    DSOrdersPcsCooler.FieldName := 'PcsCooler';
    DSOrdersPcsFreezer.SetParentComponent(DSOrders);
    DSOrdersPcsFreezer.Name := 'DSOrdersPcsFreezer';
    DSOrdersPcsFreezer.FieldName := 'PcsFreezer';
    DSOrdersPcsTotal.SetParentComponent(DSOrders);
    DSOrdersPcsTotal.Name := 'DSOrdersPcsTotal';
    DSOrdersPcsTotal.FieldName := 'PcsTotal';
    DSOrdersPcsWeight.SetParentComponent(DSOrders);
    DSOrdersPcsWeight.Name := 'DSOrdersPcsWeight';
    DSOrdersPcsWeight.FieldName := 'PcsWeight';
    DSOrdersPcsCubes.SetParentComponent(DSOrders);
    DSOrdersPcsCubes.Name := 'DSOrdersPcsCubes';
    DSOrdersPcsCubes.FieldName := 'PcsCubes';
    DSOrdersPickupPcs.SetParentComponent(DSOrders);
    DSOrdersPickupPcs.Name := 'DSOrdersPickupPcs';
    DSOrdersPickupPcs.FieldName := 'PickupPcs';
    DSOrdersWindowArriveTime.SetParentComponent(DSOrders);
    DSOrdersWindowArriveTime.Name := 'DSOrdersWindowArriveTime';
    DSOrdersWindowArriveTime.FieldName := 'WindowArriveTime';
    DSOrdersDeliveryWindow.SetParentComponent(DSOrders);
    DSOrdersDeliveryWindow.Name := 'DSOrdersDeliveryWindow';
    DSOrdersDeliveryWindow.FieldName := 'DeliveryWindow';
    DSOrdersDeliveryWindow.Size := 50;
    DSOrdersDeliveredFlag.SetParentComponent(DSOrders);
    DSOrdersDeliveredFlag.Name := 'DSOrdersDeliveredFlag';
    DSOrdersDeliveredFlag.FieldName := 'DeliveredFlag';
    DSOrdersDeliveredFlag.Size := 1;
    DSOrdersMess1.SetParentComponent(DSOrders);
    DSOrdersMess1.Name := 'DSOrdersMess1';
    DSOrdersMess1.FieldName := 'Mess1';
    DSOrdersMess1.Size := 50;
    DSOrdersMess2.SetParentComponent(DSOrders);
    DSOrdersMess2.Name := 'DSOrdersMess2';
    DSOrdersMess2.FieldName := 'Mess2';
    DSOrdersMess2.Size := 50;
    DSOrdersMess3.SetParentComponent(DSOrders);
    DSOrdersMess3.Name := 'DSOrdersMess3';
    DSOrdersMess3.FieldName := 'Mess3';
    DSOrdersMess3.Size := 50;
    DSOrdersMess4.SetParentComponent(DSOrders);
    DSOrdersMess4.Name := 'DSOrdersMess4';
    DSOrdersMess4.FieldName := 'Mess4';
    DSOrdersMess4.Size := 50;
    DSOrdersMess5.SetParentComponent(DSOrders);
    DSOrdersMess5.Name := 'DSOrdersMess5';
    DSOrdersMess5.FieldName := 'Mess5';
    DSOrdersMess5.Size := 50;
    DSOrdersStandingMessage.SetParentComponent(DSOrders);
    DSOrdersStandingMessage.Name := 'DSOrdersStandingMessage';
    DSOrdersStandingMessage.FieldName := 'StandingMessage';
    DSOrdersStandingMessage.Size := 100;
    WDSOrders.SetParentComponent(Self);
    WDSOrders.Name := 'WDSOrders';
    WDSOrders.DataSet := DSOrders;
    WDSOrders.Left := 496;
    WDSOrders.Top := 200;
    DSLabels.SetParentComponent(Self);
    DSLabels.Name := 'DSLabels';
    DSLabels.AfterOpen := DSLabelsAfterOpen;
    DSLabels.EntitySetName := 'PickLabels';
    DSLabels.Connection := Dm.ApiConnection;
    DSLabels.Left := 448;
    DSLabels.Top := 256;
    DSLabelsAssignNum.SetParentComponent(DSLabels);
    DSLabelsAssignNum.Name := 'DSLabelsAssignNum';
    DSLabelsAssignNum.FieldName := 'AssignNum';
    DSLabelsAssignNum.Required := True;
    DSLabelsRPNum.SetParentComponent(DSLabels);
    DSLabelsRPNum.Name := 'DSLabelsRPNum';
    DSLabelsRPNum.FieldName := 'RPNum';
    DSLabelsRPNum.Required := True;
    DSLabelsOrderNum.SetParentComponent(DSLabels);
    DSLabelsOrderNum.Name := 'DSLabelsOrderNum';
    DSLabelsOrderNum.FieldName := 'OrderNum';
    DSLabelsLineNum.SetParentComponent(DSLabels);
    DSLabelsLineNum.Name := 'DSLabelsLineNum';
    DSLabelsLineNum.FieldName := 'LineNum';
    DSLabelsDate.SetParentComponent(DSLabels);
    DSLabelsDate.Name := 'DSLabelsDate';
    DSLabelsDate.FieldName := 'Date';
    DSLabelsUnit_.SetParentComponent(DSLabels);
    DSLabelsUnit_.Name := 'DSLabelsUnit_';
    DSLabelsUnit_.FieldName := 'Unit_';
    DSLabelsUnit_.Size := 3;
    DSLabelsSize.SetParentComponent(DSLabels);
    DSLabelsSize.Name := 'DSLabelsSize';
    DSLabelsSize.FieldName := 'Size';
    DSLabelsSize.Size := 6;
    DSLabelsBrand.SetParentComponent(DSLabels);
    DSLabelsBrand.Name := 'DSLabelsBrand';
    DSLabelsBrand.FieldName := 'Brand';
    DSLabelsBrand.Size := 18;
    DSLabelsDescription.SetParentComponent(DSLabels);
    DSLabelsDescription.Name := 'DSLabelsDescription';
    DSLabelsDescription.FieldName := 'Description';
    DSLabelsDescription.Size := 25;
    DSLabelsQtyOrd.SetParentComponent(DSLabels);
    DSLabelsQtyOrd.Name := 'DSLabelsQtyOrd';
    DSLabelsQtyOrd.FieldName := 'QtyOrd';
    DSLabelsQtyShip.SetParentComponent(DSLabels);
    DSLabelsQtyShip.Name := 'DSLabelsQtyShip';
    DSLabelsQtyShip.FieldName := 'QtyShip';
    DSLabelsCWFlag.SetParentComponent(DSLabels);
    DSLabelsCWFlag.Name := 'DSLabelsCWFlag';
    DSLabelsCWFlag.FieldName := 'CWFlag';
    DSLabelsCWFlag.Size := 1;
    DSLabelsPalletNum.SetParentComponent(DSLabels);
    DSLabelsPalletNum.Name := 'DSLabelsPalletNum';
    DSLabelsPalletNum.FieldName := 'PalletNum';
    DSLabelsQtyOnHand.SetParentComponent(DSLabels);
    DSLabelsQtyOnHand.Name := 'DSLabelsQtyOnHand';
    DSLabelsQtyOnHand.FieldName := 'QtyOnHand';
    DSLabelsUnitsPerCase.SetParentComponent(DSLabels);
    DSLabelsUnitsPerCase.Name := 'DSLabelsUnitsPerCase';
    DSLabelsUnitsPerCase.FieldName := 'UnitsPerCase';
    DSLabelsWeight.SetParentComponent(DSLabels);
    DSLabelsWeight.Name := 'DSLabelsWeight';
    DSLabelsWeight.FieldName := 'Weight';
    DSLabelsSlot.SetParentComponent(DSLabels);
    DSLabelsSlot.Name := 'DSLabelsSlot';
    DSLabelsSlot.FieldName := 'Slot';
    DSLabelsSlot.Size := 5;
    DSLabelsCustNum.SetParentComponent(DSLabels);
    DSLabelsCustNum.Name := 'DSLabelsCustNum';
    DSLabelsCustNum.FieldName := 'CustNum';
    DSLabelsTruck.SetParentComponent(DSLabels);
    DSLabelsTruck.Name := 'DSLabelsTruck';
    DSLabelsTruck.FieldName := 'Truck';
    DSLabelsStop.SetParentComponent(DSLabels);
    DSLabelsStop.Name := 'DSLabelsStop';
    DSLabelsStop.FieldName := 'Stop';
    DSLabelsStickerNum.SetParentComponent(DSLabels);
    DSLabelsStickerNum.Name := 'DSLabelsStickerNum';
    DSLabelsStickerNum.FieldName := 'StickerNum';
    DSLabelsServingsPerCase.SetParentComponent(DSLabels);
    DSLabelsServingsPerCase.Name := 'DSLabelsServingsPerCase';
    DSLabelsServingsPerCase.FieldName := 'ServingsPerCase';
    DSLabelsCubes.SetParentComponent(DSLabels);
    DSLabelsCubes.Name := 'DSLabelsCubes';
    DSLabelsCubes.FieldName := 'Cubes';
    DSLabelsUPC.SetParentComponent(DSLabels);
    DSLabelsUPC.Name := 'DSLabelsUPC';
    DSLabelsUPC.FieldName := 'UPC';
    DSLabelsUPC.Size := 10;
    DSLabelsManufNum.SetParentComponent(DSLabels);
    DSLabelsManufNum.Name := 'DSLabelsManufNum';
    DSLabelsManufNum.FieldName := 'ManufNum';
    DSLabelsManufNum.Size := 10;
    DSLabelsCubesPerLCD.SetParentComponent(DSLabels);
    DSLabelsCubesPerLCD.Name := 'DSLabelsCubesPerLCD';
    DSLabelsCubesPerLCD.FieldName := 'CubesPerLCD';
    DSLabelsCustName.SetParentComponent(DSLabels);
    DSLabelsCustName.Name := 'DSLabelsCustName';
    DSLabelsCustName.FieldName := 'CustName';
    DSLabelsCustName.Size := 25;
    DSLabelsProd.SetParentComponent(DSLabels);
    DSLabelsProd.Name := 'DSLabelsProd';
    DSLabelsProd.FieldName := 'Prod';
    DSLabelsNoSubFlag.SetParentComponent(DSLabels);
    DSLabelsNoSubFlag.Name := 'DSLabelsNoSubFlag';
    DSLabelsNoSubFlag.FieldName := 'NoSubFlag';
    DSLabelsNoSubFlag.Size := 1;
    DSLabelsPalletFullFlag.SetParentComponent(DSLabels);
    DSLabelsPalletFullFlag.Name := 'DSLabelsPalletFullFlag';
    DSLabelsPalletFullFlag.FieldName := 'PalletFullFlag';
    DSLabelsPalletFullFlag.Size := 1;
    DSLabelsWeightPerLCD.SetParentComponent(DSLabels);
    DSLabelsWeightPerLCD.Name := 'DSLabelsWeightPerLCD';
    DSLabelsWeightPerLCD.FieldName := 'WeightPerLCD';
    DSLabelsLabelType.SetParentComponent(DSLabels);
    DSLabelsLabelType.Name := 'DSLabelsLabelType';
    DSLabelsLabelType.FieldName := 'LabelType';
    DSLabelsLabelType.Size := 1;
    DSLabelsSkipFlag.SetParentComponent(DSLabels);
    DSLabelsSkipFlag.Name := 'DSLabelsSkipFlag';
    DSLabelsSkipFlag.FieldName := 'SkipFlag';
    DSLabelsSkipFlag.Size := 1;
    DSLabelsEnteredWeight.SetParentComponent(DSLabels);
    DSLabelsEnteredWeight.Name := 'DSLabelsEnteredWeight';
    DSLabelsEnteredWeight.FieldName := 'EnteredWeight';
    DSLabelsPickStatus.SetParentComponent(DSLabels);
    DSLabelsPickStatus.Name := 'DSLabelsPickStatus';
    DSLabelsPickStatus.FieldName := 'PickStatus';
    DSLabelsPickStatus.Size := 1;
    DSLabelsIDNum.SetParentComponent(DSLabels);
    DSLabelsIDNum.Name := 'DSLabelsIDNum';
    DSLabelsIDNum.FieldName := 'IDNum';
    DSLabelsPickBatchNum.SetParentComponent(DSLabels);
    DSLabelsPickBatchNum.Name := 'DSLabelsPickBatchNum';
    DSLabelsPickBatchNum.FieldName := 'PickBatchNum';
    DSLabelsProdScan.SetParentComponent(DSLabels);
    DSLabelsProdScan.Name := 'DSLabelsProdScan';
    DSLabelsProdScan.FieldName := 'ProdScan';
    DSLabelsProdScan.Size := 100;
    DSLabelsErrorStatus.SetParentComponent(DSLabels);
    DSLabelsErrorStatus.Name := 'DSLabelsErrorStatus';
    DSLabelsErrorStatus.FieldName := 'ErrorStatus';
    DSLabelsErrorStatus.Size := 1;
    DSLabelsErrorStatusPerm.SetParentComponent(DSLabels);
    DSLabelsErrorStatusPerm.Name := 'DSLabelsErrorStatusPerm';
    DSLabelsErrorStatusPerm.FieldName := 'ErrorStatusPerm';
    DSLabelsErrorStatusPerm.Size := 1;
    DSLabelsUser.SetParentComponent(DSLabels);
    DSLabelsUser.Name := 'DSLabelsUser';
    DSLabelsUser.FieldName := 'User';
    DSLabelsUser.Size := 50;
    DSLabelsLargeQty.SetParentComponent(DSLabels);
    DSLabelsLargeQty.Name := 'DSLabelsLargeQty';
    DSLabelsLargeQty.FieldName := 'LargeQty';
    DSLabelsLargeQty.Size := 1;
    DSLabelsPrintBatchNum.SetParentComponent(DSLabels);
    DSLabelsPrintBatchNum.Name := 'DSLabelsPrintBatchNum';
    DSLabelsPrintBatchNum.FieldName := 'PrintBatchNum';
    DSLabelsShellfishLot.SetParentComponent(DSLabels);
    DSLabelsShellfishLot.Name := 'DSLabelsShellfishLot';
    DSLabelsShellfishLot.FieldName := 'ShellfishLot';
    DSLabelsShellfishLot.Size := 50;
    DSLabelsHACCPFlag.SetParentComponent(DSLabels);
    DSLabelsHACCPFlag.Name := 'DSLabelsHACCPFlag';
    DSLabelsHACCPFlag.FieldName := 'HACCPFlag';
    DSLabelsHACCPFlag.Size := 1;
    DSLabelsSubItem.SetParentComponent(DSLabels);
    DSLabelsSubItem.Name := 'DSLabelsSubItem';
    DSLabelsSubItem.FieldName := 'SubItem';
    DSLabelsReturnFlag.SetParentComponent(DSLabels);
    DSLabelsReturnFlag.Name := 'DSLabelsReturnFlag';
    DSLabelsReturnFlag.FieldName := 'ReturnFlag';
    DSLabelsReturnFlag.Size := 1;
    DSLabelsSubOutPanelScanFlag.SetParentComponent(DSLabels);
    DSLabelsSubOutPanelScanFlag.Name := 'DSLabelsSubOutPanelScanFlag';
    DSLabelsSubOutPanelScanFlag.FieldName := 'SubOutPanelScanFlag';
    DSLabelsSubOutPanelScanFlag.Size := 1;
    DSLabelsPickerSubFlag.SetParentComponent(DSLabels);
    DSLabelsPickerSubFlag.Name := 'DSLabelsPickerSubFlag';
    DSLabelsPickerSubFlag.FieldName := 'PickerSubFlag';
    DSLabelsPickerSubFlag.Size := 1;
    DSLabelsPickTime.SetParentComponent(DSLabels);
    DSLabelsPickTime.Name := 'DSLabelsPickTime';
    DSLabelsPickTime.FieldName := 'PickTime';
    DSLabelsWeighTime.SetParentComponent(DSLabels);
    DSLabelsWeighTime.Name := 'DSLabelsWeighTime';
    DSLabelsWeighTime.FieldName := 'WeighTime';
    DSLabelsItemSubbed.SetParentComponent(DSLabels);
    DSLabelsItemSubbed.Name := 'DSLabelsItemSubbed';
    DSLabelsItemSubbed.FieldName := 'ItemSubbed';
    DSLabelsItemSubbed.Size := 1;
    DSLabelsEarlyPickPallet.SetParentComponent(DSLabels);
    DSLabelsEarlyPickPallet.Name := 'DSLabelsEarlyPickPallet';
    DSLabelsEarlyPickPallet.FieldName := 'EarlyPickPallet';
    DSLabelsEarlyPickPallet.Size := 1;
    DSLabelsSkipPrint.SetParentComponent(DSLabels);
    DSLabelsSkipPrint.Name := 'DSLabelsSkipPrint';
    DSLabelsSkipPrint.FieldName := 'SkipPrint';
    DSLabelsSkipPrint.Size := 1;
    DSLabelsLeftInSlot.SetParentComponent(DSLabels);
    DSLabelsLeftInSlot.Name := 'DSLabelsLeftInSlot';
    DSLabelsLeftInSlot.FieldName := 'LeftInSlot';
    DSLabelsLeftInSlot.Size := 1;
    DSLabelsLeftInSlotPerm.SetParentComponent(DSLabels);
    DSLabelsLeftInSlotPerm.Name := 'DSLabelsLeftInSlotPerm';
    DSLabelsLeftInSlotPerm.FieldName := 'LeftInSlotPerm';
    DSLabelsLeftInSlotPerm.Size := 1;
    DSLabelsDriverScan.SetParentComponent(DSLabels);
    DSLabelsDriverScan.Name := 'DSLabelsDriverScan';
    DSLabelsDriverScan.FieldName := 'DriverScan';
    DSLabelsDriverScan.Size := 1;
  finally
    RFCRecapPnl.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    CurrentRouteGrid.AfterLoadDFMValues;
    WDSReturns.AfterLoadDFMValues;
    DSReturns.AfterLoadDFMValues;
    DSReturnsAutoInc.AfterLoadDFMValues;
    DSReturnsOrderNum.AfterLoadDFMValues;
    DSReturnsProd.AfterLoadDFMValues;
    DSReturnsLineNum.AfterLoadDFMValues;
    DSReturnsQty.AfterLoadDFMValues;
    DSReturnsReasonCode.AfterLoadDFMValues;
    DSReturnsReturningToMV.AfterLoadDFMValues;
    DSReturnsNotes.AfterLoadDFMValues;
    DSReturnsPickTagID.AfterLoadDFMValues;
    DSOrders.AfterLoadDFMValues;
    DSOrdersOrderNum.AfterLoadDFMValues;
    DSOrdersCust.AfterLoadDFMValues;
    DSOrdersTrip.AfterLoadDFMValues;
    DSOrdersStop.AfterLoadDFMValues;
    DSOrdersInvoiceNum.AfterLoadDFMValues;
    DSOrdersShipCust.AfterLoadDFMValues;
    DSOrdersShipName.AfterLoadDFMValues;
    DSOrdersShipContact.AfterLoadDFMValues;
    DSOrdersShipCity.AfterLoadDFMValues;
    DSOrdersShipState.AfterLoadDFMValues;
    DSOrdersShipZip.AfterLoadDFMValues;
    DSOrdersShipPhone.AfterLoadDFMValues;
    DSOrdersShipAdd1.AfterLoadDFMValues;
    DSOrdersShipAdd2.AfterLoadDFMValues;
    DSOrdersPcsDry.AfterLoadDFMValues;
    DSOrdersPcsCooler.AfterLoadDFMValues;
    DSOrdersPcsFreezer.AfterLoadDFMValues;
    DSOrdersPcsTotal.AfterLoadDFMValues;
    DSOrdersPcsWeight.AfterLoadDFMValues;
    DSOrdersPcsCubes.AfterLoadDFMValues;
    DSOrdersPickupPcs.AfterLoadDFMValues;
    DSOrdersWindowArriveTime.AfterLoadDFMValues;
    DSOrdersDeliveryWindow.AfterLoadDFMValues;
    DSOrdersDeliveredFlag.AfterLoadDFMValues;
    DSOrdersMess1.AfterLoadDFMValues;
    DSOrdersMess2.AfterLoadDFMValues;
    DSOrdersMess3.AfterLoadDFMValues;
    DSOrdersMess4.AfterLoadDFMValues;
    DSOrdersMess5.AfterLoadDFMValues;
    DSOrdersStandingMessage.AfterLoadDFMValues;
    WDSOrders.AfterLoadDFMValues;
    DSLabels.AfterLoadDFMValues;
    DSLabelsAssignNum.AfterLoadDFMValues;
    DSLabelsRPNum.AfterLoadDFMValues;
    DSLabelsOrderNum.AfterLoadDFMValues;
    DSLabelsLineNum.AfterLoadDFMValues;
    DSLabelsDate.AfterLoadDFMValues;
    DSLabelsUnit_.AfterLoadDFMValues;
    DSLabelsSize.AfterLoadDFMValues;
    DSLabelsBrand.AfterLoadDFMValues;
    DSLabelsDescription.AfterLoadDFMValues;
    DSLabelsQtyOrd.AfterLoadDFMValues;
    DSLabelsQtyShip.AfterLoadDFMValues;
    DSLabelsCWFlag.AfterLoadDFMValues;
    DSLabelsPalletNum.AfterLoadDFMValues;
    DSLabelsQtyOnHand.AfterLoadDFMValues;
    DSLabelsUnitsPerCase.AfterLoadDFMValues;
    DSLabelsWeight.AfterLoadDFMValues;
    DSLabelsSlot.AfterLoadDFMValues;
    DSLabelsCustNum.AfterLoadDFMValues;
    DSLabelsTruck.AfterLoadDFMValues;
    DSLabelsStop.AfterLoadDFMValues;
    DSLabelsStickerNum.AfterLoadDFMValues;
    DSLabelsServingsPerCase.AfterLoadDFMValues;
    DSLabelsCubes.AfterLoadDFMValues;
    DSLabelsUPC.AfterLoadDFMValues;
    DSLabelsManufNum.AfterLoadDFMValues;
    DSLabelsCubesPerLCD.AfterLoadDFMValues;
    DSLabelsCustName.AfterLoadDFMValues;
    DSLabelsProd.AfterLoadDFMValues;
    DSLabelsNoSubFlag.AfterLoadDFMValues;
    DSLabelsPalletFullFlag.AfterLoadDFMValues;
    DSLabelsWeightPerLCD.AfterLoadDFMValues;
    DSLabelsLabelType.AfterLoadDFMValues;
    DSLabelsSkipFlag.AfterLoadDFMValues;
    DSLabelsEnteredWeight.AfterLoadDFMValues;
    DSLabelsPickStatus.AfterLoadDFMValues;
    DSLabelsIDNum.AfterLoadDFMValues;
    DSLabelsPickBatchNum.AfterLoadDFMValues;
    DSLabelsProdScan.AfterLoadDFMValues;
    DSLabelsErrorStatus.AfterLoadDFMValues;
    DSLabelsErrorStatusPerm.AfterLoadDFMValues;
    DSLabelsUser.AfterLoadDFMValues;
    DSLabelsLargeQty.AfterLoadDFMValues;
    DSLabelsPrintBatchNum.AfterLoadDFMValues;
    DSLabelsShellfishLot.AfterLoadDFMValues;
    DSLabelsHACCPFlag.AfterLoadDFMValues;
    DSLabelsSubItem.AfterLoadDFMValues;
    DSLabelsReturnFlag.AfterLoadDFMValues;
    DSLabelsSubOutPanelScanFlag.AfterLoadDFMValues;
    DSLabelsPickerSubFlag.AfterLoadDFMValues;
    DSLabelsPickTime.AfterLoadDFMValues;
    DSLabelsWeighTime.AfterLoadDFMValues;
    DSLabelsItemSubbed.AfterLoadDFMValues;
    DSLabelsEarlyPickPallet.AfterLoadDFMValues;
    DSLabelsSkipPrint.AfterLoadDFMValues;
    DSLabelsLeftInSlot.AfterLoadDFMValues;
    DSLabelsLeftInSlotPerm.AfterLoadDFMValues;
    DSLabelsDriverScan.AfterLoadDFMValues;
  end;
end;

end.