unit Feedback;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFFeedback = class(TForm)
    FeedbackPnl: TPanel;
    DSFeedback: TXDataWebDataSet;
    DSFeedbackAutoInc: TIntegerField;
    DSFeedbackEntryDate: TDateTimeField;
    DSFeedbackDriverNum: TIntegerField;
    DSFeedbackDriverName: TStringField;
    DSFeedbackRouteNum: TIntegerField;
    DSFeedbackLoadCondition: TIntegerField;
    DSFeedbackLoadDetails: TStringField;
    WebRadioGroup1: TRadioGroup;
    WebLabel1: TLabel;
    WebMemo1: TMemo;
    WebButton1: TButton;
    WebLabel2: TLabel;
    WebMemo2: TMemo;
    DSFeedbackRouteFeedback: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FFeedback: TFFeedback;

implementation

Uses Data,Login;

{$R *.dfm}

procedure TFFeedback.WebButton1Click(Sender: TObject);
begin
  DSFeedback.Append;
  DSFeedbackEntryDate.AsDateTime := Now;
  DSFeedbackDriverNum.AsInteger := Dm.DSLoginNumber.AsInteger;
  DSFeedbackDriverName.AsString := Dm.DSLoginName.AsString;
  if Dm.DSCurrStopOrders.RecordCount > 0 then
    DSFeedbackRouteNum.AsInteger := Dm.DSCurrStopOrdersTrip.AsInteger
  else DSFeedbackRouteNum.AsInteger := 0;
  DSFeedbackLoadCondition.AsInteger := WebRadioGroup1.ItemIndex+1;
  DSFeedbackLoadDetails.AsString := WebMemo1.Lines.Text;
  DSFeedbackRouteFeedback.AsString := WebMemo2.Lines.Text;
  DSFeedback.Post;
  DSFeedback.ApplyUpdates;
  Application.CreateForm(TFLogin, FLogin);
end;

procedure TFFeedback.WebFormResize(Sender: TObject);
begin
  FeedbackPnl.Left := Round((FFeedback.Width / 2) -
    (FeedbackPnl.Width / 2));
  FeedbackPnl.Top := Round((FFeedback.Height / 2) -
    (FeedbackPnl.Height / 2));
  if FeedbackPnl.Top < 0 then FeedbackPnl.Top := 0;
end;

procedure TFFeedback.WebFormShow(Sender: TObject);
begin
  FeedbackPnl.Left := Round((FFeedback.Width / 2) -
    (FeedbackPnl.Width / 2));
  FeedbackPnl.Top := Round((FFeedback.Height / 2) -
    (FeedbackPnl.Height / 2));
  if FeedbackPnl.Top < 0 then FeedbackPnl.Top := 0;
  DSFeedback.QueryString := '$filter=(AutoInc eq 0)';
  DSFeedback.Load;
end;

procedure TFFeedback.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FeedbackPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebRadioGroup1 := TRadioGroup.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebMemo2 := TMemo.Create(Self);
  DSFeedback := TXDataWebDataSet.Create(Self);
  DSFeedbackAutoInc := TIntegerField.Create(Self);
  DSFeedbackEntryDate := TDateTimeField.Create(Self);
  DSFeedbackDriverNum := TIntegerField.Create(Self);
  DSFeedbackDriverName := TStringField.Create(Self);
  DSFeedbackRouteNum := TIntegerField.Create(Self);
  DSFeedbackLoadCondition := TIntegerField.Create(Self);
  DSFeedbackLoadDetails := TStringField.Create(Self);
  DSFeedbackRouteFeedback := TStringField.Create(Self);

  FeedbackPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebRadioGroup1.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebMemo2.BeforeLoadDFMValues;
  DSFeedback.BeforeLoadDFMValues;
  DSFeedbackAutoInc.BeforeLoadDFMValues;
  DSFeedbackEntryDate.BeforeLoadDFMValues;
  DSFeedbackDriverNum.BeforeLoadDFMValues;
  DSFeedbackDriverName.BeforeLoadDFMValues;
  DSFeedbackRouteNum.BeforeLoadDFMValues;
  DSFeedbackLoadCondition.BeforeLoadDFMValues;
  DSFeedbackLoadDetails.BeforeLoadDFMValues;
  DSFeedbackRouteFeedback.BeforeLoadDFMValues;
  try
    Name := 'FFeedback';
    Width := 400;
    Height := 697;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FeedbackPnl.SetParentComponent(Self);
    FeedbackPnl.Name := 'FeedbackPnl';
    FeedbackPnl.Left := 11;
    FeedbackPnl.Top := 11;
    FeedbackPnl.Width := 377;
    FeedbackPnl.Height := 678;
    FeedbackPnl.ElementClassName := 'card';
    FeedbackPnl.ElementBodyClassName := 'card-body';
    WebLabel1.SetParentComponent(FeedbackPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 272;
    WebLabel1.Width := 325;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'If Rated 2 or Below - Please Provide Feedback';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(FeedbackPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 440;
    WebLabel2.Width := 315;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Enter any Routing Feedback/Concerns Below';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebRadioGroup1.SetParentComponent(FeedbackPnl);
    WebRadioGroup1.Name := 'WebRadioGroup1';
    WebRadioGroup1.Left := 16;
    WebRadioGroup1.Top := 16;
    WebRadioGroup1.Width := 345;
    WebRadioGroup1.Height := 225;
    WebRadioGroup1.Caption := 'Rate How Well Your Truck Was Loaded';
    WebRadioGroup1.Columns := 1;
    WebRadioGroup1.Font.Charset := DEFAULT_CHARSET;
    WebRadioGroup1.Font.Color := clWindowText;
    WebRadioGroup1.Font.Height := -16;
    WebRadioGroup1.Font.Name := 'Tahoma';
    WebRadioGroup1.Font.Style := [];
    WebRadioGroup1.ItemIndex := 4;
    WebRadioGroup1.Items.BeginUpdate;
    try
      WebRadioGroup1.Items.Clear;
      WebRadioGroup1.Items.Add('1 - Poor');
      WebRadioGroup1.Items.Add('2 - Below Average');
      WebRadioGroup1.Items.Add('3 - Average');
      WebRadioGroup1.Items.Add('4 - Above Average');
      WebRadioGroup1.Items.Add('5 - Awesome');
    finally
      WebRadioGroup1.Items.EndUpdate;
    end;
    WebRadioGroup1.ParentFont := False;
    WebRadioGroup1.Role := '';
    WebMemo1.SetParentComponent(FeedbackPnl);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 16;
    WebMemo1.Top := 297;
    WebMemo1.Width := 345;
    WebMemo1.Height := 104;
    WebMemo1.AutoSize := False;
    WebMemo1.Font.Charset := DEFAULT_CHARSET;
    WebMemo1.Font.Color := clWindowText;
    WebMemo1.Font.Height := -16;
    WebMemo1.Font.Name := 'Tahoma';
    WebMemo1.Font.Style := [];
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.ParentFont := False;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(FeedbackPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 104;
    WebButton1.Top := 608;
    WebButton1.Width := 169;
    WebButton1.Height := 49;
    WebButton1.Caption := 'Finish Route';
    WebButton1.ChildOrder := 3;
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -16;
    WebButton1.Font.Name := 'Tahoma';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebMemo2.SetParentComponent(FeedbackPnl);
    WebMemo2.Name := 'WebMemo2';
    WebMemo2.Left := 16;
    WebMemo2.Top := 465;
    WebMemo2.Width := 345;
    WebMemo2.Height := 104;
    WebMemo2.AutoSize := False;
    WebMemo2.Font.Charset := DEFAULT_CHARSET;
    WebMemo2.Font.Color := clWindowText;
    WebMemo2.Font.Height := -16;
    WebMemo2.Font.Name := 'Tahoma';
    WebMemo2.Font.Style := [];
    WebMemo2.HeightPercent := 100.000000000000000000;
    WebMemo2.ParentFont := False;
    WebMemo2.SelLength := 0;
    WebMemo2.SelStart := 0;
    WebMemo2.WidthPercent := 100.000000000000000000;
    DSFeedback.SetParentComponent(Self);
    DSFeedback.Name := 'DSFeedback';
    DSFeedback.EntitySetName := 'DriverFeedback';
    DSFeedback.Connection := Dm.ApiConnection;
    DSFeedback.Left := 320;
    DSFeedback.Top := 720;
    DSFeedbackAutoInc.SetParentComponent(DSFeedback);
    DSFeedbackAutoInc.Name := 'DSFeedbackAutoInc';
    DSFeedbackAutoInc.FieldName := 'AutoInc';
    DSFeedbackAutoInc.Required := True;
    DSFeedbackEntryDate.SetParentComponent(DSFeedback);
    DSFeedbackEntryDate.Name := 'DSFeedbackEntryDate';
    DSFeedbackEntryDate.FieldName := 'EntryDate';
    DSFeedbackDriverNum.SetParentComponent(DSFeedback);
    DSFeedbackDriverNum.Name := 'DSFeedbackDriverNum';
    DSFeedbackDriverNum.FieldName := 'DriverNum';
    DSFeedbackDriverName.SetParentComponent(DSFeedback);
    DSFeedbackDriverName.Name := 'DSFeedbackDriverName';
    DSFeedbackDriverName.FieldName := 'DriverName';
    DSFeedbackDriverName.Size := 15;
    DSFeedbackRouteNum.SetParentComponent(DSFeedback);
    DSFeedbackRouteNum.Name := 'DSFeedbackRouteNum';
    DSFeedbackRouteNum.FieldName := 'RouteNum';
    DSFeedbackLoadCondition.SetParentComponent(DSFeedback);
    DSFeedbackLoadCondition.Name := 'DSFeedbackLoadCondition';
    DSFeedbackLoadCondition.FieldName := 'LoadCondition';
    DSFeedbackLoadDetails.SetParentComponent(DSFeedback);
    DSFeedbackLoadDetails.Name := 'DSFeedbackLoadDetails';
    DSFeedbackLoadDetails.FieldName := 'LoadDetails';
    DSFeedbackLoadDetails.Size := 500;
    DSFeedbackRouteFeedback.SetParentComponent(DSFeedback);
    DSFeedbackRouteFeedback.Name := 'DSFeedbackRouteFeedback';
    DSFeedbackRouteFeedback.FieldName := 'RouteFeedback';
    DSFeedbackRouteFeedback.Size := 1000;
  finally
    FeedbackPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebRadioGroup1.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebMemo2.AfterLoadDFMValues;
    DSFeedback.AfterLoadDFMValues;
    DSFeedbackAutoInc.AfterLoadDFMValues;
    DSFeedbackEntryDate.AfterLoadDFMValues;
    DSFeedbackDriverNum.AfterLoadDFMValues;
    DSFeedbackDriverName.AfterLoadDFMValues;
    DSFeedbackRouteNum.AfterLoadDFMValues;
    DSFeedbackLoadCondition.AfterLoadDFMValues;
    DSFeedbackLoadDetails.AfterLoadDFMValues;
    DSFeedbackRouteFeedback.AfterLoadDFMValues;
  end;
end;

end.