unit Data;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, Data.DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection;

type
  TDm = class(TDataModule)
    ApiConnection: TXDataWebConnection;
    DSLogin: TXDataWebDataSet;
    DSLoginNumber: TFloatField;
    DSLoginName: TStringField;
    DSLoginOnlineUser: TStringField;
    DSLoginOnlinePass: TStringField;
    DSCurrStopOrders: TXDataWebDataSet;
    DSCurrStopOrdersOrderNum: TIntegerField;
    DSCurrStopOrdersCust: TIntegerField;
    DSCurrStopOrdersTrip: TIntegerField;
    DSCurrStopOrdersStop: TIntegerField;
    DSCurrStopOrdersDeliveryDay: TIntegerField;
    DSCurrStopOrdersPONum: TStringField;
    DSCurrStopOrdersMess1: TStringField;
    DSCurrStopOrdersMess2: TStringField;
    DSCurrStopOrdersMess3: TStringField;
    DSCurrStopOrdersMess4: TStringField;
    DSCurrStopOrdersMess5: TStringField;
    DSCurrStopOrdersInvoiceTotal: TFloatField;
    DSCurrStopOrdersInvoiceNum: TIntegerField;
    DSCurrStopOrdersDate: TDateTimeField;
    DSCurrStopOrdersTripStop: TIntegerField;
    DSCurrStopOrdersShipCust: TIntegerField;
    DSCurrStopOrdersShipName: TStringField;
    DSCurrStopOrdersShipContact: TStringField;
    DSCurrStopOrdersShipCity: TStringField;
    DSCurrStopOrdersShipState: TStringField;
    DSCurrStopOrdersShipZip: TIntegerField;
    DSCurrStopOrdersShipPhone: TStringField;
    DSCurrStopOrdersShipAdd1: TStringField;
    DSCurrStopOrdersShipAdd2: TStringField;
    DSCurrStopOrdersTermsExplained: TStringField;
    DSCurrStopOrdersSRNum: TIntegerField;
    DSCurrStopOrdersSRName: TStringField;
    DSCurrStopOrdersPcsDry: TIntegerField;
    DSCurrStopOrdersPcsCooler: TIntegerField;
    DSCurrStopOrdersPcsFreezer: TIntegerField;
    DSCurrStopOrdersPcsTotal: TIntegerField;
    DSCurrStopOrdersPcsWeight: TFloatField;
    DSCurrStopOrdersInvoiceTax: TFloatField;
    DSCurrStopOrdersInvoiceSubTotal: TFloatField;
    DSCurrStopOrdersInvoiceDeposit: TFloatField;
    DSCurrStopOrdersInvoiceLines: TIntegerField;
    DSCurrStopOrdersInvoiceCases: TIntegerField;
    DSCurrStopOrdersPcsCubes: TFloatField;
    DSCurrStopOrdersPickupPcs: TIntegerField;
    DSCurrStopOrdersWindowArriveTime: TDateTimeField;
    DSCurrStopOrdersDeliveryWindow: TStringField;
    DSCurrStopOrdersDeliveryDriver: TStringField;
    DSCurrStopOrdersStartLocation: TStringField;
    DSCurrStopOrdersEstArriveTime: TStringField;
    DSCurrStopOrdersActualArriveTime: TDateTimeField;
    DSCurrStopOrdersSignature: TBlobField;
    DSCurrStopOrdersOnlineOrder: TStringField;
    WDSCurrStopOrders: TDataSource;
    DSLoginLastRouteDate: TDateTimeField;
    DSLoginLastRoute: TIntegerField;
    WDSLogin: TDataSource;
    DSCurrStopOrdersStandingMessage: TStringField;
    DSBackhauls: TXDataWebDataSet;
    WDSBackhauls: TDataSource;
    DSBackhaulsOrdrNumTrunc: TIntegerField;
    DSBackhaulsVenNum: TIntegerField;
    DSBackhaulsReleaseNum: TStringField;
    DSBackhaulsAdditionalTemPinfo: TStringField;
    DSBackhaulsNotes: TStringField;
    DSBackhaulsVenLoc: TStringField;
    DSBackhaulsAdd1: TStringField;
    DSBackhaulsAdd2: TStringField;
    DSBackhaulsCity: TStringField;
    DSBackhaulsST: TStringField;
    DSBackhaulsZip: TStringField;
    DSBackhaulsPhone: TStringField;
    DSBackhaulsTtlCs: TIntegerField;
    DSBackhaulsTtlCubes: TFloatField;
    DSBackhaulsTtlPndsGross: TFloatField;
    DSBackhaulsOrdrShipDt: TDateTimeField;
    DSBackhaulsPickupTruck: TIntegerField;
    DSBackhaulsLatitude: TFloatField;
    DSBackhaulsLongitude: TFloatField;
    DSBackhaulsPickupCompleted: TStringField;
    DSBackhaulsAddtionalTempInfo: TStringField;
    DSBackhaulsPickupDateTime: TDateTimeField;
    DSBackhaulsVenAltNm: TStringField;
    DSLoginDoublesFlag: TStringField;
    procedure DSCurrStopOrdersAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Dm: TDm;
  AccessToken: String;
  CurrentForm: String;
  DriverNum: Integer;
  LastRouteDate: TDateTime;
  LastRoute: Integer;

implementation

Uses CurrentStop;

{%CLASSGROUP 'Vcl.Controls.TControl'}
{$R *.dfm}

function MakeRandomString(const ALength: Integer;
  const ACharSequence
  : String =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'): String;
var
  C1, sequence_length: Integer;
begin
  sequence_length := Length(ACharSequence);
  SetLength(result, ALength);

  for C1 := 1 to ALength do
    result[C1] := ACharSequence[Random(sequence_length) + 1];
end;

procedure TDm.DSCurrStopOrdersAfterOpen(DataSet: TDataSet);
begin
  while not Dm.DSCurrStopOrders.Eof do
  begin
    if Dm.DSCurrStopOrdersActualArriveTime.AsString <> '' then
    Begin
      Dm.DSCurrStopOrders.Next;
    End
    else
    begin
      exit;
    end;
  end;
end;

procedure TDm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ApiConnection := TXDataWebConnection.Create(Self);
  DSLogin := TXDataWebDataSet.Create(Self);
  DSLoginNumber := TFloatField.Create(Self);
  DSLoginName := TStringField.Create(Self);
  DSLoginOnlineUser := TStringField.Create(Self);
  DSLoginOnlinePass := TStringField.Create(Self);
  DSLoginLastRouteDate := TDateTimeField.Create(Self);
  DSLoginLastRoute := TIntegerField.Create(Self);
  DSLoginDoublesFlag := TStringField.Create(Self);
  DSCurrStopOrders := TXDataWebDataSet.Create(Self);
  DSCurrStopOrdersOrderNum := TIntegerField.Create(Self);
  DSCurrStopOrdersCust := TIntegerField.Create(Self);
  DSCurrStopOrdersTrip := TIntegerField.Create(Self);
  DSCurrStopOrdersStop := TIntegerField.Create(Self);
  DSCurrStopOrdersDeliveryDay := TIntegerField.Create(Self);
  DSCurrStopOrdersPONum := TStringField.Create(Self);
  DSCurrStopOrdersMess1 := TStringField.Create(Self);
  DSCurrStopOrdersMess2 := TStringField.Create(Self);
  DSCurrStopOrdersMess3 := TStringField.Create(Self);
  DSCurrStopOrdersMess4 := TStringField.Create(Self);
  DSCurrStopOrdersMess5 := TStringField.Create(Self);
  DSCurrStopOrdersInvoiceTotal := TFloatField.Create(Self);
  DSCurrStopOrdersInvoiceNum := TIntegerField.Create(Self);
  DSCurrStopOrdersDate := TDateTimeField.Create(Self);
  DSCurrStopOrdersTripStop := TIntegerField.Create(Self);
  DSCurrStopOrdersShipCust := TIntegerField.Create(Self);
  DSCurrStopOrdersShipName := TStringField.Create(Self);
  DSCurrStopOrdersShipContact := TStringField.Create(Self);
  DSCurrStopOrdersShipCity := TStringField.Create(Self);
  DSCurrStopOrdersShipState := TStringField.Create(Self);
  DSCurrStopOrdersShipZip := TIntegerField.Create(Self);
  DSCurrStopOrdersShipPhone := TStringField.Create(Self);
  DSCurrStopOrdersShipAdd1 := TStringField.Create(Self);
  DSCurrStopOrdersShipAdd2 := TStringField.Create(Self);
  DSCurrStopOrdersTermsExplained := TStringField.Create(Self);
  DSCurrStopOrdersSRNum := TIntegerField.Create(Self);
  DSCurrStopOrdersSRName := TStringField.Create(Self);
  DSCurrStopOrdersPcsDry := TIntegerField.Create(Self);
  DSCurrStopOrdersPcsCooler := TIntegerField.Create(Self);
  DSCurrStopOrdersPcsFreezer := TIntegerField.Create(Self);
  DSCurrStopOrdersPcsTotal := TIntegerField.Create(Self);
  DSCurrStopOrdersPcsWeight := TFloatField.Create(Self);
  DSCurrStopOrdersInvoiceTax := TFloatField.Create(Self);
  DSCurrStopOrdersInvoiceSubTotal := TFloatField.Create(Self);
  DSCurrStopOrdersInvoiceDeposit := TFloatField.Create(Self);
  DSCurrStopOrdersInvoiceLines := TIntegerField.Create(Self);
  DSCurrStopOrdersInvoiceCases := TIntegerField.Create(Self);
  DSCurrStopOrdersPcsCubes := TFloatField.Create(Self);
  DSCurrStopOrdersPickupPcs := TIntegerField.Create(Self);
  DSCurrStopOrdersWindowArriveTime := TDateTimeField.Create(Self);
  DSCurrStopOrdersDeliveryWindow := TStringField.Create(Self);
  DSCurrStopOrdersDeliveryDriver := TStringField.Create(Self);
  DSCurrStopOrdersStartLocation := TStringField.Create(Self);
  DSCurrStopOrdersEstArriveTime := TStringField.Create(Self);
  DSCurrStopOrdersActualArriveTime := TDateTimeField.Create(Self);
  DSCurrStopOrdersSignature := TBlobField.Create(Self);
  DSCurrStopOrdersOnlineOrder := TStringField.Create(Self);
  DSCurrStopOrdersStandingMessage := TStringField.Create(Self);
  WDSCurrStopOrders := TDataSource.Create(Self);
  WDSLogin := TDataSource.Create(Self);
  DSBackhauls := TXDataWebDataSet.Create(Self);
  DSBackhaulsOrdrNumTrunc := TIntegerField.Create(Self);
  DSBackhaulsVenNum := TIntegerField.Create(Self);
  DSBackhaulsReleaseNum := TStringField.Create(Self);
  DSBackhaulsAdditionalTemPinfo := TStringField.Create(Self);
  DSBackhaulsNotes := TStringField.Create(Self);
  DSBackhaulsVenLoc := TStringField.Create(Self);
  DSBackhaulsAdd1 := TStringField.Create(Self);
  DSBackhaulsAdd2 := TStringField.Create(Self);
  DSBackhaulsCity := TStringField.Create(Self);
  DSBackhaulsST := TStringField.Create(Self);
  DSBackhaulsZip := TStringField.Create(Self);
  DSBackhaulsPhone := TStringField.Create(Self);
  DSBackhaulsTtlCs := TIntegerField.Create(Self);
  DSBackhaulsTtlCubes := TFloatField.Create(Self);
  DSBackhaulsTtlPndsGross := TFloatField.Create(Self);
  DSBackhaulsOrdrShipDt := TDateTimeField.Create(Self);
  DSBackhaulsPickupTruck := TIntegerField.Create(Self);
  DSBackhaulsLatitude := TFloatField.Create(Self);
  DSBackhaulsLongitude := TFloatField.Create(Self);
  DSBackhaulsPickupCompleted := TStringField.Create(Self);
  DSBackhaulsAddtionalTempInfo := TStringField.Create(Self);
  DSBackhaulsPickupDateTime := TDateTimeField.Create(Self);
  DSBackhaulsVenAltNm := TStringField.Create(Self);
  WDSBackhauls := TDataSource.Create(Self);

  ApiConnection.BeforeLoadDFMValues;
  DSLogin.BeforeLoadDFMValues;
  DSLoginNumber.BeforeLoadDFMValues;
  DSLoginName.BeforeLoadDFMValues;
  DSLoginOnlineUser.BeforeLoadDFMValues;
  DSLoginOnlinePass.BeforeLoadDFMValues;
  DSLoginLastRouteDate.BeforeLoadDFMValues;
  DSLoginLastRoute.BeforeLoadDFMValues;
  DSLoginDoublesFlag.BeforeLoadDFMValues;
  DSCurrStopOrders.BeforeLoadDFMValues;
  DSCurrStopOrdersOrderNum.BeforeLoadDFMValues;
  DSCurrStopOrdersCust.BeforeLoadDFMValues;
  DSCurrStopOrdersTrip.BeforeLoadDFMValues;
  DSCurrStopOrdersStop.BeforeLoadDFMValues;
  DSCurrStopOrdersDeliveryDay.BeforeLoadDFMValues;
  DSCurrStopOrdersPONum.BeforeLoadDFMValues;
  DSCurrStopOrdersMess1.BeforeLoadDFMValues;
  DSCurrStopOrdersMess2.BeforeLoadDFMValues;
  DSCurrStopOrdersMess3.BeforeLoadDFMValues;
  DSCurrStopOrdersMess4.BeforeLoadDFMValues;
  DSCurrStopOrdersMess5.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceTotal.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceNum.BeforeLoadDFMValues;
  DSCurrStopOrdersDate.BeforeLoadDFMValues;
  DSCurrStopOrdersTripStop.BeforeLoadDFMValues;
  DSCurrStopOrdersShipCust.BeforeLoadDFMValues;
  DSCurrStopOrdersShipName.BeforeLoadDFMValues;
  DSCurrStopOrdersShipContact.BeforeLoadDFMValues;
  DSCurrStopOrdersShipCity.BeforeLoadDFMValues;
  DSCurrStopOrdersShipState.BeforeLoadDFMValues;
  DSCurrStopOrdersShipZip.BeforeLoadDFMValues;
  DSCurrStopOrdersShipPhone.BeforeLoadDFMValues;
  DSCurrStopOrdersShipAdd1.BeforeLoadDFMValues;
  DSCurrStopOrdersShipAdd2.BeforeLoadDFMValues;
  DSCurrStopOrdersTermsExplained.BeforeLoadDFMValues;
  DSCurrStopOrdersSRNum.BeforeLoadDFMValues;
  DSCurrStopOrdersSRName.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsDry.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsCooler.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsFreezer.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsTotal.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsWeight.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceTax.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceSubTotal.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceDeposit.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceLines.BeforeLoadDFMValues;
  DSCurrStopOrdersInvoiceCases.BeforeLoadDFMValues;
  DSCurrStopOrdersPcsCubes.BeforeLoadDFMValues;
  DSCurrStopOrdersPickupPcs.BeforeLoadDFMValues;
  DSCurrStopOrdersWindowArriveTime.BeforeLoadDFMValues;
  DSCurrStopOrdersDeliveryWindow.BeforeLoadDFMValues;
  DSCurrStopOrdersDeliveryDriver.BeforeLoadDFMValues;
  DSCurrStopOrdersStartLocation.BeforeLoadDFMValues;
  DSCurrStopOrdersEstArriveTime.BeforeLoadDFMValues;
  DSCurrStopOrdersActualArriveTime.BeforeLoadDFMValues;
  DSCurrStopOrdersSignature.BeforeLoadDFMValues;
  DSCurrStopOrdersOnlineOrder.BeforeLoadDFMValues;
  DSCurrStopOrdersStandingMessage.BeforeLoadDFMValues;
  WDSCurrStopOrders.BeforeLoadDFMValues;
  WDSLogin.BeforeLoadDFMValues;
  DSBackhauls.BeforeLoadDFMValues;
  DSBackhaulsOrdrNumTrunc.BeforeLoadDFMValues;
  DSBackhaulsVenNum.BeforeLoadDFMValues;
  DSBackhaulsReleaseNum.BeforeLoadDFMValues;
  DSBackhaulsAdditionalTemPinfo.BeforeLoadDFMValues;
  DSBackhaulsNotes.BeforeLoadDFMValues;
  DSBackhaulsVenLoc.BeforeLoadDFMValues;
  DSBackhaulsAdd1.BeforeLoadDFMValues;
  DSBackhaulsAdd2.BeforeLoadDFMValues;
  DSBackhaulsCity.BeforeLoadDFMValues;
  DSBackhaulsST.BeforeLoadDFMValues;
  DSBackhaulsZip.BeforeLoadDFMValues;
  DSBackhaulsPhone.BeforeLoadDFMValues;
  DSBackhaulsTtlCs.BeforeLoadDFMValues;
  DSBackhaulsTtlCubes.BeforeLoadDFMValues;
  DSBackhaulsTtlPndsGross.BeforeLoadDFMValues;
  DSBackhaulsOrdrShipDt.BeforeLoadDFMValues;
  DSBackhaulsPickupTruck.BeforeLoadDFMValues;
  DSBackhaulsLatitude.BeforeLoadDFMValues;
  DSBackhaulsLongitude.BeforeLoadDFMValues;
  DSBackhaulsPickupCompleted.BeforeLoadDFMValues;
  DSBackhaulsAddtionalTempInfo.BeforeLoadDFMValues;
  DSBackhaulsPickupDateTime.BeforeLoadDFMValues;
  DSBackhaulsVenAltNm.BeforeLoadDFMValues;
  WDSBackhauls.BeforeLoadDFMValues;
  try
    Name := 'Dm';
    Height := 302;
    Width := 344;
    ApiConnection.SetParentComponent(Self);
    ApiConnection.Name := 'ApiConnection';
    ApiConnection.URL := 'https://www.mymvf.com:2002/tms/xdata';
    ApiConnection.Connected := True;
    ApiConnection.Left := 39;
    ApiConnection.Top := 16;
    DSLogin.SetParentComponent(Self);
    DSLogin.Name := 'DSLogin';
    DSLogin.EntitySetName := 'deldrivers';
    DSLogin.Connection := ApiConnection;
    DSLogin.Left := 184;
    DSLogin.Top := 120;
    DSLoginNumber.SetParentComponent(DSLogin);
    DSLoginNumber.Name := 'DSLoginNumber';
    DSLoginNumber.FieldName := 'Number';
    DSLoginNumber.Required := True;
    DSLoginName.SetParentComponent(DSLogin);
    DSLoginName.Name := 'DSLoginName';
    DSLoginName.FieldName := 'Name';
    DSLoginName.Size := 30;
    DSLoginOnlineUser.SetParentComponent(DSLogin);
    DSLoginOnlineUser.Name := 'DSLoginOnlineUser';
    DSLoginOnlineUser.FieldName := 'OnlineUser';
    DSLoginOnlineUser.Size := 30;
    DSLoginOnlinePass.SetParentComponent(DSLogin);
    DSLoginOnlinePass.Name := 'DSLoginOnlinePass';
    DSLoginOnlinePass.FieldName := 'OnlinePass';
    DSLoginOnlinePass.Size := 30;
    DSLoginLastRouteDate.SetParentComponent(DSLogin);
    DSLoginLastRouteDate.Name := 'DSLoginLastRouteDate';
    DSLoginLastRouteDate.FieldName := 'LastRouteDate';
    DSLoginLastRoute.SetParentComponent(DSLogin);
    DSLoginLastRoute.Name := 'DSLoginLastRoute';
    DSLoginLastRoute.FieldName := 'LastRoute';
    DSLoginDoublesFlag.SetParentComponent(DSLogin);
    DSLoginDoublesFlag.Name := 'DSLoginDoublesFlag';
    DSLoginDoublesFlag.FieldName := 'DoublesFlag';
    DSLoginDoublesFlag.Size := 1;
    DSCurrStopOrders.SetParentComponent(Self);
    DSCurrStopOrders.Name := 'DSCurrStopOrders';
    DSCurrStopOrders.AfterOpen := DSCurrStopOrdersAfterOpen;
    DSCurrStopOrders.EntitySetName := 'delorders';
    DSCurrStopOrders.Connection := ApiConnection;
    DSCurrStopOrders.Left := 183;
    DSCurrStopOrders.Top := 32;
    DSCurrStopOrdersOrderNum.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersOrderNum.Name := 'DSCurrStopOrdersOrderNum';
    DSCurrStopOrdersOrderNum.FieldName := 'OrderNum';
    DSCurrStopOrdersCust.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersCust.Name := 'DSCurrStopOrdersCust';
    DSCurrStopOrdersCust.FieldName := 'Cust';
    DSCurrStopOrdersTrip.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersTrip.Name := 'DSCurrStopOrdersTrip';
    DSCurrStopOrdersTrip.FieldName := 'Trip';
    DSCurrStopOrdersStop.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersStop.Name := 'DSCurrStopOrdersStop';
    DSCurrStopOrdersStop.FieldName := 'Stop';
    DSCurrStopOrdersDeliveryDay.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersDeliveryDay.Name := 'DSCurrStopOrdersDeliveryDay';
    DSCurrStopOrdersDeliveryDay.FieldName := 'DeliveryDay';
    DSCurrStopOrdersPONum.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPONum.Name := 'DSCurrStopOrdersPONum';
    DSCurrStopOrdersPONum.FieldName := 'PONum';
    DSCurrStopOrdersPONum.Size := 15;
    DSCurrStopOrdersMess1.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersMess1.Name := 'DSCurrStopOrdersMess1';
    DSCurrStopOrdersMess1.FieldName := 'Mess1';
    DSCurrStopOrdersMess1.Size := 50;
    DSCurrStopOrdersMess2.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersMess2.Name := 'DSCurrStopOrdersMess2';
    DSCurrStopOrdersMess2.FieldName := 'Mess2';
    DSCurrStopOrdersMess2.Size := 50;
    DSCurrStopOrdersMess3.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersMess3.Name := 'DSCurrStopOrdersMess3';
    DSCurrStopOrdersMess3.FieldName := 'Mess3';
    DSCurrStopOrdersMess3.Size := 50;
    DSCurrStopOrdersMess4.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersMess4.Name := 'DSCurrStopOrdersMess4';
    DSCurrStopOrdersMess4.FieldName := 'Mess4';
    DSCurrStopOrdersMess4.Size := 50;
    DSCurrStopOrdersMess5.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersMess5.Name := 'DSCurrStopOrdersMess5';
    DSCurrStopOrdersMess5.FieldName := 'Mess5';
    DSCurrStopOrdersMess5.Size := 50;
    DSCurrStopOrdersInvoiceTotal.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceTotal.Name := 'DSCurrStopOrdersInvoiceTotal';
    DSCurrStopOrdersInvoiceTotal.FieldName := 'InvoiceTotal';
    DSCurrStopOrdersInvoiceTotal.DisplayFormat := '0.00';
    DSCurrStopOrdersInvoiceNum.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceNum.Name := 'DSCurrStopOrdersInvoiceNum';
    DSCurrStopOrdersInvoiceNum.FieldName := 'InvoiceNum';
    DSCurrStopOrdersDate.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersDate.Name := 'DSCurrStopOrdersDate';
    DSCurrStopOrdersDate.FieldName := 'Date';
    DSCurrStopOrdersTripStop.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersTripStop.Name := 'DSCurrStopOrdersTripStop';
    DSCurrStopOrdersTripStop.FieldName := 'TripStop';
    DSCurrStopOrdersShipCust.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipCust.Name := 'DSCurrStopOrdersShipCust';
    DSCurrStopOrdersShipCust.FieldName := 'ShipCust';
    DSCurrStopOrdersShipName.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipName.Name := 'DSCurrStopOrdersShipName';
    DSCurrStopOrdersShipName.FieldName := 'ShipName';
    DSCurrStopOrdersShipName.Size := 25;
    DSCurrStopOrdersShipContact.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipContact.Name := 'DSCurrStopOrdersShipContact';
    DSCurrStopOrdersShipContact.FieldName := 'ShipContact';
    DSCurrStopOrdersShipContact.Size := 25;
    DSCurrStopOrdersShipCity.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipCity.Name := 'DSCurrStopOrdersShipCity';
    DSCurrStopOrdersShipCity.FieldName := 'ShipCity';
    DSCurrStopOrdersShipCity.Size := 25;
    DSCurrStopOrdersShipState.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipState.Name := 'DSCurrStopOrdersShipState';
    DSCurrStopOrdersShipState.FieldName := 'ShipState';
    DSCurrStopOrdersShipState.Size := 2;
    DSCurrStopOrdersShipZip.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipZip.Name := 'DSCurrStopOrdersShipZip';
    DSCurrStopOrdersShipZip.FieldName := 'ShipZip';
    DSCurrStopOrdersShipPhone.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipPhone.Name := 'DSCurrStopOrdersShipPhone';
    DSCurrStopOrdersShipPhone.FieldName := 'ShipPhone';
    DSCurrStopOrdersShipPhone.Size := 15;
    DSCurrStopOrdersShipAdd1.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipAdd1.Name := 'DSCurrStopOrdersShipAdd1';
    DSCurrStopOrdersShipAdd1.FieldName := 'ShipAdd1';
    DSCurrStopOrdersShipAdd1.Size := 25;
    DSCurrStopOrdersShipAdd2.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersShipAdd2.Name := 'DSCurrStopOrdersShipAdd2';
    DSCurrStopOrdersShipAdd2.FieldName := 'ShipAdd2';
    DSCurrStopOrdersShipAdd2.Size := 25;
    DSCurrStopOrdersTermsExplained.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersTermsExplained.Name := 'DSCurrStopOrdersTermsExplained';
    DSCurrStopOrdersTermsExplained.FieldName := 'TermsExplained';
    DSCurrStopOrdersTermsExplained.Size := 25;
    DSCurrStopOrdersSRNum.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersSRNum.Name := 'DSCurrStopOrdersSRNum';
    DSCurrStopOrdersSRNum.FieldName := 'SRNum';
    DSCurrStopOrdersSRName.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersSRName.Name := 'DSCurrStopOrdersSRName';
    DSCurrStopOrdersSRName.FieldName := 'SRName';
    DSCurrStopOrdersSRName.Size := 50;
    DSCurrStopOrdersPcsDry.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsDry.Name := 'DSCurrStopOrdersPcsDry';
    DSCurrStopOrdersPcsDry.FieldName := 'PcsDry';
    DSCurrStopOrdersPcsCooler.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsCooler.Name := 'DSCurrStopOrdersPcsCooler';
    DSCurrStopOrdersPcsCooler.FieldName := 'PcsCooler';
    DSCurrStopOrdersPcsFreezer.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsFreezer.Name := 'DSCurrStopOrdersPcsFreezer';
    DSCurrStopOrdersPcsFreezer.FieldName := 'PcsFreezer';
    DSCurrStopOrdersPcsTotal.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsTotal.Name := 'DSCurrStopOrdersPcsTotal';
    DSCurrStopOrdersPcsTotal.FieldName := 'PcsTotal';
    DSCurrStopOrdersPcsWeight.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsWeight.Name := 'DSCurrStopOrdersPcsWeight';
    DSCurrStopOrdersPcsWeight.FieldName := 'PcsWeight';
    DSCurrStopOrdersInvoiceTax.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceTax.Name := 'DSCurrStopOrdersInvoiceTax';
    DSCurrStopOrdersInvoiceTax.FieldName := 'InvoiceTax';
    DSCurrStopOrdersInvoiceSubTotal.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceSubTotal.Name := 'DSCurrStopOrdersInvoiceSubTotal';
    DSCurrStopOrdersInvoiceSubTotal.FieldName := 'InvoiceSubTotal';
    DSCurrStopOrdersInvoiceDeposit.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceDeposit.Name := 'DSCurrStopOrdersInvoiceDeposit';
    DSCurrStopOrdersInvoiceDeposit.FieldName := 'InvoiceDeposit';
    DSCurrStopOrdersInvoiceLines.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceLines.Name := 'DSCurrStopOrdersInvoiceLines';
    DSCurrStopOrdersInvoiceLines.FieldName := 'InvoiceLines';
    DSCurrStopOrdersInvoiceCases.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersInvoiceCases.Name := 'DSCurrStopOrdersInvoiceCases';
    DSCurrStopOrdersInvoiceCases.FieldName := 'InvoiceCases';
    DSCurrStopOrdersPcsCubes.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPcsCubes.Name := 'DSCurrStopOrdersPcsCubes';
    DSCurrStopOrdersPcsCubes.FieldName := 'PcsCubes';
    DSCurrStopOrdersPickupPcs.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersPickupPcs.Name := 'DSCurrStopOrdersPickupPcs';
    DSCurrStopOrdersPickupPcs.FieldName := 'PickupPcs';
    DSCurrStopOrdersWindowArriveTime.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersWindowArriveTime.Name := 'DSCurrStopOrdersWindowArriveTime';
    DSCurrStopOrdersWindowArriveTime.FieldName := 'WindowArriveTime';
    DSCurrStopOrdersDeliveryWindow.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersDeliveryWindow.Name := 'DSCurrStopOrdersDeliveryWindow';
    DSCurrStopOrdersDeliveryWindow.FieldName := 'DeliveryWindow';
    DSCurrStopOrdersDeliveryWindow.Size := 50;
    DSCurrStopOrdersDeliveryDriver.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersDeliveryDriver.Name := 'DSCurrStopOrdersDeliveryDriver';
    DSCurrStopOrdersDeliveryDriver.FieldName := 'DeliveryDriver';
    DSCurrStopOrdersStartLocation.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersStartLocation.Name := 'DSCurrStopOrdersStartLocation';
    DSCurrStopOrdersStartLocation.FieldName := 'StartLocation';
    DSCurrStopOrdersEstArriveTime.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersEstArriveTime.Name := 'DSCurrStopOrdersEstArriveTime';
    DSCurrStopOrdersEstArriveTime.FieldName := 'EstArriveTime';
    DSCurrStopOrdersEstArriveTime.Size := 7;
    DSCurrStopOrdersActualArriveTime.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersActualArriveTime.Name := 'DSCurrStopOrdersActualArriveTime';
    DSCurrStopOrdersActualArriveTime.FieldName := 'ActualArriveTime';
    DSCurrStopOrdersSignature.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersSignature.Name := 'DSCurrStopOrdersSignature';
    DSCurrStopOrdersSignature.FieldName := 'Signature';
    DSCurrStopOrdersOnlineOrder.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersOnlineOrder.Name := 'DSCurrStopOrdersOnlineOrder';
    DSCurrStopOrdersOnlineOrder.FieldName := 'OnlineOrder';
    DSCurrStopOrdersOnlineOrder.Size := 1;
    DSCurrStopOrdersStandingMessage.SetParentComponent(DSCurrStopOrders);
    DSCurrStopOrdersStandingMessage.Name := 'DSCurrStopOrdersStandingMessage';
    DSCurrStopOrdersStandingMessage.FieldName := 'StandingMessage';
    DSCurrStopOrdersStandingMessage.Size := 100;
    WDSCurrStopOrders.SetParentComponent(Self);
    WDSCurrStopOrders.Name := 'WDSCurrStopOrders';
    WDSCurrStopOrders.DataSet := DSCurrStopOrders;
    WDSCurrStopOrders.Left := 240;
    WDSCurrStopOrders.Top := 48;
    WDSLogin.SetParentComponent(Self);
    WDSLogin.Name := 'WDSLogin';
    WDSLogin.DataSet := DSLogin;
    WDSLogin.Left := 232;
    WDSLogin.Top := 128;
    DSBackhauls.SetParentComponent(Self);
    DSBackhauls.Name := 'DSBackhauls';
    DSBackhauls.EntitySetName := 'Backhauls';
    DSBackhauls.Connection := ApiConnection;
    DSBackhauls.Left := 176;
    DSBackhauls.Top := 184;
    DSBackhaulsOrdrNumTrunc.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrNumTrunc.Name := 'DSBackhaulsOrdrNumTrunc';
    DSBackhaulsOrdrNumTrunc.FieldName := 'OrdrNumTrunc';
    DSBackhaulsVenNum.SetParentComponent(DSBackhauls);
    DSBackhaulsVenNum.Name := 'DSBackhaulsVenNum';
    DSBackhaulsVenNum.FieldName := 'VenNum';
    DSBackhaulsReleaseNum.SetParentComponent(DSBackhauls);
    DSBackhaulsReleaseNum.Name := 'DSBackhaulsReleaseNum';
    DSBackhaulsReleaseNum.FieldName := 'ReleaseNum';
    DSBackhaulsReleaseNum.Size := 24;
    DSBackhaulsAdditionalTemPinfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAdditionalTemPinfo.Name := 'DSBackhaulsAdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.FieldName := 'AdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.Size := 120;
    DSBackhaulsNotes.SetParentComponent(DSBackhauls);
    DSBackhaulsNotes.Name := 'DSBackhaulsNotes';
    DSBackhaulsNotes.FieldName := 'Notes';
    DSBackhaulsNotes.Size := 120;
    DSBackhaulsVenLoc.SetParentComponent(DSBackhauls);
    DSBackhaulsVenLoc.Name := 'DSBackhaulsVenLoc';
    DSBackhaulsVenLoc.FieldName := 'VenLoc';
    DSBackhaulsVenLoc.Size := 24;
    DSBackhaulsAdd1.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd1.Name := 'DSBackhaulsAdd1';
    DSBackhaulsAdd1.FieldName := 'Add1';
    DSBackhaulsAdd2.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd2.Name := 'DSBackhaulsAdd2';
    DSBackhaulsAdd2.FieldName := 'Add2';
    DSBackhaulsCity.SetParentComponent(DSBackhauls);
    DSBackhaulsCity.Name := 'DSBackhaulsCity';
    DSBackhaulsCity.FieldName := 'City';
    DSBackhaulsCity.Size := 16;
    DSBackhaulsST.SetParentComponent(DSBackhauls);
    DSBackhaulsST.Name := 'DSBackhaulsST';
    DSBackhaulsST.FieldName := 'ST';
    DSBackhaulsST.Size := 2;
    DSBackhaulsZip.SetParentComponent(DSBackhauls);
    DSBackhaulsZip.Name := 'DSBackhaulsZip';
    DSBackhaulsZip.FieldName := 'Zip';
    DSBackhaulsZip.Size := 10;
    DSBackhaulsPhone.SetParentComponent(DSBackhauls);
    DSBackhaulsPhone.Name := 'DSBackhaulsPhone';
    DSBackhaulsPhone.FieldName := 'Phone';
    DSBackhaulsPhone.Size := 14;
    DSBackhaulsTtlCs.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCs.Name := 'DSBackhaulsTtlCs';
    DSBackhaulsTtlCs.FieldName := 'TtlCs';
    DSBackhaulsTtlCubes.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCubes.Name := 'DSBackhaulsTtlCubes';
    DSBackhaulsTtlCubes.FieldName := 'TtlCubes';
    DSBackhaulsTtlPndsGross.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlPndsGross.Name := 'DSBackhaulsTtlPndsGross';
    DSBackhaulsTtlPndsGross.FieldName := 'TtlPndsGross';
    DSBackhaulsOrdrShipDt.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrShipDt.Name := 'DSBackhaulsOrdrShipDt';
    DSBackhaulsOrdrShipDt.FieldName := 'OrdrShipDt';
    DSBackhaulsPickupTruck.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupTruck.Name := 'DSBackhaulsPickupTruck';
    DSBackhaulsPickupTruck.FieldName := 'PickupTruck';
    DSBackhaulsLatitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLatitude.Name := 'DSBackhaulsLatitude';
    DSBackhaulsLatitude.FieldName := 'Latitude';
    DSBackhaulsLongitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLongitude.Name := 'DSBackhaulsLongitude';
    DSBackhaulsLongitude.FieldName := 'Longitude';
    DSBackhaulsPickupCompleted.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupCompleted.Name := 'DSBackhaulsPickupCompleted';
    DSBackhaulsPickupCompleted.FieldName := 'PickupCompleted';
    DSBackhaulsPickupCompleted.Size := 1;
    DSBackhaulsAddtionalTempInfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAddtionalTempInfo.Name := 'DSBackhaulsAddtionalTempInfo';
    DSBackhaulsAddtionalTempInfo.FieldName := 'AddtionalTempInfo';
    DSBackhaulsAddtionalTempInfo.Size := 120;
    DSBackhaulsPickupDateTime.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupDateTime.Name := 'DSBackhaulsPickupDateTime';
    DSBackhaulsPickupDateTime.FieldName := 'PickupDateTime';
    DSBackhaulsVenAltNm.SetParentComponent(DSBackhauls);
    DSBackhaulsVenAltNm.Name := 'DSBackhaulsVenAltNm';
    DSBackhaulsVenAltNm.FieldName := 'VenAltNm';
    DSBackhaulsVenAltNm.Size := 30;
    WDSBackhauls.SetParentComponent(Self);
    WDSBackhauls.Name := 'WDSBackhauls';
    WDSBackhauls.DataSet := DSBackhauls;
    WDSBackhauls.Left := 232;
    WDSBackhauls.Top := 208;
  finally
    ApiConnection.AfterLoadDFMValues;
    DSLogin.AfterLoadDFMValues;
    DSLoginNumber.AfterLoadDFMValues;
    DSLoginName.AfterLoadDFMValues;
    DSLoginOnlineUser.AfterLoadDFMValues;
    DSLoginOnlinePass.AfterLoadDFMValues;
    DSLoginLastRouteDate.AfterLoadDFMValues;
    DSLoginLastRoute.AfterLoadDFMValues;
    DSLoginDoublesFlag.AfterLoadDFMValues;
    DSCurrStopOrders.AfterLoadDFMValues;
    DSCurrStopOrdersOrderNum.AfterLoadDFMValues;
    DSCurrStopOrdersCust.AfterLoadDFMValues;
    DSCurrStopOrdersTrip.AfterLoadDFMValues;
    DSCurrStopOrdersStop.AfterLoadDFMValues;
    DSCurrStopOrdersDeliveryDay.AfterLoadDFMValues;
    DSCurrStopOrdersPONum.AfterLoadDFMValues;
    DSCurrStopOrdersMess1.AfterLoadDFMValues;
    DSCurrStopOrdersMess2.AfterLoadDFMValues;
    DSCurrStopOrdersMess3.AfterLoadDFMValues;
    DSCurrStopOrdersMess4.AfterLoadDFMValues;
    DSCurrStopOrdersMess5.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceTotal.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceNum.AfterLoadDFMValues;
    DSCurrStopOrdersDate.AfterLoadDFMValues;
    DSCurrStopOrdersTripStop.AfterLoadDFMValues;
    DSCurrStopOrdersShipCust.AfterLoadDFMValues;
    DSCurrStopOrdersShipName.AfterLoadDFMValues;
    DSCurrStopOrdersShipContact.AfterLoadDFMValues;
    DSCurrStopOrdersShipCity.AfterLoadDFMValues;
    DSCurrStopOrdersShipState.AfterLoadDFMValues;
    DSCurrStopOrdersShipZip.AfterLoadDFMValues;
    DSCurrStopOrdersShipPhone.AfterLoadDFMValues;
    DSCurrStopOrdersShipAdd1.AfterLoadDFMValues;
    DSCurrStopOrdersShipAdd2.AfterLoadDFMValues;
    DSCurrStopOrdersTermsExplained.AfterLoadDFMValues;
    DSCurrStopOrdersSRNum.AfterLoadDFMValues;
    DSCurrStopOrdersSRName.AfterLoadDFMValues;
    DSCurrStopOrdersPcsDry.AfterLoadDFMValues;
    DSCurrStopOrdersPcsCooler.AfterLoadDFMValues;
    DSCurrStopOrdersPcsFreezer.AfterLoadDFMValues;
    DSCurrStopOrdersPcsTotal.AfterLoadDFMValues;
    DSCurrStopOrdersPcsWeight.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceTax.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceSubTotal.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceDeposit.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceLines.AfterLoadDFMValues;
    DSCurrStopOrdersInvoiceCases.AfterLoadDFMValues;
    DSCurrStopOrdersPcsCubes.AfterLoadDFMValues;
    DSCurrStopOrdersPickupPcs.AfterLoadDFMValues;
    DSCurrStopOrdersWindowArriveTime.AfterLoadDFMValues;
    DSCurrStopOrdersDeliveryWindow.AfterLoadDFMValues;
    DSCurrStopOrdersDeliveryDriver.AfterLoadDFMValues;
    DSCurrStopOrdersStartLocation.AfterLoadDFMValues;
    DSCurrStopOrdersEstArriveTime.AfterLoadDFMValues;
    DSCurrStopOrdersActualArriveTime.AfterLoadDFMValues;
    DSCurrStopOrdersSignature.AfterLoadDFMValues;
    DSCurrStopOrdersOnlineOrder.AfterLoadDFMValues;
    DSCurrStopOrdersStandingMessage.AfterLoadDFMValues;
    WDSCurrStopOrders.AfterLoadDFMValues;
    WDSLogin.AfterLoadDFMValues;
    DSBackhauls.AfterLoadDFMValues;
    DSBackhaulsOrdrNumTrunc.AfterLoadDFMValues;
    DSBackhaulsVenNum.AfterLoadDFMValues;
    DSBackhaulsReleaseNum.AfterLoadDFMValues;
    DSBackhaulsAdditionalTemPinfo.AfterLoadDFMValues;
    DSBackhaulsNotes.AfterLoadDFMValues;
    DSBackhaulsVenLoc.AfterLoadDFMValues;
    DSBackhaulsAdd1.AfterLoadDFMValues;
    DSBackhaulsAdd2.AfterLoadDFMValues;
    DSBackhaulsCity.AfterLoadDFMValues;
    DSBackhaulsST.AfterLoadDFMValues;
    DSBackhaulsZip.AfterLoadDFMValues;
    DSBackhaulsPhone.AfterLoadDFMValues;
    DSBackhaulsTtlCs.AfterLoadDFMValues;
    DSBackhaulsTtlCubes.AfterLoadDFMValues;
    DSBackhaulsTtlPndsGross.AfterLoadDFMValues;
    DSBackhaulsOrdrShipDt.AfterLoadDFMValues;
    DSBackhaulsPickupTruck.AfterLoadDFMValues;
    DSBackhaulsLatitude.AfterLoadDFMValues;
    DSBackhaulsLongitude.AfterLoadDFMValues;
    DSBackhaulsPickupCompleted.AfterLoadDFMValues;
    DSBackhaulsAddtionalTempInfo.AfterLoadDFMValues;
    DSBackhaulsPickupDateTime.AfterLoadDFMValues;
    DSBackhaulsVenAltNm.AfterLoadDFMValues;
    WDSBackhauls.AfterLoadDFMValues;
  end;
end;

end.
