unit Accident;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TFAccident = class(TForm)
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    EditTruck: TEdit;
    EditTrailer1: TEdit;
    EditTrailer2: TEdit;
    WebLabel5: TLabel;
    EditMVDriverName: TEdit;
    WebLabel6: TLabel;
    EditMVDriverCell: TEdit;
    WebLabel7: TLabel;
    EditMVCustName: TEdit;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    EditMVCustNum: TEdit;
    AccPnl: TScrollBox;
    WebLabel12: TLabel;
    EditMVCustCity: TEdit;
    WebLabel13: TLabel;
    EditMVCustContact: TEdit;
    WebLabel14: TLabel;
    EditMVCustPhone: TEdit;
    WebLabel15: TLabel;
    CBSuperYes: TCheckBox;
    WebLabel16: TLabel;
    EditSuperName: TEdit;
    WebLabel17: TLabel;
    EditPassName: TEdit;
    WebLabel18: TLabel;
    CBPassYes: TCheckBox;
    WebLabel19: TLabel;
    WebLabel20: TLabel;
    EditPedName: TEdit;
    CBPedInvolvedYes: TCheckBox;
    WebLabel21: TLabel;
    WebLabel22: TLabel;
    WebLabel23: TLabel;
    WebLabel24: TLabel;
    EditOVOwner: TEdit;
    CBOthVehYes: TCheckBox;
    WebLabel25: TLabel;
    EditOVOwnerAddress: TEdit;
    WebLabel26: TLabel;
    EditOVDriver: TEdit;
    WebLabel27: TLabel;
    EditOVOwnerCity: TEdit;
    WebLabel28: TLabel;
    EditOVOwnerState: TEdit;
    WebLabel29: TLabel;
    EditOVOwnerZip: TEdit;
    WebLabel30: TLabel;
    EditOVDriverAddress: TEdit;
    WebLabel31: TLabel;
    EditOVDriverCity: TEdit;
    WebLabel32: TLabel;
    EditOVDriverState: TEdit;
    WebLabel33: TLabel;
    EditOVDriverZip: TEdit;
    WebLabel34: TLabel;
    EditOVDriverPhone: TEdit;
    WebLabel35: TLabel;
    EditOVDriverRelation: TEdit;
    WebLabel36: TLabel;
    EditOVYear: TEdit;
    WebLabel37: TLabel;
    EditOVMake: TEdit;
    WebLabel38: TLabel;
    EditOVModel: TEdit;
    WebLabel39: TLabel;
    EditOVLicPlate: TEdit;
    WebLabel40: TLabel;
    EditOVLicPlateState: TEdit;
    WebLabel41: TLabel;
    EditOVVin: TEdit;
    WebLabel42: TLabel;
    WebLabel43: TLabel;
    EditOVInsuranceName: TEdit;
    WebLabel44: TLabel;
    EditOVInsuranceClaim: TEdit;
    WebLabel45: TLabel;
    WebLabel46: TLabel;
    EditWitnessName: TEdit;
    WebLabel48: TLabel;
    EditWitnessAddress: TEdit;
    WebLabel49: TLabel;
    EditWitnessPhone: TEdit;
    WebLabel53: TLabel;
    WebLabel54: TLabel;
    CBWitPassYes: TCheckBox;
    MemoDescription: TMemo;
    MemoInjuries: TMemo;
    MemoDamages: TMemo;
    WebButton2: TButton;
    CBOwnDrv: TCheckBox;
    WebLabel47: TLabel;
    EditOVOwnerPhone: TEdit;
    DSAccidentHeader: TXDataWebDataSet;
    DSAccidentHeaderAutoInc: TIntegerField;
    DSAccidentHeaderTruck: TIntegerField;
    DSAccidentHeaderTrailer1: TIntegerField;
    DSAccidentHeaderTrailer2: TIntegerField;
    DSAccidentHeaderDamages: TStringField;
    DSAccidentHeaderDriver: TStringField;
    DSAccidentHeaderCellNumber: TStringField;
    DSAccidentHeaderMVCustName: TStringField;
    DSAccidentHeaderMVCustNum: TStringField;
    DSAccidentHeaderMVCustCity: TStringField;
    DSAccidentHeaderMVCustContact: TStringField;
    DSAccidentHeaderMVCustPhone: TStringField;
    DSAccidentHeaderPicturesAvailable: TStringField;
    DSAccidentHeaderNotifySupervisor: TStringField;
    DSAccidentHeaderSupervisorName: TStringField;
    DSAccidentHeaderPassengers: TStringField;
    DSAccidentHeaderPedestrians: TStringField;
    DSAccidentHeaderInjuries: TStringField;
    DSAccidentHeaderOVOwner: TStringField;
    DSAccidentHeaderOVAddress: TStringField;
    DSAccidentHeaderOVCity: TStringField;
    DSAccidentHeaderOVState: TStringField;
    DSAccidentHeaderOVPhone: TStringField;
    DSAccidentHeaderOVDriver: TStringField;
    DSAccidentHeaderOVDriverAddress: TStringField;
    DSAccidentHeaderOVDriverCity: TStringField;
    DSAccidentHeaderOVDriverState: TStringField;
    DSAccidentHeaderOVDriverRelation: TStringField;
    DSAccidentHeaderOVInsurance: TStringField;
    DSAccidentHeaderOVClaimNum: TStringField;
    DSAccidentHeaderOVMake: TStringField;
    DSAccidentHeaderOVModel: TStringField;
    DSAccidentHeaderOVYear: TStringField;
    DSAccidentHeaderOVLicensePlate: TStringField;
    DSAccidentHeaderOVLicenseState: TStringField;
    DSAccidentHeaderOVVin: TStringField;
    DSAccidentHeaderOVDamages: TStringField;
    DSAccidentHeaderWitnessName: TStringField;
    DSAccidentHeaderWitnessAddress: TStringField;
    DSAccidentHeaderWitnessPhone: TStringField;
    DSAccidentHeaderWitnessPassenger: TStringField;
    DSAccidentPhotos: TXDataWebDataSet;
    DSAccidentHeaderPassengerName: TStringField;
    DSAccidentHeaderPedestrianName: TStringField;
    DSAccidentDetail: TXDataWebDataSet;
    DSAccidentDetailAutoInc: TIntegerField;
    DSAccidentDetailAccidentNum: TIntegerField;
    DSAccidentDetailAccidentDate: TStringField;
    DSAccidentDetailAccidentTime: TStringField;
    DSAccidentDetailLocation: TStringField;
    DSAccidentDetailIntersection: TStringField;
    DSAccidentDetailCity: TStringField;
    DSAccidentDetailCounty: TStringField;
    DSAccidentDetailState: TStringField;
    DSAccidentDetailPoliceInvestigation: TStringField;
    DSAccidentDetailIncidentNum: TStringField;
    DSAccidentDetailDepartmentName: TStringField;
    DSAccidentDetailCitations: TStringField;
    DSAccidentDetailDirectionTravelling: TStringField;
    DSAccidentDetailOVDirection: TStringField;
    DSAccidentDetailSpeed: TStringField;
    DSAccidentDetailOVSpeed: TStringField;
    DSAccidentDetailSpeedLimit: TStringField;
    DSAccidentDetailTrafficControls: TStringField;
    DSAccidentDetailOVTrafficControls: TStringField;
    DSAccidentDetailHeadlights: TStringField;
    DSAccidentDetailOVHeadlights: TStringField;
    DSAccidentDetailRoadCondition: TStringField;
    DSAccidentDetailWeatherCondition: TStringField;
    DSAccidentDetailAccidentResponsibility: TStringField;
    DSAccidentDetailAccidentResponsibilityDetails: TStringField;
    DSAccidentDetailAccidentDescription: TStringField;
    DSAccidentDetailAccidentDiagram: TStringField;
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure CBOwnDrvClick(Sender: TObject);
    procedure DSAccidentHeaderAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FAccident: TFAccident;
  AccidentNum:Integer;

implementation

Uses Data, AccidentDetail;

{$R *.dfm}

procedure TFAccident.CBOwnDrvClick(Sender: TObject);
begin
  if CBOwnDrv.Checked = True then
  begin
    EditOVDriver.Text := EditOVOwner.Text;
    EditOVDriverAddress.Text := EditOVOwnerAddress.Text;
    EditOVDriverCity.Text := EditOVOwnerCity.Text;
    EditOVDriverState.Text := EditOVOwnerState.Text;
    EditOVDriverZip.Text := EditOVOwnerZip.Text;
    EditOVDriverPhone.Text := EditOVOwnerPhone.Text;
  end
  else
  begin
    EditOVDriver.Text := '';
    EditOVDriverAddress.Text := '';
    EditOVDriverCity.Text := '';
    EditOVDriverState.Text := '';
    EditOVDriverZip.Text := '';
    EditOVDriverPhone.Text := '';
  end;
end;

procedure TFAccident.DSAccidentHeaderAfterOpen(DataSet: TDataSet);
begin
  DSAccidentHeader.Last;
  AccidentNum := DSAccidentHeaderAutoInc.AsInteger+1;
  DSAccidentDetail.QueryString := '$filter=(AccidentNum eq ' +
    IntToStr(AccidentNum) + ')';
  DSAccidentDetail.Load;
end;

procedure TFAccident.WebButton2Click(Sender: TObject);
begin
  if EditTruck.Text = '' then
  begin
    ShowMessage('Must Enter Truck Number');
    Exit;
  end;
  if EditTrailer1.Text = '' then
  begin
    ShowMessage('Must Enter Trailer Number');
    Exit;
  end;
  if MemoDescription.Text = '' then
  begin
    ShowMessage('Must Enter Description');
    Exit;
  end;
  if EditMVDriverName.Text = '' then
  begin
    ShowMessage('Must Enter MV Driver Name');
    Exit;
  end;
  if CBOthVehYes.Checked = True then
  begin
    if EditOVOwner.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner Name');
      Exit;
    end;
    if EditOVOwnerAddress.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner Address');
      Exit;
    end;
    if EditOVOwnerCity.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner City');
      Exit;
    end;
    if EditOVOwnerState.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner State');
      Exit;
    end;
    if EditOVOwnerZip.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner Zipcode');
      Exit;
    end;
    if EditOVOwnerPhone.Text = '' then
    begin
      ShowMessage('Must Enter Other Owner Phone');
      Exit;
    end;
    if EditOVDriver.Text = '' then
    begin
      ShowMessage('Must Enter Other Driver Name');
      Exit;
    end;
    if EditOVDriverAddress.Text = '' then
    begin
      ShowMessage('Must Enter Other Driver Address');
      Exit;
    end;
    if EditOVDriverCity.Text = '' then
    begin
      ShowMessage('Must Enter Other Driver City');
      Exit;
    end;
    if EditOVDriverState.Text = '' then
    begin
      ShowMessage('Must Enter Other Driver State');
      Exit;
    end;
    if EditOVDriverZip.Text = '' then
    begin
      ShowMessage('Must Enter Other Driver Zipcode');
      Exit;
    end;
    if EditOVMake.Text = '' then
    begin
      ShowMessage('Must Enter Other Vehicle Make');
      Exit;
    end;
    if EditOVModel.Text = '' then
    begin
      ShowMessage('Must Enter Other Vehicle Model');
      Exit;
    end;
  end;
  DSAccidentHeader.Append;
  DSAccidentHeaderTruck.AsString := EditTruck.Text;
  DSAccidentHeaderTrailer1.AsString := EditTrailer1.Text;
  DSAccidentHeaderTrailer2.AsString := EditTrailer2.Text;
  DSAccidentHeaderDamages.AsString := MemoDamages.Lines.Text;
  DSAccidentHeaderDriver.AsString := EditMVDriverName.Text;
  DSAccidentHeaderCellNumber.AsString := EditMVDriverCell.Text;
  DSAccidentHeaderMVCustName.AsString := EditMVCustName.Text;
  DSAccidentHeaderMVCustNum.AsString := EditMVCustNum.Text;
  DSAccidentHeaderMVCustCity.AsString := EditMVCustCity.Text;
  DSAccidentHeaderMVCustContact.AsString := EditMVCustContact.Text;
  DSAccidentHeaderMVCustPhone.AsString := EditMVCustPhone.Text;
  if CBSuperYes.Checked = True then
    DSAccidentHeaderNotifySupervisor.AsString := 'Y'
  else
    DSAccidentHeaderNotifySupervisor.AsString := 'N';
  DSAccidentHeaderSupervisorName.AsString := EditSuperName.Text;
  if CBPassYes.Checked = True then
    DSAccidentHeaderPassengers.AsString := 'Y'
  else
    DSAccidentHeaderPassengers.AsString := 'N';
  DSAccidentHeaderPassengerName.AsString := EditPassName.Text;
  if CBPedInvolvedYes.Checked = True then
    DSAccidentHeaderPedestrians.AsString := 'Y'
  else
    DSAccidentHeaderPedestrians.AsString := 'N';
  DSAccidentHeaderPedestrianName.AsString := EditPedName.Text;
  DSAccidentHeaderInjuries.AsString := MemoInjuries.Lines.Text;
  DSAccidentHeaderOVOwner.AsString := EditOVOwner.Text;
  DSAccidentHeaderOVAddress.AsString := EditOVOwnerAddress.Text;
  DSAccidentHeaderOVCity.AsString := EditOVOwnerCity.Text;
  DSAccidentHeaderOVState.AsString := EditOVOwnerState.Text;
  DSAccidentHeaderOVPhone.AsString := EditOVOwnerPhone.Text;
  DSAccidentHeaderOVDriver.AsString := EditOVDriver.Text;
  DSAccidentHeaderOVDriverAddress.AsString := EditOVDriverAddress.Text;
  DSAccidentHeaderOVDriverCity.AsString := EditOVDriverCity.Text;
  DSAccidentHeaderOVDriverState.AsString := EditOVDriverState.Text;
  DSAccidentHeaderOVDriverRelation.AsString := EditOVDriverRelation.Text;
  DSAccidentHeaderOVInsurance.AsString := EditOVInsuranceName.Text;
  DSAccidentHeaderOVClaimNum.AsString := EditOVInsuranceClaim.Text;
  DSAccidentHeaderOVMake.AsString := EditOVMake.Text;
  DSAccidentHeaderOVModel.AsString := EditOVModel.Text;
  DSAccidentHeaderOVYear.AsString := EditOVYear.Text;
  DSAccidentHeaderOVLicensePlate.AsString := EditOVLicPlate.Text;
  DSAccidentHeaderOVLicenseState.AsString := EditOVLicPlateState.Text;
  DSAccidentHeaderOVVin.AsString := EditOVVin.Text;
  DSAccidentHeaderOVDamages.AsString := MemoDamages.Lines.Text;
  DSAccidentHeaderWitnessName.AsString := EditWitnessName.Text;
  DSAccidentHeaderWitnessAddress.AsString := EditWitnessAddress.Text;
  DSAccidentHeaderWitnessPhone.AsString := EditWitnessPhone.Text;
  if CBWitPassYes.Checked = True then
    DSAccidentHeaderWitnessPassenger.AsString := 'Y'
  else
    DSAccidentHeaderWitnessPassenger.AsString := 'N';
  DSAccidentHeader.Post;
  DSAccidentHeader.ApplyUpdates;
  Application.CreateForm(TFAccidentDetail, FAccidentDetail);
end;

procedure TFAccident.WebFormCreate(Sender: TObject);
begin
  AccPnl.Left := Round((FAccident.Width / 2) - (AccPnl.Width / 2));
  AccPnl.Top := Round((FAccident.Height / 2) - (AccPnl.Height / 2));
end;

procedure TFAccident.WebFormResize(Sender: TObject);
begin
  AccPnl.Top := Round((FAccident.Height / 2) - (AccPnl.Height / 2));
  if AccPnl.Top < 0 then
    AccPnl.Top := 0;
end;

procedure TFAccident.WebFormShow(Sender: TObject);
begin
//  DSAccidentHeader.QueryString := '$filter=(AutoInc eq 0)';
  DSAccidentHeader.Load;
end;

procedure TFAccident.LoadDFMValues;
begin
  inherited LoadDFMValues;

  AccPnl := TScrollBox.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebLabel27 := TLabel.Create(Self);
  WebLabel28 := TLabel.Create(Self);
  WebLabel29 := TLabel.Create(Self);
  WebLabel30 := TLabel.Create(Self);
  WebLabel31 := TLabel.Create(Self);
  WebLabel32 := TLabel.Create(Self);
  WebLabel33 := TLabel.Create(Self);
  WebLabel34 := TLabel.Create(Self);
  WebLabel35 := TLabel.Create(Self);
  WebLabel36 := TLabel.Create(Self);
  WebLabel37 := TLabel.Create(Self);
  WebLabel38 := TLabel.Create(Self);
  WebLabel39 := TLabel.Create(Self);
  WebLabel40 := TLabel.Create(Self);
  WebLabel41 := TLabel.Create(Self);
  WebLabel42 := TLabel.Create(Self);
  WebLabel43 := TLabel.Create(Self);
  WebLabel44 := TLabel.Create(Self);
  WebLabel45 := TLabel.Create(Self);
  WebLabel46 := TLabel.Create(Self);
  WebLabel48 := TLabel.Create(Self);
  WebLabel49 := TLabel.Create(Self);
  WebLabel53 := TLabel.Create(Self);
  WebLabel54 := TLabel.Create(Self);
  WebLabel47 := TLabel.Create(Self);
  EditTruck := TEdit.Create(Self);
  EditTrailer1 := TEdit.Create(Self);
  EditTrailer2 := TEdit.Create(Self);
  EditMVDriverName := TEdit.Create(Self);
  EditMVDriverCell := TEdit.Create(Self);
  EditMVCustName := TEdit.Create(Self);
  EditMVCustNum := TEdit.Create(Self);
  EditMVCustCity := TEdit.Create(Self);
  EditMVCustContact := TEdit.Create(Self);
  EditMVCustPhone := TEdit.Create(Self);
  CBSuperYes := TCheckBox.Create(Self);
  EditSuperName := TEdit.Create(Self);
  EditPassName := TEdit.Create(Self);
  CBPassYes := TCheckBox.Create(Self);
  EditPedName := TEdit.Create(Self);
  CBPedInvolvedYes := TCheckBox.Create(Self);
  EditOVOwner := TEdit.Create(Self);
  CBOthVehYes := TCheckBox.Create(Self);
  EditOVOwnerAddress := TEdit.Create(Self);
  EditOVDriver := TEdit.Create(Self);
  EditOVOwnerCity := TEdit.Create(Self);
  EditOVOwnerState := TEdit.Create(Self);
  EditOVOwnerZip := TEdit.Create(Self);
  EditOVDriverAddress := TEdit.Create(Self);
  EditOVDriverCity := TEdit.Create(Self);
  EditOVDriverState := TEdit.Create(Self);
  EditOVDriverZip := TEdit.Create(Self);
  EditOVDriverPhone := TEdit.Create(Self);
  EditOVDriverRelation := TEdit.Create(Self);
  EditOVYear := TEdit.Create(Self);
  EditOVMake := TEdit.Create(Self);
  EditOVModel := TEdit.Create(Self);
  EditOVLicPlate := TEdit.Create(Self);
  EditOVLicPlateState := TEdit.Create(Self);
  EditOVVin := TEdit.Create(Self);
  EditOVInsuranceName := TEdit.Create(Self);
  EditOVInsuranceClaim := TEdit.Create(Self);
  EditWitnessName := TEdit.Create(Self);
  EditWitnessAddress := TEdit.Create(Self);
  EditWitnessPhone := TEdit.Create(Self);
  CBWitPassYes := TCheckBox.Create(Self);
  MemoDescription := TMemo.Create(Self);
  MemoInjuries := TMemo.Create(Self);
  MemoDamages := TMemo.Create(Self);
  WebButton2 := TButton.Create(Self);
  CBOwnDrv := TCheckBox.Create(Self);
  EditOVOwnerPhone := TEdit.Create(Self);
  DSAccidentHeader := TXDataWebDataSet.Create(Self);
  DSAccidentHeaderAutoInc := TIntegerField.Create(Self);
  DSAccidentHeaderTruck := TIntegerField.Create(Self);
  DSAccidentHeaderTrailer1 := TIntegerField.Create(Self);
  DSAccidentHeaderTrailer2 := TIntegerField.Create(Self);
  DSAccidentHeaderDamages := TStringField.Create(Self);
  DSAccidentHeaderDriver := TStringField.Create(Self);
  DSAccidentHeaderCellNumber := TStringField.Create(Self);
  DSAccidentHeaderMVCustName := TStringField.Create(Self);
  DSAccidentHeaderMVCustNum := TStringField.Create(Self);
  DSAccidentHeaderMVCustCity := TStringField.Create(Self);
  DSAccidentHeaderMVCustContact := TStringField.Create(Self);
  DSAccidentHeaderMVCustPhone := TStringField.Create(Self);
  DSAccidentHeaderPicturesAvailable := TStringField.Create(Self);
  DSAccidentHeaderNotifySupervisor := TStringField.Create(Self);
  DSAccidentHeaderSupervisorName := TStringField.Create(Self);
  DSAccidentHeaderPassengers := TStringField.Create(Self);
  DSAccidentHeaderPedestrians := TStringField.Create(Self);
  DSAccidentHeaderInjuries := TStringField.Create(Self);
  DSAccidentHeaderOVOwner := TStringField.Create(Self);
  DSAccidentHeaderOVAddress := TStringField.Create(Self);
  DSAccidentHeaderOVCity := TStringField.Create(Self);
  DSAccidentHeaderOVState := TStringField.Create(Self);
  DSAccidentHeaderOVPhone := TStringField.Create(Self);
  DSAccidentHeaderOVDriver := TStringField.Create(Self);
  DSAccidentHeaderOVDriverAddress := TStringField.Create(Self);
  DSAccidentHeaderOVDriverCity := TStringField.Create(Self);
  DSAccidentHeaderOVDriverState := TStringField.Create(Self);
  DSAccidentHeaderOVDriverRelation := TStringField.Create(Self);
  DSAccidentHeaderOVInsurance := TStringField.Create(Self);
  DSAccidentHeaderOVClaimNum := TStringField.Create(Self);
  DSAccidentHeaderOVMake := TStringField.Create(Self);
  DSAccidentHeaderOVModel := TStringField.Create(Self);
  DSAccidentHeaderOVYear := TStringField.Create(Self);
  DSAccidentHeaderOVLicensePlate := TStringField.Create(Self);
  DSAccidentHeaderOVLicenseState := TStringField.Create(Self);
  DSAccidentHeaderOVVin := TStringField.Create(Self);
  DSAccidentHeaderOVDamages := TStringField.Create(Self);
  DSAccidentHeaderWitnessName := TStringField.Create(Self);
  DSAccidentHeaderWitnessAddress := TStringField.Create(Self);
  DSAccidentHeaderWitnessPhone := TStringField.Create(Self);
  DSAccidentHeaderWitnessPassenger := TStringField.Create(Self);
  DSAccidentHeaderPassengerName := TStringField.Create(Self);
  DSAccidentHeaderPedestrianName := TStringField.Create(Self);
  DSAccidentPhotos := TXDataWebDataSet.Create(Self);
  DSAccidentDetail := TXDataWebDataSet.Create(Self);
  DSAccidentDetailAutoInc := TIntegerField.Create(Self);
  DSAccidentDetailAccidentNum := TIntegerField.Create(Self);
  DSAccidentDetailAccidentDate := TStringField.Create(Self);
  DSAccidentDetailAccidentTime := TStringField.Create(Self);
  DSAccidentDetailLocation := TStringField.Create(Self);
  DSAccidentDetailIntersection := TStringField.Create(Self);
  DSAccidentDetailCity := TStringField.Create(Self);
  DSAccidentDetailCounty := TStringField.Create(Self);
  DSAccidentDetailState := TStringField.Create(Self);
  DSAccidentDetailPoliceInvestigation := TStringField.Create(Self);
  DSAccidentDetailIncidentNum := TStringField.Create(Self);
  DSAccidentDetailDepartmentName := TStringField.Create(Self);
  DSAccidentDetailCitations := TStringField.Create(Self);
  DSAccidentDetailDirectionTravelling := TStringField.Create(Self);
  DSAccidentDetailOVDirection := TStringField.Create(Self);
  DSAccidentDetailSpeed := TStringField.Create(Self);
  DSAccidentDetailOVSpeed := TStringField.Create(Self);
  DSAccidentDetailSpeedLimit := TStringField.Create(Self);
  DSAccidentDetailTrafficControls := TStringField.Create(Self);
  DSAccidentDetailOVTrafficControls := TStringField.Create(Self);
  DSAccidentDetailHeadlights := TStringField.Create(Self);
  DSAccidentDetailOVHeadlights := TStringField.Create(Self);
  DSAccidentDetailRoadCondition := TStringField.Create(Self);
  DSAccidentDetailWeatherCondition := TStringField.Create(Self);
  DSAccidentDetailAccidentResponsibility := TStringField.Create(Self);
  DSAccidentDetailAccidentResponsibilityDetails := TStringField.Create(Self);
  DSAccidentDetailAccidentDescription := TStringField.Create(Self);
  DSAccidentDetailAccidentDiagram := TStringField.Create(Self);

  AccPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebLabel27.BeforeLoadDFMValues;
  WebLabel28.BeforeLoadDFMValues;
  WebLabel29.BeforeLoadDFMValues;
  WebLabel30.BeforeLoadDFMValues;
  WebLabel31.BeforeLoadDFMValues;
  WebLabel32.BeforeLoadDFMValues;
  WebLabel33.BeforeLoadDFMValues;
  WebLabel34.BeforeLoadDFMValues;
  WebLabel35.BeforeLoadDFMValues;
  WebLabel36.BeforeLoadDFMValues;
  WebLabel37.BeforeLoadDFMValues;
  WebLabel38.BeforeLoadDFMValues;
  WebLabel39.BeforeLoadDFMValues;
  WebLabel40.BeforeLoadDFMValues;
  WebLabel41.BeforeLoadDFMValues;
  WebLabel42.BeforeLoadDFMValues;
  WebLabel43.BeforeLoadDFMValues;
  WebLabel44.BeforeLoadDFMValues;
  WebLabel45.BeforeLoadDFMValues;
  WebLabel46.BeforeLoadDFMValues;
  WebLabel48.BeforeLoadDFMValues;
  WebLabel49.BeforeLoadDFMValues;
  WebLabel53.BeforeLoadDFMValues;
  WebLabel54.BeforeLoadDFMValues;
  WebLabel47.BeforeLoadDFMValues;
  EditTruck.BeforeLoadDFMValues;
  EditTrailer1.BeforeLoadDFMValues;
  EditTrailer2.BeforeLoadDFMValues;
  EditMVDriverName.BeforeLoadDFMValues;
  EditMVDriverCell.BeforeLoadDFMValues;
  EditMVCustName.BeforeLoadDFMValues;
  EditMVCustNum.BeforeLoadDFMValues;
  EditMVCustCity.BeforeLoadDFMValues;
  EditMVCustContact.BeforeLoadDFMValues;
  EditMVCustPhone.BeforeLoadDFMValues;
  CBSuperYes.BeforeLoadDFMValues;
  EditSuperName.BeforeLoadDFMValues;
  EditPassName.BeforeLoadDFMValues;
  CBPassYes.BeforeLoadDFMValues;
  EditPedName.BeforeLoadDFMValues;
  CBPedInvolvedYes.BeforeLoadDFMValues;
  EditOVOwner.BeforeLoadDFMValues;
  CBOthVehYes.BeforeLoadDFMValues;
  EditOVOwnerAddress.BeforeLoadDFMValues;
  EditOVDriver.BeforeLoadDFMValues;
  EditOVOwnerCity.BeforeLoadDFMValues;
  EditOVOwnerState.BeforeLoadDFMValues;
  EditOVOwnerZip.BeforeLoadDFMValues;
  EditOVDriverAddress.BeforeLoadDFMValues;
  EditOVDriverCity.BeforeLoadDFMValues;
  EditOVDriverState.BeforeLoadDFMValues;
  EditOVDriverZip.BeforeLoadDFMValues;
  EditOVDriverPhone.BeforeLoadDFMValues;
  EditOVDriverRelation.BeforeLoadDFMValues;
  EditOVYear.BeforeLoadDFMValues;
  EditOVMake.BeforeLoadDFMValues;
  EditOVModel.BeforeLoadDFMValues;
  EditOVLicPlate.BeforeLoadDFMValues;
  EditOVLicPlateState.BeforeLoadDFMValues;
  EditOVVin.BeforeLoadDFMValues;
  EditOVInsuranceName.BeforeLoadDFMValues;
  EditOVInsuranceClaim.BeforeLoadDFMValues;
  EditWitnessName.BeforeLoadDFMValues;
  EditWitnessAddress.BeforeLoadDFMValues;
  EditWitnessPhone.BeforeLoadDFMValues;
  CBWitPassYes.BeforeLoadDFMValues;
  MemoDescription.BeforeLoadDFMValues;
  MemoInjuries.BeforeLoadDFMValues;
  MemoDamages.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  CBOwnDrv.BeforeLoadDFMValues;
  EditOVOwnerPhone.BeforeLoadDFMValues;
  DSAccidentHeader.BeforeLoadDFMValues;
  DSAccidentHeaderAutoInc.BeforeLoadDFMValues;
  DSAccidentHeaderTruck.BeforeLoadDFMValues;
  DSAccidentHeaderTrailer1.BeforeLoadDFMValues;
  DSAccidentHeaderTrailer2.BeforeLoadDFMValues;
  DSAccidentHeaderDamages.BeforeLoadDFMValues;
  DSAccidentHeaderDriver.BeforeLoadDFMValues;
  DSAccidentHeaderCellNumber.BeforeLoadDFMValues;
  DSAccidentHeaderMVCustName.BeforeLoadDFMValues;
  DSAccidentHeaderMVCustNum.BeforeLoadDFMValues;
  DSAccidentHeaderMVCustCity.BeforeLoadDFMValues;
  DSAccidentHeaderMVCustContact.BeforeLoadDFMValues;
  DSAccidentHeaderMVCustPhone.BeforeLoadDFMValues;
  DSAccidentHeaderPicturesAvailable.BeforeLoadDFMValues;
  DSAccidentHeaderNotifySupervisor.BeforeLoadDFMValues;
  DSAccidentHeaderSupervisorName.BeforeLoadDFMValues;
  DSAccidentHeaderPassengers.BeforeLoadDFMValues;
  DSAccidentHeaderPedestrians.BeforeLoadDFMValues;
  DSAccidentHeaderInjuries.BeforeLoadDFMValues;
  DSAccidentHeaderOVOwner.BeforeLoadDFMValues;
  DSAccidentHeaderOVAddress.BeforeLoadDFMValues;
  DSAccidentHeaderOVCity.BeforeLoadDFMValues;
  DSAccidentHeaderOVState.BeforeLoadDFMValues;
  DSAccidentHeaderOVPhone.BeforeLoadDFMValues;
  DSAccidentHeaderOVDriver.BeforeLoadDFMValues;
  DSAccidentHeaderOVDriverAddress.BeforeLoadDFMValues;
  DSAccidentHeaderOVDriverCity.BeforeLoadDFMValues;
  DSAccidentHeaderOVDriverState.BeforeLoadDFMValues;
  DSAccidentHeaderOVDriverRelation.BeforeLoadDFMValues;
  DSAccidentHeaderOVInsurance.BeforeLoadDFMValues;
  DSAccidentHeaderOVClaimNum.BeforeLoadDFMValues;
  DSAccidentHeaderOVMake.BeforeLoadDFMValues;
  DSAccidentHeaderOVModel.BeforeLoadDFMValues;
  DSAccidentHeaderOVYear.BeforeLoadDFMValues;
  DSAccidentHeaderOVLicensePlate.BeforeLoadDFMValues;
  DSAccidentHeaderOVLicenseState.BeforeLoadDFMValues;
  DSAccidentHeaderOVVin.BeforeLoadDFMValues;
  DSAccidentHeaderOVDamages.BeforeLoadDFMValues;
  DSAccidentHeaderWitnessName.BeforeLoadDFMValues;
  DSAccidentHeaderWitnessAddress.BeforeLoadDFMValues;
  DSAccidentHeaderWitnessPhone.BeforeLoadDFMValues;
  DSAccidentHeaderWitnessPassenger.BeforeLoadDFMValues;
  DSAccidentHeaderPassengerName.BeforeLoadDFMValues;
  DSAccidentHeaderPedestrianName.BeforeLoadDFMValues;
  DSAccidentPhotos.BeforeLoadDFMValues;
  DSAccidentDetail.BeforeLoadDFMValues;
  DSAccidentDetailAutoInc.BeforeLoadDFMValues;
  DSAccidentDetailAccidentNum.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDate.BeforeLoadDFMValues;
  DSAccidentDetailAccidentTime.BeforeLoadDFMValues;
  DSAccidentDetailLocation.BeforeLoadDFMValues;
  DSAccidentDetailIntersection.BeforeLoadDFMValues;
  DSAccidentDetailCity.BeforeLoadDFMValues;
  DSAccidentDetailCounty.BeforeLoadDFMValues;
  DSAccidentDetailState.BeforeLoadDFMValues;
  DSAccidentDetailPoliceInvestigation.BeforeLoadDFMValues;
  DSAccidentDetailIncidentNum.BeforeLoadDFMValues;
  DSAccidentDetailDepartmentName.BeforeLoadDFMValues;
  DSAccidentDetailCitations.BeforeLoadDFMValues;
  DSAccidentDetailDirectionTravelling.BeforeLoadDFMValues;
  DSAccidentDetailOVDirection.BeforeLoadDFMValues;
  DSAccidentDetailSpeed.BeforeLoadDFMValues;
  DSAccidentDetailOVSpeed.BeforeLoadDFMValues;
  DSAccidentDetailSpeedLimit.BeforeLoadDFMValues;
  DSAccidentDetailTrafficControls.BeforeLoadDFMValues;
  DSAccidentDetailOVTrafficControls.BeforeLoadDFMValues;
  DSAccidentDetailHeadlights.BeforeLoadDFMValues;
  DSAccidentDetailOVHeadlights.BeforeLoadDFMValues;
  DSAccidentDetailRoadCondition.BeforeLoadDFMValues;
  DSAccidentDetailWeatherCondition.BeforeLoadDFMValues;
  DSAccidentDetailAccidentResponsibility.BeforeLoadDFMValues;
  DSAccidentDetailAccidentResponsibilityDetails.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDescription.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDiagram.BeforeLoadDFMValues;
  try
    Name := 'FAccident';
    Width := 412;
    Height := 773;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    AccPnl.SetParentComponent(Self);
    AccPnl.Name := 'AccPnl';
    AccPnl.Left := 8;
    AccPnl.Top := 8;
    AccPnl.Width := 392;
    AccPnl.Height := 761;
    AccPnl.ElementClassName := 'card';
    AccPnl.BorderStyle := bsSingle;
    WebLabel1.SetParentComponent(AccPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 40;
    WebLabel1.Top := 33;
    WebLabel1.Width := 96;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Truck Number';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(AccPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 40;
    WebLabel2.Top := 67;
    WebLabel2.Width := 115;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Trailer Number 1';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(AccPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 40;
    WebLabel3.Top := 101;
    WebLabel3.Width := 115;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Trailer Number 2';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(AccPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 40;
    WebLabel4.Top := 135;
    WebLabel4.Width := 231;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Describe Damages and Accident';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(AccPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 40;
    WebLabel5.Top := 533;
    WebLabel5.Width := 42;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'Name';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(AccPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 40;
    WebLabel6.Top := 567;
    WebLabel6.Width := 40;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'Cell #';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(AccPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 40;
    WebLabel7.Top := 633;
    WebLabel7.Width := 42;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Name';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(AccPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 64;
    WebLabel8.Top := 506;
    WebLabel8.Width := 197;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Maplevale Driver Information';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(AccPnl);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 74;
    WebLabel9.Top := 3;
    WebLabel9.Width := 199;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'General Accident Information';
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(AccPnl);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 64;
    WebLabel10.Top := 606;
    WebLabel10.Width := 223;
    WebLabel10.Height := 18;
    WebLabel10.Caption := 'Maplevale Customer Information';
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(AccPnl);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 40;
    WebLabel11.Top := 667;
    WebLabel11.Width := 65;
    WebLabel11.Height := 18;
    WebLabel11.Caption := 'Account#';
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(AccPnl);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 40;
    WebLabel12.Top := 701;
    WebLabel12.Width := 27;
    WebLabel12.Height := 18;
    WebLabel12.Caption := 'City';
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(AccPnl);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 40;
    WebLabel13.Top := 735;
    WebLabel13.Width := 54;
    WebLabel13.Height := 18;
    WebLabel13.Caption := 'Contact';
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(AccPnl);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 40;
    WebLabel14.Top := 769;
    WebLabel14.Width := 45;
    WebLabel14.Height := 18;
    WebLabel14.Caption := 'Phone';
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(AccPnl);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 40;
    WebLabel15.Top := 843;
    WebLabel15.Width := 142;
    WebLabel15.Height := 18;
    WebLabel15.Caption := 'Supervisor Notified?';
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(AccPnl);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 40;
    WebLabel16.Top := 874;
    WebLabel16.Width := 121;
    WebLabel16.Height := 18;
    WebLabel16.Caption := 'Supervisor Name';
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(AccPnl);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 40;
    WebLabel17.Top := 905;
    WebLabel17.Width := 144;
    WebLabel17.Height := 18;
    WebLabel17.Caption := 'Passenger Involved?';
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(AccPnl);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 64;
    WebLabel18.Top := 819;
    WebLabel18.Width := 217;
    WebLabel18.Height := 18;
    WebLabel18.Caption := 'Mandatory Accident Information';
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebLabel19.SetParentComponent(AccPnl);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 40;
    WebLabel19.Top := 932;
    WebLabel19.Width := 122;
    WebLabel19.Height := 18;
    WebLabel19.Caption := 'Passenger Name';
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebLabel20.SetParentComponent(AccPnl);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 40;
    WebLabel20.Top := 969;
    WebLabel20.Width := 144;
    WebLabel20.Height := 18;
    WebLabel20.Caption := 'Pedestrian Involved?';
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.WidthPercent := 100.000000000000000000;
    WebLabel21.SetParentComponent(AccPnl);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 40;
    WebLabel21.Top := 996;
    WebLabel21.Width := 122;
    WebLabel21.Height := 18;
    WebLabel21.Caption := 'Pedestrian Name';
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.WidthPercent := 100.000000000000000000;
    WebLabel22.SetParentComponent(AccPnl);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 40;
    WebLabel22.Top := 1036;
    WebLabel22.Width := 183;
    WebLabel22.Height := 18;
    WebLabel22.Caption := 'Describe All Injuries Below';
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebLabel23.SetParentComponent(AccPnl);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 74;
    WebLabel23.Top := 1419;
    WebLabel23.Width := 173;
    WebLabel23.Height := 18;
    WebLabel23.Caption := 'Other Vehicle Information';
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebLabel24.SetParentComponent(AccPnl);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 40;
    WebLabel24.Top := 1449;
    WebLabel24.Width := 161;
    WebLabel24.Height := 18;
    WebLabel24.Caption := 'Other Vehicle Involved?';
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebLabel25.SetParentComponent(AccPnl);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 40;
    WebLabel25.Top := 1476;
    WebLabel25.Width := 45;
    WebLabel25.Height := 18;
    WebLabel25.Caption := 'Owner';
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.WidthPercent := 100.000000000000000000;
    WebLabel26.SetParentComponent(AccPnl);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 40;
    WebLabel26.Top := 1510;
    WebLabel26.Width := 59;
    WebLabel26.Height := 18;
    WebLabel26.Caption := 'Address';
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebLabel27.SetParentComponent(AccPnl);
    WebLabel27.Name := 'WebLabel27';
    WebLabel27.Left := 40;
    WebLabel27.Top := 1778;
    WebLabel27.Width := 42;
    WebLabel27.Height := 18;
    WebLabel27.Caption := 'Driver';
    WebLabel27.HeightPercent := 100.000000000000000000;
    WebLabel27.WidthPercent := 100.000000000000000000;
    WebLabel28.SetParentComponent(AccPnl);
    WebLabel28.Name := 'WebLabel28';
    WebLabel28.Left := 40;
    WebLabel28.Top := 1544;
    WebLabel28.Width := 27;
    WebLabel28.Height := 18;
    WebLabel28.Caption := 'City';
    WebLabel28.HeightPercent := 100.000000000000000000;
    WebLabel28.WidthPercent := 100.000000000000000000;
    WebLabel29.SetParentComponent(AccPnl);
    WebLabel29.Name := 'WebLabel29';
    WebLabel29.Left := 40;
    WebLabel29.Top := 1578;
    WebLabel29.Width := 37;
    WebLabel29.Height := 18;
    WebLabel29.Caption := 'State';
    WebLabel29.HeightPercent := 100.000000000000000000;
    WebLabel29.WidthPercent := 100.000000000000000000;
    WebLabel30.SetParentComponent(AccPnl);
    WebLabel30.Name := 'WebLabel30';
    WebLabel30.Left := 40;
    WebLabel30.Top := 1612;
    WebLabel30.Width := 61;
    WebLabel30.Height := 18;
    WebLabel30.Caption := 'ZipCode';
    WebLabel30.HeightPercent := 100.000000000000000000;
    WebLabel30.WidthPercent := 100.000000000000000000;
    WebLabel31.SetParentComponent(AccPnl);
    WebLabel31.Name := 'WebLabel31';
    WebLabel31.Left := 40;
    WebLabel31.Top := 1812;
    WebLabel31.Width := 59;
    WebLabel31.Height := 18;
    WebLabel31.Caption := 'Address';
    WebLabel31.HeightPercent := 100.000000000000000000;
    WebLabel31.WidthPercent := 100.000000000000000000;
    WebLabel32.SetParentComponent(AccPnl);
    WebLabel32.Name := 'WebLabel32';
    WebLabel32.Left := 40;
    WebLabel32.Top := 1846;
    WebLabel32.Width := 27;
    WebLabel32.Height := 18;
    WebLabel32.Caption := 'City';
    WebLabel32.HeightPercent := 100.000000000000000000;
    WebLabel32.WidthPercent := 100.000000000000000000;
    WebLabel33.SetParentComponent(AccPnl);
    WebLabel33.Name := 'WebLabel33';
    WebLabel33.Left := 40;
    WebLabel33.Top := 1880;
    WebLabel33.Width := 37;
    WebLabel33.Height := 18;
    WebLabel33.Caption := 'State';
    WebLabel33.HeightPercent := 100.000000000000000000;
    WebLabel33.WidthPercent := 100.000000000000000000;
    WebLabel34.SetParentComponent(AccPnl);
    WebLabel34.Name := 'WebLabel34';
    WebLabel34.Left := 40;
    WebLabel34.Top := 1914;
    WebLabel34.Width := 61;
    WebLabel34.Height := 18;
    WebLabel34.Caption := 'ZipCode';
    WebLabel34.HeightPercent := 100.000000000000000000;
    WebLabel34.WidthPercent := 100.000000000000000000;
    WebLabel35.SetParentComponent(AccPnl);
    WebLabel35.Name := 'WebLabel35';
    WebLabel35.Left := 40;
    WebLabel35.Top := 1948;
    WebLabel35.Width := 45;
    WebLabel35.Height := 18;
    WebLabel35.Caption := 'Phone';
    WebLabel35.HeightPercent := 100.000000000000000000;
    WebLabel35.WidthPercent := 100.000000000000000000;
    WebLabel36.SetParentComponent(AccPnl);
    WebLabel36.Name := 'WebLabel36';
    WebLabel36.Left := 40;
    WebLabel36.Top := 1982;
    WebLabel36.Width := 123;
    WebLabel36.Height := 18;
    WebLabel36.Caption := 'Relation to Owner';
    WebLabel36.HeightPercent := 100.000000000000000000;
    WebLabel36.WidthPercent := 100.000000000000000000;
    WebLabel37.SetParentComponent(AccPnl);
    WebLabel37.Name := 'WebLabel37';
    WebLabel37.Left := 40;
    WebLabel37.Top := 2016;
    WebLabel37.Width := 86;
    WebLabel37.Height := 18;
    WebLabel37.Caption := 'Vehicle Year';
    WebLabel37.HeightPercent := 100.000000000000000000;
    WebLabel37.WidthPercent := 100.000000000000000000;
    WebLabel38.SetParentComponent(AccPnl);
    WebLabel38.Name := 'WebLabel38';
    WebLabel38.Left := 40;
    WebLabel38.Top := 2050;
    WebLabel38.Width := 94;
    WebLabel38.Height := 18;
    WebLabel38.Caption := 'Vehicle Make';
    WebLabel38.HeightPercent := 100.000000000000000000;
    WebLabel38.WidthPercent := 100.000000000000000000;
    WebLabel39.SetParentComponent(AccPnl);
    WebLabel39.Name := 'WebLabel39';
    WebLabel39.Left := 40;
    WebLabel39.Top := 2084;
    WebLabel39.Width := 98;
    WebLabel39.Height := 18;
    WebLabel39.Caption := 'Vehicle Model';
    WebLabel39.HeightPercent := 100.000000000000000000;
    WebLabel39.WidthPercent := 100.000000000000000000;
    WebLabel40.SetParentComponent(AccPnl);
    WebLabel40.Name := 'WebLabel40';
    WebLabel40.Left := 40;
    WebLabel40.Top := 2118;
    WebLabel40.Width := 70;
    WebLabel40.Height := 18;
    WebLabel40.Caption := 'Lic Plate#';
    WebLabel40.HeightPercent := 100.000000000000000000;
    WebLabel40.WidthPercent := 100.000000000000000000;
    WebLabel41.SetParentComponent(AccPnl);
    WebLabel41.Name := 'WebLabel41';
    WebLabel41.Left := 40;
    WebLabel41.Top := 2152;
    WebLabel41.Width := 102;
    WebLabel41.Height := 18;
    WebLabel41.Caption := 'Lic Plate State';
    WebLabel41.HeightPercent := 100.000000000000000000;
    WebLabel41.WidthPercent := 100.000000000000000000;
    WebLabel42.SetParentComponent(AccPnl);
    WebLabel42.Name := 'WebLabel42';
    WebLabel42.Left := 40;
    WebLabel42.Top := 2186;
    WebLabel42.Width := 25;
    WebLabel42.Height := 18;
    WebLabel42.Caption := 'VIN';
    WebLabel42.HeightPercent := 100.000000000000000000;
    WebLabel42.WidthPercent := 100.000000000000000000;
    WebLabel43.SetParentComponent(AccPnl);
    WebLabel43.Name := 'WebLabel43';
    WebLabel43.Left := 40;
    WebLabel43.Top := 2220;
    WebLabel43.Width := 69;
    WebLabel43.Height := 18;
    WebLabel43.Caption := 'Damages';
    WebLabel43.HeightPercent := 100.000000000000000000;
    WebLabel43.WidthPercent := 100.000000000000000000;
    WebLabel44.SetParentComponent(AccPnl);
    WebLabel44.Name := 'WebLabel44';
    WebLabel44.Left := 40;
    WebLabel44.Top := 1678;
    WebLabel44.Width := 66;
    WebLabel44.Height := 18;
    WebLabel44.Caption := 'Insurance';
    WebLabel44.HeightPercent := 100.000000000000000000;
    WebLabel44.WidthPercent := 100.000000000000000000;
    WebLabel45.SetParentComponent(AccPnl);
    WebLabel45.Name := 'WebLabel45';
    WebLabel45.Left := 40;
    WebLabel45.Top := 1712;
    WebLabel45.Width := 50;
    WebLabel45.Height := 18;
    WebLabel45.Caption := 'Claim#';
    WebLabel45.HeightPercent := 100.000000000000000000;
    WebLabel45.WidthPercent := 100.000000000000000000;
    WebLabel46.SetParentComponent(AccPnl);
    WebLabel46.Name := 'WebLabel46';
    WebLabel46.Left := 74;
    WebLabel46.Top := 2400;
    WebLabel46.Width := 136;
    WebLabel46.Height := 18;
    WebLabel46.Caption := 'Witness Information';
    WebLabel46.HeightPercent := 100.000000000000000000;
    WebLabel46.WidthPercent := 100.000000000000000000;
    WebLabel48.SetParentComponent(AccPnl);
    WebLabel48.Name := 'WebLabel48';
    WebLabel48.Left := 48;
    WebLabel48.Top := 2427;
    WebLabel48.Width := 42;
    WebLabel48.Height := 18;
    WebLabel48.Caption := 'Name';
    WebLabel48.HeightPercent := 100.000000000000000000;
    WebLabel48.WidthPercent := 100.000000000000000000;
    WebLabel49.SetParentComponent(AccPnl);
    WebLabel49.Name := 'WebLabel49';
    WebLabel49.Left := 48;
    WebLabel49.Top := 2461;
    WebLabel49.Width := 59;
    WebLabel49.Height := 18;
    WebLabel49.Caption := 'Address';
    WebLabel49.HeightPercent := 100.000000000000000000;
    WebLabel49.WidthPercent := 100.000000000000000000;
    WebLabel53.SetParentComponent(AccPnl);
    WebLabel53.Name := 'WebLabel53';
    WebLabel53.Left := 48;
    WebLabel53.Top := 2501;
    WebLabel53.Width := 45;
    WebLabel53.Height := 18;
    WebLabel53.Caption := 'Phone';
    WebLabel53.HeightPercent := 100.000000000000000000;
    WebLabel53.WidthPercent := 100.000000000000000000;
    WebLabel54.SetParentComponent(AccPnl);
    WebLabel54.Name := 'WebLabel54';
    WebLabel54.Left := 48;
    WebLabel54.Top := 2535;
    WebLabel54.Width := 120;
    WebLabel54.Height := 18;
    WebLabel54.Caption := 'Was Passenger?';
    WebLabel54.HeightPercent := 100.000000000000000000;
    WebLabel54.WidthPercent := 100.000000000000000000;
    WebLabel47.SetParentComponent(AccPnl);
    WebLabel47.Name := 'WebLabel47';
    WebLabel47.Left := 40;
    WebLabel47.Top := 1644;
    WebLabel47.Width := 45;
    WebLabel47.Height := 18;
    WebLabel47.Caption := 'Phone';
    WebLabel47.HeightPercent := 100.000000000000000000;
    WebLabel47.WidthPercent := 100.000000000000000000;
    EditTruck.SetParentComponent(AccPnl);
    EditTruck.Name := 'EditTruck';
    EditTruck.Left := 280;
    EditTruck.Top := 30;
    EditTruck.Width := 57;
    EditTruck.Height := 28;
    EditTruck.ChildOrder := 1;
    EditTruck.EditType := weNumeric;
    EditTruck.HeightPercent := 100.000000000000000000;
    EditTruck.WidthPercent := 100.000000000000000000;
    EditTrailer1.SetParentComponent(AccPnl);
    EditTrailer1.Name := 'EditTrailer1';
    EditTrailer1.Left := 280;
    EditTrailer1.Top := 64;
    EditTrailer1.Width := 57;
    EditTrailer1.Height := 28;
    EditTrailer1.ChildOrder := 1;
    EditTrailer1.EditType := weNumeric;
    EditTrailer1.HeightPercent := 100.000000000000000000;
    EditTrailer1.WidthPercent := 100.000000000000000000;
    EditTrailer2.SetParentComponent(AccPnl);
    EditTrailer2.Name := 'EditTrailer2';
    EditTrailer2.Left := 280;
    EditTrailer2.Top := 98;
    EditTrailer2.Width := 57;
    EditTrailer2.Height := 28;
    EditTrailer2.ChildOrder := 1;
    EditTrailer2.EditType := weNumeric;
    EditTrailer2.HeightPercent := 100.000000000000000000;
    EditTrailer2.WidthPercent := 100.000000000000000000;
    EditMVDriverName.SetParentComponent(AccPnl);
    EditMVDriverName.Name := 'EditMVDriverName';
    EditMVDriverName.Left := 140;
    EditMVDriverName.Top := 530;
    EditMVDriverName.Width := 197;
    EditMVDriverName.Height := 28;
    EditMVDriverName.ChildOrder := 1;
    EditMVDriverName.HeightPercent := 100.000000000000000000;
    EditMVDriverName.WidthPercent := 100.000000000000000000;
    EditMVDriverCell.SetParentComponent(AccPnl);
    EditMVDriverCell.Name := 'EditMVDriverCell';
    EditMVDriverCell.Left := 192;
    EditMVDriverCell.Top := 564;
    EditMVDriverCell.Width := 145;
    EditMVDriverCell.Height := 28;
    EditMVDriverCell.ChildOrder := 1;
    EditMVDriverCell.EditType := weNumeric;
    EditMVDriverCell.HeightPercent := 100.000000000000000000;
    EditMVDriverCell.WidthPercent := 100.000000000000000000;
    EditMVCustName.SetParentComponent(AccPnl);
    EditMVCustName.Name := 'EditMVCustName';
    EditMVCustName.Left := 140;
    EditMVCustName.Top := 630;
    EditMVCustName.Width := 197;
    EditMVCustName.Height := 28;
    EditMVCustName.ChildOrder := 1;
    EditMVCustName.HeightPercent := 100.000000000000000000;
    EditMVCustName.WidthPercent := 100.000000000000000000;
    EditMVCustNum.SetParentComponent(AccPnl);
    EditMVCustNum.Name := 'EditMVCustNum';
    EditMVCustNum.Left := 256;
    EditMVCustNum.Top := 664;
    EditMVCustNum.Width := 81;
    EditMVCustNum.Height := 28;
    EditMVCustNum.ChildOrder := 1;
    EditMVCustNum.EditType := weNumeric;
    EditMVCustNum.HeightPercent := 100.000000000000000000;
    EditMVCustNum.WidthPercent := 100.000000000000000000;
    EditMVCustCity.SetParentComponent(AccPnl);
    EditMVCustCity.Name := 'EditMVCustCity';
    EditMVCustCity.Left := 140;
    EditMVCustCity.Top := 698;
    EditMVCustCity.Width := 197;
    EditMVCustCity.Height := 28;
    EditMVCustCity.ChildOrder := 1;
    EditMVCustCity.HeightPercent := 100.000000000000000000;
    EditMVCustCity.WidthPercent := 100.000000000000000000;
    EditMVCustContact.SetParentComponent(AccPnl);
    EditMVCustContact.Name := 'EditMVCustContact';
    EditMVCustContact.Left := 140;
    EditMVCustContact.Top := 732;
    EditMVCustContact.Width := 197;
    EditMVCustContact.Height := 28;
    EditMVCustContact.ChildOrder := 1;
    EditMVCustContact.HeightPercent := 100.000000000000000000;
    EditMVCustContact.WidthPercent := 100.000000000000000000;
    EditMVCustPhone.SetParentComponent(AccPnl);
    EditMVCustPhone.Name := 'EditMVCustPhone';
    EditMVCustPhone.Left := 192;
    EditMVCustPhone.Top := 766;
    EditMVCustPhone.Width := 145;
    EditMVCustPhone.Height := 28;
    EditMVCustPhone.ChildOrder := 1;
    EditMVCustPhone.EditType := weNumeric;
    EditMVCustPhone.HeightPercent := 100.000000000000000000;
    EditMVCustPhone.WidthPercent := 100.000000000000000000;
    CBSuperYes.SetParentComponent(AccPnl);
    CBSuperYes.Name := 'CBSuperYes';
    CBSuperYes.Left := 292;
    CBSuperYes.Top := 842;
    CBSuperYes.Width := 45;
    CBSuperYes.Height := 22;
    CBSuperYes.Caption := 'Yes';
    CBSuperYes.Checked := True;
    CBSuperYes.ChildOrder := 26;
    CBSuperYes.Color := clNone;
    CBSuperYes.HeightPercent := 100.000000000000000000;
    CBSuperYes.State := cbChecked;
    CBSuperYes.WidthPercent := 100.000000000000000000;
    EditSuperName.SetParentComponent(AccPnl);
    EditSuperName.Name := 'EditSuperName';
    EditSuperName.Left := 192;
    EditSuperName.Top := 871;
    EditSuperName.Width := 145;
    EditSuperName.Height := 28;
    EditSuperName.ChildOrder := 1;
    EditSuperName.HeightPercent := 100.000000000000000000;
    EditSuperName.WidthPercent := 100.000000000000000000;
    EditPassName.SetParentComponent(AccPnl);
    EditPassName.Name := 'EditPassName';
    EditPassName.Left := 192;
    EditPassName.Top := 929;
    EditPassName.Width := 145;
    EditPassName.Height := 28;
    EditPassName.ChildOrder := 1;
    EditPassName.HeightPercent := 100.000000000000000000;
    EditPassName.WidthPercent := 100.000000000000000000;
    CBPassYes.SetParentComponent(AccPnl);
    CBPassYes.Name := 'CBPassYes';
    CBPassYes.Left := 292;
    CBPassYes.Top := 904;
    CBPassYes.Width := 45;
    CBPassYes.Height := 22;
    CBPassYes.Caption := 'Yes';
    CBPassYes.ChildOrder := 26;
    CBPassYes.Color := clNone;
    CBPassYes.HeightPercent := 100.000000000000000000;
    CBPassYes.State := cbUnchecked;
    CBPassYes.WidthPercent := 100.000000000000000000;
    EditPedName.SetParentComponent(AccPnl);
    EditPedName.Name := 'EditPedName';
    EditPedName.Left := 192;
    EditPedName.Top := 993;
    EditPedName.Width := 145;
    EditPedName.Height := 28;
    EditPedName.ChildOrder := 1;
    EditPedName.HeightPercent := 100.000000000000000000;
    EditPedName.WidthPercent := 100.000000000000000000;
    CBPedInvolvedYes.SetParentComponent(AccPnl);
    CBPedInvolvedYes.Name := 'CBPedInvolvedYes';
    CBPedInvolvedYes.Left := 292;
    CBPedInvolvedYes.Top := 968;
    CBPedInvolvedYes.Width := 45;
    CBPedInvolvedYes.Height := 22;
    CBPedInvolvedYes.Caption := 'Yes';
    CBPedInvolvedYes.ChildOrder := 26;
    CBPedInvolvedYes.Color := clNone;
    CBPedInvolvedYes.HeightPercent := 100.000000000000000000;
    CBPedInvolvedYes.State := cbUnchecked;
    CBPedInvolvedYes.WidthPercent := 100.000000000000000000;
    EditOVOwner.SetParentComponent(AccPnl);
    EditOVOwner.Name := 'EditOVOwner';
    EditOVOwner.Left := 140;
    EditOVOwner.Top := 1473;
    EditOVOwner.Width := 197;
    EditOVOwner.Height := 28;
    EditOVOwner.ChildOrder := 1;
    EditOVOwner.HeightPercent := 100.000000000000000000;
    EditOVOwner.WidthPercent := 100.000000000000000000;
    CBOthVehYes.SetParentComponent(AccPnl);
    CBOthVehYes.Name := 'CBOthVehYes';
    CBOthVehYes.Left := 292;
    CBOthVehYes.Top := 1448;
    CBOthVehYes.Width := 45;
    CBOthVehYes.Height := 22;
    CBOthVehYes.Caption := 'Yes';
    CBOthVehYes.ChildOrder := 26;
    CBOthVehYes.Color := clNone;
    CBOthVehYes.HeightPercent := 100.000000000000000000;
    CBOthVehYes.State := cbUnchecked;
    CBOthVehYes.WidthPercent := 100.000000000000000000;
    EditOVOwnerAddress.SetParentComponent(AccPnl);
    EditOVOwnerAddress.Name := 'EditOVOwnerAddress';
    EditOVOwnerAddress.Left := 140;
    EditOVOwnerAddress.Top := 1507;
    EditOVOwnerAddress.Width := 197;
    EditOVOwnerAddress.Height := 28;
    EditOVOwnerAddress.ChildOrder := 1;
    EditOVOwnerAddress.HeightPercent := 100.000000000000000000;
    EditOVOwnerAddress.WidthPercent := 100.000000000000000000;
    EditOVDriver.SetParentComponent(AccPnl);
    EditOVDriver.Name := 'EditOVDriver';
    EditOVDriver.Left := 140;
    EditOVDriver.Top := 1775;
    EditOVDriver.Width := 197;
    EditOVDriver.Height := 28;
    EditOVDriver.ChildOrder := 1;
    EditOVDriver.HeightPercent := 100.000000000000000000;
    EditOVDriver.WidthPercent := 100.000000000000000000;
    EditOVOwnerCity.SetParentComponent(AccPnl);
    EditOVOwnerCity.Name := 'EditOVOwnerCity';
    EditOVOwnerCity.Left := 140;
    EditOVOwnerCity.Top := 1541;
    EditOVOwnerCity.Width := 197;
    EditOVOwnerCity.Height := 28;
    EditOVOwnerCity.ChildOrder := 1;
    EditOVOwnerCity.HeightPercent := 100.000000000000000000;
    EditOVOwnerCity.WidthPercent := 100.000000000000000000;
    EditOVOwnerState.SetParentComponent(AccPnl);
    EditOVOwnerState.Name := 'EditOVOwnerState';
    EditOVOwnerState.Left := 264;
    EditOVOwnerState.Top := 1575;
    EditOVOwnerState.Width := 73;
    EditOVOwnerState.Height := 28;
    EditOVOwnerState.ChildOrder := 1;
    EditOVOwnerState.HeightPercent := 100.000000000000000000;
    EditOVOwnerState.WidthPercent := 100.000000000000000000;
    EditOVOwnerZip.SetParentComponent(AccPnl);
    EditOVOwnerZip.Name := 'EditOVOwnerZip';
    EditOVOwnerZip.Left := 264;
    EditOVOwnerZip.Top := 1609;
    EditOVOwnerZip.Width := 73;
    EditOVOwnerZip.Height := 28;
    EditOVOwnerZip.ChildOrder := 1;
    EditOVOwnerZip.EditType := weNumeric;
    EditOVOwnerZip.HeightPercent := 100.000000000000000000;
    EditOVOwnerZip.WidthPercent := 100.000000000000000000;
    EditOVDriverAddress.SetParentComponent(AccPnl);
    EditOVDriverAddress.Name := 'EditOVDriverAddress';
    EditOVDriverAddress.Left := 140;
    EditOVDriverAddress.Top := 1809;
    EditOVDriverAddress.Width := 197;
    EditOVDriverAddress.Height := 28;
    EditOVDriverAddress.ChildOrder := 1;
    EditOVDriverAddress.HeightPercent := 100.000000000000000000;
    EditOVDriverAddress.WidthPercent := 100.000000000000000000;
    EditOVDriverCity.SetParentComponent(AccPnl);
    EditOVDriverCity.Name := 'EditOVDriverCity';
    EditOVDriverCity.Left := 140;
    EditOVDriverCity.Top := 1843;
    EditOVDriverCity.Width := 197;
    EditOVDriverCity.Height := 28;
    EditOVDriverCity.ChildOrder := 1;
    EditOVDriverCity.HeightPercent := 100.000000000000000000;
    EditOVDriverCity.WidthPercent := 100.000000000000000000;
    EditOVDriverState.SetParentComponent(AccPnl);
    EditOVDriverState.Name := 'EditOVDriverState';
    EditOVDriverState.Left := 264;
    EditOVDriverState.Top := 1877;
    EditOVDriverState.Width := 73;
    EditOVDriverState.Height := 28;
    EditOVDriverState.ChildOrder := 1;
    EditOVDriverState.HeightPercent := 100.000000000000000000;
    EditOVDriverState.WidthPercent := 100.000000000000000000;
    EditOVDriverZip.SetParentComponent(AccPnl);
    EditOVDriverZip.Name := 'EditOVDriverZip';
    EditOVDriverZip.Left := 264;
    EditOVDriverZip.Top := 1911;
    EditOVDriverZip.Width := 73;
    EditOVDriverZip.Height := 28;
    EditOVDriverZip.ChildOrder := 1;
    EditOVDriverZip.EditType := weNumeric;
    EditOVDriverZip.HeightPercent := 100.000000000000000000;
    EditOVDriverZip.WidthPercent := 100.000000000000000000;
    EditOVDriverPhone.SetParentComponent(AccPnl);
    EditOVDriverPhone.Name := 'EditOVDriverPhone';
    EditOVDriverPhone.Left := 193;
    EditOVDriverPhone.Top := 1945;
    EditOVDriverPhone.Width := 144;
    EditOVDriverPhone.Height := 28;
    EditOVDriverPhone.ChildOrder := 1;
    EditOVDriverPhone.EditType := weNumeric;
    EditOVDriverPhone.HeightPercent := 100.000000000000000000;
    EditOVDriverPhone.WidthPercent := 100.000000000000000000;
    EditOVDriverRelation.SetParentComponent(AccPnl);
    EditOVDriverRelation.Name := 'EditOVDriverRelation';
    EditOVDriverRelation.Left := 193;
    EditOVDriverRelation.Top := 1979;
    EditOVDriverRelation.Width := 144;
    EditOVDriverRelation.Height := 28;
    EditOVDriverRelation.ChildOrder := 1;
    EditOVDriverRelation.HeightPercent := 100.000000000000000000;
    EditOVDriverRelation.WidthPercent := 100.000000000000000000;
    EditOVYear.SetParentComponent(AccPnl);
    EditOVYear.Name := 'EditOVYear';
    EditOVYear.Left := 249;
    EditOVYear.Top := 2013;
    EditOVYear.Width := 88;
    EditOVYear.Height := 28;
    EditOVYear.ChildOrder := 1;
    EditOVYear.EditType := weNumeric;
    EditOVYear.HeightPercent := 100.000000000000000000;
    EditOVYear.WidthPercent := 100.000000000000000000;
    EditOVMake.SetParentComponent(AccPnl);
    EditOVMake.Name := 'EditOVMake';
    EditOVMake.Left := 193;
    EditOVMake.Top := 2047;
    EditOVMake.Width := 144;
    EditOVMake.Height := 28;
    EditOVMake.ChildOrder := 1;
    EditOVMake.HeightPercent := 100.000000000000000000;
    EditOVMake.WidthPercent := 100.000000000000000000;
    EditOVModel.SetParentComponent(AccPnl);
    EditOVModel.Name := 'EditOVModel';
    EditOVModel.Left := 193;
    EditOVModel.Top := 2081;
    EditOVModel.Width := 144;
    EditOVModel.Height := 28;
    EditOVModel.ChildOrder := 1;
    EditOVModel.HeightPercent := 100.000000000000000000;
    EditOVModel.WidthPercent := 100.000000000000000000;
    EditOVLicPlate.SetParentComponent(AccPnl);
    EditOVLicPlate.Name := 'EditOVLicPlate';
    EditOVLicPlate.Left := 193;
    EditOVLicPlate.Top := 2115;
    EditOVLicPlate.Width := 144;
    EditOVLicPlate.Height := 28;
    EditOVLicPlate.ChildOrder := 1;
    EditOVLicPlate.HeightPercent := 100.000000000000000000;
    EditOVLicPlate.WidthPercent := 100.000000000000000000;
    EditOVLicPlateState.SetParentComponent(AccPnl);
    EditOVLicPlateState.Name := 'EditOVLicPlateState';
    EditOVLicPlateState.Left := 280;
    EditOVLicPlateState.Top := 2149;
    EditOVLicPlateState.Width := 57;
    EditOVLicPlateState.Height := 28;
    EditOVLicPlateState.ChildOrder := 1;
    EditOVLicPlateState.HeightPercent := 100.000000000000000000;
    EditOVLicPlateState.WidthPercent := 100.000000000000000000;
    EditOVVin.SetParentComponent(AccPnl);
    EditOVVin.Name := 'EditOVVin';
    EditOVVin.Left := 140;
    EditOVVin.Top := 2183;
    EditOVVin.Width := 197;
    EditOVVin.Height := 28;
    EditOVVin.ChildOrder := 1;
    EditOVVin.HeightPercent := 100.000000000000000000;
    EditOVVin.WidthPercent := 100.000000000000000000;
    EditOVInsuranceName.SetParentComponent(AccPnl);
    EditOVInsuranceName.Name := 'EditOVInsuranceName';
    EditOVInsuranceName.Left := 140;
    EditOVInsuranceName.Top := 1675;
    EditOVInsuranceName.Width := 197;
    EditOVInsuranceName.Height := 28;
    EditOVInsuranceName.ChildOrder := 1;
    EditOVInsuranceName.HeightPercent := 100.000000000000000000;
    EditOVInsuranceName.WidthPercent := 100.000000000000000000;
    EditOVInsuranceClaim.SetParentComponent(AccPnl);
    EditOVInsuranceClaim.Name := 'EditOVInsuranceClaim';
    EditOVInsuranceClaim.Left := 140;
    EditOVInsuranceClaim.Top := 1709;
    EditOVInsuranceClaim.Width := 197;
    EditOVInsuranceClaim.Height := 28;
    EditOVInsuranceClaim.ChildOrder := 1;
    EditOVInsuranceClaim.HeightPercent := 100.000000000000000000;
    EditOVInsuranceClaim.WidthPercent := 100.000000000000000000;
    EditWitnessName.SetParentComponent(AccPnl);
    EditWitnessName.Name := 'EditWitnessName';
    EditWitnessName.Left := 140;
    EditWitnessName.Top := 2424;
    EditWitnessName.Width := 197;
    EditWitnessName.Height := 28;
    EditWitnessName.ChildOrder := 1;
    EditWitnessName.HeightPercent := 100.000000000000000000;
    EditWitnessName.WidthPercent := 100.000000000000000000;
    EditWitnessAddress.SetParentComponent(AccPnl);
    EditWitnessAddress.Name := 'EditWitnessAddress';
    EditWitnessAddress.Left := 140;
    EditWitnessAddress.Top := 2458;
    EditWitnessAddress.Width := 197;
    EditWitnessAddress.Height := 28;
    EditWitnessAddress.ChildOrder := 1;
    EditWitnessAddress.HeightPercent := 100.000000000000000000;
    EditWitnessAddress.WidthPercent := 100.000000000000000000;
    EditWitnessPhone.SetParentComponent(AccPnl);
    EditWitnessPhone.Name := 'EditWitnessPhone';
    EditWitnessPhone.Left := 184;
    EditWitnessPhone.Top := 2498;
    EditWitnessPhone.Width := 153;
    EditWitnessPhone.Height := 28;
    EditWitnessPhone.ChildOrder := 1;
    EditWitnessPhone.HeightPercent := 100.000000000000000000;
    EditWitnessPhone.WidthPercent := 100.000000000000000000;
    CBWitPassYes.SetParentComponent(AccPnl);
    CBWitPassYes.Name := 'CBWitPassYes';
    CBWitPassYes.Left := 292;
    CBWitPassYes.Top := 2534;
    CBWitPassYes.Width := 45;
    CBWitPassYes.Height := 22;
    CBWitPassYes.Caption := 'Yes';
    CBWitPassYes.ChildOrder := 26;
    CBWitPassYes.Color := clNone;
    CBWitPassYes.HeightPercent := 100.000000000000000000;
    CBWitPassYes.State := cbUnchecked;
    CBWitPassYes.WidthPercent := 100.000000000000000000;
    MemoDescription.SetParentComponent(AccPnl);
    MemoDescription.Name := 'MemoDescription';
    MemoDescription.Left := 40;
    MemoDescription.Top := 159;
    MemoDescription.Width := 297;
    MemoDescription.Height := 341;
    MemoDescription.AutoSize := False;
    MemoDescription.HeightPercent := 100.000000000000000000;
    MemoDescription.SelLength := 0;
    MemoDescription.SelStart := 0;
    MemoDescription.WidthPercent := 100.000000000000000000;
    MemoInjuries.SetParentComponent(AccPnl);
    MemoInjuries.Name := 'MemoInjuries';
    MemoInjuries.Left := 40;
    MemoInjuries.Top := 1060;
    MemoInjuries.Width := 305;
    MemoInjuries.Height := 325;
    MemoInjuries.AutoSize := False;
    MemoInjuries.HeightPercent := 100.000000000000000000;
    MemoInjuries.SelLength := 0;
    MemoInjuries.SelStart := 0;
    MemoInjuries.WidthPercent := 100.000000000000000000;
    MemoDamages.SetParentComponent(AccPnl);
    MemoDamages.Name := 'MemoDamages';
    MemoDamages.Left := 40;
    MemoDamages.Top := 2244;
    MemoDamages.Width := 297;
    MemoDamages.Height := 141;
    MemoDamages.AutoSize := False;
    MemoDamages.HeightPercent := 100.000000000000000000;
    MemoDamages.SelLength := 0;
    MemoDamages.SelStart := 0;
    MemoDamages.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(AccPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 110;
    WebButton2.Top := 2616;
    WebButton2.Width := 161;
    WebButton2.Height := 73;
    WebButton2.Caption := 'Save && Continue';
    WebButton2.ChildOrder := 2;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    CBOwnDrv.SetParentComponent(AccPnl);
    CBOwnDrv.Name := 'CBOwnDrv';
    CBOwnDrv.Left := 64;
    CBOwnDrv.Top := 1750;
    CBOwnDrv.Width := 140;
    CBOwnDrv.Height := 22;
    CBOwnDrv.Caption := 'Same as Owner';
    CBOwnDrv.ChildOrder := 26;
    CBOwnDrv.Color := clNone;
    CBOwnDrv.HeightPercent := 100.000000000000000000;
    CBOwnDrv.State := cbUnchecked;
    CBOwnDrv.WidthPercent := 100.000000000000000000;
    SetEvent(CBOwnDrv, Self, 'OnClick', 'CBOwnDrvClick');
    EditOVOwnerPhone.SetParentComponent(AccPnl);
    EditOVOwnerPhone.Name := 'EditOVOwnerPhone';
    EditOVOwnerPhone.Left := 193;
    EditOVOwnerPhone.Top := 1641;
    EditOVOwnerPhone.Width := 144;
    EditOVOwnerPhone.Height := 28;
    EditOVOwnerPhone.ChildOrder := 1;
    EditOVOwnerPhone.EditType := weNumeric;
    EditOVOwnerPhone.HeightPercent := 100.000000000000000000;
    EditOVOwnerPhone.WidthPercent := 100.000000000000000000;
    DSAccidentHeader.SetParentComponent(Self);
    DSAccidentHeader.Name := 'DSAccidentHeader';
    DSAccidentHeader.AfterOpen := DSAccidentHeaderAfterOpen;
    DSAccidentHeader.EntitySetName := 'accidentheader';
    DSAccidentHeader.Connection := Dm.ApiConnection;
    DSAccidentHeader.Left := 48;
    DSAccidentHeader.Top := 592;
    DSAccidentHeaderAutoInc.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderAutoInc.Name := 'DSAccidentHeaderAutoInc';
    DSAccidentHeaderAutoInc.FieldName := 'AutoInc';
    DSAccidentHeaderAutoInc.Required := True;
    DSAccidentHeaderTruck.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderTruck.Name := 'DSAccidentHeaderTruck';
    DSAccidentHeaderTruck.FieldName := 'Truck';
    DSAccidentHeaderTrailer1.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderTrailer1.Name := 'DSAccidentHeaderTrailer1';
    DSAccidentHeaderTrailer1.FieldName := 'Trailer1';
    DSAccidentHeaderTrailer2.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderTrailer2.Name := 'DSAccidentHeaderTrailer2';
    DSAccidentHeaderTrailer2.FieldName := 'Trailer2';
    DSAccidentHeaderDamages.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderDamages.Name := 'DSAccidentHeaderDamages';
    DSAccidentHeaderDamages.FieldName := 'Damages';
    DSAccidentHeaderDamages.Size := 255;
    DSAccidentHeaderDriver.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderDriver.Name := 'DSAccidentHeaderDriver';
    DSAccidentHeaderDriver.FieldName := 'Driver';
    DSAccidentHeaderDriver.Size := 50;
    DSAccidentHeaderCellNumber.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderCellNumber.Name := 'DSAccidentHeaderCellNumber';
    DSAccidentHeaderCellNumber.FieldName := 'CellNumber';
    DSAccidentHeaderMVCustName.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderMVCustName.Name := 'DSAccidentHeaderMVCustName';
    DSAccidentHeaderMVCustName.FieldName := 'MVCustName';
    DSAccidentHeaderMVCustName.Size := 50;
    DSAccidentHeaderMVCustNum.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderMVCustNum.Name := 'DSAccidentHeaderMVCustNum';
    DSAccidentHeaderMVCustNum.FieldName := 'MVCustNum';
    DSAccidentHeaderMVCustNum.Size := 10;
    DSAccidentHeaderMVCustCity.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderMVCustCity.Name := 'DSAccidentHeaderMVCustCity';
    DSAccidentHeaderMVCustCity.FieldName := 'MVCustCity';
    DSAccidentHeaderMVCustCity.Size := 25;
    DSAccidentHeaderMVCustContact.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderMVCustContact.Name := 'DSAccidentHeaderMVCustContact';
    DSAccidentHeaderMVCustContact.FieldName := 'MVCustContact';
    DSAccidentHeaderMVCustContact.Size := 25;
    DSAccidentHeaderMVCustPhone.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderMVCustPhone.Name := 'DSAccidentHeaderMVCustPhone';
    DSAccidentHeaderMVCustPhone.FieldName := 'MVCustPhone';
    DSAccidentHeaderMVCustPhone.Size := 25;
    DSAccidentHeaderPicturesAvailable.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderPicturesAvailable.Name := 'DSAccidentHeaderPicturesAvailable';
    DSAccidentHeaderPicturesAvailable.FieldName := 'PicturesAvailable';
    DSAccidentHeaderPicturesAvailable.Size := 1;
    DSAccidentHeaderNotifySupervisor.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderNotifySupervisor.Name := 'DSAccidentHeaderNotifySupervisor';
    DSAccidentHeaderNotifySupervisor.FieldName := 'NotifySupervisor';
    DSAccidentHeaderNotifySupervisor.Size := 1;
    DSAccidentHeaderSupervisorName.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderSupervisorName.Name := 'DSAccidentHeaderSupervisorName';
    DSAccidentHeaderSupervisorName.FieldName := 'SupervisorName';
    DSAccidentHeaderSupervisorName.Size := 25;
    DSAccidentHeaderPassengers.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderPassengers.Name := 'DSAccidentHeaderPassengers';
    DSAccidentHeaderPassengers.FieldName := 'Passengers';
    DSAccidentHeaderPassengers.Size := 1;
    DSAccidentHeaderPedestrians.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderPedestrians.Name := 'DSAccidentHeaderPedestrians';
    DSAccidentHeaderPedestrians.FieldName := 'Pedestrians';
    DSAccidentHeaderPedestrians.Size := 1;
    DSAccidentHeaderInjuries.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderInjuries.Name := 'DSAccidentHeaderInjuries';
    DSAccidentHeaderInjuries.FieldName := 'Injuries';
    DSAccidentHeaderInjuries.Size := 255;
    DSAccidentHeaderOVOwner.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVOwner.Name := 'DSAccidentHeaderOVOwner';
    DSAccidentHeaderOVOwner.FieldName := 'OVOwner';
    DSAccidentHeaderOVOwner.Size := 50;
    DSAccidentHeaderOVAddress.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVAddress.Name := 'DSAccidentHeaderOVAddress';
    DSAccidentHeaderOVAddress.FieldName := 'OVAddress';
    DSAccidentHeaderOVAddress.Size := 100;
    DSAccidentHeaderOVCity.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVCity.Name := 'DSAccidentHeaderOVCity';
    DSAccidentHeaderOVCity.FieldName := 'OVCity';
    DSAccidentHeaderOVCity.Size := 25;
    DSAccidentHeaderOVState.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVState.Name := 'DSAccidentHeaderOVState';
    DSAccidentHeaderOVState.FieldName := 'OVState';
    DSAccidentHeaderOVState.Size := 2;
    DSAccidentHeaderOVPhone.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVPhone.Name := 'DSAccidentHeaderOVPhone';
    DSAccidentHeaderOVPhone.FieldName := 'OVPhone';
    DSAccidentHeaderOVPhone.Size := 25;
    DSAccidentHeaderOVDriver.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDriver.Name := 'DSAccidentHeaderOVDriver';
    DSAccidentHeaderOVDriver.FieldName := 'OVDriver';
    DSAccidentHeaderOVDriver.Size := 50;
    DSAccidentHeaderOVDriverAddress.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDriverAddress.Name := 'DSAccidentHeaderOVDriverAddress';
    DSAccidentHeaderOVDriverAddress.FieldName := 'OVDriverAddress';
    DSAccidentHeaderOVDriverAddress.Size := 100;
    DSAccidentHeaderOVDriverCity.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDriverCity.Name := 'DSAccidentHeaderOVDriverCity';
    DSAccidentHeaderOVDriverCity.FieldName := 'OVDriverCity';
    DSAccidentHeaderOVDriverCity.Size := 25;
    DSAccidentHeaderOVDriverState.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDriverState.Name := 'DSAccidentHeaderOVDriverState';
    DSAccidentHeaderOVDriverState.FieldName := 'OVDriverState';
    DSAccidentHeaderOVDriverState.Size := 2;
    DSAccidentHeaderOVDriverRelation.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDriverRelation.Name := 'DSAccidentHeaderOVDriverRelation';
    DSAccidentHeaderOVDriverRelation.FieldName := 'OVDriverRelation';
    DSAccidentHeaderOVDriverRelation.Size := 255;
    DSAccidentHeaderOVInsurance.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVInsurance.Name := 'DSAccidentHeaderOVInsurance';
    DSAccidentHeaderOVInsurance.FieldName := 'OVInsurance';
    DSAccidentHeaderOVInsurance.Size := 255;
    DSAccidentHeaderOVClaimNum.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVClaimNum.Name := 'DSAccidentHeaderOVClaimNum';
    DSAccidentHeaderOVClaimNum.FieldName := 'OVClaimNum';
    DSAccidentHeaderOVClaimNum.Size := 50;
    DSAccidentHeaderOVMake.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVMake.Name := 'DSAccidentHeaderOVMake';
    DSAccidentHeaderOVMake.FieldName := 'OVMake';
    DSAccidentHeaderOVMake.Size := 50;
    DSAccidentHeaderOVModel.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVModel.Name := 'DSAccidentHeaderOVModel';
    DSAccidentHeaderOVModel.FieldName := 'OVModel';
    DSAccidentHeaderOVModel.Size := 50;
    DSAccidentHeaderOVYear.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVYear.Name := 'DSAccidentHeaderOVYear';
    DSAccidentHeaderOVYear.FieldName := 'OVYear';
    DSAccidentHeaderOVYear.Size := 50;
    DSAccidentHeaderOVLicensePlate.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVLicensePlate.Name := 'DSAccidentHeaderOVLicensePlate';
    DSAccidentHeaderOVLicensePlate.FieldName := 'OVLicensePlate';
    DSAccidentHeaderOVLicensePlate.Size := 50;
    DSAccidentHeaderOVLicenseState.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVLicenseState.Name := 'DSAccidentHeaderOVLicenseState';
    DSAccidentHeaderOVLicenseState.FieldName := 'OVLicenseState';
    DSAccidentHeaderOVLicenseState.Size := 50;
    DSAccidentHeaderOVVin.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVVin.Name := 'DSAccidentHeaderOVVin';
    DSAccidentHeaderOVVin.FieldName := 'OVVin';
    DSAccidentHeaderOVVin.Size := 50;
    DSAccidentHeaderOVDamages.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderOVDamages.Name := 'DSAccidentHeaderOVDamages';
    DSAccidentHeaderOVDamages.FieldName := 'OVDamages';
    DSAccidentHeaderOVDamages.Size := 255;
    DSAccidentHeaderWitnessName.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderWitnessName.Name := 'DSAccidentHeaderWitnessName';
    DSAccidentHeaderWitnessName.FieldName := 'WitnessName';
    DSAccidentHeaderWitnessName.Size := 50;
    DSAccidentHeaderWitnessAddress.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderWitnessAddress.Name := 'DSAccidentHeaderWitnessAddress';
    DSAccidentHeaderWitnessAddress.FieldName := 'WitnessAddress';
    DSAccidentHeaderWitnessAddress.Size := 100;
    DSAccidentHeaderWitnessPhone.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderWitnessPhone.Name := 'DSAccidentHeaderWitnessPhone';
    DSAccidentHeaderWitnessPhone.FieldName := 'WitnessPhone';
    DSAccidentHeaderWitnessPhone.Size := 25;
    DSAccidentHeaderWitnessPassenger.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderWitnessPassenger.Name := 'DSAccidentHeaderWitnessPassenger';
    DSAccidentHeaderWitnessPassenger.FieldName := 'WitnessPassenger';
    DSAccidentHeaderWitnessPassenger.Size := 1;
    DSAccidentHeaderPassengerName.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderPassengerName.Name := 'DSAccidentHeaderPassengerName';
    DSAccidentHeaderPassengerName.FieldName := 'PassengerName';
    DSAccidentHeaderPassengerName.Size := 50;
    DSAccidentHeaderPedestrianName.SetParentComponent(DSAccidentHeader);
    DSAccidentHeaderPedestrianName.Name := 'DSAccidentHeaderPedestrianName';
    DSAccidentHeaderPedestrianName.FieldName := 'PedestrianName';
    DSAccidentHeaderPedestrianName.Size := 50;
    DSAccidentPhotos.SetParentComponent(Self);
    DSAccidentPhotos.Name := 'DSAccidentPhotos';
    DSAccidentPhotos.EntitySetName := 'accidentphotos';
    DSAccidentPhotos.Connection := Dm.ApiConnection;
    DSAccidentPhotos.Left := 136;
    DSAccidentPhotos.Top := 648;
    DSAccidentDetail.SetParentComponent(Self);
    DSAccidentDetail.Name := 'DSAccidentDetail';
    DSAccidentDetail.EntitySetName := 'accidentdetail';
    DSAccidentDetail.Connection := Dm.ApiConnection;
    DSAccidentDetail.Left := 128;
    DSAccidentDetail.Top := 576;
    DSAccidentDetailAutoInc.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAutoInc.Name := 'DSAccidentDetailAutoInc';
    DSAccidentDetailAutoInc.FieldName := 'AutoInc';
    DSAccidentDetailAutoInc.Required := True;
    DSAccidentDetailAccidentNum.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentNum.Name := 'DSAccidentDetailAccidentNum';
    DSAccidentDetailAccidentNum.FieldName := 'AccidentNum';
    DSAccidentDetailAccidentDate.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDate.Name := 'DSAccidentDetailAccidentDate';
    DSAccidentDetailAccidentDate.FieldName := 'AccidentDate';
    DSAccidentDetailAccidentDate.Size := 15;
    DSAccidentDetailAccidentTime.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentTime.Name := 'DSAccidentDetailAccidentTime';
    DSAccidentDetailAccidentTime.FieldName := 'AccidentTime';
    DSAccidentDetailAccidentTime.Size := 15;
    DSAccidentDetailLocation.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailLocation.Name := 'DSAccidentDetailLocation';
    DSAccidentDetailLocation.FieldName := 'Location';
    DSAccidentDetailLocation.Size := 50;
    DSAccidentDetailIntersection.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailIntersection.Name := 'DSAccidentDetailIntersection';
    DSAccidentDetailIntersection.FieldName := 'Intersection';
    DSAccidentDetailIntersection.Size := 50;
    DSAccidentDetailCity.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCity.Name := 'DSAccidentDetailCity';
    DSAccidentDetailCity.FieldName := 'City';
    DSAccidentDetailCity.Size := 25;
    DSAccidentDetailCounty.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCounty.Name := 'DSAccidentDetailCounty';
    DSAccidentDetailCounty.FieldName := 'County';
    DSAccidentDetailState.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailState.Name := 'DSAccidentDetailState';
    DSAccidentDetailState.FieldName := 'State';
    DSAccidentDetailState.Size := 2;
    DSAccidentDetailPoliceInvestigation.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailPoliceInvestigation.Name := 'DSAccidentDetailPoliceInvestigation';
    DSAccidentDetailPoliceInvestigation.FieldName := 'PoliceInvestigation';
    DSAccidentDetailPoliceInvestigation.Size := 1;
    DSAccidentDetailIncidentNum.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailIncidentNum.Name := 'DSAccidentDetailIncidentNum';
    DSAccidentDetailIncidentNum.FieldName := 'IncidentNum';
    DSAccidentDetailIncidentNum.Size := 25;
    DSAccidentDetailDepartmentName.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailDepartmentName.Name := 'DSAccidentDetailDepartmentName';
    DSAccidentDetailDepartmentName.FieldName := 'DepartmentName';
    DSAccidentDetailDepartmentName.Size := 50;
    DSAccidentDetailCitations.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCitations.Name := 'DSAccidentDetailCitations';
    DSAccidentDetailCitations.FieldName := 'Citations';
    DSAccidentDetailCitations.Size := 255;
    DSAccidentDetailDirectionTravelling.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailDirectionTravelling.Name := 'DSAccidentDetailDirectionTravelling';
    DSAccidentDetailDirectionTravelling.FieldName := 'DirectionTravelling';
    DSAccidentDetailDirectionTravelling.Size := 2;
    DSAccidentDetailOVDirection.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVDirection.Name := 'DSAccidentDetailOVDirection';
    DSAccidentDetailOVDirection.FieldName := 'OVDirection';
    DSAccidentDetailOVDirection.Size := 2;
    DSAccidentDetailSpeed.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailSpeed.Name := 'DSAccidentDetailSpeed';
    DSAccidentDetailSpeed.FieldName := 'Speed';
    DSAccidentDetailSpeed.Size := 10;
    DSAccidentDetailOVSpeed.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVSpeed.Name := 'DSAccidentDetailOVSpeed';
    DSAccidentDetailOVSpeed.FieldName := 'OVSpeed';
    DSAccidentDetailOVSpeed.Size := 10;
    DSAccidentDetailSpeedLimit.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailSpeedLimit.Name := 'DSAccidentDetailSpeedLimit';
    DSAccidentDetailSpeedLimit.FieldName := 'SpeedLimit';
    DSAccidentDetailSpeedLimit.Size := 10;
    DSAccidentDetailTrafficControls.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailTrafficControls.Name := 'DSAccidentDetailTrafficControls';
    DSAccidentDetailTrafficControls.FieldName := 'TrafficControls';
    DSAccidentDetailTrafficControls.Size := 25;
    DSAccidentDetailOVTrafficControls.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVTrafficControls.Name := 'DSAccidentDetailOVTrafficControls';
    DSAccidentDetailOVTrafficControls.FieldName := 'OVTrafficControls';
    DSAccidentDetailOVTrafficControls.Size := 25;
    DSAccidentDetailHeadlights.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailHeadlights.Name := 'DSAccidentDetailHeadlights';
    DSAccidentDetailHeadlights.FieldName := 'Headlights';
    DSAccidentDetailHeadlights.Size := 25;
    DSAccidentDetailOVHeadlights.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVHeadlights.Name := 'DSAccidentDetailOVHeadlights';
    DSAccidentDetailOVHeadlights.FieldName := 'OVHeadlights';
    DSAccidentDetailOVHeadlights.Size := 25;
    DSAccidentDetailRoadCondition.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailRoadCondition.Name := 'DSAccidentDetailRoadCondition';
    DSAccidentDetailRoadCondition.FieldName := 'RoadCondition';
    DSAccidentDetailRoadCondition.Size := 15;
    DSAccidentDetailWeatherCondition.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailWeatherCondition.Name := 'DSAccidentDetailWeatherCondition';
    DSAccidentDetailWeatherCondition.FieldName := 'WeatherCondition';
    DSAccidentDetailWeatherCondition.Size := 15;
    DSAccidentDetailAccidentResponsibility.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentResponsibility.Name := 'DSAccidentDetailAccidentResponsibility';
    DSAccidentDetailAccidentResponsibility.FieldName := 'AccidentResponsibility';
    DSAccidentDetailAccidentResponsibility.Size := 255;
    DSAccidentDetailAccidentResponsibilityDetails.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentResponsibilityDetails.Name := 'DSAccidentDetailAccidentResponsibilityDetails';
    DSAccidentDetailAccidentResponsibilityDetails.FieldName := 'AccidentResponsibilityDetails';
    DSAccidentDetailAccidentResponsibilityDetails.Size := 255;
    DSAccidentDetailAccidentDescription.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDescription.Name := 'DSAccidentDetailAccidentDescription';
    DSAccidentDetailAccidentDescription.FieldName := 'AccidentDescription';
    DSAccidentDetailAccidentDescription.Size := 255;
    DSAccidentDetailAccidentDiagram.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDiagram.Name := 'DSAccidentDetailAccidentDiagram';
    DSAccidentDetailAccidentDiagram.FieldName := 'AccidentDiagram';
    DSAccidentDetailAccidentDiagram.Size := 255;
  finally
    AccPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebLabel27.AfterLoadDFMValues;
    WebLabel28.AfterLoadDFMValues;
    WebLabel29.AfterLoadDFMValues;
    WebLabel30.AfterLoadDFMValues;
    WebLabel31.AfterLoadDFMValues;
    WebLabel32.AfterLoadDFMValues;
    WebLabel33.AfterLoadDFMValues;
    WebLabel34.AfterLoadDFMValues;
    WebLabel35.AfterLoadDFMValues;
    WebLabel36.AfterLoadDFMValues;
    WebLabel37.AfterLoadDFMValues;
    WebLabel38.AfterLoadDFMValues;
    WebLabel39.AfterLoadDFMValues;
    WebLabel40.AfterLoadDFMValues;
    WebLabel41.AfterLoadDFMValues;
    WebLabel42.AfterLoadDFMValues;
    WebLabel43.AfterLoadDFMValues;
    WebLabel44.AfterLoadDFMValues;
    WebLabel45.AfterLoadDFMValues;
    WebLabel46.AfterLoadDFMValues;
    WebLabel48.AfterLoadDFMValues;
    WebLabel49.AfterLoadDFMValues;
    WebLabel53.AfterLoadDFMValues;
    WebLabel54.AfterLoadDFMValues;
    WebLabel47.AfterLoadDFMValues;
    EditTruck.AfterLoadDFMValues;
    EditTrailer1.AfterLoadDFMValues;
    EditTrailer2.AfterLoadDFMValues;
    EditMVDriverName.AfterLoadDFMValues;
    EditMVDriverCell.AfterLoadDFMValues;
    EditMVCustName.AfterLoadDFMValues;
    EditMVCustNum.AfterLoadDFMValues;
    EditMVCustCity.AfterLoadDFMValues;
    EditMVCustContact.AfterLoadDFMValues;
    EditMVCustPhone.AfterLoadDFMValues;
    CBSuperYes.AfterLoadDFMValues;
    EditSuperName.AfterLoadDFMValues;
    EditPassName.AfterLoadDFMValues;
    CBPassYes.AfterLoadDFMValues;
    EditPedName.AfterLoadDFMValues;
    CBPedInvolvedYes.AfterLoadDFMValues;
    EditOVOwner.AfterLoadDFMValues;
    CBOthVehYes.AfterLoadDFMValues;
    EditOVOwnerAddress.AfterLoadDFMValues;
    EditOVDriver.AfterLoadDFMValues;
    EditOVOwnerCity.AfterLoadDFMValues;
    EditOVOwnerState.AfterLoadDFMValues;
    EditOVOwnerZip.AfterLoadDFMValues;
    EditOVDriverAddress.AfterLoadDFMValues;
    EditOVDriverCity.AfterLoadDFMValues;
    EditOVDriverState.AfterLoadDFMValues;
    EditOVDriverZip.AfterLoadDFMValues;
    EditOVDriverPhone.AfterLoadDFMValues;
    EditOVDriverRelation.AfterLoadDFMValues;
    EditOVYear.AfterLoadDFMValues;
    EditOVMake.AfterLoadDFMValues;
    EditOVModel.AfterLoadDFMValues;
    EditOVLicPlate.AfterLoadDFMValues;
    EditOVLicPlateState.AfterLoadDFMValues;
    EditOVVin.AfterLoadDFMValues;
    EditOVInsuranceName.AfterLoadDFMValues;
    EditOVInsuranceClaim.AfterLoadDFMValues;
    EditWitnessName.AfterLoadDFMValues;
    EditWitnessAddress.AfterLoadDFMValues;
    EditWitnessPhone.AfterLoadDFMValues;
    CBWitPassYes.AfterLoadDFMValues;
    MemoDescription.AfterLoadDFMValues;
    MemoInjuries.AfterLoadDFMValues;
    MemoDamages.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    CBOwnDrv.AfterLoadDFMValues;
    EditOVOwnerPhone.AfterLoadDFMValues;
    DSAccidentHeader.AfterLoadDFMValues;
    DSAccidentHeaderAutoInc.AfterLoadDFMValues;
    DSAccidentHeaderTruck.AfterLoadDFMValues;
    DSAccidentHeaderTrailer1.AfterLoadDFMValues;
    DSAccidentHeaderTrailer2.AfterLoadDFMValues;
    DSAccidentHeaderDamages.AfterLoadDFMValues;
    DSAccidentHeaderDriver.AfterLoadDFMValues;
    DSAccidentHeaderCellNumber.AfterLoadDFMValues;
    DSAccidentHeaderMVCustName.AfterLoadDFMValues;
    DSAccidentHeaderMVCustNum.AfterLoadDFMValues;
    DSAccidentHeaderMVCustCity.AfterLoadDFMValues;
    DSAccidentHeaderMVCustContact.AfterLoadDFMValues;
    DSAccidentHeaderMVCustPhone.AfterLoadDFMValues;
    DSAccidentHeaderPicturesAvailable.AfterLoadDFMValues;
    DSAccidentHeaderNotifySupervisor.AfterLoadDFMValues;
    DSAccidentHeaderSupervisorName.AfterLoadDFMValues;
    DSAccidentHeaderPassengers.AfterLoadDFMValues;
    DSAccidentHeaderPedestrians.AfterLoadDFMValues;
    DSAccidentHeaderInjuries.AfterLoadDFMValues;
    DSAccidentHeaderOVOwner.AfterLoadDFMValues;
    DSAccidentHeaderOVAddress.AfterLoadDFMValues;
    DSAccidentHeaderOVCity.AfterLoadDFMValues;
    DSAccidentHeaderOVState.AfterLoadDFMValues;
    DSAccidentHeaderOVPhone.AfterLoadDFMValues;
    DSAccidentHeaderOVDriver.AfterLoadDFMValues;
    DSAccidentHeaderOVDriverAddress.AfterLoadDFMValues;
    DSAccidentHeaderOVDriverCity.AfterLoadDFMValues;
    DSAccidentHeaderOVDriverState.AfterLoadDFMValues;
    DSAccidentHeaderOVDriverRelation.AfterLoadDFMValues;
    DSAccidentHeaderOVInsurance.AfterLoadDFMValues;
    DSAccidentHeaderOVClaimNum.AfterLoadDFMValues;
    DSAccidentHeaderOVMake.AfterLoadDFMValues;
    DSAccidentHeaderOVModel.AfterLoadDFMValues;
    DSAccidentHeaderOVYear.AfterLoadDFMValues;
    DSAccidentHeaderOVLicensePlate.AfterLoadDFMValues;
    DSAccidentHeaderOVLicenseState.AfterLoadDFMValues;
    DSAccidentHeaderOVVin.AfterLoadDFMValues;
    DSAccidentHeaderOVDamages.AfterLoadDFMValues;
    DSAccidentHeaderWitnessName.AfterLoadDFMValues;
    DSAccidentHeaderWitnessAddress.AfterLoadDFMValues;
    DSAccidentHeaderWitnessPhone.AfterLoadDFMValues;
    DSAccidentHeaderWitnessPassenger.AfterLoadDFMValues;
    DSAccidentHeaderPassengerName.AfterLoadDFMValues;
    DSAccidentHeaderPedestrianName.AfterLoadDFMValues;
    DSAccidentPhotos.AfterLoadDFMValues;
    DSAccidentDetail.AfterLoadDFMValues;
    DSAccidentDetailAutoInc.AfterLoadDFMValues;
    DSAccidentDetailAccidentNum.AfterLoadDFMValues;
    DSAccidentDetailAccidentDate.AfterLoadDFMValues;
    DSAccidentDetailAccidentTime.AfterLoadDFMValues;
    DSAccidentDetailLocation.AfterLoadDFMValues;
    DSAccidentDetailIntersection.AfterLoadDFMValues;
    DSAccidentDetailCity.AfterLoadDFMValues;
    DSAccidentDetailCounty.AfterLoadDFMValues;
    DSAccidentDetailState.AfterLoadDFMValues;
    DSAccidentDetailPoliceInvestigation.AfterLoadDFMValues;
    DSAccidentDetailIncidentNum.AfterLoadDFMValues;
    DSAccidentDetailDepartmentName.AfterLoadDFMValues;
    DSAccidentDetailCitations.AfterLoadDFMValues;
    DSAccidentDetailDirectionTravelling.AfterLoadDFMValues;
    DSAccidentDetailOVDirection.AfterLoadDFMValues;
    DSAccidentDetailSpeed.AfterLoadDFMValues;
    DSAccidentDetailOVSpeed.AfterLoadDFMValues;
    DSAccidentDetailSpeedLimit.AfterLoadDFMValues;
    DSAccidentDetailTrafficControls.AfterLoadDFMValues;
    DSAccidentDetailOVTrafficControls.AfterLoadDFMValues;
    DSAccidentDetailHeadlights.AfterLoadDFMValues;
    DSAccidentDetailOVHeadlights.AfterLoadDFMValues;
    DSAccidentDetailRoadCondition.AfterLoadDFMValues;
    DSAccidentDetailWeatherCondition.AfterLoadDFMValues;
    DSAccidentDetailAccidentResponsibility.AfterLoadDFMValues;
    DSAccidentDetailAccidentResponsibilityDetails.AfterLoadDFMValues;
    DSAccidentDetailAccidentDescription.AfterLoadDFMValues;
    DSAccidentDetailAccidentDiagram.AfterLoadDFMValues;
  end;
end;

end.
