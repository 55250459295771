unit SignatureCap;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, VCL.TMSFNCTypes, VCL.TMSFNCUtils,
  VCL.TMSFNCGraphics, VCL.TMSFNCGraphicsTypes, VCL.Controls,
  VCL.TMSFNCCustomControl, WEBLib.ExtCtrls,
  VCL.StdCtrls, WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.DB, VCL.TMSFNCSignatureCapture, VCL.TMSFNCImage;

type
  TFSignatureCap = class(TForm)
    SigPnl: TPanel;
    SigCap: TTMSFNCSignatureCapture;
    WebButton1: TButton;
    WebButton2: TButton;
    WDSSignatures: TDataSource;
    DSSignatures: TXDataWebDataSet;
    DSSignaturesAutoInc: TIntegerField;
    DSSignaturesOrdernum: TIntegerField;
    WIC: TImageControl;
    DSSignaturesSignature: TStringField;
    DSArrivalTime: TXDataWebDataSet;
    DSArrivalTimeAutoInc: TIntegerField;
    DSArrivalTimeOrderNum: TIntegerField;
    DSArrivalTimeActualArriveTime: TDateTimeField;
    WDSArrivalTime: TDataSource;
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FSignatureCap: TFSignatureCap;
  ms: TStream;

implementation

Uses Data, OrderDetail, CurrentStop;

{$R *.dfm}

procedure TFSignatureCap.WebButton1Click(Sender: TObject);
begin
  //FSignatureCap.Close;
  // FSignatureCap.Free;
  Application.CreateForm(TFOrderDtl, FOrderDtl);
end;

procedure TFSignatureCap.WebButton2Click(Sender: TObject);
begin
  DSSignatures.Append;
  DSSignaturesOrdernum.AsInteger := Dm.DSCurrStopOrdersOrderNum.AsInteger;
  DSSignaturesSignature.Value := SigCap.GetBase64Img;
  DSSignatures.Post;
  DSSignatures.ApplyUpdates;
  //FSignatureCap.Close;
  DSArrivalTime.Edit;
  DSArrivalTimeActualArriveTime.AsDateTime := Now;
  DSArrivalTime.Post;
  DSArrivalTime.ApplyUpdates;
  Dm.DSCurrStopOrders.Edit;
  Dm.DSCurrStopOrdersActualArriveTime.AsDateTime := Now;
  Dm.DSCurrStopOrders.Post;
  Dm.DSCurrStopOrders.ApplyUpdates;
  //FOrderDtl.Close;
  // FOrderDetail.Free;
  Application.CreateForm(TFCurrentStop, FCurrentStop);
end;

procedure TFSignatureCap.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'SignatureCap' then
  begin
    { FSignatureCap.Height := 699;
      FSignatureCap.Width := 393; }
    //FSignatureCap.Close;
    Application.CreateForm(TFSignatureCap, FSignatureCap);
  end;
end;

procedure TFSignatureCap.WebFormResize(Sender: TObject);
begin
  SigPnl.Left := Round((FSignatureCap.Width / 2) - (SigPnl.Width / 2));
  // SigPnl.Top := Round((FSignatureCap.Height/2)-(SigPnl.Height/2));
end;

procedure TFSignatureCap.WebFormShow(Sender: TObject);
begin
  { FSignatureCap.Height := 699;
    FSignatureCap.Width := 393; }
  SigPnl.Left := Round((FSignatureCap.Width / 2) - (SigPnl.Width / 2));
  SigPnl.Top := Round((FSignatureCap.Height / 2) - (SigPnl.Height / 2));
  CurrentForm := 'SignatureCap';
  DSArrivalTime.QueryString := '$filter=(OrderNum eq ' +
    Dm.DSCurrStopOrdersOrderNum.AsString + ')';
  DSArrivalTime.Load;
  DSSignatures.QueryString := '$filter=(OrderNum eq 0)';
  DSSignatures.Load;
  ms := TMemoryStream.Create;
end;

procedure TFSignatureCap.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SigPnl := TPanel.Create(Self);
  WIC := TImageControl.Create(Self);
  SigCap := TTMSFNCSignatureCapture.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WDSSignatures := TDataSource.Create(Self);
  DSSignatures := TXDataWebDataSet.Create(Self);
  DSSignaturesAutoInc := TIntegerField.Create(Self);
  DSSignaturesOrdernum := TIntegerField.Create(Self);
  DSSignaturesSignature := TStringField.Create(Self);
  DSArrivalTime := TXDataWebDataSet.Create(Self);
  DSArrivalTimeAutoInc := TIntegerField.Create(Self);
  DSArrivalTimeOrderNum := TIntegerField.Create(Self);
  DSArrivalTimeActualArriveTime := TDateTimeField.Create(Self);
  WDSArrivalTime := TDataSource.Create(Self);

  SigPnl.BeforeLoadDFMValues;
  WIC.BeforeLoadDFMValues;
  SigCap.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WDSSignatures.BeforeLoadDFMValues;
  DSSignatures.BeforeLoadDFMValues;
  DSSignaturesAutoInc.BeforeLoadDFMValues;
  DSSignaturesOrdernum.BeforeLoadDFMValues;
  DSSignaturesSignature.BeforeLoadDFMValues;
  DSArrivalTime.BeforeLoadDFMValues;
  DSArrivalTimeAutoInc.BeforeLoadDFMValues;
  DSArrivalTimeOrderNum.BeforeLoadDFMValues;
  DSArrivalTimeActualArriveTime.BeforeLoadDFMValues;
  WDSArrivalTime.BeforeLoadDFMValues;
  try
    Name := 'FSignatureCap';
    Width := 412;
    Height := 733;
    Align := alClient;
    ElementClassName := 'container-fluid';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    SigPnl.SetParentComponent(Self);
    SigPnl.Name := 'SigPnl';
    SigPnl.Left := 8;
    SigPnl.Top := 8;
    SigPnl.Width := 396;
    SigPnl.Height := 713;
    SigPnl.ElementClassName := 'container-fluid';
    SigPnl.ChildOrder := 1;
    WIC.SetParentComponent(SigPnl);
    WIC.Name := 'WIC';
    WIC.Left := 290;
    WIC.Top := 565;
    WIC.Width := 100;
    WIC.Height := 41;
    WIC.HeightPercent := 100.000000000000000000;
    WIC.WidthPercent := 100.000000000000000000;
    WIC.ChildOrder := 3;
    WIC.Visible := False;
    SigCap.SetParentComponent(SigPnl);
    SigCap.Name := 'SigCap';
    SigCap.Left := 9;
    SigCap.Top := 6;
    SigCap.Width := 376;
    SigCap.Height := 643;
    SigCap.ParentDoubleBuffered := False;
    SigCap.DoubleBuffered := True;
    SigCap.Font.Charset := DEFAULT_CHARSET;
    SigCap.Font.Color := clWindowText;
    SigCap.Font.Height := -11;
    SigCap.Font.Name := 'Tahoma';
    SigCap.Font.Style := [];
    SigCap.TabOrder := 0;
    SigCap.Pen.Color := clBlack;
    SigCap.TextPosition.Line.Color := clGray;
    SigCap.ClearSig.Image.LoadFromFile('SignatureCap.SigPnl.SigCap.ClearSig.Image.png');
    WebButton1.SetParentComponent(SigPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 21;
    WebButton1.Top := 655;
    WebButton1.Width := 158;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Cancel';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-danger';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(SigPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 216;
    WebButton2.Top := 655;
    WebButton2.Width := 158;
    WebButton2.Height := 41;
    WebButton2.Caption := 'Save';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WDSSignatures.SetParentComponent(Self);
    WDSSignatures.Name := 'WDSSignatures';
    WDSSignatures.DataSet := DSSignatures;
    WDSSignatures.Left := 304;
    WDSSignatures.Top := 640;
    DSSignatures.SetParentComponent(Self);
    DSSignatures.Name := 'DSSignatures';
    DSSignatures.EntitySetName := 'signatures';
    DSSignatures.Connection := Dm.ApiConnection;
    DSSignatures.Left := 248;
    DSSignatures.Top := 648;
    DSSignaturesAutoInc.SetParentComponent(DSSignatures);
    DSSignaturesAutoInc.Name := 'DSSignaturesAutoInc';
    DSSignaturesAutoInc.FieldName := 'AutoInc';
    DSSignaturesAutoInc.Required := True;
    DSSignaturesOrdernum.SetParentComponent(DSSignatures);
    DSSignaturesOrdernum.Name := 'DSSignaturesOrdernum';
    DSSignaturesOrdernum.FieldName := 'Ordernum';
    DSSignaturesSignature.SetParentComponent(DSSignatures);
    DSSignaturesSignature.Name := 'DSSignaturesSignature';
    DSSignaturesSignature.FieldName := 'Signature';
    DSSignaturesSignature.Size := 255;
    DSArrivalTime.SetParentComponent(Self);
    DSArrivalTime.Name := 'DSArrivalTime';
    DSArrivalTime.EntitySetName := 'arrivaltime';
    DSArrivalTime.Connection := Dm.ApiConnection;
    DSArrivalTime.Left := 72;
    DSArrivalTime.Top := 640;
    DSArrivalTimeAutoInc.SetParentComponent(DSArrivalTime);
    DSArrivalTimeAutoInc.Name := 'DSArrivalTimeAutoInc';
    DSArrivalTimeAutoInc.FieldName := 'AutoInc';
    DSArrivalTimeAutoInc.Required := True;
    DSArrivalTimeOrderNum.SetParentComponent(DSArrivalTime);
    DSArrivalTimeOrderNum.Name := 'DSArrivalTimeOrderNum';
    DSArrivalTimeOrderNum.FieldName := 'OrderNum';
    DSArrivalTimeActualArriveTime.SetParentComponent(DSArrivalTime);
    DSArrivalTimeActualArriveTime.Name := 'DSArrivalTimeActualArriveTime';
    DSArrivalTimeActualArriveTime.FieldName := 'ActualArriveTime';
    WDSArrivalTime.SetParentComponent(Self);
    WDSArrivalTime.Name := 'WDSArrivalTime';
    WDSArrivalTime.DataSet := DSArrivalTime;
    WDSArrivalTime.Left := 136;
    WDSArrivalTime.Top := 648;
  finally
    SigPnl.AfterLoadDFMValues;
    WIC.AfterLoadDFMValues;
    SigCap.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WDSSignatures.AfterLoadDFMValues;
    DSSignatures.AfterLoadDFMValues;
    DSSignaturesAutoInc.AfterLoadDFMValues;
    DSSignaturesOrdernum.AfterLoadDFMValues;
    DSSignaturesSignature.AfterLoadDFMValues;
    DSArrivalTime.AfterLoadDFMValues;
    DSArrivalTimeAutoInc.AfterLoadDFMValues;
    DSArrivalTimeOrderNum.AfterLoadDFMValues;
    DSArrivalTimeActualArriveTime.AfterLoadDFMValues;
    WDSArrivalTime.AfterLoadDFMValues;
  end;
end;

end.
