unit Returns;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Devices, WEBLib.ExtCtrls, Vcl.Grids, WEBLib.DBCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, StrUtils;

type
  TFReturns = class(TForm)
    ReturnsPnl: TPanel;
    WebButton4: TButton;
    WebDBLabel1: TDBLabel;
    WebLabel1: TLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel5: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebComboBox1: TComboBox;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    EditReturnQty: TEdit;
    WebButton1: TButton;
    DSReturns: TXDataWebDataSet;
    WDSReturns: TDataSource;
    DSReturnsAutoInc: TIntegerField;
    DSReturnsOrderNum: TIntegerField;
    DSReturnsProd: TIntegerField;
    DSReturnsLineNum: TIntegerField;
    DSReturnsQty: TIntegerField;
    DSReturnsReasonCode: TIntegerField;
    DSReturnsReturningToMV: TStringField;
    WebCheckBox1: TCheckBox;
    DSReturnsNotes: TStringField;
    WebLabel5: TLabel;
    WebMemo1: TMemo;
    DSLines: TXDataWebDataSet;
    DSLinesOrderNum: TIntegerField;
    DSLinesLineNum: TIntegerField;
    DSLinesProd: TIntegerField;
    DSLinesCust: TIntegerField;
    DSLinesQtyShip: TIntegerField;
    DSLinesStatus: TIntegerField;
    DSLinesSlot: TStringField;
    DSLinesCatchWeight: TFloatField;
    DSLinesProdUnit: TStringField;
    DSLinesProdSize: TStringField;
    DSLinesProdDescription: TStringField;
    DSLinesProdBrand: TStringField;
    DSLinesProdWeightEst: TFloatField;
    DSLinesProdDepFlag: TStringField;
    DSLinesProdTaxFlag: TStringField;
    DSLinesProdCWFlag: TStringField;
    DSLinesRealUnitPrice: TFloatField;
    DSLinesRebateAmount: TFloatField;
    DSLinesAllowanceAmount: TFloatField;
    DSLinesNOIAmount: TFloatField;
    DSLinesNOIComm: TStringField;
    DSLinesNOIRemaining: TFloatField;
    DSLinesNOIUsed: TFloatField;
    DSLinesTaxAmt: TFloatField;
    DSLinesLineShowAmount: TFloatField;
    DSLinesQtyOrd: TIntegerField;
    DSLinesDepositAmount: TFloatField;
    DSLinesCreditRequested: TStringField;
    WDSLines: TDataSource;
    WDSLabels: TDataSource;
    DSLabels: TXDataWebDataSet;
    DSLabelsAssignNum: TIntegerField;
    DSLabelsRPNum: TIntegerField;
    DSLabelsOrderNum: TIntegerField;
    DSLabelsLineNum: TIntegerField;
    DSLabelsUnit_: TStringField;
    DSLabelsSize: TStringField;
    DSLabelsBrand: TStringField;
    DSLabelsDescription: TStringField;
    DSLabelsDriverScan: TStringField;
    DSLabelsTruck: TIntegerField;
    DSLabelsStop: TIntegerField;
    DSLabelsProd: TIntegerField;
    WebLabel9: TLabel;
    ProdScan: TEdit;
    DSReturnsPickTagID: TStringField;
    procedure WebButton4Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure DSReturnsAfterOpen(Dataset: TDataSet);
    procedure WebButton1Click(Sender: TObject);
    procedure FindProdFromLabel();
    procedure ProdScanKeyPress(Sender: TObject; var Key: Char);
    procedure DSLabelsAfterOpen(DataSet: TDataSet);
    procedure ProdScanEnter(Sender: TObject);
    procedure ProdScanExit(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FReturns: TFReturns;
  BCAssign,BCRPNum:String;

implementation

Uses Data, OrderDetail, ProdScanCamera;

{$R *.dfm}

procedure TFReturns.DSLabelsAfterOpen(DataSet: TDataSet);
begin
  if DSLabels.RecordCount > 0 then
  begin
    {DSLines.QueryString := '$filter=(OrderNum eq ' +
      DSLabelsOrderNum.AsString + ' and LineNum eq ' +
      DSLabelsLineNum.AsString + ')';
    DSLines.Load;    }
    DSReturns.QueryString := '$filter=(OrderNum eq ' +
      DSLabelsOrderNum.AsString + ' and LineNum eq ' +
      DSLabelsLineNum.AsString + ')';
    DSReturns.Load;
  end
  else
  begin
    ShowMessage('Invalid Scan - Scan Again');
  end;
end;

procedure TFReturns.DSReturnsAfterOpen(Dataset: TDataSet);
begin
  {if DSReturns.RecordCount > 0 then
  begin
    ShowMessage('RFC Already Entered For Item');
    Application.CreateForm(TFOrderDtl, FOrderDtl);
  end
  else
    EditReturnQty.SetFocus;  }
  EditReturnQty.SetFocus;
end;

procedure TFReturns.WebButton4Click(Sender: TObject);
begin
  DSReturns.Close;
  DSLabels.Close;
  //DSLines.Close;
  ProdScan.Clear;
  EditReturnQty.Clear;
  WebMemo1.Lines.Clear;
  WebCheckBox1.Checked := True;
  WebComboBox1.ItemIndex := 0;
  Application.CreateForm(TFOrderDtl, FOrderDtl);
end;

procedure TFReturns.WebButton1Click(Sender: TObject);
begin
  if EditReturnQty.Text = '' then
  begin
    ShowMessage('Must Enter Qty');
    Exit;
  end;
  if WebComboBox1.ItemIndex <= 0 then
  begin
    ShowMessage('Select Credit Reason');
    Exit;
  end;
  if ProdScan.Text = '' then
  begin
    ShowMessage('Must Scan Tag or Select from Scan Items Tab');
    Exit;
  end;
  if DSLabels.RecordCount = 0 then
  begin
    ShowMessage('Must scan valid pick label');
    Exit;
  end;
  {if DSLabelsOrderNum.AsString <> DSLinesOrderNum.AsString then
  begin
    ShowMessage('Order Not Found - Check Label');
    Exit;
  end;}
  DSReturns.Append;
  DSReturnsOrderNum.AsInteger := DSLabelsOrderNum.AsInteger;
  DSReturnsProd.AsInteger := DSLabelsProd.AsInteger;
  DSReturnsLineNum.AsInteger := DSLabelsLineNum.AsInteger;
  DSReturnsQty.AsString := EditReturnQty.Text;
  if WebCheckBox1.Checked = True then
    DSReturnsReturningToMV.AsString := 'Y'
  else
    DSReturnsReturningToMV.AsString := 'N';
  DSReturnsReasonCode.AsString :=
    Trim(RightStr(WebComboBox1.Items[WebComboBox1.ItemIndex], 2));
  DSReturnsNotes.AsString := WebMemo1.Lines.Text;
  DSReturnsPickTagID.AsString := ProdScan.Text;
  DSReturns.Post;
  DSReturns.ApplyUpdates;
  EditReturnQty.Text := '';
  DSLabels.Edit;
  DSLabelsDriverScan.AsString := 'R';
  DSLabels.Post;
  DSLabels.ApplyUpdates;
  WebMemo1.Lines.Clear;
  WebCheckBox1.Checked := True;
  WebComboBox1.ItemIndex := 0;
  ShowMessage('RFC Created Successfully');
  Application.CreateForm(TFOrderDtl, FOrderDtl);
end;

procedure TFReturns.WebFormResize(Sender: TObject);
begin
  ReturnsPnl.Left := Round((FReturns.Width / 2) - (ReturnsPnl.Width / 2));
  if ReturnsPnl.Top < 0 then
    ReturnsPnl.Top := 0;
end;

procedure TFReturns.FindProdFromLabel;
var
  X:Integer;
begin
  BCAssign := '';
  BCRPNum := '';
  if (ProdScan.Text = '') or (Length(ProdScan.Text) <= 6) or not (AnsiContainsStr(ProdScan.Text,'.')) then
  begin
    //ShowMessage('Invalid Scan');
    //WebLabel2.Caption := 'Invalid Barcode';
    ShowMessage('Invalid Barcode');
  end
  else
  begin
    //Store RP and Assign#
    BCRPNum := RightStr(ProdScan.Text,6);
    BCAssign := LeftStr(ProdScan.Text,Pos('.',ProdScan.Text)-1);
    //OrigQuery := DSLabels.QueryString;
    Try
      DSLabels.Close;
    Except
      ShowMessage('Error Opening Labels Database - Try Again');
      Exit;
    End;
    //Loads only the scanned tag and only if it is on current truck/stop combination
    DSLabels.QueryString := '$filter=(AssignNum eq '+BCAssign+' AND RPNum eq '+BCRPNum+')&$orderby=RPNum';
    DSLabels.Load;
  end;
end;

procedure TFReturns.ProdScanEnter(Sender: TObject);
begin
  ProdScan.Color := clRed;
end;

procedure TFReturns.ProdScanExit(Sender: TObject);
begin
  ProdScan.Color := clWhite;
end;

procedure TFReturns.ProdScanKeyPress(Sender: TObject; var Key: Char);
begin
  if Key = #13 then
    FindProdFromLabel();
end;

procedure TFReturns.WebFormShow(Sender: TObject);
begin
  Try
    if (ReturnAssign <> '') and (ReturnRP <> '') then
    begin
      ProdScan.Text := ReturnAssign+'.'+LeftStr('000000',6-Length(ReturnRP))+ReturnRP;
    end;
    ReturnAssign := '';
    ReturnRP := '';
  Except
  End;
  if ProdScan.Text <> '' then
  begin
    FindProdFromLabel();
  end
  else ProdScan.SetFocus;
  ReturnsPnl.Left := Round((FReturns.Width / 2) - (ReturnsPnl.Width / 2));
  ReturnsPnl.Top := Round((FReturns.Height / 2) - (ReturnsPnl.Height / 2));
end;

procedure TFReturns.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ReturnsPnl := TPanel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  EditReturnQty := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  ProdScan := TEdit.Create(Self);
  DSReturns := TXDataWebDataSet.Create(Self);
  DSReturnsAutoInc := TIntegerField.Create(Self);
  DSReturnsOrderNum := TIntegerField.Create(Self);
  DSReturnsProd := TIntegerField.Create(Self);
  DSReturnsLineNum := TIntegerField.Create(Self);
  DSReturnsQty := TIntegerField.Create(Self);
  DSReturnsReasonCode := TIntegerField.Create(Self);
  DSReturnsReturningToMV := TStringField.Create(Self);
  DSReturnsNotes := TStringField.Create(Self);
  DSReturnsPickTagID := TStringField.Create(Self);
  WDSReturns := TDataSource.Create(Self);
  DSLines := TXDataWebDataSet.Create(Self);
  DSLinesOrderNum := TIntegerField.Create(Self);
  DSLinesLineNum := TIntegerField.Create(Self);
  DSLinesProd := TIntegerField.Create(Self);
  DSLinesCust := TIntegerField.Create(Self);
  DSLinesQtyShip := TIntegerField.Create(Self);
  DSLinesStatus := TIntegerField.Create(Self);
  DSLinesSlot := TStringField.Create(Self);
  DSLinesCatchWeight := TFloatField.Create(Self);
  DSLinesProdUnit := TStringField.Create(Self);
  DSLinesProdSize := TStringField.Create(Self);
  DSLinesProdDescription := TStringField.Create(Self);
  DSLinesProdBrand := TStringField.Create(Self);
  DSLinesProdWeightEst := TFloatField.Create(Self);
  DSLinesProdDepFlag := TStringField.Create(Self);
  DSLinesProdTaxFlag := TStringField.Create(Self);
  DSLinesProdCWFlag := TStringField.Create(Self);
  DSLinesRealUnitPrice := TFloatField.Create(Self);
  DSLinesRebateAmount := TFloatField.Create(Self);
  DSLinesAllowanceAmount := TFloatField.Create(Self);
  DSLinesNOIAmount := TFloatField.Create(Self);
  DSLinesNOIComm := TStringField.Create(Self);
  DSLinesNOIRemaining := TFloatField.Create(Self);
  DSLinesNOIUsed := TFloatField.Create(Self);
  DSLinesTaxAmt := TFloatField.Create(Self);
  DSLinesLineShowAmount := TFloatField.Create(Self);
  DSLinesQtyOrd := TIntegerField.Create(Self);
  DSLinesDepositAmount := TFloatField.Create(Self);
  DSLinesCreditRequested := TStringField.Create(Self);
  WDSLines := TDataSource.Create(Self);
  WDSLabels := TDataSource.Create(Self);
  DSLabels := TXDataWebDataSet.Create(Self);
  DSLabelsAssignNum := TIntegerField.Create(Self);
  DSLabelsRPNum := TIntegerField.Create(Self);
  DSLabelsOrderNum := TIntegerField.Create(Self);
  DSLabelsLineNum := TIntegerField.Create(Self);
  DSLabelsUnit_ := TStringField.Create(Self);
  DSLabelsSize := TStringField.Create(Self);
  DSLabelsBrand := TStringField.Create(Self);
  DSLabelsDescription := TStringField.Create(Self);
  DSLabelsDriverScan := TStringField.Create(Self);
  DSLabelsTruck := TIntegerField.Create(Self);
  DSLabelsStop := TIntegerField.Create(Self);
  DSLabelsProd := TIntegerField.Create(Self);

  ReturnsPnl.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  EditReturnQty.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  ProdScan.BeforeLoadDFMValues;
  DSReturns.BeforeLoadDFMValues;
  DSReturnsAutoInc.BeforeLoadDFMValues;
  DSReturnsOrderNum.BeforeLoadDFMValues;
  DSReturnsProd.BeforeLoadDFMValues;
  DSReturnsLineNum.BeforeLoadDFMValues;
  DSReturnsQty.BeforeLoadDFMValues;
  DSReturnsReasonCode.BeforeLoadDFMValues;
  DSReturnsReturningToMV.BeforeLoadDFMValues;
  DSReturnsNotes.BeforeLoadDFMValues;
  DSReturnsPickTagID.BeforeLoadDFMValues;
  WDSReturns.BeforeLoadDFMValues;
  DSLines.BeforeLoadDFMValues;
  DSLinesOrderNum.BeforeLoadDFMValues;
  DSLinesLineNum.BeforeLoadDFMValues;
  DSLinesProd.BeforeLoadDFMValues;
  DSLinesCust.BeforeLoadDFMValues;
  DSLinesQtyShip.BeforeLoadDFMValues;
  DSLinesStatus.BeforeLoadDFMValues;
  DSLinesSlot.BeforeLoadDFMValues;
  DSLinesCatchWeight.BeforeLoadDFMValues;
  DSLinesProdUnit.BeforeLoadDFMValues;
  DSLinesProdSize.BeforeLoadDFMValues;
  DSLinesProdDescription.BeforeLoadDFMValues;
  DSLinesProdBrand.BeforeLoadDFMValues;
  DSLinesProdWeightEst.BeforeLoadDFMValues;
  DSLinesProdDepFlag.BeforeLoadDFMValues;
  DSLinesProdTaxFlag.BeforeLoadDFMValues;
  DSLinesProdCWFlag.BeforeLoadDFMValues;
  DSLinesRealUnitPrice.BeforeLoadDFMValues;
  DSLinesRebateAmount.BeforeLoadDFMValues;
  DSLinesAllowanceAmount.BeforeLoadDFMValues;
  DSLinesNOIAmount.BeforeLoadDFMValues;
  DSLinesNOIComm.BeforeLoadDFMValues;
  DSLinesNOIRemaining.BeforeLoadDFMValues;
  DSLinesNOIUsed.BeforeLoadDFMValues;
  DSLinesTaxAmt.BeforeLoadDFMValues;
  DSLinesLineShowAmount.BeforeLoadDFMValues;
  DSLinesQtyOrd.BeforeLoadDFMValues;
  DSLinesDepositAmount.BeforeLoadDFMValues;
  DSLinesCreditRequested.BeforeLoadDFMValues;
  WDSLines.BeforeLoadDFMValues;
  WDSLabels.BeforeLoadDFMValues;
  DSLabels.BeforeLoadDFMValues;
  DSLabelsAssignNum.BeforeLoadDFMValues;
  DSLabelsRPNum.BeforeLoadDFMValues;
  DSLabelsOrderNum.BeforeLoadDFMValues;
  DSLabelsLineNum.BeforeLoadDFMValues;
  DSLabelsUnit_.BeforeLoadDFMValues;
  DSLabelsSize.BeforeLoadDFMValues;
  DSLabelsBrand.BeforeLoadDFMValues;
  DSLabelsDescription.BeforeLoadDFMValues;
  DSLabelsDriverScan.BeforeLoadDFMValues;
  DSLabelsTruck.BeforeLoadDFMValues;
  DSLabelsStop.BeforeLoadDFMValues;
  DSLabelsProd.BeforeLoadDFMValues;
  try
    Name := 'FReturns';
    Width := 421;
    Height := 726;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ReturnsPnl.SetParentComponent(Self);
    ReturnsPnl.Name := 'ReturnsPnl';
    ReturnsPnl.Left := 8;
    ReturnsPnl.Top := 8;
    ReturnsPnl.Width := 401;
    ReturnsPnl.Height := 710;
    ReturnsPnl.ElementClassName := 'card';
    ReturnsPnl.ElementBodyClassName := 'card-body';
    ReturnsPnl.Font.Charset := DEFAULT_CHARSET;
    ReturnsPnl.Font.Color := clWindowText;
    ReturnsPnl.Font.Height := -16;
    ReturnsPnl.Font.Name := 'Arial';
    ReturnsPnl.Font.Style := [];
    ReturnsPnl.ParentFont := False;
    WebDBLabel1.SetParentComponent(ReturnsPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 74;
    WebDBLabel1.Top := 271;
    WebDBLabel1.Width := 27;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'Unit';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -16;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Unit_';
    WebDBLabel1.DataSource := WDSLabels;
    WebLabel1.SetParentComponent(ReturnsPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 62;
    WebLabel1.Top := 223;
    WebLabel1.Width := 117;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Product Details';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel3.SetParentComponent(ReturnsPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 130;
    WebDBLabel3.Top := 271;
    WebDBLabel3.Width := 31;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'Size';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Size';
    WebDBLabel3.DataSource := WDSLabels;
    WebDBLabel5.SetParentComponent(ReturnsPnl);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 210;
    WebDBLabel5.Top := 271;
    WebDBLabel5.Width := 42;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'Brand';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Arial';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'Brand';
    WebDBLabel5.DataSource := WDSLabels;
    WebDBLabel6.SetParentComponent(ReturnsPnl);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 130;
    WebDBLabel6.Top := 247;
    WebDBLabel6.Width := 80;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := 'Description';
    WebDBLabel6.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel6.Font.Color := clWindowText;
    WebDBLabel6.Font.Height := -16;
    WebDBLabel6.Font.Name := 'Arial';
    WebDBLabel6.Font.Style := [];
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.ParentFont := False;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'Description';
    WebDBLabel6.DataSource := WDSLabels;
    WebDBLabel2.SetParentComponent(ReturnsPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 74;
    WebDBLabel2.Top := 247;
    WebDBLabel2.Width := 34;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'Prod';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -16;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'Prod';
    WebDBLabel2.DataSource := WDSLabels;
    WebLabel3.SetParentComponent(ReturnsPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 38;
    WebLabel3.Top := 383;
    WebLabel3.Width := 155;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Select Reason Code';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ReturnsPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 62;
    WebLabel4.Top := 311;
    WebLabel4.Width := 109;
    WebLabel4.Height := 19;
    WebLabel4.Caption := 'Enter Quantity';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [fsBold];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(ReturnsPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 38;
    WebLabel5.Top := 455;
    WebLabel5.Width := 324;
    WebLabel5.Height := 19;
    WebLabel5.Caption := 'Enter Notes/Qty Info (Ex- 2 Cans Returned)';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [fsBold];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(ReturnsPnl);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 17;
    WebLabel9.Top := 15;
    WebLabel9.Width := 150;
    WebLabel9.Height := 19;
    WebLabel9.Caption := 'Scan Product Label';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [fsBold];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebButton4.SetParentComponent(ReturnsPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 238;
    WebButton4.Top := 622;
    WebButton4.Width := 137;
    WebButton4.Height := 57;
    WebButton4.Caption := 'Close';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-danger';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.ParentFont := False;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebComboBox1.SetParentComponent(ReturnsPnl);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 50;
    WebComboBox1.Top := 408;
    WebComboBox1.Width := 295;
    WebComboBox1.Height := 26;
    WebComboBox1.HeightPercent := 100.000000000000000000;
    WebComboBox1.Text := '---';
    WebComboBox1.WidthPercent := 100.000000000000000000;
    WebComboBox1.ItemIndex := -1;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('---');
      WebComboBox1.Items.Add('Pickup - 00');
      WebComboBox1.Items.Add('Not On Truck - 01');
      WebComboBox1.Items.Add('Put Up Wrong - 02');
      WebComboBox1.Items.Add('Returned Sub - 03');
      WebComboBox1.Items.Add('Damaged - 04');
      WebComboBox1.Items.Add('Office Mistake - 05');
      WebComboBox1.Items.Add('Sales Mistake - 06');
      WebComboBox1.Items.Add('Customer Did Not Want - 07');
      WebComboBox1.Items.Add('Driver Missed - 08');
      WebComboBox1.Items.Add('Other/Misc - 09');
      WebComboBox1.Items.Add('Outdated/Quality Issue- 15');
      WebComboBox1.Items.Add('Pricing Issue - 22');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    EditReturnQty.SetParentComponent(ReturnsPnl);
    EditReturnQty.Name := 'EditReturnQty';
    EditReturnQty.Left := 74;
    EditReturnQty.Top := 336;
    EditReturnQty.Width := 71;
    EditReturnQty.Height := 33;
    EditReturnQty.ChildOrder := 12;
    EditReturnQty.EditType := weNumeric;
    EditReturnQty.HeightPercent := 100.000000000000000000;
    EditReturnQty.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(ReturnsPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 30;
    WebButton1.Top := 622;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Add RFC';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebCheckBox1.SetParentComponent(ReturnsPnl);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 210;
    WebCheckBox1.Top := 338;
    WebCheckBox1.Width := 121;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'Returning Item';
    WebCheckBox1.Checked := True;
    WebCheckBox1.ChildOrder := 14;
    WebCheckBox1.HeightPercent := 100.000000000000000000;
    WebCheckBox1.State := cbChecked;
    WebCheckBox1.WidthPercent := 100.000000000000000000;
    WebMemo1.SetParentComponent(ReturnsPnl);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 50;
    WebMemo1.Top := 480;
    WebMemo1.Width := 295;
    WebMemo1.Height := 121;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    ProdScan.SetParentComponent(ReturnsPnl);
    ProdScan.Name := 'ProdScan';
    ProdScan.Left := 40;
    ProdScan.Top := 40;
    ProdScan.Width := 313;
    ProdScan.Height := 33;
    ProdScan.ChildOrder := 2;
    ProdScan.Font.Charset := DEFAULT_CHARSET;
    ProdScan.Font.Color := clWindowText;
    ProdScan.Font.Height := -16;
    ProdScan.Font.Name := 'Arial';
    ProdScan.Font.Style := [];
    ProdScan.HeightPercent := 100.000000000000000000;
    ProdScan.ParentFont := False;
    ProdScan.SpellCheck := False;
    ProdScan.WidthPercent := 100.000000000000000000;
    SetEvent(ProdScan, Self, 'OnEnter', 'ProdScanEnter');
    SetEvent(ProdScan, Self, 'OnExit', 'ProdScanExit');
    SetEvent(ProdScan, Self, 'OnKeyPress', 'ProdScanKeyPress');
    DSReturns.SetParentComponent(Self);
    DSReturns.Name := 'DSReturns';
    DSReturns.AfterOpen := DSReturnsAfterOpen;
    DSReturns.EntitySetName := 'returns';
    DSReturns.Connection := Dm.ApiConnection;
    DSReturns.Left := 504;
    DSReturns.Top := 88;
    DSReturnsAutoInc.SetParentComponent(DSReturns);
    DSReturnsAutoInc.Name := 'DSReturnsAutoInc';
    DSReturnsAutoInc.FieldName := 'AutoInc';
    DSReturnsAutoInc.Required := True;
    DSReturnsOrderNum.SetParentComponent(DSReturns);
    DSReturnsOrderNum.Name := 'DSReturnsOrderNum';
    DSReturnsOrderNum.FieldName := 'OrderNum';
    DSReturnsProd.SetParentComponent(DSReturns);
    DSReturnsProd.Name := 'DSReturnsProd';
    DSReturnsProd.FieldName := 'Prod';
    DSReturnsLineNum.SetParentComponent(DSReturns);
    DSReturnsLineNum.Name := 'DSReturnsLineNum';
    DSReturnsLineNum.FieldName := 'LineNum';
    DSReturnsQty.SetParentComponent(DSReturns);
    DSReturnsQty.Name := 'DSReturnsQty';
    DSReturnsQty.FieldName := 'Qty';
    DSReturnsReasonCode.SetParentComponent(DSReturns);
    DSReturnsReasonCode.Name := 'DSReturnsReasonCode';
    DSReturnsReasonCode.FieldName := 'ReasonCode';
    DSReturnsReturningToMV.SetParentComponent(DSReturns);
    DSReturnsReturningToMV.Name := 'DSReturnsReturningToMV';
    DSReturnsReturningToMV.FieldName := 'ReturningToMV';
    DSReturnsReturningToMV.Size := 1;
    DSReturnsNotes.SetParentComponent(DSReturns);
    DSReturnsNotes.Name := 'DSReturnsNotes';
    DSReturnsNotes.FieldName := 'Notes';
    DSReturnsNotes.Size := 100;
    DSReturnsPickTagID.SetParentComponent(DSReturns);
    DSReturnsPickTagID.Name := 'DSReturnsPickTagID';
    DSReturnsPickTagID.FieldName := 'PickTagID';
    DSReturnsPickTagID.Size := 25;
    WDSReturns.SetParentComponent(Self);
    WDSReturns.Name := 'WDSReturns';
    WDSReturns.DataSet := DSReturns;
    WDSReturns.Left := 456;
    WDSReturns.Top := 88;
    DSLines.SetParentComponent(Self);
    DSLines.Name := 'DSLines';
    DSLines.EntitySetName := 'dellines';
    DSLines.Connection := Dm.ApiConnection;
    DSLines.Left := 504;
    DSLines.Top := 32;
    DSLinesOrderNum.SetParentComponent(DSLines);
    DSLinesOrderNum.Name := 'DSLinesOrderNum';
    DSLinesOrderNum.FieldName := 'OrderNum';
    DSLinesOrderNum.Required := True;
    DSLinesLineNum.SetParentComponent(DSLines);
    DSLinesLineNum.Name := 'DSLinesLineNum';
    DSLinesLineNum.FieldName := 'LineNum';
    DSLinesLineNum.Required := True;
    DSLinesProd.SetParentComponent(DSLines);
    DSLinesProd.Name := 'DSLinesProd';
    DSLinesProd.FieldName := 'Prod';
    DSLinesCust.SetParentComponent(DSLines);
    DSLinesCust.Name := 'DSLinesCust';
    DSLinesCust.FieldName := 'Cust';
    DSLinesCust.Required := True;
    DSLinesQtyShip.SetParentComponent(DSLines);
    DSLinesQtyShip.Name := 'DSLinesQtyShip';
    DSLinesQtyShip.FieldName := 'QtyShip';
    DSLinesStatus.SetParentComponent(DSLines);
    DSLinesStatus.Name := 'DSLinesStatus';
    DSLinesStatus.FieldName := 'Status';
    DSLinesSlot.SetParentComponent(DSLines);
    DSLinesSlot.Name := 'DSLinesSlot';
    DSLinesSlot.FieldName := 'Slot';
    DSLinesSlot.Size := 7;
    DSLinesCatchWeight.SetParentComponent(DSLines);
    DSLinesCatchWeight.Name := 'DSLinesCatchWeight';
    DSLinesCatchWeight.FieldName := 'CatchWeight';
    DSLinesProdUnit.SetParentComponent(DSLines);
    DSLinesProdUnit.Name := 'DSLinesProdUnit';
    DSLinesProdUnit.FieldName := 'ProdUnit';
    DSLinesProdUnit.Size := 7;
    DSLinesProdSize.SetParentComponent(DSLines);
    DSLinesProdSize.Name := 'DSLinesProdSize';
    DSLinesProdSize.FieldName := 'ProdSize';
    DSLinesProdSize.Size := 10;
    DSLinesProdDescription.SetParentComponent(DSLines);
    DSLinesProdDescription.Name := 'DSLinesProdDescription';
    DSLinesProdDescription.FieldName := 'ProdDescription';
    DSLinesProdDescription.Size := 30;
    DSLinesProdBrand.SetParentComponent(DSLines);
    DSLinesProdBrand.Name := 'DSLinesProdBrand';
    DSLinesProdBrand.FieldName := 'ProdBrand';
    DSLinesProdBrand.Size := 10;
    DSLinesProdWeightEst.SetParentComponent(DSLines);
    DSLinesProdWeightEst.Name := 'DSLinesProdWeightEst';
    DSLinesProdWeightEst.FieldName := 'ProdWeightEst';
    DSLinesProdDepFlag.SetParentComponent(DSLines);
    DSLinesProdDepFlag.Name := 'DSLinesProdDepFlag';
    DSLinesProdDepFlag.FieldName := 'ProdDepFlag';
    DSLinesProdDepFlag.Size := 1;
    DSLinesProdTaxFlag.SetParentComponent(DSLines);
    DSLinesProdTaxFlag.Name := 'DSLinesProdTaxFlag';
    DSLinesProdTaxFlag.FieldName := 'ProdTaxFlag';
    DSLinesProdTaxFlag.Size := 1;
    DSLinesProdCWFlag.SetParentComponent(DSLines);
    DSLinesProdCWFlag.Name := 'DSLinesProdCWFlag';
    DSLinesProdCWFlag.FieldName := 'ProdCWFlag';
    DSLinesProdCWFlag.Size := 1;
    DSLinesRealUnitPrice.SetParentComponent(DSLines);
    DSLinesRealUnitPrice.Name := 'DSLinesRealUnitPrice';
    DSLinesRealUnitPrice.FieldName := 'RealUnitPrice';
    DSLinesRealUnitPrice.DisplayFormat := '0.00';
    DSLinesRebateAmount.SetParentComponent(DSLines);
    DSLinesRebateAmount.Name := 'DSLinesRebateAmount';
    DSLinesRebateAmount.FieldName := 'RebateAmount';
    DSLinesRebateAmount.DisplayFormat := '0.00';
    DSLinesAllowanceAmount.SetParentComponent(DSLines);
    DSLinesAllowanceAmount.Name := 'DSLinesAllowanceAmount';
    DSLinesAllowanceAmount.FieldName := 'AllowanceAmount';
    DSLinesAllowanceAmount.DisplayFormat := '0.00';
    DSLinesNOIAmount.SetParentComponent(DSLines);
    DSLinesNOIAmount.Name := 'DSLinesNOIAmount';
    DSLinesNOIAmount.FieldName := 'NOIAmount';
    DSLinesNOIComm.SetParentComponent(DSLines);
    DSLinesNOIComm.Name := 'DSLinesNOIComm';
    DSLinesNOIComm.FieldName := 'NOIComm';
    DSLinesNOIComm.Size := 100;
    DSLinesNOIRemaining.SetParentComponent(DSLines);
    DSLinesNOIRemaining.Name := 'DSLinesNOIRemaining';
    DSLinesNOIRemaining.FieldName := 'NOIRemaining';
    DSLinesNOIUsed.SetParentComponent(DSLines);
    DSLinesNOIUsed.Name := 'DSLinesNOIUsed';
    DSLinesNOIUsed.FieldName := 'NOIUsed';
    DSLinesTaxAmt.SetParentComponent(DSLines);
    DSLinesTaxAmt.Name := 'DSLinesTaxAmt';
    DSLinesTaxAmt.FieldName := 'TaxAmt';
    DSLinesLineShowAmount.SetParentComponent(DSLines);
    DSLinesLineShowAmount.Name := 'DSLinesLineShowAmount';
    DSLinesLineShowAmount.FieldName := 'LineShowAmount';
    DSLinesLineShowAmount.DisplayFormat := '0.00';
    DSLinesQtyOrd.SetParentComponent(DSLines);
    DSLinesQtyOrd.Name := 'DSLinesQtyOrd';
    DSLinesQtyOrd.FieldName := 'QtyOrd';
    DSLinesDepositAmount.SetParentComponent(DSLines);
    DSLinesDepositAmount.Name := 'DSLinesDepositAmount';
    DSLinesDepositAmount.FieldName := 'DepositAmount';
    DSLinesCreditRequested.SetParentComponent(DSLines);
    DSLinesCreditRequested.Name := 'DSLinesCreditRequested';
    DSLinesCreditRequested.FieldName := 'CreditRequested';
    DSLinesCreditRequested.Size := 1;
    WDSLines.SetParentComponent(Self);
    WDSLines.Name := 'WDSLines';
    WDSLines.DataSet := DSLines;
    WDSLines.Left := 456;
    WDSLines.Top := 32;
    WDSLabels.SetParentComponent(Self);
    WDSLabels.Name := 'WDSLabels';
    WDSLabels.DataSet := DSLabels;
    WDSLabels.Left := 456;
    WDSLabels.Top := 144;
    DSLabels.SetParentComponent(Self);
    DSLabels.Name := 'DSLabels';
    DSLabels.AfterOpen := DSLabelsAfterOpen;
    DSLabels.EntitySetName := 'PickLabels';
    DSLabels.Connection := Dm.ApiConnection;
    DSLabels.Left := 504;
    DSLabels.Top := 144;
    DSLabelsAssignNum.SetParentComponent(DSLabels);
    DSLabelsAssignNum.Name := 'DSLabelsAssignNum';
    DSLabelsAssignNum.FieldName := 'AssignNum';
    DSLabelsAssignNum.Required := True;
    DSLabelsRPNum.SetParentComponent(DSLabels);
    DSLabelsRPNum.Name := 'DSLabelsRPNum';
    DSLabelsRPNum.FieldName := 'RPNum';
    DSLabelsRPNum.Required := True;
    DSLabelsOrderNum.SetParentComponent(DSLabels);
    DSLabelsOrderNum.Name := 'DSLabelsOrderNum';
    DSLabelsOrderNum.FieldName := 'OrderNum';
    DSLabelsLineNum.SetParentComponent(DSLabels);
    DSLabelsLineNum.Name := 'DSLabelsLineNum';
    DSLabelsLineNum.FieldName := 'LineNum';
    DSLabelsUnit_.SetParentComponent(DSLabels);
    DSLabelsUnit_.Name := 'DSLabelsUnit_';
    DSLabelsUnit_.DisplayLabel := 'Unit';
    DSLabelsUnit_.FieldName := 'Unit_';
    DSLabelsUnit_.Size := 3;
    DSLabelsSize.SetParentComponent(DSLabels);
    DSLabelsSize.Name := 'DSLabelsSize';
    DSLabelsSize.FieldName := 'Size';
    DSLabelsSize.Size := 6;
    DSLabelsBrand.SetParentComponent(DSLabels);
    DSLabelsBrand.Name := 'DSLabelsBrand';
    DSLabelsBrand.FieldName := 'Brand';
    DSLabelsBrand.Size := 18;
    DSLabelsDescription.SetParentComponent(DSLabels);
    DSLabelsDescription.Name := 'DSLabelsDescription';
    DSLabelsDescription.FieldName := 'Description';
    DSLabelsDescription.Size := 25;
    DSLabelsDriverScan.SetParentComponent(DSLabels);
    DSLabelsDriverScan.Name := 'DSLabelsDriverScan';
    DSLabelsDriverScan.FieldName := 'DriverScan';
    DSLabelsDriverScan.Size := 1;
    DSLabelsTruck.SetParentComponent(DSLabels);
    DSLabelsTruck.Name := 'DSLabelsTruck';
    DSLabelsTruck.FieldName := 'Truck';
    DSLabelsStop.SetParentComponent(DSLabels);
    DSLabelsStop.Name := 'DSLabelsStop';
    DSLabelsStop.FieldName := 'Stop';
    DSLabelsProd.SetParentComponent(DSLabels);
    DSLabelsProd.Name := 'DSLabelsProd';
    DSLabelsProd.FieldName := 'Prod';
  finally
    ReturnsPnl.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    EditReturnQty.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    ProdScan.AfterLoadDFMValues;
    DSReturns.AfterLoadDFMValues;
    DSReturnsAutoInc.AfterLoadDFMValues;
    DSReturnsOrderNum.AfterLoadDFMValues;
    DSReturnsProd.AfterLoadDFMValues;
    DSReturnsLineNum.AfterLoadDFMValues;
    DSReturnsQty.AfterLoadDFMValues;
    DSReturnsReasonCode.AfterLoadDFMValues;
    DSReturnsReturningToMV.AfterLoadDFMValues;
    DSReturnsNotes.AfterLoadDFMValues;
    DSReturnsPickTagID.AfterLoadDFMValues;
    WDSReturns.AfterLoadDFMValues;
    DSLines.AfterLoadDFMValues;
    DSLinesOrderNum.AfterLoadDFMValues;
    DSLinesLineNum.AfterLoadDFMValues;
    DSLinesProd.AfterLoadDFMValues;
    DSLinesCust.AfterLoadDFMValues;
    DSLinesQtyShip.AfterLoadDFMValues;
    DSLinesStatus.AfterLoadDFMValues;
    DSLinesSlot.AfterLoadDFMValues;
    DSLinesCatchWeight.AfterLoadDFMValues;
    DSLinesProdUnit.AfterLoadDFMValues;
    DSLinesProdSize.AfterLoadDFMValues;
    DSLinesProdDescription.AfterLoadDFMValues;
    DSLinesProdBrand.AfterLoadDFMValues;
    DSLinesProdWeightEst.AfterLoadDFMValues;
    DSLinesProdDepFlag.AfterLoadDFMValues;
    DSLinesProdTaxFlag.AfterLoadDFMValues;
    DSLinesProdCWFlag.AfterLoadDFMValues;
    DSLinesRealUnitPrice.AfterLoadDFMValues;
    DSLinesRebateAmount.AfterLoadDFMValues;
    DSLinesAllowanceAmount.AfterLoadDFMValues;
    DSLinesNOIAmount.AfterLoadDFMValues;
    DSLinesNOIComm.AfterLoadDFMValues;
    DSLinesNOIRemaining.AfterLoadDFMValues;
    DSLinesNOIUsed.AfterLoadDFMValues;
    DSLinesTaxAmt.AfterLoadDFMValues;
    DSLinesLineShowAmount.AfterLoadDFMValues;
    DSLinesQtyOrd.AfterLoadDFMValues;
    DSLinesDepositAmount.AfterLoadDFMValues;
    DSLinesCreditRequested.AfterLoadDFMValues;
    WDSLines.AfterLoadDFMValues;
    WDSLabels.AfterLoadDFMValues;
    DSLabels.AfterLoadDFMValues;
    DSLabelsAssignNum.AfterLoadDFMValues;
    DSLabelsRPNum.AfterLoadDFMValues;
    DSLabelsOrderNum.AfterLoadDFMValues;
    DSLabelsLineNum.AfterLoadDFMValues;
    DSLabelsUnit_.AfterLoadDFMValues;
    DSLabelsSize.AfterLoadDFMValues;
    DSLabelsBrand.AfterLoadDFMValues;
    DSLabelsDescription.AfterLoadDFMValues;
    DSLabelsDriverScan.AfterLoadDFMValues;
    DSLabelsTruck.AfterLoadDFMValues;
    DSLabelsStop.AfterLoadDFMValues;
    DSLabelsProd.AfterLoadDFMValues;
  end;
end;

end.
