unit MSDS;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Grids,
  WEBLib.DBCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset,
  WEBLib.Grids, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFMSDS = class(TForm)
    WebPanel1: TPanel;
    XDS_Hazmat: TXDataWebDataSet;
    WDS_Hazmat: TDataSource;
    XDS_HazmatProd: TIntegerField;
    XDS_HazmatHazmatInfo1: TStringField;
    XDS_HazmatTrip: TIntegerField;
    XDS_HazmatShipCust: TIntegerField;
    XDS_HazmatShipName: TStringField;
    XDS_HazmatShipCity: TStringField;
    XDS_HazmatShipState: TStringField;
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    XDS_HazmatProdUnit: TStringField;
    XDS_Hazmatsize: TStringField;
    XDS_Hazmatbrand: TStringField;
    XDS_HazmatDescr: TStringField;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebButton1: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FMSDS: TFMSDS;

implementation

Uses Data,SelectRoute,Options;

{$R *.dfm}

procedure TFMSDS.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFMSDS.WebFormResize(Sender: TObject);
begin
  WebPanel1.Left := Round((FMSDS.Width / 2) - (WebPanel1.Width / 2));
  WebPanel1.Top := Round((FMSDS.Height / 2) - (WebPanel1.Height / 2));
  if WebPanel1.Top < 0 then
    WebPanel1.Top := 0;
end;

procedure TFMSDS.WebFormShow(Sender: TObject);
begin
  WebPanel1.Left := Round((FMSDS.Width / 2) -
    (WebPanel1.Width / 2));
  WebPanel1.Top := Round((FMSDS.Height / 2) -
    (WebPanel1.Height / 2));
  XDS_Hazmat.QueryString := '$filter=(Trip eq ' + IntToStr(RouteNum)
      + ')&$orderby=ShipCust';
  XDS_Hazmat.Load;
end;

procedure TFMSDS.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create(Self);
  WebButton1 := TButton.Create(Self);
  XDS_Hazmat := TXDataWebDataSet.Create(Self);
  XDS_HazmatProd := TIntegerField.Create(Self);
  XDS_HazmatHazmatInfo1 := TStringField.Create(Self);
  XDS_HazmatTrip := TIntegerField.Create(Self);
  XDS_HazmatShipCust := TIntegerField.Create(Self);
  XDS_HazmatShipName := TStringField.Create(Self);
  XDS_HazmatShipCity := TStringField.Create(Self);
  XDS_HazmatShipState := TStringField.Create(Self);
  XDS_HazmatProdUnit := TStringField.Create(Self);
  XDS_Hazmatsize := TStringField.Create(Self);
  XDS_Hazmatbrand := TStringField.Create(Self);
  XDS_HazmatDescr := TStringField.Create(Self);
  WDS_Hazmat := TDataSource.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  XDS_Hazmat.BeforeLoadDFMValues;
  XDS_HazmatProd.BeforeLoadDFMValues;
  XDS_HazmatHazmatInfo1.BeforeLoadDFMValues;
  XDS_HazmatTrip.BeforeLoadDFMValues;
  XDS_HazmatShipCust.BeforeLoadDFMValues;
  XDS_HazmatShipName.BeforeLoadDFMValues;
  XDS_HazmatShipCity.BeforeLoadDFMValues;
  XDS_HazmatShipState.BeforeLoadDFMValues;
  XDS_HazmatProdUnit.BeforeLoadDFMValues;
  XDS_Hazmatsize.BeforeLoadDFMValues;
  XDS_Hazmatbrand.BeforeLoadDFMValues;
  XDS_HazmatDescr.BeforeLoadDFMValues;
  WDS_Hazmat.BeforeLoadDFMValues;
  try
    Name := 'FMSDS';
    Width := 410;
    Height := 727;
    Caption := 'MSDS';
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 16;
    WebPanel1.Top := 16;
    WebPanel1.Width := 375;
    WebPanel1.Height := 705;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.Font.Charset := DEFAULT_CHARSET;
    WebPanel1.Font.Color := clWindowText;
    WebPanel1.Font.Height := -16;
    WebPanel1.Font.Name := 'Tahoma';
    WebPanel1.Font.Style := [];
    WebPanel1.ParentFont := False;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 0;
    WebLabel1.Width := 218;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Emergency Response Number:';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 20;
    WebLabel2.Width := 210;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Chem Trec - 1-800-424-9300';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebDBResponsiveGrid1.SetParentComponent(WebPanel1);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 8;
    WebDBResponsiveGrid1.Top := 45;
    WebDBResponsiveGrid1.Width := 360;
    WebDBResponsiveGrid1.Height := 604;
    WebDBResponsiveGrid1.ElementFont := efCSS;
    WebDBResponsiveGrid1.ItemIndex := 0;
    WebDBResponsiveGrid1.Options.ItemGap := 0;
    WebDBResponsiveGrid1.Options.ItemHeight := 175;
    WebDBResponsiveGrid1.Options.ItemSelectedBorderColor := clMenuHighlight;
    WebDBResponsiveGrid1.Options.ItemTemplate := '<div>C#:(%ShipCust%)  Name:(%ShipName%) <br>City/State: (%ShipCity%) (%ShipState%) <br>P#:(%Prod%) (%ProdUnit%) (%size%) <br> (%brand%) (%Descr%)</div> <div class="text-wrap" style="width: 300px;">MSDS: (%HazmatInfo1%) </div>';
    WebDBResponsiveGrid1.Options.ItemWidthMin := 345;
    WebDBResponsiveGrid1.DataSource := WDS_Hazmat;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 233;
    WebButton1.Top := 655;
    WebButton1.Width := 129;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Close';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    XDS_Hazmat.SetParentComponent(Self);
    XDS_Hazmat.Name := 'XDS_Hazmat';
    XDS_Hazmat.EntitySetName := 'Hazmat';
    XDS_Hazmat.Connection := Dm.ApiConnection;
    XDS_Hazmat.Left := 48;
    XDS_Hazmat.Top := 776;
    XDS_HazmatProd.SetParentComponent(XDS_Hazmat);
    XDS_HazmatProd.Name := 'XDS_HazmatProd';
    XDS_HazmatProd.FieldName := 'Prod';
    XDS_HazmatProd.Required := True;
    XDS_HazmatHazmatInfo1.SetParentComponent(XDS_Hazmat);
    XDS_HazmatHazmatInfo1.Name := 'XDS_HazmatHazmatInfo1';
    XDS_HazmatHazmatInfo1.FieldName := 'HazmatInfo1';
    XDS_HazmatHazmatInfo1.Size := 200;
    XDS_HazmatTrip.SetParentComponent(XDS_Hazmat);
    XDS_HazmatTrip.Name := 'XDS_HazmatTrip';
    XDS_HazmatTrip.FieldName := 'Trip';
    XDS_HazmatShipCust.SetParentComponent(XDS_Hazmat);
    XDS_HazmatShipCust.Name := 'XDS_HazmatShipCust';
    XDS_HazmatShipCust.FieldName := 'ShipCust';
    XDS_HazmatShipName.SetParentComponent(XDS_Hazmat);
    XDS_HazmatShipName.Name := 'XDS_HazmatShipName';
    XDS_HazmatShipName.FieldName := 'ShipName';
    XDS_HazmatShipName.Size := 35;
    XDS_HazmatShipCity.SetParentComponent(XDS_Hazmat);
    XDS_HazmatShipCity.Name := 'XDS_HazmatShipCity';
    XDS_HazmatShipCity.FieldName := 'ShipCity';
    XDS_HazmatShipCity.Size := 35;
    XDS_HazmatShipState.SetParentComponent(XDS_Hazmat);
    XDS_HazmatShipState.Name := 'XDS_HazmatShipState';
    XDS_HazmatShipState.FieldName := 'ShipState';
    XDS_HazmatShipState.Size := 2;
    XDS_HazmatProdUnit.SetParentComponent(XDS_Hazmat);
    XDS_HazmatProdUnit.Name := 'XDS_HazmatProdUnit';
    XDS_HazmatProdUnit.FieldName := 'ProdUnit';
    XDS_HazmatProdUnit.Size := 3;
    XDS_Hazmatsize.SetParentComponent(XDS_Hazmat);
    XDS_Hazmatsize.Name := 'XDS_Hazmatsize';
    XDS_Hazmatsize.FieldName := 'size';
    XDS_Hazmatsize.Size := 8;
    XDS_Hazmatbrand.SetParentComponent(XDS_Hazmat);
    XDS_Hazmatbrand.Name := 'XDS_Hazmatbrand';
    XDS_Hazmatbrand.FieldName := 'brand';
    XDS_Hazmatbrand.Size := 6;
    XDS_HazmatDescr.SetParentComponent(XDS_Hazmat);
    XDS_HazmatDescr.Name := 'XDS_HazmatDescr';
    XDS_HazmatDescr.FieldName := 'Descr';
    XDS_HazmatDescr.Size := 25;
    WDS_Hazmat.SetParentComponent(Self);
    WDS_Hazmat.Name := 'WDS_Hazmat';
    WDS_Hazmat.DataSet := XDS_Hazmat;
    WDS_Hazmat.Left := 104;
    WDS_Hazmat.Top := 784;
  finally
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    XDS_Hazmat.AfterLoadDFMValues;
    XDS_HazmatProd.AfterLoadDFMValues;
    XDS_HazmatHazmatInfo1.AfterLoadDFMValues;
    XDS_HazmatTrip.AfterLoadDFMValues;
    XDS_HazmatShipCust.AfterLoadDFMValues;
    XDS_HazmatShipName.AfterLoadDFMValues;
    XDS_HazmatShipCity.AfterLoadDFMValues;
    XDS_HazmatShipState.AfterLoadDFMValues;
    XDS_HazmatProdUnit.AfterLoadDFMValues;
    XDS_Hazmatsize.AfterLoadDFMValues;
    XDS_Hazmatbrand.AfterLoadDFMValues;
    XDS_HazmatDescr.AfterLoadDFMValues;
    WDS_Hazmat.AfterLoadDFMValues;
  end;
end;

end.