unit OrderList;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, Vcl.Grids, WEBLib.DBCtrls,
  WEBLib.DB,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFOrderList = class(TForm)
    DSOrders: TXDataWebDataSet;
    WDSOrders: TDataSource;
    CurrentRoutePnl: TPanel;
    CurrentRouteGrid: TDBGrid;
    WebButton1: TButton;
    DSOrdersOrderNum: TIntegerField;
    DSOrdersCust: TIntegerField;
    DSOrdersTrip: TIntegerField;
    DSOrdersStop: TIntegerField;
    DSOrdersInvoiceNum: TIntegerField;
    DSOrdersShipCust: TIntegerField;
    DSOrdersShipName: TStringField;
    DSOrdersShipContact: TStringField;
    DSOrdersShipCity: TStringField;
    DSOrdersShipState: TStringField;
    DSOrdersShipZip: TIntegerField;
    DSOrdersShipPhone: TStringField;
    DSOrdersShipAdd1: TStringField;
    DSOrdersShipAdd2: TStringField;
    DSOrdersPcsDry: TIntegerField;
    DSOrdersPcsCooler: TIntegerField;
    DSOrdersPcsFreezer: TIntegerField;
    DSOrdersPcsTotal: TIntegerField;
    DSOrdersPcsWeight: TFloatField;
    DSOrdersPcsCubes: TFloatField;
    DSOrdersPickupPcs: TIntegerField;
    DSOrdersWindowArriveTime: TDateTimeField;
    DSOrdersDeliveryWindow: TStringField;
    DSOrdersDeliveredFlag: TStringField;
    DSOrdersMess1: TStringField;
    DSOrdersMess2: TStringField;
    DSOrdersMess3: TStringField;
    DSOrdersMess4: TStringField;
    DSOrdersMess5: TStringField;
    DSOrdersStandingMessage: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FOrderList: TFOrderList;

implementation

Uses Data, SelectRoute, CurrentStop;

{$R *.dfm}

procedure TFOrderList.WebButton1Click(Sender: TObject);
begin
  //FOrderList.Close;
  // FOrderList.Free;
  Application.CreateForm(TFCurrentStop, FCurrentStop);
end;

procedure TFOrderList.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'OrderList' then
  begin
    FOrderList.Height := 699;
    FOrderList.Width := 393;
    //FOrderList.Close;
    Application.CreateForm(TFOrderList, FOrderList);
  end;
end;

procedure TFOrderList.WebFormResize(Sender: TObject);
begin
  CurrentRoutePnl.Left :=
    Round((FOrderList.Width / 2) - (CurrentRoutePnl.Width / 2));
  // CurrentRoutePnl.Top := Round((FOrderList.Height/2)-(CurrentRoutePnl.Height/2));
  if CurrentRoutePnl.Top < 0 then
    CurrentRoutePnl.Top := 0;
  if CurrentRoutePnl.Left < 0 then
    CurrentRoutePnl.Left := 0;
end;

procedure TFOrderList.WebFormShow(Sender: TObject);
begin
  DSOrders.QueryString := '$filter=(Trip eq ' + IntToStr(RouteNum) +
    ')&$orderby=Stop';
  DSOrders.Load;
  CurrentRoutePnl.Left :=
    Round((FOrderList.Width / 2) - (CurrentRoutePnl.Width / 2));
  CurrentRoutePnl.Top := Round((FOrderList.Height / 2) -
    (CurrentRoutePnl.Height / 2));
  CurrentForm := 'OrderList';
  if CurrentRoutePnl.Left < 0 then
    CurrentRoutePnl.Left := 0;
  if CurrentRoutePnl.Top < 0 then
    CurrentRoutePnl.Top := 0;
end;

procedure TFOrderList.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CurrentRoutePnl := TPanel.Create(Self);
  CurrentRouteGrid := TDBGrid.Create(Self);
  WebButton1 := TButton.Create(Self);
  DSOrders := TXDataWebDataSet.Create(Self);
  DSOrdersOrderNum := TIntegerField.Create(Self);
  DSOrdersCust := TIntegerField.Create(Self);
  DSOrdersTrip := TIntegerField.Create(Self);
  DSOrdersStop := TIntegerField.Create(Self);
  DSOrdersInvoiceNum := TIntegerField.Create(Self);
  DSOrdersShipCust := TIntegerField.Create(Self);
  DSOrdersShipName := TStringField.Create(Self);
  DSOrdersShipContact := TStringField.Create(Self);
  DSOrdersShipCity := TStringField.Create(Self);
  DSOrdersShipState := TStringField.Create(Self);
  DSOrdersShipZip := TIntegerField.Create(Self);
  DSOrdersShipPhone := TStringField.Create(Self);
  DSOrdersShipAdd1 := TStringField.Create(Self);
  DSOrdersShipAdd2 := TStringField.Create(Self);
  DSOrdersPcsDry := TIntegerField.Create(Self);
  DSOrdersPcsCooler := TIntegerField.Create(Self);
  DSOrdersPcsFreezer := TIntegerField.Create(Self);
  DSOrdersPcsTotal := TIntegerField.Create(Self);
  DSOrdersPcsWeight := TFloatField.Create(Self);
  DSOrdersPcsCubes := TFloatField.Create(Self);
  DSOrdersPickupPcs := TIntegerField.Create(Self);
  DSOrdersWindowArriveTime := TDateTimeField.Create(Self);
  DSOrdersDeliveryWindow := TStringField.Create(Self);
  DSOrdersDeliveredFlag := TStringField.Create(Self);
  DSOrdersMess1 := TStringField.Create(Self);
  DSOrdersMess2 := TStringField.Create(Self);
  DSOrdersMess3 := TStringField.Create(Self);
  DSOrdersMess4 := TStringField.Create(Self);
  DSOrdersMess5 := TStringField.Create(Self);
  DSOrdersStandingMessage := TStringField.Create(Self);
  WDSOrders := TDataSource.Create(Self);

  CurrentRoutePnl.BeforeLoadDFMValues;
  CurrentRouteGrid.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  DSOrders.BeforeLoadDFMValues;
  DSOrdersOrderNum.BeforeLoadDFMValues;
  DSOrdersCust.BeforeLoadDFMValues;
  DSOrdersTrip.BeforeLoadDFMValues;
  DSOrdersStop.BeforeLoadDFMValues;
  DSOrdersInvoiceNum.BeforeLoadDFMValues;
  DSOrdersShipCust.BeforeLoadDFMValues;
  DSOrdersShipName.BeforeLoadDFMValues;
  DSOrdersShipContact.BeforeLoadDFMValues;
  DSOrdersShipCity.BeforeLoadDFMValues;
  DSOrdersShipState.BeforeLoadDFMValues;
  DSOrdersShipZip.BeforeLoadDFMValues;
  DSOrdersShipPhone.BeforeLoadDFMValues;
  DSOrdersShipAdd1.BeforeLoadDFMValues;
  DSOrdersShipAdd2.BeforeLoadDFMValues;
  DSOrdersPcsDry.BeforeLoadDFMValues;
  DSOrdersPcsCooler.BeforeLoadDFMValues;
  DSOrdersPcsFreezer.BeforeLoadDFMValues;
  DSOrdersPcsTotal.BeforeLoadDFMValues;
  DSOrdersPcsWeight.BeforeLoadDFMValues;
  DSOrdersPcsCubes.BeforeLoadDFMValues;
  DSOrdersPickupPcs.BeforeLoadDFMValues;
  DSOrdersWindowArriveTime.BeforeLoadDFMValues;
  DSOrdersDeliveryWindow.BeforeLoadDFMValues;
  DSOrdersDeliveredFlag.BeforeLoadDFMValues;
  DSOrdersMess1.BeforeLoadDFMValues;
  DSOrdersMess2.BeforeLoadDFMValues;
  DSOrdersMess3.BeforeLoadDFMValues;
  DSOrdersMess4.BeforeLoadDFMValues;
  DSOrdersMess5.BeforeLoadDFMValues;
  DSOrdersStandingMessage.BeforeLoadDFMValues;
  WDSOrders.BeforeLoadDFMValues;
  try
    Name := 'FOrderList';
    Width := 412;
    Height := 728;
    Align := alClient;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CurrentRoutePnl.SetParentComponent(Self);
    CurrentRoutePnl.Name := 'CurrentRoutePnl';
    CurrentRoutePnl.Left := 2;
    CurrentRoutePnl.Top := 8;
    CurrentRoutePnl.Width := 402;
    CurrentRoutePnl.Height := 713;
    CurrentRoutePnl.ElementClassName := 'card';
    CurrentRoutePnl.ChildOrder := 2;
    CurrentRoutePnl.ElementBodyClassName := 'card-body';
    CurrentRouteGrid.SetParentComponent(CurrentRoutePnl);
    CurrentRouteGrid.Name := 'CurrentRouteGrid';
    CurrentRouteGrid.Left := 7;
    CurrentRouteGrid.Top := 5;
    CurrentRouteGrid.Width := 389;
    CurrentRouteGrid.Height := 644;
    CurrentRouteGrid.Columns.Clear;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'ShipName';
      Title := 'Acct';
      Width := 169;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'Mess5';
      Title := 'Mess5';
      Width := 162;
    end;
    with CurrentRouteGrid.Columns.Add do
    begin
      DataField := 'StandingMessage';
      Title := 'StandingMessage';
      Width := 242;
    end;
    CurrentRouteGrid.DataSource := WDSOrders;
    CurrentRouteGrid.FixedCols := 0;
    CurrentRouteGrid.Font.Charset := DEFAULT_CHARSET;
    CurrentRouteGrid.Font.Color := clWindowText;
    CurrentRouteGrid.Font.Height := -16;
    CurrentRouteGrid.Font.Name := 'Arial';
    CurrentRouteGrid.Font.Style := [];
    CurrentRouteGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    CurrentRouteGrid.ParentFont := False;
    CurrentRouteGrid.TabOrder := 0;
    CurrentRouteGrid.HeightPercent := 100.000000000000000000;
    CurrentRouteGrid.WidthPercent := 100.000000000000000000;
    CurrentRouteGrid.ColWidths[0] := 169;
    CurrentRouteGrid.ColWidths[1] := 162;
    CurrentRouteGrid.ColWidths[2] := 242;
    WebButton1.SetParentComponent(CurrentRoutePnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 248;
    WebButton1.Top := 665;
    WebButton1.Width := 148;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Close';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-danger';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    DSOrders.SetParentComponent(Self);
    DSOrders.Name := 'DSOrders';
    DSOrders.EntitySetName := 'delorders';
    DSOrders.Connection := Dm.ApiConnection;
    DSOrders.Left := 23;
    DSOrders.Top := 648;
    DSOrdersOrderNum.SetParentComponent(DSOrders);
    DSOrdersOrderNum.Name := 'DSOrdersOrderNum';
    DSOrdersOrderNum.FieldName := 'OrderNum';
    DSOrdersCust.SetParentComponent(DSOrders);
    DSOrdersCust.Name := 'DSOrdersCust';
    DSOrdersCust.FieldName := 'Cust';
    DSOrdersTrip.SetParentComponent(DSOrders);
    DSOrdersTrip.Name := 'DSOrdersTrip';
    DSOrdersTrip.FieldName := 'Trip';
    DSOrdersStop.SetParentComponent(DSOrders);
    DSOrdersStop.Name := 'DSOrdersStop';
    DSOrdersStop.FieldName := 'Stop';
    DSOrdersInvoiceNum.SetParentComponent(DSOrders);
    DSOrdersInvoiceNum.Name := 'DSOrdersInvoiceNum';
    DSOrdersInvoiceNum.FieldName := 'InvoiceNum';
    DSOrdersShipCust.SetParentComponent(DSOrders);
    DSOrdersShipCust.Name := 'DSOrdersShipCust';
    DSOrdersShipCust.FieldName := 'ShipCust';
    DSOrdersShipName.SetParentComponent(DSOrders);
    DSOrdersShipName.Name := 'DSOrdersShipName';
    DSOrdersShipName.FieldName := 'ShipName';
    DSOrdersShipName.Size := 25;
    DSOrdersShipContact.SetParentComponent(DSOrders);
    DSOrdersShipContact.Name := 'DSOrdersShipContact';
    DSOrdersShipContact.FieldName := 'ShipContact';
    DSOrdersShipContact.Size := 25;
    DSOrdersShipCity.SetParentComponent(DSOrders);
    DSOrdersShipCity.Name := 'DSOrdersShipCity';
    DSOrdersShipCity.FieldName := 'ShipCity';
    DSOrdersShipCity.Size := 25;
    DSOrdersShipState.SetParentComponent(DSOrders);
    DSOrdersShipState.Name := 'DSOrdersShipState';
    DSOrdersShipState.FieldName := 'ShipState';
    DSOrdersShipState.Size := 2;
    DSOrdersShipZip.SetParentComponent(DSOrders);
    DSOrdersShipZip.Name := 'DSOrdersShipZip';
    DSOrdersShipZip.FieldName := 'ShipZip';
    DSOrdersShipPhone.SetParentComponent(DSOrders);
    DSOrdersShipPhone.Name := 'DSOrdersShipPhone';
    DSOrdersShipPhone.FieldName := 'ShipPhone';
    DSOrdersShipPhone.Size := 15;
    DSOrdersShipAdd1.SetParentComponent(DSOrders);
    DSOrdersShipAdd1.Name := 'DSOrdersShipAdd1';
    DSOrdersShipAdd1.FieldName := 'ShipAdd1';
    DSOrdersShipAdd1.Size := 25;
    DSOrdersShipAdd2.SetParentComponent(DSOrders);
    DSOrdersShipAdd2.Name := 'DSOrdersShipAdd2';
    DSOrdersShipAdd2.FieldName := 'ShipAdd2';
    DSOrdersShipAdd2.Size := 25;
    DSOrdersPcsDry.SetParentComponent(DSOrders);
    DSOrdersPcsDry.Name := 'DSOrdersPcsDry';
    DSOrdersPcsDry.FieldName := 'PcsDry';
    DSOrdersPcsCooler.SetParentComponent(DSOrders);
    DSOrdersPcsCooler.Name := 'DSOrdersPcsCooler';
    DSOrdersPcsCooler.FieldName := 'PcsCooler';
    DSOrdersPcsFreezer.SetParentComponent(DSOrders);
    DSOrdersPcsFreezer.Name := 'DSOrdersPcsFreezer';
    DSOrdersPcsFreezer.FieldName := 'PcsFreezer';
    DSOrdersPcsTotal.SetParentComponent(DSOrders);
    DSOrdersPcsTotal.Name := 'DSOrdersPcsTotal';
    DSOrdersPcsTotal.FieldName := 'PcsTotal';
    DSOrdersPcsWeight.SetParentComponent(DSOrders);
    DSOrdersPcsWeight.Name := 'DSOrdersPcsWeight';
    DSOrdersPcsWeight.FieldName := 'PcsWeight';
    DSOrdersPcsCubes.SetParentComponent(DSOrders);
    DSOrdersPcsCubes.Name := 'DSOrdersPcsCubes';
    DSOrdersPcsCubes.FieldName := 'PcsCubes';
    DSOrdersPickupPcs.SetParentComponent(DSOrders);
    DSOrdersPickupPcs.Name := 'DSOrdersPickupPcs';
    DSOrdersPickupPcs.FieldName := 'PickupPcs';
    DSOrdersWindowArriveTime.SetParentComponent(DSOrders);
    DSOrdersWindowArriveTime.Name := 'DSOrdersWindowArriveTime';
    DSOrdersWindowArriveTime.FieldName := 'WindowArriveTime';
    DSOrdersDeliveryWindow.SetParentComponent(DSOrders);
    DSOrdersDeliveryWindow.Name := 'DSOrdersDeliveryWindow';
    DSOrdersDeliveryWindow.FieldName := 'DeliveryWindow';
    DSOrdersDeliveryWindow.Size := 50;
    DSOrdersDeliveredFlag.SetParentComponent(DSOrders);
    DSOrdersDeliveredFlag.Name := 'DSOrdersDeliveredFlag';
    DSOrdersDeliveredFlag.FieldName := 'DeliveredFlag';
    DSOrdersDeliveredFlag.Size := 1;
    DSOrdersMess1.SetParentComponent(DSOrders);
    DSOrdersMess1.Name := 'DSOrdersMess1';
    DSOrdersMess1.FieldName := 'Mess1';
    DSOrdersMess1.Size := 50;
    DSOrdersMess2.SetParentComponent(DSOrders);
    DSOrdersMess2.Name := 'DSOrdersMess2';
    DSOrdersMess2.FieldName := 'Mess2';
    DSOrdersMess2.Size := 50;
    DSOrdersMess3.SetParentComponent(DSOrders);
    DSOrdersMess3.Name := 'DSOrdersMess3';
    DSOrdersMess3.FieldName := 'Mess3';
    DSOrdersMess3.Size := 50;
    DSOrdersMess4.SetParentComponent(DSOrders);
    DSOrdersMess4.Name := 'DSOrdersMess4';
    DSOrdersMess4.FieldName := 'Mess4';
    DSOrdersMess4.Size := 50;
    DSOrdersMess5.SetParentComponent(DSOrders);
    DSOrdersMess5.Name := 'DSOrdersMess5';
    DSOrdersMess5.FieldName := 'Mess5';
    DSOrdersMess5.Size := 50;
    DSOrdersStandingMessage.SetParentComponent(DSOrders);
    DSOrdersStandingMessage.Name := 'DSOrdersStandingMessage';
    DSOrdersStandingMessage.FieldName := 'StandingMessage';
    DSOrdersStandingMessage.Size := 100;
    WDSOrders.SetParentComponent(Self);
    WDSOrders.Name := 'WDSOrders';
    WDSOrders.DataSet := DSOrders;
    WDSOrders.Left := 80;
    WDSOrders.Top := 656;
  finally
    CurrentRoutePnl.AfterLoadDFMValues;
    CurrentRouteGrid.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    DSOrders.AfterLoadDFMValues;
    DSOrdersOrderNum.AfterLoadDFMValues;
    DSOrdersCust.AfterLoadDFMValues;
    DSOrdersTrip.AfterLoadDFMValues;
    DSOrdersStop.AfterLoadDFMValues;
    DSOrdersInvoiceNum.AfterLoadDFMValues;
    DSOrdersShipCust.AfterLoadDFMValues;
    DSOrdersShipName.AfterLoadDFMValues;
    DSOrdersShipContact.AfterLoadDFMValues;
    DSOrdersShipCity.AfterLoadDFMValues;
    DSOrdersShipState.AfterLoadDFMValues;
    DSOrdersShipZip.AfterLoadDFMValues;
    DSOrdersShipPhone.AfterLoadDFMValues;
    DSOrdersShipAdd1.AfterLoadDFMValues;
    DSOrdersShipAdd2.AfterLoadDFMValues;
    DSOrdersPcsDry.AfterLoadDFMValues;
    DSOrdersPcsCooler.AfterLoadDFMValues;
    DSOrdersPcsFreezer.AfterLoadDFMValues;
    DSOrdersPcsTotal.AfterLoadDFMValues;
    DSOrdersPcsWeight.AfterLoadDFMValues;
    DSOrdersPcsCubes.AfterLoadDFMValues;
    DSOrdersPickupPcs.AfterLoadDFMValues;
    DSOrdersWindowArriveTime.AfterLoadDFMValues;
    DSOrdersDeliveryWindow.AfterLoadDFMValues;
    DSOrdersDeliveredFlag.AfterLoadDFMValues;
    DSOrdersMess1.AfterLoadDFMValues;
    DSOrdersMess2.AfterLoadDFMValues;
    DSOrdersMess3.AfterLoadDFMValues;
    DSOrdersMess4.AfterLoadDFMValues;
    DSOrdersMess5.AfterLoadDFMValues;
    DSOrdersStandingMessage.AfterLoadDFMValues;
    WDSOrders.AfterLoadDFMValues;
  end;
end;

end.
