unit OrderDetail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, Vcl.Grids,
  WEBLib.DBCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TFOrderDtl = class(TForm)
    WDSLines: TDataSource;
    DSLines: TXDataWebDataSet;
    DSLinesOrderNum: TIntegerField;
    DSLinesLineNum: TIntegerField;
    DSLinesProd: TIntegerField;
    DSLinesCust: TIntegerField;
    DSLinesQtyShip: TIntegerField;
    DSLinesStatus: TIntegerField;
    DSLinesSlot: TStringField;
    DSLinesCatchWeight: TFloatField;
    DSLinesProdUnit: TStringField;
    DSLinesProdSize: TStringField;
    DSLinesProdDescription: TStringField;
    DSLinesProdBrand: TStringField;
    DSLinesProdWeightEst: TFloatField;
    DSLinesProdDepFlag: TStringField;
    DSLinesProdTaxFlag: TStringField;
    DSLinesProdCWFlag: TStringField;
    DSLinesRebateAmount: TFloatField;
    DSLinesAllowanceAmount: TFloatField;
    DSLinesNOIAmount: TFloatField;
    DSLinesNOIComm: TStringField;
    DSLinesNOIRemaining: TFloatField;
    DSLinesNOIUsed: TFloatField;
    DSLinesTaxAmt: TFloatField;
    DSLinesLineShowAmount: TFloatField;
    DSLinesQtyOrd: TIntegerField;
    DSLinesDepositAmount: TFloatField;
    DSLinesCreditRequested: TStringField;
    OrderDtlPnl: TPanel;
    WebDBLabel2: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebDBLabel11: TDBLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebDBLabel12: TDBLabel;
    WebDBLabel13: TDBLabel;
    WebLabel8: TLabel;
    WebDBLabel14: TDBLabel;
    WebDBLabel15: TDBLabel;
    WebLabel9: TLabel;
    WebDBLabel1: TDBLabel;
    WebLabel1: TLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel5: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel7: TDBLabel;
    WebLabel2: TLabel;
    WebDBGrid1: TDBGrid;
    WebButton4: TButton;
    WebButton1: TButton;
    WebButton2: TButton;
    DSLinesRealUnitPrice: TFloatField;
    WebButton3: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FOrderDtl: TFOrderDtl;

implementation

Uses Data, CurrentStop, SignatureCap, Payment, Returns,
  Statement,ProdScanCamera;

{$R *.dfm}

procedure TFOrderDtl.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFProdScanCamera, FProdScanCamera);
end;

procedure TFOrderDtl.WebButton2Click(Sender: TObject);
begin
  Application.CreateForm(TFSignatureCap, FSignatureCap);
end;

procedure TFOrderDtl.WebButton3Click(Sender: TObject);
begin
  Application.CreateForm(TFPayment, FPayment);
  //FPayment.CustNum.Text := DSLinesCust.AsString;
end;

procedure TFOrderDtl.WebButton4Click(Sender: TObject);
begin
  //FOrderDtl.Close;
  //FOrderDetail.Free;
  Application.CreateForm(TFCurrentStop, FCurrentStop);
  //FCurrentStop.Show;
end;

procedure TFOrderDtl.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFReturns, FReturns);
end;

procedure TFOrderDtl.WebButton6Click(Sender: TObject);
begin
  Application.CreateForm(TFStatement, FStatement);
  FormCreatedBy := 'OrderDetail';
end;

procedure TFOrderDtl.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'OrderDetail' then
  begin
    FOrderDtl.Height := 699;
    FOrderDtl.Width := 393;
    //FOrderDtl.Close;
    Application.CreateForm(TFOrderDtl, FOrderDtl);
  end;
end;

procedure TFOrderDtl.WebFormResize(Sender: TObject);
begin
  OrderDtlPnl.Left := Round((FOrderDtl.Width / 2) - (OrderDtlPnl.Width / 2));
  if OrderDtlPnl.Top < 0 then
    OrderDtlPnl.Top := 0;
  // OrderDtlPnl.Top := Round((FOrderDetail.Height/2)-(OrderDtlPnl.Height/2));
end;

procedure TFOrderDtl.WebFormShow(Sender: TObject);
begin
  DSLines.QueryString := '$filter=(OrderNum eq ' +
    Dm.DSCurrStopOrdersOrderNum.AsString + ' AND Prod ne 0)&$orderby=Slot';
  DSLines.Load;
  OrderDtlPnl.Left := Round((FOrderDtl.Width / 2) - (OrderDtlPnl.Width / 2));
  OrderDtlPnl.Top := Round((FOrderDtl.Height / 2) -
    (OrderDtlPnl.Height / 2));
  CurrentForm := 'OrderDetail';
end;

procedure TFOrderDtl.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OrderDtlPnl := TPanel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel11 := TDBLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebDBLabel12 := TDBLabel.Create(Self);
  WebDBLabel13 := TDBLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebDBLabel14 := TDBLabel.Create(Self);
  WebDBLabel15 := TDBLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebDBLabel7 := TDBLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WDSLines := TDataSource.Create(Self);
  DSLines := TXDataWebDataSet.Create(Self);
  DSLinesOrderNum := TIntegerField.Create(Self);
  DSLinesLineNum := TIntegerField.Create(Self);
  DSLinesProd := TIntegerField.Create(Self);
  DSLinesCust := TIntegerField.Create(Self);
  DSLinesQtyShip := TIntegerField.Create(Self);
  DSLinesStatus := TIntegerField.Create(Self);
  DSLinesSlot := TStringField.Create(Self);
  DSLinesCatchWeight := TFloatField.Create(Self);
  DSLinesProdUnit := TStringField.Create(Self);
  DSLinesProdSize := TStringField.Create(Self);
  DSLinesProdDescription := TStringField.Create(Self);
  DSLinesProdBrand := TStringField.Create(Self);
  DSLinesProdWeightEst := TFloatField.Create(Self);
  DSLinesProdDepFlag := TStringField.Create(Self);
  DSLinesProdTaxFlag := TStringField.Create(Self);
  DSLinesProdCWFlag := TStringField.Create(Self);
  DSLinesRealUnitPrice := TFloatField.Create(Self);
  DSLinesRebateAmount := TFloatField.Create(Self);
  DSLinesAllowanceAmount := TFloatField.Create(Self);
  DSLinesNOIAmount := TFloatField.Create(Self);
  DSLinesNOIComm := TStringField.Create(Self);
  DSLinesNOIRemaining := TFloatField.Create(Self);
  DSLinesNOIUsed := TFloatField.Create(Self);
  DSLinesTaxAmt := TFloatField.Create(Self);
  DSLinesLineShowAmount := TFloatField.Create(Self);
  DSLinesQtyOrd := TIntegerField.Create(Self);
  DSLinesDepositAmount := TFloatField.Create(Self);
  DSLinesCreditRequested := TStringField.Create(Self);

  OrderDtlPnl.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel11.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebDBLabel12.BeforeLoadDFMValues;
  WebDBLabel13.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebDBLabel14.BeforeLoadDFMValues;
  WebDBLabel15.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel7.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WDSLines.BeforeLoadDFMValues;
  DSLines.BeforeLoadDFMValues;
  DSLinesOrderNum.BeforeLoadDFMValues;
  DSLinesLineNum.BeforeLoadDFMValues;
  DSLinesProd.BeforeLoadDFMValues;
  DSLinesCust.BeforeLoadDFMValues;
  DSLinesQtyShip.BeforeLoadDFMValues;
  DSLinesStatus.BeforeLoadDFMValues;
  DSLinesSlot.BeforeLoadDFMValues;
  DSLinesCatchWeight.BeforeLoadDFMValues;
  DSLinesProdUnit.BeforeLoadDFMValues;
  DSLinesProdSize.BeforeLoadDFMValues;
  DSLinesProdDescription.BeforeLoadDFMValues;
  DSLinesProdBrand.BeforeLoadDFMValues;
  DSLinesProdWeightEst.BeforeLoadDFMValues;
  DSLinesProdDepFlag.BeforeLoadDFMValues;
  DSLinesProdTaxFlag.BeforeLoadDFMValues;
  DSLinesProdCWFlag.BeforeLoadDFMValues;
  DSLinesRealUnitPrice.BeforeLoadDFMValues;
  DSLinesRebateAmount.BeforeLoadDFMValues;
  DSLinesAllowanceAmount.BeforeLoadDFMValues;
  DSLinesNOIAmount.BeforeLoadDFMValues;
  DSLinesNOIComm.BeforeLoadDFMValues;
  DSLinesNOIRemaining.BeforeLoadDFMValues;
  DSLinesNOIUsed.BeforeLoadDFMValues;
  DSLinesTaxAmt.BeforeLoadDFMValues;
  DSLinesLineShowAmount.BeforeLoadDFMValues;
  DSLinesQtyOrd.BeforeLoadDFMValues;
  DSLinesDepositAmount.BeforeLoadDFMValues;
  DSLinesCreditRequested.BeforeLoadDFMValues;
  try
    Name := 'FOrderDtl';
    Width := 403;
    Height := 757;
    Align := alClient;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    OrderDtlPnl.SetParentComponent(Self);
    OrderDtlPnl.Name := 'OrderDtlPnl';
    OrderDtlPnl.Left := 0;
    OrderDtlPnl.Top := 8;
    OrderDtlPnl.Width := 400;
    OrderDtlPnl.Height := 745;
    OrderDtlPnl.ElementClassName := 'card';
    OrderDtlPnl.ElementBodyClassName := 'card-body';
    WebDBLabel2.SetParentComponent(OrderDtlPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 20;
    WebDBLabel2.Top := 3;
    WebDBLabel2.Width := 41;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'Cust#';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -16;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'Cust';
    WebDBLabel2.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel4.SetParentComponent(OrderDtlPnl);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 75;
    WebDBLabel4.Top := 3;
    WebDBLabel4.Width := 114;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := 'Customer Name';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -16;
    WebDBLabel4.Font.Name := 'Arial';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'ShipName';
    WebDBLabel4.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel11.SetParentComponent(OrderDtlPnl);
    WebDBLabel11.Name := 'WebDBLabel11';
    WebDBLabel11.Left := 20;
    WebDBLabel11.Top := 43;
    WebDBLabel11.Width := 27;
    WebDBLabel11.Height := 18;
    WebDBLabel11.Alignment := taRightJustify;
    WebDBLabel11.AutoSize := False;
    WebDBLabel11.Caption := '000';
    WebDBLabel11.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel11.Font.Color := clWindowText;
    WebDBLabel11.Font.Height := -16;
    WebDBLabel11.Font.Name := 'Arial';
    WebDBLabel11.Font.Style := [];
    WebDBLabel11.HeightPercent := 100.000000000000000000;
    WebDBLabel11.ParentFont := False;
    WebDBLabel11.WidthPercent := 100.000000000000000000;
    WebDBLabel11.DataField := 'PcsDry';
    WebDBLabel11.DataSource := Dm.WDSCurrStopOrders;
    WebLabel5.SetParentComponent(OrderDtlPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 20;
    WebLabel5.Top := 27;
    WebLabel5.Width := 27;
    WebLabel5.Height := 19;
    WebLabel5.Caption := 'Dry';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [fsBold];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(OrderDtlPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 68;
    WebLabel6.Top := 27;
    WebLabel6.Width := 22;
    WebLabel6.Height := 19;
    WebLabel6.Caption := 'Clr';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [fsBold];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(OrderDtlPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 116;
    WebLabel7.Top := 27;
    WebLabel7.Width := 25;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'Frz';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel12.SetParentComponent(OrderDtlPnl);
    WebDBLabel12.Name := 'WebDBLabel12';
    WebDBLabel12.Left := 65;
    WebDBLabel12.Top := 43;
    WebDBLabel12.Width := 27;
    WebDBLabel12.Height := 18;
    WebDBLabel12.Alignment := taRightJustify;
    WebDBLabel12.AutoSize := False;
    WebDBLabel12.Caption := '000';
    WebDBLabel12.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel12.Font.Color := clWindowText;
    WebDBLabel12.Font.Height := -16;
    WebDBLabel12.Font.Name := 'Arial';
    WebDBLabel12.Font.Style := [];
    WebDBLabel12.HeightPercent := 100.000000000000000000;
    WebDBLabel12.ParentFont := False;
    WebDBLabel12.WidthPercent := 100.000000000000000000;
    WebDBLabel12.DataField := 'PcsCooler';
    WebDBLabel12.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel13.SetParentComponent(OrderDtlPnl);
    WebDBLabel13.Name := 'WebDBLabel13';
    WebDBLabel13.Left := 113;
    WebDBLabel13.Top := 43;
    WebDBLabel13.Width := 27;
    WebDBLabel13.Height := 18;
    WebDBLabel13.Alignment := taRightJustify;
    WebDBLabel13.AutoSize := False;
    WebDBLabel13.Caption := '000';
    WebDBLabel13.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel13.Font.Color := clWindowText;
    WebDBLabel13.Font.Height := -16;
    WebDBLabel13.Font.Name := 'Arial';
    WebDBLabel13.Font.Style := [];
    WebDBLabel13.HeightPercent := 100.000000000000000000;
    WebDBLabel13.ParentFont := False;
    WebDBLabel13.WidthPercent := 100.000000000000000000;
    WebDBLabel13.DataField := 'PcsFreezer';
    WebDBLabel13.DataSource := Dm.WDSCurrStopOrders;
    WebLabel8.SetParentComponent(OrderDtlPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 172;
    WebLabel8.Top := 27;
    WebLabel8.Width := 37;
    WebLabel8.Height := 19;
    WebLabel8.Caption := 'Total';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [fsBold];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel14.SetParentComponent(OrderDtlPnl);
    WebDBLabel14.Name := 'WebDBLabel14';
    WebDBLabel14.Left := 177;
    WebDBLabel14.Top := 43;
    WebDBLabel14.Width := 27;
    WebDBLabel14.Height := 18;
    WebDBLabel14.Alignment := taRightJustify;
    WebDBLabel14.AutoSize := False;
    WebDBLabel14.Caption := '000';
    WebDBLabel14.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel14.Font.Color := clWindowText;
    WebDBLabel14.Font.Height := -16;
    WebDBLabel14.Font.Name := 'Arial';
    WebDBLabel14.Font.Style := [];
    WebDBLabel14.HeightPercent := 100.000000000000000000;
    WebDBLabel14.ParentFont := False;
    WebDBLabel14.WidthPercent := 100.000000000000000000;
    WebDBLabel14.DataField := 'PcsTotal';
    WebDBLabel14.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel15.SetParentComponent(OrderDtlPnl);
    WebDBLabel15.Name := 'WebDBLabel15';
    WebDBLabel15.Left := 244;
    WebDBLabel15.Top := 43;
    WebDBLabel15.Width := 27;
    WebDBLabel15.Height := 18;
    WebDBLabel15.Alignment := taRightJustify;
    WebDBLabel15.AutoSize := False;
    WebDBLabel15.Caption := '000';
    WebDBLabel15.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel15.Font.Color := clWindowText;
    WebDBLabel15.Font.Height := -16;
    WebDBLabel15.Font.Name := 'Arial';
    WebDBLabel15.Font.Style := [];
    WebDBLabel15.HeightPercent := 100.000000000000000000;
    WebDBLabel15.ParentFont := False;
    WebDBLabel15.WidthPercent := 100.000000000000000000;
    WebDBLabel15.DataField := 'PickupPcs';
    WebDBLabel15.DataSource := Dm.WDSCurrStopOrders;
    WebLabel9.SetParentComponent(OrderDtlPnl);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 244;
    WebLabel9.Top := 27;
    WebLabel9.Width := 27;
    WebLabel9.Height := 19;
    WebLabel9.Caption := 'P/U';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [fsBold];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebDBLabel1.SetParentComponent(OrderDtlPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 28;
    WebDBLabel1.Top := 519;
    WebDBLabel1.Width := 27;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'Unit';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -16;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'ProdUnit';
    WebDBLabel1.DataSource := WDSLines;
    WebLabel1.SetParentComponent(OrderDtlPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 503;
    WebLabel1.Width := 117;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Product Details';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel3.SetParentComponent(OrderDtlPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 84;
    WebDBLabel3.Top := 519;
    WebDBLabel3.Width := 31;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'Size';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'ProdSize';
    WebDBLabel3.DataSource := WDSLines;
    WebDBLabel5.SetParentComponent(OrderDtlPnl);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 164;
    WebDBLabel5.Top := 519;
    WebDBLabel5.Width := 42;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'Brand';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Arial';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'ProdBrand';
    WebDBLabel5.DataSource := WDSLines;
    WebDBLabel6.SetParentComponent(OrderDtlPnl);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 28;
    WebDBLabel6.Top := 536;
    WebDBLabel6.Width := 80;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := 'Description';
    WebDBLabel6.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel6.Font.Color := clWindowText;
    WebDBLabel6.Font.Height := -16;
    WebDBLabel6.Font.Name := 'Arial';
    WebDBLabel6.Font.Style := [];
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.ParentFont := False;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'ProdDescription';
    WebDBLabel6.DataSource := WDSLines;
    WebDBLabel7.SetParentComponent(OrderDtlPnl);
    WebDBLabel7.Name := 'WebDBLabel7';
    WebDBLabel7.Left := 278;
    WebDBLabel7.Top := 519;
    WebDBLabel7.Width := 31;
    WebDBLabel7.Height := 18;
    WebDBLabel7.Caption := 'C/W';
    WebDBLabel7.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel7.Font.Color := clWindowText;
    WebDBLabel7.Font.Height := -16;
    WebDBLabel7.Font.Name := 'Arial';
    WebDBLabel7.Font.Style := [];
    WebDBLabel7.HeightPercent := 100.000000000000000000;
    WebDBLabel7.ParentFont := False;
    WebDBLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel7.DataField := 'CatchWeight';
    WebDBLabel7.DataSource := WDSLines;
    WebLabel2.SetParentComponent(OrderDtlPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 237;
    WebLabel2.Top := 519;
    WebLabel2.Width := 35;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'C/W:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebDBGrid1.SetParentComponent(OrderDtlPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 3;
    WebDBGrid1.Top := 63;
    WebDBGrid1.Width := 394;
    WebDBGrid1.Height := 434;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Slot';
      Title := 'Slot';
      Width := 55;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'ProdDescription';
      Title := 'Description';
      Width := 231;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      ElementClassName := 'text-right';
      DataField := 'QtyShip';
      Title := 'Qty';
      Width := 32;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      ElementClassName := 'text-right';
      DataField := 'RealUnitPrice';
      Title := 'Price';
      Width := 50;
    end;
    WebDBGrid1.DataSource := WDSLines;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -16;
    WebDBGrid1.Font.Name := 'Arial';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 0;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 55;
    WebDBGrid1.ColWidths[1] := 231;
    WebDBGrid1.ColWidths[2] := 32;
    WebDBGrid1.ColWidths[3] := 50;
    WebButton4.SetParentComponent(OrderDtlPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 14;
    WebButton4.Top := 684;
    WebButton4.Width := 137;
    WebButton4.Height := 57;
    WebButton4.Caption := 'Close';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-danger';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.ParentFont := False;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton1.SetParentComponent(OrderDtlPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 14;
    WebButton1.Top := 621;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Scan Items';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(OrderDtlPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 244;
    WebButton2.Top := 621;
    WebButton2.Width := 137;
    WebButton2.Height := 57;
    WebButton2.Anchors := [akTop];
    WebButton2.Caption := 'Signature';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-info';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(OrderDtlPnl);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 244;
    WebButton3.Top := 684;
    WebButton3.Width := 137;
    WebButton3.Height := 57;
    WebButton3.Anchors := [akTop];
    WebButton3.Caption := 'Payment';
    WebButton3.ChildOrder := 1;
    WebButton3.ElementClassName := 'btn btn-success';
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -19;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.ParentFont := False;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton5.SetParentComponent(OrderDtlPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 14;
    WebButton5.Top := 558;
    WebButton5.Width := 137;
    WebButton5.Height := 57;
    WebButton5.Caption := 'RFC Item';
    WebButton5.ChildOrder := 1;
    WebButton5.ElementClassName := 'btn btn-warning';
    WebButton5.Font.Charset := DEFAULT_CHARSET;
    WebButton5.Font.Color := clWindowText;
    WebButton5.Font.Height := -19;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Style := [];
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.ParentFont := False;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(OrderDtlPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 244;
    WebButton6.Top := 558;
    WebButton6.Width := 137;
    WebButton6.Height := 57;
    WebButton6.Caption := 'View Statement';
    WebButton6.ChildOrder := 1;
    WebButton6.ElementClassName := 'btn btn-info';
    WebButton6.Font.Charset := DEFAULT_CHARSET;
    WebButton6.Font.Color := clWindowText;
    WebButton6.Font.Height := -19;
    WebButton6.Font.Name := 'Arial';
    WebButton6.Font.Style := [];
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.ParentFont := False;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WDSLines.SetParentComponent(Self);
    WDSLines.Name := 'WDSLines';
    WDSLines.DataSet := DSLines;
    WDSLines.Left := 88;
    WDSLines.Top := 648;
    DSLines.SetParentComponent(Self);
    DSLines.Name := 'DSLines';
    DSLines.EntitySetName := 'dellines';
    DSLines.Connection := Dm.ApiConnection;
    DSLines.Left := 136;
    DSLines.Top := 648;
    DSLinesOrderNum.SetParentComponent(DSLines);
    DSLinesOrderNum.Name := 'DSLinesOrderNum';
    DSLinesOrderNum.FieldName := 'OrderNum';
    DSLinesOrderNum.Required := True;
    DSLinesLineNum.SetParentComponent(DSLines);
    DSLinesLineNum.Name := 'DSLinesLineNum';
    DSLinesLineNum.FieldName := 'LineNum';
    DSLinesLineNum.Required := True;
    DSLinesProd.SetParentComponent(DSLines);
    DSLinesProd.Name := 'DSLinesProd';
    DSLinesProd.FieldName := 'Prod';
    DSLinesCust.SetParentComponent(DSLines);
    DSLinesCust.Name := 'DSLinesCust';
    DSLinesCust.FieldName := 'Cust';
    DSLinesCust.Required := True;
    DSLinesQtyShip.SetParentComponent(DSLines);
    DSLinesQtyShip.Name := 'DSLinesQtyShip';
    DSLinesQtyShip.FieldName := 'QtyShip';
    DSLinesStatus.SetParentComponent(DSLines);
    DSLinesStatus.Name := 'DSLinesStatus';
    DSLinesStatus.FieldName := 'Status';
    DSLinesSlot.SetParentComponent(DSLines);
    DSLinesSlot.Name := 'DSLinesSlot';
    DSLinesSlot.FieldName := 'Slot';
    DSLinesSlot.Size := 7;
    DSLinesCatchWeight.SetParentComponent(DSLines);
    DSLinesCatchWeight.Name := 'DSLinesCatchWeight';
    DSLinesCatchWeight.FieldName := 'CatchWeight';
    DSLinesProdUnit.SetParentComponent(DSLines);
    DSLinesProdUnit.Name := 'DSLinesProdUnit';
    DSLinesProdUnit.FieldName := 'ProdUnit';
    DSLinesProdUnit.Size := 7;
    DSLinesProdSize.SetParentComponent(DSLines);
    DSLinesProdSize.Name := 'DSLinesProdSize';
    DSLinesProdSize.FieldName := 'ProdSize';
    DSLinesProdSize.Size := 10;
    DSLinesProdDescription.SetParentComponent(DSLines);
    DSLinesProdDescription.Name := 'DSLinesProdDescription';
    DSLinesProdDescription.FieldName := 'ProdDescription';
    DSLinesProdDescription.Size := 30;
    DSLinesProdBrand.SetParentComponent(DSLines);
    DSLinesProdBrand.Name := 'DSLinesProdBrand';
    DSLinesProdBrand.FieldName := 'ProdBrand';
    DSLinesProdBrand.Size := 10;
    DSLinesProdWeightEst.SetParentComponent(DSLines);
    DSLinesProdWeightEst.Name := 'DSLinesProdWeightEst';
    DSLinesProdWeightEst.FieldName := 'ProdWeightEst';
    DSLinesProdDepFlag.SetParentComponent(DSLines);
    DSLinesProdDepFlag.Name := 'DSLinesProdDepFlag';
    DSLinesProdDepFlag.FieldName := 'ProdDepFlag';
    DSLinesProdDepFlag.Size := 1;
    DSLinesProdTaxFlag.SetParentComponent(DSLines);
    DSLinesProdTaxFlag.Name := 'DSLinesProdTaxFlag';
    DSLinesProdTaxFlag.FieldName := 'ProdTaxFlag';
    DSLinesProdTaxFlag.Size := 1;
    DSLinesProdCWFlag.SetParentComponent(DSLines);
    DSLinesProdCWFlag.Name := 'DSLinesProdCWFlag';
    DSLinesProdCWFlag.FieldName := 'ProdCWFlag';
    DSLinesProdCWFlag.Size := 1;
    DSLinesRealUnitPrice.SetParentComponent(DSLines);
    DSLinesRealUnitPrice.Name := 'DSLinesRealUnitPrice';
    DSLinesRealUnitPrice.FieldName := 'RealUnitPrice';
    DSLinesRealUnitPrice.DisplayFormat := '0.00';
    DSLinesRebateAmount.SetParentComponent(DSLines);
    DSLinesRebateAmount.Name := 'DSLinesRebateAmount';
    DSLinesRebateAmount.FieldName := 'RebateAmount';
    DSLinesAllowanceAmount.SetParentComponent(DSLines);
    DSLinesAllowanceAmount.Name := 'DSLinesAllowanceAmount';
    DSLinesAllowanceAmount.FieldName := 'AllowanceAmount';
    DSLinesNOIAmount.SetParentComponent(DSLines);
    DSLinesNOIAmount.Name := 'DSLinesNOIAmount';
    DSLinesNOIAmount.FieldName := 'NOIAmount';
    DSLinesNOIComm.SetParentComponent(DSLines);
    DSLinesNOIComm.Name := 'DSLinesNOIComm';
    DSLinesNOIComm.FieldName := 'NOIComm';
    DSLinesNOIComm.Size := 100;
    DSLinesNOIRemaining.SetParentComponent(DSLines);
    DSLinesNOIRemaining.Name := 'DSLinesNOIRemaining';
    DSLinesNOIRemaining.FieldName := 'NOIRemaining';
    DSLinesNOIUsed.SetParentComponent(DSLines);
    DSLinesNOIUsed.Name := 'DSLinesNOIUsed';
    DSLinesNOIUsed.FieldName := 'NOIUsed';
    DSLinesTaxAmt.SetParentComponent(DSLines);
    DSLinesTaxAmt.Name := 'DSLinesTaxAmt';
    DSLinesTaxAmt.FieldName := 'TaxAmt';
    DSLinesLineShowAmount.SetParentComponent(DSLines);
    DSLinesLineShowAmount.Name := 'DSLinesLineShowAmount';
    DSLinesLineShowAmount.FieldName := 'LineShowAmount';
    DSLinesLineShowAmount.DisplayFormat := '0.00';
    DSLinesQtyOrd.SetParentComponent(DSLines);
    DSLinesQtyOrd.Name := 'DSLinesQtyOrd';
    DSLinesQtyOrd.FieldName := 'QtyOrd';
    DSLinesDepositAmount.SetParentComponent(DSLines);
    DSLinesDepositAmount.Name := 'DSLinesDepositAmount';
    DSLinesDepositAmount.FieldName := 'DepositAmount';
    DSLinesCreditRequested.SetParentComponent(DSLines);
    DSLinesCreditRequested.Name := 'DSLinesCreditRequested';
    DSLinesCreditRequested.FieldName := 'CreditRequested';
    DSLinesCreditRequested.Size := 1;
  finally
    OrderDtlPnl.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel11.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebDBLabel12.AfterLoadDFMValues;
    WebDBLabel13.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebDBLabel14.AfterLoadDFMValues;
    WebDBLabel15.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel7.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WDSLines.AfterLoadDFMValues;
    DSLines.AfterLoadDFMValues;
    DSLinesOrderNum.AfterLoadDFMValues;
    DSLinesLineNum.AfterLoadDFMValues;
    DSLinesProd.AfterLoadDFMValues;
    DSLinesCust.AfterLoadDFMValues;
    DSLinesQtyShip.AfterLoadDFMValues;
    DSLinesStatus.AfterLoadDFMValues;
    DSLinesSlot.AfterLoadDFMValues;
    DSLinesCatchWeight.AfterLoadDFMValues;
    DSLinesProdUnit.AfterLoadDFMValues;
    DSLinesProdSize.AfterLoadDFMValues;
    DSLinesProdDescription.AfterLoadDFMValues;
    DSLinesProdBrand.AfterLoadDFMValues;
    DSLinesProdWeightEst.AfterLoadDFMValues;
    DSLinesProdDepFlag.AfterLoadDFMValues;
    DSLinesProdTaxFlag.AfterLoadDFMValues;
    DSLinesProdCWFlag.AfterLoadDFMValues;
    DSLinesRealUnitPrice.AfterLoadDFMValues;
    DSLinesRebateAmount.AfterLoadDFMValues;
    DSLinesAllowanceAmount.AfterLoadDFMValues;
    DSLinesNOIAmount.AfterLoadDFMValues;
    DSLinesNOIComm.AfterLoadDFMValues;
    DSLinesNOIRemaining.AfterLoadDFMValues;
    DSLinesNOIUsed.AfterLoadDFMValues;
    DSLinesTaxAmt.AfterLoadDFMValues;
    DSLinesLineShowAmount.AfterLoadDFMValues;
    DSLinesQtyOrd.AfterLoadDFMValues;
    DSLinesDepositAmount.AfterLoadDFMValues;
    DSLinesCreditRequested.AfterLoadDFMValues;
  end;
end;

end.
