unit ProdScanCamera;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Devices, WEBLib.ExtCtrls, Vcl.Grids, WEBLib.DBCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, StrUtils;

type
  TFProdScanCamera = class(TForm)
    CameraPnl: TPanel;
    WebButton5: TButton;
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebDBGrid1: TDBGrid;
    WDSLabels: TDataSource;
    DSLabels: TXDataWebDataSet;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WMPCorrect: TMultimediaPlayer;
    WMPWrong: TMultimediaPlayer;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebButton1: TButton;
    WebButton2: TButton;
    DS_LabelCount: TXDataWebDataSet;
    DS_LabelCountOrderNum: TIntegerField;
    DS_LabelCountDryCases: TIntegerField;
    DS_LabelCountDrySingles: TIntegerField;
    DS_LabelCountCoolerCases: TIntegerField;
    DS_LabelCountCoolerSingles: TIntegerField;
    DS_LabelCountFreezerCases: TIntegerField;
    DS_LabelCountFreezerSingles: TIntegerField;
    WebPanel1: TPanel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;
    WebLabel10: TLabel;
    WebLabel11: TLabel;
    WebLabel16: TLabel;
    WebLabel18: TLabel;
    WebPanel2: TPanel;
    WebLabel6: TLabel;
    WebLabel12: TLabel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    WebLabel15: TLabel;
    WebLabel17: TLabel;
    WebLabel19: TLabel;
    DS_LabelCountTruck: TIntegerField;
    DS_LabelCountStop: TIntegerField;
    DSLabelsAssignNum: TIntegerField;
    DSLabelsRPNum: TIntegerField;
    DSLabelsOrderNum: TIntegerField;
    DSLabelsLineNum: TIntegerField;
    DSLabelsUnit_: TStringField;
    DSLabelsSize: TStringField;
    DSLabelsBrand: TStringField;
    DSLabelsDescription: TStringField;
    DSLabelsDriverScan: TStringField;
    DSLabelsTruck: TIntegerField;
    DSLabelsStop: TIntegerField;
    DSLabelsProd: TIntegerField;
    DS_ScanLabels: TXDataWebDataSet;
    IntegerField1: TIntegerField;
    IntegerField2: TIntegerField;
    IntegerField3: TIntegerField;
    IntegerField4: TIntegerField;
    StringField1: TStringField;
    StringField2: TStringField;
    StringField3: TStringField;
    StringField4: TStringField;
    StringField5: TStringField;
    IntegerField5: TIntegerField;
    IntegerField6: TIntegerField;
    IntegerField7: TIntegerField;
    WDS_ScanLabels: TDataSource;
    procedure WebBarcodeDecoder1Decoded(Sender: TObject; ADecoded: string);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebEdit1Enter(Sender: TObject);
    procedure WebEdit1Exit(Sender: TObject);
    procedure WebEdit1Click(Sender: TObject);
    procedure WebEdit1KeyPress(Sender: TObject; var Key: Char);
    procedure DSLabelsAfterOpen(DataSet: TDataSet);
    procedure DSLabelsAfterApplyUpdates(Sender: TDataSet;
      Info: TResolveResults);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure DS_LabelCountAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
    FCameraStopped: Boolean;
    FQRReader: Boolean;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FProdScanCamera: TFProdScanCamera;
  OrigQuery:String;
  BCAssign,BCRPNum:String;
  TempTruck,TempStop:String;
  ReturnAssign:String;
  ReturnRP:String;
  TRP:String;

implementation

Uses Data,OrderDetail,Returns;

{$R *.dfm}

procedure TFProdScanCamera.DSLabelsAfterApplyUpdates(Sender: TDataSet;
  Info: TResolveResults);
begin
  //After .ApplyUpdates then load original query and label count of remaining
  DSLabels.Close;
  DSLabels.QueryString := OrigQuery;
  DSLabels.Load;
  DS_LabelCount.Close;
  DS_LabelCount.QueryString := '$filter=(Truck eq '+TempTruck+' AND Stop eq '+TempStop+')';
  DS_LabelCount.Load;
end;

procedure TFProdScanCamera.DSLabelsAfterOpen(DataSet: TDataSet);
begin
  //After Scanning and Loading Labels File (Should be by Assign/RP/Truck/Stop)
  if (DSLabels.RecordCount = 0) and (WebEdit1.Text = '') then
  begin
    WebLabel3.Caption := 'No Items Available to Scan';
    WebEdit1.Enabled := True;
    WebEdit1.SetFocus;
    Exit;
  end;
  //Display total label count left
  WebLabel5.Caption := IntToStr(DSLabels.RecordCount);
  if WebEdit1.Text <> '' then
  begin
    if DSLabels.RecordCount = 0 then
    begin
      //Where labels did not return any results
      //ShowMessage('Invalid Scan');
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      WebLabel2.Caption := 'Invalid Scan';
      WMPWrong.Play;
      DSLabels.Close;
      DSLabels.QueryString := OrigQuery;
      DSLabels.Load;
      TRP := '';
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    //Check to see if already scanned
    else if (DSLabelsDriverScan.AsString = 'Y') then
    begin
      //ShowMessage('Product Already Scanned');
      //Product was already scanned by driver
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      WebLabel2.Caption := 'Already Scanned';
      WMPWrong.Play;
      DSLabels.Close;
      DSLabels.QueryString := OrigQuery;
      DSLabels.Load;
      TRP := '';
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    else if (DSLabelsDriverScan.AsString = 'R') and (TRP <> DSLabelsRPNum.AsString) then
    begin
      //ShowMessage('Product Already Scanned');
      //Product manually marked as 'scanned'
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      WebLabel2.Caption := 'Already Marked for RFC - Scan Again to Deliver';
      TRP := DSLabelsRPNum.AsString;
      WMPWrong.Play;
      DSLabels.Close;
      DSLabels.QueryString := OrigQuery;
      DSLabels.Load;
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    else if (DSLabelsDriverScan.AsString = 'R') and (TRP = DSLabelsRPNum.AsString) then
    begin
      //ShowMessage('Product Already Scanned');
      //Product manually marked as 'scanned'
      WebLabel2.Caption := 'Item Scanned: '+DSLabelsDescription.AsString;
      WMPCorrect.Play;
      DSLabels.Edit;
      DSLabelsDriverScan.AsString := 'Y';
      DSLabels.Post;
      DSLabels.ApplyUpdates;
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      TRP := '';
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    else if (DSLabelsDriverScan.AsString = 'M') then
    begin
      //ShowMessage('Product Already Scanned');
      //Product manually marked as 'scanned'
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      WebLabel2.Caption := 'Already Marked Manually';
      WMPWrong.Play;
      DSLabels.Close;
      DSLabels.QueryString := OrigQuery;
      DSLabels.Load;
      TRP := '';
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    else
    begin
      //Good scan - mark scanned and post - posting will reload label file
      WebLabel2.Caption := 'Item Scanned: '+DSLabelsDescription.AsString;
      WMPCorrect.Play;
      DSLabels.Edit;
      DSLabelsDriverScan.AsString := 'Y';
      DSLabels.Post;
      DSLabels.ApplyUpdates;
      WebEdit1.Clear;
      WebEdit1.SetFocus;
      TRP := '';
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end;
  end;
end;

procedure TFProdScanCamera.DS_LabelCountAfterOpen(DataSet: TDataSet);
begin
  //Set Displays for Drivers
  WebLabel9.Caption := DS_LabelCountCoolerCases.AsString;
  WebLabel11.Caption := DS_LabelCountDryCases.AsString;
  WebLabel10.Caption := DS_LabelCountFreezerCases.AsString;
  WebLabel13.Caption := DS_LabelCountDrySingles.AsString;
  WebLabel15.Caption := DS_LabelCountFreezerSingles.AsString;
  WebLabel19.Caption := DS_LabelCountCoolerSingles.AsString;
  WebLabel5.Caption := IntToStr(DSLabels.RecordCount);
end;

procedure TFProdScanCamera.WebBarcodeDecoder1Decoded(Sender: TObject; ADecoded: string);
begin
  //WebLabel2.Caption := ADecoded;
  //WebCamera1.Stop;
end;

procedure TFProdScanCamera.WebButton1Click(Sender: TObject);
begin
  //Locate Order/Line Numbers and Load Returns Screen
  ReturnAssign := DSLabelsAssignNum.AsString;
  ReturnRP := DSLabelsRPNum.AsString;
//  Dm.DSCurrStopOrders.Locate('OrderNum',DSLabelsOrderNum.AsString,[]);
//  FOrderDtl.DSLines.Locate('LineNum',DSLabelsLineNum.AsString,[]);
  Application.CreateForm(TFReturns, FReturns);
end;

procedure TFProdScanCamera.WebButton2Click(Sender: TObject);
begin
  //Manual Mark as Scan
  if Confirm('Mark '+DSLabelsDescription.AsString+' As Scanned?') then
  begin
    WebLabel2.Caption := 'Item Scanned: '+DSLabelsDescription.AsString;
    WMPCorrect.Play;
    DSLabels.Edit;
    DSLabelsDriverScan.AsString := 'M';
    DSLabels.Post;
    DSLabels.ApplyUpdates;
    WebEdit1.Clear;
    WebEdit1.SetFocus;
  end;
end;

procedure TFProdScanCamera.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFOrderDtl, FOrderDtl);
end;

procedure TFProdScanCamera.WebEdit1Click(Sender: TObject);
begin
  WebEdit1.SelectAll;
end;

procedure TFProdScanCamera.WebEdit1Enter(Sender: TObject);
begin
  WebEdit1.Color := clRed;
  WebEdit1.SelectAll;
end;

procedure TFProdScanCamera.WebEdit1Exit(Sender: TObject);
begin
  WebEdit1.Color := clWhite;
end;

procedure TFProdScanCamera.WebEdit1KeyPress(Sender: TObject; var Key: Char);
begin
  //Item Scanned - Decoding
  BCAssign := '';
  BCRPNum := '';
  if (Key = #13) then
  begin
    WebEdit1.Enabled := False;
    if (WebEdit1.Text = '') or (Length(WebEdit1.Text) <= 6) or not (AnsiContainsStr(WebEdit1.Text,'.')) then
    begin
      //ShowMessage('Invalid Scan');
      WebLabel2.Caption := 'Invalid Barcode';
      WMPWrong.Play;
      WebEdit1.Enabled := True;
      WebEdit1.SetFocus;
    end
    else
    begin
      //Store RP and Assign#
      BCRPNum := RightStr(WebEdit1.Text,6);
      BCAssign := LeftStr(WebEdit1.Text,Pos('.',WebEdit1.Text)-1);
      //OrigQuery := DSLabels.QueryString;
      Try
        DSLabels.Close;
      Except
        ShowMessage('Error Opening Labels Database - Try Again');
        WebEdit1.Enabled := True;
        WebEdit1.SetFocus;
        Exit;
      End;
      //Loads only the scanned tag and only if it is on current truck/stop combination
      DSLabels.QueryString := '$filter=(AssignNum eq '+BCAssign+' AND RPNum eq '+BCRPNum+' AND Truck eq '+TempTruck+' AND Stop eq '+TempStop+')&$orderby=RPNum';
      DSLabels.Load;
    end;
  end;
end;

procedure TFProdScanCamera.WebFormCreate(Sender: TObject);
begin
  //WebCamera1.Start;
  //FCameraStopped := False;
  //FQRReader := False;
end;

procedure TFProdScanCamera.WebFormShow(Sender: TObject);
begin
  //Initial Load
  TRP := '';
  CameraPnl.Left := Round((FProdScanCamera.Width / 2) - (CameraPnl.Width / 2));
  CameraPnl.Top := Round((FProdScanCamera.Height / 2) -
    (CameraPnl.Height / 2));
  WebLabel2.Caption := '';
  WebLabel3.Caption := '';
  TempTruck := Dm.DSCurrStopOrdersTrip.AsString;
  TempStop := Dm.DSCurrStopOrdersStop.AsString;
  FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
  //Store Initial Query to Revert to Later
  OrigQuery := '$filter=(Truck eq '+TempTruck+' AND Stop eq '+TempStop+' AND Prod ne 0 AND DriverScan eq null AND (PickStatus ne ''O'' OR PickStatus eq null) AND Date eq '''+Dm.DSCurrStopOrdersDate.AsString+''')&$orderby=Slot';
  DSLabels.QueryString := OrigQuery;
  FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
  DSLabels.Load;
  WebEdit1.SetFocus;
  //Count Query to Display Counts to Drivers
  DS_LabelCount.QueryString := '$filter=(Truck eq '+TempTruck+' AND Stop eq '+TempStop+')';
  DS_LabelCount.Load;
end;

procedure TFProdScanCamera.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CameraPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WMPCorrect := TMultimediaPlayer.Create(Self);
  WMPWrong := TMultimediaPlayer.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WDSLabels := TDataSource.Create(Self);
  DSLabels := TXDataWebDataSet.Create(Self);
  DSLabelsAssignNum := TIntegerField.Create(Self);
  DSLabelsRPNum := TIntegerField.Create(Self);
  DSLabelsOrderNum := TIntegerField.Create(Self);
  DSLabelsLineNum := TIntegerField.Create(Self);
  DSLabelsUnit_ := TStringField.Create(Self);
  DSLabelsSize := TStringField.Create(Self);
  DSLabelsBrand := TStringField.Create(Self);
  DSLabelsDescription := TStringField.Create(Self);
  DSLabelsDriverScan := TStringField.Create(Self);
  DSLabelsTruck := TIntegerField.Create(Self);
  DSLabelsStop := TIntegerField.Create(Self);
  DSLabelsProd := TIntegerField.Create(Self);
  DS_LabelCount := TXDataWebDataSet.Create(Self);
  DS_LabelCountTruck := TIntegerField.Create(Self);
  DS_LabelCountStop := TIntegerField.Create(Self);
  DS_LabelCountOrderNum := TIntegerField.Create(Self);
  DS_LabelCountDryCases := TIntegerField.Create(Self);
  DS_LabelCountDrySingles := TIntegerField.Create(Self);
  DS_LabelCountCoolerCases := TIntegerField.Create(Self);
  DS_LabelCountCoolerSingles := TIntegerField.Create(Self);
  DS_LabelCountFreezerCases := TIntegerField.Create(Self);
  DS_LabelCountFreezerSingles := TIntegerField.Create(Self);
  DS_ScanLabels := TXDataWebDataSet.Create(Self);
  IntegerField1 := TIntegerField.Create(Self);
  IntegerField2 := TIntegerField.Create(Self);
  IntegerField3 := TIntegerField.Create(Self);
  IntegerField4 := TIntegerField.Create(Self);
  StringField1 := TStringField.Create(Self);
  StringField2 := TStringField.Create(Self);
  StringField3 := TStringField.Create(Self);
  StringField4 := TStringField.Create(Self);
  StringField5 := TStringField.Create(Self);
  IntegerField5 := TIntegerField.Create(Self);
  IntegerField6 := TIntegerField.Create(Self);
  IntegerField7 := TIntegerField.Create(Self);
  WDS_ScanLabels := TDataSource.Create(Self);

  CameraPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WMPCorrect.BeforeLoadDFMValues;
  WMPWrong.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WDSLabels.BeforeLoadDFMValues;
  DSLabels.BeforeLoadDFMValues;
  DSLabelsAssignNum.BeforeLoadDFMValues;
  DSLabelsRPNum.BeforeLoadDFMValues;
  DSLabelsOrderNum.BeforeLoadDFMValues;
  DSLabelsLineNum.BeforeLoadDFMValues;
  DSLabelsUnit_.BeforeLoadDFMValues;
  DSLabelsSize.BeforeLoadDFMValues;
  DSLabelsBrand.BeforeLoadDFMValues;
  DSLabelsDescription.BeforeLoadDFMValues;
  DSLabelsDriverScan.BeforeLoadDFMValues;
  DSLabelsTruck.BeforeLoadDFMValues;
  DSLabelsStop.BeforeLoadDFMValues;
  DSLabelsProd.BeforeLoadDFMValues;
  DS_LabelCount.BeforeLoadDFMValues;
  DS_LabelCountTruck.BeforeLoadDFMValues;
  DS_LabelCountStop.BeforeLoadDFMValues;
  DS_LabelCountOrderNum.BeforeLoadDFMValues;
  DS_LabelCountDryCases.BeforeLoadDFMValues;
  DS_LabelCountDrySingles.BeforeLoadDFMValues;
  DS_LabelCountCoolerCases.BeforeLoadDFMValues;
  DS_LabelCountCoolerSingles.BeforeLoadDFMValues;
  DS_LabelCountFreezerCases.BeforeLoadDFMValues;
  DS_LabelCountFreezerSingles.BeforeLoadDFMValues;
  DS_ScanLabels.BeforeLoadDFMValues;
  IntegerField1.BeforeLoadDFMValues;
  IntegerField2.BeforeLoadDFMValues;
  IntegerField3.BeforeLoadDFMValues;
  IntegerField4.BeforeLoadDFMValues;
  StringField1.BeforeLoadDFMValues;
  StringField2.BeforeLoadDFMValues;
  StringField3.BeforeLoadDFMValues;
  StringField4.BeforeLoadDFMValues;
  StringField5.BeforeLoadDFMValues;
  IntegerField5.BeforeLoadDFMValues;
  IntegerField6.BeforeLoadDFMValues;
  IntegerField7.BeforeLoadDFMValues;
  WDS_ScanLabels.BeforeLoadDFMValues;
  try
    Name := 'FProdScanCamera';
    Width := 732;
    Height := 737;
    Caption := 'DriverLabels';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CameraPnl.SetParentComponent(Self);
    CameraPnl.Name := 'CameraPnl';
    CameraPnl.Left := 8;
    CameraPnl.Top := 5;
    CameraPnl.Width := 396;
    CameraPnl.Height := 729;
    CameraPnl.ElementClassName := 'card';
    CameraPnl.ElementBodyClassName := 'card-body';
    WebLabel1.SetParentComponent(CameraPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 16;
    WebLabel1.Width := 137;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Scan Product Label';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(CameraPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 128;
    WebLabel2.Width := 81;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'WebLabel2';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(CameraPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 149;
    WebLabel3.Width := 81;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'WebLabel2';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clRed;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(CameraPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 200;
    WebLabel4.Top := 16;
    WebLabel4.Width := 130;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'Labels Remaining:';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(CameraPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 336;
    WebLabel5.Top := 16;
    WebLabel5.Width := 45;
    WebLabel5.Height := 18;
    WebLabel5.Caption := '99999';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebButton5.SetParentComponent(CameraPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 237;
    WebButton5.Top := 663;
    WebButton5.Width := 145;
    WebButton5.Height := 57;
    WebButton5.Caption := 'Close';
    WebButton5.ChildOrder := 3;
    WebButton5.ElementClassName := 'btn btn-success';
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebEdit1.SetParentComponent(CameraPnl);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 40;
    WebEdit1.Top := 40;
    WebEdit1.Width := 313;
    WebEdit1.Height := 33;
    WebEdit1.ChildOrder := 2;
    WebEdit1.Font.Charset := DEFAULT_CHARSET;
    WebEdit1.Font.Color := clWindowText;
    WebEdit1.Font.Height := -16;
    WebEdit1.Font.Name := 'Arial';
    WebEdit1.Font.Style := [];
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.ParentFont := False;
    WebEdit1.SpellCheck := False;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SetEvent(WebEdit1, Self, 'OnClick', 'WebEdit1Click');
    SetEvent(WebEdit1, Self, 'OnEnter', 'WebEdit1Enter');
    SetEvent(WebEdit1, Self, 'OnExit', 'WebEdit1Exit');
    SetEvent(WebEdit1, Self, 'OnKeyPress', 'WebEdit1KeyPress');
    WebDBGrid1.SetParentComponent(CameraPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 8;
    WebDBGrid1.Top := 173;
    WebDBGrid1.Width := 379;
    WebDBGrid1.Height := 421;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Prod';
      Title := 'Prod';
      Width := 43;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Unit_';
      Title := 'Unit';
      Width := 33;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Size';
      Title := 'Size';
      Width := 48;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Brand';
      Title := 'Brand';
      Width := 60;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Description';
      Title := 'Description';
      Width := 171;
    end;
    WebDBGrid1.DataSource := WDSLabels;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -13;
    WebDBGrid1.Font.Name := 'Arial';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 2;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 43;
    WebDBGrid1.ColWidths[1] := 33;
    WebDBGrid1.ColWidths[2] := 48;
    WebDBGrid1.ColWidths[3] := 60;
    WebDBGrid1.ColWidths[4] := 171;
    WMPCorrect.SetParentComponent(CameraPnl);
    WMPCorrect.Name := 'WMPCorrect';
    WMPCorrect.Left := 16;
    WMPCorrect.Top := 663;
    WMPCorrect.Width := 185;
    WMPCorrect.Height := 57;
    WMPCorrect.MultimediaType := mtAudio;
    WMPCorrect.PlaybackRate := 1.000000000000000000;
    WMPCorrect.URL := 'https://photos.mymvf.com/audio/correct.wav';
    WMPWrong.SetParentComponent(CameraPnl);
    WMPWrong.Name := 'WMPWrong';
    WMPWrong.Left := 16;
    WMPWrong.Top := 663;
    WMPWrong.Width := 185;
    WMPWrong.Height := 57;
    WMPWrong.MultimediaType := mtAudio;
    WMPWrong.PlaybackRate := 1.000000000000000000;
    WMPWrong.URL := 'https://photos.mymvf.com/audio/wrong.wav';
    WebButton1.SetParentComponent(CameraPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 16;
    WebButton1.Top := 600;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'RFC Item';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-warning';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(CameraPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 168;
    WebButton2.Top := 600;
    WebButton2.Width := 214;
    WebButton2.Height := 57;
    WebButton2.Caption := 'Mark Label Scanned';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-danger';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebPanel1.SetParentComponent(CameraPnl);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 15;
    WebPanel1.Top := 79;
    WebPanel1.Width := 185;
    WebPanel1.Height := 43;
    WebPanel1.BorderColor := clBlack;
    WebPanel1.ChildOrder := 26;
    WebLabel7.SetParentComponent(WebPanel1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 128;
    WebLabel7.Top := 19;
    WebLabel7.Width := 14;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'F:';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(WebPanel1);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 8;
    WebLabel8.Top := 19;
    WebLabel8.Width := 16;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'D:';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(WebPanel1);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 86;
    WebLabel9.Top := 19;
    WebLabel9.Width := 27;
    WebLabel9.Height := 18;
    WebLabel9.Caption := '999';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(WebPanel1);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 148;
    WebLabel10.Top := 19;
    WebLabel10.Width := 27;
    WebLabel10.Height := 18;
    WebLabel10.Caption := '999';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(WebPanel1);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 30;
    WebLabel11.Top := 19;
    WebLabel11.Width := 27;
    WebLabel11.Height := 18;
    WebLabel11.Caption := '999';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(WebPanel1);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 67;
    WebLabel16.Top := 3;
    WebLabel16.Width := 46;
    WebLabel16.Height := 18;
    WebLabel16.Caption := 'Cases';
    WebLabel16.Font.Charset := DEFAULT_CHARSET;
    WebLabel16.Font.Color := clWindowText;
    WebLabel16.Font.Height := -16;
    WebLabel16.Font.Name := 'Arial';
    WebLabel16.Font.Style := [];
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.ParentFont := False;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(WebPanel1);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 64;
    WebLabel18.Top := 19;
    WebLabel18.Width := 16;
    WebLabel18.Height := 18;
    WebLabel18.Caption := 'C:';
    WebLabel18.Font.Charset := DEFAULT_CHARSET;
    WebLabel18.Font.Color := clWindowText;
    WebLabel18.Font.Height := -16;
    WebLabel18.Font.Name := 'Arial';
    WebLabel18.Font.Style := [];
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.ParentFont := False;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(CameraPnl);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 206;
    WebPanel2.Top := 79;
    WebPanel2.Width := 174;
    WebPanel2.Height := 43;
    WebPanel2.BorderColor := clBlack;
    WebPanel2.ChildOrder := 26;
    WebLabel6.SetParentComponent(WebPanel2);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 63;
    WebLabel6.Top := 19;
    WebLabel6.Width := 16;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'C:';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(WebPanel2);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 8;
    WebLabel12.Top := 19;
    WebLabel12.Width := 16;
    WebLabel12.Height := 18;
    WebLabel12.Caption := 'D:';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -16;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Style := [];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(WebPanel2);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 30;
    WebLabel13.Top := 19;
    WebLabel13.Width := 27;
    WebLabel13.Height := 18;
    WebLabel13.Caption := '999';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -16;
    WebLabel13.Font.Name := 'Arial';
    WebLabel13.Font.Style := [];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(WebPanel2);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 118;
    WebLabel14.Top := 19;
    WebLabel14.Width := 14;
    WebLabel14.Height := 18;
    WebLabel14.Caption := 'F:';
    WebLabel14.Font.Charset := DEFAULT_CHARSET;
    WebLabel14.Font.Color := clWindowText;
    WebLabel14.Font.Height := -16;
    WebLabel14.Font.Name := 'Arial';
    WebLabel14.Font.Style := [];
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.ParentFont := False;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPanel2);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 137;
    WebLabel15.Top := 19;
    WebLabel15.Width := 27;
    WebLabel15.Height := 18;
    WebLabel15.Caption := '999';
    WebLabel15.Font.Charset := DEFAULT_CHARSET;
    WebLabel15.Font.Color := clWindowText;
    WebLabel15.Font.Height := -16;
    WebLabel15.Font.Name := 'Arial';
    WebLabel15.Font.Style := [];
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.ParentFont := False;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(WebPanel2);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 30;
    WebLabel17.Top := 3;
    WebLabel17.Width := 117;
    WebLabel17.Height := 18;
    WebLabel17.Caption := 'Singles/Chem/IC';
    WebLabel17.Font.Charset := DEFAULT_CHARSET;
    WebLabel17.Font.Color := clWindowText;
    WebLabel17.Font.Height := -16;
    WebLabel17.Font.Name := 'Arial';
    WebLabel17.Font.Style := [];
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.ParentFont := False;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel19.SetParentComponent(WebPanel2);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 85;
    WebLabel19.Top := 19;
    WebLabel19.Width := 27;
    WebLabel19.Height := 18;
    WebLabel19.Caption := '999';
    WebLabel19.Font.Charset := DEFAULT_CHARSET;
    WebLabel19.Font.Color := clWindowText;
    WebLabel19.Font.Height := -16;
    WebLabel19.Font.Name := 'Arial';
    WebLabel19.Font.Style := [];
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.ParentFont := False;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WDSLabels.SetParentComponent(Self);
    WDSLabels.Name := 'WDSLabels';
    WDSLabels.DataSet := DSLabels;
    WDSLabels.Left := 424;
    WDSLabels.Top := 40;
    DSLabels.SetParentComponent(Self);
    DSLabels.Name := 'DSLabels';
    DSLabels.AfterApplyUpdates := DSLabelsAfterApplyUpdates;
    DSLabels.AfterOpen := DSLabelsAfterOpen;
    DSLabels.EntitySetName := 'DriverLabels';
    DSLabels.Connection := Dm.ApiConnection;
    DSLabels.Left := 464;
    DSLabels.Top := 24;
    DSLabelsAssignNum.SetParentComponent(DSLabels);
    DSLabelsAssignNum.Name := 'DSLabelsAssignNum';
    DSLabelsAssignNum.FieldName := 'AssignNum';
    DSLabelsAssignNum.Required := True;
    DSLabelsRPNum.SetParentComponent(DSLabels);
    DSLabelsRPNum.Name := 'DSLabelsRPNum';
    DSLabelsRPNum.FieldName := 'RPNum';
    DSLabelsRPNum.Required := True;
    DSLabelsOrderNum.SetParentComponent(DSLabels);
    DSLabelsOrderNum.Name := 'DSLabelsOrderNum';
    DSLabelsOrderNum.FieldName := 'OrderNum';
    DSLabelsLineNum.SetParentComponent(DSLabels);
    DSLabelsLineNum.Name := 'DSLabelsLineNum';
    DSLabelsLineNum.FieldName := 'LineNum';
    DSLabelsUnit_.SetParentComponent(DSLabels);
    DSLabelsUnit_.Name := 'DSLabelsUnit_';
    DSLabelsUnit_.DisplayLabel := 'Unit';
    DSLabelsUnit_.FieldName := 'Unit_';
    DSLabelsUnit_.Size := 3;
    DSLabelsSize.SetParentComponent(DSLabels);
    DSLabelsSize.Name := 'DSLabelsSize';
    DSLabelsSize.FieldName := 'Size';
    DSLabelsSize.Size := 6;
    DSLabelsBrand.SetParentComponent(DSLabels);
    DSLabelsBrand.Name := 'DSLabelsBrand';
    DSLabelsBrand.FieldName := 'Brand';
    DSLabelsBrand.Size := 18;
    DSLabelsDescription.SetParentComponent(DSLabels);
    DSLabelsDescription.Name := 'DSLabelsDescription';
    DSLabelsDescription.FieldName := 'Description';
    DSLabelsDescription.Size := 25;
    DSLabelsDriverScan.SetParentComponent(DSLabels);
    DSLabelsDriverScan.Name := 'DSLabelsDriverScan';
    DSLabelsDriverScan.FieldName := 'DriverScan';
    DSLabelsDriverScan.Size := 1;
    DSLabelsTruck.SetParentComponent(DSLabels);
    DSLabelsTruck.Name := 'DSLabelsTruck';
    DSLabelsTruck.FieldName := 'Truck';
    DSLabelsStop.SetParentComponent(DSLabels);
    DSLabelsStop.Name := 'DSLabelsStop';
    DSLabelsStop.FieldName := 'Stop';
    DSLabelsProd.SetParentComponent(DSLabels);
    DSLabelsProd.Name := 'DSLabelsProd';
    DSLabelsProd.FieldName := 'Prod';
    DS_LabelCount.SetParentComponent(Self);
    DS_LabelCount.Name := 'DS_LabelCount';
    DS_LabelCount.AfterOpen := DS_LabelCountAfterOpen;
    DS_LabelCount.EntitySetName := 'GetLabels';
    DS_LabelCount.Connection := Dm.ApiConnection;
    DS_LabelCount.Left := 440;
    DS_LabelCount.Top := 104;
    DS_LabelCountTruck.SetParentComponent(DS_LabelCount);
    DS_LabelCountTruck.Name := 'DS_LabelCountTruck';
    DS_LabelCountTruck.FieldName := 'Truck';
    DS_LabelCountTruck.Required := True;
    DS_LabelCountStop.SetParentComponent(DS_LabelCount);
    DS_LabelCountStop.Name := 'DS_LabelCountStop';
    DS_LabelCountStop.FieldName := 'Stop';
    DS_LabelCountStop.Required := True;
    DS_LabelCountOrderNum.SetParentComponent(DS_LabelCount);
    DS_LabelCountOrderNum.Name := 'DS_LabelCountOrderNum';
    DS_LabelCountOrderNum.FieldName := 'OrderNum';
    DS_LabelCountOrderNum.Required := True;
    DS_LabelCountDryCases.SetParentComponent(DS_LabelCount);
    DS_LabelCountDryCases.Name := 'DS_LabelCountDryCases';
    DS_LabelCountDryCases.FieldName := 'DryCases';
    DS_LabelCountDrySingles.SetParentComponent(DS_LabelCount);
    DS_LabelCountDrySingles.Name := 'DS_LabelCountDrySingles';
    DS_LabelCountDrySingles.FieldName := 'DrySingles';
    DS_LabelCountCoolerCases.SetParentComponent(DS_LabelCount);
    DS_LabelCountCoolerCases.Name := 'DS_LabelCountCoolerCases';
    DS_LabelCountCoolerCases.FieldName := 'CoolerCases';
    DS_LabelCountCoolerSingles.SetParentComponent(DS_LabelCount);
    DS_LabelCountCoolerSingles.Name := 'DS_LabelCountCoolerSingles';
    DS_LabelCountCoolerSingles.FieldName := 'CoolerSingles';
    DS_LabelCountFreezerCases.SetParentComponent(DS_LabelCount);
    DS_LabelCountFreezerCases.Name := 'DS_LabelCountFreezerCases';
    DS_LabelCountFreezerCases.FieldName := 'FreezerCases';
    DS_LabelCountFreezerSingles.SetParentComponent(DS_LabelCount);
    DS_LabelCountFreezerSingles.Name := 'DS_LabelCountFreezerSingles';
    DS_LabelCountFreezerSingles.FieldName := 'FreezerSingles';
    DS_ScanLabels.SetParentComponent(Self);
    DS_ScanLabels.Name := 'DS_ScanLabels';
    DS_ScanLabels.AfterApplyUpdates := DSLabelsAfterApplyUpdates;
    DS_ScanLabels.AfterOpen := DSLabelsAfterOpen;
    DS_ScanLabels.EntitySetName := 'DriverLabels';
    DS_ScanLabels.Connection := Dm.ApiConnection;
    DS_ScanLabels.Left := 616;
    DS_ScanLabels.Top := 40;
    IntegerField1.SetParentComponent(DS_ScanLabels);
    IntegerField1.Name := 'IntegerField1';
    IntegerField1.FieldName := 'AssignNum';
    IntegerField1.Required := True;
    IntegerField2.SetParentComponent(DS_ScanLabels);
    IntegerField2.Name := 'IntegerField2';
    IntegerField2.FieldName := 'RPNum';
    IntegerField2.Required := True;
    IntegerField3.SetParentComponent(DS_ScanLabels);
    IntegerField3.Name := 'IntegerField3';
    IntegerField3.FieldName := 'OrderNum';
    IntegerField4.SetParentComponent(DS_ScanLabels);
    IntegerField4.Name := 'IntegerField4';
    IntegerField4.FieldName := 'LineNum';
    StringField1.SetParentComponent(DS_ScanLabels);
    StringField1.Name := 'StringField1';
    StringField1.DisplayLabel := 'Unit';
    StringField1.FieldName := 'Unit_';
    StringField1.Size := 3;
    StringField2.SetParentComponent(DS_ScanLabels);
    StringField2.Name := 'StringField2';
    StringField2.FieldName := 'Size';
    StringField2.Size := 6;
    StringField3.SetParentComponent(DS_ScanLabels);
    StringField3.Name := 'StringField3';
    StringField3.FieldName := 'Brand';
    StringField3.Size := 18;
    StringField4.SetParentComponent(DS_ScanLabels);
    StringField4.Name := 'StringField4';
    StringField4.FieldName := 'Description';
    StringField4.Size := 25;
    StringField5.SetParentComponent(DS_ScanLabels);
    StringField5.Name := 'StringField5';
    StringField5.FieldName := 'DriverScan';
    StringField5.Size := 1;
    IntegerField5.SetParentComponent(DS_ScanLabels);
    IntegerField5.Name := 'IntegerField5';
    IntegerField5.FieldName := 'Truck';
    IntegerField6.SetParentComponent(DS_ScanLabels);
    IntegerField6.Name := 'IntegerField6';
    IntegerField6.FieldName := 'Stop';
    IntegerField7.SetParentComponent(DS_ScanLabels);
    IntegerField7.Name := 'IntegerField7';
    IntegerField7.FieldName := 'Prod';
    WDS_ScanLabels.SetParentComponent(Self);
    WDS_ScanLabels.Name := 'WDS_ScanLabels';
    WDS_ScanLabels.DataSet := DS_ScanLabels;
    WDS_ScanLabels.Left := 640;
    WDS_ScanLabels.Top := 88;
  finally
    CameraPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WMPCorrect.AfterLoadDFMValues;
    WMPWrong.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WDSLabels.AfterLoadDFMValues;
    DSLabels.AfterLoadDFMValues;
    DSLabelsAssignNum.AfterLoadDFMValues;
    DSLabelsRPNum.AfterLoadDFMValues;
    DSLabelsOrderNum.AfterLoadDFMValues;
    DSLabelsLineNum.AfterLoadDFMValues;
    DSLabelsUnit_.AfterLoadDFMValues;
    DSLabelsSize.AfterLoadDFMValues;
    DSLabelsBrand.AfterLoadDFMValues;
    DSLabelsDescription.AfterLoadDFMValues;
    DSLabelsDriverScan.AfterLoadDFMValues;
    DSLabelsTruck.AfterLoadDFMValues;
    DSLabelsStop.AfterLoadDFMValues;
    DSLabelsProd.AfterLoadDFMValues;
    DS_LabelCount.AfterLoadDFMValues;
    DS_LabelCountTruck.AfterLoadDFMValues;
    DS_LabelCountStop.AfterLoadDFMValues;
    DS_LabelCountOrderNum.AfterLoadDFMValues;
    DS_LabelCountDryCases.AfterLoadDFMValues;
    DS_LabelCountDrySingles.AfterLoadDFMValues;
    DS_LabelCountCoolerCases.AfterLoadDFMValues;
    DS_LabelCountCoolerSingles.AfterLoadDFMValues;
    DS_LabelCountFreezerCases.AfterLoadDFMValues;
    DS_LabelCountFreezerSingles.AfterLoadDFMValues;
    DS_ScanLabels.AfterLoadDFMValues;
    IntegerField1.AfterLoadDFMValues;
    IntegerField2.AfterLoadDFMValues;
    IntegerField3.AfterLoadDFMValues;
    IntegerField4.AfterLoadDFMValues;
    StringField1.AfterLoadDFMValues;
    StringField2.AfterLoadDFMValues;
    StringField3.AfterLoadDFMValues;
    StringField4.AfterLoadDFMValues;
    StringField5.AfterLoadDFMValues;
    IntegerField5.AfterLoadDFMValues;
    IntegerField6.AfterLoadDFMValues;
    IntegerField7.AfterLoadDFMValues;
    WDS_ScanLabels.AfterLoadDFMValues;
  end;
end;

end.
