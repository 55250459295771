unit Login;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset,
  WEBLib.JQCtrls;

type
  TFLogin = class(TForm)
    LoginPnl: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    VersionTxt: TLabel;
    UsernameEdit: TEdit;
    PasswordEdit: TEdit;
    LoginBtn: TButton;
    DSLogin: TXDataWebDataSet;
    DSLoginNumber: TFloatField;
    DSLoginName: TStringField;
    DSLoginOnlineUser: TStringField;
    DSLoginOnlinePass: TStringField;
    DSLoginAccessToken: TStringField;
    DSLoginLastRouteDate: TDateTimeField;
    DSLoginLastRoute: TIntegerField;
    DSLoginDoublesFlag: TStringField;
    procedure LoginBtnClick(Sender: TObject);
    procedure DSLoginAfterOpen(Dataset: TDataSet);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FLogin: TFLogin;

implementation

Uses Data, SelectRoute, Inspection;

{$R *.dfm}

procedure TFLogin.DSLoginAfterOpen(Dataset: TDataSet);
begin
  if (DSLogin.RecordCount > 0) and
    (DSLoginOnlinePass.AsString = PasswordEdit.Text) then
  begin
    Dm.DSLogin.QueryString := '$filter=(Number eq '+DSLoginNumber.AsString+')';
    Dm.DSLogin.Load;
    Application.CreateForm(TFInspection, FInspection);
  end
  else
  begin
    DSLogin.Close;
    ShowMessage('Invalid Login Info');
  end;
end;

procedure TFLogin.LoginBtnClick(Sender: TObject);
begin
  if UsernameEdit.Text = '' then
  begin
    ShowMessage('Please Enter a Username');
    Exit;
  end;
  if PasswordEdit.Text = '' then
  begin
    ShowMessage('Please Enter a Password');
    Exit;
  end;
  DSLogin.QueryString := '$filter=(OnlineUser eq ''' +
    UsernameEdit.Text + ''')';
  DSLogin.Load;
end;

procedure TFLogin.WebButton1Click(Sender: TObject);
begin
  ShowMessage('screen width = ' + IntToStr(FLogin.Width) + ', screen height = '
    + IntToStr(FLogin.Height));
end;

procedure TFLogin.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'Login' then
  begin
    FLogin.Height := 699;
    FLogin.Width := 393;
    FLogin.Close;
    Application.CreateForm(TFLogin, FLogin);
  end;
end;

procedure TFLogin.WebFormResize(Sender: TObject);
begin
  // ScaleBy (4,3);
  { LoginPnl.HeightPercent := Round((FLogin.Height/720)*100);
    LoginPnl.Left := Round((FLogin.Width/2)-(LoginPnl.Width/2));
    LoginPnl.Top := Round((FLogin.Height/2)-(LoginPnl.Height/2)); }
  if LoginPnl.Top < 0 then
    LoginPnl.Top := 0;
end;

procedure TFLogin.WebFormShow(Sender: TObject);
begin
  LoginPnl.Left := Round((FLogin.Width / 2) - (LoginPnl.Width / 2));
  LoginPnl.Top := Round((FLogin.Height / 2) - (LoginPnl.Height / 2));
  CurrentForm := 'Login';
  // VersionTxt.Caption :=
  // FLogin.ScaleBy(FLogin.Height/720,FLogin.Width/360);
end;

procedure TFLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  LoginPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  VersionTxt := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  PasswordEdit := TEdit.Create(Self);
  LoginBtn := TButton.Create(Self);
  DSLogin := TXDataWebDataSet.Create(Self);
  DSLoginNumber := TFloatField.Create(Self);
  DSLoginName := TStringField.Create(Self);
  DSLoginOnlineUser := TStringField.Create(Self);
  DSLoginOnlinePass := TStringField.Create(Self);
  DSLoginAccessToken := TStringField.Create(Self);
  DSLoginLastRouteDate := TDateTimeField.Create(Self);
  DSLoginLastRoute := TIntegerField.Create(Self);
  DSLoginDoublesFlag := TStringField.Create(Self);

  LoginPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  VersionTxt.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  PasswordEdit.BeforeLoadDFMValues;
  LoginBtn.BeforeLoadDFMValues;
  DSLogin.BeforeLoadDFMValues;
  DSLoginNumber.BeforeLoadDFMValues;
  DSLoginName.BeforeLoadDFMValues;
  DSLoginOnlineUser.BeforeLoadDFMValues;
  DSLoginOnlinePass.BeforeLoadDFMValues;
  DSLoginAccessToken.BeforeLoadDFMValues;
  DSLoginLastRouteDate.BeforeLoadDFMValues;
  DSLoginLastRoute.BeforeLoadDFMValues;
  DSLoginDoublesFlag.BeforeLoadDFMValues;
  try
    Name := 'FLogin';
    Width := 400;
    Height := 845;
    Align := alClient;
    Caption := 'DriverApp Login Page';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    LoginPnl.SetParentComponent(Self);
    LoginPnl.Name := 'LoginPnl';
    LoginPnl.AlignWithMargins := True;
    LoginPnl.Left := 47;
    LoginPnl.Top := 132;
    LoginPnl.Width := 295;
    LoginPnl.Height := 529;
    LoginPnl.ElementClassName := 'card';
    LoginPnl.HeightPercent := 10.000000000000000000;
    LoginPnl.Anchors := [];
    LoginPnl.ChildOrder := 5;
    LoginPnl.ElementBodyClassName := 'card-body';
    LoginPnl.Font.Charset := DEFAULT_CHARSET;
    LoginPnl.Font.Color := clWindowText;
    LoginPnl.Font.Height := -21;
    LoginPnl.Font.Name := 'Arial';
    LoginPnl.Font.Style := [];
    LoginPnl.ParentFont := False;
    WebLabel1.SetParentComponent(LoginPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 94;
    WebLabel1.Top := 97;
    WebLabel1.Width := 103;
    WebLabel1.Height := 23;
    WebLabel1.Anchors := [];
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Username';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -21;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightStyle := ssPercent;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthStyle := ssPercent;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(LoginPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 39;
    WebLabel3.Top := 26;
    WebLabel3.Width := 221;
    WebLabel3.Height := 22;
    WebLabel3.Anchors := [];
    WebLabel3.Caption := 'Maplevale Driver Assistant';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    VersionTxt.SetParentComponent(LoginPnl);
    VersionTxt.Name := 'VersionTxt';
    VersionTxt.Left := 274;
    VersionTxt.Top := 508;
    VersionTxt.Width := 18;
    VersionTxt.Height := 16;
    VersionTxt.Alignment := taRightJustify;
    VersionTxt.Anchors := [];
    VersionTxt.Caption := '4.0';
    VersionTxt.Font.Charset := DEFAULT_CHARSET;
    VersionTxt.Font.Color := clWindowText;
    VersionTxt.Font.Height := -13;
    VersionTxt.Font.Name := 'Arial';
    VersionTxt.Font.Style := [];
    VersionTxt.HeightPercent := 100.000000000000000000;
    VersionTxt.ParentFont := False;
    VersionTxt.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(LoginPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 94;
    WebLabel2.Top := 217;
    WebLabel2.Width := 103;
    WebLabel2.Height := 26;
    WebLabel2.Anchors := [];
    WebLabel2.AutoSize := False;
    WebLabel2.Caption := 'Password';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -21;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    UsernameEdit.SetParentComponent(LoginPnl);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 60;
    UsernameEdit.Top := 137;
    UsernameEdit.Width := 171;
    UsernameEdit.Height := 40;
    UsernameEdit.Anchors := [];
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := 'form-control';
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.WidthPercent := 100.000000000000000000;
    PasswordEdit.SetParentComponent(LoginPnl);
    PasswordEdit.Name := 'PasswordEdit';
    PasswordEdit.Left := 60;
    PasswordEdit.Top := 255;
    PasswordEdit.Width := 171;
    PasswordEdit.Height := 39;
    PasswordEdit.Anchors := [];
    PasswordEdit.ChildOrder := 1;
    PasswordEdit.ElementClassName := 'form-control';
    PasswordEdit.HeightPercent := 100.000000000000000000;
    PasswordEdit.PasswordChar := '*';
    PasswordEdit.WidthPercent := 100.000000000000000000;
    LoginBtn.SetParentComponent(LoginPnl);
    LoginBtn.Name := 'LoginBtn';
    LoginBtn.Left := 73;
    LoginBtn.Top := 331;
    LoginBtn.Width := 141;
    LoginBtn.Height := 53;
    LoginBtn.Anchors := [];
    LoginBtn.Caption := 'Login';
    LoginBtn.ChildOrder := 4;
    LoginBtn.ElementClassName := 'btn btn-primary';
    LoginBtn.HeightPercent := 100.000000000000000000;
    LoginBtn.WidthPercent := 100.000000000000000000;
    SetEvent(LoginBtn, Self, 'OnClick', 'LoginBtnClick');
    DSLogin.SetParentComponent(Self);
    DSLogin.Name := 'DSLogin';
    DSLogin.AfterOpen := DSLoginAfterOpen;
    DSLogin.EntitySetName := 'deldrivers';
    DSLogin.Connection := Dm.ApiConnection;
    DSLogin.Left := 56;
    DSLogin.Top := 584;
    DSLoginNumber.SetParentComponent(DSLogin);
    DSLoginNumber.Name := 'DSLoginNumber';
    DSLoginNumber.FieldName := 'Number';
    DSLoginNumber.Required := True;
    DSLoginName.SetParentComponent(DSLogin);
    DSLoginName.Name := 'DSLoginName';
    DSLoginName.FieldName := 'Name';
    DSLoginName.Size := 30;
    DSLoginOnlineUser.SetParentComponent(DSLogin);
    DSLoginOnlineUser.Name := 'DSLoginOnlineUser';
    DSLoginOnlineUser.FieldName := 'OnlineUser';
    DSLoginOnlineUser.Size := 30;
    DSLoginOnlinePass.SetParentComponent(DSLogin);
    DSLoginOnlinePass.Name := 'DSLoginOnlinePass';
    DSLoginOnlinePass.FieldName := 'OnlinePass';
    DSLoginOnlinePass.Size := 30;
    DSLoginAccessToken.SetParentComponent(DSLogin);
    DSLoginAccessToken.Name := 'DSLoginAccessToken';
    DSLoginAccessToken.FieldName := 'AccessToken';
    DSLoginAccessToken.Size := 30;
    DSLoginLastRouteDate.SetParentComponent(DSLogin);
    DSLoginLastRouteDate.Name := 'DSLoginLastRouteDate';
    DSLoginLastRouteDate.FieldName := 'LastRouteDate';
    DSLoginLastRoute.SetParentComponent(DSLogin);
    DSLoginLastRoute.Name := 'DSLoginLastRoute';
    DSLoginLastRoute.FieldName := 'LastRoute';
    DSLoginDoublesFlag.SetParentComponent(DSLogin);
    DSLoginDoublesFlag.Name := 'DSLoginDoublesFlag';
    DSLoginDoublesFlag.FieldName := 'DoublesFlag';
    DSLoginDoublesFlag.Size := 1;
  finally
    LoginPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    VersionTxt.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    PasswordEdit.AfterLoadDFMValues;
    LoginBtn.AfterLoadDFMValues;
    DSLogin.AfterLoadDFMValues;
    DSLoginNumber.AfterLoadDFMValues;
    DSLoginName.AfterLoadDFMValues;
    DSLoginOnlineUser.AfterLoadDFMValues;
    DSLoginOnlinePass.AfterLoadDFMValues;
    DSLoginAccessToken.AfterLoadDFMValues;
    DSLoginLastRouteDate.AfterLoadDFMValues;
    DSLoginLastRoute.AfterLoadDFMValues;
    DSLoginDoublesFlag.AfterLoadDFMValues;
  end;
end;

end.
