program DriverApp;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Login in 'Login.pas'{*.html},
  Data in 'Data.pas',
  OrderList in 'OrderList.pas'{*.html},
  CurrentStop in 'CurrentStop.pas'{*.html},
  SelectRoute in 'SelectRoute.pas'{*.html},
  OrderDetail in 'OrderDetail.pas'{*.html},
  SignatureCap in 'SignatureCap.pas'{*.html},
  Inspection in 'Inspection.pas'{*.html},
  Payment in 'Payment.pas'{*.html},
  InspectionEod in 'InspectionEod.pas'{*.html},
  Options in 'Options.pas'{*.html},
  FuelPurchase in 'FuelPurchase.pas'{*.html},
  Map in 'Map.pas'{*.html},
  SettleUp in 'SettleUp.pas'{*.html},
  Accident in 'Accident.pas'{*.html},
  AccidentDetail in 'AccidentDetail.pas'{*.html},
  Statement in 'Statement.pas'{*.html},
  Returns in 'Returns.pas'{*.html},
  Deposit in 'Deposit.pas'{*.html},
  Pickups in 'Pickups.pas'{*.html},
  PickupsMap in 'PickupsMap.pas'{*.html},
  Feedback in 'Feedback.pas'{*.html},
  Expense in 'Expense.pas'{*.html},
  ProdScanCamera in 'ProdScanCamera.pas'{*.html},
  RFCRecap in 'RFCRecap.pas'{*.html},
  MSDS in 'MSDS.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  // Vcl.Forms.Application.Title := 'Maplevale Driver App';
  Application.CreateForm(TFLogin, FLogin);
  Application.CreateFormDirect(TDm, Dm);
  // Application.CreateForm(TFMSDS, FMSDS);
  // Application.CreateForm(TFProdScanCamera, FProdScanCamera);
  // Application.CreateForm(TFRFCRecap, FRFCRecap);
  // Application.CreateForm(TFExpsense, FExpsense);
  // Application.CreateForm(TFFeedback, FFeedback);
  // Application.CreateForm(TFPickups, FPickups);
  // Application.CreateForm(TFPickupMap, FPickupMap);
  // Application.CreateForm(TFDeposit, FDeposit);
  // Application.CreateForm(TFStatement, FStatement);
  // Application.CreateForm(TFAccidentPhotos, FAccidentPhotos);
  // Application.CreateForm(TFAccidentDiagram, FAccidentDiagram);
  // Application.CreateForm(TFAccidentDetail, FAccidentDetail);
  // Application.CreateForm(TFAccident, FAccident);
  // Application.CreateForm(TFSettleUp, FSettleUp);
  // Application.CreateForm(TFMap, FMap);
  // Application.CreateForm(TFReturns, FReturns);
  // Application.CreateForm(TFFuelPurchase, FFuelPurchase);
  // Application.CreateForm(TFInspectionEod, FInspectionEod);
  // Application.CreateForm(TFInspectionEod, FInspectionEod);
  // Application.CreateForm(TFOptions, FOptions);
  // Application.CreateForm(TFPayment, FPayment);
  // Application.CreateForm(TFProdScanCamera, FProdScanCamera);
  // Application.CreateForm(TFInspection, FInspection);
  // Application.CreateForm(TFSignatureCap, FSignatureCap);
  // Application.CreateForm(TFTest, FTest);
  // Application.CreateForm(TFOrderDetail, FOrderDetail);
  // Application.CreateForm(TFRouteSelect, FRouteSelect);
  // Application.CreateForm(TFOrderList, FOrderList);
  // Application.CreateForm(TFCurrentStop, FCurrentStop);
  // Application.CreateForm(TFSelectRoute, FSelectRoute);
  Application.Run;
end.
