unit FuelPurchase;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.ExtCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB;

type
  TFFuelPurchase = class(TForm)
    FuelPnl: TPanel;
    CloseBtn: TButton;
    WDSFuelPurchase: TDataSource;
    DSFuelPurchase: TXDataWebDataSet;
    DSFuelPurchaseAutoInc: TIntegerField;
    DSFuelPurchaseVehicleNum: TIntegerField;
    DSFuelPurchasePurchaseDateTime: TDateTimeField;
    DSFuelPurchasePurchaseGallons: TFloatField;
    DSFuelPurchasePurchaseDollars: TFloatField;
    DSFuelPurchaseOdometerReading: TFloatField;
    DSFuelPurchaseDriverNum: TIntegerField;
    DSFuelPurchasePurchaseState: TStringField;
    EditGallons: TEdit;
    WebLabel1: TLabel;
    WebComboBox1: TComboBox;
    WebLabel2: TLabel;
    EditDollars: TEdit;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    EditVehNum: TEdit;
    SaveBtn: TButton;
    WebLabel5: TLabel;
    EditOdometer: TEdit;
    procedure CloseBtnClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FFuelPurchase: TFFuelPurchase;

implementation

Uses Data, Options;

{$R *.dfm}

procedure TFFuelPurchase.CloseBtnClick(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFFuelPurchase.SaveBtnClick(Sender: TObject);
begin
  if WebComboBox1.ItemIndex < 1 then
  begin
    ShowMessage('Select a State');
    Exit;
  end;
  if EditGallons.Text = '' then
  begin
    ShowMessage('Enter Gallons Purchased');
    Exit;
  end;
  if EditDollars.Text = '' then
  begin
    ShowMessage('Enter Dollars Purchased');
    Exit;
  end;
  if EditVehNum.Text = '' then
  begin
    ShowMessage('Enter Vehicle Number');
    Exit;
  end;
  if EditOdometer.Text = '' then
  begin
    ShowMessage('Enter an Odometer Reading');
    Exit;
  end;
  DSFuelPurchase.Append;
  DSFuelPurchaseVehicleNum.AsString := EditVehNum.Text;
  DSFuelPurchasePurchaseDateTime.AsDateTime := Now;
  DSFuelPurchasePurchaseGallons.AsString := EditGallons.Text;
  DSFuelPurchasePurchaseDollars.AsString := EditDollars.Text;
  DSFuelPurchaseOdometerReading.AsString := EditOdometer.Text;
  DSFuelPurchaseDriverNum.AsInteger := Dm.DSLoginNumber.AsInteger;
  if WebComboBox1.ItemIndex = 1 then
    DSFuelPurchasePurchaseState.AsString := 'NY';
  if WebComboBox1.ItemIndex = 2 then
    DSFuelPurchasePurchaseState.AsString := 'PA';
  if WebComboBox1.ItemIndex = 3 then
    DSFuelPurchasePurchaseState.AsString := 'OH';
  DSFuelPurchase.Post;
  DSFuelPurchase.ApplyUpdates;
  ShowMessage('Fuel Purchase Saved Successfully');
  EditGallons.Text := '';
  EditDollars.Text := '';
  EditVehNum.Text := '';
  EditOdometer.Text := '';
end;

procedure TFFuelPurchase.WebFormResize(Sender: TObject);
begin
  FuelPnl.Left := Round((FFuelPurchase.Width / 2) - (FuelPnl.Width / 2));
  // FuelPnl.Top := Round((FFuelPurchase.Height/2)-(FuelPnl.Height/2));
  if FuelPnl.Top < 0 then
    FuelPnl.Top := 0;
end;

procedure TFFuelPurchase.WebFormShow(Sender: TObject);
begin
  DSFuelPurchase.QueryString := '$filter=(AutoInc eq 0)';
  DSFuelPurchase.Load;
  FuelPnl.Left := Round((FFuelPurchase.Width / 2) - (FuelPnl.Width / 2));
  FuelPnl.Top := Round((FFuelPurchase.Height / 2) - (FuelPnl.Height / 2));
  EditGallons.Text := '';
  EditDollars.Text := '';
  EditVehNum.Text := '';
  EditOdometer.Text := '';
end;

procedure TFFuelPurchase.LoadDFMValues;
begin
  inherited LoadDFMValues;

  FuelPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  CloseBtn := TButton.Create(Self);
  EditGallons := TEdit.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  EditDollars := TEdit.Create(Self);
  EditVehNum := TEdit.Create(Self);
  SaveBtn := TButton.Create(Self);
  EditOdometer := TEdit.Create(Self);
  WDSFuelPurchase := TDataSource.Create(Self);
  DSFuelPurchase := TXDataWebDataSet.Create(Self);
  DSFuelPurchaseAutoInc := TIntegerField.Create(Self);
  DSFuelPurchaseVehicleNum := TIntegerField.Create(Self);
  DSFuelPurchasePurchaseDateTime := TDateTimeField.Create(Self);
  DSFuelPurchasePurchaseGallons := TFloatField.Create(Self);
  DSFuelPurchasePurchaseDollars := TFloatField.Create(Self);
  DSFuelPurchaseOdometerReading := TFloatField.Create(Self);
  DSFuelPurchaseDriverNum := TIntegerField.Create(Self);
  DSFuelPurchasePurchaseState := TStringField.Create(Self);

  FuelPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  CloseBtn.BeforeLoadDFMValues;
  EditGallons.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  EditDollars.BeforeLoadDFMValues;
  EditVehNum.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  EditOdometer.BeforeLoadDFMValues;
  WDSFuelPurchase.BeforeLoadDFMValues;
  DSFuelPurchase.BeforeLoadDFMValues;
  DSFuelPurchaseAutoInc.BeforeLoadDFMValues;
  DSFuelPurchaseVehicleNum.BeforeLoadDFMValues;
  DSFuelPurchasePurchaseDateTime.BeforeLoadDFMValues;
  DSFuelPurchasePurchaseGallons.BeforeLoadDFMValues;
  DSFuelPurchasePurchaseDollars.BeforeLoadDFMValues;
  DSFuelPurchaseOdometerReading.BeforeLoadDFMValues;
  DSFuelPurchaseDriverNum.BeforeLoadDFMValues;
  DSFuelPurchasePurchaseState.BeforeLoadDFMValues;
  try
    Name := 'FFuelPurchase';
    Width := 412;
    Height := 756;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    FuelPnl.SetParentComponent(Self);
    FuelPnl.Name := 'FuelPnl';
    FuelPnl.Left := 16;
    FuelPnl.Top := 12;
    FuelPnl.Width := 377;
    FuelPnl.Height := 733;
    FuelPnl.ElementClassName := 'card';
    FuelPnl.ElementBodyClassName := 'card-body';
    FuelPnl.Font.Charset := DEFAULT_CHARSET;
    FuelPnl.Font.Color := clWindowText;
    FuelPnl.Font.Height := -19;
    FuelPnl.Font.Name := 'Arial';
    FuelPnl.Font.Style := [];
    FuelPnl.ParentFont := False;
    WebLabel1.SetParentComponent(FuelPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 43;
    WebLabel1.Width := 176;
    WebLabel1.Height := 22;
    WebLabel1.Caption := 'Fuel Purchase State:';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(FuelPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 152;
    WebLabel2.Width := 196;
    WebLabel2.Height := 22;
    WebLabel2.Caption := 'Fuel Purchase Gallons:';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(FuelPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 208;
    WebLabel3.Width := 191;
    WebLabel3.Height := 22;
    WebLabel3.Caption := 'Fuel Purchase Dollars:';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(FuelPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 24;
    WebLabel4.Top := 264;
    WebLabel4.Width := 180;
    WebLabel4.Height := 22;
    WebLabel4.Caption := 'Truck/Trailer Number:';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(FuelPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 24;
    WebLabel5.Top := 320;
    WebLabel5.Width := 158;
    WebLabel5.Height := 22;
    WebLabel5.Caption := 'Current Odometer:';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    CloseBtn.SetParentComponent(FuelPnl);
    CloseBtn.Name := 'CloseBtn';
    CloseBtn.Left := 200;
    CloseBtn.Top := 632;
    CloseBtn.Width := 161;
    CloseBtn.Height := 73;
    CloseBtn.Caption := 'Close';
    CloseBtn.ChildOrder := 1;
    CloseBtn.ElementClassName := 'btn btn-warning';
    CloseBtn.HeightPercent := 100.000000000000000000;
    CloseBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CloseBtn, Self, 'OnClick', 'CloseBtnClick');
    EditGallons.SetParentComponent(FuelPnl);
    EditGallons.Name := 'EditGallons';
    EditGallons.Left := 256;
    EditGallons.Top := 149;
    EditGallons.Width := 105;
    EditGallons.Height := 33;
    EditGallons.ChildOrder := 1;
    EditGallons.EditType := weFloat;
    EditGallons.HeightPercent := 100.000000000000000000;
    EditGallons.WidthPercent := 100.000000000000000000;
    WebComboBox1.SetParentComponent(FuelPnl);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 206;
    WebComboBox1.Top := 40;
    WebComboBox1.Width := 155;
    WebComboBox1.Height := 30;
    WebComboBox1.HeightPercent := 100.000000000000000000;
    WebComboBox1.Text := 'Select State..';
    WebComboBox1.WidthPercent := 100.000000000000000000;
    WebComboBox1.ItemIndex := 0;
    WebComboBox1.Items.BeginUpdate;
    try
      WebComboBox1.Items.Clear;
      WebComboBox1.Items.Add('Select State..');
      WebComboBox1.Items.Add('New York');
      WebComboBox1.Items.Add('Pennsylvania');
      WebComboBox1.Items.Add('Ohio');
    finally
      WebComboBox1.Items.EndUpdate;
    end;
    EditDollars.SetParentComponent(FuelPnl);
    EditDollars.Name := 'EditDollars';
    EditDollars.Left := 256;
    EditDollars.Top := 205;
    EditDollars.Width := 105;
    EditDollars.Height := 33;
    EditDollars.ChildOrder := 1;
    EditDollars.EditType := weFloat;
    EditDollars.HeightPercent := 100.000000000000000000;
    EditDollars.WidthPercent := 100.000000000000000000;
    EditVehNum.SetParentComponent(FuelPnl);
    EditVehNum.Name := 'EditVehNum';
    EditVehNum.Left := 296;
    EditVehNum.Top := 261;
    EditVehNum.Width := 65;
    EditVehNum.Height := 33;
    EditVehNum.ChildOrder := 1;
    EditVehNum.EditType := weNumeric;
    EditVehNum.HeightPercent := 100.000000000000000000;
    EditVehNum.WidthPercent := 100.000000000000000000;
    SaveBtn.SetParentComponent(FuelPnl);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 144;
    SaveBtn.Top := 408;
    SaveBtn.Width := 217;
    SaveBtn.Height := 73;
    SaveBtn.Caption := 'Save Fuel Purchase';
    SaveBtn.ChildOrder := 1;
    SaveBtn.ElementClassName := 'btn btn-success';
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    EditOdometer.SetParentComponent(FuelPnl);
    EditOdometer.Name := 'EditOdometer';
    EditOdometer.Left := 256;
    EditOdometer.Top := 317;
    EditOdometer.Width := 105;
    EditOdometer.Height := 33;
    EditOdometer.ChildOrder := 1;
    EditOdometer.EditType := weNumeric;
    EditOdometer.HeightPercent := 100.000000000000000000;
    EditOdometer.WidthPercent := 100.000000000000000000;
    WDSFuelPurchase.SetParentComponent(Self);
    WDSFuelPurchase.Name := 'WDSFuelPurchase';
    WDSFuelPurchase.DataSet := DSFuelPurchase;
    WDSFuelPurchase.Left := 40;
    WDSFuelPurchase.Top := 624;
    DSFuelPurchase.SetParentComponent(Self);
    DSFuelPurchase.Name := 'DSFuelPurchase';
    DSFuelPurchase.EntitySetName := 'fuelpurchase';
    DSFuelPurchase.Connection := Dm.ApiConnection;
    DSFuelPurchase.Left := 104;
    DSFuelPurchase.Top := 616;
    DSFuelPurchaseAutoInc.SetParentComponent(DSFuelPurchase);
    DSFuelPurchaseAutoInc.Name := 'DSFuelPurchaseAutoInc';
    DSFuelPurchaseAutoInc.FieldName := 'AutoInc';
    DSFuelPurchaseAutoInc.Required := True;
    DSFuelPurchaseVehicleNum.SetParentComponent(DSFuelPurchase);
    DSFuelPurchaseVehicleNum.Name := 'DSFuelPurchaseVehicleNum';
    DSFuelPurchaseVehicleNum.FieldName := 'VehicleNum';
    DSFuelPurchasePurchaseDateTime.SetParentComponent(DSFuelPurchase);
    DSFuelPurchasePurchaseDateTime.Name := 'DSFuelPurchasePurchaseDateTime';
    DSFuelPurchasePurchaseDateTime.FieldName := 'PurchaseDateTime';
    DSFuelPurchasePurchaseGallons.SetParentComponent(DSFuelPurchase);
    DSFuelPurchasePurchaseGallons.Name := 'DSFuelPurchasePurchaseGallons';
    DSFuelPurchasePurchaseGallons.FieldName := 'PurchaseGallons';
    DSFuelPurchasePurchaseDollars.SetParentComponent(DSFuelPurchase);
    DSFuelPurchasePurchaseDollars.Name := 'DSFuelPurchasePurchaseDollars';
    DSFuelPurchasePurchaseDollars.FieldName := 'PurchaseDollars';
    DSFuelPurchaseOdometerReading.SetParentComponent(DSFuelPurchase);
    DSFuelPurchaseOdometerReading.Name := 'DSFuelPurchaseOdometerReading';
    DSFuelPurchaseOdometerReading.FieldName := 'OdometerReading';
    DSFuelPurchaseDriverNum.SetParentComponent(DSFuelPurchase);
    DSFuelPurchaseDriverNum.Name := 'DSFuelPurchaseDriverNum';
    DSFuelPurchaseDriverNum.FieldName := 'DriverNum';
    DSFuelPurchasePurchaseState.SetParentComponent(DSFuelPurchase);
    DSFuelPurchasePurchaseState.Name := 'DSFuelPurchasePurchaseState';
    DSFuelPurchasePurchaseState.FieldName := 'PurchaseState';
    DSFuelPurchasePurchaseState.Size := 2;
  finally
    FuelPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    CloseBtn.AfterLoadDFMValues;
    EditGallons.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    EditDollars.AfterLoadDFMValues;
    EditVehNum.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    EditOdometer.AfterLoadDFMValues;
    WDSFuelPurchase.AfterLoadDFMValues;
    DSFuelPurchase.AfterLoadDFMValues;
    DSFuelPurchaseAutoInc.AfterLoadDFMValues;
    DSFuelPurchaseVehicleNum.AfterLoadDFMValues;
    DSFuelPurchasePurchaseDateTime.AfterLoadDFMValues;
    DSFuelPurchasePurchaseGallons.AfterLoadDFMValues;
    DSFuelPurchasePurchaseDollars.AfterLoadDFMValues;
    DSFuelPurchaseOdometerReading.AfterLoadDFMValues;
    DSFuelPurchaseDriverNum.AfterLoadDFMValues;
    DSFuelPurchasePurchaseState.AfterLoadDFMValues;
  end;
end;

end.
