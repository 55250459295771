{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2023                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.REST;

{$DEFINE NOPP}

interface

uses
  Classes, Web, JS, SysUtils, WEBLib.Controls, WEBLib.JSON;

type
  THTTPCommand = (httpGET, httpPOST, httpPUT, httpDELETE, httpHEAD, httpPATCH, httpCUSTOM);

  THTTPRequestResponseType = (rtDefault, rtText, rtBlob, rtJSON, rtDocument, rtArrayBuffer);

  THTTPResponseProc = reference to procedure(AResponse: string; ARequest: TJSXMLHttpRequest);
  THTTPErrorProc = reference to procedure(ARequest: TJSXMLHttpRequest);

  THTTPProgressEvent = procedure(Sender: TObject; Position, Total: int64) of object;

  THttpRequest = class(TComponent)
  private
    FURL: string;
    FOnResponse: THTTPResponseEvent;
    FOnAbort: THTTPAbortEvent;
    FHeaders: TStringList;
    FCommand: THTTPCommand;
    FCustomCommand: string;
    FPostData: string;
    FOnRequestResponse: THTTPRequestResponseEvent;
    FPassword: string;
    FUser: string;
    FTimeout: integer;
    FOnTimeout: TNotifyEvent;
    FOnError: THTTPErrorEvent;
    FResponse: THttpResponseProc;
    FErrorProc: THttpErrorProc;
    FTimeOutProc: THttpErrorProc;
    FResponseType: THTTPRequestResponseType;
    FOnProgress: THTTPProgressEvent;
  protected
    function HandleResponse(Event: TEventListenerEvent): boolean;
    function HandleAbort(Event: TEventListenerEvent): boolean;
    function HandleTimeout(Event: TEventListenerEvent): boolean;
    function HandleError(Event: TEventListenerEvent): boolean;
    function HandleProgress(Event: TEventListenerEvent): boolean;
    function HandleReadyStateChange(Event: TEventListenerEvent): boolean;
    procedure SetHeaders(const AValue: TStringList);
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    class function GetResponseType(AResponseType: THTTPRequestResponseType): string;
    procedure ExecuteSizeRequest(AResponse: THTTPResponseProc; AError: THTTPErrorProc; ATimeOut: THTTPErrorProc);
    procedure Execute(AResponse: THTTPResponseProc = nil); overload;
    procedure Execute(AResponse: THTTPResponseProc; AError: THTTPErrorProc); overload;
    procedure Execute(AResponse: THTTPResponseProc; AError: THTTPErrorProc; ATimeOut: THTTPErrorProc); overload;
    [async]
    function GetSize: TJSPromise;
    [async]
    function Perform: TJSPromise;
  published
    property Command: THTTPCommand read FCommand write FCommand;
    property CustomCommand: string read FCustomCommand write FCustomCommand;
    property Headers: TStringList read FHeaders write SetHeaders;
    property Password: string read FPassword write FPassword;
    property PostData: string read FPostData write FPostData;
    property ResponseType: THTTPRequestResponseType read FResponseType write FResponseType default rtDefault;
    property Timeout: integer read FTimeout write FTimeout default 0;
    property URL: string read FURL write FURL;
    property User: string read FUser write FUser;
    property OnError: THTTPErrorEvent read FOnError write FOnError;
    property OnAbort: THTTPAbortEvent read FOnAbort write FOnAbort;
    property OnProgress: THTTPProgressEvent read FOnProgress write FOnProgress;
    property OnRequestResponse: THTTPRequestResponseEvent read FOnRequestResponse write FOnRequestResponse;
    property OnResponse: THTTPResponseEvent read FOnResponse write FOnResponse;
    property OnTimeout: TNotifyEvent read FOnTimeout write FOnTimeout;
  end;

  TWebHTTPRequest = class(THTTPRequest);

  THttpResponse = procedure(Sender: TObject; AResponse: string) of object;
  TInternalHttpResponse = procedure(Sender: TObject; AResponse: string; var Handled: boolean) of object;

  TPersistTokens = class(TPersistent)
  private
    FKey: string;
    FEnabled: boolean;
  public
    constructor Create; reintroduce;
    procedure Assign(Source: TPersistent); override;
  published
    property Key: string read FKey write FKey;
    property Enabled: boolean read FEnabled write FEnabled default false;
  end;

  TRESTApp = class(TPersistent)
  private
    FKey: string;
    FCallbackURL: string;
    FSecret: string;
    FAuthURL: string;
  public
    procedure Assign(Source: TPersistent); override;
  published
    property AuthURL: string read FAuthURL write FAuthURL;
    property Key: string read FKey write FKey;
    property CallbackURL: string read FCallbackURL write FCallbackURL;
    property Secret: string read FSecret write FSecret;
  end;

  TAuthLocale = (lcDefault, lcEnglish, lcDutch, lcGerman, lcFrench, lcSpanish, lcItalian,
     lcPortuguese, lcGreek, lcDanish, lcRussian, lcRomanian, lcSwedish, lcFinnish, lcTurkish,
     lcJapanese);

  TCoreCloudHeader = record
    header: String;
    value: String;
  end;

  TCoreCloudHeaders = array of TCoreCloudHeader;

  TRESTClient = class(TComponent)
  private
    FAPIBase: string;
    FAccessToken: string;
    FOnAccessToken: TNotifyEvent;
    FEventRegistered: boolean;
    FOnResponse: THttpResponse;
    FPersistTokens: TPersistTokens;
    FOnHttpResponse: TInternalHttpResponse;
    FApp: TRESTApp;
    FScopes: TStrings;
    FLocale: TAuthLocale;
    FOnRequestResponse: THTTPRequestResponseEvent;
    FOnError: THttpErrorEvent;
    FResponseType: THTTPRequestResponseType;
    FLoginWidth: integer;
    FLoginHeight: integer;
    procedure SetPersistTokens(const Value: TPersistTokens);
    procedure SetApp(const Value: TRESTApp);
    procedure SetScopes(const Value: TStrings);
  protected
    class function InstallCallback: boolean;
    function HandleAccessToken(const s: TJSObject): boolean;
    function HandleResponse(Event: TEventListenerEvent): boolean;
    function GetAuthURL: string; virtual;
    function ScopeParamText(Delimiter: string; Encode: boolean = false): string;
    function GetLocaleParam: string; virtual;
    function GetJSONValue(o: TJSONObject; ID: string): TJSONValue;
    function GetJSONObject(o: TJSONObject; ID: string): TJSONValue;
    property APIBase: string read FAPIBase write FAPIBase;
    procedure ReadTokens; virtual;
    procedure WriteTokens; virtual;
    property Locale: TAuthLocale read FLocale write FLocale;
    property Token_Access: string read FAccessToken;
    function TMSUTF8Encode(s: string): string;
    procedure HttpsCommand(Command, URL, Data, ContentType: string; headers: TCoreCloudHeaders); virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure HttpsDelete(URL: string); overload; virtual;
    procedure HttpsDelete(URL: string; headers: TCoreCloudHeaders); overload; virtual;
    procedure HttpsGet(URL: string); overload; virtual;
    procedure HttpsGet(URL: string; headers: TCoreCloudHeaders); overload; virtual;
    procedure HttpsPost(URL: string; Data: string); overload; virtual;
    procedure HttpsPost(URL: string; ContentType, Data: string); overload; virtual;
    procedure HttpsPost(URL: string; headers: TCoreCloudHeaders; Data: string); overload; virtual;
    procedure HttpsUpdate(URL: string; headers: TCoreCloudHeaders; Data: string); virtual;
    procedure HttpsPut(URL: string; ContentType, Data: string); virtual;
    property AccessToken: string read FAccessToken write FAccessToken;
    class function URLEncode(URI: string): string;
    class function JSONEscape(s: string): string;
    class function IsoToDateTime(const s: string): TDateTime; overload;
    class function IsoToDateTime(const s: string; IsUTC: Boolean): TDateTime; overload;
    class function IsoToDate(const s: string): TDateTime;
    class function IsIsoDateTime(const s: string): boolean;
    class function DateTimeToWL(dt: TDateTime): string;
    class function DateTimeToDaylightSavings(dt: TDateTime): TDateTime;
    class function GetJSONProp(O: TJSONOBject; ID: string): string; overload;
    class function GetJSONInt(O: TJSONOBject; ID: string): integer; overload;
    procedure DoAuth; virtual;
    procedure Connect;
    procedure TestTokens; virtual;
    procedure ClearTokens; virtual;
    property OnHttpResponse: TInternalHttpResponse read FOnHttpResponse write FOnHttpResponse;
  published
    property App: TRESTApp read FApp write SetApp;
    property LoginHeight: integer read FLoginHeight write FLoginHeight default 600;
    property LoginWidth: integer read FLoginWidth write FLoginWidth default 800;
    property PersistTokens: TPersistTokens read FPersistTokens write SetPersistTokens;
    property ResponseType: THTTPRequestResponseType read FResponseType write FResponseType default rtDefault;
    property Scopes: TStrings read FScopes write SetScopes;
    property OnAccessToken: TNotifyEvent read FOnAccessToken write FOnAccessToken;
    property OnRequestResponse: THTTPRequestResponseEvent read FOnRequestResponse write FOnRequestResponse;
    property OnResponse: THttpResponse read FOnResponse write FOnResponse;
    property OnError: THttpErrorEvent read FOnError write FOnError;
  end;

  TWebRESTClient = class(TRESTClient);


procedure AddHeader(var AHeaders: TCoreCloudHeaders; Header: string; Value: string);
function GetArraySize(ja: TJSONArray): integer;
function GetArrayItem(ja: TJSONArray; Index: integer): TJSONObject;
function HTTPCommand(ACommand: THTTPCommand; ACustomCommand: string): string;

implementation

uses
  WEBLib.Storage;

function HTTPCommand(ACommand: THTTPCommand; ACustomCommand: string): string;
var
  cmd: string;
begin
  case ACommand of
    httpGET: cmd := 'GET';
    httpPOST: cmd := 'POST';
    httpPUT: cmd := 'PUT';
    httpDELETE: cmd := 'DELETE';
    httpHEAD: cmd := 'HEAD';
    httpPATCH: cmd := 'PATCH';
    httpCUSTOM: cmd := ACustomCommand;
  end;
  Result := cmd;
end;

function GetArraySize(ja: TJSONArray): integer;
begin
  Result := ja.Count;
end;

function GetArrayItem(ja: TJSONArray; Index: integer): TJSONObject;
begin
  Result := TJSONObject(ja.Items[Index]);
end;


procedure AddHeader(var AHeaders: TCoreCloudHeaders; Header: String; Value: String);
begin
  SetLength(AHeaders, Length(AHeaders) + 1);
  AHeaders[Length(AHeaders) - 1].header := Header;
  AHeaders[Length(AHeaders) - 1].value := Value;
end;

{ THttpRequest }

constructor THttpRequest.Create(AOwner: TComponent);
begin
  inherited;
  FHeaders := TStringList.Create;
  FResponseType := rtDefault;
  FCommand := httpGET;
  FTimeout := 0;
  FResponse := nil;
end;

destructor THttpRequest.Destroy;
begin
  FHeaders.Free;
  inherited;
end;

function THttpRequest.Perform: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      Execute(
        procedure(AResponse: string; ARequest: TJSXMLHttpRequest)
        begin
          ASuccess(ARequest);
        end,
        procedure(ARequest: TJSXMLHttpRequest)
        begin
          AFailed(ARequest);
        end,
        procedure(ARequest: TJSXMLHttpRequest)
        begin
          AFailed(ARequest);
        end);
    end);
end;

procedure THttpRequest.Execute(AResponse: THttpResponseProc = nil);
begin
  Execute(AResponse, nil, nil);
end;

procedure THttpRequest.Execute(AResponse: THTTPResponseProc; AError: THTTPErrorProc);
begin
  Execute(AResponse, AError, nil);
end;


procedure THttpRequest.Execute(AResponse: THTTPResponseProc; AError: THTTPErrorProc; ATimeOut: THTTPErrorProc);
var
  i: integer;
  cmd: string;
  req: TJSXMLHttpRequest;
  headname,headvalue: string;
begin
  FResponse := AResponse;
  FErrorProc := AError;
  FTimeOutProc := ATimeOut;

  req := TJSXMLHttpRequest.new;
  req.addEventListener('load', @HandleResponse);
  req.addEventListener('abort',@HandleAbort);
  req.addEventListener('timeout',@HandleTimeout);
  req.addEventListener('error',@HandleError);
  req.addEventListener('progress',@HandleProgress);
  req.responseType := THTTPRequest.GetResponseType(FResponseType);

  cmd := HTTPCommand(Command, CustomCommand);

  if FTimeout <> 0 then
    req.timeout := FTimeout;

  if (FUser <> '') then
  begin
    req.open(cmd, FURL, true);
    req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  end
  else
    req.open(cmd, FURL);

  for i := 0 to FHeaders.Count - 1 do
  begin
    FHeaders.GetNameValue(i, headname, headvalue);
    req.setRequestHeader(headname, headvalue);
  end;

  if (FUser <> '') then
    req.setRequestHeader('Authorization', 'Basic '+window.btoa(User+':'+Password));

  if PostData <> '' then
    req.send(PostData)
  else
    req.send;
end;

procedure THttpRequest.ExecuteSizeRequest(AResponse: THTTPResponseProc; AError: THTTPErrorProc; ATimeOut: THTTPErrorProc);
var
  i: integer;
  req: TJSXMLHttpRequest;
  headname,headvalue: string;
begin
  FResponse := AResponse;
  FErrorProc := AError;
  FTimeOutProc := ATimeOut;

  req := TJSXMLHttpRequest.new;
  req.addEventListener('readystatechange', @HandleReadyStateChange);
  req.addEventListener('timeout',@HandleTimeout);
  req.addEventListener('error',@HandleError);

  if FTimeout <> 0 then
    req.timeout := FTimeout;

  if (FUser <> '') then
  begin
    req.open('HEAD', FURL, true, FUser, FPassword);
    req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  end
  else
    req.open('HEAD', FURL);

  for i := 0 to FHeaders.Count - 1 do
  begin
    FHeaders.GetNameValue(i, headname, headvalue);
    req.setRequestHeader(headname, headvalue);
  end;

  if (FUser <> '') then
    req.setRequestHeader('Authorization', 'Basic '+window.btoa(User+':'+Password));

  req.send;
end;

class function THttpRequest.GetResponseType(AResponseType: THTTPRequestResponseType): string;
begin
  Result := '';
  case AResponseType of
  rtText: Result := 'text';
  rtBlob: Result := 'blob';
  rtDocument: Result := 'document';
  rtJSON: Result := 'json';
  rtArrayBuffer: Result := 'arraybuffer';
  end;
end;

function THttpRequest.GetSize: TJSPromise;
var
  sz: int64;
  e: integer;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      ExecuteSizeRequest(
        procedure(AResponse: string; ARequest: TJSXMLHttpRequest)
        begin
          val(AResponse, sz, e);
          ASuccess(sz);
        end,
        procedure(ARequest: TJSXMLHttpRequest)
        begin
          AFailed(0);
        end,
        procedure(ARequest: TJSXMLHttpRequest)
        begin
          AFailed(ARequest);
        end)
    end);
end;

function THttpRequest.HandleAbort(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnAbort) then
    OnAbort(Self);
  Result := true;
end;

function THttpRequest.HandleError(Event: TEventListenerEvent): boolean;
var
  Handled: boolean;
  LEventRec: TJSEventRecord;
  LRequestRec: TJSXMLHttpRequestRecord;
begin
  Result := true;
  Handled := false;

  if Assigned(FOnError) then
  begin
    LEventRec.event := Event;
    LRequestRec.req := TJSXMLHttpRequest(Event.Target);
    FOnError(Self, LRequestRec, LEventRec, Handled);
  end;

  if Assigned(FErrorProc) then
    FErrorProc(LRequestRec.req);

  if not Handled then
    raise Exception.Create('HTTP request error @' + URL);
end;

function THttpRequest.HandleProgress(Event: TEventListenerEvent): boolean;
var
  position, total: int64;
begin
  Result := true;

  asm
    position = Event.loaded;
    total = Event.total;
  end;

  if Assigned(OnProgress) then
    OnProgress(Self, position, total);
end;

function THttpRequest.HandleReadyStateChange(
  Event: TEventListenerEvent): boolean;
var
  LRequestRec: TJSXMLHttpRequestRecord;
  sz: string;
begin
  Result := true;
  LRequestRec.req := TJSXMLHttpRequest(Event.target);

  if (LRequestRec.req.readyState = 4) then
  begin
    if (LRequestRec.req.status >= 200) and (LRequestRec.req.status < 300) then
    begin
      sz := LRequestRec.req.getResponseHeader('Content-Length');

      if Assigned(FResponse) then
      begin
        FResponse(sz, LRequestRec.req);
      end;
    end
    else
      if Assigned(FErrorProc) then
        FErrorProc(LRequestRec.req);
  end;
end;

function THttpRequest.HandleResponse(Event: TEventListenerEvent): boolean;
var
  s: string;
  LRequestRec: TJSXMLHttpRequestRecord;

begin
  LRequestRec.req := TJSXMLHttpRequest(Event.target);

  s := '';
  if ResponseType in [rtText, rtDefault] then
  begin
    asm
      s = Event.target.responseText;
    end;
  end;

  if Assigned(OnRequestResponse) then
  begin
    OnRequestResponse(Self, LRequestRec, s);
  end;

  if Assigned(OnResponse) then
    OnResponse(Self, s);

  if Assigned(FResponse) then
  begin
    FResponse(s, LRequestRec.req);
  end;

  Result := true;
end;

function THttpRequest.HandleTimeout(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnTimeout) then
    OnTimeout(Self);

  if Assigned(FTimeOutProc) then
    FTimeOutProc(TJSXMLHttpRequest(Event.Target));

  Result := true;
end;

procedure THttpRequest.SetHeaders(const AValue: TStringList);
begin
  FHeaders.Assign(AValue);
end;


{ TRESTClient }

procedure TRESTClient.ClearTokens;
begin
  AccessToken := '';
  WriteTokens;
end;

procedure TRESTClient.Connect;
begin
  if not FEventRegistered then
  begin
    document.addEventListener('oauthcallback', @HandleAccessToken);
    FEventRegistered := true;
  end;

  if FPersistTokens.Enabled then
  begin
    ReadTokens;

    if AccessToken <> '' then
    begin
      TestTokens;
    end
    else
      DoAuth;
  end
  else
    DoAuth;
end;

constructor TRESTClient.Create(AOwner: TComponent);
begin
  inherited;
  FPersistTokens := TPersistTokens.Create;
  FApp := TRESTApp.Create;
  FScopes := TStringList.Create;
  FLocale := lcDefault;
  FResponseType := rtDefault;
  FLoginWidth := 800;
  FLoginHeight := 600;
end;

function IntToZStr(i,l: Integer): string;
var
  Res: string;
begin
  Res := IntToStr(i);
  while Length(Res)<l do
    Res := '0' + Res;

  Result := Res;
end;


class function TRESTClient.DateTimeToDaylightSavings(dt: TDateTime): TDateTime;
begin
  Result := dt;
end;

class function TRESTClient.DateTimeToWL(dt: TDateTime): string;
var
  da,mo,ye,ho,mi,se,ms:word;
//  lTimeZone: TTimeZoneInformation;
begin
//  GetTimeZoneInformation(lTimeZone);

  DecodeDate(dt,ye,mo,da);
  DecodeTime(dt,ho,mi,se,ms);
  Result := IntToStr(ye) + '-' + IntToZStr(mo,2) + '-' + IntToZStr(da,2) + 'T' +
            IntToZStr(ho,2) + ':' + IntToZStr(mi,2) + ':' + IntToZStr(se,2);

//    Result := Result + '+';
//  case GetTimeZoneInformation(tz) of
//    TIME_ZONE_ID_STANDARD: Result := Result + IntToZStr((- lTimeZone.Bias) div 60,2) + ':00';
//    TIME_ZONE_ID_DAYLIGHT: Result := Result + IntToZStr((- lTimeZone.Bias - lTimeZone.DaylightBias) div 60,2) + ':00';
//  else
//    Result := Result + '00:00';
//  end;

  Result := Result + '+00:00';

end;

destructor TRESTClient.Destroy;
begin
  FApp.Free;
  FScopes.Free;
  FPersistTokens.Free;
  inherited;
end;

{$HINTS OFF}
procedure TRESTClient.DoAuth;
var
  URL: string;
  param: string;
begin
  URL := GetAuthURL;
  param := 'width=' + FLoginWidth.ToString + ',height='+ FLoginHeight.ToString +',location=no,toolbar=no,menubar=no';

  asm
    window.open(URL, 'oauth', param);
  end;
end;
{$HINTS ON}

function TRESTClient.GetAuthURL: string;
begin
  Result := App.AuthURL;
end;

class function TRESTClient.GetJSONInt(O: TJSONOBject; ID: string): integer;
var
  jv: JSValue;
begin

  jv := o.JSObject.Properties[ID];

  if isNumber(jv) then
    Result := integer(jv);
end;

{$HINTS OFF}
function TRESTClient.GetJSONObject(o: TJSONObject; ID: string): TJSONValue;
var
  ro: TJSObject;
  jv: JSValue;
begin
  jv := o.JSObject.Properties[ID];

  asm
    ro = jv;
  end;

  if isArray(ro) then
  begin
    Result := TJSONArray.Create(TJSArray(ro));
    //(Result as TJSONArray).ja := ;
  end
  else
  begin
    Result := TJSONObject.Create(ro);
    //Result.jo := ro;
  end;
end;
{$HINTS ON}

class function TRESTClient.GetJSONProp(O: TJSONOBject; ID: string): string;
begin
  Result := O.GetJSONValue(ID);
end;

function TRESTClient.GetJSONValue(o: TJSONObject; ID: string): TJSONValue;
var
  js: JSValue;
  res: boolean;
  jvb: boolean;
begin
  Result := nil;

  js := o.JSObject.Properties[ID];

  res := false;

  asm
    res = (js != undefined)
  end;

  if res then
  begin
    if isboolean(js) then
    begin
      asm
        jvb = js;
      end;

      if jvb then
        Result := TJSONTrue.Create
      else
        Result := TJSONFalse.Create;
    end;

    Result := TJSONValue.Create(js);
  end;
end;

function TRESTClient.GetLocaleParam: string;
begin
  //
  Result := '';
end;

function TRESTClient.HandleAccessToken(const s: TJSObject): boolean;
var
  token: string;
begin
  asm
    token = s.detail.message;
  end;

  FAccessToken := token;

  WriteTokens;

  if Assigned(OnAccessToken) then
    OnAccessToken(Self);

  Result := true;
end;

function TRESTClient.HandleResponse(Event: TEventListenerEvent): boolean;
var
  Response: string;
  Handled: boolean;
  LRequestRec: TJSXMLHttpRequestRecord;

begin
  LRequestRec.req := TJSXMLHttpRequest(Event.target);

  Response := '';

  if (ResponseType in [rtText, rtDefault]) then
  begin
    asm
      Response = Event.target.responseText;
    end;
  end;

  Handled := false;

  if Assigned(FOnHttpResponse) then
    FOnHttpResponse(Self, Response, Handled);

  if Assigned(OnRequestResponse) and not Handled then
    OnRequestResponse(Self, LRequestRec, Response);

  if Assigned(OnResponse) and not Handled then
    OnResponse(Self, Response);

  Result := true;
end;

procedure TRESTClient.HttpsCommand(Command, URL, Data, ContentType: string; headers: TCoreCloudHeaders);
var
  i: integer;
  req: TJSXMLHttpRequest;
  FRequestResponse: THTTPRequestResponseEvent;
  FResponse: THttpResponse;
  FHttpResponse: TInternalHttpResponse;

  function ResponseHandler(Event: TEventListenerEvent): boolean;
  var
    Response: string;
    Handled: boolean;
    LRequestRec: TJSXMLHttpRequestRecord;

  begin
    LRequestRec.req := TJSXMLHttpRequest(Event.target);

    asm
      Response = Event.target.responseText;
    end;

    Handled := false;

    if Assigned(FHttpResponse) then
      FHttpResponse(Self, Response, Handled);

    if Assigned(FRequestResponse) and not Handled then
    begin
      FRequestResponse(Self, LRequestRec, Response);
    end;

    if Assigned(FResponse) and not Handled then
    begin
      FResponse(Self, Response);
    end;

    Result := true;
  end;

  function ErrorHandler(Event: TEventListenerEvent): boolean;
  var
    Handled: boolean;
    LEventRec: TJSEventRecord;
    LRequestRec: TJSXMLHttpRequestRecord;
  begin
    Handled := false;
    if Assigned(FOnError) then
    begin
      LRequestRec.req := TJSXMLHttpRequest(Event.target);
      LEventRec.event := Event;

      FOnError(Self, LRequestRec, LEventRec, Handled);
    end;

    if not Handled then
      raise Exception.Create('HTTP request error @' + URL);

    Result := true;
  end;

begin
  FRequestResponse := OnRequestResponse;
  FResponse := OnResponse;
  FHttpResponse := OnHttpResponse;

  req := TJSXMLHttpRequest.new;
  //req.addEventListener('load', @HandleResponse);
  req.addEventListener('load', @ResponseHandler);
  req.addEventListener('error', @ErrorHandler);

  req.responseType := THTTPRequest.GetResponseType(FResponseType);

  req.open(Command, URL, true);

  if ContentType <> '' then
    req.setRequestHeader('Content-Type', ContentType);

  if FAccessToken <> '' then
    req.setRequestHeader('Authorization', 'Bearer ' + FAccessToken);

  if Assigned(headers) then
  begin
    for i := 0 to Length(headers) - 1 do
      req.setRequestHeader(headers[i].header, headers[i].value);
  end;

  if Data = '' then
    req.send(nil)
  else
    req.send(Data);
end;

procedure TRESTClient.HttpsDelete(URL: string);
begin
  HttpsCommand('DELETE', URL, '','', nil);
end;

procedure TRESTClient.HttpsDelete(URL: string; headers: TCoreCloudHeaders);
begin
  HttpsCommand('DELETE', URL,'','', headers);
end;

procedure TRESTClient.HttpsGet(URL: string; headers: TCoreCloudHeaders);
begin
  HttpsCommand('GET', URL,'','', headers);
end;

procedure TRESTClient.HttpsGet(URL: string);
begin
  HttpsCommand('GET', URL,'','', nil);
end;

procedure TRESTClient.HttpsPost(URL, ContentType, Data: string);
begin
  HttpsCommand('POST',URL,Data,ContentType,nil);
end;

procedure TRESTClient.HttpsPost(URL: string; headers: TCoreCloudHeaders;
  Data: string);
begin
  HttpsCommand('POST', URL, Data, '', headers);
end;

procedure TRESTClient.HttpsPut(URL, ContentType, Data: string);
begin
  HttpsCommand('PUT', URL, Data, ContentType, nil);
end;

procedure TRESTClient.HttpsUpdate(URL: string; headers: TCoreCloudHeaders;
  Data: string);
begin
  HttpsCommand('PUT', URL, Data, '', headers);
end;

procedure TRESTClient.HttpsPost(URL, Data: string);
begin
  HttpsPost(URL, Data, '');
end;

{$HINTS OFF}
class function TRESTClient.InstallCallback: boolean;
var
  scriptsrc: string;
begin
  scriptsrc :=
       ' function processAuthData(access_token) {'
      +'var event = new CustomEvent("oauthcallback", {'#13
			+'            detail: {'#13
			+'                 message: access_token'#13
			+'            },'#13
			+'  bubbles: true,'#13
			+'  cancelable: true});'#13
      +'  document.dispatchEvent(event);'
      +'}';

  asm
    var script = document.createElement("script");
    script.innerHTML = scriptsrc;
    document.head.appendChild(script);

    var scr = document.createElement('script');
    scr.async = true;
    scr.defer = true;
    scr.type = 'text/javascript';
    document.body.appendChild(scr);
  end;

  Result := true;
end;
{$HINTS ON}

class function TRESTClient.IsoToDate(const s: string): TDateTime;
var
  da,mo,ye: Word;
  err: Integer;
begin
  Val(Copy(s,1,4),ye,err);
  Val(Copy(s,6,2),mo,err);
  Val(Copy(s,9,2),da,err);

  if ye < 1 then
    ye := 1;
  if mo < 1 then
    mo := 1;
  if da < 1 then
    da := 1;

  Result := EncodeDate(ye,mo,da) + EncodeTime(0,0,0,0);
end;

class function TRESTClient.IsIsoDateTime(const s: string): boolean;
var
  da,mo,ye,ho,mi,se: Word;
  err: Integer;
begin
  Result := true;

  Val(Copy(s,1,4),ye,err);
  if err <> 0 then
    exit(False);
  if not (copy(s,5,1) = '-') then
    exit(False);

  Val(Copy(s,6,2),mo,err);
  if err <> 0 then
    exit(False);

  if not (copy(s,8,1) = '-') then
    exit(False);

  Val(Copy(s,9,2),da,err);
  if err <> 0 then
    exit(False);

  Val(Copy(s,12,2),ho,err);
  if err = 0 then
  begin
    if not (copy(s,14,1) = ':') then
      exit(False);

    Val(Copy(s,15,2),mi,err);
    if err <> 0 then
      exit(False);

    if not (copy(s,17,1) = ':') then
      exit(False);

    Val(Copy(s,18,2),se,err);
    if err <> 0 then
      exit(False);
  end;
end;


class function TRESTClient.IsoToDateTime(const s: string;
  IsUTC: Boolean): TDateTime;
var
  da,mo,ye,ho,mi,se,ms: Word;
  HourOffset, MinuteOffset: Integer;
  err,delta: Integer;
  sign: string;
begin
  HourOffset := 0;
  MinuteOffset := 0;
  ms := 0;
  delta := 0;

  Val(Copy(s,1,4),ye,err);
  Val(Copy(s,6,2),mo,err);
  Val(Copy(s,9,2),da,err);
  Val(Copy(s,12,2),ho,err);
  Val(Copy(s,15,2),mi,err);
  Val(Copy(s,18,2),se,err);

  if (length(s) >= 20) and (s[20] = '.') then
  begin
    Val(Copy(s,21,3),ms,err);
    delta := 4;
  end;

  if ye < 1 then
    ye := 1;
  if mo < 1 then
    mo := 1;
  if da < 1 then
    da := 1;

  //Get TimeOffset from ISO string
  if Length(s) > 20 + delta then
  begin
    sign := Copy(s,20 + delta,1);
    Val(Copy(s,22 + delta,2), HourOffset, err);
    Val(Copy(s,25 + delta,2), MinuteOffset, err);

    if sign = '-' then
    begin
      HourOffset := HourOffset * -1;
      MinuteOffset := MinuteOffset * -1;
    end;
  end;

  Result := EncodeDate(ye,mo,da) + EncodeTime(ho,mi,se,ms);
//  Result := AdjustDateTime(Result, HourOffset, MinuteOffset, IsUTC);
end;

class function TRESTClient.IsoToDateTime(const s: string): TDateTime;
var
  da,mo,ye,ho,mi,se,ms: Word;
  err: Integer;
begin
  ms := 0;

  Val(Copy(s,1,4),ye,err);
  Val(Copy(s,6,2),mo,err);
  Val(Copy(s,9,2),da,err);
  Val(Copy(s,12,2),ho,err);
  Val(Copy(s,15,2),mi,err);
  Val(Copy(s,18,2),se,err);

  if (length(s) >= 20) and (s[20] = '.') then
  begin
    Val(Copy(s,21,3),ms,err);
  end;


  if ye < 1 then
    ye := 1;
  if mo < 1 then
    mo := 1;
  if da < 1 then
    da := 1;

  Result := EncodeDate(ye,mo,da) + EncodeTime(ho,mi,se,ms);
end;

class function TRESTClient.JSONEscape(s: string): string;
begin
  Result := encodeURIComponent(s);
end;

procedure TRESTClient.ReadTokens;
var
  ls: TLocalStorage;
begin
  // retrieve the token from local storage
  if FPersistTokens.Enabled and (FPersistTokens.Key <> '') then
  begin
    ls := TLocalStorage.Create;
    FAccessToken := ls.Values[FPersistTokens.Key];
    ls.Free;
  end;

end;

function TRESTClient.ScopeParamText(Delimiter: string; Encode: boolean): string;
var
  i: integer;
  scopestr: string;
begin
  Result := '';
  for i := 0 to Scopes.Count - 1 do
  begin
    if Encode then
      scopestr := encodeURIComponent(Scopes[i])
    else
      scopestr := Scopes[i];

    if Result = '' then
      Result := scopestr
    else
      Result := Result + Delimiter + scopestr;
  end;
end;

procedure TRESTClient.SetApp(const Value: TRESTApp);
begin
  FApp.Assign(Value);
end;

procedure TRESTClient.SetPersistTokens(const Value: TPersistTokens);
begin
  FPersistTokens.Assign(Value);
end;

procedure TRESTClient.SetScopes(const Value: TStrings);
begin
  FScopes.Assign(Value);
end;

procedure TRESTClient.TestTokens;
begin
  if Assigned(OnAccessToken) then
    OnAccessToken(Self);
end;

function TRESTClient.TMSUTF8Encode(s: string): string;
begin
  Result := s;
end;

class function TRESTClient.URLEncode(URI: string): string;
begin
  Result := encodeURIComponent(URI);
end;

procedure TRESTClient.WriteTokens;
var
  ls: TLocalStorage;
begin
  if FPersistTokens.Enabled and (FPersistTokens.Key <> '') then
  begin
    ls := TLocalStorage.Create;
    ls.Values[FPersistTokens.Key] := FAccessToken;
    ls.Free;
  end;
end;

{ TPersistTokens }

procedure TPersistTokens.Assign(Source: TPersistent);
begin
  if (Source is TPersistTokens) then
  begin
    FKey := (Source as TPersistTokens).Key;
    FEnabled := (Source as TPersistTokens).Enabled;
  end;
end;

constructor TPersistTokens.Create;
begin
  FEnabled := false;
  FKey := '';
end;

{ TRESTApp }

procedure TRESTApp.Assign(Source: TPersistent);
begin
  if (Source is TRESTApp) then
  begin
    FKey := (Source as TRESTApp).Key;
    FCallbackURL := (Source as TRESTApp).CallbackURL;
    FSecret := (Source as TRESTApp).Secret;
    FAuthURL := (Source as TRESTApp).AuthURL;
  end;
end;


begin
  TRESTClient.InstallCallback;

end.
