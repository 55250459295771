unit Payment;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.DBCtrls, Vcl.Grids, Data.DB, WEBLib.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, System.StrUtils;

type
  TFPayment = class(TForm)
    PaymentPnl: TPanel;
    WebButton1: TButton;
    WebLabelPm: TLabel;
    PaymentAmt: TEdit;
    WebLabel2: TLabel;
    WCBPaymentType: TComboBox;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebDBGrid1: TDBGrid;
    CheckNum: TEdit;
    WebLabel7: TLabel;
    DSPayments: TXDataWebDataSet;
    WDSPayments: TDataSource;
    DSPaymentsAutoInc: TIntegerField;
    DSPaymentsInvoice: TIntegerField;
    DSPaymentsCust: TIntegerField;
    DSPaymentsPaymentType: TStringField;
    DSPaymentsCheckNumber: TIntegerField;
    DSPaymentsPaymentAmount: TFloatField;
    DSPaymentsDate: TDateTimeField;
    WebButton2: TButton;
    WebButton3: TButton;
    WebMessageDlg1: TMessageDlg;
    WebLabel1: TLabel;
    DSPaymentsRoute: TIntegerField;
    DSPaymentsDriverNum: TIntegerField;
    WebDBLabel22: TDBLabel;
    WebButton4: TButton;
    WebLabel8: TLabel;
    CustNum: TEdit;
    DSCust: TXDataWebDataSet;
    WDSCust: TDataSource;
    WebButton5: TButton;
    DSCustCust: TIntegerField;
    DSCustCustName: TStringField;
    DSCustSRNum: TIntegerField;
    DSCustAddress1: TStringField;
    DSCustAddress2: TStringField;
    DSCustCity: TStringField;
    DSCustState: TStringField;
    DSCustTermsExplained: TStringField;
    DSCustLatitude: TFloatField;
    DSCustLongitude: TFloatField;
    procedure WebFormShow(Sender: TObject);
    procedure DSPaymentsAfterOpen(Dataset: TDataSet);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebMessageDlg1Close(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure DSCustAfterOpen(DataSet: TDataSet);
    procedure CustNumClick(Sender: TObject);
    procedure PaymentAmtChange(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FPayment: TFPayment;
  TempPaymentAmt: Double;

implementation

{$R *.dfm}

Uses Data, CurrentStop, OrderDetail, Statement;

procedure TFPayment.CustNumClick(Sender: TObject);
begin
  CustNum.Clear;
end;

procedure TFPayment.DSCustAfterOpen(DataSet: TDataSet);
begin
  if DSCust.RecordCount = 0 then
  begin
    ShowMessage('Invalid Account Number');
    Exit;
  end;
end;

procedure TFPayment.DSPaymentsAfterOpen(Dataset: TDataSet);
begin
  TempPaymentAmt := 0;
  DSPayments.First;
  while not DSPayments.Eof do
  begin
    TempPaymentAmt := TempPaymentAmt + DSPaymentsPaymentAmount.AsFloat;
    DSPayments.Next;
  end;
  DSPayments.First;
  WebLabel6.Caption := FloatToStrF(TempPaymentAmt, ffFixed, 7, 2);
  WebLabel1.Caption := FloatToStrF(TempPaymentAmt -
    Dm.DSCurrStopOrdersInvoiceTotal.AsFloat, ffFixed, 7, 2);
  if StrToFloat(WebLabel1.Caption) < 0 then
    WebLabel1.Font.Color := clRed
  else
    WebLabel1.Font.Color := clGreen;
  WebLabel1.Left := 300;
end;

procedure TFPayment.PaymentAmtChange(Sender: TObject);
begin
  if (PaymentAmt.Text <> '') then
  begin
    if not AnsiContainsStr(PaymentAmt.Text,'.') then
      WebLabelPm.Caption := FloatToStrF(StrToFloat(PaymentAmt.Text)/100,ffFixed,6,2)
    else WebLabelPm.Caption := FloatToStrF(StrToFloat(PaymentAmt.Text),ffFixed,6,2);
  end
  else WebLabelPm.Caption := 'Payment Amount';
end;

procedure TFPayment.WebButton1Click(Sender: TObject);
begin
  if WCBPaymentType.ItemIndex = 0 then
  begin
    ShowMessage('Select a Payment Type');
    Exit;
  end;
  if (CheckNum.Text = '') and (WCBPaymentType.ItemIndex = 1) then
  begin
    ShowMessage('Enter a Check Number');
    Exit;
  end;
  if PaymentAmt.Text = '' then
  begin
    ShowMessage('Enter a Payment Amount');
    Exit;
  end;
  if DSCust.RecordCount = 0 then
  begin
    ShowMessage('Invalid Account Number');
    Exit;
  end;
  if not AnsiContainsStr(PaymentAmt.Text,'.') then
  begin
    PaymentAmt.Text := FloatToStrF(StrToFloat(PaymentAmt.Text)/100,ffFixed,6,2);
  end;
  if StrToFloat(PaymentAmt.Text) > (1.50*Dm.DSCurrStopOrdersInvoiceTotal.AsFloat) then
  begin
    ShowMessage('You entered much more than the invoice amount - if this is wrong please delete below and correct');
  end;
  if StrToFloat(PaymentAmt.Text) < (0.25*Dm.DSCurrStopOrdersInvoiceTotal.AsFloat) then
  begin
    ShowMessage('You entered much less than the invoice amount - if this is wrong please delete below and correct');
  end;
  DSPayments.Append;
  if Dm.DSCurrStopOrdersCust.AsInteger = DSCustCust.AsInteger then
  begin
    DSPaymentsInvoice.AsInteger := Dm.DSCurrStopOrdersInvoiceNum.AsInteger;
    DSPaymentsCust.AsInteger := Dm.DSCurrStopOrdersCust.AsInteger;
  end
  else
  begin
    DSPaymentsInvoice.AsInteger := 0;
    DSPaymentsCust.AsInteger := DSCustCust.AsInteger;
  end;
  if WCBPaymentType.ItemIndex = 1 then
    DSPaymentsPaymentType.AsString := 'Check';
  if WCBPaymentType.ItemIndex = 2 then
    DSPaymentsPaymentType.AsString := 'Cash';
  if WCBPaymentType.ItemIndex = 3 then
    DSPaymentsPaymentType.AsString := 'Postdated';
  if WCBPaymentType.ItemIndex = 1 then
    DSPaymentsCheckNumber.AsString := CheckNum.Text;
  DSPaymentsPaymentAmount.AsString := PaymentAmt.Text;
  DSPaymentsDate.AsDateTime := Now;
  DSPaymentsRoute.AsInteger := Dm.DSLoginLastRoute.AsInteger;
  DSPaymentsDriverNum.AsInteger := Dm.DSLoginNumber.AsInteger;
  DSPayments.Post;
  DSPayments.ApplyUpdates;
  TempPaymentAmt := 0;
  DSPayments.First;
  while not DSPayments.Eof do
  begin
    TempPaymentAmt := TempPaymentAmt + DSPaymentsPaymentAmount.AsFloat;
    DSPayments.Next;
  end;
  DSPayments.First;
  WebLabel6.Caption := FloatToStrF(TempPaymentAmt, ffFixed, 7, 2);
  WebLabel1.Caption := FloatToStrF(TempPaymentAmt -
    Dm.DSCurrStopOrdersInvoiceTotal.AsFloat, ffFixed, 7, 2);
  if StrToFloat(WebLabel1.Caption) < 0 then
    WebLabel1.Font.Color := clRed
  else
    WebLabel1.Font.Color := clGreen;
  WCBPaymentType.ItemIndex := 0;
  WebLabel1.Left := 300;
  PaymentAmt.Text := '';
  CheckNum.Text := '';
  WebLabelPm.Caption := 'Payment Amount';
end;

procedure TFPayment.WebButton2Click(Sender: TObject);
begin
  Application.CreateForm(TFOrderDtl, FOrderDtl);
  //FPayment.Close;
end;

procedure TFPayment.WebButton3Click(Sender: TObject);
begin
  WebMessageDlg1.ShowDialog('Delete Payment?', WEBLib.Dialogs.mtConfirmation,
    [mbNo, mbYes]);
end;

procedure TFPayment.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFStatement, FStatement);
  FormCreatedBy := 'Payment';
end;

procedure TFPayment.WebButton5Click(Sender: TObject);
begin
  if CustNum.Text <> '' then
  begin
    DSCust.Close;
    DSCust.QueryString := '$filter=(Cust eq ' + CustNum.Text +')';
    DSCust.Load;
  end;
end;

procedure TFPayment.WebFormResize(Sender: TObject);
begin
  PaymentPnl.Left := Round((FPayment.Width / 2) - (PaymentPnl.Width / 2));
  if PaymentPnl.Top < 0 then
    PaymentPnl.Top := 0;
end;

procedure TFPayment.WebFormShow(Sender: TObject);
begin
  TempPaymentAmt := 0;
  DSPayments.QueryString := '$filter=(Invoice eq ' +
    Dm.DSCurrStopOrdersInvoiceNum.AsString + ' AND Cust eq ' +
    Dm.DSCurrStopOrdersCust.AsString + ')';
  DSPayments.Load;
  DSCust.QueryString := '$filter=(Cust eq '+Dm.DSCurrStopOrdersCust.AsString+')';  //.$filter=(cuscode eq 3)
  DSCust.Load;
  PaymentPnl.Left := Round((FPayment.Width / 2) - (PaymentPnl.Width / 2));
  PaymentPnl.Top := Round((FPayment.Height / 2) - (PaymentPnl.Height / 2));
  CurrentForm := 'Payment';
  CustNum.Text := Dm.DSCurrStopOrdersCust.AsString;
end;

procedure TFPayment.WebMessageDlg1Close(Sender: TObject);
begin
  if (WebMessageDlg1.DialogResult = 6) and (DSPayments.RecordCount > 0) then
  begin
    DSPayments.Delete;
    DSPayments.ApplyUpdates;
    TempPaymentAmt := 0;
    DSPayments.First;
    while not DSPayments.Eof do
    begin
      TempPaymentAmt := TempPaymentAmt + DSPaymentsPaymentAmount.AsFloat;
      DSPayments.Next;
    end;
    DSPayments.First;
    WebLabel6.Caption := FloatToStrF(TempPaymentAmt, ffFixed, 7, 2);
    WebLabel1.Caption :=
      FloatToStrF(TempPaymentAmt - Dm.DSCurrStopOrdersInvoiceTotal.AsFloat,
      ffFixed, 7, 2);
    if StrToFloat(WebLabel1.Caption) < 0 then
      WebLabel1.Font.Color := clRed
    else
      WebLabel1.Font.Color := clGreen;
    WCBPaymentType.ItemIndex := 0;
    WebLabel1.Left := 300;
    PaymentAmt.Text := '';
    CheckNum.Text := '';
    DSPayments.QueryString := '$filter=(Invoice eq ' +
      Dm.DSCurrStopOrdersInvoiceNum.AsString + ' AND Cust eq ' +
      Dm.DSCurrStopOrdersCust.AsString + ')';
    DSPayments.Load;
  end;
end;

procedure TFPayment.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PaymentPnl := TPanel.Create(Self);
  WebLabelPm := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebDBLabel22 := TDBLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  PaymentAmt := TEdit.Create(Self);
  WCBPaymentType := TComboBox.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  CheckNum := TEdit.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebMessageDlg1 := TMessageDlg.Create(Self);
  WebButton4 := TButton.Create(Self);
  CustNum := TEdit.Create(Self);
  WebButton5 := TButton.Create(Self);
  DSPayments := TXDataWebDataSet.Create(Self);
  DSPaymentsAutoInc := TIntegerField.Create(Self);
  DSPaymentsInvoice := TIntegerField.Create(Self);
  DSPaymentsCust := TIntegerField.Create(Self);
  DSPaymentsPaymentType := TStringField.Create(Self);
  DSPaymentsCheckNumber := TIntegerField.Create(Self);
  DSPaymentsPaymentAmount := TFloatField.Create(Self);
  DSPaymentsDate := TDateTimeField.Create(Self);
  DSPaymentsRoute := TIntegerField.Create(Self);
  DSPaymentsDriverNum := TIntegerField.Create(Self);
  WDSPayments := TDataSource.Create(Self);
  DSCust := TXDataWebDataSet.Create(Self);
  DSCustCust := TIntegerField.Create(Self);
  DSCustCustName := TStringField.Create(Self);
  DSCustSRNum := TIntegerField.Create(Self);
  DSCustAddress1 := TStringField.Create(Self);
  DSCustAddress2 := TStringField.Create(Self);
  DSCustCity := TStringField.Create(Self);
  DSCustState := TStringField.Create(Self);
  DSCustTermsExplained := TStringField.Create(Self);
  DSCustLatitude := TFloatField.Create(Self);
  DSCustLongitude := TFloatField.Create(Self);
  WDSCust := TDataSource.Create(Self);

  PaymentPnl.BeforeLoadDFMValues;
  WebLabelPm.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebDBLabel22.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  PaymentAmt.BeforeLoadDFMValues;
  WCBPaymentType.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  CheckNum.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebMessageDlg1.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  CustNum.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  DSPayments.BeforeLoadDFMValues;
  DSPaymentsAutoInc.BeforeLoadDFMValues;
  DSPaymentsInvoice.BeforeLoadDFMValues;
  DSPaymentsCust.BeforeLoadDFMValues;
  DSPaymentsPaymentType.BeforeLoadDFMValues;
  DSPaymentsCheckNumber.BeforeLoadDFMValues;
  DSPaymentsPaymentAmount.BeforeLoadDFMValues;
  DSPaymentsDate.BeforeLoadDFMValues;
  DSPaymentsRoute.BeforeLoadDFMValues;
  DSPaymentsDriverNum.BeforeLoadDFMValues;
  WDSPayments.BeforeLoadDFMValues;
  DSCust.BeforeLoadDFMValues;
  DSCustCust.BeforeLoadDFMValues;
  DSCustCustName.BeforeLoadDFMValues;
  DSCustSRNum.BeforeLoadDFMValues;
  DSCustAddress1.BeforeLoadDFMValues;
  DSCustAddress2.BeforeLoadDFMValues;
  DSCustCity.BeforeLoadDFMValues;
  DSCustState.BeforeLoadDFMValues;
  DSCustTermsExplained.BeforeLoadDFMValues;
  DSCustLatitude.BeforeLoadDFMValues;
  DSCustLongitude.BeforeLoadDFMValues;
  WDSCust.BeforeLoadDFMValues;
  try
    Name := 'FPayment';
    Width := 412;
    Height := 769;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    PaymentPnl.SetParentComponent(Self);
    PaymentPnl.Name := 'PaymentPnl';
    PaymentPnl.Left := 8;
    PaymentPnl.Top := 8;
    PaymentPnl.Width := 396;
    PaymentPnl.Height := 753;
    PaymentPnl.ElementClassName := 'card';
    PaymentPnl.ElementBodyClassName := 'card-body';
    WebLabelPm.SetParentComponent(PaymentPnl);
    WebLabelPm.Name := 'WebLabelPm';
    WebLabelPm.Left := 14;
    WebLabelPm.Top := 327;
    WebLabelPm.Width := 143;
    WebLabelPm.Height := 22;
    WebLabelPm.Caption := 'Payment Amount';
    WebLabelPm.Font.Charset := DEFAULT_CHARSET;
    WebLabelPm.Font.Color := clWindowText;
    WebLabelPm.Font.Height := -19;
    WebLabelPm.Font.Name := 'Arial';
    WebLabelPm.Font.Style := [];
    WebLabelPm.HeightPercent := 100.000000000000000000;
    WebLabelPm.ParentFont := False;
    WebLabelPm.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(PaymentPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 14;
    WebLabel2.Top := 227;
    WebLabel2.Width := 121;
    WebLabel2.Height := 22;
    WebLabel2.Caption := 'Payment Type';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel1.SetParentComponent(PaymentPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 11;
    WebDBLabel1.Top := 64;
    WebDBLabel1.Width := 50;
    WebDBLabel1.Height := 22;
    WebDBLabel1.Caption := 'Cust#';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -19;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'Cust';
    WebDBLabel1.DataSource := WDSCust;
    WebDBLabel2.SetParentComponent(PaymentPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 80;
    WebDBLabel2.Top := 64;
    WebDBLabel2.Width := 50;
    WebDBLabel2.Height := 22;
    WebDBLabel2.Caption := 'Name';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -19;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'CustName';
    WebDBLabel2.DataSource := WDSCust;
    WebDBLabel3.SetParentComponent(PaymentPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 172;
    WebDBLabel3.Top := 120;
    WebDBLabel3.Width := 61;
    WebDBLabel3.Height := 22;
    WebDBLabel3.Caption := 'Invoice';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clWindowText;
    WebDBLabel3.Font.Height := -19;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'InvoiceNum';
    WebDBLabel3.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel4.SetParentComponent(PaymentPnl);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 180;
    WebDBLabel4.Top := 144;
    WebDBLabel4.Width := 61;
    WebDBLabel4.Height := 22;
    WebDBLabel4.Alignment := taRightJustify;
    WebDBLabel4.Caption := 'Invoice';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -19;
    WebDBLabel4.Font.Name := 'Arial';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'InvoiceTotal';
    WebDBLabel4.DataSource := Dm.WDSCurrStopOrders;
    WebLabel3.SetParentComponent(PaymentPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 68;
    WebLabel3.Top := 120;
    WebLabel3.Width := 82;
    WebLabel3.Height := 22;
    WebLabel3.Caption := 'Invoice #:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(PaymentPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 80;
    WebLabel4.Top := 144;
    WebLabel4.Width := 70;
    WebLabel4.Height := 22;
    WebLabel4.Caption := 'Amount:';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -19;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(PaymentPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 11;
    WebLabel5.Top := 179;
    WebLabel5.Width := 134;
    WebLabel5.Height := 22;
    WebLabel5.Caption := 'Total Payments:';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -19;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(PaymentPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 203;
    WebLabel6.Top := 179;
    WebLabel6.Width := 38;
    WebLabel6.Height := 22;
    WebLabel6.Alignment := taRightJustify;
    WebLabel6.Caption := '0.00';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -19;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(PaymentPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 14;
    WebLabel7.Top := 271;
    WebLabel7.Width := 126;
    WebLabel7.Height := 22;
    WebLabel7.Caption := 'Check Number';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -19;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(PaymentPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 299;
    WebLabel1.Top := 179;
    WebLabel1.Width := 38;
    WebLabel1.Height := 22;
    WebLabel1.Alignment := taRightJustify;
    WebLabel1.Caption := '0.00';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel22.SetParentComponent(PaymentPnl);
    WebDBLabel22.Name := 'WebDBLabel22';
    WebDBLabel22.Left := 80;
    WebDBLabel22.Top := 92;
    WebDBLabel22.Width := 115;
    WebDBLabel22.Height := 18;
    WebDBLabel22.Caption := 'Terms Explained';
    WebDBLabel22.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel22.Font.Color := clWindowText;
    WebDBLabel22.Font.Height := -16;
    WebDBLabel22.Font.Name := 'Arial';
    WebDBLabel22.Font.Style := [];
    WebDBLabel22.HeightPercent := 100.000000000000000000;
    WebDBLabel22.ParentFont := False;
    WebDBLabel22.WidthPercent := 100.000000000000000000;
    WebDBLabel22.DataField := 'TermsExplained';
    WebDBLabel22.DataSource := WDSCust;
    WebLabel8.SetParentComponent(PaymentPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 14;
    WebLabel8.Top := 15;
    WebLabel8.Width := 161;
    WebLabel8.Height := 22;
    WebLabel8.Caption := 'Customer Override';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -19;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(PaymentPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 216;
    WebButton1.Top := 371;
    WebButton1.Width := 161;
    WebButton1.Height := 73;
    WebButton1.Caption := 'Add Payment';
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.ParentFont := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    PaymentAmt.SetParentComponent(PaymentPnl);
    PaymentAmt.Name := 'PaymentAmt';
    PaymentAmt.Left := 256;
    PaymentAmt.Top := 316;
    PaymentAmt.Width := 121;
    PaymentAmt.Height := 44;
    PaymentAmt.ChildOrder := 3;
    PaymentAmt.EditType := weFloat;
    PaymentAmt.ElementClassName := 'form-control';
    PaymentAmt.Font.Charset := DEFAULT_CHARSET;
    PaymentAmt.Font.Color := clWindowText;
    PaymentAmt.Font.Height := -27;
    PaymentAmt.Font.Name := 'Arial';
    PaymentAmt.Font.Style := [];
    PaymentAmt.HeightPercent := 100.000000000000000000;
    PaymentAmt.ParentFont := False;
    PaymentAmt.WidthPercent := 100.000000000000000000;
    SetEvent(PaymentAmt, Self, 'OnChange', 'PaymentAmtChange');
    WCBPaymentType.SetParentComponent(PaymentPnl);
    WCBPaymentType.Name := 'WCBPaymentType';
    WCBPaymentType.Left := 144;
    WCBPaymentType.Top := 222;
    WCBPaymentType.Width := 233;
    WCBPaymentType.Height := 30;
    WCBPaymentType.Font.Charset := DEFAULT_CHARSET;
    WCBPaymentType.Font.Color := clWindowText;
    WCBPaymentType.Font.Height := -19;
    WCBPaymentType.Font.Name := 'Arial';
    WCBPaymentType.Font.Style := [];
    WCBPaymentType.HeightPercent := 100.000000000000000000;
    WCBPaymentType.ParentFont := False;
    WCBPaymentType.Text := 'Select Payment Type..';
    WCBPaymentType.WidthPercent := 100.000000000000000000;
    WCBPaymentType.ItemIndex := 0;
    WCBPaymentType.Items.BeginUpdate;
    try
      WCBPaymentType.Items.Clear;
      WCBPaymentType.Items.Add('Select Payment Type..');
      WCBPaymentType.Items.Add('Check');
      WCBPaymentType.Items.Add('Cash');
      WCBPaymentType.Items.Add('Postdated Check');
    finally
      WCBPaymentType.Items.EndUpdate;
    end;
    WebDBGrid1.SetParentComponent(PaymentPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 14;
    WebDBGrid1.Top := 464;
    WebDBGrid1.Width := 350;
    WebDBGrid1.Height := 204;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'PaymentType';
      Title := 'Type';
      Width := 110;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'CheckNumber';
      Title := 'Check#';
      Width := 121;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'PaymentAmount';
      Title := 'Amount';
      Width := 90;
    end;
    WebDBGrid1.DataSource := WDSPayments;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Font.Charset := DEFAULT_CHARSET;
    WebDBGrid1.Font.Color := clWindowText;
    WebDBGrid1.Font.Height := -16;
    WebDBGrid1.Font.Name := 'Arial';
    WebDBGrid1.Font.Style := [];
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    WebDBGrid1.ParentFont := False;
    WebDBGrid1.TabOrder := 3;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 110;
    WebDBGrid1.ColWidths[1] := 121;
    WebDBGrid1.ColWidths[2] := 90;
    CheckNum.SetParentComponent(PaymentPnl);
    CheckNum.Name := 'CheckNum';
    CheckNum.Left := 256;
    CheckNum.Top := 260;
    CheckNum.Width := 121;
    CheckNum.Height := 44;
    CheckNum.ChildOrder := 3;
    CheckNum.EditType := weFloat;
    CheckNum.ElementClassName := 'form-control';
    CheckNum.Font.Charset := DEFAULT_CHARSET;
    CheckNum.Font.Color := clWindowText;
    CheckNum.Font.Height := -27;
    CheckNum.Font.Name := 'Arial';
    CheckNum.Font.Style := [];
    CheckNum.HeightPercent := 100.000000000000000000;
    CheckNum.ParentFont := False;
    CheckNum.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(PaymentPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 216;
    WebButton2.Top := 680;
    WebButton2.Width := 145;
    WebButton2.Height := 65;
    WebButton2.Caption := 'Close';
    WebButton2.ChildOrder := 16;
    WebButton2.ElementClassName := 'btn btn-danger';
    WebButton2.Font.Charset := DEFAULT_CHARSET;
    WebButton2.Font.Color := clWindowText;
    WebButton2.Font.Height := -19;
    WebButton2.Font.Name := 'Arial';
    WebButton2.Font.Style := [];
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.ParentFont := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(PaymentPnl);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 11;
    WebButton3.Top := 680;
    WebButton3.Width := 145;
    WebButton3.Height := 65;
    WebButton3.Caption := 'Delete Payment';
    WebButton3.ChildOrder := 16;
    WebButton3.ElementClassName := 'btn btn-warning';
    WebButton3.Font.Charset := DEFAULT_CHARSET;
    WebButton3.Font.Color := clWindowText;
    WebButton3.Font.Height := -19;
    WebButton3.Font.Name := 'Arial';
    WebButton3.Font.Style := [];
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.ParentFont := False;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebMessageDlg1.SetParentComponent(PaymentPnl);
    WebMessageDlg1.Name := 'WebMessageDlg1';
    WebMessageDlg1.Left := 24;
    WebMessageDlg1.Top := 554;
    WebMessageDlg1.Width := 24;
    WebMessageDlg1.Height := 24;
    WebMessageDlg1.Buttons := [];
    WebMessageDlg1.DialogType := mtConfirmation;
    WebMessageDlg1.Opacity := 0.200000000000000000;
    SetEvent(WebMessageDlg1, Self, 'OnClose', 'WebMessageDlg1Close');
    WebButton4.SetParentComponent(PaymentPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 14;
    WebButton4.Top := 371;
    WebButton4.Width := 161;
    WebButton4.Height := 73;
    WebButton4.Caption := 'View Statement';
    WebButton4.ChildOrder := 21;
    WebButton4.ElementClassName := 'btn btn-info';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.ParentFont := False;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    CustNum.SetParentComponent(PaymentPnl);
    CustNum.Name := 'CustNum';
    CustNum.Left := 181;
    CustNum.Top := 8;
    CustNum.Width := 108;
    CustNum.Height := 41;
    CustNum.ChildOrder := 3;
    CustNum.EditType := weNumeric;
    CustNum.ElementClassName := 'form-control';
    CustNum.Font.Charset := DEFAULT_CHARSET;
    CustNum.Font.Color := clWindowText;
    CustNum.Font.Height := -27;
    CustNum.Font.Name := 'Arial';
    CustNum.Font.Style := [];
    CustNum.HeightPercent := 100.000000000000000000;
    CustNum.ParentFont := False;
    CustNum.WidthPercent := 100.000000000000000000;
    SetEvent(CustNum, Self, 'OnClick', 'CustNumClick');
    WebButton5.SetParentComponent(PaymentPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 295;
    WebButton5.Top := 8;
    WebButton5.Width := 73;
    WebButton5.Height := 41;
    WebButton5.Caption := 'Load';
    WebButton5.ChildOrder := 24;
    WebButton5.Font.Charset := DEFAULT_CHARSET;
    WebButton5.Font.Color := clWindowText;
    WebButton5.Font.Height := -16;
    WebButton5.Font.Name := 'Arial';
    WebButton5.Font.Style := [];
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.ParentFont := False;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    DSPayments.SetParentComponent(Self);
    DSPayments.Name := 'DSPayments';
    DSPayments.AfterOpen := DSPaymentsAfterOpen;
    DSPayments.EntitySetName := 'payments';
    DSPayments.Connection := Dm.ApiConnection;
    DSPayments.Left := 144;
    DSPayments.Top := 568;
    DSPaymentsAutoInc.SetParentComponent(DSPayments);
    DSPaymentsAutoInc.Name := 'DSPaymentsAutoInc';
    DSPaymentsAutoInc.FieldName := 'AutoInc';
    DSPaymentsAutoInc.Required := True;
    DSPaymentsInvoice.SetParentComponent(DSPayments);
    DSPaymentsInvoice.Name := 'DSPaymentsInvoice';
    DSPaymentsInvoice.FieldName := 'Invoice';
    DSPaymentsCust.SetParentComponent(DSPayments);
    DSPaymentsCust.Name := 'DSPaymentsCust';
    DSPaymentsCust.FieldName := 'Cust';
    DSPaymentsPaymentType.SetParentComponent(DSPayments);
    DSPaymentsPaymentType.Name := 'DSPaymentsPaymentType';
    DSPaymentsPaymentType.FieldName := 'PaymentType';
    DSPaymentsPaymentType.Size := 10;
    DSPaymentsCheckNumber.SetParentComponent(DSPayments);
    DSPaymentsCheckNumber.Name := 'DSPaymentsCheckNumber';
    DSPaymentsCheckNumber.FieldName := 'CheckNumber';
    DSPaymentsPaymentAmount.SetParentComponent(DSPayments);
    DSPaymentsPaymentAmount.Name := 'DSPaymentsPaymentAmount';
    DSPaymentsPaymentAmount.FieldName := 'PaymentAmount';
    DSPaymentsPaymentAmount.DisplayFormat := '0.00';
    DSPaymentsDate.SetParentComponent(DSPayments);
    DSPaymentsDate.Name := 'DSPaymentsDate';
    DSPaymentsDate.FieldName := 'Date';
    DSPaymentsRoute.SetParentComponent(DSPayments);
    DSPaymentsRoute.Name := 'DSPaymentsRoute';
    DSPaymentsRoute.FieldName := 'Route';
    DSPaymentsDriverNum.SetParentComponent(DSPayments);
    DSPaymentsDriverNum.Name := 'DSPaymentsDriverNum';
    DSPaymentsDriverNum.FieldName := 'DriverNum';
    WDSPayments.SetParentComponent(Self);
    WDSPayments.Name := 'WDSPayments';
    WDSPayments.DataSet := DSPayments;
    WDSPayments.Left := 184;
    WDSPayments.Top := 616;
    DSCust.SetParentComponent(Self);
    DSCust.Name := 'DSCust';
    DSCust.AfterOpen := DSCustAfterOpen;
    DSCust.EntitySetName := 'dlcust';
    DSCust.Connection := Dm.ApiConnection;
    DSCust.Left := 248;
    DSCust.Top := 576;
    DSCustCust.SetParentComponent(DSCust);
    DSCustCust.Name := 'DSCustCust';
    DSCustCust.FieldName := 'Cust';
    DSCustCustName.SetParentComponent(DSCust);
    DSCustCustName.Name := 'DSCustCustName';
    DSCustCustName.FieldName := 'CustName';
    DSCustCustName.Size := 255;
    DSCustSRNum.SetParentComponent(DSCust);
    DSCustSRNum.Name := 'DSCustSRNum';
    DSCustSRNum.FieldName := 'SRNum';
    DSCustAddress1.SetParentComponent(DSCust);
    DSCustAddress1.Name := 'DSCustAddress1';
    DSCustAddress1.FieldName := 'Address1';
    DSCustAddress1.Size := 255;
    DSCustAddress2.SetParentComponent(DSCust);
    DSCustAddress2.Name := 'DSCustAddress2';
    DSCustAddress2.FieldName := 'Address2';
    DSCustAddress2.Size := 255;
    DSCustCity.SetParentComponent(DSCust);
    DSCustCity.Name := 'DSCustCity';
    DSCustCity.FieldName := 'City';
    DSCustCity.Size := 255;
    DSCustState.SetParentComponent(DSCust);
    DSCustState.Name := 'DSCustState';
    DSCustState.FieldName := 'State';
    DSCustState.Size := 255;
    DSCustTermsExplained.SetParentComponent(DSCust);
    DSCustTermsExplained.Name := 'DSCustTermsExplained';
    DSCustTermsExplained.FieldName := 'TermsExplained';
    DSCustTermsExplained.Size := 255;
    DSCustLatitude.SetParentComponent(DSCust);
    DSCustLatitude.Name := 'DSCustLatitude';
    DSCustLatitude.FieldName := 'Latitude';
    DSCustLongitude.SetParentComponent(DSCust);
    DSCustLongitude.Name := 'DSCustLongitude';
    DSCustLongitude.FieldName := 'Longitude';
    WDSCust.SetParentComponent(Self);
    WDSCust.Name := 'WDSCust';
    WDSCust.DataSet := DSCust;
    WDSCust.Left := 280;
    WDSCust.Top := 624;
  finally
    PaymentPnl.AfterLoadDFMValues;
    WebLabelPm.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebDBLabel22.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    PaymentAmt.AfterLoadDFMValues;
    WCBPaymentType.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    CheckNum.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebMessageDlg1.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    CustNum.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    DSPayments.AfterLoadDFMValues;
    DSPaymentsAutoInc.AfterLoadDFMValues;
    DSPaymentsInvoice.AfterLoadDFMValues;
    DSPaymentsCust.AfterLoadDFMValues;
    DSPaymentsPaymentType.AfterLoadDFMValues;
    DSPaymentsCheckNumber.AfterLoadDFMValues;
    DSPaymentsPaymentAmount.AfterLoadDFMValues;
    DSPaymentsDate.AfterLoadDFMValues;
    DSPaymentsRoute.AfterLoadDFMValues;
    DSPaymentsDriverNum.AfterLoadDFMValues;
    WDSPayments.AfterLoadDFMValues;
    DSCust.AfterLoadDFMValues;
    DSCustCust.AfterLoadDFMValues;
    DSCustCustName.AfterLoadDFMValues;
    DSCustSRNum.AfterLoadDFMValues;
    DSCustAddress1.AfterLoadDFMValues;
    DSCustAddress2.AfterLoadDFMValues;
    DSCustCity.AfterLoadDFMValues;
    DSCustState.AfterLoadDFMValues;
    DSCustTermsExplained.AfterLoadDFMValues;
    DSCustLatitude.AfterLoadDFMValues;
    DSCustLongitude.AfterLoadDFMValues;
    WDSCust.AfterLoadDFMValues;
  end;
end;

end.
