unit PickupsMap;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.WebCtrls, Vcl.Controls;

type
  TFPickupMap = class(TForm)
    MapPnl: TPanel;
    WGM: TGoogleMaps;
    WebButton1: TButton;
    DSBackhauls: TXDataWebDataSet;
    WDSBackhauls: TDataSource;
    DSBackhaulsOrdrNumTrunc: TIntegerField;
    DSBackhaulsVenNum: TIntegerField;
    DSBackhaulsReleaseNum: TStringField;
    DSBackhaulsAdditionalTemPinfo: TStringField;
    DSBackhaulsNotes: TStringField;
    DSBackhaulsVenLoc: TStringField;
    DSBackhaulsAdd1: TStringField;
    DSBackhaulsAdd2: TStringField;
    DSBackhaulsCity: TStringField;
    DSBackhaulsST: TStringField;
    DSBackhaulsZip: TStringField;
    DSBackhaulsPhone: TStringField;
    DSBackhaulsTtlCs: TIntegerField;
    DSBackhaulsTtlCubes: TFloatField;
    DSBackhaulsTtlPndsGross: TFloatField;
    DSBackhaulsOrdrShipDt: TDateTimeField;
    DSBackhaulsPickupTruck: TIntegerField;
    DSBackhaulsLatitude: TFloatField;
    DSBackhaulsLongitude: TFloatField;
    DSBackhaulsPickupCompleted: TStringField;
    GeoLoc: TGeoLocation;
    procedure GeoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure WebFormShow(Sender: TObject);
    procedure DSBackhaulsAfterOpen(DataSet: TDataSet);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FPickupMap: TFPickupMap;
  vLat, vLong: Double;

implementation

Uses Data, Pickups;

{$R *.dfm}

procedure TFPickupMap.DSBackhaulsAfterOpen(DataSet: TDataSet);
begin
  GeoLoc.GetGeolocation;
end;

procedure TFPickupMap.GeoLocGeolocation(Sender: TObject; Lat, Lon, Alt: Double);
begin
  vLat := Lat;
  vLong := Lon;
  WGM.SetCenter(vLat, vLong);
  WGM.AddMarker(vLat, vLong);
  WGM.AddMarker(StrToFloat(DSBackhaulsLatitude.AsString),
    StrToFloat(DSBackhaulsLongitude.AsString), DSBackhaulsVenLoc.AsString);
end;

procedure TFPickupMap.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFPickups, FPickups);
end;

procedure TFPickupMap.WebFormShow(Sender: TObject);
begin
  DSBackhauls.QueryString := '$filter=(OrdrNumTrunc eq ' +
    FPickups.DSBackhaulsOrdrNumTrunc.AsString + ')';
  DSBackhauls.Load;
end;

procedure TFPickupMap.LoadDFMValues;
begin
  inherited LoadDFMValues;

  MapPnl := TPanel.Create(Self);
  WGM := TGoogleMaps.Create(Self);
  WebButton1 := TButton.Create(Self);
  DSBackhauls := TXDataWebDataSet.Create(Self);
  DSBackhaulsOrdrNumTrunc := TIntegerField.Create(Self);
  DSBackhaulsVenNum := TIntegerField.Create(Self);
  DSBackhaulsReleaseNum := TStringField.Create(Self);
  DSBackhaulsAdditionalTemPinfo := TStringField.Create(Self);
  DSBackhaulsNotes := TStringField.Create(Self);
  DSBackhaulsVenLoc := TStringField.Create(Self);
  DSBackhaulsAdd1 := TStringField.Create(Self);
  DSBackhaulsAdd2 := TStringField.Create(Self);
  DSBackhaulsCity := TStringField.Create(Self);
  DSBackhaulsST := TStringField.Create(Self);
  DSBackhaulsZip := TStringField.Create(Self);
  DSBackhaulsPhone := TStringField.Create(Self);
  DSBackhaulsTtlCs := TIntegerField.Create(Self);
  DSBackhaulsTtlCubes := TFloatField.Create(Self);
  DSBackhaulsTtlPndsGross := TFloatField.Create(Self);
  DSBackhaulsOrdrShipDt := TDateTimeField.Create(Self);
  DSBackhaulsPickupTruck := TIntegerField.Create(Self);
  DSBackhaulsLatitude := TFloatField.Create(Self);
  DSBackhaulsLongitude := TFloatField.Create(Self);
  DSBackhaulsPickupCompleted := TStringField.Create(Self);
  WDSBackhauls := TDataSource.Create(Self);
  GeoLoc := TGeoLocation.Create(Self);

  MapPnl.BeforeLoadDFMValues;
  WGM.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  DSBackhauls.BeforeLoadDFMValues;
  DSBackhaulsOrdrNumTrunc.BeforeLoadDFMValues;
  DSBackhaulsVenNum.BeforeLoadDFMValues;
  DSBackhaulsReleaseNum.BeforeLoadDFMValues;
  DSBackhaulsAdditionalTemPinfo.BeforeLoadDFMValues;
  DSBackhaulsNotes.BeforeLoadDFMValues;
  DSBackhaulsVenLoc.BeforeLoadDFMValues;
  DSBackhaulsAdd1.BeforeLoadDFMValues;
  DSBackhaulsAdd2.BeforeLoadDFMValues;
  DSBackhaulsCity.BeforeLoadDFMValues;
  DSBackhaulsST.BeforeLoadDFMValues;
  DSBackhaulsZip.BeforeLoadDFMValues;
  DSBackhaulsPhone.BeforeLoadDFMValues;
  DSBackhaulsTtlCs.BeforeLoadDFMValues;
  DSBackhaulsTtlCubes.BeforeLoadDFMValues;
  DSBackhaulsTtlPndsGross.BeforeLoadDFMValues;
  DSBackhaulsOrdrShipDt.BeforeLoadDFMValues;
  DSBackhaulsPickupTruck.BeforeLoadDFMValues;
  DSBackhaulsLatitude.BeforeLoadDFMValues;
  DSBackhaulsLongitude.BeforeLoadDFMValues;
  DSBackhaulsPickupCompleted.BeforeLoadDFMValues;
  WDSBackhauls.BeforeLoadDFMValues;
  GeoLoc.BeforeLoadDFMValues;
  try
    Name := 'FPickupMap';
    Width := 400;
    Height := 795;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    MapPnl.SetParentComponent(Self);
    MapPnl.Name := 'MapPnl';
    MapPnl.Left := 1;
    MapPnl.Top := 0;
    MapPnl.Width := 391;
    MapPnl.Height := 785;
    MapPnl.ElementClassName := 'card';
    MapPnl.BorderColor := clSilver;
    MapPnl.BorderStyle := bsSingle;
    MapPnl.ElementBodyClassName := 'card-body';
    WGM.SetParentComponent(MapPnl);
    WGM.Name := 'WGM';
    WGM.Left := 3;
    WGM.Top := 3;
    WGM.Width := 385;
    WGM.Height := 702;
    WGM.APIKey := 'AIzaSyA5XnK3yR5OksMDSDSQmm33LA8gKehL3Rg';
    WGM.Options.DefaultLatitude := -34.397000000000000000;
    WGM.Options.DefaultLongitude := 150.644000000000000000;
    WebButton1.SetParentComponent(MapPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 238;
    WebButton1.Top := 711;
    WebButton1.Width := 137;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Close';
    WebButton1.ChildOrder := 1;
    WebButton1.ElementClassName := 'btn btn-danger';
    WebButton1.Font.Charset := DEFAULT_CHARSET;
    WebButton1.Font.Color := clWindowText;
    WebButton1.Font.Height := -19;
    WebButton1.Font.Name := 'Arial';
    WebButton1.Font.Style := [];
    WebButton1.ParentFont := False;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    DSBackhauls.SetParentComponent(Self);
    DSBackhauls.Name := 'DSBackhauls';
    DSBackhauls.AfterOpen := DSBackhaulsAfterOpen;
    DSBackhauls.EntitySetName := 'Backhauls';
    DSBackhauls.Connection := Dm.ApiConnection;
    DSBackhauls.Left := 48;
    DSBackhauls.Top := 440;
    DSBackhaulsOrdrNumTrunc.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrNumTrunc.Name := 'DSBackhaulsOrdrNumTrunc';
    DSBackhaulsOrdrNumTrunc.FieldName := 'OrdrNumTrunc';
    DSBackhaulsVenNum.SetParentComponent(DSBackhauls);
    DSBackhaulsVenNum.Name := 'DSBackhaulsVenNum';
    DSBackhaulsVenNum.FieldName := 'VenNum';
    DSBackhaulsReleaseNum.SetParentComponent(DSBackhauls);
    DSBackhaulsReleaseNum.Name := 'DSBackhaulsReleaseNum';
    DSBackhaulsReleaseNum.FieldName := 'ReleaseNum';
    DSBackhaulsReleaseNum.Size := 24;
    DSBackhaulsAdditionalTemPinfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAdditionalTemPinfo.Name := 'DSBackhaulsAdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.FieldName := 'AdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.Size := 120;
    DSBackhaulsNotes.SetParentComponent(DSBackhauls);
    DSBackhaulsNotes.Name := 'DSBackhaulsNotes';
    DSBackhaulsNotes.FieldName := 'Notes';
    DSBackhaulsNotes.Size := 120;
    DSBackhaulsVenLoc.SetParentComponent(DSBackhauls);
    DSBackhaulsVenLoc.Name := 'DSBackhaulsVenLoc';
    DSBackhaulsVenLoc.FieldName := 'VenLoc';
    DSBackhaulsVenLoc.Size := 24;
    DSBackhaulsAdd1.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd1.Name := 'DSBackhaulsAdd1';
    DSBackhaulsAdd1.FieldName := 'Add1';
    DSBackhaulsAdd2.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd2.Name := 'DSBackhaulsAdd2';
    DSBackhaulsAdd2.FieldName := 'Add2';
    DSBackhaulsCity.SetParentComponent(DSBackhauls);
    DSBackhaulsCity.Name := 'DSBackhaulsCity';
    DSBackhaulsCity.FieldName := 'City';
    DSBackhaulsCity.Size := 16;
    DSBackhaulsST.SetParentComponent(DSBackhauls);
    DSBackhaulsST.Name := 'DSBackhaulsST';
    DSBackhaulsST.FieldName := 'ST';
    DSBackhaulsST.Size := 2;
    DSBackhaulsZip.SetParentComponent(DSBackhauls);
    DSBackhaulsZip.Name := 'DSBackhaulsZip';
    DSBackhaulsZip.FieldName := 'Zip';
    DSBackhaulsZip.Size := 10;
    DSBackhaulsPhone.SetParentComponent(DSBackhauls);
    DSBackhaulsPhone.Name := 'DSBackhaulsPhone';
    DSBackhaulsPhone.FieldName := 'Phone';
    DSBackhaulsPhone.Size := 14;
    DSBackhaulsTtlCs.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCs.Name := 'DSBackhaulsTtlCs';
    DSBackhaulsTtlCs.FieldName := 'TtlCs';
    DSBackhaulsTtlCubes.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCubes.Name := 'DSBackhaulsTtlCubes';
    DSBackhaulsTtlCubes.FieldName := 'TtlCubes';
    DSBackhaulsTtlPndsGross.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlPndsGross.Name := 'DSBackhaulsTtlPndsGross';
    DSBackhaulsTtlPndsGross.FieldName := 'TtlPndsGross';
    DSBackhaulsOrdrShipDt.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrShipDt.Name := 'DSBackhaulsOrdrShipDt';
    DSBackhaulsOrdrShipDt.FieldName := 'OrdrShipDt';
    DSBackhaulsPickupTruck.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupTruck.Name := 'DSBackhaulsPickupTruck';
    DSBackhaulsPickupTruck.FieldName := 'PickupTruck';
    DSBackhaulsLatitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLatitude.Name := 'DSBackhaulsLatitude';
    DSBackhaulsLatitude.FieldName := 'Latitude';
    DSBackhaulsLongitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLongitude.Name := 'DSBackhaulsLongitude';
    DSBackhaulsLongitude.FieldName := 'Longitude';
    DSBackhaulsPickupCompleted.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupCompleted.Name := 'DSBackhaulsPickupCompleted';
    DSBackhaulsPickupCompleted.FieldName := 'PickupCompleted';
    DSBackhaulsPickupCompleted.Size := 1;
    WDSBackhauls.SetParentComponent(Self);
    WDSBackhauls.Name := 'WDSBackhauls';
    WDSBackhauls.DataSet := DSBackhauls;
    WDSBackhauls.Left := 112;
    WDSBackhauls.Top := 440;
    GeoLoc.SetParentComponent(Self);
    GeoLoc.Name := 'GeoLoc';
    SetEvent(GeoLoc, Self, 'OnGeolocation', 'GeoLocGeolocation');
    GeoLoc.Left := 72;
    GeoLoc.Top := 511;
  finally
    MapPnl.AfterLoadDFMValues;
    WGM.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    DSBackhauls.AfterLoadDFMValues;
    DSBackhaulsOrdrNumTrunc.AfterLoadDFMValues;
    DSBackhaulsVenNum.AfterLoadDFMValues;
    DSBackhaulsReleaseNum.AfterLoadDFMValues;
    DSBackhaulsAdditionalTemPinfo.AfterLoadDFMValues;
    DSBackhaulsNotes.AfterLoadDFMValues;
    DSBackhaulsVenLoc.AfterLoadDFMValues;
    DSBackhaulsAdd1.AfterLoadDFMValues;
    DSBackhaulsAdd2.AfterLoadDFMValues;
    DSBackhaulsCity.AfterLoadDFMValues;
    DSBackhaulsST.AfterLoadDFMValues;
    DSBackhaulsZip.AfterLoadDFMValues;
    DSBackhaulsPhone.AfterLoadDFMValues;
    DSBackhaulsTtlCs.AfterLoadDFMValues;
    DSBackhaulsTtlCubes.AfterLoadDFMValues;
    DSBackhaulsTtlPndsGross.AfterLoadDFMValues;
    DSBackhaulsOrdrShipDt.AfterLoadDFMValues;
    DSBackhaulsPickupTruck.AfterLoadDFMValues;
    DSBackhaulsLatitude.AfterLoadDFMValues;
    DSBackhaulsLongitude.AfterLoadDFMValues;
    DSBackhaulsPickupCompleted.AfterLoadDFMValues;
    WDSBackhauls.AfterLoadDFMValues;
    GeoLoc.AfterLoadDFMValues;
  end;
end;

end.