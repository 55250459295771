unit Inspection;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Grids, WEBLib.DBCtrls, StrUtils, DateUtils;

type
  TFInspection = class(TForm)
    InspectionPnl: TPanel;
    WDSDefects: TDataSource;
    DSDefects: TXDataWebDataSet;
    DSInspection: TXDataWebDataSet;
    DSInspectionItems: TXDataWebDataSet;
    WDSInspection: TDataSource;
    WDSInspecitonItems: TDataSource;
    EditTruck: TEdit;
    EditTrailer1: TEdit;
    EditOdometer: TEdit;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WCBItems: TComboBox;
    EditTrailer2: TEdit;
    WebLabel5: TLabel;
    WebMemo1: TMemo;
    WebDBGrid1: TDBGrid;
    WebButton1: TButton;
    DSInspectionAutoInc: TIntegerField;
    DSInspectionDriverNum: TIntegerField;
    DSInspectionDate: TDateTimeField;
    DSInspectionTruck: TIntegerField;
    DSInspectionTrailer1: TIntegerField;
    DSInspectionTrailer2: TIntegerField;
    DSInspectionOdometer: TIntegerField;
    DSDefectsAutoInc: TIntegerField;
    DSDefectsDriverNum: TIntegerField;
    DSDefectsDate: TDateTimeField;
    DSDefectsInspectionItem: TIntegerField;
    DSDefectsTruck: TIntegerField;
    DSDefectsTrailer: TIntegerField;
    DSDefectsNotes: TStringField;
    DSInspectionItemsAutoInc: TIntegerField;
    DSInspectionItemsDescription: TStringField;
    DSInspectionItemsCategory: TStringField;
    WebButton2: TButton;
    DSInspectionInspectionType: TStringField;
    DSInspectionRouteNum: TIntegerField;
    DSInspectionNYMiles: TIntegerField;
    DSInspectionPAMiles: TIntegerField;
    DSInspectionOHMiles: TIntegerField;
    DSInspectionNYThruwayMiles: TIntegerField;
    DSInspectionDoublesFinish: TStringField;
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure DSInspectionItemsAfterOpen(Dataset: TDataSet);
    procedure EditTruckClick(Sender: TObject);
    procedure EditTrailer1Click(Sender: TObject);
    procedure EditTrailer2Click(Sender: TObject);
    procedure EditOdometerClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure DSInspectionAfterOpen(Dataset: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FInspection: TFInspection;
  VarTop: Integer;

implementation

Uses Data, Login, SelectRoute, InspectionEod;

{$R *.dfm}

procedure TFInspection.DSInspectionAfterOpen(Dataset: TDataSet);
begin
  if DSInspection.RecordCount = 0 then
  begin
    DSInspectionItems.Load;
    FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
    DSDefects.QueryString := '$filter=(DriverNum eq ' +
      FLogin.DsLoginNumber.AsString + ' AND Date eq ''' +
      DateToStr(Now) + ''')';
    DSDefects.Load;
    FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
  end
  else
  begin
    if Dm.DSLoginDoublesFlag.AsString = 'Y' then
      Application.CreateForm(TFInspectionEod, FInspectionEod)
    else if Dm.DSLoginLastRoute.AsString <> '' then
      Application.CreateForm(TFSelectRoute, FSelectRoute);
  end;
end;

procedure TFInspection.DSInspectionItemsAfterOpen(Dataset: TDataSet);
Var
  TempCat: String;
begin
  TempCat := '';
  WCBItems.Items.Clear;
  WCBItems.Items.Add('Select Inspection Item..');
  while not DSInspectionItems.eof do
  begin
    if TempCat <> DSInspectionItemsCategory.AsString then
      WCBItems.Items.Add('-- ' + DSInspectionItemsCategory.AsString + ' - 00');
    WCBItems.Items.Add(DSInspectionItemsDescription.AsString + ' - ' +
      DSInspectionItemsAutoInc.AsString);
    TempCat := DSInspectionItemsCategory.AsString;
    DSInspectionItems.Next;
  end;
  DSInspectionItems.Close;
  WCBItems.ItemIndex := 0;
end;

procedure TFInspection.EditOdometerClick(Sender: TObject);
begin
  EditOdometer.SelectAll;
end;

procedure TFInspection.EditTrailer1Click(Sender: TObject);
begin
  EditTrailer1.SelectAll;
end;

procedure TFInspection.EditTrailer2Click(Sender: TObject);
begin
  EditTrailer2.SelectAll;
end;

procedure TFInspection.EditTruckClick(Sender: TObject);
begin
  EditTruck.SelectAll;
end;

procedure TFInspection.WebButton1Click(Sender: TObject);
begin
  if EditTruck.Text = '' then
  begin
    ShowMessage('Please Enter Truck Number Above');
    Exit;
  end;
  if EditTrailer1.Text = '' then
  begin
    ShowMessage('Please Enter Trailer Number Above');
    Exit;
  end;
  if EditOdometer.Text = '' then
  begin
    ShowMessage('Please Enter Odometer Reading Above');
    Exit;
  end;
  if StrToInt(EditOdometer.Text) < 10 then
  begin
    ShowMessage('Please Enter a Correct Odometer Reading Above');
    Exit;
  end;
  if (StrToInt(EditTruck.Text) < 50) or (StrToInt(EditTruck.Text) > 999) then
  begin
    ShowMessage('Invalid Truck Number');
    Exit;
  end;
  DSInspection.Append;
  DSInspectionDriverNum.AsInteger := Dm.DsLoginNumber.AsInteger;
  DSInspectionTruck.AsString := EditTruck.Text;
  DSInspectionTrailer1.AsString := EditTrailer1.Text;
  DSInspectionTrailer2.AsString := EditTrailer2.Text;
  DSInspectionOdometer.AsString := EditOdometer.Text;
  DSInspectionRouteNum.AsInteger := Dm.DSLoginLastRoute.AsInteger;
  if HourOf(Now) > 17 then
    DSInspectionDate.AsDateTime := IncDay(Now,1)
  else DSInspectionDate.AsDateTime := Now;
  if Dm.DSLoginDoublesFlag.AsString = 'Y' then
    DSInspectionInspectionType.AsString := 'Doubles'
  else DSInspectionInspectionType.AsString := 'Initial';
  DSInspection.Post;
  DSInspection.ApplyUpdates;
  if Dm.DSLoginDoublesFlag.AsString = 'Y' then
    ShowMessage('Initial Inspection Saved')
  else Application.CreateForm(TFSelectRoute, FSelectRoute);
end;

procedure TFInspection.WebButton2Click(Sender: TObject);
begin
  if EditTruck.Text = '' then
  begin
    ShowMessage('Please Enter Truck Number Above');
    Exit;
  end;
  if EditTrailer1.Text = '' then
  begin
    ShowMessage('Please Enter Trailer Number Above');
    Exit;
  end;
  if EditOdometer.Text = '' then
  begin
    ShowMessage('Please Enter Odometer Reading Above');
    Exit;
  end;
  if WCBItems.ItemIndex <= 0 then
  begin
    ShowMessage('Please Select an Inspection Item');
    Exit;
  end;
  DSDefects.Append;
  DSDefectsDriverNum.AsInteger := Dm.DsLoginNumber.AsInteger;
  DSDefectsDate.AsDateTime := Now;
  DSDefectsInspectionItem.AsString :=
    Trim(RightStr(WCBItems.Items[WCBItems.ItemIndex], 2));
  DSDefectsTruck.AsString := EditTruck.Text;
  DSDefectsTrailer.AsString := EditTrailer1.Text;
  DSDefectsNotes.AsString := WebMemo1.Lines.Text;
  DSDefects.Post;
  DSDefects.ApplyUpdates;
  WebMemo1.Clear;
end;

procedure TFInspection.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'Inspection' then
  begin
    FInspection.Height := 699;
    FInspection.Width := 393;
    Application.CreateForm(TFInspection, FInspection);
  end;
end;

procedure TFInspection.WebFormResize(Sender: TObject);
begin
  InspectionPnl.Left := Round((FInspection.Width / 2) -
    (InspectionPnl.Width / 2));
  if InspectionPnl.Top < 0 then
    InspectionPnl.Top := 0;
  // InspectionPnl.Top := VarTop;
end;

procedure TFInspection.WebFormShow(Sender: TObject);
begin
  DSInspectionItems.Load;
  InspectionPnl.Left := Round((FInspection.Width / 2) -
    (InspectionPnl.Width / 2));
  InspectionPnl.Top := Round((FInspection.Height / 2) -
    (InspectionPnl.Height / 2));
  if InspectionPnl.Top < 0 then
    InspectionPnl.Top := 0;
  EditTruck.Text := '';
  EditTrailer1.Text := '';
  EditTrailer2.Text := '';
  EditOdometer.Text := '';
  CurrentForm := 'Inspection';
  FormatSettings.ShortDateFormat := 'YYYY-MM-DD';
  if FLogin.DSLoginDoublesFlag.AsString = 'Y' then
    DSInspection.QueryString := '$filter=(DriverNum eq ' + FLogin.DsLoginNumber.AsString + ' AND Date ge ''' + DateToStr(Now-1) + ''' AND InspectionType eq ''Doubles'' AND DoublesFinish eq null)'
  else
    DSInspection.QueryString := '$filter=(DriverNum eq ' + FLogin.DsLoginNumber.AsString + ' AND Date eq ''' + DateToStr(Now) + ''' AND DoublesFinish eq null)';

  DSInspection.Load;
  FormatSettings.ShortDateFormat := 'MM/DD/YYYY';
  VarTop := InspectionPnl.Top;
end;

procedure TFInspection.LoadDFMValues;
begin
  inherited LoadDFMValues;

  InspectionPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  EditTruck := TEdit.Create(Self);
  EditTrailer1 := TEdit.Create(Self);
  EditOdometer := TEdit.Create(Self);
  WCBItems := TComboBox.Create(Self);
  EditTrailer2 := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WDSDefects := TDataSource.Create(Self);
  DSDefects := TXDataWebDataSet.Create(Self);
  DSDefectsAutoInc := TIntegerField.Create(Self);
  DSDefectsDriverNum := TIntegerField.Create(Self);
  DSDefectsDate := TDateTimeField.Create(Self);
  DSDefectsInspectionItem := TIntegerField.Create(Self);
  DSDefectsTruck := TIntegerField.Create(Self);
  DSDefectsTrailer := TIntegerField.Create(Self);
  DSDefectsNotes := TStringField.Create(Self);
  DSInspection := TXDataWebDataSet.Create(Self);
  DSInspectionAutoInc := TIntegerField.Create(Self);
  DSInspectionDriverNum := TIntegerField.Create(Self);
  DSInspectionDate := TDateTimeField.Create(Self);
  DSInspectionTruck := TIntegerField.Create(Self);
  DSInspectionTrailer1 := TIntegerField.Create(Self);
  DSInspectionTrailer2 := TIntegerField.Create(Self);
  DSInspectionOdometer := TIntegerField.Create(Self);
  DSInspectionInspectionType := TStringField.Create(Self);
  DSInspectionRouteNum := TIntegerField.Create(Self);
  DSInspectionNYMiles := TIntegerField.Create(Self);
  DSInspectionPAMiles := TIntegerField.Create(Self);
  DSInspectionOHMiles := TIntegerField.Create(Self);
  DSInspectionNYThruwayMiles := TIntegerField.Create(Self);
  DSInspectionDoublesFinish := TStringField.Create(Self);
  DSInspectionItems := TXDataWebDataSet.Create(Self);
  DSInspectionItemsAutoInc := TIntegerField.Create(Self);
  DSInspectionItemsDescription := TStringField.Create(Self);
  DSInspectionItemsCategory := TStringField.Create(Self);
  WDSInspection := TDataSource.Create(Self);
  WDSInspecitonItems := TDataSource.Create(Self);

  InspectionPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  EditTruck.BeforeLoadDFMValues;
  EditTrailer1.BeforeLoadDFMValues;
  EditOdometer.BeforeLoadDFMValues;
  WCBItems.BeforeLoadDFMValues;
  EditTrailer2.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WDSDefects.BeforeLoadDFMValues;
  DSDefects.BeforeLoadDFMValues;
  DSDefectsAutoInc.BeforeLoadDFMValues;
  DSDefectsDriverNum.BeforeLoadDFMValues;
  DSDefectsDate.BeforeLoadDFMValues;
  DSDefectsInspectionItem.BeforeLoadDFMValues;
  DSDefectsTruck.BeforeLoadDFMValues;
  DSDefectsTrailer.BeforeLoadDFMValues;
  DSDefectsNotes.BeforeLoadDFMValues;
  DSInspection.BeforeLoadDFMValues;
  DSInspectionAutoInc.BeforeLoadDFMValues;
  DSInspectionDriverNum.BeforeLoadDFMValues;
  DSInspectionDate.BeforeLoadDFMValues;
  DSInspectionTruck.BeforeLoadDFMValues;
  DSInspectionTrailer1.BeforeLoadDFMValues;
  DSInspectionTrailer2.BeforeLoadDFMValues;
  DSInspectionOdometer.BeforeLoadDFMValues;
  DSInspectionInspectionType.BeforeLoadDFMValues;
  DSInspectionRouteNum.BeforeLoadDFMValues;
  DSInspectionNYMiles.BeforeLoadDFMValues;
  DSInspectionPAMiles.BeforeLoadDFMValues;
  DSInspectionOHMiles.BeforeLoadDFMValues;
  DSInspectionNYThruwayMiles.BeforeLoadDFMValues;
  DSInspectionDoublesFinish.BeforeLoadDFMValues;
  DSInspectionItems.BeforeLoadDFMValues;
  DSInspectionItemsAutoInc.BeforeLoadDFMValues;
  DSInspectionItemsDescription.BeforeLoadDFMValues;
  DSInspectionItemsCategory.BeforeLoadDFMValues;
  WDSInspection.BeforeLoadDFMValues;
  WDSInspecitonItems.BeforeLoadDFMValues;
  try
    Name := 'FInspection';
    Width := 412;
    Height := 711;
    Align := alClient;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    InspectionPnl.SetParentComponent(Self);
    InspectionPnl.Name := 'InspectionPnl';
    InspectionPnl.Left := 16;
    InspectionPnl.Top := 8;
    InspectionPnl.Width := 375;
    InspectionPnl.Height := 697;
    InspectionPnl.ElementClassName := 'container-fluid';
    InspectionPnl.Font.Charset := DEFAULT_CHARSET;
    InspectionPnl.Font.Color := clWindowText;
    InspectionPnl.Font.Height := -16;
    InspectionPnl.Font.Name := 'Arial';
    InspectionPnl.Font.Style := [];
    InspectionPnl.ParentFont := False;
    WebLabel1.SetParentComponent(InspectionPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 22;
    WebLabel1.Width := 173;
    WebLabel1.Height := 22;
    WebLabel1.Caption := 'Enter Truck Number:';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(InspectionPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 61;
    WebLabel2.Width := 195;
    WebLabel2.Height := 22;
    WebLabel2.Caption := 'Enter Trailer 1 Number:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(InspectionPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 148;
    WebLabel3.Width := 192;
    WebLabel3.Height := 22;
    WebLabel3.Caption := 'Enter Truck Odometer:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(InspectionPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 16;
    WebLabel4.Top := 196;
    WebLabel4.Width := 196;
    WebLabel4.Height := 22;
    WebLabel4.Caption := 'Truck/Trailer Inspection';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -19;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(InspectionPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 16;
    WebLabel5.Top := 100;
    WebLabel5.Width := 195;
    WebLabel5.Height := 22;
    WebLabel5.Caption := 'Enter Trailer 2 Number:';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -19;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    EditTruck.SetParentComponent(InspectionPnl);
    EditTruck.Name := 'EditTruck';
    EditTruck.Left := 303;
    EditTruck.Top := 19;
    EditTruck.Width := 50;
    EditTruck.Height := 33;
    EditTruck.EditType := weNumeric;
    EditTruck.ElementClassName := 'form-control';
    EditTruck.Font.Charset := DEFAULT_CHARSET;
    EditTruck.Font.Color := clWindowText;
    EditTruck.Font.Height := -19;
    EditTruck.Font.Name := 'Arial';
    EditTruck.Font.Style := [];
    EditTruck.HeightPercent := 100.000000000000000000;
    EditTruck.ParentFont := False;
    EditTruck.WidthPercent := 100.000000000000000000;
    SetEvent(EditTruck, Self, 'OnClick', 'EditTruckClick');
    EditTrailer1.SetParentComponent(InspectionPnl);
    EditTrailer1.Name := 'EditTrailer1';
    EditTrailer1.Left := 303;
    EditTrailer1.Top := 58;
    EditTrailer1.Width := 50;
    EditTrailer1.Height := 33;
    EditTrailer1.ChildOrder := 1;
    EditTrailer1.EditType := weNumeric;
    EditTrailer1.ElementClassName := 'form-control';
    EditTrailer1.Font.Charset := DEFAULT_CHARSET;
    EditTrailer1.Font.Color := clWindowText;
    EditTrailer1.Font.Height := -19;
    EditTrailer1.Font.Name := 'Arial';
    EditTrailer1.Font.Style := [];
    EditTrailer1.HeightPercent := 100.000000000000000000;
    EditTrailer1.ParentFont := False;
    EditTrailer1.WidthPercent := 100.000000000000000000;
    SetEvent(EditTrailer1, Self, 'OnClick', 'EditTrailer1Click');
    EditOdometer.SetParentComponent(InspectionPnl);
    EditOdometer.Name := 'EditOdometer';
    EditOdometer.Left := 240;
    EditOdometer.Top := 142;
    EditOdometer.Width := 113;
    EditOdometer.Height := 33;
    EditOdometer.ChildOrder := 1;
    EditOdometer.EditType := weNumeric;
    EditOdometer.ElementClassName := 'form-control';
    EditOdometer.Font.Charset := DEFAULT_CHARSET;
    EditOdometer.Font.Color := clWindowText;
    EditOdometer.Font.Height := -19;
    EditOdometer.Font.Name := 'Arial';
    EditOdometer.Font.Style := [];
    EditOdometer.HeightPercent := 100.000000000000000000;
    EditOdometer.ParentFont := False;
    EditOdometer.WidthPercent := 100.000000000000000000;
    SetEvent(EditOdometer, Self, 'OnClick', 'EditOdometerClick');
    WCBItems.SetParentComponent(InspectionPnl);
    WCBItems.Name := 'WCBItems';
    WCBItems.Left := 16;
    WCBItems.Top := 224;
    WCBItems.Width := 337;
    WCBItems.Height := 32;
    WCBItems.Font.Charset := DEFAULT_CHARSET;
    WCBItems.Font.Color := clWindowText;
    WCBItems.Font.Height := -21;
    WCBItems.Font.Name := 'Arial';
    WCBItems.Font.Style := [];
    WCBItems.HeightPercent := 100.000000000000000000;
    WCBItems.ParentFont := False;
    WCBItems.Text := 'Select Inspection Item..';
    WCBItems.WidthPercent := 100.000000000000000000;
    WCBItems.ItemIndex := 0;
    WCBItems.Items.BeginUpdate;
    try
      WCBItems.Items.Clear;
      WCBItems.Items.Add('Select Inspection Item..');
    finally
      WCBItems.Items.EndUpdate;
    end;
    EditTrailer2.SetParentComponent(InspectionPnl);
    EditTrailer2.Name := 'EditTrailer2';
    EditTrailer2.Left := 303;
    EditTrailer2.Top := 97;
    EditTrailer2.Width := 50;
    EditTrailer2.Height := 33;
    EditTrailer2.ChildOrder := 1;
    EditTrailer2.EditType := weNumeric;
    EditTrailer2.ElementClassName := 'form-control';
    EditTrailer2.Font.Charset := DEFAULT_CHARSET;
    EditTrailer2.Font.Color := clWindowText;
    EditTrailer2.Font.Height := -19;
    EditTrailer2.Font.Name := 'Arial';
    EditTrailer2.Font.Style := [];
    EditTrailer2.HeightPercent := 100.000000000000000000;
    EditTrailer2.ParentFont := False;
    EditTrailer2.WidthPercent := 100.000000000000000000;
    SetEvent(EditTrailer2, Self, 'OnClick', 'EditTrailer2Click');
    WebMemo1.SetParentComponent(InspectionPnl);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 16;
    WebMemo1.Top := 272;
    WebMemo1.Width := 249;
    WebMemo1.Height := 137;
    WebMemo1.AutoSize := False;
    WebMemo1.ElementClassName := 'form-control';
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.SetParentComponent(InspectionPnl);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 16;
    WebDBGrid1.Top := 432;
    WebDBGrid1.Width := 337;
    WebDBGrid1.Height := 201;
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'InspectionItem';
      Title := 'Item';
      Width := 39;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      DataField := 'Notes';
      Title := 'Notes';
      Width := 286;
    end;
    WebDBGrid1.DataSource := WDSDefects;
    WebDBGrid1.FixedCols := 0;
    WebDBGrid1.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    WebDBGrid1.TabOrder := 6;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    WebDBGrid1.ColWidths[0] := 39;
    WebDBGrid1.ColWidths[1] := 286;
    WebButton1.SetParentComponent(InspectionPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 200;
    WebButton1.Top := 639;
    WebButton1.Width := 153;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Save && Continue';
    WebButton1.ChildOrder := 12;
    WebButton1.ElementClassName := 'btn btn-success';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(InspectionPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 271;
    WebButton2.Top := 360;
    WebButton2.Width := 82;
    WebButton2.Height := 49;
    WebButton2.Caption := 'Add';
    WebButton2.ChildOrder := 13;
    WebButton2.ElementClassName := 'btn btn-warning';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WDSDefects.SetParentComponent(Self);
    WDSDefects.Name := 'WDSDefects';
    WDSDefects.DataSet := DSDefects;
    WDSDefects.Left := 40;
    WDSDefects.Top := 680;
    DSDefects.SetParentComponent(Self);
    DSDefects.Name := 'DSDefects';
    DSDefects.EntitySetName := 'inspectiondefects';
    DSDefects.Connection := Dm.ApiConnection;
    DSDefects.Left := 24;
    DSDefects.Top := 656;
    DSDefectsAutoInc.SetParentComponent(DSDefects);
    DSDefectsAutoInc.Name := 'DSDefectsAutoInc';
    DSDefectsAutoInc.FieldName := 'AutoInc';
    DSDefectsAutoInc.Required := True;
    DSDefectsDriverNum.SetParentComponent(DSDefects);
    DSDefectsDriverNum.Name := 'DSDefectsDriverNum';
    DSDefectsDriverNum.FieldName := 'DriverNum';
    DSDefectsDate.SetParentComponent(DSDefects);
    DSDefectsDate.Name := 'DSDefectsDate';
    DSDefectsDate.FieldName := 'Date';
    DSDefectsInspectionItem.SetParentComponent(DSDefects);
    DSDefectsInspectionItem.Name := 'DSDefectsInspectionItem';
    DSDefectsInspectionItem.FieldName := 'InspectionItem';
    DSDefectsTruck.SetParentComponent(DSDefects);
    DSDefectsTruck.Name := 'DSDefectsTruck';
    DSDefectsTruck.FieldName := 'Truck';
    DSDefectsTrailer.SetParentComponent(DSDefects);
    DSDefectsTrailer.Name := 'DSDefectsTrailer';
    DSDefectsTrailer.FieldName := 'Trailer';
    DSDefectsNotes.SetParentComponent(DSDefects);
    DSDefectsNotes.Name := 'DSDefectsNotes';
    DSDefectsNotes.FieldName := 'Notes';
    DSDefectsNotes.Size := 250;
    DSInspection.SetParentComponent(Self);
    DSInspection.Name := 'DSInspection';
    DSInspection.AfterOpen := DSInspectionAfterOpen;
    DSInspection.EntitySetName := 'inspectioninfo';
    DSInspection.Connection := Dm.ApiConnection;
    DSInspection.QueryString := 'orderby=Category,Description';
    DSInspection.Left := 32;
    DSInspection.Top := 584;
    DSInspectionAutoInc.SetParentComponent(DSInspection);
    DSInspectionAutoInc.Name := 'DSInspectionAutoInc';
    DSInspectionAutoInc.FieldName := 'AutoInc';
    DSInspectionAutoInc.Required := True;
    DSInspectionDriverNum.SetParentComponent(DSInspection);
    DSInspectionDriverNum.Name := 'DSInspectionDriverNum';
    DSInspectionDriverNum.FieldName := 'DriverNum';
    DSInspectionDate.SetParentComponent(DSInspection);
    DSInspectionDate.Name := 'DSInspectionDate';
    DSInspectionDate.FieldName := 'Date';
    DSInspectionTruck.SetParentComponent(DSInspection);
    DSInspectionTruck.Name := 'DSInspectionTruck';
    DSInspectionTruck.FieldName := 'Truck';
    DSInspectionTrailer1.SetParentComponent(DSInspection);
    DSInspectionTrailer1.Name := 'DSInspectionTrailer1';
    DSInspectionTrailer1.FieldName := 'Trailer1';
    DSInspectionTrailer2.SetParentComponent(DSInspection);
    DSInspectionTrailer2.Name := 'DSInspectionTrailer2';
    DSInspectionTrailer2.FieldName := 'Trailer2';
    DSInspectionOdometer.SetParentComponent(DSInspection);
    DSInspectionOdometer.Name := 'DSInspectionOdometer';
    DSInspectionOdometer.FieldName := 'Odometer';
    DSInspectionInspectionType.SetParentComponent(DSInspection);
    DSInspectionInspectionType.Name := 'DSInspectionInspectionType';
    DSInspectionInspectionType.FieldName := 'InspectionType';
    DSInspectionInspectionType.Size := 50;
    DSInspectionRouteNum.SetParentComponent(DSInspection);
    DSInspectionRouteNum.Name := 'DSInspectionRouteNum';
    DSInspectionRouteNum.FieldName := 'RouteNum';
    DSInspectionNYMiles.SetParentComponent(DSInspection);
    DSInspectionNYMiles.Name := 'DSInspectionNYMiles';
    DSInspectionNYMiles.FieldName := 'NYMiles';
    DSInspectionPAMiles.SetParentComponent(DSInspection);
    DSInspectionPAMiles.Name := 'DSInspectionPAMiles';
    DSInspectionPAMiles.FieldName := 'PAMiles';
    DSInspectionOHMiles.SetParentComponent(DSInspection);
    DSInspectionOHMiles.Name := 'DSInspectionOHMiles';
    DSInspectionOHMiles.FieldName := 'OHMiles';
    DSInspectionNYThruwayMiles.SetParentComponent(DSInspection);
    DSInspectionNYThruwayMiles.Name := 'DSInspectionNYThruwayMiles';
    DSInspectionNYThruwayMiles.FieldName := 'NYThruwayMiles';
    DSInspectionDoublesFinish.SetParentComponent(DSInspection);
    DSInspectionDoublesFinish.Name := 'DSInspectionDoublesFinish';
    DSInspectionDoublesFinish.FieldName := 'DoublesFinish';
    DSInspectionDoublesFinish.Size := 1;
    DSInspectionItems.SetParentComponent(Self);
    DSInspectionItems.Name := 'DSInspectionItems';
    DSInspectionItems.AfterOpen := DSInspectionItemsAfterOpen;
    DSInspectionItems.EntitySetName := 'inspectionitems';
    DSInspectionItems.Connection := Dm.ApiConnection;
    DSInspectionItems.QueryString := 'orderby=Category,Description';
    DSInspectionItems.Left := 40;
    DSInspectionItems.Top := 720;
    DSInspectionItemsAutoInc.SetParentComponent(DSInspectionItems);
    DSInspectionItemsAutoInc.Name := 'DSInspectionItemsAutoInc';
    DSInspectionItemsAutoInc.FieldName := 'AutoInc';
    DSInspectionItemsAutoInc.Required := True;
    DSInspectionItemsDescription.SetParentComponent(DSInspectionItems);
    DSInspectionItemsDescription.Name := 'DSInspectionItemsDescription';
    DSInspectionItemsDescription.FieldName := 'Description';
    DSInspectionItemsDescription.Size := 50;
    DSInspectionItemsCategory.SetParentComponent(DSInspectionItems);
    DSInspectionItemsCategory.Name := 'DSInspectionItemsCategory';
    DSInspectionItemsCategory.FieldName := 'Category';
    DSInspectionItemsCategory.Size := 50;
    WDSInspection.SetParentComponent(Self);
    WDSInspection.Name := 'WDSInspection';
    WDSInspection.DataSet := DSInspection;
    WDSInspection.Left := 72;
    WDSInspection.Top := 600;
    WDSInspecitonItems.SetParentComponent(Self);
    WDSInspecitonItems.Name := 'WDSInspecitonItems';
    WDSInspecitonItems.DataSet := DSInspectionItems;
    WDSInspecitonItems.Left := 72;
    WDSInspecitonItems.Top := 728;
  finally
    InspectionPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    EditTruck.AfterLoadDFMValues;
    EditTrailer1.AfterLoadDFMValues;
    EditOdometer.AfterLoadDFMValues;
    WCBItems.AfterLoadDFMValues;
    EditTrailer2.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WDSDefects.AfterLoadDFMValues;
    DSDefects.AfterLoadDFMValues;
    DSDefectsAutoInc.AfterLoadDFMValues;
    DSDefectsDriverNum.AfterLoadDFMValues;
    DSDefectsDate.AfterLoadDFMValues;
    DSDefectsInspectionItem.AfterLoadDFMValues;
    DSDefectsTruck.AfterLoadDFMValues;
    DSDefectsTrailer.AfterLoadDFMValues;
    DSDefectsNotes.AfterLoadDFMValues;
    DSInspection.AfterLoadDFMValues;
    DSInspectionAutoInc.AfterLoadDFMValues;
    DSInspectionDriverNum.AfterLoadDFMValues;
    DSInspectionDate.AfterLoadDFMValues;
    DSInspectionTruck.AfterLoadDFMValues;
    DSInspectionTrailer1.AfterLoadDFMValues;
    DSInspectionTrailer2.AfterLoadDFMValues;
    DSInspectionOdometer.AfterLoadDFMValues;
    DSInspectionInspectionType.AfterLoadDFMValues;
    DSInspectionRouteNum.AfterLoadDFMValues;
    DSInspectionNYMiles.AfterLoadDFMValues;
    DSInspectionPAMiles.AfterLoadDFMValues;
    DSInspectionOHMiles.AfterLoadDFMValues;
    DSInspectionNYThruwayMiles.AfterLoadDFMValues;
    DSInspectionDoublesFinish.AfterLoadDFMValues;
    DSInspectionItems.AfterLoadDFMValues;
    DSInspectionItemsAutoInc.AfterLoadDFMValues;
    DSInspectionItemsDescription.AfterLoadDFMValues;
    DSInspectionItemsCategory.AfterLoadDFMValues;
    WDSInspection.AfterLoadDFMValues;
    WDSInspecitonItems.AfterLoadDFMValues;
  end;
end;

end.
