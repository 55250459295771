unit Deposit;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Data.DB,
  XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB, Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TFDeposit = class(TForm)
    DepositPnl: TPanel;
    DSDeposit: TXDataWebDataSet;
    WDSDeposit: TDataSource;
    BankDropdown: TComboBox;
    WebLabel1: TLabel;
    AmountEdit: TEdit;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    LocationEdit: TEdit;
    CloseBtn: TButton;
    SaveBtn: TButton;
    DSDepositAutoInc: TIntegerField;
    DSDepositDepositDate: TDateTimeField;
    DSDepositDriverNum: TIntegerField;
    DSDepositDepositBank: TStringField;
    DSDepositDepositLocation: TStringField;
    DSDepositDepositAmount: TFloatField;
    procedure WebFormShow(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
    procedure CloseBtnClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FDeposit: TFDeposit;

implementation

Uses Data, Options;

{$R *.dfm}

procedure TFDeposit.CloseBtnClick(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFDeposit.SaveBtnClick(Sender: TObject);
begin
  DSDeposit.Append;
  DSDepositDepositDate.AsDateTime := Now;
  DSDepositDriverNum.AsInteger := Dm.DSLoginNumber.AsInteger;
  //DSDepositDepositBank.AsString := 'Test';
  DSDepositDepositBank.AsString := BankDropdown.Items[BankDropdown.ItemIndex];
  DSDepositDepositLocation.AsString := LocationEdit.Text;
  DSDepositDepositAmount.AsFloat := StrToFloat(AmountEdit.Text);
  DSDeposit.Post;
  DSDeposit.ApplyUpdates;
  ShowMessage('Deposit Saved');
  AmountEdit.Clear;
  LocationEdit.Clear;
  BankDropdown.ItemIndex := 0;
end;

procedure TFDeposit.WebFormShow(Sender: TObject);
begin
  DSDeposit.QueryString := '$filter=(AutoInc eq 1)';
  DSDeposit.Load;
  DepositPnl.Left := Round((FDeposit.Width / 2) -
    (DepositPnl.Width / 2));
  DepositPnl.Top := Round((FDeposit.Height / 2) -
    (DepositPnl.Height / 2));
  AmountEdit.Clear;
  LocationEdit.Clear;
  BankDropdown.ItemIndex := 0;
end;

procedure TFDeposit.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DepositPnl := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  BankDropdown := TComboBox.Create(Self);
  AmountEdit := TEdit.Create(Self);
  LocationEdit := TEdit.Create(Self);
  CloseBtn := TButton.Create(Self);
  SaveBtn := TButton.Create(Self);
  DSDeposit := TXDataWebDataSet.Create(Self);
  DSDepositAutoInc := TIntegerField.Create(Self);
  DSDepositDepositDate := TDateTimeField.Create(Self);
  DSDepositDriverNum := TIntegerField.Create(Self);
  DSDepositDepositBank := TStringField.Create(Self);
  DSDepositDepositLocation := TStringField.Create(Self);
  DSDepositDepositAmount := TFloatField.Create(Self);
  WDSDeposit := TDataSource.Create(Self);

  DepositPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  BankDropdown.BeforeLoadDFMValues;
  AmountEdit.BeforeLoadDFMValues;
  LocationEdit.BeforeLoadDFMValues;
  CloseBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  DSDeposit.BeforeLoadDFMValues;
  DSDepositAutoInc.BeforeLoadDFMValues;
  DSDepositDepositDate.BeforeLoadDFMValues;
  DSDepositDriverNum.BeforeLoadDFMValues;
  DSDepositDepositBank.BeforeLoadDFMValues;
  DSDepositDepositLocation.BeforeLoadDFMValues;
  DSDepositDepositAmount.BeforeLoadDFMValues;
  WDSDeposit.BeforeLoadDFMValues;
  try
    Name := 'FDeposit';
    Width := 385;
    Height := 692;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    DepositPnl.SetParentComponent(Self);
    DepositPnl.Name := 'DepositPnl';
    DepositPnl.Left := 4;
    DepositPnl.Top := 3;
    DepositPnl.Width := 377;
    DepositPnl.Height := 606;
    DepositPnl.ElementClassName := 'card';
    DepositPnl.ElementBodyClassName := 'card-body';
    DepositPnl.Font.Charset := DEFAULT_CHARSET;
    DepositPnl.Font.Color := clWindowText;
    DepositPnl.Font.Height := -19;
    DepositPnl.Font.Name := 'Tahoma';
    DepositPnl.Font.Style := [];
    DepositPnl.ParentFont := False;
    WebLabel1.SetParentComponent(DepositPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 16;
    WebLabel1.Top := 34;
    WebLabel1.Width := 140;
    WebLabel1.Height := 23;
    WebLabel1.Caption := 'Deposit Amount:';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -19;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(DepositPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 16;
    WebLabel2.Top := 146;
    WebLabel2.Width := 116;
    WebLabel2.Height := 23;
    WebLabel2.Caption := 'Deposit Bank:';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -19;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(DepositPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 16;
    WebLabel3.Top := 258;
    WebLabel3.Width := 144;
    WebLabel3.Height := 23;
    WebLabel3.Caption := 'Deposit Location:';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -19;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    BankDropdown.SetParentComponent(DepositPnl);
    BankDropdown.Name := 'BankDropdown';
    BankDropdown.Left := 16;
    BankDropdown.Top := 175;
    BankDropdown.Width := 265;
    BankDropdown.Height := 31;
    BankDropdown.ChildOrder := 1;
    BankDropdown.Font.Charset := DEFAULT_CHARSET;
    BankDropdown.Font.Color := clWindowText;
    BankDropdown.Font.Height := -19;
    BankDropdown.Font.Name := 'Tahoma';
    BankDropdown.Font.Style := [];
    BankDropdown.HeightPercent := 100.000000000000000000;
    BankDropdown.ParentFont := False;
    BankDropdown.TabOrder := 1;
    BankDropdown.Text := 'Select a Bank Below...';
    BankDropdown.WidthPercent := 100.000000000000000000;
    BankDropdown.ItemIndex := -1;
    BankDropdown.Items.BeginUpdate;
    try
      BankDropdown.Items.Clear;
      BankDropdown.Items.Add('Select a Bank Below...');
      BankDropdown.Items.Add('Community Bank');
      BankDropdown.Items.Add('Huntington Bank');
      BankDropdown.Items.Add('Northwest Savings Bank');
      BankDropdown.Items.Add('Key Bank');
      BankDropdown.Items.Add('Maplevale');
    finally
      BankDropdown.Items.EndUpdate;
    end;
    AmountEdit.SetParentComponent(DepositPnl);
    AmountEdit.Name := 'AmountEdit';
    AmountEdit.Left := 16;
    AmountEdit.Top := 63;
    AmountEdit.Width := 129;
    AmountEdit.Height := 34;
    AmountEdit.EditType := weFloat;
    AmountEdit.Font.Charset := DEFAULT_CHARSET;
    AmountEdit.Font.Color := clWindowText;
    AmountEdit.Font.Height := -19;
    AmountEdit.Font.Name := 'Tahoma';
    AmountEdit.Font.Style := [];
    AmountEdit.HeightPercent := 100.000000000000000000;
    AmountEdit.ParentFont := False;
    AmountEdit.WidthPercent := 100.000000000000000000;
    LocationEdit.SetParentComponent(DepositPnl);
    LocationEdit.Name := 'LocationEdit';
    LocationEdit.Left := 16;
    LocationEdit.Top := 287;
    LocationEdit.Width := 329;
    LocationEdit.Height := 34;
    LocationEdit.ChildOrder := 2;
    LocationEdit.Font.Charset := DEFAULT_CHARSET;
    LocationEdit.Font.Color := clWindowText;
    LocationEdit.Font.Height := -19;
    LocationEdit.Font.Name := 'Tahoma';
    LocationEdit.Font.Style := [];
    LocationEdit.HeightPercent := 100.000000000000000000;
    LocationEdit.ParentFont := False;
    LocationEdit.TabOrder := 2;
    LocationEdit.WidthPercent := 100.000000000000000000;
    CloseBtn.SetParentComponent(DepositPnl);
    CloseBtn.Name := 'CloseBtn';
    CloseBtn.Left := 16;
    CloseBtn.Top := 427;
    CloseBtn.Width := 137;
    CloseBtn.Height := 57;
    CloseBtn.Caption := 'Close';
    CloseBtn.ChildOrder := 1;
    CloseBtn.ElementClassName := 'btn btn-danger';
    CloseBtn.Font.Charset := DEFAULT_CHARSET;
    CloseBtn.Font.Color := clWindowText;
    CloseBtn.Font.Height := -19;
    CloseBtn.Font.Name := 'Arial';
    CloseBtn.Font.Style := [];
    CloseBtn.HeightPercent := 100.000000000000000000;
    CloseBtn.ParentFont := False;
    CloseBtn.TabOrder := 3;
    CloseBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CloseBtn, Self, 'OnClick', 'CloseBtnClick');
    SaveBtn.SetParentComponent(DepositPnl);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 224;
    SaveBtn.Top := 427;
    SaveBtn.Width := 137;
    SaveBtn.Height := 57;
    SaveBtn.Caption := 'Save Deposit';
    SaveBtn.ChildOrder := 4;
    SaveBtn.ElementClassName := 'btn btn-success';
    SaveBtn.Font.Charset := DEFAULT_CHARSET;
    SaveBtn.Font.Color := clWindowText;
    SaveBtn.Font.Height := -19;
    SaveBtn.Font.Name := 'Arial';
    SaveBtn.Font.Style := [];
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.ParentFont := False;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    DSDeposit.SetParentComponent(Self);
    DSDeposit.Name := 'DSDeposit';
    DSDeposit.EntitySetName := 'DriverDeposit';
    DSDeposit.Connection := Dm.ApiConnection;
    DSDeposit.Left := 250;
    DSDeposit.Top := 626;
    DSDepositAutoInc.SetParentComponent(DSDeposit);
    DSDepositAutoInc.Name := 'DSDepositAutoInc';
    DSDepositAutoInc.FieldName := 'AutoInc';
    DSDepositAutoInc.Required := True;
    DSDepositDepositDate.SetParentComponent(DSDeposit);
    DSDepositDepositDate.Name := 'DSDepositDepositDate';
    DSDepositDepositDate.FieldName := 'DepositDate';
    DSDepositDriverNum.SetParentComponent(DSDeposit);
    DSDepositDriverNum.Name := 'DSDepositDriverNum';
    DSDepositDriverNum.FieldName := 'DriverNum';
    DSDepositDepositBank.SetParentComponent(DSDeposit);
    DSDepositDepositBank.Name := 'DSDepositDepositBank';
    DSDepositDepositBank.FieldName := 'DepositBank';
    DSDepositDepositBank.Size := 100;
    DSDepositDepositLocation.SetParentComponent(DSDeposit);
    DSDepositDepositLocation.Name := 'DSDepositDepositLocation';
    DSDepositDepositLocation.FieldName := 'DepositLocation';
    DSDepositDepositLocation.Size := 100;
    DSDepositDepositAmount.SetParentComponent(DSDeposit);
    DSDepositDepositAmount.Name := 'DSDepositDepositAmount';
    DSDepositDepositAmount.FieldName := 'DepositAmount';
    WDSDeposit.SetParentComponent(Self);
    WDSDeposit.Name := 'WDSDeposit';
    WDSDeposit.DataSet := DSDeposit;
    WDSDeposit.Left := 298;
    WDSDeposit.Top := 602;
  finally
    DepositPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    BankDropdown.AfterLoadDFMValues;
    AmountEdit.AfterLoadDFMValues;
    LocationEdit.AfterLoadDFMValues;
    CloseBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    DSDeposit.AfterLoadDFMValues;
    DSDepositAutoInc.AfterLoadDFMValues;
    DSDepositDepositDate.AfterLoadDFMValues;
    DSDepositDriverNum.AfterLoadDFMValues;
    DSDepositDepositBank.AfterLoadDFMValues;
    DSDepositDepositLocation.AfterLoadDFMValues;
    DSDepositDepositAmount.AfterLoadDFMValues;
    WDSDeposit.AfterLoadDFMValues;
  end;
end;

end.