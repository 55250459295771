unit Options;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls;

type
  TFOptions = class(TForm)
    OptionsPnl: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebButton10: TButton;
    WebButton11: TButton;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton6Click(Sender: TObject);
    procedure WebButton7Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton9Click(Sender: TObject);
    procedure WebButton10Click(Sender: TObject);
    procedure WebButton11Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FOptions: TFOptions;

implementation

Uses Data, InspectionEod, CurrentStop, FuelPurchase, Map, MSDS,
        Deposit, SettleUp, Accident, Pickups, Expense, RFCRecap;

{$R *.dfm}

procedure TFOptions.WebButton10Click(Sender: TObject);
begin
  Application.CreateForm(TFRFCRecap, FRFCRecap);
end;

procedure TFOptions.WebButton11Click(Sender: TObject);
begin
  Application.CreateForm(TFMSDS, FMSDS);
end;

procedure TFOptions.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFInspectionEod, FInspectionEod);
end;

procedure TFOptions.WebButton2Click(Sender: TObject);
begin
  Application.CreateForm(TFCurrentStop, FCurrentStop);
end;

procedure TFOptions.WebButton3Click(Sender: TObject);
begin
  Application.CreateForm(TFFuelPurchase, FFuelPurchase);
end;

procedure TFOptions.WebButton4Click(Sender: TObject);
begin
  Application.CreateForm(TFMap, FMap);
end;

procedure TFOptions.WebButton5Click(Sender: TObject);
begin
  Application.CreateForm(TFSettleUp, FSettleUp);
end;

procedure TFOptions.WebButton6Click(Sender: TObject);
begin
  Application.CreateForm(TFAccident, FAccident);
end;

procedure TFOptions.WebButton7Click(Sender: TObject);
begin
  Application.CreateForm(TFDeposit, FDeposit);
end;

procedure TFOptions.WebButton8Click(Sender: TObject);
begin
  Application.CreateForm(TFPickups, FPickups);
end;

procedure TFOptions.WebButton9Click(Sender: TObject);
begin
  Application.CreateForm(TFExpense, FExpense);
end;

procedure TFOptions.WebFormResize(Sender: TObject);
begin
  OptionsPnl.Left := Round((FOptions.Width / 2) - (OptionsPnl.Width / 2));
  //OptionsPnl.Top := Round((FOptions.Height/2)-(OptionsPnl.Height/2));
  if OptionsPnl.Top < 0 then
    OptionsPnl.Top := 0;
end;

procedure TFOptions.WebFormShow(Sender: TObject);
begin
  OptionsPnl.Left := Round((FOptions.Width / 2) - (OptionsPnl.Width / 2));
  OptionsPnl.Top := Round((FOptions.Height / 2) - (OptionsPnl.Height / 2));
end;

procedure TFOptions.LoadDFMValues;
begin
  inherited LoadDFMValues;

  OptionsPnl := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton10 := TButton.Create(Self);
  WebButton11 := TButton.Create(Self);

  OptionsPnl.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  WebButton11.BeforeLoadDFMValues;
  try
    Name := 'FOptions';
    Width := 399;
    Height := 665;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    OptionsPnl.SetParentComponent(Self);
    OptionsPnl.Name := 'OptionsPnl';
    OptionsPnl.Left := 8;
    OptionsPnl.Top := 8;
    OptionsPnl.Width := 385;
    OptionsPnl.Height := 649;
    OptionsPnl.ElementClassName := 'card';
    OptionsPnl.ElementBodyClassName := 'card-body';
    OptionsPnl.Font.Charset := DEFAULT_CHARSET;
    OptionsPnl.Font.Color := clWindowText;
    OptionsPnl.Font.Height := -19;
    OptionsPnl.Font.Name := 'Arial';
    OptionsPnl.Font.Style := [];
    OptionsPnl.ParentFont := False;
    WebButton1.SetParentComponent(OptionsPnl);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 200;
    WebButton1.Top := 568;
    WebButton1.Width := 161;
    WebButton1.Height := 73;
    WebButton1.Caption := 'End Route';
    WebButton1.ElementClassName := 'btn btn-danger';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(OptionsPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 568;
    WebButton2.Width := 161;
    WebButton2.Height := 73;
    WebButton2.Caption := 'Return to Route';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(OptionsPnl);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 24;
    WebButton3.Top := 112;
    WebButton3.Width := 161;
    WebButton3.Height := 73;
    WebButton3.Caption := 'Purchase Fuel';
    WebButton3.ChildOrder := 2;
    WebButton3.ElementClassName := 'btn btn-warning';
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(OptionsPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 24;
    WebButton4.Top := 24;
    WebButton4.Width := 161;
    WebButton4.Height := 73;
    WebButton4.Caption := 'Map Current Stop';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn btn-info';
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(OptionsPnl);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 24;
    WebButton5.Top := 200;
    WebButton5.Width := 161;
    WebButton5.Height := 73;
    WebButton5.Caption := 'Payments Recap';
    WebButton5.ChildOrder := 2;
    WebButton5.ElementClassName := 'btn btn-warning';
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(OptionsPnl);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 200;
    WebButton6.Top := 288;
    WebButton6.Width := 161;
    WebButton6.Height := 73;
    WebButton6.Caption := 'Accident Report';
    WebButton6.ChildOrder := 2;
    WebButton6.ElementClassName := 'btn btn-danger';
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton6Click');
    WebButton7.SetParentComponent(OptionsPnl);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 200;
    WebButton7.Top := 24;
    WebButton7.Width := 161;
    WebButton7.Height := 73;
    WebButton7.Caption := 'Make Deposit';
    WebButton7.ChildOrder := 2;
    WebButton7.ElementClassName := 'btn btn-info';
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton7Click');
    WebButton8.SetParentComponent(OptionsPnl);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 200;
    WebButton8.Top := 112;
    WebButton8.Width := 161;
    WebButton8.Height := 73;
    WebButton8.Caption := 'Backhauls';
    WebButton8.ChildOrder := 2;
    WebButton8.ElementClassName := 'btn btn-danger';
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton9.SetParentComponent(OptionsPnl);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 200;
    WebButton9.Top := 200;
    WebButton9.Width := 161;
    WebButton9.Height := 73;
    WebButton9.Caption := 'Expenses';
    WebButton9.ChildOrder := 2;
    WebButton9.ElementClassName := 'btn btn-danger';
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton9, Self, 'OnClick', 'WebButton9Click');
    WebButton10.SetParentComponent(OptionsPnl);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 24;
    WebButton10.Top := 288;
    WebButton10.Width := 161;
    WebButton10.Height := 73;
    WebButton10.Caption := 'RFC Recap';
    WebButton10.ChildOrder := 2;
    WebButton10.ElementClassName := 'btn btn-warning';
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton10Click');
    WebButton11.SetParentComponent(OptionsPnl);
    WebButton11.Name := 'WebButton11';
    WebButton11.Left := 24;
    WebButton11.Top := 376;
    WebButton11.Width := 161;
    WebButton11.Height := 73;
    WebButton11.Caption := 'MSDS';
    WebButton11.ChildOrder := 2;
    WebButton11.ElementClassName := 'btn btn-warning';
    WebButton11.HeightPercent := 100.000000000000000000;
    WebButton11.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton11, Self, 'OnClick', 'WebButton11Click');
  finally
    OptionsPnl.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    WebButton11.AfterLoadDFMValues;
  end;
end;

end.
