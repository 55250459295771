{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCCalendar;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  {$IFDEF WIN32}
  Windows,
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  UITypes,
  {$ENDIF}
  {$ENDIF}
  Classes, Types, TypInfo, WEBLib.Controls, WEBLib.Graphics, WEBLib.TMSFNCCustomControl, WEBLib.TMSFNCGraphics,
  WEBLib.TMSFNCGraphicsTypes, WEBLib.TMSFNCTypes, WEBLib.TMSFNCUtils, WEBLib.TMSFNCBitmapContainer, SysUtils,
  WEBLib.TMSFNCStyles;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 3; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.0.1 : Improved : ShowFocus property under DateAppearance to remove the dotted border around the focused date
  //v1.0.1.0 : New : Added DayNameAppearance.Height property
  //         : New : Added OnNavigateBack, OnNavigateForward and OnNavigateFooter events
  //v1.0.2.0 : New : Support for high dpi
  //v1.0.2.1 : Improved: Exposed Date and Day properties to have more control over design-time view
  //v1.0.3.0 : New : GlobalFont interface implemented
  //         : New : Updated initial look
  //v1.0.3.1 : Improved : Date selection handling

type
  TTMSFNCCalendarArrowLayoutValue = (alvLeft, alvRight);
  TTMSFNCCalendarArrowLayout = set of TTMSFNCCalendarArrowLayoutValue;

  TTMSFNCCalendarArrowType = (atTriangle, atArrow, atBitmap, atCustom);

  {$IFNDEF WEBLIB}
  TTMSFNCCalendarArrow = class(TTMSFNCPersistent, ITMSFNCBitmapContainer)
  {$ENDIF}
  {$IFDEF WEBLIB}
  TTMSFNCCalendarArrow = class(TTMSFNCPersistent)
  {$ENDIF}
  private
    FOwner: TPersistent;
    FColor: TTMSFNCGraphicsColor;
    FVisibility: TTMSFNCCalendarArrowLayout;
    FSize: Integer;
    FOnChange: TNotifyEvent;
    FArrowLeftType: TTMSFNCCalendarArrowType;
    FArrowLeftBitmap: TTMSFNCScaledBitmaps;
    FArrowRightBitmap: TTMSFNCScaledBitmaps;
    FArrowRightBitmapSize: Single;
    FArrowRightType: TTMSFNCCalendarArrowType;
    FArrowLeftBitmapSize: Single;
    procedure SetColor(const Value: TTMSFNCGraphicsColor);
    procedure SetVisibility(const Value: TTMSFNCCalendarArrowLayout);
    procedure SetSize(const Value: Integer);
    procedure SetArrowLeftBitmap(const Value: TTMSFNCScaledBitmaps);
    procedure SetArrowRightBitmap(const Value: TTMSFNCScaledBitmaps);
    procedure SetArrowLeftBitmapSize(const Value: Single);
    procedure SetArrowLeftType(const Value: TTMSFNCCalendarArrowType);
    procedure SetArrowRightBitmapSize(const Value: Single);
    procedure SetArrowRightType(const Value: TTMSFNCCalendarArrowType);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function IsArrowLeftBitmapSizeStored: Boolean;
    function IsArrowRightBitmapSizeStore: Boolean;
  protected
    procedure Changed;
    function GetOwner: TPersistent; override;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
  public
    constructor Create(AOwner: TComponent);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property Size: Integer read FSize write SetSize default 15;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property ArrowLeftBitmap: TTMSFNCScaledBitmaps read FArrowLeftBitmap write SetArrowLeftBitmap;
    property ArrowLeftType: TTMSFNCCalendarArrowType read FArrowLeftType write SetArrowLeftType default atArrow;
    property ArrowLeftBitmapSize: Single read FArrowLeftBitmapSize write SetArrowLeftBitmapSize stored IsArrowLeftBitmapSizeStored nodefault;
    property ArrowRightBitmap: TTMSFNCScaledBitmaps read FArrowRightBitmap write SetArrowRightBitmap;
    property ArrowRightType: TTMSFNCCalendarArrowType read FArrowRightType write SetArrowRightType default atArrow;
    property ArrowRightBitmapSize: Single read FArrowRightBitmapSize write SetArrowRightBitmapSize stored IsArrowRightBitmapSizeStore nodefault;
    property Color: TTMSFNCGraphicsColor read FColor write SetColor default gcDimgray;
    property Visibility: TTMSFNCCalendarArrowLayout read FVisibility write SetVisibility default [alvLeft, alvRight];
  end;

  TTMSFNCCalendarHeader = class(TPersistent)
  private
    FFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FArrow: TTMSFNCCalendarArrow;
    FVisible: Boolean;
    FOnChange: TNotifyEvent;
    FHeight: Integer;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetArrow(const Value: TTMSFNCCalendarArrow);
    procedure SetVisible(const Value: Boolean);
    procedure SetHeight(const Value: Integer);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create(AOwner: TComponent);
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Arrow: TTMSFNCCalendarArrow read FArrow write SetArrow;
    property Height: Integer read FHeight write SetHeight default 25;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default True;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
  end;

  TTMSFNCCalendarFooterDate = (fdNone, fdSelected, fdToday);

  TTMSFNCCalendarFooter = class(TPersistent)
  private
    FFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FCaption: string;
    FVisible: Boolean;
    FDateCaption: TTMSFNCCalendarFooterDate;
    FOnChange: TNotifyEvent;
    FHeight: Integer;
    FCaptionRect: TRectF;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetCaption(const Value: string);
    procedure SetVisible(const Value: Boolean);
    procedure SetHeight(const Value: Integer);
    procedure SetDateCaption(const Value: TTMSFNCCalendarFooterDate);
    procedure SetCaptionRect(const Value: TRectF);
    function IsCaptionStored: Boolean;
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
    property CaptionRect: TRectF read FCaptionRect write SetCaptionRect;
  published
    property Caption: string read FCaption write SetCaption stored IsCaptionStored nodefault;
    property DateCaption: TTMSFNCCalendarFooterDate read FDateCaption write SetDateCaption default fdToday;
    property Height: Integer read FHeight write SetHeight default 25;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property Visible: Boolean read FVisible write SetVisible default True;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
  end;

  TTMSFNCCalendarDayNameAppearance = class(TPersistent)
  private
    FFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FOnChange: TNotifyEvent;
    FHeight: Integer;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetHeight(const Value: Integer);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Height: Integer read FHeight write SetHeight default 15;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
  end;

  TTMSFNCCalendarDateAppearance = class(TPersistent)
  private
    FFill: TTMSFNCGraphicsFill;
    FFont: TTMSFNCGraphicsFont;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FSelectedFill: TTMSFNCGraphicsFill;
    FSelectedFont: TTMSFNCGraphicsFont;
    FSelectedStroke: TTMSFNCGraphicsStroke;
    FSelectedVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FSelectedHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    FDisabledFill: TTMSFNCGraphicsFill;
    FDisabledFont: TTMSFNCGraphicsFont;
    FDisabledStroke: TTMSFNCGraphicsStroke;
    FDateBeforeFill: TTMSFNCGraphicsFill;
    FDateBeforeFont: TTMSFNCGraphicsFont;
    FDateBeforeStroke: TTMSFNCGraphicsStroke;
    FDateAfterFont: TTMSFNCGraphicsFont;
    FDateAfterFill: TTMSFNCGraphicsFill;
    FDateAfterStroke: TTMSFNCGraphicsStroke;
    FShowDaysBefore, FShowDaysAfter: Boolean;
    FOnChange: TNotifyEvent;
    FTodayFill: TTMSFNCGraphicsFill;
    FBadgeFont: TTMSFNCGraphicsFont;
    FTodayStroke: TTMSFNCGraphicsStroke;
    FBadgeFill: TTMSFNCGraphicsFill;
    FBadgeStroke: TTMSFNCGraphicsStroke;
    FTodayFont: TTMSFNCGraphicsFont;
    FShowFocus: Boolean;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSelectedFill(const Value: TTMSFNCGraphicsFill);
    procedure SetSelectedFont(const Value: TTMSFNCGraphicsFont);
    procedure SetSelectedHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetSelectedStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetSelectedVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetDisabledFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDisabledFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDisabledStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDateAfterFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDateAfterFont(const Value: TTMSFNCGraphicsFont);
    procedure SetDateBeforeFill(const Value: TTMSFNCGraphicsFill);
    procedure SetDateBeforeFont(const Value: TTMSFNCGraphicsFont);
    procedure SetShowDaysAfter(const Value: Boolean);
    procedure SetShowDaysBefore(const Value: Boolean);
    procedure SetDateAfterStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetDateBeforeStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetBadgeFill(const Value: TTMSFNCGraphicsFill);
    procedure SetBadgeFont(const Value: TTMSFNCGraphicsFont);
    procedure SetBadgeStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetTodayFill(const Value: TTMSFNCGraphicsFill);
    procedure SetTodayFont(const Value: TTMSFNCGraphicsFont);
    procedure SetTodayStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetShowFocus(const Value: Boolean);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
    property SelectedFill: TTMSFNCGraphicsFill read FSelectedFill write SetSelectedFill;
    property SelectedFont: TTMSFNCGraphicsFont read FSelectedFont write SetSelectedFont;
    property SelectedStroke: TTMSFNCGraphicsStroke read FSelectedStroke write SetSelectedStroke;
    property SelectedVerticalTextAlign: TTMSFNCGraphicsTextAlign read FSelectedVerticalTextAlign write SetSelectedVerticalTextAlign default gtaCenter;
    property SelectedHorizontalTextAlign: TTMSFNCGraphicsTextAlign read FSelectedHorizontalTextAlign write SetSelectedHorizontalTextAlign default gtaCenter;
    property DisabledFill: TTMSFNCGraphicsFill read FDisabledFill write SetDisabledFill;
    property DisabledFont: TTMSFNCGraphicsFont read FDisabledFont write SetDisabledFont;
    property DisabledStroke: TTMSFNCGraphicsStroke read FDisabledStroke write SetDisabledStroke;
    property ShowDaysBefore: Boolean read FShowDaysBefore write SetShowDaysBefore default False;
    property ShowDaysAfter: Boolean read FShowDaysAfter write SetShowDaysAfter default False;
    property DateBeforeFill: TTMSFNCGraphicsFill read FDateBeforeFill write SetDateBeforeFill;
    property DateBeforeFont: TTMSFNCGraphicsFont read FDateBeforeFont write SetDateBeforeFont;
    property DateBeforeStroke: TTMSFNCGraphicsStroke read FDateBeforeStroke write SetDateBeforeStroke;
    property DateAfterFill: TTMSFNCGraphicsFill read FDateAfterFill write SetDateAfterFill;
    property DateAfterFont: TTMSFNCGraphicsFont read FDateAfterFont write SetDateAfterFont;
    property DateAfterStroke: TTMSFNCGraphicsStroke read FDateAfterStroke write SetDateAfterStroke;
    property TodayFill: TTMSFNCGraphicsFill read FTodayFill write SetTodayFill;
    property TodayFont: TTMSFNCGraphicsFont read FTodayFont write SetTodayFont;
    property TodayStroke: TTMSFNCGraphicsStroke read FTodayStroke write SetTodayStroke;
    property BadgeFill: TTMSFNCGraphicsFill read FBadgeFill write SetBadgeFill;
    property BadgeFont: TTMSFNCGraphicsFont read FBadgeFont write SetBadgeFont;
    property BadgeStroke: TTMSFNCGraphicsStroke read FBadgeStroke write SetBadgeStroke;
    property ShowFocus: Boolean read FShowFocus write SetShowFocus default True;
  end;

  TTMSFNCCalendarLineAppearance = class(TPersistent)
  private
    FStroke: TTMSFNCGraphicsStroke;
    FOnChange: TNotifyEvent;
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
  protected
    procedure Changed;
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
  end;

  TTMSFNCCalendarWeekNumberAppearance = class(TPersistent)
  private
    FOnChange: TNotifyEvent;
    FVisible: Boolean;
    FFont: TTMSFNCGraphicsFont;
    FFill: TTMSFNCGraphicsFill;
    FStroke: TTMSFNCGraphicsStroke;
    FVerticalTextAlign: TTMSFNCGraphicsTextAlign;
    FHorizontalTextAlign: TTMSFNCGraphicsTextAlign;
    procedure SetFill(const Value: TTMSFNCGraphicsFill);
    procedure SetFont(const Value: TTMSFNCGraphicsFont);
    procedure SetStroke(const Value: TTMSFNCGraphicsStroke);
    procedure SetVisible(const Value: Boolean);
    procedure SetHorizontalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
    procedure SetVerticalTextAlign(const Value: TTMSFNCGraphicsTextAlign);
  protected
    procedure Changed;
    procedure FillChanged(Sender: TObject);
    procedure FontChanged(Sender: TObject);
    procedure StrokeChanged(Sender: TObject);
  public
    constructor Create; virtual;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  published
    property Visible: Boolean read FVisible write SetVisible default False;
    property Font: TTMSFNCGraphicsFont read FFont write SetFont;
    property Fill: TTMSFNCGraphicsFill read FFill write SetFill;
    property Stroke: TTMSFNCGraphicsStroke read FStroke write SetStroke;
    property VerticalTextAlign: TTMSFNCGraphicsTextAlign read FVerticalTextAlign write SetVerticalTextAlign default gtaCenter;
    property HorizontalTextAlign: TTMSFNCGraphicsTextAlign read FHorizontalTextAlign write SetHorizontalTextAlign default gtaCenter;
  end;

  TTMSFNCCalendarNavigationMode = (nmMonth, nmYear, nmFocusedDate, nmCustom);

  TTMSFNCCalendarCustomInteraction = class(TPersistent)
  private
    FMultiSelect: Boolean;
    FKeyboardSupport: Boolean;
    FReadOnlyMode: Boolean;
    FNavigationMode: TTMSFNCCalendarNavigationMode;
    FSwipeOnHeader: Boolean;
    procedure SetKeyboardSupport(const Value: Boolean);
    procedure SetMultiSelect(const Value: Boolean);
    procedure SetReadOnlyMode(const Value: Boolean);
    procedure SetNavigationMode(const Value: TTMSFNCCalendarNavigationMode);
    procedure SetSwipeOnHeader(const Value: Boolean);
  protected
    property MultiSelect: Boolean read FMultiSelect write SetMultiSelect default False;
    property KeyboardSupport: Boolean read FKeyboardSupport write SetKeyboardSupport default True;
    property ReadOnlyMode: Boolean read FReadOnlyMode write SetReadOnlyMode default False;
    property SwipeOnHeader: Boolean read FSwipeOnHeader write SetSwipeOnHeader default False;
    property NavigationMode: TTMSFNCCalendarNavigationMode read FNavigationMode write SetNavigationMode default nmMonth;
  public
    constructor Create; virtual;
    procedure Assign(Source: TPersistent); override;
  end;

  TTMSFNCCalendarInteraction = class(TTMSFNCCalendarCustomInteraction)
  published
    property MultiSelect;
    property KeyboardSupport;
    property ReadOnlyMode;
    property SwipeOnHeader;
    property NavigationMode;
  end;

  TTMSFNCCalendarDateItem = class(TCollectionItem)
  private
    FDate: TDate;
    procedure SetDate(const Value: TDate);
  protected
    procedure Update;
  public
    procedure Assign(Source: TPersistent); override;
  published
    property Date: TDate read FDate write SetDate;
  end;

  TTMSFNCCalendarDates = class(TCollection)
  private
    FOnChange: TNotifyEvent;
    procedure SetItem(Index: integer; const Value: TTMSFNCCalendarDateItem);
    function GetItem(Index: integer): TTMSFNCCalendarDateItem;
  protected
    procedure DoChange; virtual;
    procedure Update(Item: TCollectionItem); override;
  public
    procedure SelectDate(ADate: TDate);
    procedure SelectDateInterval(ADateFrom, ADateTo: TDate);
    procedure UnselectDate(ADate: TDate);
    procedure UnselectAll;
    function Add: TTMSFNCCalendarDateItem;
    function InCollection(ADate: TDate): Boolean;
    property Items[Index: integer]: TTMSFNCCalendarDateItem read GetItem write SetItem;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  TTMSFNCCalendarEventItem = class(TTMSFNCCalendarDateItem)
  private
    FHint: string;
    FBadgeValue: Integer;
    FBadgeColor: TTMSFNCGraphicsColor;
    FShowBadge: Boolean;
    FBadgeFontColor: TTMSFNCGraphicsColor;
    procedure SetBadgeColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBadgeFontColor(const Value: TTMSFNCGraphicsColor);
    procedure SetBadgeValue(const Value: Integer);
    procedure SetHint(const Value: string);
    procedure SetShowBadge(const Value: Boolean);
    function IsHintStored: Boolean;
  protected
    procedure Update;
  public
    procedure Assign(Source: TPersistent); override;
    constructor Create(ACollection: TCollection); override;
  published
    property BadgeValue: Integer read FBadgeValue write SetBadgeValue default 0;
    property ShowBadge: Boolean read FShowBadge write SetShowBadge default True;
    property BadgeFontColor: TTMSFNCGraphicsColor read FBadgeFontColor write SetBadgeFontColor default gcNull;
    property BadgeColor: TTMSFNCGraphicsColor read FBadgeColor write SetBadgeColor default gcNull;
    property Hint: string read FHint write SetHint stored IsHintStored nodefault;
  end;

  TTMSFNCCalendarEvents = class(TTMSFNCCalendarDates)
  private
    procedure SetItem(Index: integer; const Value: TTMSFNCCalendarEventItem);
    function GetItem(Index: integer): TTMSFNCCalendarEventItem;
  public
    function Add: TTMSFNCCalendarEventItem;
    function GetEventCollectionItemByDate(ADate: TDate): TTMSFNCCalendarEventItem;
    property Items[Index: integer]: TTMSFNCCalendarEventItem read GetItem write SetItem;
  end;

  TTMSFNCCalendarBeforeDrawHeaderEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawHeaderEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCCalendarBeforeDrawHeaderTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawHeaderTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign) of object;
  TTMSFNCCalendarBeforeDrawFooterEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawFooterEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF) of object;
  TTMSFNCCalendarBeforeDrawFooterTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawFooterTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign) of object;
  TTMSFNCCalendarBeforeDrawDayNamesEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawDayNamesEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCCalendarBeforeDrawDayNamesTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawDayNamesTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign) of object;
  TTMSFNCCalendarBeforeDrawDayNumbersEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawDayNumbersEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate) of object;
  TTMSFNCCalendarBeforeDrawDayNumbersTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawDayNumbersTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign) of object;
  TTMSFNCCalendarBeforeDrawLinesEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawLinesEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics) of object;
  TTMSFNCCalendarBeforeDrawWeekNumbersEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawWeekNumbersEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF) of object;
  TTMSFNCCalendarBeforeDrawWeekNumbersTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawWeekNumbersTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign) of object;
  TTMSFNCCalendarBeforeDrawBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawBadgeEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem) of object;
  TTMSFNCCalendarBeforeDrawArrowLeftEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawArrowLeftEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF) of object;
  TTMSFNCCalendarBeforeDrawArrowRightEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCalendarAfterDrawArrowRightEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; AArrowRect: TRectF) of object;
  TTMSFNCCalendarBeforeSelectDateEvent = procedure(Sender: TObject; ADate: TDate) of object;
  TTMSFNCCalendarSelectedDateEvent = procedure(Sender: TObject; ADate: TDate) of object;
  TTMSFNCCalendarSelectedMultiDateEvent = procedure(Sender: TObject; AStartDate: TDate; AEndDate: TDate) of object;
  TTMSFNCCalendarYearChangedEvent = procedure(Sender: TObject; AYear: Integer) of object;
  TTMSFNCCalendarMonthChangedEvent = procedure(Sender: TObject; AMonth: Integer) of object;
  TTMSFNCCalendarCustomNavigationEvent = procedure(Sender: TObject; ADate: TDate; AFocusedDate: TDate; ADirection: Boolean) of object;
  TTMSFNCCalendarCustomArrowDrawEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARect: TRectF; ADirection: Boolean) of object;
  TTMSFNCCalendarCustomNavigateEvent = procedure(Sender: TObject; AMonth: Integer; AYear: Integer) of object;

  {$IFNDEF WEBLIB}
  TTMSFNCCustomCalendar = class(TTMSFNCCustomControl, ITMSFNCBitmapContainer, ITMSFNCAppearanceGlobalFont)
  {$ENDIF}
  {$IFDEF WEBLIB}
  TTMSFNCCustomCalendar = class(TTMSFNCCustomControl, ITMSFNCAppearanceGlobalFont)
  {$ENDIF}
  private
    FDay, FMonth, FYear: Integer;
    FBitmapContainer: TTMSFNCBitmapContainer;
    FNumOfWeeksDrawn: Integer;
    FWeekNumberAppearance: TTMSFNCCalendarWeekNumberAppearance;
    FFirstDay: Integer;
    FHeader: TTMSFNCCalendarHeader;
    FFooter: TTMSFNCCalendarFooter;
    FDayNameAppearance: TTMSFNCCalendarDayNameAppearance;
    FDateAppearance: TTMSFNCCalendarDateAppearance;
    FLineAppearance: TTMSFNCCalendarLineAppearance;
    FXOffset, FYOffset, FDateAreaHeight: Single;
    FMx, FMy: Single;
    FTick: Cardinal;
    FSelectedDates: TTMSFNCCalendarDates;
    FEvents: TTMSFNCCalendarEvents;
    FBadgeSize: Integer;
    FMouseDown, FShiftPressed, FSelectedAll: Boolean;
    FDate: TDate;
    FMaxDate, FMinDate: TDate;
    FClickedDate, FMouseAtDate, FFocusedDate, FHoverDate: TDate;
    FOnChange: TNotifyEvent;
    FOnBeforeDrawHeader: TTMSFNCCalendarBeforeDrawHeaderEvent;
    FOnAfterDrawDayNames: TTMSFNCCalendarAfterDrawDayNamesEvent;
    FOnAfterDrawHeader: TTMSFNCCalendarAfterDrawHeaderEvent;
    FOnBeforeDrawDayNames: TTMSFNCCalendarBeforeDrawDayNamesEvent;
    FOnAfterDrawHeaderText: TTMSFNCCalendarAfterDrawHeaderTextEvent;
    FOnBeforeDrawHeaderText: TTMSFNCCalendarBeforeDrawHeaderTextEvent;
    FOnAfterDrawDayNamesText: TTMSFNCCalendarAfterDrawDayNamesTextEvent;
    FOnBeforeDrawDayNamesText: TTMSFNCCalendarBeforeDrawDayNamesTextEvent;
    FOnAfterDrawWeekNumbersText: TTMSFNCCalendarAfterDrawWeekNumbersTextEvent;
    FOnBeforeDrawDayNumbersText: TTMSFNCCalendarBeforeDrawDayNumbersTextEvent;
    FOnBeforeDrawLines: TTMSFNCCalendarBeforeDrawLinesEvent;
    FOnAfterDrawWeekNumbers: TTMSFNCCalendarAfterDrawWeekNumbersEvent;
    FOnBeforeDrawDayNumbers: TTMSFNCCalendarBeforeDrawDayNumbersEvent;
    FOnAfterDrawDayNumbersText: TTMSFNCCalendarAfterDrawDayNumbersTextEvent;
    FOnBeforeDrawWeekNumbersText: TTMSFNCCalendarBeforeDrawWeekNumbersTextEvent;
    FOnAfterDrawLines: TTMSFNCCalendarAfterDrawLinesEvent;
    FOnAfterDrawDayNumbers: TTMSFNCCalendarAfterDrawDayNumbersEvent;
    FOnBeforeDrawWeekNumbers: TTMSFNCCalendarBeforeDrawWeekNumbersEvent;
    FInteraction: TTMSFNCCalendarInteraction;
    FOnYearChanged: TTMSFNCCalendarYearChangedEvent;
    FOnSelectDate: TTMSFNCCalendarSelectedDateEvent;
    FOnMonthChanged: TTMSFNCCalendarMonthChangedEvent;
    FOnSelectMultiDate: TTMSFNCCalendarSelectedMultiDateEvent;
    FOnBeforeSelectDate: TTMSFNCCalendarBeforeSelectDateEvent;
    FOnBeforeDrawFooterText: TTMSFNCCalendarBeforeDrawFooterTextEvent;
    FOnBeforeDrawFooter: TTMSFNCCalendarBeforeDrawFooterEvent;
    FOnAfterDrawFooterText: TTMSFNCCalendarAfterDrawFooterTextEvent;
    FOnAfterDrawFooter: TTMSFNCCalendarAfterDrawFooterEvent;
    FOnCustomNavigation: TTMSFNCCalendarCustomNavigationEvent;
    FOnAfterDrawBadge: TTMSFNCCalendarAfterDrawBadgeEvent;
    FOnBeforeDrawBadge: TTMSFNCCalendarBeforeDrawBadgeEvent;
    FOnCustomArrowDraw: TTMSFNCCalendarCustomArrowDrawEvent;
    FOnAfterDrawArrowRight: TTMSFNCCalendarAfterDrawArrowRightEvent;
    FOnBeforeDrawArrowLeft: TTMSFNCCalendarBeforeDrawArrowLeftEvent;
    FOnBeforeDrawArrowRight: TTMSFNCCalendarBeforeDrawArrowRightEvent;
    FOnAfterDrawArrowLeft: TTMSFNCCalendarAfterDrawArrowLeftEvent;
    FOnNavigateBack: TTMSFNCCalendarCustomNavigateEvent;
    FOnNavigateForward: TTMSFNCCalendarCustomNavigateEvent;
    FOnNavigateFooter: TNotifyEvent;
    FGlobalFont: TTMSFNCAppearanceGlobalFont;
    procedure SetDay(const Value: Integer);
    procedure SetMonth(const Value: Integer);
    procedure SetYear(const Value: Integer);
    procedure SetFirstDay(const Value: Integer);
    procedure SetHeader(const Value: TTMSFNCCalendarHeader);
    procedure IncrementCell(var ACellSide: Single; const AMoveBy: Single);
    procedure ShiftCellH(var ACell: TRectF; const AMoveBy: Single);
    procedure ShiftCellV(var ACell: TRectF; const AMoveBy: Single);
    procedure IncrementWeekDay(var ADay: Integer);
    procedure SetDateForward(var ADate: TDate; ASetBy: Integer);
    procedure SetDateBackward(var ADate: TDate; ASetBy: Integer);
    procedure SetDayNameAppearance(const Value: TTMSFNCCalendarDayNameAppearance);
    procedure SetDateAppearance(const Value: TTMSFNCCalendarDateAppearance);
    procedure SetLineAppearance(const Value: TTMSFNCCalendarLineAppearance);
    procedure SetWeekNumberAppearance(const Value: TTMSFNCCalendarWeekNumberAppearance);
    procedure SetArrowLeftRect(AHeaderRect: TRectF; var AArrowRect: TRectF; ACentre: Single);
    procedure SetArrowRightRect(AHeaderRect: TREctF; var AArrowRect: TRectF; ACentre: Single);
    procedure SetFocusedDate(const Value: TDate);
    procedure SetDate(const Value: TDate);
    procedure SetInteraction(const Value: TTMSFNCCalendarInteraction);
    procedure SetMaxDate(const Value: TDate);
    procedure SetMinDate(const Value: TDate);
    procedure SetFooter(const Value: TTMSFNCCalendarFooter);
    procedure SetBitmapContainer(const Value: TTMSFNCBitmapContainer);
    procedure SetBadgeSize(const Value: Integer);
    procedure SetEvents(const Value: TTMSFNCCalendarEvents);
    function ColCount(const AShowWeeks: Boolean): Integer;
    function CalcRowCount(const ACol: Integer; const ADaysInAMonth: Integer): Integer;
    function CalcDateAreaHeight(const AHeight: Single; const AHeaderVisible: Boolean; const AFooterVisible: Boolean): Single;
    function GetArrowRect(ALeft: Boolean): TRectF;
    function GetFooterTextRect: TRectF;
    function PointInRect(ARect: TRectF; APoint: TPointF): Boolean;
    function IsMaxDateStored: Boolean;
    function IsMinDateStored: Boolean;
    function GetCurrentDate: TDate;
    function GetBitmapContainer: TTMSFNCBitmapContainer;
    function IsFocusedDateStored: Boolean;
    procedure SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
  protected
    procedure ApplyStyle; override;
    procedure ResetToDefaultStyle; override;
    procedure Changed;
    procedure CalculateXOffset; virtual;
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARectF: TRectF); override;
    procedure DrawHeader(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawFooter(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawArrows(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ADirection: TTMSFNCCalendarArrowLayoutValue);
    procedure DrawDayNames(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawDays(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawDayNumbers(AGraphics: TTMSFNCGraphics; ACellRect: TRectF; Col, Row, AMonth, I: Integer);
    procedure DrawWeekNumbers(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawLines(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawBadges(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
    procedure DrawBadge(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ABadge: TTMSFNCCalendarEventItem; ACol, ARow: Integer);
    procedure EventsChanged(Sender: TObject);
    procedure WeekNumbersChanged(Sender: TObject);
    procedure ArrowsChanged(Sender: TObject);
    procedure HeaderChanged(Sender: TObject);
    procedure FooterChanged(Sender: TObject);
    procedure DayNameAppearanceChanged(Sender: TObject);
    procedure LineAppearanceChanged(Sender: TObject);
    procedure ShowBeforeAndAfterChanged(Sender: TObject);
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseUp(Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X, Y: Single); override;
    procedure HandleKeyDown(var Key: Word; Shift: TShiftState); override;
    procedure HandleKeyUp(var Key: Word; Shift: TShiftState); override;
    procedure DoBeforeDrawHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeader(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawHeaderText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeaderText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawFooter(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawFooter(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawFooterText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawFooterText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawDayNames(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNames(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawDayNamesText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNamesText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawDayNumbers(AGraphics: TTMSFNCGraphics; ARect: TrectF; ADate: TDate; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNumbers(AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate); virtual;
    procedure DoBeforeDrawDayNumbersText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawDayNumbersText(AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawLines(AGraphics: TTMSFNCGraphics; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawLines(AGraphics: TTMSFNCGraphics); virtual;
    procedure DoBeforeDrawWeekNumbers(AGraphics: TTMSFNCGraphics; ARect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWeekNumbers(AGraphics: TTMSFNCGraphics; ARect: TRectF); virtual;
    procedure DoBeforeDrawWeekNumbersText(AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawWeekNumbersText(AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string; AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign); virtual;
    procedure DoBeforeDrawBadge(AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawBadge(AGraphics: TTMSFNCGraphics; ARect: TRectF; ABadge: TTMSFNCCalendarEventItem); virtual;
    procedure DoBeforeDrawArrowLeft(AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrowLeft(AGraphics: TTMSFNCGraphics; AArrowRect: TRectF); virtual;
    procedure DoBeforeDrawArrowRight(AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrowRight(AGraphics: TTMSFNCGraphics; AArrowRect: TRectF); virtual;
    procedure DoBeforeSelectDate(ADate: TDate); virtual;
    procedure DoSelectDate(ADate: TDate); virtual;
    procedure DoSelectMultiDate(AStartDate: TDate; AEndDate: TDate); virtual;
    procedure DoChangeYear(AYear: Integer); virtual;
    procedure DoChangeMonth(AMonth: Integer); virtual;
    procedure DoCustomNavigation(ADate: TDate; AFocusedDate: TDate; ADirection: Boolean); virtual;
    procedure DoCustomArrowDraw(AGraphics: TTMSFNCGraphics; ARect: TRectF; ADirection: Boolean); virtual;
    function IsAppearanceProperty(AObject: TObject; APropertyName: string; APropertyType: TTypeKind): Boolean; override;
    function GetVersion: string; override;
    function GetHintString: string; override;
    function HasHint: Boolean; override;
    function GetDocURL: string; override;
    procedure SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType); virtual;
    property BitmapContainer: TTMSFNCBitmapContainer read GetBitmapContainer write SetBitmapContainer;
    property Header: TTMSFNCCalendarHeader read FHeader write SetHeader;
    property Footer: TTMSFNCCalendarFooter read FFooter write SetFooter;
    property Events: TTMSFNCCalendarEvents read FEvents write SetEvents;
    property Month: Integer read FMonth write SetMonth;
    property Year: Integer read FYear write SetYear;
    property MaxDate: TDate read FMaxDate write SetMaxDate stored IsMaxDateStored;
    property MinDate: TDate read FMinDate write SetMinDate stored IsMinDateStored;
    property WeekNumberAppearance: TTMSFNCCalendarWeekNumberAppearance read FWeekNumberAppearance write SetWeekNumberAppearance;
    property FirstDay: Integer read FFirstDay write SetFirstDay default 1;
    property DayNameAppearance: TTMSFNCCalendarDayNameAppearance read FDayNameAppearance write SetDayNameAppearance;
    property DateAppearance: TTMSFNCCalendarDateAppearance read FDateAppearance write SetDateAppearance;
    property LineAppearance: TTMSFNCCalendarLineAppearance read FLineAppearance write SetLineAppearance;
    property Interaction: TTMSFNCCalendarInteraction read FInteraction write SetInteraction;
    property GlobalFont: TTMSFNCAppearanceGlobalFont read FGlobalFont write SetGlobalFont;
    property OnBeforeDrawHeader: TTMSFNCCalendarBeforeDrawHeaderEvent read FOnBeforeDrawHeader write FOnBeforeDrawHeader;
    property OnAfterDrawHeader: TTMSFNCCalendarAfterDrawHeaderEvent read FOnAfterDrawHeader write FOnAfterDrawHeader;
    property OnBeforeDrawHeaderText: TTMSFNCCalendarBeforeDrawHeaderTextEvent read FOnBeforeDrawHeaderText write FOnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText: TTMSFNCCalendarAfterDrawHeaderTextEvent read FOnAfterDrawHeaderText write FOnAfterDrawHeaderText;
    property OnBeforeDrawFooter: TTMSFNCCalendarBeforeDrawFooterEvent read FOnBeforeDrawFooter write FOnBeforeDrawFooter;
    property OnAfterDrawFooter: TTMSFNCCalendarAfterDrawFooterEvent read FOnAfterDrawFooter write FOnAfterDrawFooter;
    property OnBeforeDrawFooterText: TTMSFNCCalendarBeforeDrawFooterTextEvent read FOnBeforeDrawFooterText write FOnBeforeDrawFooterText;
    property OnAfterDrawFooterText: TTMSFNCCalendarAfterDrawFooterTextEvent read FOnAfterDrawFooterText write FOnAfterDrawFooterText;
    property OnBeforeDrawDayNames: TTMSFNCCalendarBeforeDrawDayNamesEvent read FOnBeforeDrawDayNames write FOnBeforeDrawDayNames;
    property OnAfterDrawDayNames: TTMSFNCCalendarAfterDrawDayNamesEvent read FOnAfterDrawDayNames write FOnAfterDrawDayNames;
    property OnBeforeDrawDayNamesText: TTMSFNCCalendarBeforeDrawDayNamesTextEvent read FOnBeforeDrawDayNamesText write FOnBeforeDrawDayNamesText;
    property OnAfterDrawDayNamesText: TTMSFNCCalendarAfterDrawDayNamesTextEvent read FOnAfterDrawDayNamesText write FOnAfterDrawDayNamesText;
    property OnBeforeDrawDayNumbers: TTMSFNCCalendarBeforeDrawDayNumbersEvent read FOnBeforeDrawDayNumbers write FOnBeforeDrawDayNumbers;
    property OnAfterDrawDayNumbers: TTMSFNCCalendarAfterDrawDayNumbersEvent read FOnAfterDrawDayNumbers write FOnAfterDrawDayNumbers;
    property OnBeforeDrawDayNumbersText: TTMSFNCCalendarBeforeDrawDayNumbersTextEvent read FOnBeforeDrawDayNumbersText write FOnBeforeDrawDayNumbersText;
    property OnAfterDrawDayNumbersText: TTMSFNCCalendarAfterDrawDayNumbersTextEvent read FOnAfterDrawDayNumbersText write FOnAfterDrawDayNumbersText;
    property OnBeforeDrawLines: TTMSFNCCalendarBeforeDrawLinesEvent read FOnBeforeDrawLines write FOnBeforeDrawLines;
    property OnAfterDrawLines: TTMSFNCCalendarAfterDrawLinesEvent read FOnAfterDrawLines write FOnAfterDrawLines;
    property OnBeforeDrawWeekNumbers: TTMSFNCCalendarBeforeDrawWeekNumbersEvent read FOnBeforeDrawWeekNumbers write FOnBeforeDrawWeekNumbers;
    property OnAfterDrawWeekNumbers: TTMSFNCCalendarAfterDrawWeekNumbersEvent read FOnAfterDrawWeekNumbers write FOnAfterDrawWeekNumbers;
    property OnBeforeDrawWeekNumbersText: TTMSFNCCalendarBeforeDrawWeekNumbersTextEvent read FOnBeforeDrawWeekNumbersText write FOnBeforeDrawWeekNumbersText;
    property OnAfterDrawWeekNumbersText: TTMSFNCCalendarAfterDrawWeekNumbersTextEvent read FOnAfterDrawWeekNumbersText write FOnAfterDrawWeekNumbersText;
    property OnBeforeDrawBadge: TTMSFNCCalendarBeforeDrawBadgeEvent read FOnBeforeDrawBadge write FOnBeforeDrawBadge;
    property OnAfterDrawBadge: TTMSFNCCalendarAfterDrawBadgeEvent read FOnAfterDrawBadge write FOnAfterDrawBadge;
    property OnBeforeDrawArrowLeft: TTMSFNCCalendarBeforeDrawArrowLeftEvent read FOnBeforeDrawArrowLeft write FOnBeforeDrawArrowLeft;
    property OnAfterDrawArrowLeft: TTMSFNCCalendarAfterDrawArrowLeftEvent read FOnAfterDrawArrowLeft write FOnAfterDrawArrowLeft;
    property OnBeforeDrawArrowRight: TTMSFNCCalendarBeforeDrawArrowRightEvent read FOnBeforeDrawArrowRight write FOnBeforeDrawArrowRight;
    property OnAfterDrawArrowRight: TTMSFNCCalendarAfterDrawArrowRightEvent read FOnAfterDrawArrowRight write FOnAfterDrawArrowRight;
    property OnBeforeSelectDate: TTMSFNCCalendarBeforeSelectDateEvent read FOnBeforeSelectDate write FOnBeforeSelectDate;
    property OnSelectDate: TTMSFNCCalendarSelectedDateEvent read FOnSelectDate write FOnSelectDate;
    property OnSelectMultiDate: TTMSFNCCalendarSelectedMultiDateEvent read FOnSelectMultiDate write FOnSelectMultiDate;
    property OnYearChanged: TTMSFNCCalendarYearChangedEvent read FOnYearChanged write FOnYearChanged;
    property OnMonthChanged: TTMSFNCCalendarMonthChangedEvent read FOnMonthChanged write FOnMonthChanged;
    property OnCustomNavigation: TTMSFNCCalendarCustomNavigationEvent read FOnCustomNavigation write FOnCustomNavigation;
    property OnCustomArrowDraw: TTMSFNCCalendarCustomArrowDrawEvent read FOnCustomArrowDraw write FOnCustomArrowDraw;
    property OnNavigateBack: TTMSFNCCalendarCustomNavigateEvent read FOnNavigateBack write FOnNavigateBack;
    property OnNavigateForward: TTMSFNCCalendarCustomNavigateEvent read FOnNavigateForward write FOnNavigateForward;
    property OnNavigateFooter: TNotifyEvent read FOnNavigateFooter write FOnNavigateFooter;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure SelectDate(ADate: TDate);
    procedure SelectEvent(ADate: TDate);
    procedure SelectMultiDates(AStartDate: TDate; AEndDate: TDate);
    procedure PreviousDate;
    procedure NextDate;
    procedure UpdateControlAfterResize; override;
    procedure SelectADate(ADate: TDate);
    procedure SelectDateIfAvailable(ADate: TDate);
    procedure InitSample; virtual;
    function ColAtX(AX: Single): Integer;
    function RowAtY(AY: Single): Integer;
    function DateToRow(ADate: TDate): Integer;
    function DateToCol(ADate: TDate): Integer;
    function XYToDate(AX, AY: Single): TDate;
    function CurrentDay: Integer;
    function CurrentMonth: Integer;
    function CurrentYear: Integer;
    function PreviousMonth: Integer;
    function PreviousYear: Integer;
    function NextMonth: Integer;
    function NextYear: Integer;
    function SelectedDate: TDate;
    property Day: Integer read FDay write SetDay;
    property Date: TDate read FDate write SetDate;
    property CurrentDate: TDate read GetCurrentDate;
    property FocusedDate: TDate read FFocusedDate write SetFocusedDate stored IsFocusedDateStored nodefault;
    property SelectedDates: TTMSFNCCalendarDates read FSelectedDates;
    property BadgeSize: Integer read FBadgeSize write SetBadgeSize default 14;
    property OnChange: TNotifyEvent read FOnChange write FOnChange;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCCalendar = class(TTMSFNCCustomCalendar)
  published
    property BitmapContainer;
    property BadgeSize;
    property Header;
    property Footer;
    property Fill;
    property Stroke;
    property Day;
    property Month;
    property Year;
    property Date;
    property MaxDate;
    property MinDate;
    property FirstDay;
    property Events;
    property WeekNumberAppearance;
    property DayNameAppearance;
    property DateAppearance;
    property LineAppearance;
    property Interaction;
    property GlobalFont;
    property OnBeforeDrawHeader;
    property OnAfterDrawHeader;
    property OnBeforeDrawFooter;
    property OnAfterDrawFooter;
    property OnBeforeDrawDayNames;
    property OnAfterDrawDayNames;
    property OnBeforeDrawDayNumbers;
    property OnAfterDrawDayNumbers;
    property OnBeforeDrawLines;
    property OnAfterDrawLines;
    property OnBeforeDrawWeekNumbers;
    property OnAfterDrawWeekNumbers;
    property OnBeforeDrawArrowLeft;
    property OnAfterDrawArrowLeft;
    property OnBeforeDrawArrowRight;
    property OnAfterDrawArrowRight;
    property OnBeforeDrawBadge;
    property OnAfterDrawBadge;
    property OnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText;
    property OnBeforeDrawFooterText;
    property OnAfterDrawFooterText;
    property OnBeforeDrawDayNamesText;
    property OnAfterDrawDayNamesText;
    property OnBeforeDrawDayNumbersText;
    property OnAfterDrawDayNumbersText;
    property OnBeforeDrawWeekNumbersText;
    property OnAfterDrawWeekNumbersText;
    property OnBeforeSelectDate;
    property OnSelectDate;
    property OnSelectMultiDate;
    property OnYearChanged;
    property OnMonthChanged;
    property OnCustomNavigation;
    property OnCustomArrowDraw;
    property OnNavigateBack;
    property OnNavigateForward;
    property OnNavigateFooter;
  end;

implementation

uses
  Math, DateUtils, Variants, WEBLib.TMSFNCPersistence;

const
  CALMINDATE = -693594;
  ARRPOS = 8;
  //DAYNAMEHEIGHT = 15;

function GetTickCountX: DWORD;
var
  h, m, s, ms: Word;
begin
  DecodeTime(Now, h, m, s, ms);
  Result := ms + s * 1000 + m * 60 * 1000 + h * 60 * 60 * 1000;
end;

{ TTMSFNCCustomCalendar }

function TTMSFNCCustomCalendar.ColCount(const AShowWeeks: Boolean): Integer;
begin
  if AShowWeeks then
    Result := 8
  else
    Result := 7;
end;

constructor TTMSFNCCustomCalendar.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  TextQuality := gtqClearType;
  {$ENDIF}
  {$ENDIF}
  Width := 235;
  Height := 235;
  Date := Int(Now);
  FHoverDate := CALMINDATE;
  FSelectedAll := False;
  FBadgeSize := 14;
  FFocusedDate := FDate;
  SetFirstDay(1);

  FHeader := TTMSFNCCalendarHeader.Create(Self);
  FFooter := TTMSFNCCalendarFooter.Create;
  FDayNameAppearance := TTMSFNCCalendarDayNameAppearance.Create;
  FDateAppearance := TTMSFNCCalendarDateAppearance.Create;
  FLineAppearance := TTMSFNCCalendarLineAppearance.Create;
  FWeekNumberAppearance := TTMSFNCCalendarWeekNumberAppearance.Create;
  FInteraction := TTMSFNCCalendarInteraction.Create;
  FSelectedDates := TTMSFNCCalendarDates.Create(TTMSFNCCalendarDateItem);
  FEvents := TTMSFNCCalendarEvents.Create(TTMSFNCCalendarEventItem);

  SelectDate(FFocusedDate);
  CalculateXOffset;

  FEvents.OnChange := @EventsChanged;
  FWeekNumberAppearance.OnChange := @WeekNumbersChanged;
  FDateAppearance.OnChange := @ShowBeforeAndAfterChanged;
  FHeader.OnChange := @HeaderChanged;
  FHeader.Arrow.OnChange := @ArrowsChanged;
  FFooter.OnChange := @FooterChanged;
  FDayNameAppearance.OnChange := @DayNameAppearanceChanged;
  FLineAppearance.OnChange := @LineAppearanceChanged;
  FGlobalFont := TTMSFNCAppearanceGlobalFont.Create(Self);

  if IsDesignTime then
    InitSample;
end;

function TTMSFNCCustomCalendar.DateToCol(ADate: TDate): Integer;
var
  Col: Integer;
begin
  Col := DayOfWeek(EncodeDate(FYear, FMonth, 1)) - FirstDay;
  if Col < 0 then
    Col := Col + 7;

  if WeekNumberAppearance.Visible then
    Result := (Col + DayOf(ADate) + DateToRow(ADate)) mod 8
  else
    Result := (Col + DayOf(ADate) - 1) mod 7;
end;

function TTMSFNCCustomCalendar.DateToRow(ADate: TDate): Integer;
var
  fd: Integer;
begin
  fd := DayOfWeek(EncodeDate(FYear, FMonth, 1)) - FirstDay;
  if fd < 0 then
    fd := fd + 7;

  Result := (fd - 1 + DayOf(ADate)) div 7;
end;

procedure TTMSFNCCustomCalendar.DayNameAppearanceChanged(Sender: TObject);
begin
  Changed;
end;

destructor TTMSFNCCustomCalendar.Destroy;
begin
  FGlobalFont.Free;
  FHeader.Free;
  FFooter.Free;
  FDayNameAppearance.Free;
  FDateAppearance.Free;
  FLineAppearance.Free;
  FWeekNumberAppearance.Free;
  FSelectedDates.Free;
  FEvents.Free;
  FInteraction.Free;
  inherited;
end;

 procedure TTMSFNCCustomCalendar.DoAfterDrawArrowLeft(AGraphics: TTMSFNCGraphics;
  AArrowRect: TRectF);
begin
  if Assigned(OnAfterDrawArrowLeft) then
    OnAfterDrawArrowLeft(Self, AGraphics, AArrowRect);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawArrowRight(
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF);
begin
  if Assigned(OnAfterDrawArrowRight) then
    OnAfterDrawArrowRight(Self, Agraphics, AArrowRect);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawBadge(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ABadge: TTMSFNCCalendarEventItem);
begin
  if Assigned(OnAfterDrawBadge) then
    OnAfterDrawBadge(Self, AGraphics, ARect, ABadge);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawDayNames(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(OnAfterDrawDayNames) then
    OnAfterDrawDayNames(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawDayNamesText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawDayNamesText) then
    OnAfterDrawDayNamesText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawDayNumbers(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; ADate: TDate);
begin
  if Assigned(OnAfterDrawDayNumbers) then
    OnAfterDrawDayNumbers(Self, AGraphics, ARect, ADate);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawDayNumbersText(
  AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawDayNumbersText) then
    OnAfterDrawDayNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawFooter(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(OnAfterDrawFooter) then
    OnAfterDrawFooter(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawFooterText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawFooterText) then
    OnAfterDrawFooterText(Self, AGraphics, ARect, AText, AHorizontalTexTAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(OnAfterDrawHeader) then
    OnAfterDrawHeader(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawHeaderText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawHeaderText) then
    OnAfterDrawHeaderText(Self, AGraphics, ARect, AText, AHorizontalTexTAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawWeekNumbersText(
  AGraphics: TTMSFNCGraphics; ARect: TrectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign);
begin
  if Assigned(OnAfterDrawWeekNumbersText) then
    OnAfterDrawWeekNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawLines(AGraphics: TTMSFNCGraphics);
begin
  if Assigned(OnAfterDrawLines) then
    OnAfterDrawLines(Self, AGraphics);
end;

procedure TTMSFNCCustomCalendar.DoAfterDrawWeekNumbers(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
begin
  if Assigned(OnAfterDrawWeekNumbers) then
    OnAfterDrawWeekNumbers(Self, AGraphics, ARect);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawArrowLeft(
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrowLeft) then
    OnBeforeDrawArrowLeft(Self, Agraphics, AArrowRect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawArrowRight(
  AGraphics: TTMSFNCGraphics; AArrowRect: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrowRight) then
    OnBeforeDrawArrowRight(Self, AGraphics, AArrowRect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawBadge(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ABadge: TTMSFNCCalendarEventItem; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawBadge) then
    OnBeforeDrawBadge(Self, AGraphics, ARect, ABadge, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawDayNames(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNames) then
    OnBeforeDrawDayNames(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawDayNamesText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow: Boolean; var ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNamesText) then
    OnBeforeDrawDayNamesText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawDayNumbers(
  AGraphics: TTMSFNCGraphics; ARect: TrectF; ADate: TDate; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNumbers) then
    OnBeforeDrawDayNumbers(Self, AGraphics, ARect, ADate, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawDayNumbersText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawDayNumbersText) then
    OnBeforeDrawDayNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawFooter(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFooter) then
    OnBeforeDrawFooter(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawFooterText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawFooterText) then
    OnBeforeDrawFooterText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawHeader(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeader) then
    OnBeforeDrawHeader(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawHeaderText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeaderText) then
    OnBeforeDrawHeaderText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawWeekNumbersText(
  AGraphics: TTMSFNCGraphics; ARect: TRectF; AText: string;
  AHorizontalTextAlign, AVerticalTextAlign: TTMSFNCGraphicsTextAlign;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawWeekNumbersText) then
    OnBeforeDrawWeekNumbersText(Self, AGraphics, ARect, AText, AHorizontalTextAlign, AVerticalTextAlign, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeSelectDate(ADate: TDate);
begin
  if Assigned(OnBeforeSelectDate) then
    OnBeforeSelectDate(Self, ADate);
end;

procedure TTMSFNCCustomCalendar.DoChangeMonth(AMonth: Integer);
begin
  if Assigned(OnMonthChanged) then
    OnMonthChanged(Self, AMonth);
end;

procedure TTMSFNCCustomCalendar.DoChangeYear(AYear: Integer);
begin
  if Assigned(OnYearChanged) then
    OnYearChanged(Self, AYear);
end;

procedure TTMSFNCCustomCalendar.DoCustomArrowDraw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; ADirection: Boolean);
begin
  if Assigned(OnCustomArrowDraw) then
    OnCustomArrowDraw(Self, AGraphics, ARect, ADirection);
end;

procedure TTMSFNCCustomCalendar.DoCustomNavigation(ADate,
  AFocusedDate: TDate; ADirection: Boolean);
begin
  if Assigned(OnCustomNavigation) then
    OnCustomNavigation(Self, ADate, AFocusedDate, ADirection);
end;

procedure TTMSFNCCustomCalendar.DoSelectDate(ADate: TDate);
begin
  if Assigned(OnSelectDate) then
    OnSelectDate(Self, ADate);
end;

procedure TTMSFNCCustomCalendar.DoSelectMultiDate(AStartDate,
  AEndDate: TDate);
begin
  if Assigned(OnSelectMultiDate) then
    OnSelectMultiDate(Self, AStartDate, AEndDate);
end;

function TTMSFNCCustomCalendar.IsMaxDateStored: Boolean;
begin
  Result := FMaxDate <> 0.0;
end;

function TTMSFNCCustomCalendar.IsMinDateStored: Boolean;
begin
  Result := FMinDate <> 0.0;
end;

procedure TTMSFNCCustomCalendar.LineAppearanceChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawLines(AGraphics: TTMSFNCGraphics;
  var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawLines) then
    OnBeforeDrawLines(Self, AGraphics, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.DoBeforeDrawWeekNumbers(AGraphics: TTMSFNCGraphics;
  ARect: TRectF; var AAllow, ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawWeekNumbers) then
    OnBeforeDrawWeekNumbers(Self, AGraphics, ARect, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomCalendar.Draw(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
begin
  inherited;
  FDateAreaHeight := CalcDateAreaHeight(Height, FHeader.Visible, FFooter.Visible);

  AGraphics.BitmapContainer := BitmapContainer;

  DrawDayNames(AGraphics, ARectF);

  if FDateAreaHeight > 0 then
  begin
    DrawDays(AGraphics, ARectF);
    if WeekNumberAppearance.Visible then
      DrawWeekNumbers(AGraphics, ARectF);
  end;

  if FFooter.Visible then
    DrawFooter(AGraphics, ARectF);

  DrawLines(AGraphics, ARectF);

  if FHeader.Visible then
    DrawHeader(AGraphics, ARectF);

  DrawBadges(AGraphics, ARectF);
end;

procedure TTMSFNCCustomCalendar.DrawArrows(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ADirection: TTMSFNCCalendarArrowLayoutValue);
var
  arrPath: TTMSFNCGraphicsPath;
  p: TPointF;
  rHeight, rRight, rLeft: Single;
  b, df: Boolean;
begin
  rHeight := (ARectF.Bottom - ARectF.Top);
  InflateRectEx(ARectF, -1, -1);
  AGraphics.Fill.Color := Header.Arrow.Color;
  AGraphics.Stroke.Color := Header.Arrow.Color;

  if ADirection = alvLeft then
  begin
    b := True;
    df := True;
    case Header.Arrow.ArrowLeftType of
      atTriangle:
      begin
        arrPath := TTMSFNCGraphicsPath.Create;
        try
          p := PointF(ARectF.Left, ARectF.Top + rHeight / 2);
          arrPath.MoveTo(p);
          arrPath.LineTo(PointF(ARectF.Right, ARectF.Top));
          arrPath.LineTo(PointF(ARectF.Right, ARectF.Bottom));
          arrPath.ClosePath;
          AGraphics.Stroke.Width := 1;

          DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawPath(arrPath);

            DoAfterDrawArrowLeft(AGraphics, ARectF);
          end;
        finally
          arrPath.Free;
        end;
      end;
      atArrow:
      begin
        p := PointF(ARectF.Left, ARectF.Top + rHeight / 2);
        rRight := ARectF.Left + (ARectF.Right - ARectF.Left) / 2;
        AGraphics.Stroke.Width := 2;

        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(PointF(rRight, ARectF.Top), p);
            AGraphics.DrawLine(p, PointF(rRight, ARectF.Bottom));
          end;
          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
      atBitmap:
      begin
        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawScaledBitmap(ARectF, FHeader.Arrow.FArrowLeftBitmap);

          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
      atCustom:
      begin
        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
            DoCustomArrowDraw(AGraphics, ARectF, True);

          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
    end;
  end
  else if ADirection = alvRight then
  begin
    b := True;
    df := True;
    case Header.Arrow.ArrowRightType of
      atTriangle:
      begin
        arrPath := TTMSFNCGraphicsPath.Create;
        try
          p := PointF(ARectF.Right, ARectF.Top + rHeight / 2);
          arrPath.MoveTo(p);
          arrPath.LineTo(PointF(ARectF.Left, ARectF.Bottom));
          arrPath.LineTo(PointF(ARectF.Left, ARectF.Top));
          arrPath.ClosePath;
          AGraphics.Stroke.Width := 1;

          DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
          if b then
          begin
            if df then
              AGraphics.DrawPath(arrPath);

            DoAfterDrawArrowLeft(AGraphics, ARectF);
          end;
        finally
          arrPath.Free;
        end;
      end;
      atArrow:
      begin
        p := PointF(ARectF.Right, ARectF.Top + rHeight / 2);
        rLeft := ARectF.Left + (ARectF.Right - ARectF.Left) / 2;
        AGraphics.Stroke.Width := 2;

        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
          begin
            AGraphics.DrawLine(PointF(rLeft, ARectF.Top), p);
            AGraphics.DrawLine(PointF(rLeft, ARectF.Bottom), p);
          end;
          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
      atBitmap:
      begin
        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
            AGraphics.DrawScaledBitmap(ARectF, FHeader.Arrow.ArrowRightBitmap);

          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
      atCustom:
      begin
        DoBeforeDrawArrowLeft(AGraphics, ARectF, b, df);
        if b then
        begin
          if df then
            DoCustomArrowDraw(AGraphics, ARectF, False);

          DoAfterDrawArrowLeft(AGraphics, ARectF);
        end;
      end;
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.DrawBadge(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF; ABadge: TTMSFNCCalendarEventItem; ACol, ARow: Integer);
var
  b, df: Boolean;
begin
  if ABadge.BadgeColor <> gcNull then
  begin
    AGraphics.Fill.Color := ABadge.BadgeColor;
    AGraphics.Stroke.Color := ABadge.BadgeColor;
  end
  else
  begin
    AGraphics.Fill.Assign(DateAppearance.BadgeFill);
    AGraphics.Stroke.Assign(DateAppearance.BadgeStroke);
  end;

  if ABadge.BadgeFontColor <> gcNull then
  begin
    {$IFNDEF FMXLIB}
    AGraphics.Font.Size := 7;
    {$ENDIF}
    {$IFDEF FMXLIB}
    AGraphics.Font.Size := 10;
    {$ENDIF}
    AGraphics.Font.Color := ABadge.BadgeFontColor
  end
  else
    AGraphics.Font.AssignSource(DateAppearance.BadgeFont);

  ShiftCellH(ARectF, (ACol + 1) * FXOffset - FBadgeSize);
  ShiftCellV(ARectF, ARow * FYOffset);

  b := True;
  df := True;
  DoBeforeDrawBadge(AGraphics, ARectF, ABadge, b, df);
  if b then
  begin
    if df then
    begin
      AGraphics.DrawEllipse(ARectF);
      if ABadge.BadgeValue <> 0 then
         AGraphics.DrawText(ARectF, IntToStr(ABadge.BadgeValue), False, gtaCenter, gtaCenter)
    end;
    DoAfterDrawBadge(AGraphics, ARectF, ABadge);
  end;
end;

procedure TTMSFNCCustomCalendar.DrawBadges(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  col, row, I: Integer;
  br: TRectF;
  badge: TTMSFNCCalendarEventItem;
begin
  br := RectF(ARectF.Left, ARectF.Top, ARectF.Left + FBadgeSize, ARectF.Top + FBadgeSize);
  ShiftCellH(br, 1);

  if FHeader.Visible then
    ShiftCellV(br, FHeader.Height + FDayNameAppearance.Height)
  else
    ShiftCellV(br, FDayNameAppearance.Height);

  for I := 0 to FEvents.Count - 1 do
  begin
    Badge := FEvents.Items[I];
    if (FYear = YearOf(badge.Date)) and (FMonth = MonthOf(badge.Date)) and badge.ShowBadge then
    begin
      row := DateToRow(badge.Date);
      col := DateToCol(badge.Date);
      DrawBadge(AGraphics, br, badge, col, row);
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.DrawDayNames(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  dnColCount, dn: Integer;
  cellRect: TRectF;
  I: Integer;
  b, df: Boolean;
begin
  dnColCount := ColCount(WeekNumberAppearance.Visible);

  if FHeader.Visible then
    cellRect := RectF(ARectF.Left, Header.Height - 1, FXOffset, Header.Height + FDayNameAppearance.Height)
  else
    cellRect := RectF(ARectF.Left, ARectF.Top + 1, FXOffset, FDayNameAppearance.Height);

  dn := FirstDay;
  ShiftCellH(cellRect, 1);

  if WeekNumberAppearance.Visible then
  begin
    ShiftCellH(cellRect, FXOffset);
    dnColCount := dnColCount - 1;
  end;

  AGraphics.Fill.Assign(DayNameAppearance.Fill);
  AGraphics.Stroke.Assign(DayNameAppearance.Stroke);
  AGraphics.Font.AssignSource(DayNameAppearance.Font);

  for I := 0 to dnColCount - 1 do
  begin
    b := True;
    df := True;
    DoBeforeDrawDayNames(AGraphics, cellRect, b, df);
    if b then
    begin
      if df then
        AGraphics.DrawRectangle(cellRect, gcrmNone);

      DoAfterDrawDayNames(AGraphics, cellRect);
    end;

    b := True;
    df := True;
    DoBeforeDrawDayNamesText(AGraphics, cellRect, FormatSettings.ShortDayNames[dn], DayNameAppearance.HorizontalTextAlign, DayNameAppearance.VerticalTextAlign, b, df);
    if b then
    begin
      if df then
        AGraphics.DrawText(cellRect, FormatSettings.ShortDayNames[dn], False, DayNameAppearance.HorizontalTextAlign, DayNameAppearance.VerticalTextAlign);

      DoAfterDrawDayNamesText(AGraphics, cellRect, FormatSettings.ShortDayNames[dn], DayNameAppearance.HorizontalTextAlign, DayNameAppearance.VerticalTextAlign);
    end;

    IncrementWeekDay(dn);
    ShiftCellH(cellRect, FXOffset);
  end;
end;

procedure TTMSFNCCustomCalendar.DrawDayNumbers(AGraphics: TTMSFNCGraphics; ACellRect: TRectF; Col,
  Row, AMonth, I: Integer);
var
  d, mind, maxd: TDate;
  b, df: Boolean;
begin
  if WeekNumberAppearance.Visible then
    Inc(Col);

  if FHeader.Visible then
    ShiftCellV(ACellRect, FHeader.Height + FDayNameAppearance.Height + (Row - 1) * FYOffset)
  else
    ShiftCellV(ACellRect, FDayNameAppearance.Height + (Row - 1) * FYOffset);

  ShiftCellH(ACellRect, Col * FXOffset);
  d := EncodeDate(FYear, AMonth, I);
  mind := MinDate;
  maxd := MaxDate;

  if MinDate = 0 then
    mind := d;

  if MaxDate = 0 then
    maxd := d;

  if FSelectedDates.InCollection(d) then
  begin
    AGraphics.Fill.Assign(DateAppearance.SelectedFill);
    AGraphics.Stroke.Assign(DateAppearance.SelectedStroke);
    AGraphics.Font.AssignSource(DateAppearance.SelectedFont);
  end
  else if (Int(d) < Int(mind)) or (Int(d) > Int(maxd)) then
  begin
    AGraphics.Fill.Assign(DateAppearance.DisabledFill);
    AGraphics.Stroke.Assign(DateAppearance.DisabledStroke);
    AGraphics.Font.AssignSource(DateAppearance.DisabledFont);
  end
  else if FDateAppearance.ShowDaysBefore and (20 < I) and (Row = 1) then
  begin
    d := IncMonth(d, -1);
    AGraphics.Fill.Assign(DateAppearance.DateBeforeFill);
    AGraphics.Font.AssignSource(DateAppearance.DateBeforeFont);
    AGraphics.Stroke.Assign(DateAppearance.DateBeforeStroke);
  end
  else if FDateAppearance.ShowDaysAfter and (15 > I) and (Row >= 5) then
  begin
    d := IncMonth(d, 1);
    AGraphics.Fill.Assign(DateAppearance.DateAfterFill);
    AGraphics.Font.AssignSource(DateAppearance.DateAfterFont);
    AGraphics.Stroke.Assign(DateAppearance.DateAfterStroke);
  end
  else if CompareDate(d, CurrentDate) = 0 then
  begin
    AGraphics.Fill.Assign(DateAppearance.TodayFill);
    AGraphics.Stroke.Assign(DateAppearance.TodayStroke);
    AGraphics.Font.AssignSource(DateAppearance.TodayFont);
  end
  else
  begin
    AGraphics.Fill.Assign(DateAppearance.Fill);
    AGraphics.Stroke.Assign(DateAppearance.Stroke);
    AGraphics.Font.AssignSource(DateAppearance.Font);
  end;

  b := True;
  df := True;
  DoBeforeDrawDayNumbers(AGraphics, ACellRect, d, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawRectangle(ACellRect, gcrmNone);

    DoAfterDrawDayNumbers(AGraphics, ACellRect, d);
  end;

  b := True;
  DoBeforeDrawDayNumbersText(AGraphics, ACellRect, IntToStr(I), DateAppearance.HorizontalTextAlign, DateAppearance.VerticalTextAlign, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawText(ACellRect, IntToStr(I), False, DateAppearance.HorizontalTextAlign, DateAppearance.VerticalTextAlign);

    DoAfterDrawDayNumbersText(AGraphics, ACellRect, IntToStr(I), DateAppearance.HorizontalTextAlign, DateAppearance.VerticalTextAlign);
  end;

  if (CompareDate(d, FFocusedDate) = 0) and DateAppearance.ShowFocus then
    AGraphics.DrawFocusRectangle(ACellRect);
end;

procedure TTMSFNCCustomCalendar.DrawDays(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  I, row, allRows, col, bCol, dim, baDays: Integer;
  CalcMonth: TDate;
  CellRect: TRectF;
begin
  row := 1;
  CalcMonth := (EncodeDate(FYear, FMonth, 1));
  col := DayOfWeek(CalcMonth) - FirstDay;

  if col < 0 then
    col := col + 7;

  dim := DaysInMonth(CalcMonth);
  allRows := CalcRowCount(col, dim);

  if allRows <> 0 then
    FYOffset := FDateAreaHeight / allRows;

  CellRect := RectF(ARectF.Left, ARectF.Top, ARectF.Left + FXOffset, ARectF.Top + FYOffset);
  ShiftCellH(CellRect, 1);

  if FDateAppearance.ShowDaysBefore then
  begin
    bCol := col;
    if bCol > 0 then
    begin
      baDays := DaysInMonth(IncMonth(CalcMonth, -1));
      for I := bCol - 1 downto 0 do
      begin
        Dec(bCol);
        DrawDayNumbers(AGraphics, CellRect, bCol, row, PreviousMonth, baDays);
        Dec(baDays);
      end;
    end;
  end;

  for I := 1 to dim do
  begin
    DrawDayNumbers(AGraphics, CellRect, col, row, FMonth, I);
    Inc(col);
    if col > 6 then
    begin
      col := 0;
      Inc(row);
    end;
  end;

  if FDateAppearance.ShowDaysAfter then
  begin
    baDays := 1;
    while (row <= allRows) do
    begin
      DrawDayNumbers(AGraphics, CellRect, col, row, NextMonth, baDays);
      Inc(col);
      if col > 6 then
      begin
        col := 0;
        Inc(row);
      end;
      Inc(baDays);
    end;
  end;

  FNumOfWeeksDrawn := allRows;
end;

procedure TTMSFNCCustomCalendar.DrawFooter(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  fr: TRectF;
  fText: string;
  b, df: Boolean;
begin
  fr := RectF(ARectF.Left, ARectF.Bottom - FFooter.Height, ARectF.Right, ARectF.Bottom);
  AGraphics.Fill.Assign(FFooter.Fill);
  AGraphics.Stroke.Assign(FFooter.Stroke);

  b := True;
  df := True;
  DoBeforeDrawFooter(AGraphics, fr, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawRectangle(fr);

    DoAfterDrawFooter(AGraphics, fr);
  end;

  if fdSelected = FFooter.DateCaption then
    fText := FFooter.Caption + ' ' + DateToStr(SelectedDate)
  else if fdToday = FFooter.DateCaption then
    fText := FFooter.Caption + ' ' + DateToStr(CurrentDate)
  else
    fText := FFooter.Caption;

  AGraphics.Font.AssignSource(FFooter.Font);
  FFooter.CaptionRect := AGraphics.CalculateText(fText, fr);

  b := True;
  df := True;
  DoBeforeDrawFooterText(AGraphics, fr, fText, FFooter.HorizontalTextAlign, FFooter.VerticalTextAlign, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawText(fr, fText, False, FFooter.HorizontalTextAlign, FFooter.VerticalTextAlign);

    DoAfterDrawFooterText(AGraphics, fr, fText, FFooter.HorizontalTextAlign, FFooter.VerticalTextAlign);
  end;
end;

procedure TTMSFNCCustomCalendar.DrawHeader(AGraphics: TTMSFNCGraphics; ARectF: TRectF);
var
  b, df: Boolean;
  hr, ar: TRectF;
  rCentre: Single;
  ht: string;
begin
  ht := FormatSettings.ShortMonthNames[Month] + ' ' + IntToStr(Year);
  hr := RectF(ARectF.Left, ARectF.Top, ARectF.Right, FHeader.Height);
  AGraphics.Fill.Assign(Header.Fill);
  AGraphics.Stroke.Assign(Header.Stroke);

  b := True;
  df := True;
  DoBeforeDrawHeader(AGraphics, hr, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawRectangle(hr);

    DoAfterDrawHeader(AGraphics, hr);
  end;

  AGraphics.Font.AssignSource(Header.Font);

  b := True;
  df := True;
  DoBeforeDrawHeaderText(AGraphics, hr, ht, Header.HorizontalTextAlign, Header.VerticalTextAlign, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawText(hr, ht, False, Header.HorizontalTextAlign, Header.VerticalTextAlign);

    DoAfterDrawHeaderText(AGraphics, hr, ht, Header.HorizontalTextAlign, Header.VerticalTextAlign);
  end;

  if not (Header.Arrow.Visibility = []) then
  begin
    ar := hr;
    rCentre := (hr.Bottom - hr.Top) / 2;

    if alvLeft in Header.Arrow.Visibility then
    begin
      SetArrowLeftRect(hr, ar, rCentre);
      DrawArrows(AGraphics, ar, alvLeft);
    end;

    if alvRight in Header.Arrow.Visibility then
    begin
      SetArrowRightRect(hr, ar, rCentre);
      DrawArrows(AGraphics, ar, alvRight);
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.DrawLines(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  fLeft, fTop, tRight, tBottom: Single;
  b, df: Boolean;
begin
  AGraphics.Stroke.Assign(LineAppearance.Stroke);

  fLeft := ARectF.Left + 1;
  tRight := ARectF.Right - 1;

  if FHeader.Visible then
    fTop := FHeader.Height + FDayNameAppearance.Height - 1
  else
    fTop := FDayNameAppearance.Height - 1;

  tBottom := fTop;

  b := True;
  df := True;
  DoBeforeDrawLines(AGraphics, b, df);
  if b then
  begin
    if df then
        AGraphics.DrawLine(PointF(fLeft, fTop), PointF(tRight, tBottom));

    DoAfterDrawLines(AGraphics);
  end;

  if WeekNumberAppearance.Visible then
  begin
    fLeft := ARectF.Left + FXOffset;
    tRight := fLeft;

    if FHeader.Visible then
      fTop := FHeader.Height - 1
    else
      fTop := 0;

    if FFooter.Visible then
      tBottom := ARectF.Bottom - FFooter.Height
    else
      tBottom := ARectF.Bottom;

    DoBeforeDrawLines(AGraphics, b, df);
    if b then
    begin
      if df then
        AGraphics.DrawLine(PointF(fLeft, fTop), PointF(tRight, tBottom));

      DoAfterDrawLines(AGraphics);
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.DrawWeekNumbers(AGraphics: TTMSFNCGraphics;
  ARectF: TRectF);
var
  wNum, I: Integer;
  wDay: TDate;
  CellRect: TRectF;
  b, df: Boolean;
begin
  wDay := EncodeDate(FYear, FMonth, 1);
  wNum := WeekOf(wDay);
  CellRect := RectF(ARectF.Left, ARectF.Top, ARectF.Left + FXOffset, ARectF.Top + FYOffset);
  ShiftCellH(CellRect, 1);

  if FHeader.Visible then
    ShiftCellV(CellRect, FHeader.Height + FDayNameAppearance.Height)
  else
    ShiftCellV(CellRect, FDayNameAppearance.Height);

  AGraphics.Fill.Assign(WeekNumberAppearance.Fill);
  AGraphics.Stroke.Assign(WeekNumberAppearance.Stroke);
  AGraphics.Font.AssignSource(WeekNumberAppearance.Font);

  for I := 0 to FNumOfWeeksDrawn - 1 do
  begin
    b := True;
    df := True;
    DoBeforeDrawWeekNumbers(AGraphics, CellRect, b, df);
    if b then
    begin
      if df then
        AGraphics.DrawRectangle(CellRect, gcrmNone);

      DoAfterDrawWeekNumbers(AGraphics, CellRect);
    end;

    b := True;
    df := True;
    DoBeforeDrawWeekNumbersText(AGraphics, CellRect, IntToStr(wNum), WeekNumberAppearance.HorizontalTextAlign, WeekNumberAppearance.VerticalTextAlign, b, df);
    if b then
    begin
      if df then
        AGraphics.DrawText(CellRect, IntToStr(wNum) , False, WeekNumberAppearance.HorizontalTextAlign, WeekNumberAppearance.VerticalTextAlign);

      DoAfterDrawWeekNumbersText(AGraphics, CellRect, IntToStr(wNum), WeekNumberAppearance.HorizontalTextAlign, WeekNumberAppearance.VerticalTextAlign);
    end;

    ShiftCellV(CellRect, FYOffset);
    wDay := IncDay(wDay, 7);
    wNum := WeekOf(wDay);
  end;
end;

procedure TTMSFNCCustomCalendar.EventsChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomCalendar.FooterChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCCustomCalendar.GetArrowRect(ALeft: Boolean): TRectF;
var
  rect: TRectF;
  hHeight: Single;
  s: Single;
begin
  hHeight := FHeader.Height;
  s := 0;

  if ALeft then
  begin
    case Header.Arrow.ArrowLeftType of
      atTriangle, atArrow, atCustom: s := Header.Arrow.Size;
      atBitmap: s := Header.Arrow.ArrowLeftBitmapSize;
    end;

    rect.Top := (hHeight / 2) - (s / 2);
    rect.Bottom := (hHeight / 2) + (s / 2);
    rect.Left := ARRPOS;
    rect.Right := ARRPOS + s;
  end
  else
  begin
    case Header.Arrow.ArrowRightType of
      atTriangle, atArrow, atCustom: s := Header.Arrow.Size;
      atBitmap: s := Header.Arrow.ArrowRightBitmapSize;
    end;

    rect.Top := (hHeight / 2) - (s / 2);
    rect.Bottom := (hHeight / 2) + (s / 2);
    rect.Left := Width - ARRPOS - s;
    rect.Right := Width - ARRPOS;
  end;

  Result := rect;
end;

function TTMSFNCCustomCalendar.GetBitmapContainer: TTMSFNCBitmapContainer;
begin
  Result := FBitmapContainer;
end;

function TTMSFNCCustomCalendar.GetCurrentDate: TDate;
begin
  Result := Now;
end;

function TTMSFNCCustomCalendar.GetDocURL: string;
begin
  Result := TTMSFNCBaseDocURL + 'tmsfncuipack/components/' + LowerCase(ClassName);
end;

function TTMSFNCCustomCalendar.GetFooterTextRect: TRectF;
var
  l, t, r, b, fHeight, fWidth: Single;
begin
  l := 0;
  t := 0;
  fHeight := FFooter.CaptionRect.Bottom;
  fWidth := FFooter.CaptionRect.Right;

  case FFooter.HorizontalTextAlign of
    gtaCenter: l := (Width / 2) - (fWidth / 2);
    gtaLeading: l := FFooter.CaptionRect.Left;
    gtaTrailing: l := Width - fWidth;
  end;

  case FFooter.VerticalTextAlign of
    gtaCenter: t := Height - (FFooter.Height / 2) - (fHeight / 2);
    gtaLeading: t := FFooter.CaptionRect.Top;
    gtaTrailing: t := Height - fHeight;
  end;

  b := t + fHeight;
  r := l + fWidth;

  Result := RectF(l, t, r, b);
end;

function TTMSFNCCustomCalendar.GetHintString: string;
var
  it: TTMSFNCCalendarEventItem;
begin
  Result := inherited GetHintString;
  it := FEvents.GetEventCollectionItemByDate(FHoverDate);

  if Assigned(it) then
    Result := it.Hint;
end;

function TTMSFNCCustomCalendar.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

function TTMSFNCCustomCalendar.CurrentDay: Integer;
begin
  Result := DayOf(Now);
end;

function TTMSFNCCustomCalendar.CurrentMonth: Integer;
begin
  Result := MonthOf(Now);
end;

function TTMSFNCCustomCalendar.CurrentYear: Integer;
begin
  Result := YearOf(Now);
end;

procedure TTMSFNCCustomCalendar.HandleKeyDown(var Key: Word;
  Shift: TShiftState);
var
  df, dt: TDate;
begin
  inherited;

  if Interaction.ReadOnlyMode or (not Interaction.KeyboardSupport) then
    Exit;

  if ([ssCtrl] = Shift) and Interaction.MultiSelect then
  begin
    case Key of
      Ord('A'), Ord('a'):
      begin
        if FSelectedAll then
        begin
          FSelectedDates.UnselectAll;
          DoBeforeSelectDate(FFocusedDate);
          SelectDate(FFocusedDate);
          DoSelectDate(FFocusedDate);
          FSelectedAll := False;
        end
        else
        begin
          df := EncodeDate(FYear, FMonth, 1);
          dt := EncodeDate(FYear, FMonth, DaysInAMonth(FYear, FMonth));

          if MinDate <> 0 then
          begin
            if (MinDate >= df) and (MinDate <= dt) then
              df := MinDate;
          end;

          if MaxDate <> 0 then
          begin
            if (MaxDate <= dt) and (MaxDate >= df) then
              dt := MaxDate;
          end;

          SelectMultiDates(df, dt);
          DoSelectMultiDate(df, dt);
          FSelectedAll := True;
        end;
      end;
    end;
  end
  else if ([ssShift] = Shift) and Interaction.MultiSelect then
  begin
    case Key of
      KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN:
      begin
        if not FShiftPressed then
        begin
          FClickedDate := FFocusedDate;
          DoBeforeSelectDate(FClickedDate);
          SelectDate(FClickedDate);
          DoSelectDate(FClickedDate);
          FShiftPressed := True;
        end;
        if Key = KEY_LEFT then
        begin
          SetDateBackward(FFocusedDate, -1);
          SelectMultiDates(FClickedDate, FFocusedDate);
          DoSelectMultiDate(FClickedDate, FFocusedDate);
        end
        else if Key = KEY_RIGHT then
        begin
          SetDateForward(FFocusedDate, 1);
          SelectMultiDates(FClickedDate, FFocusedDate);
          DoSelectMultiDate(FClickedDate, FFocusedDate);
        end
        else if Key = KEY_UP then
        begin
          SetDateBackward(FFocusedDate, -7);
          SelectMultiDates(FClickedDate, FFocusedDate);
          DoSelectMultiDate(FClickedDate, FFocusedDate);
        end
        else if KEY = KEY_DOWN then
        begin
          SetDateForward(FFocusedDate, 7);
          SelectMultiDates(FClickedDate, FFocusedDate);
          DoSelectMultiDate(FClickedDate, FFocusedDate);
        end;
      end;
    end;
    Exit;
  end;

  case Key of
    KEY_LEFT:
    begin
      SetDateBackward(FFocusedDate, -1);
    end;
    KEY_RIGHT:
    begin
      SetDateForward(FFocusedDate, 1);
    end;
    KEY_UP:
    begin
      SetDateBackward(FFocusedDate, -7);
    end;
    KEY_DOWN:
    begin
      SetDateForward(FFocusedDate, 7);
    end;
    KEY_HOME:
    begin
      FFocusedDate := EncodeDate(FYear, FMonth, 1);
    end;
    KEY_END:
    begin
      FFocusedDate := EncodeDate(FYear, FMonth, DaysInAMonth(Year, Month));
    end;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomCalendar.HandleKeyUp(var Key: Word; Shift: TShiftState);
begin
  inherited;

  if Interaction.ReadOnlyMode or (not Interaction.KeyboardSupport) then
    Exit;

  case Key of
    KEY_SHIFT: FShiftPressed := False;
    KEY_SPACE, KEY_RETURN:
    begin
      DoBeforeSelectDate(FFocusedDate);
      if not (Interaction.MultiSelect and (ssCtrl in Shift)) then
        SelectDateIfAvailable(FFocusedDate)
      else
        SelectDate(FFocusedDate);
      DoSelectDate(FFocusedDate);
    end;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomCalendar.HandleMouseDown(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
var
  dateAtXY, mind, maxd: TDate;
begin
  inherited;

  if Interaction.ReadOnlyMode then
    Exit;

  SetFocus;
  FMouseDown := True;
  CaptureEx;

  if FHeader.Visible then
  begin
    if Y <= FHeader.Height then
    begin
      if (alvLeft in FHeader.Arrow.Visibility) and PointInRect(GetArrowRect(True), PointF(X,Y)) then
        PreviousDate
      else if (alvRight in FHeader.Arrow.Visibility) and PointInRect(GetArrowRect(False), PointF(X,Y)) then
        NextDate;

      if Interaction.SwipeOnHeader then
      begin
        FTick := GetTickCountX;
        FMx := X;
        FMy := Y;
      end;

      Invalidate;
      Exit;
    end;
  end;

  if FFooter.Visible then
  begin
    if Y >= (Height - FFooter.Height) then
    begin
      if PointInRect(GetFooterTextRect, PointF(X,Y)) and (FFooter.DateCaption = fdToday) then
      begin
        if (MonthOf(Date) <> MonthOf(Now)) or (YearOf(Date) <> YearOf(Now)) then
        begin
          if Assigned(OnNavigateFooter) then
            OnNavigateFooter(Self);
        end;
        Date := Now;
      end;

      Invalidate;
      Exit;
    end;
  end;

  dateAtXY := XYToDate(X, Y);
  mind := MinDate;
  maxd := MaxDate;

  if MinDate = 0 then
    mind := dateAtXY;

  if MaxDate = 0 then
    maxd := dateAtXY;

  if (Int(dateAtXY) = CALMINDATE) or (Int(dateAtXY) < Int(mind)) or (Int(dateAtXY) > Int(maxd)) then
    Exit;

  FClickedDate := dateAtXY;
  FFocusedDate := FClickedDate;
  FMouseAtDate := dateAtXY;

  if Interaction.MultiSelect then
  begin
    if (ssCtrl in Shift) then
    begin
      DoBeforeSelectDate(dateAtXY);
      SelectDate(dateAtXY);
      DoSelectDate(dateAtXY);
    end
    else if (ssShift in Shift) then
    begin
      if FSelectedDates.Count > 0 then
      begin
        SelectMultiDates(FSelectedDates.Items[FSelectedDates.Count - 1].Date, dateAtXY);
        DoSelectMultiDate(FSelectedDates.Items[FSelectedDates.Count - 1].Date, dateAtXY);
      end;
    end
    else
    begin
      FSelectedDates.UnselectAll;

      if FSelectedDates.InCollection(dateAtXY) then
        FSelectedDates.UnselectDate(dateAtXY)
      else
      begin
        DoBeforeSelectDate(dateAtXY);
        SelectDate(dateAtXY);
        Date := dateAtXY;
        DoSelectDate(dateAtXY);
      end;
    end;
  end
  else
  begin
    FSelectedDates.UnselectAll;

    if FSelectedDates.InCollection(dateAtXY) then
      FSelectedDates.UnselectDate(dateAtXY)
    else
    begin
      DoBeforeSelectDate(dateAtXY);
      Date := dateAtXY;
      SelectDate(dateAtXY);
      DoSelectDate(dateAtXY);
      ReleaseCaptureEx;
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.HandleMouseMove(Shift: TShiftState; X, Y: Single);
var
  dt: TDate;
begin
  inherited;

  if (FYOffset = 0) or (FXOffset = 0) then
    Exit;

  dt := XYToDate(X, Y);

  if FHoverDate <> dt then
  begin
    FHoverDate := dt;
    CancelHint;
    Invalidate;
  end;

  if Interaction.ReadOnlyMode then
    Exit;

  if FHeader.Visible then
  begin
    if Y <= FHeader.Height then
    begin
      if ((alvLeft in FHeader.Arrow.Visibility) and (PointInRect(GetArrowRect(True), PointF(X,Y)))) or ((alvRight in FHeader.Arrow.Visibility) and (PointInRect(GetArrowRect(False), PointF(X,Y)))) then
        Cursor := crHandPoint
      else
        Cursor := crDefault;
      Exit;
    end
    else
      Cursor := crDefault;
  end;

  if FFooter.Visible then
  begin
    if Y > (Height - FFooter.Height) then
    begin
      if PointInRect(GetFooterTextRect, PointF(X,Y)) and (FFooter.DateCaption = fdToday) then
        Cursor := crHandPoint
      else
        Cursor := crDefault;
      Exit;
    end
    else
      Cursor := crDefault;
  end;

  if Interaction.MultiSelect and FMouseDown then
  begin
    if X > (Self.Width) then
      Exit;

    if dt = CALMINDATE then
    begin
      FMouseAtDate := dt;
      Exit;
    end;

    if FMouseAtDate = dt then
      Exit;

    if (dt <> CALMINDATE) then
    begin
      FMouseAtDate := dt;
      SelectMultiDates(FClickedDate, FMouseAtDate);
      DoSelectMultiDate(FClickedDate, FMouseAtDate);
      Invalidate;
    end;
  end;
end;

procedure TTMSFNCCustomCalendar.HandleMouseUp(Button: TTMSFNCMouseButton;
  Shift: TShiftState; X, Y: Single);
begin
  inherited;

  if Interaction.SwipeOnHeader then
  begin
    if (Abs(Y - FMy) < 10) and (Abs(X - FMx) > 20) and FMouseDown and ((GetTickCountX - FTick) < 1000) then
    begin
      if X < FMx then
        NextDate
      else
        PreviousDate;
    end;

    Invalidate;
  end;

  FMouseDown := False;
  ReleaseCaptureEx;
end;

function TTMSFNCCustomCalendar.HasHint: Boolean;
var
  it: TTMSFNCCalendarEventItem;
begin
  it := FEvents.GetEventCollectionItemByDate(FHoverDate);
  Result := Assigned(it) and (it.Hint <> '');
end;

procedure TTMSFNCCustomCalendar.HeaderChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomCalendar.IncrementCell(var ACellSide: Single;
  const AMoveBy: Single);
begin
  ACellSide := ACellSide + AMoveBy;
end;

procedure TTMSFNCCustomCalendar.IncrementWeekDay(var ADay: Integer);
begin
  ADay := (ADay + 1) mod 7;
  if ADay = 0 then
    ADay := 7;
end;

procedure TTMSFNCCustomCalendar.InitSample;
begin
  BeginUpdate;

  ResetToDefaultStyle;

  EndUpdate;
end;

function TTMSFNCCustomCalendar.IsAppearanceProperty(AObject: TObject;
  APropertyName: string; APropertyType: TTypeKind): Boolean;
begin
  Result := inherited IsAppearanceProperty(AObject, APropertyName, APropertyType);
  Result := Result or (APropertyName = 'Header') or (APropertyName = 'Footer');
end;

function TTMSFNCCustomCalendar.IsFocusedDateStored: Boolean;
begin
  Result := FocusedDate <> 0.0;
end;

procedure TTMSFNCCustomCalendar.NextDate;
var
  tMonth, tYear: Integer;
  d: TDate;
begin
  case FInteraction.NavigationMode of
    nmMonth:
    begin
      if (FMonth + 1) > 12 then
      begin
        Date := EncodeDate(NextYear, NextMonth, 1);
        DoChangeYear(FYear);
      end
      else
        Date := EncodeDate(FYear, NextMonth, 1);

      DoChangeMonth(FMonth);
    end;
    nmYear:
    begin
      Date := IncYear(FDate);
      DoChangeYear(FYear);
    end;
    nmFocusedDate:
    begin
      tMonth := FMonth;
      tYear := FYear;
      FocusedDate := IncDay(FFocusedDate);

      if tMonth <> MonthOf(FocusedDate) then
      begin
        Date := FocusedDate;
        DoChangeMonth(FMonth);

        if tYear <> YearOf(FocusedDate) then
          DoChangeYear(FYear);
      end;
    end;
    nmCustom:
    begin
      d := FDate;
      DoCustomNavigation(FDate, FFocusedDate, False);

      if MonthOf(d) <> MonthOf(FDate) then
        DoChangeMonth(FMonth);
      if YearOf(d) <> YearOf(FDate) then
        DoChangeYear(FYear);
    end;
  end;
  if Assigned(OnNavigateForward) then
    OnNavigateForward(Self, FMonth, FYear);
end;

function TTMSFNCCustomCalendar.NextMonth: Integer;
var
  nMonth: Integer;
begin
  nMonth := FMonth + 1;

  if nMonth = 13 then
    nMonth := 1;

  Result := nMonth;
end;

function TTMSFNCCustomCalendar.NextYear: Integer;
begin
  Result := FYear + 1;
end;

procedure TTMSFNCCustomCalendar.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;

  if (Operation = opRemove) and (AComponent = FBitmapContainer) then
    FBitmapContainer := nil;
end;

function TTMSFNCCustomCalendar.PointInRect(ARect: TRectF;
  APoint: TPointF): Boolean;
begin
  Result := (ARect.Left <= APoint.X) and (ARect.Right >= APoint.X) and (ARect.Top <= APoint.Y) and (ARect.Bottom >= APoint.Y);
end;

procedure TTMSFNCCustomCalendar.PreviousDate;
var
  tMonth, tYear: Integer;
  d: TDate;
begin
  case FInteraction.NavigationMode of
    nmMonth:
    begin
      if (FMonth - 1) <= 0 then
      begin
        Date := EncodeDate(PreviousYear, PreviousMonth, 1);
        DoChangeYear(FYear);
      end
      else
        Date := EncodeDate(FYear, PreviousMonth, 1);

      DoChangeMonth(FMonth);
    end;
    nmYear:
    begin
      Date := IncYear(FDate, -1);
      DoChangeYear(FYear);
    end;
    nmFocusedDate:
    begin
      tMonth := FMonth;
      tYear := FYear;
      FocusedDate := IncDay(FFocusedDate, -1);

      if tMonth <> MonthOf(FocusedDate) then
      begin
        Date := FocusedDate;
        DoChangeMonth(FMonth);

        if tYear <> MonthOf(FocusedDate) then
          DoChangeYear(FYear);
      end;
    end;
    nmCustom:
    begin
      d := FDate;
      DoCustomNavigation(FDate, FFocusedDate, True);

      if MonthOf(d) <> MonthOf(FDate) then
        DoChangeMonth(FMonth);
      if YearOf(d) <> YearOf(FDate) then
        DoChangeYear(FYear);
    end;
  end;
  if Assigned(OnNavigateBack) then
    OnNavigateBack(Self, FMonth, FYear);
end;

function TTMSFNCCustomCalendar.PreviousMonth: Integer;
var
  pMonth: Integer;
begin
  pMonth := FMonth - 1;

  if pMonth = 0 then
    pMonth := 12;

  Result := pMonth;
end;

function TTMSFNCCustomCalendar.PreviousYear: Integer;
begin
  Result := FYear - 1;
end;

function TTMSFNCCustomCalendar.XYToDate(AX, AY: Single): TDate;
var
  colFrom, rowFrom, colTo, rowTo, cDay, dim: Integer;
  calcMonth: TDate;
begin
  rowTo := RowAtY(AY);
  colTo := ColAtX(AX);

  if (rowTo = -1) or (colTo = -1) then
  begin
    Result := CALMINDATE;
    Exit;
  end;

  calcMonth := (EncodeDate(FYear, FMonth, 1));
  rowFrom := 1;
  colFrom := DayOfWeek(calcMonth) - FirstDay;

  if (colFrom < 0) then
    colFrom := colFrom + 7;

  Inc(colFrom);
  cDay := colTo - colFrom + 1;

  if (rowFrom = rowTo) and (not FDateAppearance.ShowDaysBefore) then
  begin
    if (cDay < 1) then
    begin
      Result := CALMINDATE;
      Exit;
    end;
    Result := EncodeDate(FYear, FMonth, cDay);
    Exit;
  end
  else if (rowFrom = rowTo) and FDateAppearance.ShowDaysBefore then
  begin
    if (cDay < 1) then
    begin
      Result := IncDay(calcMonth, colTo - colFrom);
      Exit;
    end;
  end;

  cDay := (rowTo - rowFrom - 1) * 7 + colTo + (7 - colFrom + 1);
  dim := DaysInAMonth(FYear, FMonth);
  calcMonth := EncodeDate(FYear, FMonth, dim);

  if (cDay > dim) and (not FDateAppearance.ShowDaysAfter) then
    Result := CALMINDATE
  else if (cDay > dim) and FDateAppearance.ShowDaysAfter then
    Result := IncDay(calcMonth, cDay - dim)
  else
    Result := EncodeDate(FYear, FMonth, cDay);
end;

procedure TTMSFNCCustomCalendar.ApplyStyle;
var
  c: TTMSFNCGraphicsColor;
begin
  inherited;
  c := gcNull;
  if TTMSFNCStyles.GetStyleBackgroundFillColor(c) then
  begin
    Fill.Color := c;
    DateAppearance.Fill.Color := c;
    DateAppearance.Stroke.Kind := gskNone;
    DateAppearance.DateAfterFill.Color := c;
    DateAppearance.DateAfterStroke.Kind := gskNone;
    DateAppearance.DateBeforeFill.Color := c;
    DateAppearance.DateBeforeStroke.Kind := gskNone;
    DateAppearance.DisabledFill.Color := c;
    DateAppearance.DisabledStroke.Kind := gskNone;
    DateAppearance.TodayFill.Color := c;
    DateAppearance.TodayStroke.Kind := gskNone;
    WeekNumberAppearance.Fill.Color := c;
    WeekNumberAppearance.Stroke.Kind := gskNone;
    DayNameAppearance.Stroke.Kind := gskNone;
    DayNameAppearance.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColor(c) then
  begin
    DateAppearance.SelectedFill.Color := c;
    DateAppearance.SelectedStroke.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleLineFillColor(c) then
  begin
    Stroke.Color := c;
    Header.Stroke.Color := c;
    Footer.Stroke.Color := c;
    LineAppearance.Stroke.Color := c;
    Header.Arrow.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColor(c) then
  begin
    Header.Fill.Color := c;
    Footer.Fill.Color := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleHeaderFillColorTo(c) then
  begin
    Header.Fill.Kind := gfkGradient;
    Header.Fill.ColorTo := c;
    Footer.Fill.Kind := gfkGradient;
    Footer.Fill.ColorTo := c;
  end;

  c := gcNull;
  if TTMSFNCStyles.GetStyleSelectionFillColorTo(c) then
    DateAppearance.TodayFont.Color := c;

  c := gcNull;
  if TTMSFNCStyles.GetStyleTextFontColor(c) then
  begin
    if c = gcBlack then
    begin
      DateAppearance.DateAFterFont.Color := Lighter(c, 50);
      DateAppearance.DateBeforeFont.Color := Lighter(c, 50);
      DateAppearance.DisabledFont.Color := Lighter(c, 75);
    end
    else
    begin
      DateAppearance.DateAFterFont.Color := Blend(c, Fill.Color, 50);
      DateAppearance.DateBeforeFont.Color := Blend(c, Fill.Color, 50);
      DateAppearance.DisabledFont.Color := Blend(c, Fill.Color, 25);
    end;

    DateAppearance.SelectedFont.Color := c;
    DateAppearance.Font.Color := c;
    Header.Font.Color := c;
    Footer.Font.Color := c;
    DayNameAppearance.Font.Color := c;
    WeekNumberAppearance.Font.Color := c;
  end;
end;

procedure TTMSFNCCustomCalendar.ArrowsChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomCalendar.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCustomCalendar) then
  begin
    FDate := (Source as TTMSFNCCustomCalendar).Date;
    FYear := (Source as TTMSFNCCustomCalendar).Year;
    FMonth := (Source as TTMSFNCCustomCalendar).Month;
    FDay := (Source as TTMSFNCCustomCalendar).Day;
    FMinDate := (Source as TTMSFNCCustomCalendar).MinDate;
    FMaxDate := (Source as TTMSFNCCustomCalendar).MaxDate;
    Fill.Assign((Source as TTMSFNCCustomCalendar).Fill);
    Stroke.Assign((Source as TTMSFNCCustomCalendar).Stroke);
    FSelectedAll := (Source as TTMSFNCCustomCalendar).FSelectedAll;
    FFirstDay := (Source as TTMSFNCCustomCalendar).FirstDay;
    FBadgeSize := (Source as TTMSFNCCustomCalendar).BadgeSize;
    FFocusedDate := (Source as TTMSFNCCustomCalendar).FocusedDate;
    FXOffset := (Source as TTMSFNCCustomCalendar).FXOffset;
    FYOffset := (Source as TTMSFNCCustomCalendar).FYOffset;
    FHeader.Assign((Source as TTMSFNCCustomCalendar).Header);
    FFooter.Assign((Source as TTMSFNCCustomCalendar).Footer);
    FDayNameAppearance.Assign((Source as TTMSFNCCustomCalendar).DayNameAppearance);
    FDateAppearance.Assign((Source as TTMSFNCCustomCalendar).DateAppearance);
    FLineAppearance.Assign((Source as TTMSFNCCustomCalendar).LineAppearance);
    FWeekNumberAppearance.Assign((Source as TTMSFNCCustomCalendar).WeekNumberAppearance);
    FInteraction.Assign((Source as TTMSFNCCustomCalendar).Interaction);
    FSelectedDates.Assign((Source as TTMSFNCCustomCalendar).SelectedDates);
    FEvents.Assign((Source as TTMSFNCCustomCalendar).Events);
  end
  else
  inherited;
end;

function TTMSFNCCustomCalendar.CalcDateAreaHeight(const AHeight: Single;
  const AHeaderVisible, AFooterVisible: Boolean): Single;
var
  h: Single;
begin
  h := AHeight;

  if AHeaderVisible then
    h := h - FHeader.Height;
  if AFooterVisible then
    h := h - FFooter.Height;

  h := h - FDayNameAppearance.Height;
  Result := h;
end;

function TTMSFNCCustomCalendar.CalcRowCount(const ACol,
  ADaysInAMonth: Integer): Integer;
begin
  Result := Ceil((ADaysInAMonth + ACol) / 7);
end;

procedure TTMSFNCCustomCalendar.CalculateXOffset;
begin
  FXOffset := (Width - 2) / ColCount(WeekNumberAppearance.Visible);
end;

procedure TTMSFNCCustomCalendar.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);

  Invalidate;
end;

procedure TTMSFNCCustomCalendar.ChangeDPIScale(M, D: Integer);
begin
  inherited;

  BeginUpdate;

  Header.Font.Height := TTMSFNCUtils.MulDivInt(Header.Font.Height, M, D);
  Footer.Font.Height := TTMSFNCUtils.MulDivInt(Footer.Font.Height, M, D);
  DayNameAppearance.Font.Height := TTMSFNCUtils.MulDivInt(DayNameAppearance.Font.Height, M, D);
  DateAppearance.Font.Height := TTMSFNCUtils.MulDivInt(DateAppearance.Font.Height, M, D);
  DateAppearance.SelectedFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.SelectedFont.Height, M, D);
  DateAppearance.DisabledFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.DisabledFont.Height, M, D);
  DateAppearance.BadgeFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.BadgeFont.Height, M, D);
  DateAppearance.DateBeforeFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.DateBeforeFont.Height, M, D);
  DateAppearance.DateAfterFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.DateAfterFont.Height, M, D);
  DateAppearance.TodayFont.Height := TTMSFNCUtils.MulDivInt(DateAppearance.TodayFont.Height, M, D);
  WeekNumberAppearance.Font.Height := TTMSFNCUtils.MulDivInt(WeekNumberAppearance.Font.Height, M, D);
  DayNameAppearance.Height := TTMSFNCUtils.MulDivInt(DayNameAppearance.Height, M, D);
  Header.Height := TTMSFNCUtils.MulDivInt(Header.Height, M, D);
  Footer.Height := TTMSFNCUtils.MulDivInt(Footer.Height, M, D);
  Header.Arrow.Size := TTMSFNCUtils.MulDivInt(Header.Arrow.Size, M, D);
  Header.Arrow.ArrowLeftBitmapSize := TTMSFNCUtils.MulDivSingle(Header.Arrow.ArrowLeftBitmapSize, M, D);
  Header.Arrow.ArrowRightBitmapSize := TTMSFNCUtils.MulDivSingle(Header.Arrow.ArrowRightBitmapSize, M, D);
  BadgeSize := TTMSFNCUtils.MulDivInt(BadgeSize, M, D);

  EndUpdate;
end;

function TTMSFNCCustomCalendar.ColAtX(AX: Single): Integer;
begin
  if WeekNumberAppearance.Visible then
  begin
    if (AX > FXOffset) and (AX < Self.Width - 2) and (FXOffset <> 0) then
      Result := Trunc(AX / FXOffset)
    else
      Result := -1;
  end
  else
  begin
    if (AX > 0) and (AX < Self.Width - 2) and (FXOffset <> 0) then
      Result := Trunc(AX / FXOffset) + 1
    else
      Result := -1;
  end;
end;

procedure TTMSFNCCustomCalendar.ResetToDefaultStyle;
begin
  inherited;

  Fill.Kind := gfkSolid;
  Stroke.Kind := gskSolid;
  Fill.Color := gcWhite;
  Stroke.Color := gcSilver;

  Header.Fill.Kind := gfkGradient;
  {$IFDEF FMXLIB}
  Header.Fill.Color := $FFF6F8FC;
  Header.Fill.ColorTo := $FFEEF2F9;
  Header.Font.Color := $FF454545;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  Header.Fill.Color := $FCF8F6;
  Header.Fill.ColorTo := $F9F2EE;
  Header.Font.Color := $454545;
  {$ENDIF}
  Header.Stroke.Kind := gskSolid;
  Header.Stroke.Color := gcSilver;
  Header.Arrow.Color := gcDimgray;
  TTMSFNCUtils.SetFontSize(Header.Font, 14);

  Footer.Fill.Assign(Header.Fill);
  Footer.Stroke.Assign(Header.Stroke);
  Footer.Font.Assign(Header.Font);
  Footer.Font.Style := [];

  LineAppearance.Stroke.Kind := gskSolid;
  LineAppearance.Stroke.Color := gcDarkgray;

  DayNameAppearance.Fill.Kind := gfkSolid;
  DayNameAppearance.Stroke.Kind := gskSolid;
  DayNameAppearance.Fill.Color := gcNull;
  DayNameAppearance.Stroke.Color := gcNull;
  {$IFDEF FMXLIB}
  DayNameAppearance.Font.Color := $FF7A7A7A;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  DayNameAppearance.Font.Color := $7A7A7A;
  {$ENDIF}

  WeekNumberAppearance.Fill.Kind := gfkSolid;
  WeekNumberAppearance.Stroke.Kind := gskSolid;
  WeekNumberAppearance.Fill.Color := gcNull;
  WeekNumberAppearance.Stroke.Color := gcNull;
  WeekNumberAppearance.Font.Color := DayNameAppearance.Font.Color;

  DateAppearance.DateAfterFill.Kind := gfkSolid;
  DateAppearance.DateAfterStroke.Kind := gskSolid;
  DateAppearance.DateBeforeFill.Kind := gfkSolid;
  DateAppearance.DateBeforeStroke.Kind := gskSolid;
  DateAppearance.DisabledFill.Kind := gfkSolid;
  DateAppearance.DisabledStroke.Kind := gskSolid;
  DateAppearance.Fill.Kind := gfkSolid;
  DateAppearance.Stroke.Kind := gskSolid;
  DateAppearance.SelectedFill.Kind := gfkSolid;
  DateAppearance.SelectedStroke.Kind := gskSolid;
  DateAppearance.TodayFill.Kind := gfkSolid;
  DateAppearance.TodayStroke.Kind := gskSolid;

  {$IFDEF FMXLIB}
  DateAppearance.Font.Color := $FF7A7A7A;
  DateAppearance.TodayFont.Color := $FF2D9BEF;
  DateAppearance.DateBeforeFont.Color := $FFBBBBBB;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  DateAppearance.Font.Color := $7A7A7A;
  DateAppearance.TodayFont.Color := $EF9B2D;
  DateAppearance.DateBeforeFont.Color := $BBBBBB;
  {$ENDIF}

  DateAppearance.DateAfterFill.Color := gcNull;
  DateAppearance.DateAfterStroke.Color := gcNull;
  DateAppearance.DateAfterFont.Color := DateAppearance.DateBeforeFont.Color;
  DateAppearance.DateBeforeFill.Color := gcNull;
  DateAppearance.DateBeforeStroke.Color := gcNull;
  DateAppearance.DisabledFill.Color := gcNull;
  DateAppearance.DisabledStroke.Color := gcNull;
  DateAppearance.DisabledFont.Color := gcLightgrey;
  DateAppearance.Fill.Color := gcNull;
  DateAppearance.Stroke.Color := gcNull;
  DateAppearance.SelectedFill.Color := Header.Fill.ColorTo;
  DateAppearance.SelectedStroke.Color :=  DateAppearance.TodayFont.Color;
  DateAppearance.TodayFill.Color := gcNull;
  DateAppearance.TodayStroke.Color := gcNull;
  DateAppearance.TodayFont.Style := [TFontStyle.fsBold];
  DateAppearance.ShowFocus := False;

  Header.Font.Style := [TFontStyle.fsBold];
  DateAppearance.SelectedFont.Assign(Header.Font);
end;

function TTMSFNCCustomCalendar.RowAtY(AY: Single): Integer;
var
  t, b: Single;
begin
  if FHeader.Visible then
    t := FHeader.Height + FDayNameAppearance.Height
  else
    t := FDayNameAppearance.Height;

  if FFooter.Visible then
    b := Self.Height - FFooter.Height
  else
    b := Self.Height;

  if (AY > t) and (AY < b) and (FYOffset <> 0) then
  begin
    if FHeader.Visible then
      Result := Trunc((AY - FHeader.Height - FDayNameAppearance.Height) / FYOffset) + 1
    else
      Result := Trunc((AY - FDayNameAppearance.Height) / FYOffset) + 1;
  end
  else
    Result := -1;
end;

procedure TTMSFNCCustomCalendar.SelectADate(ADate: TDate);
begin
  FSelectedDates.UnselectAll;
  FSelectedDates.SelectDate(ADate);
  Date := ADate;
  FFocusedDate := ADate;
  Invalidate;
end;

procedure TTMSFNCCustomCalendar.SelectDate(ADate: TDate);
var
  mind, maxd: TDate;
begin
  mind := MinDate;
  maxd := MaxDate;

  if MinDate = 0 then
    mind := ADate;
  if MaxDate = 0 then
    maxd := ADate;

  if (Int(ADate) >= Int(mind)) and (Int(ADate) <= Int(maxd)) then
  begin
    FSelectedDates.SelectDate(ADate);
    FClickedDate := ADate;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomCalendar.SelectDateIfAvailable(ADate: TDate);
var
  mind, maxd: TDate;
begin
  mind := MinDate;
  maxd := MaxDate;

  if MinDate = 0 then
    mind := ADate;
  if MaxDate = 0 then
    maxd := ADate;

  if (Int(ADate) >= Int(mind)) and (Int(ADate) <= Int(maxd)) then
  begin
    FSelectedDates.UnselectAll;
    FSelectedDates.SelectDate(ADate);
    Date := ADate;
    FFocusedDate := ADate;
    FClickedDate := ADate;
  end;

  Invalidate;
end;

function TTMSFNCCustomCalendar.SelectedDate: TDate;
begin
  if FSelectedDates.Count > 0 then
    Result := FSelectedDates.Items[FSelectedDates.Count - 1].Date
  else
    Result := CALMINDATE;
end;

procedure TTMSFNCCustomCalendar.SelectEvent(ADate: TDate);
begin
  FEvents.SelectDate(ADate);
end;

procedure TTMSFNCCustomCalendar.SelectMultiDates(AStartDate,
  AEndDate: TDate);
var
  mind, maxd: TDate;
begin
  mind := MinDate;
  maxd := MaxDate;

  if CompareDate(AStartDate, AEndDate) = -1 then
  begin
    if MinDate = 0 then
      mind := AStartDate;

    if MaxDate = 0 then
      maxd := AEndDate;

    if (Int(AStartDate) >= Int(mind)) and (Int(AEndDate) <= Int(maxd)) then
    begin
      FSelectedDates.UnselectAll;
      FSelectedDates.SelectDateInterval(AStartDate, AEndDate);
    end;
  end
  else
  begin
    if MinDate = 0 then
      mind := AEndDate;

    if MaxDate = 0 then
      maxd := AStartDate;

    if (Int(AEndDate) >= Int(mind)) and (Int(AStartDate) <= Int(maxd)) then
    begin
      FSelectedDates.UnselectAll;
      FSelectedDates.SelectDateInterval(AStartDate, AEndDate);
    end;
  end;

  Invalidate;
end;

procedure TTMSFNCCustomCalendar.SetArrowLeftRect(AHeaderRect: TRectF; var AArrowRect: TRectF;
  ACentre: Single);
begin
  case Header.Arrow.ArrowLeftType of
    atTriangle, atArrow, atCustom:
    begin
      AArrowRect.Top := ACentre - Header.Arrow.Size / 2;
      AArrowRect.Bottom := ACentre + Header.Arrow.Size / 2;
      AArrowRect.Right := AHeaderRect.Left + Header.Arrow.Size + ARRPOS;
    end;
    atBitmap:
    begin
      AArrowRect.Top := ACentre - Header.Arrow.ArrowLeftBitmapSize / 2;
      AArrowRect.Bottom := ACentre + Header.Arrow.ArrowLeftBitmapSize / 2;
      AArrowRect.Right := AHeaderRect.Left + Header.Arrow.ArrowLeftBitmapSize + ARRPOS;
    end;
  end;

  AArrowRect.Left := AHeaderRect.Left + ARRPOS;
end;

procedure TTMSFNCCustomCalendar.SetArrowRightRect(AHeaderRect: TREctF;
  var AArrowRect: TRectF; ACentre: Single);
begin
  case Header.Arrow.ArrowRightType of
    atTriangle, atArrow, atCustom:
    begin
      AArrowRect.Top := ACentre - Header.Arrow.Size / 2;
      AArrowRect.Bottom := ACentre + Header.Arrow.Size / 2;
      AArrowRect.Left := AHeaderRect.Right - ARRPOS - Header.Arrow.Size;
    end;
    atBitmap:
    begin
      AArrowRect.Top := ACentre - Header.Arrow.ArrowRightBitmapSize / 2;
      AArrowRect.Bottom := ACentre + Header.Arrow.ArrowRightBitmapSize / 2;
      AArrowRect.Left := AHeaderRect.Right - ARRPOS - Header.Arrow.ArrowRightBitmapSize;
    end;
  end;

  AArrowRect.Right := AHeaderRect.Right - ARRPOS;
end;

procedure TTMSFNCCustomCalendar.SetBadgeSize(const Value: Integer);
begin
  if FBadgeSize <> Value then
    FBadgeSize := Value;
end;

procedure TTMSFNCCustomCalendar.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
begin
  FBitmapContainer := Value;
  Invalidate;
end;

procedure TTMSFNCCustomCalendar.SetDate(const Value: TDate);
begin
  if FDate <> Value then
  begin
    FDate := Value;
    FDay := DayOf(FDate);
    FMonth := MonthOf(FDate);
    FYear := YearOf(FDate);
  end;
end;

procedure TTMSFNCCustomCalendar.SetDateAppearance(
  const Value: TTMSFNCCalendarDateAppearance);
begin
  FDateAppearance.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetDay(const Value: Integer);
begin
  if FDay <> Value then
  begin
    FDay := Value;
    FDate := EncodeDate(YearOf(FDate), MonthOf(FDate), FDay);
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCalendar.SetDayNameAppearance(
  const Value: TTMSFNCCalendarDayNameAppearance);
begin
  FDayNameAppearance.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetEvents(
  const Value: TTMSFNCCalendarEvents);
begin
  FEvents.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetFirstDay(const Value: Integer);
begin
  if FFirstDay <> Value then
  begin
    FFirstDay := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCalendar.SetFocusedDate(const Value: TDate);
begin
  if FFocusedDate <> Value then
    FFocusedDate := Value;
end;

procedure TTMSFNCCustomCalendar.SetFonts(ASetType: TTMSFNCAppearanceGlobalFontType);
begin
  BeginUpdate;

  GlobalFont.ApplyChange(DayNameAppearance.Font, ASetType);

  GlobalFont.ApplyChange(WeekNumberAppearance.Font, ASetType);

  GlobalFont.ApplyChange(DateAppearance.Font, ASetType);
  GlobalFont.ApplyChange(DateAppearance.SelectedFont, ASetType);

  if not (ASetType = aftColor) then
    GlobalFont.ApplyChange(DateAppearance.DisabledFont, ASetType);

  GlobalFont.ApplyChange(DateAppearance.DateBeforeFont, ASetType);
  GlobalFont.ApplyChange(DateAppearance.DateAfterFont, ASetType);
  GlobalFont.ApplyChange(DateAppearance.BadgeFont, ASetType);
  GlobalFont.ApplyChange(DateAppearance.TodayFont, ASetType);

  GlobalFont.ApplyChange(FFooter.Font, ASetType);

  GlobalFont.ApplyChange(FHeader.Font, ASetType);

  EndUpdate;
end;

procedure TTMSFNCCustomCalendar.SetFooter(const Value: TTMSFNCCalendarFooter);
begin
  FFooter.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetGlobalFont(const Value: TTMSFNCAppearanceGlobalFont);
begin
  FGlobalFont.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetDateBackward(var ADate: TDate;
  ASetBy: Integer);
begin
  ADate := IncDay(ADate, ASetBy);

  if YearOf(ADate) < FYear then
  begin
    DoChangeYear(FYear);
    DoChangeMonth(FMonth);
  end
  else if MonthOf(ADate) < FMonth then
  begin
    DoChangeMonth(FMonth);
  end;

  Date := ADate;
end;

procedure TTMSFNCCustomCalendar.SetDateForward(var ADate: TDate;
  ASetBy: Integer);
begin
  ADate := IncDay(ADate, ASetBy);

  if YearOf(ADate) > FYear then
  begin
    DoChangeYear(FYear);
    DoChangeMonth(FMonth);
  end
  else if MonthOf(ADate) > FMonth then
  begin
    DoChangeMonth(FMonth);
  end;

  Date := ADate;
end;

procedure TTMSFNCCustomCalendar.SetHeader(const Value: TTMSFNCCalendarHeader);
begin
  FHeader.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetInteraction(
  const Value: TTMSFNCCalendarInteraction);
begin
  FInteraction.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetLineAppearance(
  const Value: TTMSFNCCalendarLineAppearance);
begin
  FLineAppearance.Assign(Value);
end;

procedure TTMSFNCCustomCalendar.SetMaxDate(const Value: TDate);
begin
  if FMaxDate <> Value then
  begin
    FMaxDate := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomCalendar.SetMinDate(const Value: TDate);
begin
  if FMinDate <> Value then
  begin
    FMinDate := Value;
    Changed;
  end;
end;

procedure TTMSFNCCustomCalendar.SetMonth(const Value: Integer);
begin
  if FMonth <> Value then
  begin
    FMonth := Value;
    FDate := EncodeDate(YearOf(FDate), FMonth, DayOf(FDate));
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCalendar.SetWeekNumberAppearance(
  const Value: TTMSFNCCalendarWeekNumberAppearance);
begin
  FWeekNumberAppearance.Assign(Value);
  Invalidate;
end;

procedure TTMSFNCCustomCalendar.SetYear(const Value: Integer);
begin
  if FYear <> Value then
  begin
    FYear := Value;
    FDate := EncodeDate(FYear, MonthOf(FDate), DayOf(FDate));
    Invalidate;
  end;
end;

procedure TTMSFNCCustomCalendar.ShiftCellH(var ACell: TRectF;
  const AMoveBy: Single);
begin
  IncrementCell(ACell.Left, AMoveBy);
  IncrementCell(ACell.Right, AMoveBy);
end;

procedure TTMSFNCCustomCalendar.ShiftCellV(var ACell: TRectF;
  const AMoveBy: Single);
begin
  IncrementCell(ACell.Top, AMoveBy);
  IncrementCell(ACell.Bottom, AMoveBy);
end;

procedure TTMSFNCCustomCalendar.ShowBeforeAndAfterChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCustomCalendar.UpdateControlAfterResize;
begin
  inherited;
  if Assigned(WeekNumberAppearance) then
    CalculateXOffset;
end;

procedure TTMSFNCCustomCalendar.WeekNumbersChanged(Sender: TObject);
begin
  CalculateXOffset;
  Changed;
end;

{ TTMSFNCCalendarHeader }

procedure TTMSFNCCalendarHeader.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCalendarHeader) then
  begin
    FFont.AssignSource((Source as TTMSFNCCalendarHeader).Font);
    FHorizontalTextAlign := (Source as TTMSFNCCalendarHeader).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCCalendarHeader).VerticalTextAlign;
    FStroke.Assign((Source as TTMSFNCCalendarHeader).Stroke);
    FFill.Assign((Source as TTMSFNCCalendarHeader).Fill);
    FArrow.Assign((Source as TTMSFNCCalendarHeader).Arrow);
    FVisible := (Source as TTMSFNCCalendarHeader).Visible;
    FHeight := (Source as TTMSFNCCalendarHeader).Height;
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarHeader.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarHeader.Create(AOwner: TComponent);
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcSilver);
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(228, 228, 228));
  FArrow := TTMSFNCCalendarArrow.Create(AOwner);
  FVisible := True;
  FHeight := 25;

  FFill.OnChanged := @FillChanged;
  FFont.OnChanged := @FontChanged;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCCalendarHeader.Destroy;
begin
  FFont.Free;
  FFill.Free;
  FStroke.Free;
  FArrow.Free;
  inherited;
end;

procedure TTMSFNCCalendarHeader.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarHeader.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarHeader.SetArrow(const Value: TTMSFNCCalendarArrow);
begin
  if FArrow <> Value then
    FArrow.Assign(Value);
end;

procedure TTMSFNCCalendarHeader.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCCalendarHeader.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarHeader.SetHeight(const Value: Integer);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarHeader.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarHeader.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarHeader.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarHeader.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarHeader.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarDayNameAppearance }

procedure TTMSFNCCalendarDayNameAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarDayNameAppearance then
  begin
    FHeight := (Source as TTMSFNCCalendarDayNameAppearance).Height;
    FFill.Assign((Source as TTMSFNCCalendarDayNameAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCCalendarDayNameAppearance).Stroke);
    FFont.AssignSource((Source as TTMSFNCCalendarDayNameAppearance).Font);
    FVerticalTextAlign := ((Source as TTMSFNCCalendarDayNameAppearance).VerticalTextAlign);
    FHorizontalTextAlign := ((Source as TTMSFNCCalendarDayNameAppearance).HorizontalTextAlign);
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarDayNameAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarDayNameAppearance.Create;
begin
  FHeight := 15;
  FFont := TTMSFNCGraphicsFont.Create;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
end;

destructor TTMSFNCCalendarDayNameAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  FFont.Free;
  inherited;
end;

procedure TTMSFNCCalendarDayNameAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarDayNameAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarDayNameAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCCalendarDayNameAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDayNameAppearance.SetHeight(const Value: Integer);
begin
  if FHeight <> Value then
    FHeight := Value;
end;

procedure TTMSFNCCalendarDayNameAppearance.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
    FHorizontalTextAlign := Value;
end;

procedure TTMSFNCCalendarDayNameAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDayNameAppearance.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
    FVerticalTextAlign := Value;
end;

procedure TTMSFNCCalendarDayNameAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarDateAppearance }

procedure TTMSFNCCalendarDateAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarDateAppearance then
  begin
    FFill.Assign((Source as TTMSFNCCalendarDateAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCCalendarDateAppearance).Stroke);
    FFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).Font);
    FVerticalTextAlign := (Source as TTMSFNCCalendarDateAppearance).VerticalTextAlign;
    FHorizontalTextAlign := (Source as TTMSFNCCalendarDateAppearance).HorizontalTextAlign;
    FSelectedFill.Assign((Source as TTMSFNCCalendarDateAppearance).SelectedFill);
    FSelectedStroke.Assign((Source as TTMSFNCCalendarDateAppearance).SelectedStroke);
    FSelectedFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).SelectedFont);
    FSelectedVerticalTextAlign := (Source as TTMSFNCCalendarDateAppearance).SelectedVerticalTextAlign;
    FSelectedHorizontalTextAlign := (Source as TTMSFNCCalendarDateAppearance).SelectedHorizontalTextAlign;
    FDisabledFill.Assign((Source as TTMSFNCCalendarDateAppearance).DisabledFill);
    FDisabledStroke.Assign((Source as TTMSFNCCalendarDateAppearance).DisabledStroke);
    FDisabledFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).DisabledFont);
    FDateBeforeFill.Assign((Source as TTMSFNCCalendarDateAppearance).DateBeforeFill);
    FDateBeforeFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).DateBeforeFont);
    FDateBeforeStroke.Assign((Source as TTMSFNCCalendarDateAppearance).DateBeforeStroke);
    FDateAfterFill.Assign((Source as TTMSFNCCalendarDateAppearance).DateAfterFill);
    FDateAfterFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).DateAfterFont);
    FDateAfterStroke.Assign((Source as TTMSFNCCalendarDateAppearance).DateAfterStroke);
    FTodayFill.Assign((Source as TTMSFNCCalendarDateAppearance).TodayFill);
    FTodayFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).TodayFont);
    FTodayStroke.Assign((Source as TTMSFNCCalendarDateAppearance).TodayStroke);
    FBadgeFill.Assign((Source as TTMSFNCCalendarDateAppearance).BadgeFill);
    FBadgeFont.AssignSource((Source as TTMSFNCCalendarDateAppearance).BadgeFont);
    FBadgeStroke.Assign((Source as TTMSFNCCalendarDateAppearance).BadgeStroke);
    FShowDaysBefore := (Source as TTMSFNCCalendarDateAppearance).ShowDaysBefore;
    FShowDaysAfter := (Source as TTMSFNCCalendarDateAppearance).ShowDaysAfter;
    FShowFocus := (Source as TTMSFNCCalendarDateAppearance).ShowFocus;
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarDateAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarDateAppearance.Create;
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
  FSelectedFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcDodgerblue);
  FSelectedStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcDodgerblue);
  FSelectedFont := TTMSFNCGraphicsFont.Create;
  FSelectedFont.Color := gcWhite;
  FSelectedHorizontalTextAlign := gtaCenter;
  FSelectedVerticalTextAlign := gtaCenter;
  FDisabledFont := TTMSFNCGraphicsFont.Create;
  FDisabledFont.Color := gcLightgrey;
  FDisabledFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FDisabledStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FDateBeforeFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FDateBeforeFont := TTMSFNCGraphicsFont.Create;
  FDateBeforeStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FDateBeforeFont.Color := gcMedGray;
  FDateAfterFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FDateAfterFont := TTMSFNCGraphicsFont.Create;
  FDateAfterFont.Color := gcMedGray;
  FDateAfterStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FTodayFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FTodayFont := TTMSFNCGraphicsFont.Create;
  FTodayFont.Color := gcRed;
  FTodayStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FBadgeFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcRed);
  FBadgeFont := TTMSFNCGraphicsFont.Create;
  FBadgeFont.Color := gcWhite;
  FShowFocus := True;
  {$IFNDEF FMXLIB}
  FBadgeFont.Size := 7;
  {$ENDIF}
  {$IFDEF FMXLIB}
  FBadgeFont.Size := 10;
  {$ENDIF}
  FBadgeStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcRed);
  FShowDaysBefore := False;
  FShowDaysAfter := False;

  FFont.OnChanged := @FontChanged;
  FSelectedFont.OnChanged := @FontChanged;
  FDisabledFont.OnChanged := @FontChanged;
  FDateBeforeFont.OnChanged := @FontChanged;
  FDateAfterFont.OnChanged := @FontChanged;
  FTodayFont.OnChanged := @FontChanged;
  FBadgeFont.OnChanged := @FontChanged;
  FFill.OnChanged := @FillChanged;
  FSelectedFill.OnChanged := @FillChanged;
  FDisabledFill.OnChanged := @FillChanged;
  FDateBeforeFill.OnChanged := @FillChanged;
  FDateAfterFill.OnChanged := @FillChanged;
  FTodayFill.OnChanged := @FillChanged;
  FBadgeFill.OnChanged := @FillChanged;
  FStroke.OnChanged := @StrokeChanged;
  FSelectedStroke.OnChanged := @StrokeChanged;
  FDisabledStroke.OnChanged := @StrokeChanged;
  FDateBeforeStroke.OnChanged := @StrokeChanged;
  FDateAfterStroke.OnChanged := @StrokeChanged;
  FTodayStroke.OnChanged := @StrokeChanged;
  FBadgeStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCCalendarDateAppearance.Destroy;
begin
  FFill.Free;
  FStroke.Free;
  FFont.Free;
  FSelectedFill.Free;
  FSelectedStroke.Free;
  FSelectedFont.Free;
  FDisabledFill.Free;
  FDisabledStroke.Free;
  FDisabledFont.Free;
  FDateBeforeFill.Free;
  FDateBeforeFont.Free;
  FDateBeforeStroke.Free;
  FDateAfterFill.Free;
  FDateAfterFont.Free;
  FDateAfterStroke.Free;
  FTodayFill.Free;
  FTodayFont.Free;
  FTodayStroke.Free;
  FBadgeFill.Free;
  FBadgeFont.Free;
  FBadgeStroke.Free;
  inherited;
end;

procedure TTMSFNCCalendarDateAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarDateAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarDateAppearance.SetBadgeFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FBadgeFill <> Value then
    FBadgeFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetBadgeFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FBadgeFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetBadgeStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FBadgeStroke <> Value then
    FBadgeStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateAfterFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FDateAfterFill <> Value then
    FDateAfterFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateAfterFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FDateAfterFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateAfterStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FDateAfterStroke <> Value then
    FDateAfterStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateBeforeFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FDateBeforeFill <> Value then
    FDateBeforeFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateBeforeFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FDateBeforeFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDateBeforeStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FDateBeforeStroke <> Value then
    FDateBeforeStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDisabledFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FDisabledFill <> Value then
    FDisabledFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDisabledFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FDisabledFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetDisabledStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FDisabledStroke <> Value then
    FDisabledStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
    FHorizontalTextAlign := Value;
end;

procedure TTMSFNCCalendarDateAppearance.SetSelectedFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FSelectedFill <> Value then
    FSelectedFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetSelectedFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FSelectedFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetSelectedHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FSelectedHorizontalTextAlign <> Value then
    FSelectedHorizontalTextAlign := Value;
end;

procedure TTMSFNCCalendarDateAppearance.SetSelectedStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FSelectedStroke <> Value then
    FSelectedStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetSelectedVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FSelectedVerticalTextAlign <> Value then
    FSelectedVerticalTextAlign := Value;
end;

procedure TTMSFNCCalendarDateAppearance.SetShowDaysAfter(const Value: Boolean);
begin
  if FShowDaysAfter <> Value then
  begin
    FShowDaysAfter := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarDateAppearance.SetShowDaysBefore(const Value: Boolean);
begin
  if FShowDaysBefore <> Value then
  begin
    FShowDaysBefore := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarDateAppearance.SetShowFocus(const Value: Boolean);
begin
  if FShowFocus <> Value then
  begin
    FShowFocus := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarDateAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetTodayFill(
  const Value: TTMSFNCGraphicsFill);
begin
  if FTodayFill <> Value then
    FTodayFill.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetTodayFont(
  const Value: TTMSFNCGraphicsFont);
begin
  FTodayFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetTodayStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FTodayStroke <> Value then
    FTodayStroke.Assign(Value);
end;

procedure TTMSFNCCalendarDateAppearance.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
    FVerticalTextAlign := Value;
end;

procedure TTMSFNCCalendarDateAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarLineAppearance }

procedure TTMSFNCCalendarLineAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarLineAppearance then
    FStroke.Assign((Source as TTMSFNCCalendarLineAppearance).Stroke)
  else
    inherited;
end;

procedure TTMSFNCCalendarLineAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarLineAppearance.Create;
begin
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcSilver);
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCCalendarLineAppearance.Destroy;
begin
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCCalendarLineAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarLineAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarWeekNumberAppearance }

procedure TTMSFNCCalendarWeekNumberAppearance.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarWeekNumberAppearance then
  begin
    FVisible := (Source as TTMSFNCCalendarWeekNumberAppearance).Visible;
    FFill.Assign((Source as TTMSFNCCalendarWeekNumberAppearance).Fill);
    FStroke.Assign((Source as TTMSFNCCalendarWeekNumberAppearance).Stroke);
    FFont.AssignSource((Source as TTMSFNCCalendarWeekNumberAppearance).Font);
    FHorizontalTextAlign := (Source as TTMSFNCCalendarWeekNumberAppearance).HorizontalTextAlign;
    FVerticalTextAlign := (Source as TTMSFNCCalendarWeekNumberAppearance).VerticalTextAlign;
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarWeekNumberAppearance.Create;
begin
  FVisible := False;
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, gcNull);
  FFont := TTMSFNCGraphicsFont.Create;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcNull);
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;

  FFill.OnChanged := @FillChanged;
  FFont.OnChanged := @FontChanged;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCCalendarWeekNumberAppearance.Destroy;
begin
  FFill.Free;
  FFont.Free;
  FStroke.Free;
  inherited;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.FontChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetStroke(
  const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarWeekNumberAppearance.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarDateItem }

procedure TTMSFNCCalendarDateItem.Assign(Source: TPersistent);
begin
  if (Source is TTMSFNCCalendarDateItem) then
    FDate := (Source as TTMSFNCCalendarDateItem).Date
  else
    inherited;
end;

procedure TTMSFNCCalendarDateItem.SetDate(const Value: TDate);
begin
  if FDate <> Value then
  begin
    FDate := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarDateItem.Update;
begin
  TTMSFNCCalendarDates(Collection).Update(Self);
end;

{ TTMSFNCCalendarDates }

function TTMSFNCCalendarDates.Add: TTMSFNCCalendarDateItem;
begin
  Result := TTMSFNCCalendarDateItem(inherited Add);
end;

procedure TTMSFNCCalendarDates.DoChange;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

function TTMSFNCCalendarDates.GetItem(Index: integer): TTMSFNCCalendarDateItem;
begin
  Result := TTMSFNCCalendarDateItem(inherited Items[Index]);
end;

function TTMSFNCCalendarDates.InCollection(ADate: TDate): Boolean;
var
  I: Integer;
begin
  Result := False;

  for I := 0 to Count - 1 do
    if CompareDate(Items[I].Date, ADate) = 0 then
    begin
      Result := True;
      Break;
    end;
end;

procedure TTMSFNCCalendarDates.SelectDate(ADate: TDate);
var
  DateToAdd: TTMSFNCCalendarDateItem;
begin
  DateToAdd := Add;
  DateToAdd.Date := ADate;
end;

procedure TTMSFNCCalendarDates.SelectDateInterval(ADateFrom, ADateTo: TDate);
var
  I: Integer;
  IDate: TDate;
begin
  SelectDate(ADateFrom);
  IDate := ADateFrom;

  if CompareDate(ADateFrom, ADateTo) = -1  then
  begin
    for I := 0 to DaysBetween(ADateFrom, ADateTo) - 1 do
    begin
      IDate := IncDay(IDate, 1);
      SelectDate(IDate);
    end;
  end;

  if CompareDate(ADateTo, ADateFrom) = -1  then
  begin
    for I := 0 to DaysBetween(ADateTo, ADateFrom) - 1 do
    begin
      IDate := IncDay(IDate, -1);
      SelectDate(IDate);
    end;
  end;
end;

procedure TTMSFNCCalendarDates.SetItem(Index: integer;
  const Value: TTMSFNCCalendarDateItem);
begin
  inherited Items[Index] := Value;
end;

procedure TTMSFNCCalendarDates.UnselectAll;
begin
  Clear;
end;

procedure TTMSFNCCalendarDates.UnselectDate(ADate: TDate);
var
  I: Integer;
begin
  for I := 0 to Count - 1 do
    if Items[I].Date = ADate then
    begin
      Delete(I);
      Exit;
    end;
end;

procedure TTMSFNCCalendarDates.Update(Item: TCollectionItem);
begin
  inherited;
  DoChange;
end;

{ TTMSFNCCalendarInteraction }

procedure TTMSFNCCalendarCustomInteraction.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarCustomInteraction then
  begin
    FKeyboardSupport := (Source as TTMSFNCCalendarCustomInteraction).KeyboardSupport;
    FMultiSelect := (Source as TTMSFNCCalendarCustomInteraction).MultiSelect;
    FReadOnlyMode := (Source as TTMSFNCCalendarCustomInteraction).ReadOnlyMode;
    FNavigationMode := (Source as TTMSFNCCalendarCustomInteraction).NavigationMode;
    FSwipeOnHeader := (Source as TTMSFNCCalendarCustomInteraction).SwipeOnHeader;
  end
  else
    inherited;
end;

constructor TTMSFNCCalendarCustomInteraction.Create;
begin
  inherited;
  FKeyboardSupport := True;
  FMultiSelect := False;
  FReadOnlyMode := False;
  FNavigationMode := nmMonth;
  FSwipeOnHeader := False;
end;

procedure TTMSFNCCalendarCustomInteraction.SetKeyboardSupport(const Value: Boolean);
begin
  if FKeyboardSupport <> Value then
    FKeyboardSupport := Value;
end;

procedure TTMSFNCCalendarCustomInteraction.SetMultiSelect(const Value: Boolean);
begin
  if FMultiSelect <> Value then
    FMultiSelect := Value;
end;

procedure TTMSFNCCalendarCustomInteraction.SetNavigationMode(
  const Value: TTMSFNCCalendarNavigationMode);
begin
  if FNavigationMode <> Value then
    FNavigationMode := Value;
end;

procedure TTMSFNCCalendarCustomInteraction.SetReadOnlyMode(const Value: Boolean);
begin
  if FReadOnlyMode <> Value then
    FReadOnlyMode := Value;
end;

procedure TTMSFNCCalendarCustomInteraction.SetSwipeOnHeader(const Value: Boolean);
begin
  if FSwipeOnHeader <> Value then
    FSwipeOnHeader := Value;
end;

{ TTMSFNCCalendarArrow }

procedure TTMSFNCCalendarArrow.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarArrow then
  begin
    FColor := (Source as TTMSFNCCalendarArrow).Color;
    FVisibility := (Source as TTMSFNCCalendarArrow).Visibility;
    FSize := (Source as TTMSFNCCalendarArrow).Size;
    FArrowLeftType := (Source as TTMSFNCCalendarArrow).ArrowLeftType;
    FArrowLeftBitmapSize := (Source as TTMSFNCCalendarArrow).ArrowLeftBitmapSize;
    FArrowLeftBitmap.Assign((Source as TTMSFNCCalendarArrow).ArrowLeftBitmap);
    FArrowRightType := (Source as TTMSFNCCalendarArrow).ArrowRightType;
    FArrowRightBitmapSize := (Source as TTMSFNCCalendarArrow).ArrowRightBitmapSize;
    FArrowRightBitmap.Assign((Source as TTMSFNCCalendarArrow).ArrowRightBitmap);
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarArrow.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarArrow.Create(AOwner: TComponent);
begin
  FOwner := AOwner;
  FArrowLeftBitmap := TTMSFNCScaledBitmaps.Create(Self);
  FArrowRightBitmap := TTMSFNCScaledBitmaps.Create(Self);
  FColor := gcDimgray;
  FVisibility := [alvLeft, alvRight];
  FArrowLeftType := atArrow;
  FArrowRightType := atArrow;
  FArrowLeftBitmapSize := 15;
  FArrowRightBitmapSize := 15;
  FSize := 15;
end;

destructor TTMSFNCCalendarArrow.Destroy;
begin
  FArrowLeftBitmap.Free;
  FArrowRightBitmap.Free;
  inherited;
end;

function TTMSFNCCalendarArrow.GetBitmapContainer: TTMSFNCBitmapContainer;
{$IFNDEF WEBLIB}
var
  ia: ITMSFNCBitmapContainer;
{$ENDIF}
begin
  Result := nil;
  {$IFNDEF WEBLIB}
  if Assigned(FOwner) and Supports(FOwner, ITMSFNCBitmapContainer, ia) then
    Result := ia.BitmapContainer;
  {$ENDIF}
end;

function TTMSFNCCalendarArrow.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TTMSFNCCalendarArrow.IsArrowLeftBitmapSizeStored: Boolean;
begin
  Result := ArrowLeftBitmapSize <> 15;
end;

function TTMSFNCCalendarArrow.IsArrowRightBitmapSizeStore: Boolean;
begin
  Result := ArrowRightBitmapSize <> 15;
end;

procedure TTMSFNCCalendarArrow.SetArrowLeftBitmap(const Value: TTMSFNCScaledBitmaps);
begin
  FArrowLeftBitmap.Assign(Value);
end;

procedure TTMSFNCCalendarArrow.SetArrowLeftBitmapSize(const Value: Single);
begin
  if FArrowLeftBitmapSize <> Value then
  begin
    FArrowLeftBitmapSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarArrow.SetArrowLeftType(
  const Value: TTMSFNCCalendarArrowType);
begin
  if FArrowLeftType <> Value then
  begin
    FArrowLeftType := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarArrow.SetArrowRightBitmap(const Value: TTMSFNCScaledBitmaps);
begin
  FArrowRightBitmap.Assign(Value);
end;

procedure TTMSFNCCalendarArrow.SetArrowRightBitmapSize(const Value: Single);
begin
  if FArrowRightBitmapSize <> Value then
  begin
    FArrowRightBitmapSize := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarArrow.SetArrowRightType(
  const Value: TTMSFNCCalendarArrowType);
begin
  if FArrowRightType <> Value then
  begin
    FArrowRightType := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarArrow.SetBitmapContainer(
  const Value: TTMSFNCBitmapContainer);
{$IFNDEF WEBLIB}
var
  ia: ITMSFNCBitmapContainer;
{$ENDIF}
begin
  {$IFNDEF WEBLIB}
  if Assigned(FOwner) and Supports(FOwner, ITMSFNCBitmapContainer, ia) then
    ia.BitmapContainer := Value;
  {$ENDIF}
end;

procedure TTMSFNCCalendarArrow.SetColor(const Value: TTMSFNCGraphicsColor);
begin
  if FColor <> Value then
  begin
    FColor := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarArrow.SetSize(const Value: Integer);
begin
  if FSize <> Value then
    FSize := Value;
end;

procedure TTMSFNCCalendarArrow.SetVisibility(
  const Value: TTMSFNCCalendarArrowLayout);
begin
  if FVisibility <> Value then
  begin
    FVisibility := Value;
    Changed;
  end;
end;

{ TTMSFNCCalendarFooter }

procedure TTMSFNCCalendarFooter.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarFooter then
  begin
    FFill.Assign((Source as TTMSFNCCalendarFooter).Fill);
    FStroke.Assign((Source as TTMSFNCCalendarFooter).Stroke);
    FFont.AssignSource((Source as TTMSFNCCalendarFooter).Font);
    FVerticalTextAlign := (Source as TTMSFNCCalendarFooter).FVerticalTextAlign;
    FHorizontalTextAlign := (Source as TTMSFNCCalendarFooter).FHorizontalTextAlign;
    FVisible := (Source as TTMSFNCCalendarFooter).Visible;
    FDateCaption := (Source as TTMSFNCCalendarFooter).DateCaption;
    FCaptionRect := (Source as TTMSFNCCalendarFooter).CaptionRect;
    FCaption := (Source as TTMSFNCCalendarFooter).Caption;
    FHeight := (Source as TTMSFNCCalendarFooter).Height;
  end
  else
    inherited;
end;

procedure TTMSFNCCalendarFooter.Changed;
begin
  if Assigned(OnChange) then
    OnChange(Self);
end;

constructor TTMSFNCCalendarFooter.Create;
begin
  FFont := TTMSFNCGraphicsFont.Create;
  FHorizontalTextAlign := gtaCenter;
  FVerticalTextAlign := gtaCenter;
  FStroke := TTMSFNCGraphicsStroke.Create(gskSolid, gcSilver);
  FFill := TTMSFNCGraphicsFill.Create(gfkSolid, MakeGraphicsColor(228, 228, 228));
  FVisible := True;
  FHeight := 25;
  FDateCaption := fdToday;

  FFill.OnChanged := @FillChanged;
  FFont.OnChanged := @FontChanged;
  FStroke.OnChanged := @StrokeChanged;
end;

destructor TTMSFNCCalendarFooter.Destroy;
begin
  FFont.Free;
  FStroke.Free;
  FFill.Free;
  inherited;
end;

procedure TTMSFNCCalendarFooter.FillChanged(Sender: TObject);
begin
  Changed;
end;

procedure TTMSFNCCalendarFooter.FontChanged(Sender: TObject);
begin
  Changed;
end;

function TTMSFNCCalendarFooter.IsCaptionStored: Boolean;
begin
  Result := Caption <> '';
end;

procedure TTMSFNCCalendarFooter.SetCaption(const Value: string);
begin
  if FCaption <> Value then
  begin
    FCaption := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.SetCaptionRect(const Value: TRectF);
begin
  FCaptionRect := Value;
end;

procedure TTMSFNCCalendarFooter.SetDateCaption(
  const Value: TTMSFNCCalendarFooterDate);
begin
  if FDateCaption <> Value then
  begin
    FDateCaption := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.SetFill(const Value: TTMSFNCGraphicsFill);
begin
  if FFill <> Value then
    FFill.Assign(Value);
end;

procedure TTMSFNCCalendarFooter.SetFont(const Value: TTMSFNCGraphicsFont);
begin
  FFont.AssignSource(Value);
end;

procedure TTMSFNCCalendarFooter.SetHeight(const Value: Integer);
begin
  if FHeight <> Value then
  begin
    FHeight := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.SetHorizontalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FHorizontalTextAlign <> Value then
  begin
    FHorizontalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.SetStroke(const Value: TTMSFNCGraphicsStroke);
begin
  if FStroke <> Value then
    FStroke.Assign(Value);
end;

procedure TTMSFNCCalendarFooter.SetVerticalTextAlign(
  const Value: TTMSFNCGraphicsTextAlign);
begin
  if FVerticalTextAlign <> Value then
  begin
    FVerticalTextAlign := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.SetVisible(const Value: Boolean);
begin
  if FVisible <> Value then
  begin
    FVisible := Value;
    Changed;
  end;
end;

procedure TTMSFNCCalendarFooter.StrokeChanged(Sender: TObject);
begin
  Changed;
end;

{ TTMSFNCCalendarEventItem }

procedure TTMSFNCCalendarEventItem.Assign(Source: TPersistent);
begin
  if Source is TTMSFNCCalendarEventItem then
  begin
    FBadgeColor := (Source as TTMSFNCCalendarEventItem).BadgeColor;
    FBadgeFontColor := (Source as TTMSFNCCalendarEventItem).BadgeFontColor;
    FBadgeValue := (Source as TTMSFNCCalendarEventItem).BadgeValue;
    FHint := (Source as TTMSFNCCalendarEventItem).Hint;
    FShowBadge := (Source as TTMSFNCCalendarEventItem).ShowBadge;
    FDate := (Source as TTMSFNCCalendarEventItem).Date;
  end
  else
    inherited;
end;

constructor TTMSFNCCalendarEventItem.Create(ACollection: TCollection);
begin
  inherited;
  FShowBadge := True;
  FBadgeColor := gcNull;
  FBadgeFontColor := gcNull;
  Date := Now;
end;

function TTMSFNCCalendarEventItem.IsHintStored: Boolean;
begin
  Result := Hint <> '';
end;

procedure TTMSFNCCalendarEventItem.SetBadgeColor(const Value: TTMSFNCGraphicsColor);
begin
  if FBadgeColor <> Value then
  begin
    FBadgeColor := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarEventItem.SetBadgeFontColor(
  const Value: TTMSFNCGraphicsColor);
begin
  if FBadgeFontColor <> Value then
  begin
    FBadgeFontColor := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarEventItem.SetBadgeValue(const Value: Integer);
begin
  if FBadgeValue <> Value then
  begin
    FBadgeValue := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarEventItem.SetHint(const Value: string);
begin
  if FHint <> Value then
  begin
    FHint := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarEventItem.SetShowBadge(const Value: Boolean);
begin
  if FShowBadge <> Value then
  begin
    FShowBadge := Value;
    Update;
  end;
end;

procedure TTMSFNCCalendarEventItem.Update;
begin
  TTMSFNCCalendarEvents(Collection).Update(Self);
end;

{ TTMSFNCCalendarEvents }

function TTMSFNCCalendarEvents.Add: TTMSFNCCalendarEventItem;
begin
  Result := TTMSFNCCalendarEventItem(inherited Add);
end;

function TTMSFNCCalendarEvents.GetEventCollectionItemByDate(
  ADate: TDate): TTMSFNCCalendarEventItem;
var
  I: Integer;
begin
  Result := nil;

  for I := 0 to Count - 1 do
    if CompareDate(Items[I].Date, ADate) = 0 then
    begin
      Result := Items[I];
      Exit;
    end;
end;

function TTMSFNCCalendarEvents.GetItem(
  Index: integer): TTMSFNCCalendarEventItem;
begin
  Result := TTMSFNCCalendarEventItem(inherited Items[Index]);
end;

procedure TTMSFNCCalendarEvents.SetItem(Index: integer;
  const Value: TTMSFNCCalendarEventItem);
begin
  inherited Items[Index] := Value;
end;

end.
