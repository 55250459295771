unit CurrentStop;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, WEBLib.DB,
  WEBLib.DBCtrls, StrUtils;

type
  TFCurrentStop = class(TForm)
    CurrentStopPnl: TPanel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebDBLabel5: TDBLabel;
    WebDBLabel6: TDBLabel;
    WebDBLabel7: TDBLabel;
    WebDBLabel8: TDBLabel;
    WebDBLabel9: TDBLabel;
    WebDBLabel10: TDBLabel;
    WebLabel4: TLabel;
    WebDBLabel11: TDBLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebDBLabel12: TDBLabel;
    WebDBLabel13: TDBLabel;
    WebLabel8: TLabel;
    WebDBLabel14: TDBLabel;
    WebDBLabel15: TDBLabel;
    WebLabel9: TLabel;
    WebDBLabel16: TDBLabel;
    WebLabel10: TLabel;
    WebDBLabel17: TDBLabel;
    WebLabel11: TLabel;
    WebDBLabel18: TDBLabel;
    WebDBLabel19: TDBLabel;
    WebDBLabel20: TDBLabel;
    WebDBLabel21: TDBLabel;
    NextOrdBtn: TButton;
    PrevOrdBtn: TButton;
    RouteRecapBtn: TButton;
    WebButton4: TButton;
    OrderDtlBtn: TButton;
    OptionsBtn: TButton;
    WebDBLabel22: TDBLabel;
    WebDBLabel23: TDBLabel;
    WebDBLabel24: TDBLabel;
    WebDBLabel25: TDBLabel;
    WebLabel12: TLabel;
    DSBackhauls: TXDataWebDataSet;
    WDSBackhauls: TDataSource;
    WebLabel13: TLabel;
    DSBackhaulsOrdrNumTrunc: TIntegerField;
    DSBackhaulsVenNum: TIntegerField;
    DSBackhaulsReleaseNum: TStringField;
    DSBackhaulsAdditionalTemPinfo: TStringField;
    DSBackhaulsNotes: TStringField;
    DSBackhaulsVenLoc: TStringField;
    DSBackhaulsAdd1: TStringField;
    DSBackhaulsAdd2: TStringField;
    DSBackhaulsCity: TStringField;
    DSBackhaulsST: TStringField;
    DSBackhaulsZip: TStringField;
    DSBackhaulsPhone: TStringField;
    DSBackhaulsTtlCs: TIntegerField;
    DSBackhaulsTtlCubes: TFloatField;
    DSBackhaulsTtlPndsGross: TFloatField;
    DSBackhaulsOrdrShipDt: TDateTimeField;
    DSBackhaulsPickupTruck: TIntegerField;
    DSBackhaulsLatitude: TFloatField;
    DSBackhaulsLongitude: TFloatField;
    DSBackhaulsPickupCompleted: TStringField;
    WebDBLabel4: TDBLabel;
    procedure WebFormShow(Sender: TObject);
    procedure PrevOrdBtnClick(Sender: TObject);
    procedure NextOrdBtnClick(Sender: TObject);
    procedure RouteRecapBtnClick(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure OrderDtlBtnClick(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure OptionsBtnClick(Sender: TObject);
    procedure SetColorVars;
    procedure DSBackhaulsAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FCurrentStop: TFCurrentStop;

implementation

Uses Data, SelectRoute, OrderList, OrderDetail, Login, Options;

{$R *.dfm}

procedure TFCurrentStop.SetColorVars;
begin
  if Dm.DSCurrStopOrdersActualArriveTime.AsString <> '' then
  begin
    WebDBLabel3.ElementClassName := 'alert-danger h5';
    WebDBLabel1.ElementClassName := 'alert-danger h5';
  end
  else
  begin
    WebDBLabel3.ElementClassName := 'h5';
    WebDBLabel1.ElementClassName := 'h5';
  end;
  if Dm.DSCurrStopOrdersPickupPcs.AsInteger > 0 then
  begin
    WebDBLabel15.ElementClassName := 'alert-warning h5';
  end
  else
  begin
    WebDBLabel15.ElementClassName := 'h5';
  end;
  if Dm.DSCurrStopOrdersStandingMessage.AsString <> '' then
  begin
    WebDBLabel23.ElementClassName := 'alert-warning h5';
  end
  else
  begin
    WebDBLabel23.ElementClassName := 'h5';
  end;
  if (AnsiContainsStr(UpperCase(Dm.DSCurrStopOrdersTermsExplained.AsString),
    'ARRIVAL')) or
    (AnsiContainsStr(UpperCase(Dm.DSCurrStopOrdersTermsExplained.AsString),
    'CASH')) then
  begin
    WebDBLabel22.ElementClassName := 'alert-danger h5';
  end
  else
  begin
    WebDBLabel22.ElementClassName := 'h5';
  end;
end;

procedure TFCurrentStop.PrevOrdBtnClick(Sender: TObject);
begin
  Dm.DSCurrStopOrders.Prior;
  SetColorVars;
end;

procedure TFCurrentStop.RouteRecapBtnClick(Sender: TObject);
begin
  Application.CreateForm(TFOrderList, FOrderList);
end;

procedure TFCurrentStop.DSBackhaulsAfterOpen(DataSet: TDataSet);
begin
  if DSBackhauls.RecordCount > 0 then
  begin
    WebLabel13.Caption := 'Vendor Pickups on Route - Check Options';
    WebLabel13.ElementClassName := 'bg-danger text-white';
  end
  else
  begin
    WebLabel13.Caption := '';
    WebLabel13.ElementClassName := 'h5';
  end;
end;

procedure TFCurrentStop.NextOrdBtnClick(Sender: TObject);
begin
  Dm.DSCurrStopOrders.Next;
  SetColorVars;
end;

procedure TFCurrentStop.OptionsBtnClick(Sender: TObject);
begin
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFCurrentStop.OrderDtlBtnClick(Sender: TObject);
begin
  Application.CreateForm(TFOrderDtl, FOrderDtl);
end;

procedure TFCurrentStop.WebButton4Click(Sender: TObject);
begin
  //FCurrentStop.Close;
  // FCurrentStop.Free;
  Application.CreateForm(TFLogin, FLogin);
end;

procedure TFCurrentStop.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
  if CurrentForm = 'CurrentStop' then
  begin
    FCurrentStop.Height := 699;
    FCurrentStop.Width := 393;
    FCurrentStop.Close;
    // FCurrentStop.Free;
    Application.CreateForm(TFCurrentStop, FCurrentStop);
  end;
end;

procedure TFCurrentStop.WebFormResize(Sender: TObject);
begin
  CurrentStopPnl.Left := Round((FCurrentStop.Width / 2) -
    (CurrentStopPnl.Width / 2));
  // CurrentStopPnl.Top := Round((FCurrentStop.Height/2)-(CurrentStopPnl.Height/2));
  if CurrentStopPnl.Top < 0 then
    CurrentStopPnl.Top := 0;
end;

procedure TFCurrentStop.WebFormShow(Sender: TObject);
begin
  DSBackhauls.QueryString := '$filter=(PickupTruck eq ' + Dm.DSLoginLastRoute.AsString+')';
  DSBackhauls.Load;
  CurrentStopPnl.Left := Round((FCurrentStop.Width / 2) -
    (CurrentStopPnl.Width / 2));
  CurrentStopPnl.Top := Round((FCurrentStop.Height / 2) -
    (CurrentStopPnl.Height / 2));
  CurrentForm := 'CurrentStop';
  if Dm.DSCurrStopOrders.Active then
    SetColorVars;
end;

procedure TFCurrentStop.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CurrentStopPnl := TPanel.Create(Self);
  WebDBLabel1 := TDBLabel.Create('row2');
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebDBLabel6 := TDBLabel.Create(Self);
  WebDBLabel7 := TDBLabel.Create(Self);
  WebDBLabel8 := TDBLabel.Create(Self);
  WebDBLabel9 := TDBLabel.Create(Self);
  WebDBLabel10 := TDBLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebDBLabel11 := TDBLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebDBLabel12 := TDBLabel.Create(Self);
  WebDBLabel13 := TDBLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebDBLabel14 := TDBLabel.Create(Self);
  WebDBLabel15 := TDBLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebDBLabel16 := TDBLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebDBLabel17 := TDBLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebDBLabel18 := TDBLabel.Create(Self);
  WebDBLabel19 := TDBLabel.Create(Self);
  WebDBLabel20 := TDBLabel.Create(Self);
  WebDBLabel21 := TDBLabel.Create(Self);
  WebDBLabel22 := TDBLabel.Create(Self);
  WebDBLabel23 := TDBLabel.Create(Self);
  WebDBLabel24 := TDBLabel.Create(Self);
  WebDBLabel25 := TDBLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  NextOrdBtn := TButton.Create(Self);
  PrevOrdBtn := TButton.Create(Self);
  RouteRecapBtn := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  OrderDtlBtn := TButton.Create(Self);
  OptionsBtn := TButton.Create(Self);
  DSBackhauls := TXDataWebDataSet.Create(Self);
  DSBackhaulsOrdrNumTrunc := TIntegerField.Create(Self);
  DSBackhaulsVenNum := TIntegerField.Create(Self);
  DSBackhaulsReleaseNum := TStringField.Create(Self);
  DSBackhaulsAdditionalTemPinfo := TStringField.Create(Self);
  DSBackhaulsNotes := TStringField.Create(Self);
  DSBackhaulsVenLoc := TStringField.Create(Self);
  DSBackhaulsAdd1 := TStringField.Create(Self);
  DSBackhaulsAdd2 := TStringField.Create(Self);
  DSBackhaulsCity := TStringField.Create(Self);
  DSBackhaulsST := TStringField.Create(Self);
  DSBackhaulsZip := TStringField.Create(Self);
  DSBackhaulsPhone := TStringField.Create(Self);
  DSBackhaulsTtlCs := TIntegerField.Create(Self);
  DSBackhaulsTtlCubes := TFloatField.Create(Self);
  DSBackhaulsTtlPndsGross := TFloatField.Create(Self);
  DSBackhaulsOrdrShipDt := TDateTimeField.Create(Self);
  DSBackhaulsPickupTruck := TIntegerField.Create(Self);
  DSBackhaulsLatitude := TFloatField.Create(Self);
  DSBackhaulsLongitude := TFloatField.Create(Self);
  DSBackhaulsPickupCompleted := TStringField.Create(Self);
  WDSBackhauls := TDataSource.Create(Self);

  CurrentStopPnl.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebDBLabel6.BeforeLoadDFMValues;
  WebDBLabel7.BeforeLoadDFMValues;
  WebDBLabel8.BeforeLoadDFMValues;
  WebDBLabel9.BeforeLoadDFMValues;
  WebDBLabel10.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebDBLabel11.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebDBLabel12.BeforeLoadDFMValues;
  WebDBLabel13.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebDBLabel14.BeforeLoadDFMValues;
  WebDBLabel15.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebDBLabel16.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebDBLabel17.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebDBLabel18.BeforeLoadDFMValues;
  WebDBLabel19.BeforeLoadDFMValues;
  WebDBLabel20.BeforeLoadDFMValues;
  WebDBLabel21.BeforeLoadDFMValues;
  WebDBLabel22.BeforeLoadDFMValues;
  WebDBLabel23.BeforeLoadDFMValues;
  WebDBLabel24.BeforeLoadDFMValues;
  WebDBLabel25.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  NextOrdBtn.BeforeLoadDFMValues;
  PrevOrdBtn.BeforeLoadDFMValues;
  RouteRecapBtn.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  OrderDtlBtn.BeforeLoadDFMValues;
  OptionsBtn.BeforeLoadDFMValues;
  DSBackhauls.BeforeLoadDFMValues;
  DSBackhaulsOrdrNumTrunc.BeforeLoadDFMValues;
  DSBackhaulsVenNum.BeforeLoadDFMValues;
  DSBackhaulsReleaseNum.BeforeLoadDFMValues;
  DSBackhaulsAdditionalTemPinfo.BeforeLoadDFMValues;
  DSBackhaulsNotes.BeforeLoadDFMValues;
  DSBackhaulsVenLoc.BeforeLoadDFMValues;
  DSBackhaulsAdd1.BeforeLoadDFMValues;
  DSBackhaulsAdd2.BeforeLoadDFMValues;
  DSBackhaulsCity.BeforeLoadDFMValues;
  DSBackhaulsST.BeforeLoadDFMValues;
  DSBackhaulsZip.BeforeLoadDFMValues;
  DSBackhaulsPhone.BeforeLoadDFMValues;
  DSBackhaulsTtlCs.BeforeLoadDFMValues;
  DSBackhaulsTtlCubes.BeforeLoadDFMValues;
  DSBackhaulsTtlPndsGross.BeforeLoadDFMValues;
  DSBackhaulsOrdrShipDt.BeforeLoadDFMValues;
  DSBackhaulsPickupTruck.BeforeLoadDFMValues;
  DSBackhaulsLatitude.BeforeLoadDFMValues;
  DSBackhaulsLongitude.BeforeLoadDFMValues;
  DSBackhaulsPickupCompleted.BeforeLoadDFMValues;
  WDSBackhauls.BeforeLoadDFMValues;
  try
    Name := 'FCurrentStop';
    Width := 385;
    Height := 750;
    Align := alClient;
    Caption := 'Current Stop';
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CurrentStopPnl.SetParentComponent(Self);
    CurrentStopPnl.Name := 'CurrentStopPnl';
    CurrentStopPnl.Left := 3;
    CurrentStopPnl.Top := 2;
    CurrentStopPnl.Width := 375;
    CurrentStopPnl.Height := 743;
    CurrentStopPnl.ElementClassName := 'card';
    CurrentStopPnl.ElementBodyClassName := 'card-body';
    CurrentStopPnl.Font.Charset := DEFAULT_CHARSET;
    CurrentStopPnl.Font.Color := clWindowText;
    CurrentStopPnl.Font.Height := -16;
    CurrentStopPnl.Font.Name := 'Arial';
    CurrentStopPnl.Font.Style := [];
    CurrentStopPnl.ParentFont := False;
    WebDBLabel1.SetParentComponent(CurrentStopPnl);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 103;
    WebDBLabel1.Top := 36;
    WebDBLabel1.Width := 48;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'Invoice';
    WebDBLabel1.ElementClassName := 'h5';
    WebDBLabel1.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel1.Font.Color := clWindowText;
    WebDBLabel1.Font.Height := -16;
    WebDBLabel1.Font.Name := 'Arial';
    WebDBLabel1.Font.Style := [];
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.ParentFont := False;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'InvoiceNum';
    WebDBLabel1.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel2.SetParentComponent(CurrentStopPnl);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 31;
    WebDBLabel2.Top := 80;
    WebDBLabel2.Width := 41;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'Cust#';
    WebDBLabel2.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel2.Font.Color := clWindowText;
    WebDBLabel2.Font.Height := -16;
    WebDBLabel2.Font.Name := 'Arial';
    WebDBLabel2.Font.Style := [];
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.ParentFont := False;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'Cust';
    WebDBLabel2.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel3.SetParentComponent(CurrentStopPnl);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 31;
    WebDBLabel3.Top := 36;
    WebDBLabel3.Width := 33;
    WebDBLabel3.Height := 18;
    WebDBLabel3.AutoSize := False;
    WebDBLabel3.Caption := '000';
    WebDBLabel3.ElementClassName := 'h5';
    WebDBLabel3.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel3.Font.Color := clBlack;
    WebDBLabel3.Font.Height := -16;
    WebDBLabel3.Font.Name := 'Arial';
    WebDBLabel3.Font.Style := [];
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.ParentFont := False;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Stop';
    WebDBLabel3.DataSource := Dm.WDSCurrStopOrders;
    WebLabel1.SetParentComponent(CurrentStopPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 11;
    WebLabel1.Width := 36;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Stop';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Arial';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(CurrentStopPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 103;
    WebLabel2.Top := 11;
    WebLabel2.Width := 68;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Invoice #';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clWindowText;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Arial';
    WebLabel2.Font.Style := [fsBold];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(CurrentStopPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 56;
    WebLabel3.Width := 108;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Customer Info';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clWindowText;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Arial';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel5.SetParentComponent(CurrentStopPnl);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 31;
    WebDBLabel5.Top := 104;
    WebDBLabel5.Width := 72;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'Address 1';
    WebDBLabel5.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel5.Font.Color := clWindowText;
    WebDBLabel5.Font.Height := -16;
    WebDBLabel5.Font.Name := 'Arial';
    WebDBLabel5.Font.Style := [];
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.ParentFont := False;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'ShipAdd1';
    WebDBLabel5.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel6.SetParentComponent(CurrentStopPnl);
    WebDBLabel6.Name := 'WebDBLabel6';
    WebDBLabel6.Left := 31;
    WebDBLabel6.Top := 128;
    WebDBLabel6.Width := 72;
    WebDBLabel6.Height := 18;
    WebDBLabel6.Caption := 'Address 2';
    WebDBLabel6.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel6.Font.Color := clWindowText;
    WebDBLabel6.Font.Height := -16;
    WebDBLabel6.Font.Name := 'Arial';
    WebDBLabel6.Font.Style := [];
    WebDBLabel6.HeightPercent := 100.000000000000000000;
    WebDBLabel6.ParentFont := False;
    WebDBLabel6.WidthPercent := 100.000000000000000000;
    WebDBLabel6.DataField := 'ShipAdd2';
    WebDBLabel6.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel7.SetParentComponent(CurrentStopPnl);
    WebDBLabel7.Name := 'WebDBLabel7';
    WebDBLabel7.Left := 31;
    WebDBLabel7.Top := 152;
    WebDBLabel7.Width := 27;
    WebDBLabel7.Height := 18;
    WebDBLabel7.Caption := 'City';
    WebDBLabel7.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel7.Font.Color := clWindowText;
    WebDBLabel7.Font.Height := -16;
    WebDBLabel7.Font.Name := 'Arial';
    WebDBLabel7.Font.Style := [];
    WebDBLabel7.HeightPercent := 100.000000000000000000;
    WebDBLabel7.ParentFont := False;
    WebDBLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel7.DataField := 'ShipCity';
    WebDBLabel7.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel8.SetParentComponent(CurrentStopPnl);
    WebDBLabel8.Name := 'WebDBLabel8';
    WebDBLabel8.Left := 207;
    WebDBLabel8.Top := 152;
    WebDBLabel8.Width := 37;
    WebDBLabel8.Height := 18;
    WebDBLabel8.Caption := 'State';
    WebDBLabel8.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel8.Font.Color := clWindowText;
    WebDBLabel8.Font.Height := -16;
    WebDBLabel8.Font.Name := 'Arial';
    WebDBLabel8.Font.Style := [];
    WebDBLabel8.HeightPercent := 100.000000000000000000;
    WebDBLabel8.ParentFont := False;
    WebDBLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel8.DataField := 'ShipState';
    WebDBLabel8.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel9.SetParentComponent(CurrentStopPnl);
    WebDBLabel9.Name := 'WebDBLabel9';
    WebDBLabel9.Left := 31;
    WebDBLabel9.Top := 176;
    WebDBLabel9.Width := 54;
    WebDBLabel9.Height := 18;
    WebDBLabel9.Caption := 'Contact';
    WebDBLabel9.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel9.Font.Color := clWindowText;
    WebDBLabel9.Font.Height := -16;
    WebDBLabel9.Font.Name := 'Arial';
    WebDBLabel9.Font.Style := [];
    WebDBLabel9.HeightPercent := 100.000000000000000000;
    WebDBLabel9.ParentFont := False;
    WebDBLabel9.WidthPercent := 100.000000000000000000;
    WebDBLabel9.DataField := 'ShipContact';
    WebDBLabel9.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel10.SetParentComponent(CurrentStopPnl);
    WebDBLabel10.Name := 'WebDBLabel10';
    WebDBLabel10.Left := 31;
    WebDBLabel10.Top := 200;
    WebDBLabel10.Width := 104;
    WebDBLabel10.Height := 18;
    WebDBLabel10.Caption := 'Phone Number';
    WebDBLabel10.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel10.Font.Color := clWindowText;
    WebDBLabel10.Font.Height := -16;
    WebDBLabel10.Font.Name := 'Arial';
    WebDBLabel10.Font.Style := [];
    WebDBLabel10.HeightPercent := 100.000000000000000000;
    WebDBLabel10.ParentFont := False;
    WebDBLabel10.WidthPercent := 100.000000000000000000;
    WebDBLabel10.DataField := 'ShipPhone';
    WebDBLabel10.DataSource := Dm.WDSCurrStopOrders;
    WebLabel4.SetParentComponent(CurrentStopPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 24;
    WebLabel4.Top := 224;
    WebLabel4.Width := 134;
    WebLabel4.Height := 19;
    WebLabel4.Caption := 'Order Information';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := clWindowText;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Arial';
    WebLabel4.Font.Style := [fsBold];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel11.SetParentComponent(CurrentStopPnl);
    WebDBLabel11.Name := 'WebDBLabel11';
    WebDBLabel11.Left := 31;
    WebDBLabel11.Top := 274;
    WebDBLabel11.Width := 27;
    WebDBLabel11.Height := 18;
    WebDBLabel11.Alignment := taRightJustify;
    WebDBLabel11.AutoSize := False;
    WebDBLabel11.Caption := '000';
    WebDBLabel11.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel11.Font.Color := clWindowText;
    WebDBLabel11.Font.Height := -16;
    WebDBLabel11.Font.Name := 'Arial';
    WebDBLabel11.Font.Style := [];
    WebDBLabel11.HeightPercent := 100.000000000000000000;
    WebDBLabel11.ParentFont := False;
    WebDBLabel11.WidthPercent := 100.000000000000000000;
    WebDBLabel11.DataField := 'PcsDry';
    WebDBLabel11.DataSource := Dm.WDSCurrStopOrders;
    WebLabel5.SetParentComponent(CurrentStopPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 31;
    WebLabel5.Top := 250;
    WebLabel5.Width := 27;
    WebLabel5.Height := 19;
    WebLabel5.Caption := 'Dry';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := clWindowText;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Arial';
    WebLabel5.Font.Style := [fsBold];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(CurrentStopPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 79;
    WebLabel6.Top := 250;
    WebLabel6.Width := 22;
    WebLabel6.Height := 19;
    WebLabel6.Caption := 'Clr';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := clWindowText;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Arial';
    WebLabel6.Font.Style := [fsBold];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(CurrentStopPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 127;
    WebLabel7.Top := 250;
    WebLabel7.Width := 25;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'Frz';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := clWindowText;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Arial';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebDBLabel12.SetParentComponent(CurrentStopPnl);
    WebDBLabel12.Name := 'WebDBLabel12';
    WebDBLabel12.Left := 76;
    WebDBLabel12.Top := 274;
    WebDBLabel12.Width := 27;
    WebDBLabel12.Height := 18;
    WebDBLabel12.Alignment := taRightJustify;
    WebDBLabel12.AutoSize := False;
    WebDBLabel12.Caption := '000';
    WebDBLabel12.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel12.Font.Color := clWindowText;
    WebDBLabel12.Font.Height := -16;
    WebDBLabel12.Font.Name := 'Arial';
    WebDBLabel12.Font.Style := [];
    WebDBLabel12.HeightPercent := 100.000000000000000000;
    WebDBLabel12.ParentFont := False;
    WebDBLabel12.WidthPercent := 100.000000000000000000;
    WebDBLabel12.DataField := 'PcsCooler';
    WebDBLabel12.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel13.SetParentComponent(CurrentStopPnl);
    WebDBLabel13.Name := 'WebDBLabel13';
    WebDBLabel13.Left := 124;
    WebDBLabel13.Top := 274;
    WebDBLabel13.Width := 27;
    WebDBLabel13.Height := 18;
    WebDBLabel13.Alignment := taRightJustify;
    WebDBLabel13.AutoSize := False;
    WebDBLabel13.Caption := '000';
    WebDBLabel13.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel13.Font.Color := clWindowText;
    WebDBLabel13.Font.Height := -16;
    WebDBLabel13.Font.Name := 'Arial';
    WebDBLabel13.Font.Style := [];
    WebDBLabel13.HeightPercent := 100.000000000000000000;
    WebDBLabel13.ParentFont := False;
    WebDBLabel13.WidthPercent := 100.000000000000000000;
    WebDBLabel13.DataField := 'PcsFreezer';
    WebDBLabel13.DataSource := Dm.WDSCurrStopOrders;
    WebLabel8.SetParentComponent(CurrentStopPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 183;
    WebLabel8.Top := 250;
    WebLabel8.Width := 37;
    WebLabel8.Height := 19;
    WebLabel8.Caption := 'Total';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clWindowText;
    WebLabel8.Font.Height := -16;
    WebLabel8.Font.Name := 'Arial';
    WebLabel8.Font.Style := [fsBold];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebDBLabel14.SetParentComponent(CurrentStopPnl);
    WebDBLabel14.Name := 'WebDBLabel14';
    WebDBLabel14.Left := 188;
    WebDBLabel14.Top := 274;
    WebDBLabel14.Width := 27;
    WebDBLabel14.Height := 18;
    WebDBLabel14.Alignment := taRightJustify;
    WebDBLabel14.AutoSize := False;
    WebDBLabel14.Caption := '000';
    WebDBLabel14.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel14.Font.Color := clWindowText;
    WebDBLabel14.Font.Height := -16;
    WebDBLabel14.Font.Name := 'Arial';
    WebDBLabel14.Font.Style := [];
    WebDBLabel14.HeightPercent := 100.000000000000000000;
    WebDBLabel14.ParentFont := False;
    WebDBLabel14.WidthPercent := 100.000000000000000000;
    WebDBLabel14.DataField := 'PcsTotal';
    WebDBLabel14.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel15.SetParentComponent(CurrentStopPnl);
    WebDBLabel15.Name := 'WebDBLabel15';
    WebDBLabel15.Left := 255;
    WebDBLabel15.Top := 274;
    WebDBLabel15.Width := 27;
    WebDBLabel15.Height := 18;
    WebDBLabel15.Alignment := taRightJustify;
    WebDBLabel15.AutoSize := False;
    WebDBLabel15.Caption := '000';
    WebDBLabel15.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel15.Font.Color := clWindowText;
    WebDBLabel15.Font.Height := -16;
    WebDBLabel15.Font.Name := 'Arial';
    WebDBLabel15.Font.Style := [];
    WebDBLabel15.HeightPercent := 100.000000000000000000;
    WebDBLabel15.ParentFont := False;
    WebDBLabel15.WidthPercent := 100.000000000000000000;
    WebDBLabel15.DataField := 'PickupPcs';
    WebDBLabel15.DataSource := Dm.WDSCurrStopOrders;
    WebLabel9.SetParentComponent(CurrentStopPnl);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 255;
    WebLabel9.Top := 250;
    WebLabel9.Width := 27;
    WebLabel9.Height := 19;
    WebLabel9.Caption := 'P/U';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := clWindowText;
    WebLabel9.Font.Height := -16;
    WebLabel9.Font.Name := 'Arial';
    WebLabel9.Font.Style := [fsBold];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebDBLabel16.SetParentComponent(CurrentStopPnl);
    WebDBLabel16.Name := 'WebDBLabel16';
    WebDBLabel16.Left := 72;
    WebDBLabel16.Top := 318;
    WebDBLabel16.Width := 27;
    WebDBLabel16.Height := 18;
    WebDBLabel16.Alignment := taRightJustify;
    WebDBLabel16.AutoSize := False;
    WebDBLabel16.Caption := '000';
    WebDBLabel16.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel16.Font.Color := clWindowText;
    WebDBLabel16.Font.Height := -16;
    WebDBLabel16.Font.Name := 'Arial';
    WebDBLabel16.Font.Style := [];
    WebDBLabel16.HeightPercent := 100.000000000000000000;
    WebDBLabel16.ParentFont := False;
    WebDBLabel16.WidthPercent := 100.000000000000000000;
    WebDBLabel16.DataField := 'InvoiceLines';
    WebDBLabel16.DataSource := Dm.WDSCurrStopOrders;
    WebLabel10.SetParentComponent(CurrentStopPnl);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 31;
    WebLabel10.Top := 294;
    WebLabel10.Width := 78;
    WebLabel10.Height := 19;
    WebLabel10.Caption := 'Line Items';
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clWindowText;
    WebLabel10.Font.Height := -16;
    WebLabel10.Font.Name := 'Arial';
    WebLabel10.Font.Style := [fsBold];
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebDBLabel17.SetParentComponent(CurrentStopPnl);
    WebDBLabel17.Name := 'WebDBLabel17';
    WebDBLabel17.Left := 247;
    WebDBLabel17.Top := 318;
    WebDBLabel17.Width := 84;
    WebDBLabel17.Height := 18;
    WebDBLabel17.Alignment := taRightJustify;
    WebDBLabel17.AutoSize := False;
    WebDBLabel17.Caption := '00000.00';
    WebDBLabel17.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel17.Font.Color := clWindowText;
    WebDBLabel17.Font.Height := -16;
    WebDBLabel17.Font.Name := 'Arial';
    WebDBLabel17.Font.Style := [];
    WebDBLabel17.HeightPercent := 100.000000000000000000;
    WebDBLabel17.ParentFont := False;
    WebDBLabel17.WidthPercent := 100.000000000000000000;
    WebDBLabel17.DataField := 'InvoiceTotal';
    WebDBLabel17.DataSource := Dm.WDSCurrStopOrders;
    WebLabel11.SetParentComponent(CurrentStopPnl);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 247;
    WebLabel11.Top := 294;
    WebLabel11.Width := 96;
    WebLabel11.Height := 19;
    WebLabel11.Caption := 'Invoice Total';
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clWindowText;
    WebLabel11.Font.Height := -16;
    WebLabel11.Font.Name := 'Arial';
    WebLabel11.Font.Style := [fsBold];
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebDBLabel18.SetParentComponent(CurrentStopPnl);
    WebDBLabel18.Name := 'WebDBLabel18';
    WebDBLabel18.Left := 31;
    WebDBLabel18.Top := 342;
    WebDBLabel18.Width := 73;
    WebDBLabel18.Height := 18;
    WebDBLabel18.Caption := 'Messages';
    WebDBLabel18.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel18.Font.Color := clWindowText;
    WebDBLabel18.Font.Height := -16;
    WebDBLabel18.Font.Name := 'Arial';
    WebDBLabel18.Font.Style := [];
    WebDBLabel18.HeightPercent := 100.000000000000000000;
    WebDBLabel18.ParentFont := False;
    WebDBLabel18.WidthPercent := 100.000000000000000000;
    WebDBLabel18.DataField := 'Mess1';
    WebDBLabel18.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel19.SetParentComponent(CurrentStopPnl);
    WebDBLabel19.Name := 'WebDBLabel19';
    WebDBLabel19.Left := 31;
    WebDBLabel19.Top := 366;
    WebDBLabel19.Width := 73;
    WebDBLabel19.Height := 18;
    WebDBLabel19.Caption := 'Messages';
    WebDBLabel19.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel19.Font.Color := clWindowText;
    WebDBLabel19.Font.Height := -16;
    WebDBLabel19.Font.Name := 'Arial';
    WebDBLabel19.Font.Style := [];
    WebDBLabel19.HeightPercent := 100.000000000000000000;
    WebDBLabel19.ParentFont := False;
    WebDBLabel19.WidthPercent := 100.000000000000000000;
    WebDBLabel19.DataField := 'Mess2';
    WebDBLabel19.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel20.SetParentComponent(CurrentStopPnl);
    WebDBLabel20.Name := 'WebDBLabel20';
    WebDBLabel20.Left := 174;
    WebDBLabel20.Top := 342;
    WebDBLabel20.Width := 73;
    WebDBLabel20.Height := 18;
    WebDBLabel20.Caption := 'Messages';
    WebDBLabel20.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel20.Font.Color := clWindowText;
    WebDBLabel20.Font.Height := -16;
    WebDBLabel20.Font.Name := 'Arial';
    WebDBLabel20.Font.Style := [];
    WebDBLabel20.HeightPercent := 100.000000000000000000;
    WebDBLabel20.ParentFont := False;
    WebDBLabel20.WidthPercent := 100.000000000000000000;
    WebDBLabel20.DataField := 'Mess3';
    WebDBLabel20.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel21.SetParentComponent(CurrentStopPnl);
    WebDBLabel21.Name := 'WebDBLabel21';
    WebDBLabel21.Left := 174;
    WebDBLabel21.Top := 366;
    WebDBLabel21.Width := 73;
    WebDBLabel21.Height := 18;
    WebDBLabel21.Caption := 'Messages';
    WebDBLabel21.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel21.Font.Color := clWindowText;
    WebDBLabel21.Font.Height := -16;
    WebDBLabel21.Font.Name := 'Arial';
    WebDBLabel21.Font.Style := [];
    WebDBLabel21.HeightPercent := 100.000000000000000000;
    WebDBLabel21.ParentFont := False;
    WebDBLabel21.WidthPercent := 100.000000000000000000;
    WebDBLabel21.DataField := 'Mess5';
    WebDBLabel21.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel22.SetParentComponent(CurrentStopPnl);
    WebDBLabel22.Name := 'WebDBLabel22';
    WebDBLabel22.Left := 31;
    WebDBLabel22.Top := 459;
    WebDBLabel22.Width := 115;
    WebDBLabel22.Height := 18;
    WebDBLabel22.Caption := 'Terms Explained';
    WebDBLabel22.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel22.Font.Color := clWindowText;
    WebDBLabel22.Font.Height := -16;
    WebDBLabel22.Font.Name := 'Arial';
    WebDBLabel22.Font.Style := [];
    WebDBLabel22.HeightPercent := 100.000000000000000000;
    WebDBLabel22.ParentFont := False;
    WebDBLabel22.WidthPercent := 100.000000000000000000;
    WebDBLabel22.DataField := 'TermsExplained';
    WebDBLabel22.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel23.SetParentComponent(CurrentStopPnl);
    WebDBLabel23.Name := 'WebDBLabel23';
    WebDBLabel23.Left := 31;
    WebDBLabel23.Top := 390;
    WebDBLabel23.Width := 131;
    WebDBLabel23.Height := 18;
    WebDBLabel23.Caption := 'Standing Message';
    WebDBLabel23.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel23.Font.Color := clWindowText;
    WebDBLabel23.Font.Height := -16;
    WebDBLabel23.Font.Name := 'Arial';
    WebDBLabel23.Font.Style := [];
    WebDBLabel23.HeightPercent := 100.000000000000000000;
    WebDBLabel23.ParentFont := False;
    WebDBLabel23.WordWrap := True;
    WebDBLabel23.WidthPercent := 100.000000000000000000;
    WebDBLabel23.DataField := 'StandingMessage';
    WebDBLabel23.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel24.SetParentComponent(CurrentStopPnl);
    WebDBLabel24.Name := 'WebDBLabel24';
    WebDBLabel24.Left := 31;
    WebDBLabel24.Top := 435;
    WebDBLabel24.Width := 116;
    WebDBLabel24.Height := 18;
    WebDBLabel24.Caption := 'Delivery Window';
    WebDBLabel24.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel24.Font.Color := clWindowText;
    WebDBLabel24.Font.Height := -16;
    WebDBLabel24.Font.Name := 'Arial';
    WebDBLabel24.Font.Style := [];
    WebDBLabel24.HeightPercent := 100.000000000000000000;
    WebDBLabel24.ParentFont := False;
    WebDBLabel24.WordWrap := True;
    WebDBLabel24.WidthPercent := 100.000000000000000000;
    WebDBLabel24.DataField := 'DeliveryWindow';
    WebDBLabel24.DataSource := Dm.WDSCurrStopOrders;
    WebDBLabel25.SetParentComponent(CurrentStopPnl);
    WebDBLabel25.Name := 'WebDBLabel25';
    WebDBLabel25.Left := 131;
    WebDBLabel25.Top := 318;
    WebDBLabel25.Width := 84;
    WebDBLabel25.Height := 18;
    WebDBLabel25.Alignment := taRightJustify;
    WebDBLabel25.AutoSize := False;
    WebDBLabel25.Caption := '00000.00';
    WebDBLabel25.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel25.Font.Color := clWindowText;
    WebDBLabel25.Font.Height := -16;
    WebDBLabel25.Font.Name := 'Arial';
    WebDBLabel25.Font.Style := [];
    WebDBLabel25.HeightPercent := 100.000000000000000000;
    WebDBLabel25.ParentFont := False;
    WebDBLabel25.WidthPercent := 100.000000000000000000;
    WebDBLabel25.DataField := 'PcsWeight';
    WebDBLabel25.DataSource := Dm.WDSCurrStopOrders;
    WebLabel12.SetParentComponent(CurrentStopPnl);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 126;
    WebLabel12.Top := 294;
    WebLabel12.Width := 100;
    WebLabel12.Height := 19;
    WebLabel12.Caption := 'Order Weight';
    WebLabel12.Font.Charset := DEFAULT_CHARSET;
    WebLabel12.Font.Color := clWindowText;
    WebLabel12.Font.Height := -16;
    WebLabel12.Font.Name := 'Arial';
    WebLabel12.Font.Style := [fsBold];
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.ParentFont := False;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(CurrentStopPnl);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 24;
    WebLabel13.Top := 538;
    WebLabel13.Width := 321;
    WebLabel13.Height := 19;
    WebLabel13.Caption := 'Vendor Pickups on Route - Check Options';
    WebLabel13.Font.Charset := DEFAULT_CHARSET;
    WebLabel13.Font.Color := clWindowText;
    WebLabel13.Font.Height := -16;
    WebLabel13.Font.Name := 'Arial';
    WebLabel13.Font.Style := [fsBold];
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.ParentFont := False;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebDBLabel4.SetParentComponent(CurrentStopPnl);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 109;
    WebDBLabel4.Top := 80;
    WebDBLabel4.Width := 41;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := 'Cust#';
    WebDBLabel4.Font.Charset := DEFAULT_CHARSET;
    WebDBLabel4.Font.Color := clWindowText;
    WebDBLabel4.Font.Height := -16;
    WebDBLabel4.Font.Name := 'Arial';
    WebDBLabel4.Font.Style := [];
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.ParentFont := False;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'ShipName';
    WebDBLabel4.DataSource := Dm.WDSCurrStopOrders;
    NextOrdBtn.SetParentComponent(CurrentStopPnl);
    NextOrdBtn.Name := 'NextOrdBtn';
    NextOrdBtn.Left := 207;
    NextOrdBtn.Top := 559;
    NextOrdBtn.Width := 137;
    NextOrdBtn.Height := 57;
    NextOrdBtn.Caption := 'Next Order';
    NextOrdBtn.ChildOrder := 1;
    NextOrdBtn.ElementClassName := 'btn btn-success';
    NextOrdBtn.Font.Charset := DEFAULT_CHARSET;
    NextOrdBtn.Font.Color := clWindowText;
    NextOrdBtn.Font.Height := -19;
    NextOrdBtn.Font.Name := 'Arial';
    NextOrdBtn.Font.Style := [];
    NextOrdBtn.HeightPercent := 100.000000000000000000;
    NextOrdBtn.ParentFont := False;
    NextOrdBtn.WidthPercent := 100.000000000000000000;
    SetEvent(NextOrdBtn, Self, 'OnClick', 'NextOrdBtnClick');
    PrevOrdBtn.SetParentComponent(CurrentStopPnl);
    PrevOrdBtn.Name := 'PrevOrdBtn';
    PrevOrdBtn.Left := 31;
    PrevOrdBtn.Top := 559;
    PrevOrdBtn.Width := 137;
    PrevOrdBtn.Height := 57;
    PrevOrdBtn.Caption := 'Prev Order';
    PrevOrdBtn.ChildOrder := 1;
    PrevOrdBtn.ElementClassName := 'btn btn-warning';
    PrevOrdBtn.Font.Charset := DEFAULT_CHARSET;
    PrevOrdBtn.Font.Color := clWindowText;
    PrevOrdBtn.Font.Height := -19;
    PrevOrdBtn.Font.Name := 'Arial';
    PrevOrdBtn.Font.Style := [];
    PrevOrdBtn.HeightPercent := 100.000000000000000000;
    PrevOrdBtn.ParentFont := False;
    PrevOrdBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PrevOrdBtn, Self, 'OnClick', 'PrevOrdBtnClick');
    RouteRecapBtn.SetParentComponent(CurrentStopPnl);
    RouteRecapBtn.Name := 'RouteRecapBtn';
    RouteRecapBtn.Left := 31;
    RouteRecapBtn.Top := 622;
    RouteRecapBtn.Width := 137;
    RouteRecapBtn.Height := 57;
    RouteRecapBtn.Caption := 'Route Recap';
    RouteRecapBtn.ChildOrder := 1;
    RouteRecapBtn.ElementClassName := 'btn btn-info';
    RouteRecapBtn.Font.Charset := DEFAULT_CHARSET;
    RouteRecapBtn.Font.Color := clWindowText;
    RouteRecapBtn.Font.Height := -19;
    RouteRecapBtn.Font.Name := 'Arial';
    RouteRecapBtn.Font.Style := [];
    RouteRecapBtn.HeightPercent := 100.000000000000000000;
    RouteRecapBtn.ParentFont := False;
    RouteRecapBtn.WidthPercent := 100.000000000000000000;
    SetEvent(RouteRecapBtn, Self, 'OnClick', 'RouteRecapBtnClick');
    WebButton4.SetParentComponent(CurrentStopPnl);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 31;
    WebButton4.Top := 685;
    WebButton4.Width := 137;
    WebButton4.Height := 57;
    WebButton4.Caption := 'Close';
    WebButton4.ChildOrder := 1;
    WebButton4.ElementClassName := 'btn btn-danger';
    WebButton4.Font.Charset := DEFAULT_CHARSET;
    WebButton4.Font.Color := clWindowText;
    WebButton4.Font.Height := -19;
    WebButton4.Font.Name := 'Arial';
    WebButton4.Font.Style := [];
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.ParentFont := False;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    OrderDtlBtn.SetParentComponent(CurrentStopPnl);
    OrderDtlBtn.Name := 'OrderDtlBtn';
    OrderDtlBtn.Left := 207;
    OrderDtlBtn.Top := 622;
    OrderDtlBtn.Width := 137;
    OrderDtlBtn.Height := 57;
    OrderDtlBtn.Caption := 'Order Details';
    OrderDtlBtn.ChildOrder := 1;
    OrderDtlBtn.ElementClassName := 'btn btn-info';
    OrderDtlBtn.Font.Charset := DEFAULT_CHARSET;
    OrderDtlBtn.Font.Color := clWindowText;
    OrderDtlBtn.Font.Height := -19;
    OrderDtlBtn.Font.Name := 'Arial';
    OrderDtlBtn.Font.Style := [];
    OrderDtlBtn.HeightPercent := 100.000000000000000000;
    OrderDtlBtn.ParentFont := False;
    OrderDtlBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OrderDtlBtn, Self, 'OnClick', 'OrderDtlBtnClick');
    OptionsBtn.SetParentComponent(CurrentStopPnl);
    OptionsBtn.Name := 'OptionsBtn';
    OptionsBtn.Left := 207;
    OptionsBtn.Top := 685;
    OptionsBtn.Width := 137;
    OptionsBtn.Height := 57;
    OptionsBtn.Caption := 'Options';
    OptionsBtn.ChildOrder := 1;
    OptionsBtn.ElementClassName := 'btn btn-secondary';
    OptionsBtn.Font.Charset := DEFAULT_CHARSET;
    OptionsBtn.Font.Color := clWindowText;
    OptionsBtn.Font.Height := -19;
    OptionsBtn.Font.Name := 'Arial';
    OptionsBtn.Font.Style := [];
    OptionsBtn.HeightPercent := 100.000000000000000000;
    OptionsBtn.ParentFont := False;
    OptionsBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OptionsBtn, Self, 'OnClick', 'OptionsBtnClick');
    DSBackhauls.SetParentComponent(Self);
    DSBackhauls.Name := 'DSBackhauls';
    DSBackhauls.AfterOpen := DSBackhaulsAfterOpen;
    DSBackhauls.EntitySetName := 'Backhauls';
    DSBackhauls.Connection := Dm.ApiConnection;
    DSBackhauls.Left := 40;
    DSBackhauls.Top := 496;
    DSBackhaulsOrdrNumTrunc.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrNumTrunc.Name := 'DSBackhaulsOrdrNumTrunc';
    DSBackhaulsOrdrNumTrunc.FieldName := 'OrdrNumTrunc';
    DSBackhaulsVenNum.SetParentComponent(DSBackhauls);
    DSBackhaulsVenNum.Name := 'DSBackhaulsVenNum';
    DSBackhaulsVenNum.FieldName := 'VenNum';
    DSBackhaulsReleaseNum.SetParentComponent(DSBackhauls);
    DSBackhaulsReleaseNum.Name := 'DSBackhaulsReleaseNum';
    DSBackhaulsReleaseNum.FieldName := 'ReleaseNum';
    DSBackhaulsReleaseNum.Size := 24;
    DSBackhaulsAdditionalTemPinfo.SetParentComponent(DSBackhauls);
    DSBackhaulsAdditionalTemPinfo.Name := 'DSBackhaulsAdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.FieldName := 'AdditionalTemPinfo';
    DSBackhaulsAdditionalTemPinfo.Size := 120;
    DSBackhaulsNotes.SetParentComponent(DSBackhauls);
    DSBackhaulsNotes.Name := 'DSBackhaulsNotes';
    DSBackhaulsNotes.FieldName := 'Notes';
    DSBackhaulsNotes.Size := 120;
    DSBackhaulsVenLoc.SetParentComponent(DSBackhauls);
    DSBackhaulsVenLoc.Name := 'DSBackhaulsVenLoc';
    DSBackhaulsVenLoc.FieldName := 'VenLoc';
    DSBackhaulsVenLoc.Size := 24;
    DSBackhaulsAdd1.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd1.Name := 'DSBackhaulsAdd1';
    DSBackhaulsAdd1.FieldName := 'Add1';
    DSBackhaulsAdd2.SetParentComponent(DSBackhauls);
    DSBackhaulsAdd2.Name := 'DSBackhaulsAdd2';
    DSBackhaulsAdd2.FieldName := 'Add2';
    DSBackhaulsCity.SetParentComponent(DSBackhauls);
    DSBackhaulsCity.Name := 'DSBackhaulsCity';
    DSBackhaulsCity.FieldName := 'City';
    DSBackhaulsCity.Size := 16;
    DSBackhaulsST.SetParentComponent(DSBackhauls);
    DSBackhaulsST.Name := 'DSBackhaulsST';
    DSBackhaulsST.FieldName := 'ST';
    DSBackhaulsST.Size := 2;
    DSBackhaulsZip.SetParentComponent(DSBackhauls);
    DSBackhaulsZip.Name := 'DSBackhaulsZip';
    DSBackhaulsZip.FieldName := 'Zip';
    DSBackhaulsZip.Size := 10;
    DSBackhaulsPhone.SetParentComponent(DSBackhauls);
    DSBackhaulsPhone.Name := 'DSBackhaulsPhone';
    DSBackhaulsPhone.FieldName := 'Phone';
    DSBackhaulsPhone.Size := 14;
    DSBackhaulsTtlCs.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCs.Name := 'DSBackhaulsTtlCs';
    DSBackhaulsTtlCs.FieldName := 'TtlCs';
    DSBackhaulsTtlCubes.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlCubes.Name := 'DSBackhaulsTtlCubes';
    DSBackhaulsTtlCubes.FieldName := 'TtlCubes';
    DSBackhaulsTtlPndsGross.SetParentComponent(DSBackhauls);
    DSBackhaulsTtlPndsGross.Name := 'DSBackhaulsTtlPndsGross';
    DSBackhaulsTtlPndsGross.FieldName := 'TtlPndsGross';
    DSBackhaulsOrdrShipDt.SetParentComponent(DSBackhauls);
    DSBackhaulsOrdrShipDt.Name := 'DSBackhaulsOrdrShipDt';
    DSBackhaulsOrdrShipDt.FieldName := 'OrdrShipDt';
    DSBackhaulsPickupTruck.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupTruck.Name := 'DSBackhaulsPickupTruck';
    DSBackhaulsPickupTruck.FieldName := 'PickupTruck';
    DSBackhaulsLatitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLatitude.Name := 'DSBackhaulsLatitude';
    DSBackhaulsLatitude.FieldName := 'Latitude';
    DSBackhaulsLongitude.SetParentComponent(DSBackhauls);
    DSBackhaulsLongitude.Name := 'DSBackhaulsLongitude';
    DSBackhaulsLongitude.FieldName := 'Longitude';
    DSBackhaulsPickupCompleted.SetParentComponent(DSBackhauls);
    DSBackhaulsPickupCompleted.Name := 'DSBackhaulsPickupCompleted';
    DSBackhaulsPickupCompleted.FieldName := 'PickupCompleted';
    DSBackhaulsPickupCompleted.Size := 1;
    WDSBackhauls.SetParentComponent(Self);
    WDSBackhauls.Name := 'WDSBackhauls';
    WDSBackhauls.DataSet := DSBackhauls;
    WDSBackhauls.Left := 96;
    WDSBackhauls.Top := 504;
  finally
    CurrentStopPnl.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebDBLabel6.AfterLoadDFMValues;
    WebDBLabel7.AfterLoadDFMValues;
    WebDBLabel8.AfterLoadDFMValues;
    WebDBLabel9.AfterLoadDFMValues;
    WebDBLabel10.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebDBLabel11.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebDBLabel12.AfterLoadDFMValues;
    WebDBLabel13.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebDBLabel14.AfterLoadDFMValues;
    WebDBLabel15.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebDBLabel16.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebDBLabel17.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebDBLabel18.AfterLoadDFMValues;
    WebDBLabel19.AfterLoadDFMValues;
    WebDBLabel20.AfterLoadDFMValues;
    WebDBLabel21.AfterLoadDFMValues;
    WebDBLabel22.AfterLoadDFMValues;
    WebDBLabel23.AfterLoadDFMValues;
    WebDBLabel24.AfterLoadDFMValues;
    WebDBLabel25.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    NextOrdBtn.AfterLoadDFMValues;
    PrevOrdBtn.AfterLoadDFMValues;
    RouteRecapBtn.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    OrderDtlBtn.AfterLoadDFMValues;
    OptionsBtn.AfterLoadDFMValues;
    DSBackhauls.AfterLoadDFMValues;
    DSBackhaulsOrdrNumTrunc.AfterLoadDFMValues;
    DSBackhaulsVenNum.AfterLoadDFMValues;
    DSBackhaulsReleaseNum.AfterLoadDFMValues;
    DSBackhaulsAdditionalTemPinfo.AfterLoadDFMValues;
    DSBackhaulsNotes.AfterLoadDFMValues;
    DSBackhaulsVenLoc.AfterLoadDFMValues;
    DSBackhaulsAdd1.AfterLoadDFMValues;
    DSBackhaulsAdd2.AfterLoadDFMValues;
    DSBackhaulsCity.AfterLoadDFMValues;
    DSBackhaulsST.AfterLoadDFMValues;
    DSBackhaulsZip.AfterLoadDFMValues;
    DSBackhaulsPhone.AfterLoadDFMValues;
    DSBackhaulsTtlCs.AfterLoadDFMValues;
    DSBackhaulsTtlCubes.AfterLoadDFMValues;
    DSBackhaulsTtlPndsGross.AfterLoadDFMValues;
    DSBackhaulsOrdrShipDt.AfterLoadDFMValues;
    DSBackhaulsPickupTruck.AfterLoadDFMValues;
    DSBackhaulsLatitude.AfterLoadDFMValues;
    DSBackhaulsLongitude.AfterLoadDFMValues;
    DSBackhaulsPickupCompleted.AfterLoadDFMValues;
    WDSBackhauls.AfterLoadDFMValues;
  end;
end;

end.
