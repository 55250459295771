unit AccidentDetail;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Data.DB, XData.Web.JsonDataset, XData.Web.Dataset,
  Vcl.TMSFNCTypes, Vcl.TMSFNCUtils, Vcl.TMSFNCGraphics, Vcl.TMSFNCGraphicsTypes,
  Vcl.TMSFNCDatePicker, Vcl.TMSFNCCustomControl, Vcl.TMSFNCCustomPicker,
  Vcl.TMSFNCDigitalTimePicker, DateUtils;

type
  TFAccidentDetail = class(TForm)
    ADtlPnl: TScrollBox;
    WebButton2: TButton;
    DSAccidentDetail: TXDataWebDataSet;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    EditLocation: TEdit;
    WebLabel3: TLabel;
    EditIntersection: TEdit;
    WebLabel4: TLabel;
    EditCity: TEdit;
    WebLabel5: TLabel;
    EditState: TEdit;
    WebLabel6: TLabel;
    EditCounty: TEdit;
    WebLabel7: TLabel;
    CBPoliceYes: TCheckBox;
    WebLabel8: TLabel;
    EditIncidentNum: TEdit;
    WebLabel9: TLabel;
    EditPoliceDepartment: TEdit;
    WebLabel11: TLabel;
    MemoCitations: TMemo;
    WebLabel12: TLabel;
    WebLabel13: TLabel;
    WebLabel14: TLabel;
    CombDirection: TComboBox;
    CombOVDirection: TComboBox;
    WebLabel15: TLabel;
    WebLabel16: TLabel;
    WebLabel17: TLabel;
    EditSpeed: TEdit;
    EditOVSpeed: TEdit;
    WebLabel18: TLabel;
    EditSpeedLimit: TEdit;
    WebLabel19: TLabel;
    EditTrafficControls: TEdit;
    WebLabel20: TLabel;
    EditOVTrafficControls: TEdit;
    WebLabel21: TLabel;
    EditHeadlights: TEdit;
    WebLabel22: TLabel;
    EditOVHeadlights: TEdit;
    WebLabel23: TLabel;
    EditRoadConditions: TEdit;
    WebLabel24: TLabel;
    EditWeatherConditions: TEdit;
    WebLabel25: TLabel;
    MemoResponsibility: TMemo;
    WebLabel26: TLabel;
    MemoResponsibilityDetails: TMemo;
    EditAccidentDate: TEdit;
    EditAccidentTime: TEdit;
    WebLabel10: TLabel;
    DSAccidentDetailAutoInc: TIntegerField;
    DSAccidentDetailAccidentNum: TIntegerField;
    DSAccidentDetailAccidentDate: TStringField;
    DSAccidentDetailAccidentTime: TStringField;
    DSAccidentDetailLocation: TStringField;
    DSAccidentDetailIntersection: TStringField;
    DSAccidentDetailCity: TStringField;
    DSAccidentDetailCounty: TStringField;
    DSAccidentDetailState: TStringField;
    DSAccidentDetailPoliceInvestigation: TStringField;
    DSAccidentDetailIncidentNum: TStringField;
    DSAccidentDetailDepartmentName: TStringField;
    DSAccidentDetailCitations: TStringField;
    DSAccidentDetailDirectionTravelling: TStringField;
    DSAccidentDetailOVDirection: TStringField;
    DSAccidentDetailSpeed: TStringField;
    DSAccidentDetailOVSpeed: TStringField;
    DSAccidentDetailSpeedLimit: TStringField;
    DSAccidentDetailTrafficControls: TStringField;
    DSAccidentDetailOVTrafficControls: TStringField;
    DSAccidentDetailHeadlights: TStringField;
    DSAccidentDetailOVHeadlights: TStringField;
    DSAccidentDetailRoadCondition: TStringField;
    DSAccidentDetailWeatherCondition: TStringField;
    DSAccidentDetailAccidentResponsibility: TStringField;
    DSAccidentDetailAccidentResponsibilityDetails: TStringField;
    DSAccidentDetailAccidentDescription: TStringField;
    DSAccidentDetailAccidentDiagram: TStringField;
    procedure WebFormShow(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FAccidentDetail: TFAccidentDetail;

implementation

Uses Data, Accident, Options;

{$R *.dfm}

procedure TFAccidentDetail.WebButton2Click(Sender: TObject);
begin
  DSAccidentDetail.Append;
  DSAccidentDetailAccidentNum.AsInteger :=
    AccidentNum;
  DSAccidentDetailAccidentDate.AsString := EditAccidentDate.Text;
  DSAccidentDetailAccidentTime.AsString := EditAccidentTime.Text;
  DSAccidentDetailLocation.AsString := EditLocation.Text;
  DSAccidentDetailIntersection.AsString := EditIntersection.Text;
  DSAccidentDetailCity.AsString := EditCity.Text;
  DSAccidentDetailCounty.AsString := EditCounty.Text;
  DSAccidentDetailState.AsString := EditCounty.Text;
  if CBPoliceYes.Checked = True then
    DSAccidentDetailPoliceInvestigation.AsString := 'Y'
  else
    DSAccidentDetailPoliceInvestigation.AsString := 'N';
  DSAccidentDetailIncidentNum.AsString := EditIncidentNum.Text;
  DSAccidentDetailDepartmentName.AsString := EditPoliceDepartment.Text;
  DSAccidentDetailCitations.AsString := MemoCitations.Lines.Text;
  DSAccidentDetailDirectionTravelling.AsString := CombDirection.Items
    [CombDirection.ItemIndex];
  DSAccidentDetailOVDirection.AsString := CombOVDirection.Items
    [CombOVDirection.ItemIndex];
  DSAccidentDetailSpeed.AsString := EditSpeed.Text;
  DSAccidentDetailOVSpeed.AsString := EditOVSpeed.Text;
  DSAccidentDetailSpeedLimit.AsString := EditSpeedLimit.Text;
  DSAccidentDetailTrafficControls.AsString := EditTrafficControls.Text;
  DSAccidentDetailOVTrafficControls.AsString := EditOVTrafficControls.Text;
  DSAccidentDetailHeadlights.AsString := EditHeadlights.Text;
  DSAccidentDetailOVHeadlights.AsString := EditOVHeadlights.Text;
  DSAccidentDetailRoadCondition.AsString := EditRoadConditions.Text;
  DSAccidentDetailWeatherCondition.AsString := EditWeatherConditions.Text;
  DSAccidentDetailAccidentResponsibility.AsString :=
    MemoResponsibility.Lines.Text;
  DSAccidentDetailAccidentResponsibilityDetails.AsString :=
    MemoResponsibilityDetails.Lines.Text;
  // DSAccidentDetailAccidentDescription.AsString := MemoDescription.Lines.Text;
  DSAccidentDetail.Post;
  DSAccidentDetail.ApplyUpdates;
  // Application.CreateForm(TFAccidentDiagram, FAccidentDiagram);
  // Application.CreateForm(TFAccidentPhotos, FAccidentPhotos);
  ShowMessage('Accident Form Submitted');
  ShowMessage('Please Send Photos of Accident to accidents@maplevalefarms.com');
  Application.CreateForm(TFOptions, FOptions);
end;

procedure TFAccidentDetail.WebFormResize(Sender: TObject);
begin
  ADtlPnl.Top := Round((FAccidentDetail.Height / 2) - (ADtlPnl.Height / 2));
  if ADtlPnl.Top < 0 then
    ADtlPnl.Top := 0;
end;

procedure TFAccidentDetail.WebFormShow(Sender: TObject);
begin
  ADtlPnl.Left := Round((FAccidentDetail.Width / 2) - (ADtlPnl.Width / 2));
  ADtlPnl.Top := Round((FAccidentDetail.Height / 2) - (ADtlPnl.Height / 2));
  DSAccidentDetail.QueryString := '$filter=(AccidentNum eq ' +
    IntToStr(FAccident.DSAccidentDetailAccidentNum.AsInteger) + ')';
  DSAccidentDetail.Load;
end;

procedure TFAccidentDetail.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ADtlPnl := TScrollBox.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  WebLabel22 := TLabel.Create(Self);
  WebLabel23 := TLabel.Create(Self);
  WebLabel24 := TLabel.Create(Self);
  WebLabel25 := TLabel.Create(Self);
  WebLabel26 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebButton2 := TButton.Create(Self);
  EditLocation := TEdit.Create(Self);
  EditIntersection := TEdit.Create(Self);
  EditCity := TEdit.Create(Self);
  EditState := TEdit.Create(Self);
  EditCounty := TEdit.Create(Self);
  CBPoliceYes := TCheckBox.Create(Self);
  EditIncidentNum := TEdit.Create(Self);
  EditPoliceDepartment := TEdit.Create(Self);
  MemoCitations := TMemo.Create(Self);
  CombDirection := TComboBox.Create(Self);
  CombOVDirection := TComboBox.Create(Self);
  EditSpeed := TEdit.Create(Self);
  EditOVSpeed := TEdit.Create(Self);
  EditSpeedLimit := TEdit.Create(Self);
  EditTrafficControls := TEdit.Create(Self);
  EditOVTrafficControls := TEdit.Create(Self);
  EditHeadlights := TEdit.Create(Self);
  EditOVHeadlights := TEdit.Create(Self);
  EditRoadConditions := TEdit.Create(Self);
  EditWeatherConditions := TEdit.Create(Self);
  MemoResponsibility := TMemo.Create(Self);
  MemoResponsibilityDetails := TMemo.Create(Self);
  EditAccidentDate := TEdit.Create(Self);
  EditAccidentTime := TEdit.Create(Self);
  DSAccidentDetail := TXDataWebDataSet.Create(Self);
  DSAccidentDetailAutoInc := TIntegerField.Create(Self);
  DSAccidentDetailAccidentNum := TIntegerField.Create(Self);
  DSAccidentDetailAccidentDate := TStringField.Create(Self);
  DSAccidentDetailAccidentTime := TStringField.Create(Self);
  DSAccidentDetailLocation := TStringField.Create(Self);
  DSAccidentDetailIntersection := TStringField.Create(Self);
  DSAccidentDetailCity := TStringField.Create(Self);
  DSAccidentDetailCounty := TStringField.Create(Self);
  DSAccidentDetailState := TStringField.Create(Self);
  DSAccidentDetailPoliceInvestigation := TStringField.Create(Self);
  DSAccidentDetailIncidentNum := TStringField.Create(Self);
  DSAccidentDetailDepartmentName := TStringField.Create(Self);
  DSAccidentDetailCitations := TStringField.Create(Self);
  DSAccidentDetailDirectionTravelling := TStringField.Create(Self);
  DSAccidentDetailOVDirection := TStringField.Create(Self);
  DSAccidentDetailSpeed := TStringField.Create(Self);
  DSAccidentDetailOVSpeed := TStringField.Create(Self);
  DSAccidentDetailSpeedLimit := TStringField.Create(Self);
  DSAccidentDetailTrafficControls := TStringField.Create(Self);
  DSAccidentDetailOVTrafficControls := TStringField.Create(Self);
  DSAccidentDetailHeadlights := TStringField.Create(Self);
  DSAccidentDetailOVHeadlights := TStringField.Create(Self);
  DSAccidentDetailRoadCondition := TStringField.Create(Self);
  DSAccidentDetailWeatherCondition := TStringField.Create(Self);
  DSAccidentDetailAccidentResponsibility := TStringField.Create(Self);
  DSAccidentDetailAccidentResponsibilityDetails := TStringField.Create(Self);
  DSAccidentDetailAccidentDescription := TStringField.Create(Self);
  DSAccidentDetailAccidentDiagram := TStringField.Create(Self);

  ADtlPnl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  WebLabel22.BeforeLoadDFMValues;
  WebLabel23.BeforeLoadDFMValues;
  WebLabel24.BeforeLoadDFMValues;
  WebLabel25.BeforeLoadDFMValues;
  WebLabel26.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  EditLocation.BeforeLoadDFMValues;
  EditIntersection.BeforeLoadDFMValues;
  EditCity.BeforeLoadDFMValues;
  EditState.BeforeLoadDFMValues;
  EditCounty.BeforeLoadDFMValues;
  CBPoliceYes.BeforeLoadDFMValues;
  EditIncidentNum.BeforeLoadDFMValues;
  EditPoliceDepartment.BeforeLoadDFMValues;
  MemoCitations.BeforeLoadDFMValues;
  CombDirection.BeforeLoadDFMValues;
  CombOVDirection.BeforeLoadDFMValues;
  EditSpeed.BeforeLoadDFMValues;
  EditOVSpeed.BeforeLoadDFMValues;
  EditSpeedLimit.BeforeLoadDFMValues;
  EditTrafficControls.BeforeLoadDFMValues;
  EditOVTrafficControls.BeforeLoadDFMValues;
  EditHeadlights.BeforeLoadDFMValues;
  EditOVHeadlights.BeforeLoadDFMValues;
  EditRoadConditions.BeforeLoadDFMValues;
  EditWeatherConditions.BeforeLoadDFMValues;
  MemoResponsibility.BeforeLoadDFMValues;
  MemoResponsibilityDetails.BeforeLoadDFMValues;
  EditAccidentDate.BeforeLoadDFMValues;
  EditAccidentTime.BeforeLoadDFMValues;
  DSAccidentDetail.BeforeLoadDFMValues;
  DSAccidentDetailAutoInc.BeforeLoadDFMValues;
  DSAccidentDetailAccidentNum.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDate.BeforeLoadDFMValues;
  DSAccidentDetailAccidentTime.BeforeLoadDFMValues;
  DSAccidentDetailLocation.BeforeLoadDFMValues;
  DSAccidentDetailIntersection.BeforeLoadDFMValues;
  DSAccidentDetailCity.BeforeLoadDFMValues;
  DSAccidentDetailCounty.BeforeLoadDFMValues;
  DSAccidentDetailState.BeforeLoadDFMValues;
  DSAccidentDetailPoliceInvestigation.BeforeLoadDFMValues;
  DSAccidentDetailIncidentNum.BeforeLoadDFMValues;
  DSAccidentDetailDepartmentName.BeforeLoadDFMValues;
  DSAccidentDetailCitations.BeforeLoadDFMValues;
  DSAccidentDetailDirectionTravelling.BeforeLoadDFMValues;
  DSAccidentDetailOVDirection.BeforeLoadDFMValues;
  DSAccidentDetailSpeed.BeforeLoadDFMValues;
  DSAccidentDetailOVSpeed.BeforeLoadDFMValues;
  DSAccidentDetailSpeedLimit.BeforeLoadDFMValues;
  DSAccidentDetailTrafficControls.BeforeLoadDFMValues;
  DSAccidentDetailOVTrafficControls.BeforeLoadDFMValues;
  DSAccidentDetailHeadlights.BeforeLoadDFMValues;
  DSAccidentDetailOVHeadlights.BeforeLoadDFMValues;
  DSAccidentDetailRoadCondition.BeforeLoadDFMValues;
  DSAccidentDetailWeatherCondition.BeforeLoadDFMValues;
  DSAccidentDetailAccidentResponsibility.BeforeLoadDFMValues;
  DSAccidentDetailAccidentResponsibilityDetails.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDescription.BeforeLoadDFMValues;
  DSAccidentDetailAccidentDiagram.BeforeLoadDFMValues;
  try
    Name := 'FAccidentDetail';
    Width := 412;
    Height := 758;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -16;
    Font.Name := 'Arial';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ADtlPnl.SetParentComponent(Self);
    ADtlPnl.Name := 'ADtlPnl';
    ADtlPnl.Left := 16;
    ADtlPnl.Top := 8;
    ADtlPnl.Width := 377;
    ADtlPnl.Height := 745;
    ADtlPnl.ElementClassName := 'card';
    ADtlPnl.BorderStyle := bsSingle;
    WebLabel1.SetParentComponent(ADtlPnl);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 32;
    WebLabel1.Top := 32;
    WebLabel1.Width := 99;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'Accident Date';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(ADtlPnl);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 32;
    WebLabel2.Top := 104;
    WebLabel2.Width := 153;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Approximate Location';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(ADtlPnl);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 32;
    WebLabel3.Top := 160;
    WebLabel3.Width := 79;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Intersection';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(ADtlPnl);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 32;
    WebLabel4.Top := 216;
    WebLabel4.Width := 27;
    WebLabel4.Height := 18;
    WebLabel4.Caption := 'City';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(ADtlPnl);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 258;
    WebLabel5.Top := 216;
    WebLabel5.Width := 37;
    WebLabel5.Height := 18;
    WebLabel5.Caption := 'State';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(ADtlPnl);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 32;
    WebLabel6.Top := 272;
    WebLabel6.Width := 48;
    WebLabel6.Height := 18;
    WebLabel6.Caption := 'County';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(ADtlPnl);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 32;
    WebLabel7.Top := 327;
    WebLabel7.Width := 143;
    WebLabel7.Height := 18;
    WebLabel7.Caption := 'Police Investigating?';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(ADtlPnl);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 32;
    WebLabel8.Top := 352;
    WebLabel8.Width := 160;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'Police Incident Number';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(ADtlPnl);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 32;
    WebLabel9.Top := 408;
    WebLabel9.Width := 130;
    WebLabel9.Height := 18;
    WebLabel9.Caption := 'Police Department';
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(ADtlPnl);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 32;
    WebLabel11.Top := 464;
    WebLabel11.Width := 111;
    WebLabel11.Height := 18;
    WebLabel11.Caption := 'Citations Issued';
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(ADtlPnl);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 32;
    WebLabel12.Top := 674;
    WebLabel12.Width := 132;
    WebLabel12.Height := 18;
    WebLabel12.Caption := 'Direction Travelling';
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(ADtlPnl);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 171;
    WebLabel13.Top := 674;
    WebLabel13.Width := 25;
    WebLabel13.Height := 18;
    WebLabel13.Caption := 'You';
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.WidthPercent := 100.000000000000000000;
    WebLabel14.SetParentComponent(ADtlPnl);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 251;
    WebLabel14.Top := 674;
    WebLabel14.Width := 38;
    WebLabel14.Height := 18;
    WebLabel14.Caption := 'Other';
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(ADtlPnl);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 31;
    WebLabel15.Top := 714;
    WebLabel15.Width := 47;
    WebLabel15.Height := 18;
    WebLabel15.Caption := 'Speed';
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(ADtlPnl);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 170;
    WebLabel16.Top := 714;
    WebLabel16.Width := 25;
    WebLabel16.Height := 18;
    WebLabel16.Caption := 'You';
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.WidthPercent := 100.000000000000000000;
    WebLabel17.SetParentComponent(ADtlPnl);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 250;
    WebLabel17.Top := 714;
    WebLabel17.Width := 38;
    WebLabel17.Height := 18;
    WebLabel17.Caption := 'Other';
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(ADtlPnl);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 31;
    WebLabel18.Top := 746;
    WebLabel18.Width := 85;
    WebLabel18.Height := 18;
    WebLabel18.Caption := 'Speed Limit';
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.WidthPercent := 100.000000000000000000;
    WebLabel19.SetParentComponent(ADtlPnl);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 31;
    WebLabel19.Top := 777;
    WebLabel19.Width := 104;
    WebLabel19.Height := 18;
    WebLabel19.Caption := 'Traffic Controls';
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebLabel20.SetParentComponent(ADtlPnl);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 31;
    WebLabel20.Top := 833;
    WebLabel20.Width := 201;
    WebLabel20.Height := 18;
    WebLabel20.Caption := 'Other Vehicle Traffic Controls';
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.WidthPercent := 100.000000000000000000;
    WebLabel21.SetParentComponent(ADtlPnl);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 31;
    WebLabel21.Top := 889;
    WebLabel21.Width := 74;
    WebLabel21.Height := 18;
    WebLabel21.Caption := 'Headlights';
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.WidthPercent := 100.000000000000000000;
    WebLabel22.SetParentComponent(ADtlPnl);
    WebLabel22.Name := 'WebLabel22';
    WebLabel22.Left := 31;
    WebLabel22.Top := 945;
    WebLabel22.Width := 171;
    WebLabel22.Height := 18;
    WebLabel22.Caption := 'Other Vehicle Headlights';
    WebLabel22.HeightPercent := 100.000000000000000000;
    WebLabel22.WidthPercent := 100.000000000000000000;
    WebLabel23.SetParentComponent(ADtlPnl);
    WebLabel23.Name := 'WebLabel23';
    WebLabel23.Left := 31;
    WebLabel23.Top := 1009;
    WebLabel23.Width := 109;
    WebLabel23.Height := 18;
    WebLabel23.Caption := 'Road Condition';
    WebLabel23.HeightPercent := 100.000000000000000000;
    WebLabel23.WidthPercent := 100.000000000000000000;
    WebLabel24.SetParentComponent(ADtlPnl);
    WebLabel24.Name := 'WebLabel24';
    WebLabel24.Left := 31;
    WebLabel24.Top := 1065;
    WebLabel24.Width := 138;
    WebLabel24.Height := 18;
    WebLabel24.Caption := 'Weather Conditions';
    WebLabel24.HeightPercent := 100.000000000000000000;
    WebLabel24.WidthPercent := 100.000000000000000000;
    WebLabel25.SetParentComponent(ADtlPnl);
    WebLabel25.Name := 'WebLabel25';
    WebLabel25.Left := 31;
    WebLabel25.Top := 1120;
    WebLabel25.Width := 171;
    WebLabel25.Height := 18;
    WebLabel25.Caption := 'Accident Responsibility?';
    WebLabel25.HeightPercent := 100.000000000000000000;
    WebLabel25.WidthPercent := 100.000000000000000000;
    WebLabel26.SetParentComponent(ADtlPnl);
    WebLabel26.Name := 'WebLabel26';
    WebLabel26.Left := 32;
    WebLabel26.Top := 1272;
    WebLabel26.Width := 215;
    WebLabel26.Height := 18;
    WebLabel26.Caption := 'Accident Responsibility Details';
    WebLabel26.HeightPercent := 100.000000000000000000;
    WebLabel26.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(ADtlPnl);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 191;
    WebLabel10.Top := 32;
    WebLabel10.Width := 99;
    WebLabel10.Height := 18;
    WebLabel10.Caption := 'Accident Time';
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebButton2.SetParentComponent(ADtlPnl);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 102;
    WebButton2.Top := 1440;
    WebButton2.Width := 161;
    WebButton2.Height := 73;
    WebButton2.Caption := 'Save && Continue';
    WebButton2.ChildOrder := 2;
    WebButton2.ElementClassName := 'btn btn-success';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    EditLocation.SetParentComponent(ADtlPnl);
    EditLocation.Name := 'EditLocation';
    EditLocation.Left := 32;
    EditLocation.Top := 128;
    EditLocation.Width := 305;
    EditLocation.Height := 25;
    EditLocation.ChildOrder := 5;
    EditLocation.HeightPercent := 100.000000000000000000;
    EditLocation.WidthPercent := 100.000000000000000000;
    EditIntersection.SetParentComponent(ADtlPnl);
    EditIntersection.Name := 'EditIntersection';
    EditIntersection.Left := 32;
    EditIntersection.Top := 184;
    EditIntersection.Width := 305;
    EditIntersection.Height := 25;
    EditIntersection.ChildOrder := 5;
    EditIntersection.HeightPercent := 100.000000000000000000;
    EditIntersection.WidthPercent := 100.000000000000000000;
    EditCity.SetParentComponent(ADtlPnl);
    EditCity.Name := 'EditCity';
    EditCity.Left := 32;
    EditCity.Top := 240;
    EditCity.Width := 201;
    EditCity.Height := 25;
    EditCity.ChildOrder := 5;
    EditCity.HeightPercent := 100.000000000000000000;
    EditCity.WidthPercent := 100.000000000000000000;
    EditState.SetParentComponent(ADtlPnl);
    EditState.Name := 'EditState';
    EditState.Left := 258;
    EditState.Top := 240;
    EditState.Width := 79;
    EditState.Height := 25;
    EditState.ChildOrder := 5;
    EditState.HeightPercent := 100.000000000000000000;
    EditState.WidthPercent := 100.000000000000000000;
    EditCounty.SetParentComponent(ADtlPnl);
    EditCounty.Name := 'EditCounty';
    EditCounty.Left := 32;
    EditCounty.Top := 296;
    EditCounty.Width := 305;
    EditCounty.Height := 25;
    EditCounty.ChildOrder := 5;
    EditCounty.HeightPercent := 100.000000000000000000;
    EditCounty.WidthPercent := 100.000000000000000000;
    CBPoliceYes.SetParentComponent(ADtlPnl);
    CBPoliceYes.Name := 'CBPoliceYes';
    CBPoliceYes.Left := 288;
    CBPoliceYes.Top := 327;
    CBPoliceYes.Width := 49;
    CBPoliceYes.Height := 22;
    CBPoliceYes.Caption := 'Yes';
    CBPoliceYes.ChildOrder := 15;
    CBPoliceYes.Color := clNone;
    CBPoliceYes.HeightPercent := 100.000000000000000000;
    CBPoliceYes.State := cbUnchecked;
    CBPoliceYes.WidthPercent := 100.000000000000000000;
    EditIncidentNum.SetParentComponent(ADtlPnl);
    EditIncidentNum.Name := 'EditIncidentNum';
    EditIncidentNum.Left := 32;
    EditIncidentNum.Top := 376;
    EditIncidentNum.Width := 305;
    EditIncidentNum.Height := 25;
    EditIncidentNum.ChildOrder := 5;
    EditIncidentNum.HeightPercent := 100.000000000000000000;
    EditIncidentNum.WidthPercent := 100.000000000000000000;
    EditPoliceDepartment.SetParentComponent(ADtlPnl);
    EditPoliceDepartment.Name := 'EditPoliceDepartment';
    EditPoliceDepartment.Left := 32;
    EditPoliceDepartment.Top := 432;
    EditPoliceDepartment.Width := 305;
    EditPoliceDepartment.Height := 25;
    EditPoliceDepartment.ChildOrder := 5;
    EditPoliceDepartment.HeightPercent := 100.000000000000000000;
    EditPoliceDepartment.WidthPercent := 100.000000000000000000;
    MemoCitations.SetParentComponent(ADtlPnl);
    MemoCitations.Name := 'MemoCitations';
    MemoCitations.Left := 32;
    MemoCitations.Top := 488;
    MemoCitations.Width := 305;
    MemoCitations.Height := 153;
    MemoCitations.AutoSize := False;
    MemoCitations.HeightPercent := 100.000000000000000000;
    MemoCitations.SelLength := 0;
    MemoCitations.SelStart := 0;
    MemoCitations.WidthPercent := 100.000000000000000000;
    CombDirection.SetParentComponent(ADtlPnl);
    CombDirection.Name := 'CombDirection';
    CombDirection.Left := 204;
    CombDirection.Top := 672;
    CombDirection.Width := 42;
    CombDirection.Height := 26;
    CombDirection.HeightPercent := 100.000000000000000000;
    CombDirection.Text := 'E';
    CombDirection.WidthPercent := 100.000000000000000000;
    CombDirection.ItemIndex := 0;
    CombDirection.Items.BeginUpdate;
    try
      CombDirection.Items.Clear;
      CombDirection.Items.Add('E');
      CombDirection.Items.Add('NE');
      CombDirection.Items.Add('N');
      CombDirection.Items.Add('NW');
      CombDirection.Items.Add('W');
      CombDirection.Items.Add('SW');
      CombDirection.Items.Add('S');
      CombDirection.Items.Add('SE');
    finally
      CombDirection.Items.EndUpdate;
    end;
    CombOVDirection.SetParentComponent(ADtlPnl);
    CombOVDirection.Name := 'CombOVDirection';
    CombOVDirection.Left := 295;
    CombOVDirection.Top := 671;
    CombOVDirection.Width := 42;
    CombOVDirection.Height := 26;
    CombOVDirection.HeightPercent := 100.000000000000000000;
    CombOVDirection.WidthPercent := 100.000000000000000000;
    CombOVDirection.ItemIndex := 0;
    CombOVDirection.Items.BeginUpdate;
    try
      CombOVDirection.Items.Clear;
      CombOVDirection.Items.Add('');
      CombOVDirection.Items.Add('E');
      CombOVDirection.Items.Add('NE');
      CombOVDirection.Items.Add('N');
      CombOVDirection.Items.Add('NW');
      CombOVDirection.Items.Add('W');
      CombOVDirection.Items.Add('SW');
      CombOVDirection.Items.Add('S');
      CombOVDirection.Items.Add('SE');
    finally
      CombOVDirection.Items.EndUpdate;
    end;
    EditSpeed.SetParentComponent(ADtlPnl);
    EditSpeed.Name := 'EditSpeed';
    EditSpeed.Left := 201;
    EditSpeed.Top := 711;
    EditSpeed.Width := 43;
    EditSpeed.Height := 23;
    EditSpeed.ChildOrder := 32;
    EditSpeed.EditType := weNumeric;
    EditSpeed.HeightPercent := 100.000000000000000000;
    EditSpeed.WidthPercent := 100.000000000000000000;
    EditOVSpeed.SetParentComponent(ADtlPnl);
    EditOVSpeed.Name := 'EditOVSpeed';
    EditOVSpeed.Left := 294;
    EditOVSpeed.Top := 711;
    EditOVSpeed.Width := 43;
    EditOVSpeed.Height := 23;
    EditOVSpeed.ChildOrder := 32;
    EditOVSpeed.EditType := weNumeric;
    EditOVSpeed.HeightPercent := 100.000000000000000000;
    EditOVSpeed.WidthPercent := 100.000000000000000000;
    EditSpeedLimit.SetParentComponent(ADtlPnl);
    EditSpeedLimit.Name := 'EditSpeedLimit';
    EditSpeedLimit.Left := 231;
    EditSpeedLimit.Top := 742;
    EditSpeedLimit.Width := 105;
    EditSpeedLimit.Height := 25;
    EditSpeedLimit.ChildOrder := 5;
    EditSpeedLimit.EditType := weNumeric;
    EditSpeedLimit.HeightPercent := 100.000000000000000000;
    EditSpeedLimit.WidthPercent := 100.000000000000000000;
    EditTrafficControls.SetParentComponent(ADtlPnl);
    EditTrafficControls.Name := 'EditTrafficControls';
    EditTrafficControls.Left := 31;
    EditTrafficControls.Top := 801;
    EditTrafficControls.Width := 305;
    EditTrafficControls.Height := 25;
    EditTrafficControls.ChildOrder := 5;
    EditTrafficControls.HeightPercent := 100.000000000000000000;
    EditTrafficControls.WidthPercent := 100.000000000000000000;
    EditOVTrafficControls.SetParentComponent(ADtlPnl);
    EditOVTrafficControls.Name := 'EditOVTrafficControls';
    EditOVTrafficControls.Left := 31;
    EditOVTrafficControls.Top := 857;
    EditOVTrafficControls.Width := 305;
    EditOVTrafficControls.Height := 25;
    EditOVTrafficControls.ChildOrder := 5;
    EditOVTrafficControls.HeightPercent := 100.000000000000000000;
    EditOVTrafficControls.WidthPercent := 100.000000000000000000;
    EditHeadlights.SetParentComponent(ADtlPnl);
    EditHeadlights.Name := 'EditHeadlights';
    EditHeadlights.Left := 31;
    EditHeadlights.Top := 913;
    EditHeadlights.Width := 305;
    EditHeadlights.Height := 25;
    EditHeadlights.ChildOrder := 5;
    EditHeadlights.HeightPercent := 100.000000000000000000;
    EditHeadlights.WidthPercent := 100.000000000000000000;
    EditOVHeadlights.SetParentComponent(ADtlPnl);
    EditOVHeadlights.Name := 'EditOVHeadlights';
    EditOVHeadlights.Left := 31;
    EditOVHeadlights.Top := 969;
    EditOVHeadlights.Width := 305;
    EditOVHeadlights.Height := 25;
    EditOVHeadlights.ChildOrder := 5;
    EditOVHeadlights.HeightPercent := 100.000000000000000000;
    EditOVHeadlights.WidthPercent := 100.000000000000000000;
    EditRoadConditions.SetParentComponent(ADtlPnl);
    EditRoadConditions.Name := 'EditRoadConditions';
    EditRoadConditions.Left := 31;
    EditRoadConditions.Top := 1033;
    EditRoadConditions.Width := 305;
    EditRoadConditions.Height := 25;
    EditRoadConditions.ChildOrder := 5;
    EditRoadConditions.HeightPercent := 100.000000000000000000;
    EditRoadConditions.WidthPercent := 100.000000000000000000;
    EditWeatherConditions.SetParentComponent(ADtlPnl);
    EditWeatherConditions.Name := 'EditWeatherConditions';
    EditWeatherConditions.Left := 31;
    EditWeatherConditions.Top := 1089;
    EditWeatherConditions.Width := 305;
    EditWeatherConditions.Height := 25;
    EditWeatherConditions.ChildOrder := 5;
    EditWeatherConditions.HeightPercent := 100.000000000000000000;
    EditWeatherConditions.WidthPercent := 100.000000000000000000;
    MemoResponsibility.SetParentComponent(ADtlPnl);
    MemoResponsibility.Name := 'MemoResponsibility';
    MemoResponsibility.Left := 31;
    MemoResponsibility.Top := 1144;
    MemoResponsibility.Width := 306;
    MemoResponsibility.Height := 105;
    MemoResponsibility.AutoSize := False;
    MemoResponsibility.HeightPercent := 100.000000000000000000;
    MemoResponsibility.SelLength := 0;
    MemoResponsibility.SelStart := 0;
    MemoResponsibility.WidthPercent := 100.000000000000000000;
    MemoResponsibilityDetails.SetParentComponent(ADtlPnl);
    MemoResponsibilityDetails.Name := 'MemoResponsibilityDetails';
    MemoResponsibilityDetails.Left := 32;
    MemoResponsibilityDetails.Top := 1296;
    MemoResponsibilityDetails.Width := 306;
    MemoResponsibilityDetails.Height := 105;
    MemoResponsibilityDetails.AutoSize := False;
    MemoResponsibilityDetails.HeightPercent := 100.000000000000000000;
    MemoResponsibilityDetails.SelLength := 0;
    MemoResponsibilityDetails.SelStart := 0;
    MemoResponsibilityDetails.WidthPercent := 100.000000000000000000;
    EditAccidentDate.SetParentComponent(ADtlPnl);
    EditAccidentDate.Name := 'EditAccidentDate';
    EditAccidentDate.Left := 31;
    EditAccidentDate.Top := 56;
    EditAccidentDate.Width := 133;
    EditAccidentDate.Height := 25;
    EditAccidentDate.ChildOrder := 5;
    EditAccidentDate.EditType := weNumeric;
    EditAccidentDate.HeightPercent := 100.000000000000000000;
    EditAccidentDate.WidthPercent := 100.000000000000000000;
    EditAccidentTime.SetParentComponent(ADtlPnl);
    EditAccidentTime.Name := 'EditAccidentTime';
    EditAccidentTime.Left := 191;
    EditAccidentTime.Top := 56;
    EditAccidentTime.Width := 133;
    EditAccidentTime.Height := 25;
    EditAccidentTime.ChildOrder := 5;
    EditAccidentTime.EditType := weNumeric;
    EditAccidentTime.HeightPercent := 100.000000000000000000;
    EditAccidentTime.WidthPercent := 100.000000000000000000;
    DSAccidentDetail.SetParentComponent(Self);
    DSAccidentDetail.Name := 'DSAccidentDetail';
    DSAccidentDetail.EntitySetName := 'accidentdetail';
    DSAccidentDetail.Connection := Dm.ApiConnection;
    DSAccidentDetail.Left := 24;
    DSAccidentDetail.Top := 656;
    DSAccidentDetailAutoInc.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAutoInc.Name := 'DSAccidentDetailAutoInc';
    DSAccidentDetailAutoInc.FieldName := 'AutoInc';
    DSAccidentDetailAutoInc.Required := True;
    DSAccidentDetailAccidentNum.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentNum.Name := 'DSAccidentDetailAccidentNum';
    DSAccidentDetailAccidentNum.FieldName := 'AccidentNum';
    DSAccidentDetailAccidentDate.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDate.Name := 'DSAccidentDetailAccidentDate';
    DSAccidentDetailAccidentDate.FieldName := 'AccidentDate';
    DSAccidentDetailAccidentDate.Size := 15;
    DSAccidentDetailAccidentTime.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentTime.Name := 'DSAccidentDetailAccidentTime';
    DSAccidentDetailAccidentTime.FieldName := 'AccidentTime';
    DSAccidentDetailAccidentTime.Size := 15;
    DSAccidentDetailLocation.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailLocation.Name := 'DSAccidentDetailLocation';
    DSAccidentDetailLocation.FieldName := 'Location';
    DSAccidentDetailLocation.Size := 50;
    DSAccidentDetailIntersection.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailIntersection.Name := 'DSAccidentDetailIntersection';
    DSAccidentDetailIntersection.FieldName := 'Intersection';
    DSAccidentDetailIntersection.Size := 50;
    DSAccidentDetailCity.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCity.Name := 'DSAccidentDetailCity';
    DSAccidentDetailCity.FieldName := 'City';
    DSAccidentDetailCity.Size := 25;
    DSAccidentDetailCounty.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCounty.Name := 'DSAccidentDetailCounty';
    DSAccidentDetailCounty.FieldName := 'County';
    DSAccidentDetailState.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailState.Name := 'DSAccidentDetailState';
    DSAccidentDetailState.FieldName := 'State';
    DSAccidentDetailState.Size := 2;
    DSAccidentDetailPoliceInvestigation.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailPoliceInvestigation.Name := 'DSAccidentDetailPoliceInvestigation';
    DSAccidentDetailPoliceInvestigation.FieldName := 'PoliceInvestigation';
    DSAccidentDetailPoliceInvestigation.Size := 1;
    DSAccidentDetailIncidentNum.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailIncidentNum.Name := 'DSAccidentDetailIncidentNum';
    DSAccidentDetailIncidentNum.FieldName := 'IncidentNum';
    DSAccidentDetailIncidentNum.Size := 25;
    DSAccidentDetailDepartmentName.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailDepartmentName.Name := 'DSAccidentDetailDepartmentName';
    DSAccidentDetailDepartmentName.FieldName := 'DepartmentName';
    DSAccidentDetailDepartmentName.Size := 50;
    DSAccidentDetailCitations.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailCitations.Name := 'DSAccidentDetailCitations';
    DSAccidentDetailCitations.FieldName := 'Citations';
    DSAccidentDetailCitations.Size := 255;
    DSAccidentDetailDirectionTravelling.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailDirectionTravelling.Name := 'DSAccidentDetailDirectionTravelling';
    DSAccidentDetailDirectionTravelling.FieldName := 'DirectionTravelling';
    DSAccidentDetailDirectionTravelling.Size := 2;
    DSAccidentDetailOVDirection.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVDirection.Name := 'DSAccidentDetailOVDirection';
    DSAccidentDetailOVDirection.FieldName := 'OVDirection';
    DSAccidentDetailOVDirection.Size := 2;
    DSAccidentDetailSpeed.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailSpeed.Name := 'DSAccidentDetailSpeed';
    DSAccidentDetailSpeed.FieldName := 'Speed';
    DSAccidentDetailSpeed.Size := 10;
    DSAccidentDetailOVSpeed.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVSpeed.Name := 'DSAccidentDetailOVSpeed';
    DSAccidentDetailOVSpeed.FieldName := 'OVSpeed';
    DSAccidentDetailOVSpeed.Size := 10;
    DSAccidentDetailSpeedLimit.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailSpeedLimit.Name := 'DSAccidentDetailSpeedLimit';
    DSAccidentDetailSpeedLimit.FieldName := 'SpeedLimit';
    DSAccidentDetailSpeedLimit.Size := 10;
    DSAccidentDetailTrafficControls.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailTrafficControls.Name := 'DSAccidentDetailTrafficControls';
    DSAccidentDetailTrafficControls.FieldName := 'TrafficControls';
    DSAccidentDetailTrafficControls.Size := 25;
    DSAccidentDetailOVTrafficControls.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVTrafficControls.Name := 'DSAccidentDetailOVTrafficControls';
    DSAccidentDetailOVTrafficControls.FieldName := 'OVTrafficControls';
    DSAccidentDetailOVTrafficControls.Size := 25;
    DSAccidentDetailHeadlights.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailHeadlights.Name := 'DSAccidentDetailHeadlights';
    DSAccidentDetailHeadlights.FieldName := 'Headlights';
    DSAccidentDetailHeadlights.Size := 25;
    DSAccidentDetailOVHeadlights.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailOVHeadlights.Name := 'DSAccidentDetailOVHeadlights';
    DSAccidentDetailOVHeadlights.FieldName := 'OVHeadlights';
    DSAccidentDetailOVHeadlights.Size := 25;
    DSAccidentDetailRoadCondition.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailRoadCondition.Name := 'DSAccidentDetailRoadCondition';
    DSAccidentDetailRoadCondition.FieldName := 'RoadCondition';
    DSAccidentDetailRoadCondition.Size := 15;
    DSAccidentDetailWeatherCondition.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailWeatherCondition.Name := 'DSAccidentDetailWeatherCondition';
    DSAccidentDetailWeatherCondition.FieldName := 'WeatherCondition';
    DSAccidentDetailWeatherCondition.Size := 15;
    DSAccidentDetailAccidentResponsibility.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentResponsibility.Name := 'DSAccidentDetailAccidentResponsibility';
    DSAccidentDetailAccidentResponsibility.FieldName := 'AccidentResponsibility';
    DSAccidentDetailAccidentResponsibility.Size := 255;
    DSAccidentDetailAccidentResponsibilityDetails.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentResponsibilityDetails.Name := 'DSAccidentDetailAccidentResponsibilityDetails';
    DSAccidentDetailAccidentResponsibilityDetails.FieldName := 'AccidentResponsibilityDetails';
    DSAccidentDetailAccidentResponsibilityDetails.Size := 255;
    DSAccidentDetailAccidentDescription.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDescription.Name := 'DSAccidentDetailAccidentDescription';
    DSAccidentDetailAccidentDescription.FieldName := 'AccidentDescription';
    DSAccidentDetailAccidentDescription.Size := 255;
    DSAccidentDetailAccidentDiagram.SetParentComponent(DSAccidentDetail);
    DSAccidentDetailAccidentDiagram.Name := 'DSAccidentDetailAccidentDiagram';
    DSAccidentDetailAccidentDiagram.FieldName := 'AccidentDiagram';
    DSAccidentDetailAccidentDiagram.Size := 255;
  finally
    ADtlPnl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    WebLabel22.AfterLoadDFMValues;
    WebLabel23.AfterLoadDFMValues;
    WebLabel24.AfterLoadDFMValues;
    WebLabel25.AfterLoadDFMValues;
    WebLabel26.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    EditLocation.AfterLoadDFMValues;
    EditIntersection.AfterLoadDFMValues;
    EditCity.AfterLoadDFMValues;
    EditState.AfterLoadDFMValues;
    EditCounty.AfterLoadDFMValues;
    CBPoliceYes.AfterLoadDFMValues;
    EditIncidentNum.AfterLoadDFMValues;
    EditPoliceDepartment.AfterLoadDFMValues;
    MemoCitations.AfterLoadDFMValues;
    CombDirection.AfterLoadDFMValues;
    CombOVDirection.AfterLoadDFMValues;
    EditSpeed.AfterLoadDFMValues;
    EditOVSpeed.AfterLoadDFMValues;
    EditSpeedLimit.AfterLoadDFMValues;
    EditTrafficControls.AfterLoadDFMValues;
    EditOVTrafficControls.AfterLoadDFMValues;
    EditHeadlights.AfterLoadDFMValues;
    EditOVHeadlights.AfterLoadDFMValues;
    EditRoadConditions.AfterLoadDFMValues;
    EditWeatherConditions.AfterLoadDFMValues;
    MemoResponsibility.AfterLoadDFMValues;
    MemoResponsibilityDetails.AfterLoadDFMValues;
    EditAccidentDate.AfterLoadDFMValues;
    EditAccidentTime.AfterLoadDFMValues;
    DSAccidentDetail.AfterLoadDFMValues;
    DSAccidentDetailAutoInc.AfterLoadDFMValues;
    DSAccidentDetailAccidentNum.AfterLoadDFMValues;
    DSAccidentDetailAccidentDate.AfterLoadDFMValues;
    DSAccidentDetailAccidentTime.AfterLoadDFMValues;
    DSAccidentDetailLocation.AfterLoadDFMValues;
    DSAccidentDetailIntersection.AfterLoadDFMValues;
    DSAccidentDetailCity.AfterLoadDFMValues;
    DSAccidentDetailCounty.AfterLoadDFMValues;
    DSAccidentDetailState.AfterLoadDFMValues;
    DSAccidentDetailPoliceInvestigation.AfterLoadDFMValues;
    DSAccidentDetailIncidentNum.AfterLoadDFMValues;
    DSAccidentDetailDepartmentName.AfterLoadDFMValues;
    DSAccidentDetailCitations.AfterLoadDFMValues;
    DSAccidentDetailDirectionTravelling.AfterLoadDFMValues;
    DSAccidentDetailOVDirection.AfterLoadDFMValues;
    DSAccidentDetailSpeed.AfterLoadDFMValues;
    DSAccidentDetailOVSpeed.AfterLoadDFMValues;
    DSAccidentDetailSpeedLimit.AfterLoadDFMValues;
    DSAccidentDetailTrafficControls.AfterLoadDFMValues;
    DSAccidentDetailOVTrafficControls.AfterLoadDFMValues;
    DSAccidentDetailHeadlights.AfterLoadDFMValues;
    DSAccidentDetailOVHeadlights.AfterLoadDFMValues;
    DSAccidentDetailRoadCondition.AfterLoadDFMValues;
    DSAccidentDetailWeatherCondition.AfterLoadDFMValues;
    DSAccidentDetailAccidentResponsibility.AfterLoadDFMValues;
    DSAccidentDetailAccidentResponsibilityDetails.AfterLoadDFMValues;
    DSAccidentDetailAccidentDescription.AfterLoadDFMValues;
    DSAccidentDetailAccidentDiagram.AfterLoadDFMValues;
  end;
end;

end.
