{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : https://www.tmssoftware.com                       }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.TMSFNCDigitalTimeSelector;

{$I WEBLib.TMSFNCDefines.inc}

interface

uses
  Classes,
  {$IFNDEF LCLLIB}
  Types,
  {$ENDIF}
  {$IFNDEF WEBLIB}
  {$IFNDEF LCLLIB}
  UITypes,
  {$ENDIF}
  {$ENDIF}
  WEBLib.TMSFNCCustomSelector, WEBLib.TMSFNCGraphics, WEBLib.TMSFNCGraphicsTypes,
  WEBLib.TMSFNCTypes, WEBLib.Controls, WEBLib.TMSFNCUtils;

const
  MAJ_VER = 1; // Major version nr.
  MIN_VER = 0; // Minor version nr.
  REL_VER = 2; // Release nr.
  BLD_VER = 1; // Build nr.

  //Version history
  //v1.0.0.0 : First Release
  //v1.0.1.0 : New: Property HeaderSize added
  //v1.0.1.1 : Fixed : Issue with Column and Row property persistence
  //v1.0.2.0 : New : Support for high dpi
  //v1.0.2.1 : Improved : Upadated initial look

type
  TTMSFNCDigitalTimeSelectorIntervalUnit = (tsuMilliseconds, tsuSeconds, tsuMinutes, tsuHours);

  TTMSFNCCustomDigitalTimeSelector = class;

  TTMSFNCDigitalTimeSelectorItem = class(TTMSFNCCustomSelectorItem)
  private
    FOwner: TTMSFNCCustomDigitalTimeSelector;
    FTime: TTime;
    procedure SetTime(const Value: TTime);
    function IsTimeStored: Boolean;
  public
    constructor Create(ACollection: TCollection); override;
    procedure Assign(Source: TPersistent); override;
  published
    property Time: TTime read FTime write SetTime stored IsTimeStored nodefault;
  end;

  TTMSFNCDigitalTimeSelectorItems = class(TTMSFNCCustomSelectorItems)
  private
    FOwner: TTMSFNCCustomDigitalTimeSelector;
    procedure SetItem(Index: Integer; const Value: TTMSFNCDigitalTimeSelectorItem);
    function GetItem(Index: Integer): TTMSFNCDigitalTimeSelectorItem;
  protected
    function CreateItemClass: TCollectionItemClass; override;
  public
    constructor Create(AOwner: TTMSFNCCustomSelector); override;
    function Add: TTMSFNCDigitalTimeSelectorItem;
    function Insert(Index: Integer): TTMSFNCDigitalTimeSelectorItem;
    property Items[Index: Integer]: TTMSFNCDigitalTimeSelectorItem read GetItem write SetItem; default;
  end;

  TTMSFNCCustomDigitalTimeSelectorTimeSelected = procedure(Sender: TObject; ATime: TTime) of object;
  TTMSFNCCustomDigitalTimeSelectorTimeDeselected = procedure(Sender: TObject; ATime: TTime) of object;
  TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF) of object;
  TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderTextEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string) of object;
  TTMSFNCCustomDigitalTimeSelectorBeforeDrawArrowEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean) of object;
  TTMSFNCCustomDigitalTimeSelectorAfterDrawArrowEvent = procedure(Sender: TObject; AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean) of object;
  TTMSFNCCustomDigitalTimeSelectorNavigationEvent = procedure(Sender: TObject; ALeft: Boolean) of object;

  TTMSFNCCustomDigitalTimeSelector = class(TTMSFNCDefaultSelector)
  private
    FStartTime, FEndTime, FFirst, FLast, FSelectedTime: TTime;
    FTimeInterval: Integer;
    FIntervalUnit: TTMSFNCDigitalTimeSelectorIntervalUnit;
    FTimeFormat: string;
    FOnTimeDeselected: TTMSFNCCustomDigitalTimeSelectorTimeDeselected;
    FOnTimeSelected: TTMSFNCCustomDigitalTimeSelectorTimeSelected;
    FOnAfterDrawArrow: TTMSFNCCustomDigitalTimeSelectorAfterDrawArrowEvent;
    FOnBeforeDrawHeader: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderEvent;
    FOnBeforeDrawArrow: TTMSFNCCustomDigitalTimeSelectorBeforeDrawArrowEvent;
    FOnAfterDrawHeader: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderEvent;
    FOnAfterDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderTextEvent;
    FOnBeforeDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderTextEvent;
    FOnNavigation: TTMSFNCCustomDigitalTimeSelectorNavigationEvent;
    FHeaderSize: Integer;
    procedure SetItems(const Value: TTMSFNCDigitalTimeSelectorItems);
    procedure SetSelectedTime(const Value: TTime);
    procedure SetStartTime(const Value: TTime);
    procedure SetTimeInterval(const Value: Integer);
    procedure SetEndTime(const Value: TTime);
    procedure SetIntervalUnit(const Value: TTMSFNCDigitalTimeSelectorIntervalUnit);
    procedure SetTimeFormat(const Value: string);
    procedure SetHeaderSize(const Value: Integer);
    function GetItems: TTMSFNCDigitalTimeSelectorItems;
    function IsTimeFormatStored: Boolean;
  protected
    procedure ChangeDPIScale(M, D: Integer); override;
    procedure DoItemSelected(AItemIndex: Integer); override;
    procedure DoItemDeselected(AItemIndex: Integer); override;
    procedure DoBeforeDrawHeader(AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeader(AGraphics: TTMSFNCGraphics; ARectF: TRectF); virtual;
    procedure DoBeforeDrawHeaderText(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawHeaderText(AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string); virtual;
    procedure DoBeforeDrawArrow(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean; var AAllow: Boolean; var ADefaultDraw: Boolean); virtual;
    procedure DoAfterDrawArrow(AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean); virtual;
    procedure DoNavigation(ALeft: Boolean); virtual;
    procedure Draw(AGraphics: TTMSFNCGraphics; ARect: TRectF); override;
    procedure DrawArrows(AGraphics: TTMSFNCGraphics);
    procedure DrawItemText(AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem); override;
    procedure AddTimes; virtual;
    procedure CalculateTimes; virtual;
    procedure UpdateCalculations; override;
    procedure HandleMouseDown(Button: TTMSFNCMouseButton; Shift: TShiftState; X: Single; Y: Single); override;
    procedure HandleMouseMove(Shift: TShiftState; X: Single; Y: Single); override;
    function GetVersion: string; override;
    function CreateItemsCollection: TTMSFNCCustomSelectorItems; override;
    function GetTopOffset: Single; override;
    function GetArrowRect(ALeft: Boolean): TRectF; virtual;
    procedure ResetToDefaultStyle; override;
    property HeaderSize: Integer read FHeaderSize write SetHeaderSize default 24;
    property StartTime: TTime read FStartTime write SetStartTime;
    property EndTime: TTime read FEndTime write SetEndTime;
    property TimeInterval: Integer read FTimeInterval write SetTimeInterval default 5;
    property IntervalUnit: TTMSFNCDigitalTimeSelectorIntervalUnit read FIntervalUnit write SetIntervalUnit default tsuMinutes;
    property TimeFormat: string read FTimeFormat write SetTimeFormat stored IsTimeFormatStored nodefault;
    property OnTimeSelected: TTMSFNCCustomDigitalTimeSelectorTimeSelected read FOnTimeSelected write FOnTimeSelected;
    property OnTimeDeselected: TTMSFNCCustomDigitalTimeSelectorTimeDeselected read FOnTimeDeselected write FOnTimeDeselected;
    property OnBeforeDrawHeader: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderEvent read FOnBeforeDrawHeader write FOnBeforeDrawHeader;
    property OnAfterDrawHeader: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderEvent read FOnAfterDrawHeader write FOnAfterDrawHeader;
    property OnBeforeDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorBeforeDrawHeaderTextEvent read FOnBeforeDrawHeaderText write FOnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText: TTMSFNCCustomDigitalTimeSelectorAfterDrawHeaderTextEvent read FOnAfterDrawHeaderText write FOnAfterDrawHeaderText;
    property OnBeforeDrawArrow: TTMSFNCCustomDigitalTimeSelectorBeforeDrawArrowEvent read FOnBeforeDrawArrow write FOnBeforeDrawArrow;
    property OnAfterDrawArrow: TTMSFNCCustomDigitalTimeSelectorAfterDrawArrowEvent read FOnAfterDrawArrow write FOnAfterDrawArrow;
    property OnNavigation: TTMSFNCCustomDigitalTimeSelectorNavigationEvent read FOnNavigation write FOnNavigation;
  public
    constructor Create(AOwner: TComponent); override;
    procedure NavigateBack;
    procedure NavigateForth;
    procedure InitializePage(ATime: TTime);
    function FindItemByTime(ATime: TTime): Integer;
    function FindTimeByItem(AItem: Integer): TTime;
    procedure InitSample; virtual;
    property SelectedTime: TTime read FSelectedTime write SetSelectedTime;
    property Items: TTMSFNCDigitalTimeSelectorItems read GetItems write SetItems;
  end;

  {$IFNDEF LCLLIB}
  [ComponentPlatformsAttribute(TMSPlatformsWeb)]
  {$ENDIF}
  TTMSFNCDigitalTimeSelector = class(TTMSFNCCustomDigitalTimeSelector)
  protected
    procedure RegisterRuntimeClasses; override;
  published
    property Appearance;
    property Rows default 4;
    property Columns default 3;
    property SelectedItemIndex;
    property StartTime;
    property EndTime;
    property HeaderSize;
    property IntervalUnit;
    property TimeInterval;
    property TimeFormat;
    property OnTimeSelected;
    property OnTimeDeselected;
    property OnBeforeDrawHeader;
    property OnAfterDrawHeader;
    property OnBeforeDrawHeaderText;
    property OnAfterDrawHeaderText;
    property OnBeforeDrawArrow;
    property OnAfterDrawArrow;
    property OnNavigation;
  end;

implementation

uses
  Math, DateUtils, SysUtils;

{ TTMSFNCCustomDigitalTimeSelectorItem }

procedure TTMSFNCDigitalTimeSelectorItem.Assign(Source: TPersistent);
begin
  inherited;
  if Source is TTMSFNCDigitalTimeSelectorItem then
    FTime := (Source as TTMSFNCDigitalTimeSelectorItem).FTime;
end;

constructor TTMSFNCDigitalTimeSelectorItem.Create(
  ACollection: TCollection);
begin
  inherited;
  if Assigned(ACollection) then
    FOwner := (ACollection as TTMSFNCDigitalTimeSelectorItems).FOwner;
end;

function TTMSFNCDigitalTimeSelectorItem.IsTimeStored: Boolean;
begin
  Result := FTime <> 0.0;
end;

procedure TTMSFNCDigitalTimeSelectorItem.SetTime(const Value: TTime);
begin
  if FTime <> Value then
    FTime := Value;
end;

{ TTMSFNCCustomDigitalTimeSelectorItems }

function TTMSFNCDigitalTimeSelectorItems.Add: TTMSFNCDigitalTimeSelectorItem;
begin
  Result := TTMSFNCDigitalTimeSelectorItem(inherited Add);
end;

constructor TTMSFNCDigitalTimeSelectorItems.Create(
  AOwner: TTMSFNCCustomSelector);
begin
  inherited;
  FOwner := AOwner as TTMSFNCCustomDigitalTimeSelector;
end;

function TTMSFNCDigitalTimeSelectorItems.CreateItemClass: TCollectionItemClass;
begin
  Result := TTMSFNCDigitalTimeSelectorItem;
end;

function TTMSFNCDigitalTimeSelectorItems.GetItem(
  Index: Integer): TTMSFNCDigitalTimeSelectorItem;
begin
  Result := TTMSFNCDigitalTimeSelectorItem(inherited Items[Index]);
end;

function TTMSFNCDigitalTimeSelectorItems.Insert(
  Index: Integer): TTMSFNCDigitalTimeSelectorItem;
begin
  Result := TTMSFNCDigitalTimeSelectorItem(inherited Insert(Index));
end;

procedure TTMSFNCDigitalTimeSelectorItems.SetItem(Index: Integer;
  const Value: TTMSFNCDigitalTimeSelectorItem);
begin
  inherited Items[Index] := Value;
end;

{ TTMSFNCCustomDigitalTimeSelector }

procedure TTMSFNCCustomDigitalTimeSelector.AddTimes;
begin
  BeginUpdate;
  CalculateTimes;
  EndUpdate;
end;

procedure TTMSFNCCustomDigitalTimeSelector.CalculateTimes;
var
  I: Integer;
  t: TDateTime;
begin
  Items.Clear;
  t := 0.0;
  for I := 0 to Columns * Rows - 1 do
  begin
    case FIntervalUnit of
      tsuMilliseconds: t := IncMillisecond(FFirst, I * TimeInterval);
      tsuSeconds: t := IncSecond(FFirst, I * TimeInterval);
      tsuMinutes: t := IncMinute(FFirst, I * TimeInterval);
      tsuHours: t := IncHour(FFirst, I * TimeInterval);
    end;
    if CompareDateTime(t, EndTime) = 1 then
      Break;
    TTMSFNCDigitalTimeSelectorItem(Items.Add).Time := t;
    FLast := t;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.ChangeDPIScale(M, D: Integer);
begin
  HeaderSize := TTMSFNCUtils.MulDivInt(HeaderSize, M, D);
  inherited;
end;

constructor TTMSFNCCustomDigitalTimeSelector.Create(AOwner: TComponent);
begin
  inherited;
  {$IFDEF CMNLIB}
  {$IFDEF MSWINDOWS}
  NativeCanvas := True;
  TextQuality := gtqClearType;
  {$ENDIF}
  {$ENDIF}
  Height := 150;
  Width := 220;
  Columns := 3;
  Rows := 4;
  FTimeInterval := 5;
  FHeaderSize := 24;
  FIntervalUnit := tsuMinutes;
  FStartTime := StrToTime('00:00:00');
  FEndTime := StrToTime('23:59:59');
  FSelectedTime := FStartTime;
  FFirst := FStartTime;
  FTimeFormat := 'hh:nn:ss';
  AddTimes;

  if IsDesignTime then
    InitSample;
end;

function TTMSFNCCustomDigitalTimeSelector.CreateItemsCollection: TTMSFNCCustomSelectorItems;
begin
  Result := TTMSFNCDigitalTimeSelectorItems.Create(Self);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoAfterDrawArrow(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean);
begin
  if Assigned(OnAfterDrawArrow) then
    OnAfterDrawArrow(Self, AGraphics, ARectF, ALeft);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoAfterDrawHeader(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF);
begin
  if Assigned(OnAfterDrawHeader) then
    OnAfterDrawHeader(Self, AGraphics, ARectF);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoAfterDrawHeaderText(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string);
begin
  if Assigned(OnAfterDrawHeaderText) then
    OnAfterDrawHeaderText(Self, AGraphics, ARectF, AText);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoBeforeDrawArrow(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; ALeft: Boolean; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawArrow) then
    OnBeforeDrawArrow(Self, AGraphics, ARectF, ALeft, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoBeforeDrawHeader(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeader) then
    OnBeforeDrawHeader(Self, AGraphics, ARectF, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoBeforeDrawHeaderText(
  AGraphics: TTMSFNCGraphics; ARectF: TRectF; AText: string; var AAllow,
  ADefaultDraw: Boolean);
begin
  if Assigned(OnBeforeDrawHeaderText) then
    OnBeforeDrawHeaderText(Self, AGraphics, ARectF, AText, AAllow, ADefaultDraw);
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoItemDeselected(
  AItemIndex: Integer);
begin
  inherited;
  if Assigned(OnTimeDeselected) then
    OnTimeDeselected(Self, FindTimeByItem(AItemIndex));
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoItemSelected(AItemIndex: Integer);
begin
  inherited;
  FSelectedTime := FindTimeByItem(AItemIndex);
  if Assigned(OnTimeSelected) then
    OnTimeSelected(Self, FindTimeByItem(AItemIndex));
end;

procedure TTMSFNCCustomDigitalTimeSelector.DoNavigation(ALeft: Boolean);
begin
  if Assigned(OnNavigation) then
    OnNavigation(Self, ALeft);
end;

procedure TTMSFNCCustomDigitalTimeSelector.Draw(AGraphics: TTMSFNCGraphics;
  ARect: TRectF);
var
  b, df: Boolean;
  r: TRectF;
  s: string;
begin
  b := True;
  df := True;
  r := RectF(0, 0, Width, GetTopOffset);
  AGraphics.Fill.Color := Appearance.Fill.Color;
  AGraphics.Stroke.Color := Appearance.Stroke.Color;
  AGraphics.Font.AssignSource(Appearance.Font);

  DoBeforeDrawHeader(AGraphics, r, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawRectangle(r);
    DoAfterDrawHeader(AGraphics, r);
  end;

  s := FormatDateTime(FTimeFormat, FindTimeByItem(0)) + ' - ' + FormatDateTime(FTimeFormat, FindTimeByItem(Items.Count - 1));
  b := True;
  df := True;
  DoBeforeDrawHeaderText(AGraphics, r, s, b, df);
  if b then
  begin
    if df then
      AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);

    DoAfterDrawHeaderText(AGraphics, r, s);
  end;

  DrawArrows(AGraphics);
  inherited;
end;

procedure TTMSFNCCustomDigitalTimeSelector.DrawArrows(
  AGraphics: TTMSFNCGraphics);
var
  r: TRectF;
  p: TPointF;
  b, df: Boolean;
begin
  r := GetArrowRect(True);
  AGraphics.Stroke.Width := ScalePaintValue(2);
  AGraphics.Stroke.Color := gcDimgray;

  b := True;
  df := True;
  DoBeforeDrawArrow(AGraphics, r, True, b, df);
  if b then
  begin
    if df then
    begin
      p := PointF(r.Left + ScalePaintValue(4), GetTopOffset / 2);
      AGraphics.DrawLine(p, PointF(r.Right - ScalePaintValue(4), r.Top));
      AGraphics.DrawLine(p, PointF(r.Right - ScalePaintValue(4), r.Bottom - 1));
    end;
    DoAfterDrawArrow(AGraphics, r, True);
  end;

  r := GetArrowRect(False);
  b := True;
  df := True;
  DoBeforeDrawArrow(AGraphics, r, False, b, df);
  if b then
  begin
    if df then
    begin
      p := PointF(r.Right - ScalePaintValue(4), GetTopOffset / 2);
      AGraphics.DrawLine(p, PointF(r.Left + ScalePaintValue(4), r.Top));
      AGraphics.DrawLine(p, PointF(r.Left + ScalePaintValue(4), r.Bottom - 1));
    end;
    DoAfterDrawArrow(AGraphics, r, False);
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.DrawItemText(
  AGraphics: TTMSFNCGraphics; ADisplayItem: TTMSFNCCustomSelectorDisplayItem);
var
  r: TRectF;
  s: string;
  it: TTMSFNCCustomSelectorItem;
  t: TTime;
  a: Boolean;
begin
  it := ADisplayItem.Item;
  if Assigned(it) and (it is TTMSFNCDigitalTimeSelectorItem) then
  begin
    r := ADisplayItem.Rect;
    a := True;
    InflateRectEx(r, -5, -5);
    t := (it as TTMSFNCDigitalTimeSelectorItem).Time;
    s := FormatDateTime(FTimeFormat, t);
    AGraphics.Font.AssignSource(Appearance.Font);
    DoItemBeforeDrawText(AGraphics, ADisplayItem.Rect, it.Index, s, a);
    if a then
    begin
       AGraphics.DrawText(r, s, False, gtaCenter, gtaCenter);
      DoItemAfterDrawText(AGraphics, ADisplayItem.Rect, it.Index, s);
    end;
  end;
end;

function TTMSFNCCustomDigitalTimeSelector.FindItemByTime(ATime: TTime): Integer;
var
  I: Integer;
  it: TTMSFNCDigitalTimeSelectorItem;
begin
  Result := -1;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCDigitalTimeSelectorItem;
    if (CompareTime(it.Time, ATime) = 0) and it.CanSelect then
    begin
      Result := it.Index;
      Break;
    end;
  end;
end;

function TTMSFNCCustomDigitalTimeSelector.FindTimeByItem(AItem: Integer): TTime;
var
  I: Integer;
  it: TTMSFNCDigitalTimeSelectorItem;
begin
  Result := 0.0;
  for I := 0 to Items.Count - 1 do
  begin
    it := Items[I] as TTMSFNCDigitalTimeSelectorItem;
    if it.Index = AItem then
    begin
      Result := it.Time;
      Break;
    end;
  end;
end;

function TTMSFNCCustomDigitalTimeSelector.GetArrowRect(ALeft: Boolean): TRectF;
var
  r: TRectF;
begin
  r := GetControlRect;
  if ALeft then
    Result := RectF(r.Left, r.Top, r.Left + FHeaderSize, r.Top + FHeaderSize)
  else
    Result := RectF(r.Right - FHeaderSize, r.Top, r.Right, r.Top + FHeaderSize);

  InflateRectEx(Result, -ScalePaintValue(4), -ScalePaintValue(4));
end;

function TTMSFNCCustomDigitalTimeSelector.GetItems: TTMSFNCDigitalTimeSelectorItems;
begin
  Result := TTMSFNCDigitalTimeSelectorItems(inherited Items);
end;

function TTMSFNCCustomDigitalTimeSelector.GetTopOffset: Single;
begin
  Result := FHeaderSize;
end;

function TTMSFNCCustomDigitalTimeSelector.GetVersion: string;
begin
  Result := GetVersionNumber(MAJ_VER, MIN_VER, REL_VER, BLD_VER);
end;

procedure TTMSFNCCustomDigitalTimeSelector.HandleMouseDown(
  Button: TTMSFNCMouseButton; Shift: TShiftState; X, Y: Single);
begin
  inherited;
  if PtInRectEx(GetArrowRect(True), PointF(X, Y)) then
    NavigateBack
  else if PtInRectEx(GetArrowRect(False), PointF(X, Y)) then
    NavigateForth;
end;

procedure TTMSFNCCustomDigitalTimeSelector.HandleMouseMove(Shift: TShiftState;
  X, Y: Single);
begin
  inherited;
  if PtInRectEx(GetArrowRect(True), PointF(X, Y)) or PtInRectEx(GetArrowRect(False), PointF(X, Y)) then
    Cursor :=  crHandPoint
  else
    Cursor := crArrow;
end;

procedure TTMSFNCCustomDigitalTimeSelector.InitializePage(ATime: TTime);
var
  e, p: Integer;
begin
  if (CompareTime(ATime, FStartTime) = -1) or (CompareTime(ATime, FEndTime) = 1) then
  begin
    FFirst := FStartTime;
    Exit;
  end;

  case FIntervalUnit of
    tsuMilliseconds:
    begin
      e := (MilliSecondOfTheDay(ATime) - MilliSecondOfTheDay(FStartTime)) div Cardinal(FTimeInterval);
      p := e div (Rows * Columns);
      FFirst := IncMilliSecond(FStartTime, p * Rows * Columns * FTimeInterval);
    end;
    tsuSeconds:
    begin
      e := (SecondOfTheDay(ATime) - SecondOfTheDay(FStartTime)) div Cardinal(FTimeInterval);
      p := e div (Rows * Columns);
      FFirst := IncSecond(FStartTime, p * Rows * Columns * FTimeInterval);
    end;
    tsuMinutes:
    begin
      e := (MinuteOfTheDay(ATime) - MinuteOfTheDay(FStartTime)) div FTimeInterval;
      p := e div (Rows * Columns);
      FFirst := IncMinute(FStartTime, p * Rows * Columns * FTimeInterval);
    end;
    tsuHours:
    begin
      e := (HourOfTheDay(ATime) - HourOfTheDay(FStartTime)) div FTimeInterval;
      p := e div (Rows * Columns);
      FFirst := IncHour(FStartTime, p * Rows * Columns * FTimeInterval);
    end;
  end;

  AddTimes;
  SelectedItemIndex := FindItemByTime(FSelectedTime);
end;

procedure TTMSFNCCustomDigitalTimeSelector.InitSample;
begin
  ResetToDefaultStyle;
end;

function TTMSFNCCustomDigitalTimeSelector.IsTimeFormatStored: Boolean;
begin
  Result := FTimeFormat <> 'hh:nn:ss';
end;

procedure TTMSFNCCustomDigitalTimeSelector.NavigateBack;
var
  t: TDateTime;
begin
  t := 0.0;
  case FIntervalUnit of
    tsuMilliseconds: t := IncMilliSecond(FFirst, -(Columns * Rows) * FTimeInterval);
    tsuSeconds: t := IncSecond(FFirst, -(Columns * Rows) * FTimeInterval);
    tsuMinutes: t := IncMinute(FFirst, -(Columns * Rows) * FTimeInterval);
    tsuHours: t := IncHour(FFirst, -(Columns * Rows) * FTimeInterval);
  end;
  if CompareDateTime(t, FStartTime) >= 0 then
  begin
    FFirst := Frac(t);
    AddTimes;
    SelectedItemIndex := FindItemByTime(FSelectedTime);
    DoNavigation(True);
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.NavigateForth;
var
  t: TDateTime;
begin
  t := 0.0;
  case FIntervalUnit of
    tsuMilliseconds: t := IncMilliSecond(FLast, FTimeInterval);
    tsuSeconds: t := IncSecond(FLast, FTimeInterval);
    tsuMinutes: t := IncMinute(FLast, FTimeInterval);
    tsuHours: t := IncHour(FLast, FTimeInterval);
  end;
  if CompareDateTime(t, FEndTime) < 0 then
  begin
    FFirst := Frac(t);
    AddTimes;
    SelectedItemIndex := FindItemByTime(FSelectedTime);
    DoNavigation(False);
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.ResetToDefaultStyle;
begin
  inherited;
  BeginUpdate;

  {$IFDEF FMXLIB}
  Appearance.Font.Color := $FF454545;
  Appearance.Fill.Color := $FFEEF2F9;
  Appearance.FillSelected.Color := $FFA8BCF0;
  Appearance.StrokeSelected.Color := $FF2D9BEF;
  Appearance.FillDown.Color := $FF5A81E6;
  {$ENDIF}
  {$IFNDEF FMXLIB}
  Appearance.Font.Color := $454545;
  Appearance.Fill.Color := $F9F2EE;
  Appearance.FillSelected.Color := $F0BCA8;
  Appearance.StrokeSelected.Color := $EF9B2D;
  Appearance.FillDown.Color := $E6815A;
  {$ENDIF}

  Appearance.FillSelected.Kind := gfkSolid;
  Appearance.Fill.Kind := gfkSolid;
  Appearance.FillDown.Kind := gfkSolid;
  Appearance.Stroke.Kind := gskSolid;
  Appearance.StrokeSelected.Kind := gskSolid;
  Appearance.StrokeHover.Kind := gskSolid;

  Appearance.Stroke.Color := gcDarkgray;
  Appearance.StrokeHover.Color := Appearance.FillDown.Color;

  Appearance.FillHover.Assign(Appearance.Fill);
  Appearance.StrokeDown.Assign(Appearance.Stroke);

  EndUpdate;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetEndTime(const Value: TTime);
begin
  if CompareTime(FEndTime, Value) <> 0 then
  begin
    FEndTime := Value;
    AddTimes;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetHeaderSize(const Value: Integer);
begin
  if HeaderSize <> Value then
    FHeaderSize := Value;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetIntervalUnit(
  const Value: TTMSFNCDigitalTimeSelectorIntervalUnit);
begin
  if FIntervalUnit <> Value then
  begin
    FIntervalUnit := Value;
    AddTimes;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetItems(
  const Value: TTMSFNCDigitalTimeSelectorItems);
begin
  Items.Assign(Value);
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetSelectedTime(const Value: TTime);
begin
  FSelectedTime := Value;
  SelectedItemIndex := FindItemByTime(Value);
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetStartTime(const Value: TTime);
begin
  if CompareTime(FStartTime, Value) <> 0 then
  begin
    FStartTime := Value;
    FFirst := FStartTime;
    AddTimes;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetTimeFormat(const Value: string);
begin
  if FTimeFormat <> Value then
  begin
    FTimeFormat := Value;
    Invalidate;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.SetTimeInterval(
  const Value: Integer);
begin
  if FTimeInterval <> Value then
  begin
    FTimeInterval := Value;
    AddTimes;
  end;
end;

procedure TTMSFNCCustomDigitalTimeSelector.UpdateCalculations;
begin
  inherited;
  CalculateTimes;
end;

{ TTMSFNCDigitalTimeSelector }

procedure TTMSFNCDigitalTimeSelector.RegisterRuntimeClasses;
begin
  inherited;
  RegisterClasses([TTMSFNCDigitalTimeSelector, TTMSFNCDigitalTimeSelectorItem]);
end;

end.
